import React, { FC, useState } from 'react';
import { NavigateBeforeRounded, NavigateNextRounded, AddRounded, InfoOutlined } from '@mui/icons-material';
import { IconButton, Button, Popover, ToggleButton, ToggleButtonGroup } from '@mui/material';
import { CalendarType } from '../../interfaces';
import { StaticDatePicker } from '@mui/lab';
// import { DateInput } from 'components/Shared/Inputs';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { NavigationInstructionsDialog } from '../NavigationInstructionsDialog';
import { TextBox } from 'components/Shared/Inputs';

interface CalendaringToolbarProps {
    calendarType?: CalendarType,
    selectedDate: string,
    canBookAppointments: boolean,
    onSkipNavigationClick: () => void,
    onPreviousPeriodClick: () => void,
    onNextPeriodClick: () => void,
    onCalendarTypeChange: (type: CalendarType) => void,
    onDateSelected: (date: string) => void,
    onAddUnavailabilityClick: () => void,
    onAddAppointmentClick: () => void
}

export const CalendaringToolbar: FC<CalendaringToolbarProps> = (props) => {
    const { t } = useTranslation(['calendaring', 'common'], { useSuspense: false });

    const {
        calendarType,
        selectedDate,
        canBookAppointments,
        onSkipNavigationClick,
        onPreviousPeriodClick,
        onNextPeriodClick,
        onCalendarTypeChange,
        onDateSelected,
        onAddUnavailabilityClick,
        onAddAppointmentClick
    } = props;

    const [datePickerDate, setDatePickerDate] = useState(moment(selectedDate));
    const [instructionsDialogOpen, setInstructionsDialogOpen] = useState(false);

    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);

    const handleCalendarTypeChange = (_, type: CalendarType) => {
        type && onCalendarTypeChange(type);
    };

    const handleDateChange = (date) => {
        date && setDatePickerDate(date);
    };

    const showDatePicker = (event) => {
        setDatePickerDate(moment(selectedDate));
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleTodayButtonClick = () => {
        onDateSelected(moment().startOf('day').format('YYYY-MM-DD'));
        handleClose();
    };

    const getDatePickerButtonLabel = (): string => {
        switch (calendarType) {
            case CalendarType.Month: {
                return moment(selectedDate).format('MMMM, YYYY');
            }
            case CalendarType.Week: {
                const weekStart = moment(selectedDate).startOf('week');
                const weekEnd = moment(selectedDate).endOf('week');

                if (weekStart.isSame(weekEnd, 'month')) {
                    return `${weekStart.format('Do')} - ${weekEnd.format('Do MMMM, YYYY')}`;
                } else {
                    return `${weekStart.format('Do MMMM')} - ${weekEnd.format('Do MMMM, YYYY')}`;
                }
            }
            default: {
                return moment(selectedDate).toDate().toLocaleDateString();
            }
        }
    };

    const acceptDate = () => {
        onDateSelected(datePickerDate.format('YYYY-MM-DD'));
        handleClose();
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    return (
    <>
        <section className="full_calendar_toolbar">
            <div>
                <Button className="skip_navigation_button" onClick={onSkipNavigationClick}>{t('skip_navigation_label')}</Button>
                <IconButton
                    onClick={() => setInstructionsDialogOpen(true)} 
                    aria-label={t('calendar_navigation_instructions_button_label')}
                >
                    <InfoOutlined />
                </IconButton>
                <div className="calendar_next_prev_buttons_div">
                    <IconButton
                        id="calendar_previous_period_button"
                        onClick={onPreviousPeriodClick} 
                        aria-label={t('previous_period')}
                    >
                        <NavigateBeforeRounded />
                    </IconButton>
                    <IconButton 
                        onClick={onNextPeriodClick}
                        aria-label={t('next_period')}
                    >
                        <NavigateNextRounded />
                    </IconButton>
                </div>
                <div className="select_calendar_popover_button_div">
                    <Button className="date_picker_button" variant="text" onClick={showDatePicker}>
                        {getDatePickerButtonLabel()}
                        <NavigateNextRounded
                            style={{ transform: open ? 'rotate(-90deg)' : 'rotate(90deg)', transition: 'transform 100ms' }}
                        />
                    </Button>
                    <Popover
                        id={id}
                        open={open}
                        anchorEl={anchorEl}
                        onClose={handleClose}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'center'
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'center'
                        }}
                    >
                        <StaticDatePicker
                            openTo="month"
                            views={['year', 'month', 'day']}
                            value={datePickerDate}
                            onChange={handleDateChange}
                            renderInput={(props) => <TextBox {...(props as any)}/>}
                            // cancelLabel={t('common:cancel')}
                            // okLabel={t('common:ok')}
                        />
                        <Button onClick={handleClose}>{t('common:cancel')}</Button>
                        <Button onClick={acceptDate}>{t('common:ok')}</Button>
                    </Popover>

                    <Button onClick={handleTodayButtonClick}>{t('today')}</Button>
                </div>
            </div>
            <div>
                {
                    canBookAppointments &&
                    <Button startIcon={<AddRounded/>} onClick={onAddAppointmentClick}>
                        {t('add_appointment_label')}
                    </Button>
                }
                <Button startIcon={<AddRounded/>} onClick={onAddUnavailabilityClick}>
                    {t('add_unavailability_label')}
                </Button>
                <ToggleButtonGroup
                    className="month_week_toggle_button"
                    size="small"
                    onChange={handleCalendarTypeChange}
                    exclusive
                    value={calendarType}
                >
                    <ToggleButton className="global__button" value={CalendarType.Week} aria-label="week view">
                        {t('week')}
                    </ToggleButton>
                    <ToggleButton className="global__button" value={CalendarType.Month} aria-label="month view">
                        {t('month')}
                    </ToggleButton>
                </ToggleButtonGroup>
            </div>
        </section>
        <NavigationInstructionsDialog open={instructionsDialogOpen} onClose={() => setInstructionsDialogOpen(false)}/>
    </>
    );
};

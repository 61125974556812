import React, { FC, useState, useEffect } from 'react';
import './DeclineCaseDialog.scss';
import { IconButton, MenuItem, Modal, Alert } from '@mui/material';
import { Trans, useTranslation } from 'react-i18next';
import { useLazyQuery } from '@apollo/client';
import { DECLINE_REASONS_QUERY } from '../../queries/index';
import { useAuth } from 'components/Auth';
import CloseIcon from '@mui/icons-material/Close';
import { Button, Select } from 'components/Shared/Inputs';
import { Link } from 'react-router-dom';
import { Spinner } from 'components/Shared';
import { Card } from 'components/Shared/Card';

interface DeclineCaseDialogProps {
    open: boolean,
    firstName: string,
    lastName: string,
    selectReason: boolean,
    onCancel: () => void,
    onDeclineCase: (reason: number) => Promise<any>
}

export const DeclineCaseDialog: FC<DeclineCaseDialogProps> = (props) => {
    const { onCancel, onDeclineCase, firstName, lastName, open, selectReason } = props;
    const { effectiveProviderData } = useAuth();
    const { t } = useTranslation('caseManagement', { useSuspense: false });
    
    const [declinePending, setDeclinePending] = useState(false);
    const [declineReason, setDeclineReason] = useState<number | undefined>(undefined);

    const [doDeclineReasonsQuery, declineReasonsQuery] = useLazyQuery(DECLINE_REASONS_QUERY, {
        errorPolicy: 'all'
    });

    useEffect(() => {
        open && selectReason && !declineReasonsQuery.called && doDeclineReasonsQuery();
    }, [open]);

    const handleReasonChange = (event) => {
        setDeclineReason(event.target.value ? Number(event.target.value) : undefined);
    }

    const handleDecline = () => {
        setDeclinePending(true);
        declineReason && onDeclineCase(declineReason)
            .then(() => onCancel())
            .finally(() => {
                setDeclinePending(false);
            })
    }

    const disableContinue = selectReason && !Boolean(declineReason) && declinePending;

    return (
        <Modal open={open} onClose={onCancel}className='case_modal'>
            <Card elevation={1} className='case_modal_surface'>
                {
                    declinePending &&
                    <Spinner fillParent backdrop/>
                }
                <div className="case_modal_title_wrapper">
                    <h2>
                        {t('decline_case')}
                    </h2>
                    <IconButton aria-label="close" onClick={onCancel}>
                        <CloseIcon />
                    </IconButton>
                </div>
                <div className="case_modal_content_wrapper">
                {
                    selectReason
                    ? <p>
                        {t('decline_reason')}                                    
                    </p>
                    : <>
                        <p>
                            {t('date_outside_decline', {firstName, lastName})} 
                        </p>
                        <p className="global__paragraph">                                        
                            {t('are_you_sure_you_want_to_decline_the_case')}
                        </p>
                    </>
                }
                {
                    selectReason
                    ? <Select
                        label={t('reason_for_declining_case')}
                        id="decline_reason_dropdown"
                        value={declineReason ?? ''}
                        onChange={handleReasonChange}
                        className="decline_reason_dropdown"
                    >
                    {
                        declineReasonsQuery.data?.appointmentDeclineReasons?.map((reason, index) => (
                            <MenuItem value={reason.id} key={index}> 
                                {reason.description}
                            </MenuItem>
                        ))
                    }    
                    </Select>
                    : <br />
                }
                {
                    selectReason && declineReason === 5 &&
                    <Alert severity="info" className="add_unavailability_message">
                        {
                            effectiveProviderData?.hasManagedCalendar
                            ? <Trans t={t} i18nKey="add_unavailability_reminder_calendar">
                                Remember to add unavailability in <Link to={{pathname: '/profile/availabilities'}}>Provider profile</Link>
                                {effectiveProviderData?.hasManagedCalendar && <> or <Link to={{pathname: '/calendar'}}>Calendar</Link></>} if you haven't done it yet.
                            </Trans>
                            // @ts-expect-error
                            : <Trans t={t} i18nKey="add_unavailability_reminder" tOptions={{context: effectiveProviderData?.hasManagedCalendar ? 'calendar' : ''}}>
                                Remember to add unavailability in <Link to={{pathname: '/profile/availabilities'}}>Provider profile</Link>
                                if you haven't done it yet.
                            </Trans>
                        }
                    </Alert>
                }
                </div>
                <div className="case_modal_actions_wrapper">
                    <Button variant="text" onClick={onCancel}>
                        {t('cancel')}
                    </Button>
                    <Button variant="opaque" disabled={disableContinue} onClick={handleDecline}>
                        {t('continue')}
                    </Button>
                </div>
            </Card>
        </Modal>
    )
}
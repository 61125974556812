import React, { FC } from "react";
import { Dialog, DialogContent, IconButton } from '@mui/material';
import { useTranslation } from "react-i18next";
import CloseIcon from '@mui/icons-material/Close';
import { RequestForm } from '../RequestForm';
import './RequestDialog.scss';

export interface IRequestFormProps {
    activityId: number,
    providerId: number,
    open: boolean,
    onClose: () => void,
    onRequestCreated: () => void
}

export const RequestsDialog: FC<IRequestFormProps> = (props) => {
    const { activityId, providerId,  open, onClose, onRequestCreated } = props;
    const { t } = useTranslation(['requests', 'common'], { useSuspense: false });

    return (
        <Dialog open={open} className="request_hours_modal">
            <DialogContent>
                <div className="request_dialog_header">
                    <h2 className="request_dialog_title">{t('additional_hours_request')}</h2>
                    <IconButton className="request_dialog_close_button" onClick={onClose}><CloseIcon /></IconButton>
                </div>
                <div className="request_hours_modal_content">
                    <RequestForm activityId={activityId} providerId={providerId} onExit={onClose} onRequestCreated={onRequestCreated}/>
                </div>
            </DialogContent>
        </Dialog> 
    )
}

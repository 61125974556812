import React, { FC, useState, useRef, useEffect } from 'react';
import { Alert } from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import { useAuth } from '../AuthProvider';
import { ShowPasswordCheckbox, Spinner } from 'components/Shared';
import { useTranslation } from 'react-i18next';
import { Button, TextBox } from 'components/Shared/Inputs';

interface LoginFormProps { }

export const LoginForm: FC<LoginFormProps> = (_) => {
    const { t, i18n } = useTranslation(['login', 'common'], { useSuspense: false }); 
    const [loading, setLoading] = useState<boolean>(false);
    const navigate = useNavigate();
    const authProvider = useAuth();
    const formRef = useRef(null);

    useEffect(() => {
        formik.validateForm();
    }, [i18n.language]);

    const formik = useFormik({
        initialValues: {
            username: '',
            password: '',
            showPassword: false,
            rememberMe: false,
            graphQL: ''
        },
        validate: (values) => { 
            const errors: any = {};

            if (!values.username) {
                errors.username = t('common:id_is_required', 'Provider ID is required');
            }
            if (!values.password) {
                errors.password = t('common:password_is_required', 'Password is required');
            }

            return errors;
        },
        validateOnBlur: true,
        onSubmit: (values) => {
            formik.setTouched({username: true, password: true});
            formik.validateForm();

            if (formik.isValid) {
                doLogin(values.username, values.password);
            }
        }
    });
    
    const doLogin = (username: string, password: string) => {
        setLoading(true);

        authProvider.login(username, password)
            .then(data => {
                if (data.error){
                    formik.setFieldError('graphQL', data.error);
                    return;
                }
                navigate('/', { replace: true });
            })
            .catch((error) => {
                if (error.graphQLErrors && error.graphQLErrors[0]?.message) {
                    formik.setFieldError('graphQL', error.graphQLErrors[0]?.message);
                } else {
                    formik.setFieldError('graphQL', error.message);
                }
            })
            .finally(() => {
                if (formRef.current) { setLoading(false) }
            });
    }

    const getErrorMessage = (): string | null => {
        if (formik.touched.username && formik.errors.username) {
            return formik.errors.username;
        }
        if (formik.touched.password && formik.errors.password) {
            return formik.errors.password;
        }
        if (formik.errors.graphQL) {
            return formik.errors.graphQL;
        }
        return null;
    }

    return ( 
        <form onSubmit={formik.handleSubmit} ref={formRef} className="login_screen_form">
            <h2 className="login_screen_form_title">{t('login:login_to_your_account', 'Login to your account.')}</h2>
            <TextBox
                id="login_form_login_input"
                onChange={formik.handleChange}
                value={formik.values.username}
                onBlur={formik.handleBlur}
                name="username"
                className="login_screen_form_input"
                label={t('common:provider_ID', 'Provider ID')}
                error={formik.touched.username && !!formik.errors.username}
            />
            <TextBox
                id="login_form_password_input"
                onChange={formik.handleChange}
                value={formik.values.password}
                onBlur={formik.handleBlur}
                name="password"
                className="login_screen_form_input password_input"
                label={t('common:password', 'Password')}
                type={formik.values.showPassword ? "text" : "password"}
                error={formik.touched.password && !!formik.errors.password}
                InputProps={{
                    endAdornment: <ShowPasswordCheckbox name="showPassword" onChange={formik.handleChange} checked={formik.values.showPassword}
                        inputProps={{'aria-label': t('common:show_password'), 'aria-controls': "password_input_field"}}/>
                }}
            />
            {
                getErrorMessage() &&
                <Alert severity="error" className="login_screen_alert">{getErrorMessage()}</Alert>
            }
            <div className="filler" />
            {
                loading
                    ? <Spinner />
                    : <Button type="submit" variant="opaque" size="large" className="login_screen_form_submit_button">{t('common:login','Log In')}</Button>
            }
            <p className="login_screen_form_footer_text"><Link to="/forgot_password">{t('login:forgot_your_password','Forgot your password?')}</Link></p>
        </form>
    );
}

import React, { FC, useEffect, useState } from 'react';
import { useMutation } from '@apollo/client';
import { Identification } from './Identification';
import { UPDATE_NPI_MUTATION } from './queries';
import { useTranslation } from 'react-i18next';

interface IdentificationNPIProps {
    providerId: number,
    NPI?: string,
    onNPIUpdated: (npi: string) => void
}

export const IdentificationNPI : FC<IdentificationNPIProps> = (props) => {
    const { providerId, NPI, onNPIUpdated} = props;
    
    const { t, ready: translationsReady } = useTranslation('providerprofile', { useSuspense: false });

    const [cachePendingUpdate, setCachePendingUpdate] = useState(false);
    
    const [updateNPI, updateNPIMutation] = useMutation(UPDATE_NPI_MUTATION, {
        update: (_, result) => {
            const value = result.data?.updateCredentials?.credential?.nationalProviderIdentifier;
            if (value && value !== NPI) {
                onNPIUpdated(value)
            }
        }
    });

    useEffect(() => {
        setCachePendingUpdate(false);
    }, [NPI]);
    
    const updateFn = (identifier) => updateNPI({variables: { providerId: providerId, nationalProviderIdentifier: identifier }});

    const validator = /^(\d *?){10}$/;

    const labels = {
        sectionHeader: t('identification_npi__section_title'),
        sectionDescription: t('identification_npi__section_description'),
        inputLabel: t('identification_npi__input_label'),
        noData: t('identification_npi__no_data')
    }

    return !translationsReady
        ? <></>
        : <Identification identifier={NPI} updateFn={updateFn} mutation={updateNPIMutation}
            validator={validator} labels={labels} cachePendingUpdate={cachePendingUpdate}/>
}
import React, { FC, useState } from 'react';
import './Settings.scss';
import { Paper, Container, Alert } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useMutation } from '@apollo/client';
import { CHANGE_PASSWORD_MUTATION, ChangePasswordMutationVars } from './queries';
import { useFormik } from 'formik';
import { Spinner, ShowPasswordCheckbox } from 'components/Shared';
import { Button, TextBox } from 'components/Shared/Inputs';

interface SettingsProps {
    providerId: number
}
export const Settings: FC<SettingsProps> = ({providerId}) => {
    const { t } = useTranslation(['providerprofile', 'login'], { useSuspense: false });
    const [passwordChanged, setPasswordChanged] = useState(false);

    const [changePassword, { loading }] = useMutation<{changePassword: boolean}, ChangePasswordMutationVars>(CHANGE_PASSWORD_MUTATION,
        {
            context: {
                skipGlobalErrorHandling: true
            },
            onError: (error) => {
                formik.setFieldError('graphQL', error.message);
            },
            onCompleted: () => {
                setPasswordChanged(true);
            }
        })

    const formik = useFormik({
        initialValues: {
            currentPassword: '',
            passwordA: '',
            passwordB: '',
            showCurrentPassword: false,
            showPasswordA: false,
            showPasswordB: false,
            graphQL: ''
        },
        validate: (values) => { 
            const errors: any = {};

            if (values.passwordA !== values.passwordB) {
                errors.passwordB = t('authentication_error_passwords_mismatch', 'Passwords do not match');
            }
            if (!/^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[*.!@#$%^&(){}[\]:\\;<>,.?/~_+\-=|]).{12,128}$/.test(values.passwordA)) {
                errors.passwordA = t('authentication_error_password_not_compliant', 'Password does not fit requirements');
            }

            return errors;
        },
        validateOnBlur: true,
        onSubmit: (values) => {
            formik.setTouched({passwordA: true, passwordB: true});
            formik.validateForm();

            if (formik.isValid) {
                changePassword({variables: {
                    currentPassword: values.currentPassword,
                    newPassword: values.passwordA,
                    userName: providerId.toString()
                }})
            }
        }
    });

    const getErrorMessage = (): string | null => {
        if (formik.errors.graphQL) {
            return formik.errors.graphQL;
        }
        if (formik.touched.passwordA && formik.touched.passwordB && formik.errors.passwordB) {
            return formik.errors.passwordB;
        }
        if (formik.touched.passwordA && formik.errors.passwordA) {
            return formik.errors.passwordA;
        }

        return null;
    }

    return (
        <Container maxWidth="sm" disableGutters>
            <Paper className='provider_profile_section_container settings_container' variant='outlined' elevation={0}
                component="form" onSubmit={formik.handleSubmit as any}>
                <h2>{t('settings_change_password', 'Change Password')}</h2>
                <p>
                    {t('authentication_password_requirements',
                            'Please note that your password must contain at least 12 alphanumeric characters including a minimum of one uppercase letter, one lowercase letter, one number, and one special character (! @ # $ % ^ & * - or _). For example: Password_123 (please do not use this password)')}
                </p>
                <TextBox
                    onChange={formik.handleChange}
                    value={formik.values.currentPassword}
                    onBlur={formik.handleBlur}
                    error={formik.touched.currentPassword && !!formik.errors.currentPassword}
                    name="currentPassword"
                    className="password_input"
                    label={t('settings_current_password','Current password')}
                    type={formik.values.showCurrentPassword ? "text" : "password"}
                    disabled={passwordChanged}
                    id="current_password_input_field"
                    InputProps={{
                        endAdornment: <ShowPasswordCheckbox name="showCurrentPassword" onChange={formik.handleChange} checked={formik.values.showCurrentPassword}
                            inputProps={{'aria-label': t('common:show_password'), 'aria-controls': "current_password_input_field"}}/>
                    }}
                />
                <TextBox 
                    onChange={formik.handleChange}
                    value={formik.values.passwordA}
                    onBlur={formik.handleBlur}
                    error={formik.touched.passwordA && !!formik.errors.passwordA}
                    name="passwordA"
                    className="password_input"
                    label={t('login:new_password', 'New password')}
                    type={formik.values.showPasswordA ? "text" : "password"}
                    disabled={passwordChanged}
                    id="new_password_input_field"
                    InputProps={{
                        endAdornment: <ShowPasswordCheckbox name="showPasswordA" onChange={formik.handleChange} checked={formik.values.showPasswordA}
                            inputProps={{'aria-label': t('common:show_password'), 'aria-controls': "new_password_input_field"}}/>
                    }}
                />
                <TextBox 
                    onChange={formik.handleChange}
                    value={formik.values.passwordB}
                    onBlur={formik.handleBlur}
                    error={formik.touched.passwordB && !!formik.errors.passwordB}
                    name="passwordB"
                    className="password_input"
                    label={t('login:confirm_new_password', 'Confirm new password')}
                    type={formik.values.showPasswordB ? "text" : "password"}
                    disabled={passwordChanged}
                    id="confirm_new_password_input_field"
                    InputProps={{
                        endAdornment: <ShowPasswordCheckbox name="showPasswordB" onChange={formik.handleChange} checked={formik.values.showPasswordB}
                            inputProps={{'aria-label': t('common:show_password'), 'aria-controls': "confirm_new_password_input_field"}}/>
                    }}
                />
                {
                    getErrorMessage() &&
                    <Alert severity="error" className="update_button">{getErrorMessage()}</Alert>
                }
                {
                    passwordChanged &&
                    <Alert severity="success" className="update_button">
                        {t('settings_password_changed_success_message', 'Password has been successfully changed')}
                    </Alert>
                }
                {
                    loading
                        ? <Spinner />
                        : <Button variant="opaque" type="submit" className="update_button" disabled={passwordChanged}>
                            {t('settings_change_password', 'Change Password')}
                        </Button>
                }
            </Paper>
        </Container>
    )
}
import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { License } from '../../../interfaces';
import { SectionContainer } from '../../Other/SectionContainer';
import { LicenseAndRegistrationsForm } from './LicensesAndRegistrationsForm';
import { useMutation } from '@apollo/client';
import { UPDATE_LICENSES_MUTATION } from './queries';
import { useFeatureManager } from 'hooks/useFeatureManager';

interface LicensesAndRegistrationsProps {
    providerId: number,
    licenses?: License[],
    onLicensesUpdated: (licenses: License[]) => void
}

export const LicensesAndRegistrations: FC<LicensesAndRegistrationsProps> = (props) => {
    const { licenses, providerId, onLicensesUpdated } = props;
    const { t, ready: translationsReady } = useTranslation('providerprofile', { useSuspense: false });

    const { isProviderProfileSelfServeFeatureEnabled } = useFeatureManager();

    const [cachePendingUpdate, setCachePendingUpdate] = useState(false);

    const [updateLicenses, updateLicensesMutation] = useMutation(UPDATE_LICENSES_MUTATION, {
        update: (_, result) => {
            if (result.data?.updateCredentials?.credential?.licenses) {
                setCachePendingUpdate(true);
                onLicensesUpdated(result.data.updateCredentials.credential.licenses);
            }
        }
    });

    useEffect(() => {
        setCachePendingUpdate(false);
    }, [licenses]);

    return !translationsReady
        ? <></>
        : <SectionContainer title={t('licenses_registrations__section_title')}>
            {
                licenses?.map((license, index) => (
                    <LicenseAndRegistrationsForm
                        key={index}
                        providerId={providerId}
                        license={license}
                        licenses={licenses}
                        cachePendingUpdate={cachePendingUpdate}
                        mutation={[updateLicenses, updateLicensesMutation]}
                    />
                ))
            }
            {
                isProviderProfileSelfServeFeatureEnabled() &&
                <LicenseAndRegistrationsForm
                    providerId={providerId}
                    license={undefined}
                    licenses={licenses ?? []}
                    cachePendingUpdate={cachePendingUpdate}
                    mutation={[updateLicenses, updateLicensesMutation]}
                />
            }
        </SectionContainer>
}
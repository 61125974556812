import React, { FC } from 'react';
import { Button } from 'components/Shared/Inputs';
import { Link } from 'react-router-dom';
import { ToDoBase } from '../../interfaces';
import { useTranslation } from 'react-i18next';
import { getToDoLink } from '../../utils';

import './ToDoPrompt.scss';
interface ToDoPromptProps {
    toDo: ToDoBase
}

export const ToDoPrompt: FC<ToDoPromptProps> = (props) => {
    const { toDo } = props;
    const { t, ready: translationsReady } = useTranslation('todos', { useSuspense: false });
    
    return (
        translationsReady
            ? <div>
                <p>
                    {t('next_step','Next Step')}:
                </p>
                <h2>
                {t(`${toDo.step.toString()}-title`, { replace: { clientName: toDo?.activityInfo?.clientName }})}
                </h2>
                {/* {
                    body &&
                    <>
                        <p className="global__paragraph promptCard_global__paragraph">
                            {body}
                        </p>
                    </>
                } */}
                <Button variant="opaque" component={Link} to={{ pathname: getToDoLink(toDo)}} state={{ workflowId: toDo.id, activity: toDo.activityInfo, fromToDo: true }}>
                    {t(`${toDo?.step?.toString()}-button`)}
                </Button>
            </div>
            : <></>
    )
};
import React, { forwardRef } from 'react';
import { DateTimePicker, DateTimePickerProps } from '@mui/x-date-pickers';
import EventRoundedIcon from '@mui/icons-material/EventRounded';
import { Moment } from 'moment-timezone';

import "../TextBox/TextBox.scss";

type DateTimeInputProps = Omit<DateTimePickerProps<Moment>, "inputVariant" | "renderInput"> & {
    error?: boolean,
    helperText?: string,
    onBlur?: React.FocusEventHandler<HTMLInputElement | HTMLTextAreaElement>
};

export const DateTimeInput = forwardRef<HTMLDivElement, DateTimeInputProps>(({ error, className, helperText, onBlur, ...props}, ref) => (
    <DateTimePicker
        slots={{
            openPickerIcon: EventRoundedIcon
        }}
        slotProps={{
            openPickerButton: {
                size: 'small',
                disableRipple: true,
                style: {
                    color: 'inherit'
                }
            },
            textField: { helperText, onBlur, error, className: `${className} ms_textbox` },
            inputAdornment: {
                style: {
                    color: 'inherit'
                }
            }
        }}
        ref={ref}
        {...props}
    />
))
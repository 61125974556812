import React, { forwardRef } from 'react';
import { DatePicker, DatePickerProps } from '@mui/x-date-pickers';
import EventRoundedIcon from '@mui/icons-material/EventRounded';
import { Moment } from 'moment';

import "../TextBox/TextBox.scss";

type DateInputProps = Omit<DatePickerProps<Moment>, "inputVariant" | "renderInput"> & {
    error?: boolean,
    helperText?: string,
    onBlur?: React.FocusEventHandler<HTMLInputElement | HTMLTextAreaElement>
};

export const DateInput = forwardRef<HTMLDivElement, DateInputProps>(({ error, className, helperText, onBlur, ...props}, ref) => (
    <DatePicker
        slots={{
            openPickerIcon: EventRoundedIcon
        }}
        slotProps={{
            openPickerButton: {
                size: 'small',
                disableRipple: true,
                style: {
                    color: 'inherit'
                }
            },
            textField: { helperText, onBlur, error, className: `${className} ms_textbox` },
            inputAdornment: {
                style: {
                    color: 'inherit'
                }
            }
        }}
        ref={ref}
        {...props}
    />
))
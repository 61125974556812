import React, { FC, useEffect, useMemo } from 'react';
import { useSnackbar } from 'notistack';
import { useLazyQuery } from '@apollo/client';
import { STAFF_QUERY } from 'components/Staff/queries';
import { Spinner } from 'components/Shared';
import { useTranslation } from 'react-i18next';
import { GroupAdministrators } from '../../Sections/GroupAdministators';

import './GroupPractice.scss';

interface GroupPracticeProps {
    vendorId: number | undefined,
    vendorName: string | undefined
}

export const GroupPractice: FC<GroupPracticeProps> = (props) => {
    const { t, ready: translationsReady } = useTranslation('providerprofile', { useSuspense: false });
    const { vendorId, vendorName } = props;

    const { enqueueSnackbar } = useSnackbar();

    const [getProviders, staffQuery] = useLazyQuery(STAFF_QUERY);

    useEffect(() => {
        if (vendorId) {
            getProviders({variables: {vendorId: vendorId}});
        } else {
            enqueueSnackbar(t('invalid_vendor_id'), { variant: 'error' });
        }
    }, [vendorId]);

    const groupAdministrators = useMemo(() => (
        staffQuery.data?.providers?.filter(provider => provider.isAdmin)
    ), [staffQuery.data?.providers]);

    const loading = !translationsReady || staffQuery.loading;

    return loading
        ? <Spinner fillParent/>
        : <>
            <h2>{vendorName}</h2>
            <GroupAdministrators administrators={groupAdministrators}/>
        </>
}

import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { CREDENTIALING_QUERY } from './queries';
import { useQuery, useApolloClient } from '@apollo/client';
import { Spinner } from 'components/Shared';
import { Education } from '../../Sections/Education';
import { IdentificationABN, IdentificationNPI } from '../../Sections/Identification';
import { LiabilityInsurance } from '../../Sections/LiabilityInsurance';
import { ProfessionalDesignation } from '../../Sections/ProfessionalDesignation';
import { LicensesAndRegistrations } from '../../Sections/LicensesAndRegistrations';
import { PreviousExperience } from '../../Sections/PreviousExperience';
import { ProfessionalDevelopment } from '../../Sections/ProfessionalDevelopment';
import { Specializations } from '../../Sections/Specializations';
import './Credentialing.scss';
import { LiabilityInsurance as ILiabilityInsurance } from '../../../interfaces';

interface CredentialingProps {
    providerId: number
}

export const Credentialing: FC<CredentialingProps> = ({providerId}) => {
    const { ready: translationsReady } = useTranslation('providerprofile', { useSuspense: false });

    const { cache } = useApolloClient();

    const credentialingQuery = useQuery(CREDENTIALING_QUERY, {
        fetchPolicy: 'network-only',
        variables: { providerId: providerId },
        errorPolicy: 'all'
    });

    const handleEducationsUpdated = (educations) => {
        cache.writeQuery({
            query: CREDENTIALING_QUERY,
            variables: { providerId: providerId },
            data: {
                ...credentialingQuery.data,
                provider: {
                    ...credentialingQuery.data?.provider,
                    credential: {
                        ...credentialingQuery.data?.provider.credential,
                        educations: educations
                    }
            }}
        })
    }
    
    const handleLicensesUpdated = (licenses) => {
        cache.writeQuery({
            query: CREDENTIALING_QUERY,
            variables: { providerId: providerId },
            data: {
                ...credentialingQuery.data,
                provider: {
                    ...credentialingQuery.data?.provider,
                    credential: {
                        ...credentialingQuery.data?.provider.credential,
                        licenses: licenses
                    }
            }}
        })
    }
    
    const handleABNUpdated = (abn) => {
        cache.writeQuery({
            query: CREDENTIALING_QUERY,
            variables: { providerId: providerId },
            data: {
                ...credentialingQuery.data,
                provider: {
                    ...credentialingQuery.data?.provider,
                    credential: {
                        ...credentialingQuery.data?.provider.credential,
                        businessNumber: abn
                    }
            }}
        })
    }

    const handleNPIUpdated = (npi) => {
        cache.writeQuery({
            query: CREDENTIALING_QUERY,
            variables: { providerId: providerId },
            data: {
                ...credentialingQuery.data,
                provider: {
                    ...credentialingQuery.data?.provider,
                    credential: {
                        ...credentialingQuery.data?.provider.credential,
                        nationalProviderIdentifier: npi
                    }
            }}
        })
    }

    const handlePreviousExperienceUpdated = (previousExperience) => {
        cache.writeQuery({
            query: CREDENTIALING_QUERY,
            variables: { providerId: providerId },
            data: {
                ...credentialingQuery.data,
                provider: {
                    ...credentialingQuery.data?.provider,
                    credential: {
                        ...credentialingQuery.data?.provider.credential,
                        previousExperience: previousExperience
                    }
            }}
        })
    }
    
    const handleProfessionalDevelopmentUpdated = (professionalDevelopment) => {
        cache.writeQuery({
            query: CREDENTIALING_QUERY,
            variables: { providerId: providerId },
            data: {
                ...credentialingQuery.data,
                provider: {
                    ...credentialingQuery.data?.provider,
                    credential: {
                        ...credentialingQuery.data?.provider.credential,
                        professionalDevelopment: professionalDevelopment
                    }
            }}
        })
    }
    
    const handleProfessionalDesignationUpdated = (professionalDesignation) => {
        cache.writeQuery({
            query: CREDENTIALING_QUERY,
            variables: { providerId: providerId },
            data: {
                ...credentialingQuery.data,
                provider: {
                    ...credentialingQuery.data?.provider,
                    credential: {
                        ...credentialingQuery.data?.provider.credential,
                        professionalDesignation: professionalDesignation
                    }
            }}
        })
    }

    const handleSpecializationsUpdated = (specializations) => {
        cache.writeQuery({
            query: CREDENTIALING_QUERY,
            variables: { providerId: providerId },
            data: {
                ...credentialingQuery.data,
                provider: {
                    ...credentialingQuery.data?.provider,
                    credential: {
                        ...credentialingQuery.data?.provider.credential,
                        specializations: specializations
                    }
            }}
        })
    }

    const handleLiabilityInsuranceUpdated = (liabilityInsurance: ILiabilityInsurance) => {
        cache.writeQuery({
            query: CREDENTIALING_QUERY,
            variables: { providerId: providerId },
            data: {
                ...credentialingQuery.data,
                provider: {
                    ...(credentialingQuery.data?.provider as any),
                    liabilityInsurance: liabilityInsurance
            }}
        })
    }

    const loading = !translationsReady || credentialingQuery.loading;

    const showNPI = credentialingQuery.data?.provider?.credential?.showNationalProviderIdentifier ?? false;
    const showABN = credentialingQuery.data?.provider?.credential?.showBusinessNumber ?? false;

    const educations = credentialingQuery.data?.provider?.credential?.educations;
    const licenses = credentialingQuery.data?.provider?.credential?.licenses;
    const previousExperience = credentialingQuery.data?.provider?.credential?.previousExperience;
    const professionalDevelopment = credentialingQuery.data?.provider?.credential?.professionalDevelopment;
    const professionalDesignation = credentialingQuery.data?.provider?.credential?.professionalDesignation;
    const nationalProviderIdentifier = credentialingQuery.data?.provider?.credential?.nationalProviderIdentifier ?? undefined;
    const businessNumber = credentialingQuery.data?.provider?.credential?.businessNumber ?? undefined;
    const specializations = credentialingQuery.data?.provider?.credential?.specializations;
    const liabilityInsurance = credentialingQuery.data?.provider?.liabilityInsurance;

    return loading
        ? <Spinner fillParent/>
        : <>
            {
                showABN &&
                <IdentificationABN providerId={providerId} ABN={businessNumber} onABNUpdated={handleABNUpdated}/>
            }
            {
                showNPI &&
                <IdentificationNPI providerId={providerId} NPI={nationalProviderIdentifier} onNPIUpdated={handleNPIUpdated}/>
            }
            <LiabilityInsurance providerId={providerId} liabilityInsurance={liabilityInsurance} onUpdated={handleLiabilityInsuranceUpdated}/>
            <Education providerId={providerId} educations={educations} onEducationsUpdated={handleEducationsUpdated}/>
            <Specializations providerId={providerId} specializations={specializations} onSpecializationsUpdated={handleSpecializationsUpdated}/>
            <LicensesAndRegistrations providerId={providerId} licenses={licenses} onLicensesUpdated={handleLicensesUpdated}/>
            <ProfessionalDesignation providerId={providerId} professionalDesignations={professionalDesignation} onProfessionalDesignationsUpdated={handleProfessionalDesignationUpdated}/>
            <PreviousExperience providerId={providerId} previousExperience={previousExperience} onPreviousExperienceUpdated={handlePreviousExperienceUpdated}/>
            <ProfessionalDevelopment providerId={providerId} professionalDevelopment={professionalDevelopment} onProfessionalDevelopmentUpdated={handleProfessionalDevelopmentUpdated}/>
        </>
}
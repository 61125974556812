import React from 'react';
import { ArrowBackRounded as ArrowBack, ArrowForwardRounded as ArrowForward } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';

import './JumbotronPager.scss';
import { Button } from 'components/Shared/Inputs';

interface JumbotronPagerProps {
    index: number,
    total: number,
    onChange: (index: number) => void
}


export const JumbotronPager: React.FC<JumbotronPagerProps> = (props) => {
    const { index, total, onChange } = props;

    const { t } = useTranslation('dashboard', { useSuspense: false });

    return (
        <div className="jumbotron_pager_container">
            <Button variant="opaque" round aria-label={t('previous_slide')}
                disabled={index <= 0} onClick={() => onChange(index - 1)}
            >
                <ArrowBack/>
            </Button>
            <div className="pager">
                {
                    [...Array(total)].map((val, idx) => (<div className={clsx("page_indicator", idx === index && "active")} key={idx}/>))
                }
            </div>
            <Button variant="opaque" round aria-label={t('next_slide')}
                disabled={index >= total - 1} onClick={() => onChange(index + 1)}
            >
                <ArrowForward/>
            </Button>
        </div>
    )
}
import React, { FC, PropsWithChildren } from 'react';
import { Container } from '@mui/material';

import './FullScreenMessage.scss';

interface FullScreenMessageProps extends PropsWithChildren {
    title: string
}

export const FullScreenMessage: FC<FullScreenMessageProps> = (props) => {
    const { title, children } = props;

    return (
        <Container maxWidth="sm" disableGutters className="full_screen_message_container" role="alert" aria-live="polite">
            <h1>{title}</h1>
            {children}
        </Container>
    )
}
import { gql, TypedDocumentNode } from '@apollo/client';
import { StaffTableItem } from './interfaces';

export const STAFF_QUERY: TypedDocumentNode<StaffQueryData, StaffQueryVars> = gql`
    query STAFF_QUERY($vendorId: Long!) {
        providers(vendorId: $vendorId) {
            providerId
            emailAddress
            firstName
            lastName
            isAdmin
            preferredPhone {
                phoneNumber
                phoneType
            }
        }
    }
`

export interface StaffQueryVars {
    vendorId: number
}

export interface StaffQueryData {
    providers: StaffTableItem[]
}

import React, { FC, useMemo } from "react";
import { FormikErrors, useFormik } from 'formik';
import { Container, Radio, Alert } from "@mui/material";
import { Button } from 'components/Shared/Inputs/Buttons';
import { useTranslation } from "react-i18next";

interface DAST_Props {
    onSubmit: (values: DAST_Result) => void,
    result: DAST_FormValues | null
}

interface DAST_FormValues {
    used_drugs_not_for_medical_reasons: boolean | undefined,
    abused_prescription_drugs: boolean | undefined,
    abused_more_than_one_drug: boolean | undefined,
    get_through_week_without_drugs: boolean | undefined,
    able_to_stop_using_drugs: boolean | undefined,
    had_blackouts_or_flashbacks: boolean | undefined,
    feel_bad_over_drug_use: boolean | undefined,
    family_complains: boolean | undefined,
    family_problems: boolean | undefined,
    lost_friends: boolean | undefined
}

interface DAST_Result extends DAST_FormValues {
    score: number
}

export const DAST: FC<DAST_Props> = (props) => {
    const { result, onSubmit } = props;

    const { t, ready } = useTranslation(['forms', 'common'], { useSuspense: false });

    const calculateScore = (values: DAST_FormValues) => (
        Object.keys(values).reduce((score, question) => {
            if (question === 'get_through_week_without_drugs' || question === 'able_to_stop_using_drugs') {
                if (values[question] === false) {
                    score += 1;
                }
            } else if (values[question] === true) {
                score += 1;
            }
            return score;
        }, 0)
    )
    
    const formik = useFormik<DAST_FormValues>({
        initialValues: {
            used_drugs_not_for_medical_reasons: result?.used_drugs_not_for_medical_reasons,
            abused_prescription_drugs: result?.abused_prescription_drugs,
            abused_more_than_one_drug: result?.abused_more_than_one_drug,
            get_through_week_without_drugs: result?.get_through_week_without_drugs,
            able_to_stop_using_drugs: result?.able_to_stop_using_drugs,
            had_blackouts_or_flashbacks: result?.had_blackouts_or_flashbacks,
            feel_bad_over_drug_use: result?.feel_bad_over_drug_use,
            family_complains: result?.family_complains,
            family_problems: result?.family_problems,
            lost_friends: result?.lost_friends
        },
        validate: (values) => {
            const errors: FormikErrors<DAST_FormValues> = {};

            for (const key in values) {
                if (values[key] === undefined) {
                    errors[key] = 'Select answer'
                }
            }

            return errors;
        },
        validateOnMount: true,
        validateOnChange: true,
        onSubmit: (values) => {
            onSubmit({
                ...values,
                score: calculateScore(values)
            });
        }
    });

    const renderQuestion = (number: number, label: string, value: boolean | undefined, onChange: (value: boolean) => void) => (
        <fieldset className="form-row" role="radiogroup">
            <legend><div className="question_number">{`${number}.`}</div><div>{label}</div></legend>
            <div className="radio-cell">
                <Radio name={`question_${number}`} value={true} aria-checked={value === true} checked={value === true} color="primary"
                    onChange={(_, checked) => {checked && onChange(true)}} inputProps={{ 'aria-label': t('common:yes') }}
                />
            </div>
            <div className="radio-cell">
                <Radio name={`question_${number}`} value={false} aria-checked={value === false} checked={value === false} color="primary"
                    onChange={(_, checked) => checked && onChange(false)} inputProps={{ 'aria-label': t('common:no') }}
                />
            </div>
        </fieldset>
    )

    const score = useMemo(() => {
        if (!formik.isValid) {
            return undefined;
        } else {
            return calculateScore(formik.values);
        }
    }, [formik.isValid, formik.values]);

    return !ready
        ? <></>
        : <Container maxWidth="md" component="form" onSubmit={formik.handleSubmit} className="assessment_container dast_assessment">
            <h1 className="assessment_name">
                {t('DAST__title')}
            </h1>
            <div className="questions_matrix_container">
                <div className="questions_header">
                    <div className="question-cell">{t('DAST__questions_description')}</div>
                    <div className="radio-cell">{t('common:yes')}</div>
                    <div className="radio-cell">{t('common:no')}</div>
                </div>
                {
                    renderQuestion(1, t('DAST__question__used_drugs_not_for_medical_reasons'),
                        formik.values.used_drugs_not_for_medical_reasons,
                        (value) => formik.setFieldValue('used_drugs_not_for_medical_reasons', value))
                }
                {
                    renderQuestion(2, t('DAST__question__abused_prescription_drugs'),
                        formik.values.abused_prescription_drugs,
                        (value) => formik.setFieldValue('abused_prescription_drugs', value))
                }
                {
                    renderQuestion(3, t('DAST__question__abused_more_than_one_drug'),
                        formik.values.abused_more_than_one_drug,
                        (value) => formik.setFieldValue('abused_more_than_one_drug', value))
                }
                {
                    renderQuestion(4, t('DAST__question__get_through_week_without_drugs'),
                        formik.values.get_through_week_without_drugs,
                        (value) => formik.setFieldValue('get_through_week_without_drugs', value))
                }
                {
                    renderQuestion(5, t('DAST__question__able_to_stop_using_drugs'),
                        formik.values.able_to_stop_using_drugs,
                        (value) => formik.setFieldValue('able_to_stop_using_drugs', value))
                }
                {
                    renderQuestion(6, t('DAST__question__had_blackouts_or_flashbacks'),
                        formik.values.had_blackouts_or_flashbacks,
                        (value) => formik.setFieldValue('had_blackouts_or_flashbacks', value))
                }
                {
                    renderQuestion(7, t('DAST__question__feel_bad_over_drug_use'),
                        formik.values.feel_bad_over_drug_use,
                        (value) => formik.setFieldValue('feel_bad_over_drug_use', value))
                }
                {
                    renderQuestion(8, t('DAST__question__family_complains'),
                        formik.values.family_complains,
                        (value) => formik.setFieldValue('family_complains', value))
                }
                {
                    renderQuestion(9, t('DAST__question__family_problems'),
                        formik.values.family_problems,
                        (value) => formik.setFieldValue('family_problems', value))
                }
                {
                    renderQuestion(10, t('DAST__question__lost_friends'),
                        formik.values.lost_friends,
                        (value) => formik.setFieldValue('lost_friends', value))
                }
            </div>
            {
                score !== undefined && (
                    score <= 2
                        ? <Alert severity="info" className="summary">
                            {t('DAST__score_label__severity_0', { replace: { score: score }})}
                        </Alert>
                        : <Alert severity="warning" className="summary">
                            {t('DAST__score_label__severity_1', { replace: { score: score }})}
                        </Alert>
                )
            }
            <div className="buttons_container">
                <Button type="submit" disabled={!formik.isValid}>
                    {t('common:submit')}
                </Button>
            </div>
        </Container>
}
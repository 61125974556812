export const CA_TIMEZONES = [
    'America/Atikokan',
    'America/Blanc-Sablon',
    'America/Cambridge_Bay',
    'America/Coral_Harbour',
    'America/Creston',
    'America/Dawson',
    'America/Dawson_Creek',
    'America/Edmonton',
    'America/Fort_Nelson',
    'America/Glace_Bay',
    'America/Goose_Bay',
    'America/Halifax',
    'America/Inuvik',
    'America/Iqaluit',
    'America/Moncton',
    'America/Montreal',
    'America/Nipigon',
    'America/Pangnirtung',
    'America/Rainy_River',
    'America/Rankin_Inlet',
    'America/Regina',
    'America/Resolute',
    'America/St_Johns',
    'America/Swift_Current',
    'America/Thunder_Bay',
    'America/Toronto',
    'America/Vancouver',
    'America/Whitehorse',
    'America/Winnipeg',
    'America/Yellowknife',
    'Canada/Atlantic',
    'Canada/Central',
    'Canada/Eastern',
    'Canada/Mountain',
    'Canada/Newfoundland',
    'Canada/Pacific',
    'Canada/Saskatchewan',
    'Canada/Yukon',
]

export const US_TIMEZONES = [
    'America/Adak',
    'America/Anchorage',
    'America/Atka',
    'America/Boise',
    'America/Chicago',
    'America/Denver',
    'America/Detroit',
    'America/Fort_Wayne',
    'America/Indiana/Indianapolis',
    'America/Indiana/Knox',
    'America/Indiana/Marengo',
    'America/Indiana/Petersburg',
    'America/Indiana/Tell_City',
    'America/Indiana/Vevay',
    'America/Indiana/Vincennes',
    'America/Indiana/Winamac',
    'America/Indianapolis',
    'America/Juneau',
    'America/Kentucky/Louisville',
    'America/Kentucky/Monticello',
    'America/Knox_IN',
    'America/Los_Angeles',
    'America/Louisville',
    'America/Menominee',
    'America/Metlakatla',
    'America/New_York',
    'America/Nome',
    'America/North_Dakota/Beulah',
    'America/North_Dakota/Center',
    'America/North_Dakota/New_Salem',
    'America/Phoenix',
    'America/Shiprock',
    'America/Sitka',
    'America/Yakutat',
    'Navajo',
    'Pacific/Honolulu',
    'US/Alaska',
    'US/Aleutian',
    'US/Arizona',
    'US/Central',
    'US/East-Indiana',
    'US/Eastern',
    'US/Hawaii',
    'US/Indiana-Starke',
    'US/Michigan',
    'US/Mountain',
    'US/Pacific'
]

export const AU_TIMEZONES = [
    'Antarctica/Macquarie',
    'Australia/ACT',
    'Australia/Adelaide',
    'Australia/Brisbane',
    'Australia/Broken_Hill',
    'Australia/Canberra',
    'Australia/Currie',
    'Australia/Darwin',
    'Australia/Eucla',
    'Australia/Hobart',
    'Australia/LHI',
    'Australia/Lindeman',
    'Australia/Lord_Howe',
    'Australia/Melbourne',
    'Australia/North',
    'Australia/NSW',
    'Australia/Perth',
    'Australia/Queensland',
    'Australia/South',
    'Australia/Sydney',
    'Australia/Tasmania',
    'Australia/Victoria',
    'Australia/West',
    'Australia/Yancowinna'
]

export const UK_TIMEZONES = [
    'Europe/Belfast',
    'Europe/London',
    'GB',
    'GB-Eire'
]

export enum COUNTRY_CODE {
    AUSTRALIA = "au",
    CANADA = "ca",
    UK = "uk",
    USA = "us",
}

export const regionsToShowPreferredNumber = [
    COUNTRY_CODE.CANADA
]

export const REGION_HOSTNAMES = {
    'localhost': {
        regionCode: COUNTRY_CODE.CANADA,
        environment: 'local'
    },
    'caqaproviderhub.morneaushepell.com': {
        regionCode: COUNTRY_CODE.CANADA,
        environment: 'qa'
    },
    'cauat.theproviderhub.com': {
        regionCode: COUNTRY_CODE.CANADA,
        environment: 'uat'
    },
    'ca.theproviderhub.com': {
        regionCode: COUNTRY_CODE.CANADA,
        environment: 'prod'
    },
    'usqaproviderhub.morneaushepell.com': {
        regionCode: COUNTRY_CODE.USA,
        environment: 'qa'
    },
    'usuat.theproviderhub.com': {
        regionCode: COUNTRY_CODE.USA,
        environment: 'uat'
    },
    'us.theproviderhub.com': {
        regionCode: COUNTRY_CODE.USA,
        environment: 'prod'
    },
    'ukqaproviderhub.morneaushepell.com': {
        regionCode: COUNTRY_CODE.UK,
        environment: 'qa'
    },
    'ukuat.theproviderhub.com': {
        regionCode: COUNTRY_CODE.UK,
        environment: 'uat'
    },
    'uk.theproviderhub.com': {
        regionCode: COUNTRY_CODE.UK,
        environment: 'prod'
    },
    'auqaproviderhub.morneaushepell.com': {
        regionCode: COUNTRY_CODE.AUSTRALIA,
        environment: 'qa'
    },
    'auuat.theproviderhub.com': {
        regionCode: COUNTRY_CODE.AUSTRALIA,
        environment: 'uat'
    },
    'au.theproviderhub.com': {
        regionCode: COUNTRY_CODE.AUSTRALIA,
        environment: 'prod'
    },
}

export const getAppRegionCode = (): COUNTRY_CODE => {
    if (typeof window !== 'undefined') {
        const { hostname } = window.location
        return REGION_HOSTNAMES[hostname]?.regionCode || '' as COUNTRY_CODE
    }
    return '' as COUNTRY_CODE
}

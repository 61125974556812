import { useAuth } from 'components/Auth';
import { BookApptStep2Staff } from 'components/Calendaring/AppointmentModals/BookAppt/BookApptStep2';
import { PageMetadata } from 'components/Shared/PageMetadata';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { ScheduleNextOfflineSession } from '../components/ScheduleNextOfflineSession';

interface ScheduleNextSessionViewProps {}

export const ScheduleNextSessionView: FC<ScheduleNextSessionViewProps> = () => {
    const { t, ready: translationsReady } = useTranslation('caseManagement', { useSuspense: false });

    const { activityId: activityIdString } = useParams<{activityId: string}>();
    const activityId = parseInt(activityIdString || '');

    const { effectiveProviderData } = useAuth();

    const isFullTimeStaff = effectiveProviderData?.employmentTypeId === 1 || effectiveProviderData?.employmentTypeId === 2;

    return (
        <React.Fragment>
            { 
                translationsReady && 
                <PageMetadata 
                    pageDescription={t('next_appt_page_description')} 
                    pageTitle={t('next_appt_page_title')}
                /> 
            }
            {
                isFullTimeStaff
                    ? <BookApptStep2Staff entryRoute={`/clients/${activityId}`} activityId={activityId}/>
                    : <ScheduleNextOfflineSession activityId={activityId} providerId={effectiveProviderData!.providerId}/>
            }
        </React.Fragment>
    )
}
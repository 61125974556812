import * as Moment from 'moment-timezone';
import { extendMoment } from 'moment-range';
import React, { FC, useEffect, useMemo, useState } from 'react';
import { FilterOptions, DatePreference } from '../../interfaces';
import { groupSlotsByDate } from '../../utils';
import { ScheduleSlot } from 'components/Calendaring/AppointmentModals/interfaces';
import { useTranslation } from 'react-i18next';
import { IconButton } from '@mui/material';
import { ArrowBackIos, ArrowForwardIos } from '@mui/icons-material';

const moment = extendMoment(Moment);

interface ScheduleSlotPickerProps {
    displayedTimeZone: string,
    scheduleSlots: ScheduleSlot[] | undefined,
    filters: FilterOptions,
    selectedSlot: ScheduleSlot | null,
    onScheduleSlotSelected: (scheduleSlot: ScheduleSlot) => void
}

export const ScheduleSlotPicker: FC<ScheduleSlotPickerProps> = (props) => {
    const { scheduleSlots, selectedSlot, displayedTimeZone, filters, onScheduleSlotSelected } = props;

    const { t } = useTranslation();

    const [shownRange, setShownRange] = useState(moment.range(moment(), moment()));

    useEffect(() => {
        const start = moment.tz(filters.startDate, 'YYYYMMDD', displayedTimeZone);
        switch (filters.datePreference) {
            default:
            case DatePreference.on:
                const range = moment.range(
                    start.clone().startOf('day'),
                    start.clone().endOf('day')
                );
                setShownRange(range);
                break;
            case DatePreference.any:
            case DatePreference.bef:
            case DatePreference.aft: {
                const range = moment.range(
                    start.clone().startOf('month'),
                    start.clone().endOf('month')
                );
                setShownRange(range);
                break;
            }
        }
    }, [filters.startDate, filters.datePreference, displayedTimeZone]);

    const sortedScheduleSlots = useMemo(() => (
        scheduleSlots
            ?.sort((a, b) => a.startDate.unix() - b.startDate.unix())
    ), [scheduleSlots]);

    const filteredSlots = useMemo(() => (
        sortedScheduleSlots?.filter(slot => shownRange.contains(slot.startDate))
    ), [sortedScheduleSlots, shownRange]);

    const groupedSlots = useMemo(() => (
        groupSlotsByDate(filteredSlots, displayedTimeZone)
    ), [filteredSlots, displayedTimeZone]);

    const handlePreviusMonthClick = () => {
        const newStart = shownRange.start.clone().subtract(1, 'month');
        setShownRange(moment.range(newStart, newStart.clone().endOf('month')));
    }

    const handleNextMonthClick = () => {
        const newStart = shownRange.start.clone().add(1, 'month');
        setShownRange(moment.range(newStart, newStart.clone().endOf('month')));
    }

    const renderScheduleBodyContent = () => {
        // if (props.clientHasNoExistingSessions) {
        //     return (
        //         <Alert severity="warning">
        //             {t('no_session_warning')}
        //         </Alert>
        //     )
        // }
        // if (props.clientHasNoMinimumHours) {
        //     return (
        //         <Alert severity="warning">
        //             <p>{t("book_appt_no_available_hours")} {props?.activity?.sessionInfo?.modelId === MODEL_18 && 
        //                 t("request_additional_hours")}</p>
        //         </Alert>
        //     )
        // }
        return (
            groupedSlots?.map((date, index) => (
                <div className="day-time-block" key={index} >
                    <h3 className="month">{date.date.format('dddd, MMMM Do')}</h3>
                    {
                        date.slots.map((slot, index) => (
                            <span key={index} className={`time-block${slot === selectedSlot ? ' selected': ''}`}
                                onClick={() => onScheduleSlotSelected(slot)}
                            >
                                {slot.startDate.clone().tz(displayedTimeZone).format('LT')} {slot.isBackToBack ? t('back_to_back') : null}
                            </span>
                        ))
                    }
                </div>
            ))
        )
    }

    return (
        <div className="step2-appt-date schedule_slot_picker">
            <div className="date-filter-row">
                <div className="date">
                    <h2>
                        {shownRange.start.format('MMM YYYY')}
                    </h2>
                    <IconButton disabled={shownRange.contains(moment())} onClick={handlePreviusMonthClick}>
                        <ArrowBackIos className="arrow-back-icon"/>
                    </IconButton>
                    <IconButton onClick={handleNextMonthClick}>
                        <ArrowForwardIos className="arrow-forward-icon"/>
                    </IconButton>
                </div>
            </div>
            <p>{t('using_client_timezone')}: {displayedTimeZone}</p>
            {renderScheduleBodyContent()}
        </div>
    )
}
import React, { useState, FC } from 'react';
import { UnavailabilitiesList } from './UnavailabilitiesList';
import { useTranslation } from 'react-i18next';
import { useQuery } from '@apollo/client';
import { UNAVAILABILITY_QUERY, UnavailabilityData } from 'components/Calendaring/Unavailabilities/queries';
import './Unavailabilities.scss';
import { Spinner } from 'components/Shared';
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import { UnavailabilityForm, Unavailability, useSubmitUnavailability, useDeleteUnavailability } from 'components/Calendaring/Unavailabilities';
import { useIntercessionWspTaskCodes, useWspTaskCodes } from 'hooks/useWspTaskCodes'
import { inferStartAndEndTimes } from 'components/Calendaring/Unavailabilities/utils';
import { useProviderActivities, ActivitySolutionCodes } from 'hooks/useProviderActivities';
import { ACTIVE_STATUS_CODE as ACTIVITY_ACTIVE_STATUS_CODE } from 'constants/activityStatus'
import { Card } from 'components/Shared/Card';
import { Button } from 'components/Shared/Inputs';

interface UnavailabilitiesProps {
    providerId: number,
    managedCalendar: boolean
}

export const Unavailabilities: FC<UnavailabilitiesProps> = ({ providerId, managedCalendar }) => {
    const { t } = useTranslation('providerprofile', { useSuspense: false });
    const [addUnavailabilityButtonClicked, setAddUnavailabilityButtonClicked] = useState<boolean>(false);

    const { data, loading, refetch } = useQuery(
        UNAVAILABILITY_QUERY,
        {
            errorPolicy: 'all',
            variables: { providerId },
            fetchPolicy: 'network-only'
        }
    );

    // TODO: I'm leaving the original WSP tasks here as well
    // in case the requirements change and we need them in the future. 
    // Meanwhile, there's no harm in retrieving them here.

    const wspTaskCodes = useWspTaskCodes()
    const intercessionWspTasks = useIntercessionWspTaskCodes()
    const { allClients } = useProviderActivities(providerId)

    const activeWspClients = allClients?.filter((activity) => activity.activityStatusCode === ACTIVITY_ACTIVE_STATUS_CODE)
        .filter((activity) => activity.solutionCode === ActivitySolutionCodes.ECS)


    const getRecurringDays = (unavailability: UnavailabilityData) => {
        const unavailabilityRecurringDays: number[] = [];
        if (unavailability.shifts && unavailability.shifts.length) {
            for (const shift of unavailability.shifts) {
                if (unavailabilityRecurringDays.indexOf(shift.startDay) === -1) {
                    unavailabilityRecurringDays.push(shift.startDay);
                }
            }
        }

        return unavailabilityRecurringDays.sort();
    }

    const unavailabilities: Unavailability[] | undefined =
        data?.schedule?.unavailabilities?.map((unavailability) => ({
            ...unavailability,
            scheduleId: unavailability.scheduleId,
            seriesId: unavailability.seriesId,
            startDate: new Date(unavailability.startDate),
            endDate: unavailability.endDate ? new Date(unavailability.endDate) : undefined,
            reason: data?.schedule?.unavailabilityReasons?.find(reason => reason.id === unavailability.unavailabilityReasonID),
            recurring: !unavailability.oneTime,
            recurringDays: getRecurringDays(unavailability),
            ...inferStartAndEndTimes(unavailability),
        })
    );

    const pendingUnavailabilities = unavailabilities?.filter(isNonExpiredUnavailability);

    const addUnavailabilityButtonHandler = () => setAddUnavailabilityButtonClicked(true);

    const submitUnavailability = useSubmitUnavailability();
    const handleUnavailabilitySubmit = (unavailability: Unavailability) => {
        return submitUnavailability(unavailability)
            .then((result) => {
                setAddUnavailabilityButtonClicked(false);
                refetch();
                return result;
            })
    }

    const deleteUnavailability = useDeleteUnavailability();
    const handleDeleteUnavailability = (unavailability: Unavailability) => {
        return deleteUnavailability(unavailability)
            .then((result) => {
                refetch();
                return result;
            })
    }

    return (
        <div className='unavailability_tab_view_wrapper'>
            <Card className='provider_profile_section_container' elevation={1}>
                {
                    loading
                        ? <div className='unavailabilities_loading_container'><Spinner/></div>
                        : <React.Fragment>
                            <h2 className="my_availabilities_title">{t('availability_header')}</h2>
                            <p className="my_availabilities_description">{t('unavailability_desc')}</p>
                            {
                                unavailabilities && data?.schedule?.unavailabilityReasons &&
                                <UnavailabilitiesList 
                                    unavailabilities={pendingUnavailabilities}
                                    unavailabilityReasons={data.schedule.unavailabilityReasons}
                                    managedCalendar={managedCalendar}
                                    addresses={data?.schedule.addresses}
                                    onSubmit={handleUnavailabilitySubmit}
                                    wspClients={activeWspClients}
                                    wspTaskCodes={wspTaskCodes}
                                    intercessionWspTasks={intercessionWspTasks}
                                    onDelete={handleDeleteUnavailability} />
                            }
                            {
                                addUnavailabilityButtonClicked
                                ? <div className='unavailability_entry_container'>
                                    <UnavailabilityForm
                                        unavailabilityReasons={data?.schedule.unavailabilityReasons}
                                        pendingUnavailabilities={pendingUnavailabilities}
                                        managedCalendar={managedCalendar}
                                        addresses={data?.schedule.addresses}
                                        onSubmit={handleUnavailabilitySubmit}
                                        wspClients={activeWspClients}
                                        wspTaskCodes={wspTaskCodes}
                                        intercessionWspTasks={intercessionWspTasks}
                                        onCancel={() => setAddUnavailabilityButtonClicked(false)}/>
                                </div>
                                : <Button
                                    variant="outline"
                                    aria-label={t('add_unavailability')}
                                    className=" add_unavailability"
                                    onClick={addUnavailabilityButtonHandler}
                                    startIcon={<AddRoundedIcon/>}
                                >
                                    {t('unavailability')}
                                </Button>
                            }
                        </React.Fragment>
                }
            </Card>
        </div>
    )
}

function isNonExpiredUnavailability (unavailability: Unavailability) {
    const startDate = unavailability.startDate;
    const endDate = unavailability.endDate ?? unavailability.startDate;
    const currentDate = new Date();
    if (startDate && (startDate.getTime() >= currentDate.getTime())) {
        return true;
    }
    if (startDate && endDate && (startDate < currentDate && (endDate.getTime() >= currentDate.getTime()))) {
        return true;
    }
    return false;
}

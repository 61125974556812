import React, { FC } from 'react'
import './Logo.scss';
import clsx from 'clsx';

interface LogoSmallProps {
    className?: string
}

export const LogoSmall: FC<LogoSmallProps> = ({className}) => (
    <div className={clsx('logos_wrapper', className)}>
        <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M35.2748 22.4066C28.2688 22.4066 22.4066 28.2687 22.4066 35.1318C22.4066 42.2808 28.2688 48 35.2748 48C42.2808 48 48 42.2808 48 35.1318C48 28.2687 42.2808 22.4066 35.2748 22.4066ZM35.239 41.6016C31.736 41.6016 28.8049 38.742 28.8049 35.1675C28.8049 31.736 31.736 28.8049 35.239 28.8049C38.742 28.8049 41.6016 31.736 41.6016 35.1675C41.6016 38.742 38.742 41.6016 35.239 41.6016Z" fill="var(--accent_color)"/>
            <path d="M12.8682 25.5934C19.8742 25.5934 25.5934 19.8742 25.5934 12.7252C25.5934 5.86218 19.8742 0 12.8682 0C5.86218 0 0 5.86218 0 12.7252C0 19.8742 5.86218 25.5934 12.8682 25.5934Z" fill="var(--accent_color)"/>
        </svg>
    </div>
)
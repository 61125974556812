import { useEffect, useState } from "react";
import {
    useQuery,
    WatchQueryFetchPolicy
} from "@apollo/client";
import { getOperationName } from "@apollo/client/utilities";
import { cacheClearEvents, fetchTimestampRefs } from "./ApolloProvider";
import { useLocation } from "react-router-dom";
import { useAppConfig } from "providers/AppConfig";
import { TUseQueryWithCacheExpiry } from "./interfaces";

export const useQueryWithCacheExpiry: TUseQueryWithCacheExpiry = (query, options) => {
    const { CACHE_EXPIRY_IN_MILLISECONDS } = useAppConfig();  // 15 minutes is the default cache expiry if not specified in options
    const [loadingStart, setLoadingStart] = useState<number>(0);
    const [loadingStop, setLoadingStop] = useState<number>(0);
    const MIN_NETWORK_DELAY = 300;
    const location = useLocation();
    // if the user runs window.sessionStorage.set("apolloCacheLife", "2") in browser devtools, cache expiry becomes 2 minutes
    const testCacheExpiry = parseInt(window.sessionStorage.getItem("apolloCacheLife") || "0", 10) * 1000 * 60;
    const isQATesting = testCacheExpiry > 0;
    const { refs: fetchTimeRef } = fetchTimestampRefs;
    const [fetchPolicy, setFetchPolicy] = useState<WatchQueryFetchPolicy>("cache-first");
    const { cacheLife = isQATesting ? testCacheExpiry : CACHE_EXPIRY_IN_MILLISECONDS, ...hookOptions } = options;
    const currentTime = Date.now();
    const queryName = getOperationName(query)
    const cacheKey = options.expiryKey || `${location.pathname.replaceAll('/', '_')}__${queryName}`;

    const getLastFetchedTime = () => {
        return fetchTimeRef?.current?.[cacheKey] || 0;
    }

    const setLastFetchedTime = (newTime: number) => {
        if (fetchTimeRef?.current) {
            fetchTimeRef.current[cacheKey] = newTime;
        }
    }

    const getLoadingDuration = () => loadingStop - loadingStart;

    if (!!fetchTimeRef && !fetchTimeRef?.current?.[cacheKey]) {
        isQATesting && console.info(queryName, "💾🤞😢 setting last fetched time because is was not set...", Date.now());
        setLastFetchedTime(Date.now());
    }

    const { refetch, data, loading, ...queryResult } = useQuery(query, { ...hookOptions, fetchPolicy });

    useEffect(() => {
        if(loading && !loadingStart) {
            setLoadingStart(Date.now());
        }
        if(!loading && loadingStart && !!data) {
            setLoadingStop(Date.now());
        }
        if (options.expiryEvents?.length) {
            options.expiryEvents.forEach(bucket => {
                if (!cacheClearEvents[bucket]) {
                    cacheClearEvents[bucket] = new Set();
                }
                cacheClearEvents[bucket].add(cacheKey);
            });
            isQATesting && console.info(`📁💾 Added cache key '${cacheKey}' to ${options.expiryEvents.join(" and ")} expiry events`, cacheClearEvents);
        }
    }, [loading]);

    if (getLoadingDuration() > MIN_NETWORK_DELAY) {
        isQATesting && console.log(`${queryName}, 🔁🔁🔁 setting fetchPolicy to cache-first after ${getLoadingDuration()} milliseconds data fetch`, fetchPolicy);
        setFetchPolicy("cache-first");
        setLastFetchedTime(Date.now());
        setLoadingStart(0);
        setLoadingStop(0);
    }

    useEffect(() => {
        if (fetchTimeRef?.current && currentTime > (getLastFetchedTime() + cacheLife)) {
            isQATesting && console.log(queryName, "🚀🚀🚀 refetching from network...", fetchTimeRef.current, currentTime);
            setFetchPolicy("network-only");
        }
        // isQATesting && console.log(queryName, "⏳⏳⏳", currentTime, getLastFetchedTime(), getLoadingDuration(), ((currentTime - (getLastFetchedTime() + cacheLife))/60000).toFixed(2));
    }, [currentTime, cacheLife]);
    
    useEffect(() => {
        isQATesting && console.log(queryName, "🛠⚒🔨 Mounting hook...", getLoadingDuration(), getLastFetchedTime());
        return () => console.log(queryName, "🛠⚒🔨 Unmounting hook...", getLoadingDuration(), getLastFetchedTime());
    }, []);

    const reftechWithTimestamp = async (variables?: Partial<typeof hookOptions.variables>) => {
        setLoadingStart(Date.now());
        return refetch(variables).then((resp) => {
            setLoadingStop(Date.now())
            if (resp.data && fetchPolicy === "network-only") {
                isQATesting && console.info(queryName, "🤷‍♀️🤷‍♀️ Resetting last fetched time after refecth()...");
                setLastFetchedTime(Date.now());
            }
            return resp;
        });
    }
    return { ...queryResult, data, loading, refetch: reftechWithTimestamp };
}

import React from 'react';
import { createRoot } from 'react-dom/client';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { AuthProvider } from 'components/Auth';
import { AppConfigProvider, SnackbarProvider, RegionConfigProvider,
    TranslationsProvider, ThemeProvider, MomentProvider, ApolloProvider } from 'providers';
import { ScreenSizeProvider } from 'components/Layout';
import { Suspense } from 'react';
import { Spinner } from 'components/Shared';

import 'index.scss';

const elements = (
    <ThemeProvider>
        <Suspense fallback={<Spinner fillParent/>}>
            <AppConfigProvider>
                <RegionConfigProvider>
                    <SnackbarProvider>
                        <TranslationsProvider>
                            <AuthProvider>
                                <ApolloProvider>
                                    <MomentProvider>
                                        <ScreenSizeProvider>
                                            <App/>
                                        </ScreenSizeProvider>
                                    </MomentProvider>
                                </ApolloProvider>
                            </AuthProvider>
                        </TranslationsProvider>
                    </SnackbarProvider>
                </RegionConfigProvider>
            </AppConfigProvider>
        </Suspense>
    </ThemeProvider>
);

createRoot(document.getElementById('root')!).render(elements);

serviceWorker.register();
import React, { FC } from 'react';
import { SurveyModel } from 'survey-react';
import { SurveyJSContainer, SurveyJSContainerProps, useSurveyJSUtils } from '../../../components/SurveyJS';

interface Questionnaire_Props extends SurveyJSContainerProps {}

export const Questionnaire: FC<Questionnaire_Props> = (props) => {
    const { onSurveyJSInitialized, ...rest } = props;
    const { updateAriaLabels } = useSurveyJSUtils();

    const handleSurveyJSInitialized = (surveyModel: SurveyModel) => {
        onSurveyJSInitialized?.(surveyModel);

        surveyModel.onVisibleChanged.add(() => {
            // const document = options.htmlElement?.ownerDocument;
            updateAriaLabels(document?.querySelector("div[name='qnr_last_12_months_alcohol_drug_use']"));
        })
        surveyModel.onValueChanged.add(() => {
            // const document = options.htmlElement?.ownerDocument;
            updateAriaLabels(document?.querySelector("div[name='qnr_last_12_months_alcohol_drug_use']"));
        })
    }

    return <SurveyJSContainer
        onSurveyJSInitialized={handleSurveyJSInitialized}
        {...rest}
    />
}
import React, { FunctionComponent } from 'react';
import { Button } from 'components/Shared/Inputs'
import AddIcon from '@mui/icons-material/Add';
import { Session, IActivityDetails, SessionStatus } from '../../../interfaces';
import { Link, useParams } from 'react-router-dom';
import { PROVIDER_HUB } from 'constants/targetedSystem';
import { useTranslation } from 'react-i18next';
import './SessionsList.scss';
import { IContractDetails } from 'hooks/useContractDetails/interfaces';
import { useFeatureManager } from 'hooks/useFeatureManager';
import { ActivityStatus } from 'constants/activityStatus';
import { ColumnDefinition, MaterialTable } from 'components/Shared/MaterialTable';
import useTimezoneUtils from 'hooks/useTimezoneUtils';
import { ScheduleNextSessionRoute, SessionNoteRoute, SessionStatusRoute } from 'utils/routeUtils';
import { TableContainer } from '@mui/material';
import { Card } from 'components/Shared/Card';
import { COUNTRY_CODE, getAppRegionCode, regionsToShowPreferredNumber } from 'utils/regionUtils';
import { useAuth } from 'components/Auth';
import { checkIfStaffProvider } from 'utils';
import { 
    EXPLICIT_DAY_MONTH_YEAR_MOMENT_FORMAT,
    EXPLICIT_MONTH_DAY_YEAR_MOMENT_FORMAT
} from 'hooks/useTimezoneUtils/momentDateFormats';

const sortSessionsByDate = (sessions?: Session[]) => (
    sessions
        ?.slice()
        ?.sort((a, b) => new Date(a.startDateTimeUTC).getTime() - new Date(b.startDateTimeUTC).getTime())
)

interface SessionsListProps {
    sessions?: Session[]
    activityStatusCode?: number;
    activity: IActivityDetails;
    contractDetails: IContractDetails | undefined,
    onCreateAdditionalHoursRequestClick: () => void
}

export const SessionsList: FunctionComponent<SessionsListProps> = ({ activity, onCreateAdditionalHoursRequestClick }) => {
    const { activityId = '' } = useParams<{activityId: string}>();
    const { t } = useTranslation(['activityDetails', 'requests'], { useSuspense: false });

    const { isAdditionalHoursFeatureEnabled } = useFeatureManager();
    const { formatDate } = useTimezoneUtils();

    const providerRegion = getAppRegionCode();
    const { effectiveProviderData } = useAuth();

    function hasNoSessions (sessions?: Session[]): boolean {
        return !sessions || sessions.length === 0
    }
    
    function isProviderHubActivity (activity?: IActivityDetails): boolean {
        return activity?.targetedSystem?.systemCode === PROVIDER_HUB
    }
    
    function isInactiveActivity (activityStatusCode?: number): boolean {
        return activityStatusCode !== 1 // 1 means active, everything else inactive
    }
    
    function ableToCreateAppt(activity?: IActivityDetails): boolean {
        if (!isProviderHubActivity(activity) || isInactiveActivity(activity?.activityStatusCode)) {
            return false;
        }
        // If they have so sessions, then they cannot book
        // since they have to go through First Appt. flow
        if (hasNoSessions(activity?.sessions)) {
            return false
        }
        
        return activity?.sessionBooking.allowed ?? true;
    }

    const sortedSessions = sortSessionsByDate(activity?.sessions);
    const ableToAddAppt = ableToCreateAppt(activity);
    const canUpdateSessionItemStatus = isProviderHubActivity(activity) && !isInactiveActivity(activity?.activityStatusCode);

    const ableToRequestAdditionalHours = isAdditionalHoursFeatureEnabled()
        && activity?.contractAllowsAdditionalHours
        && activity?.activityStatusCode === ActivityStatus.Active;

    const sessionDateFormat = [COUNTRY_CODE.AUSTRALIA, COUNTRY_CODE.UK].includes(providerRegion) 
        ? EXPLICIT_DAY_MONTH_YEAR_MOMENT_FORMAT 
        : EXPLICIT_MONTH_DAY_YEAR_MOMENT_FORMAT;

    const renderSessionSummary = (session: Session, index: number) => (
        <div className="session_item_wrapper">
            <div>
                <p className="sessions_list_session_number">
                    <span>{t('appointment', 'Appointment')} { index + 1 }</span>
                    {
                        regionsToShowPreferredNumber.includes(providerRegion)
                        && activity?.service === 'Counselling'
                        && activity?.modality === 'Tele-Counselling'
                        && checkIfStaffProvider(effectiveProviderData)
                        && session.clientReservationPhoneNumber
                        && (
                            <span style={{ paddingLeft: "15px"}}><strong>{session.clientReservationPhoneNumber}</strong> ({t('preferred_phone_number')})</span>
                        )
                    }
                </p>
                <p className="sessions_list_session_date">{ formatDate(session.startDateTimeUTC, `${sessionDateFormat} | LT`) }</p>
            </div>
            <div className='session_list_status_wrapper'>
                <p className="session_list_item_status">
                        {t('appointment_status') + ': '}
                        <b>
                        {
                            session.sessionStatusId === SessionStatus.ACTIVE
                                ? '---'
                                : session.sessionStatus
                        }
                        </b>
                </p>
                {
                    session.sessionStatusId === SessionStatus.ACTIVE && canUpdateSessionItemStatus &&
                    <Button variant="text" size="small" component={Link} to={{ pathname: SessionStatusRoute(activityId, session.sessionId)}}>
                        {t('set_session_status')}
                    </Button>
                }
            </div>
        </div>
    )

    const renderSessionDetails = (session: Session) => (
        <div>
            <div className="session_list_item_status_container">
            {
                session.sessionStatusId === SessionStatus.COMPLETE && canUpdateSessionItemStatus &&
                <span className="session_status">
                    <Button variant="text" size="small" component={Link} to={{ pathname: SessionNoteRoute(activityId, session.sessionId) }}>
                        {t('edit_note')}
                    </Button>
                    {/*<Button variant="text" size="small" component={Link} to={{ pathname: AssessmentsPickerRoute(activityId, session.sessionId) }}>*/}
                    {/*    {t('administer_assessment')}*/}
                    {/*</Button>*/}
                </span>
            }
            </div>
        </div>
    )

    const renderActivityId = () => (
        <p>
          {t('appointments_for_activity')} {activityId}
        </p>
    )

    const renderNextSessionButton = () => (
        <Button aria-label={t('add_appointment')} variant='outline' component={Link} to={{ pathname: ScheduleNextSessionRoute(activityId) }}>
            <AddIcon/>
            {t('appointment')}
        </Button>
    )

    const renderAdditionalHoursButton = () => (
        <Button aria-label={t('requests:request_additional_hours')} variant="outline" onClick={onCreateAdditionalHoursRequestClick}>
            <AddIcon/>
            {t('requests:request_additional_hours')}
        </Button>
    )

    const renderHeaderCell = () => (
        <div className="sessions_list_header_cell">
            <div className="sessions_list_header_cell_text">
                {renderActivityId()}
            </div> 
            <div className="sessions_list_header_cell_btn">
                {ableToAddAppt && renderNextSessionButton()}
                {ableToRequestAdditionalHours && renderAdditionalHoursButton()}
            </div>
        </div> 
    )
    
    const columns: ColumnDefinition[] = [{
        title: renderHeaderCell(),
        field: '',
        sortable: false,
        template: renderSessionSummary
    }];
    
    return (
        <TableContainer component={Card} elevation={1}>
            <MaterialTable columns={columns} data={sortedSessions} collapsible collapsibleRowTemplate={renderSessionDetails} dataLoading={false}/>
        </TableContainer>
    );
}
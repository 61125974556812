import { DataRequest } from 'components/Shared/MaterialTable';
import { ReportType } from './constants';

export interface InvoiceTableItem {
    invoiceId: number,
    activityId: number,
    createdDateUtc: string,
    submittedDateUtc: Date | string | null,
    caseAcceptedDateUtc: Date | string | null,
    statusId: InvoiceStatus,
    statusString: string,
    currencyCode: string,
    grandTotal: number,
    client: string,
    providerInvoiceNumber: string,
    wspTaskCode?: string,
    isActivityAvailable : boolean
}

export interface InvoiceSummary {
    invoiceId: number,
    activityId: number,
    createdDateUtc: string,
    submittedDateUtc: string,
    caseAcceptedDateUtc: Date | string,
    statusId: InvoiceStatus,
    statusString: string,
    currencyCode: string,
    subTotal: number
}

export interface SessionSummary {
    duration: number,
    isPendingForInvoice: boolean,
    sessionId: number,
    startDateTime: string,
    sessionStatus: string,
    sessionStatusId: number
}

export interface InvoiceDetails {
    invoiceId: number,
    currencyCode: string,
    grandTotal: number,
    createdDate: string,
    submittedDate: string | null,
    approverComment: string,
    statusId: InvoiceStatus,
    statusString: string,
    billables: InvoiceBillable[],
    taxes: ProviderInvoiceTax[],
    provider: InvoiceProvider,
    activity: InvoiceActivity,
    providerInvoiceNumber: string,
    totalExpenses: number,
    totalFees: number,
    addCaseManagementTime?: boolean,
    displayCaseManagementTime?: boolean
}

export interface IActivityInvoiceData {
    providerId: number,
    activityId: number,
    invoices?: InvoiceSummary[],
    invoiceAllowed: boolean,
    service: string,
    openDate?: string,
    invoicableSessions?: SessionSummary[],
    isWSPActivity: boolean,
    isProviderHubActivity: boolean
}

export interface InvoiceProvider {
    providerId: number,
    emailAddress: string,
    firstName: string,
    lastName: string,
    businessNumber: string,
    contactInfo: {
        addresses: {
            addressLine1: string,
            addressLine2: string,
            city: string,
            zIP_Postal: string,
            state_ProvinceCode: string,
            countryCode: string,
            officeType: string
        }[]
        phones: {
            phoneNumber: string
        }[]
    }
    vendor: {
        vendorId: number,
        name: string,
        email: string,
        address: {
            addressLine1: string,
            addressLine2: string,
            city: string,
            zIP_Postal: string,
            state_ProvinceCode: string,
            provinceAbbreviation?: string,
            countryCode: string
        }
    }
}

export interface InvoiceBillable {
    activityInvoiceId: number,
    activityId: number,
    sessionId: number | null,
    date: string,
    dateUtc: string,
    currencyCode: string,
    currencyDescription: string,
    amount: number,
    hours: number,
    feeCodeDescription: string,
    feeCode: string,
    rate: number,
    rateTypeCode: string,
    wspTaskCode?: string,
    wspReportType?: ReportType | null,
    wspReportTypeString?: string | null,
    wspMonthlyReportYear?: number | null,
    wspMonthlyReportMonth?: number | null
}

export interface EditWspBillable {
    billableId: number | undefined,
    activityId: number,
    invoiceId: number,
    providerId: number,
    date: string | undefined,
    wspTaskCode: string | undefined,
    duration: number | undefined,
    wspReportType: ReportType | undefined | null,
    wspMonthlyReportYear: number | undefined | null,
    wspMonthlyReportMonth: number | undefined | null
}

export interface ProviderInvoiceTax {
    invoiceTaxId: number,
    taxCode: string,
    taxProvinceId: number,
    amount: number,
    taxDescription: string
}

export interface InvoiceActivity {
    activityId: number,
    service: string
}

export enum InvoiceStatus {
    Submitted = 1,
    PendingApproval = 2,
    Approved = 3,
    Declined = 4,
    Draft = 5,
    Void = 6,
    AutoApproval = 7
}

export interface InvoicingDataRequest extends DataRequest {
    filters: FiltersDefinition
}

export interface FiltersDefinition {
    status: InvoiceStatus | -1,
    startDate: Date | undefined,
    endDate: Date | undefined
}

import { gql, TypedDocumentNode } from "@apollo/client";
import { ToDo } from 'components/ToDos'

export interface IToDoQueryData {
    toDos: ToDo[]
}

export interface IActivityInfo {
    clientName: string
    activityId: number
}

export interface IToDoQueryVars {
    providerId: number
}

export const USE_TODOS_QUERY: TypedDocumentNode<IToDoQueryData, IToDoQueryVars> = gql`
query TODO_QUERY($providerId: Long!) {
    toDos(providerId: $providerId) {
        id
        providerId
        sessionId
        step
        visibility
        createTime
        activityInfo {
            clientName
            activityId
        }
    }
}
`
import { useMemo } from 'react';
import { DataRequest, sortData, derivePaginationDetails, PaginationDetails } from 'components/Shared/MaterialTable';
import { ActivityTableItem, IActivityListEntity } from '../interfaces';

export const useProcessActivitiesData = (data: IActivityListEntity[] | undefined, dataRequest: DataRequest)
    : [ActivityTableItem[] | undefined, PaginationDetails] =>
{
    const parsedData = useMemo(() =>
        data?.filter(activity => Boolean(activity))?.map<ActivityTableItem>(activity => ({
            activityId: activity.activityId,
            clientName: `${activity.client?.firstName} ${activity.client?.lastName}`,
            organizationName: activity.organization?.name,
            presentingIssue: activity.presentingIssue,
            targetedSystemCode: activity.targetedSystem?.systemCode
        }))
    , [data]);

    const filteredData = useMemo(() => {
        const searchString = dataRequest.search.toLowerCase().trim();
        return parsedData?.filter(activity =>
            (activity.clientName?.toLowerCase().includes(searchString) ?? false)
            || (activity.activityId?.toString().includes(searchString) ?? false)
            || (activity.organizationName?.toLowerCase().includes(searchString) ?? false)
            || (activity.presentingIssue?.toLowerCase().includes(searchString) ?? false)
        )
    }, [parsedData, dataRequest.search]);

    const sortedData = useMemo(() =>
        sortData(filteredData, dataRequest.sorts)
    , [filteredData, dataRequest.sorts]);

    const pagedData = useMemo(() => 
        sortedData?.slice(dataRequest.skip, dataRequest.skip + dataRequest.take)
    , [sortedData, dataRequest.skip, dataRequest.take]);

    const paginationDetails = derivePaginationDetails(sortedData?.length, dataRequest);
    
    return [pagedData, paginationDetails];
}
import { Container, Tooltip, IconButton } from '@mui/material';
import React, { FC } from 'react';
import './style.scss'
import { NextArrowButton } from 'components/Shared';
import { PreviousArrowButton } from 'components/Shared';
import CloseIcon from '@mui/icons-material/Close';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';


export interface IBookApptFrame {
    showNextButton?: boolean;
    className?: string;
    onClickNextButton?: () => void;
    nextButtonDisabled?: boolean;
    children?: any;
    showPreviousButton?: boolean;
    previousButtonDisabled?: boolean;
    onClickPreviousButton?: () => void;
    backRoute: string
}

export const BookApptFrame: FC<IBookApptFrame> = (props) => {
    const { t } = useTranslation('calendaring', { useSuspense: false });
    const navigate = useNavigate()

    const goBackToWhereWeCameFrom = () => {
        navigate(props.backRoute)
    }

    const exitRouteTooltip = t('session_booking_exit_flow_text');

    return (
        <div className={`calendar_appt_booking ${props.className}`}>
            <Tooltip title={exitRouteTooltip}>
                <IconButton className="close-booking-icon" onClick={goBackToWhereWeCameFrom}>
                    <CloseIcon />
                </IconButton>
            </Tooltip>
            <div className="calendar_appt_booking_wrapper">
                <Container className="calendar_appt_booking_container" disableGutters>
                    {props.children}
                </Container>
                {
                    props.showNextButton && 
                    <NextArrowButton 
                        className="book-appt-next-button" 
                        disabled={props.nextButtonDisabled}  
                        onClick={props.onClickNextButton}
                    />
                }
                {
                    props.showPreviousButton && 
                    <PreviousArrowButton 
                        className="book-appt-previous-button" 
                        disabled={props.previousButtonDisabled}  
                        onClick={props.onClickPreviousButton}
                    />
                }
            </div>
        </div>
    );
}
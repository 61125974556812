import React, { FC, useState, useEffect } from 'react';
import { useAuth  } from 'components/Auth';
import { useTranslation } from 'react-i18next';
import { PageMetadata } from 'components/Shared/PageMetadata';
import { useAppConfig } from 'providers/AppConfig';
import { BrowserRouter } from 'react-router-dom';
import { AppRouting } from './AppRouting';
import { NewVersionMessage } from 'components/Layout';

import { withAITracking } from '@microsoft/applicationinsights-react-js';
import { activateAppInsights, reactPlugin } from './AppInsights';

declare const AppVersion: string;

interface AppProps {}

export const App: FC<AppProps> = () => {
    const { authenticated } = useAuth();
    const [newVersionFound, setNewVersionFound] = useState(false);
    const { t, ready } = useTranslation('common', { useSuspense: false });
    const config = useAppConfig();

    activateAppInsights(config);

    useEffect(() => {
        if ((AppVersion !== '#{AppVersion}') && (AppVersion !== config.AppVersion)) {
            setNewVersionFound(true);
        }
    }, [config.AppVersion, AppVersion])

    useEffect(() => {
        if (newVersionFound && !authenticated) {
            if ('serviceWorker' in navigator) {
                navigator.serviceWorker.getRegistration()
                    .then((registration) => {
                        if (registration?.waiting) {
                            registration.waiting.postMessage('SKIP_WAITING');
                        }
                    })
                    .finally(() => {
                        window.location.replace(window.location.href);
                    });
            } else {
                window.location.replace(window.location.href);
            }
        }
    }, [authenticated, newVersionFound])


    return (
        <>
            {
                ready &&
                <PageMetadata
                    pageDescription={t('page_description')}
                    pageTitle={t('page_title')}
                />
            }
            {
                newVersionFound &&
                <NewVersionMessage/>
            }
            <BrowserRouter>
                <AppRouting/>
            </BrowserRouter>
        </>
    )
}

export default withAITracking(reactPlugin, App)

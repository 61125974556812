import React, { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { AddressForm } from '../../Forms/AddressForm';
import { EmailForm } from '../../Forms/EmailForm';
import { PhoneForm } from '../../Forms/PhoneForm';
import { Address, Email, Phone } from '../../../interfaces';
import { SectionContainer } from '../../Other/SectionContainer';
import { isFuture, isPast } from 'utils/dateUtils';
import { Alert } from '@mui/material';

interface ContactDetailsProps {
    contactInfo?: {
        mailingAddresses: Address[],
        emails: Email[],
        phones: Phone[]
    }
}

export const ContactDetails: FC<ContactDetailsProps> = (props) => {
    const { contactInfo } = props;
    const { phones, emails, mailingAddresses } = contactInfo ?? {};
    
    const { t, ready: translationsReady } = useTranslation('providerprofile', { useSuspense: false });

    const orderedPhones = useMemo(() => phones?.slice()?.sort((a, b) => {
        if (a.preferredLine || a.preferredConfidentialLine) return -1;
        if (b.preferredLine || b.preferredConfidentialLine) return 1;
        return 0;
    }), [phones]);

    const orderedEmails = useMemo(() => emails?.slice().sort((a, b) => {
        if (a.primary) return -1;
        if (b.primary) return 1;
        return 0;
    }), [emails]);

    
    const addresses = useMemo(() => mailingAddresses?.filter(address => {
        // default to show in case of missing dates
        if (!address.addressEffectiveDate && !address.addressExpiryDate) return true;

        if (address.addressEffectiveDate && isFuture(new Date(address.addressEffectiveDate))) return false;
        if (address.addressExpiryDate && isPast(new Date(address.addressExpiryDate))) return false;
        return true;
    }), [mailingAddresses]);

    
    const changeInformationNoteContent = t('contact_details__note');

    return !translationsReady
        ? <></>
        : <SectionContainer title={t('contact_details__section_title')}>
            {
                changeInformationNoteContent &&
                <Alert severity="info">{t('contact_details__note')}</Alert>
            }
            <h3>{t('contact_details__phone_numbers_header')}</h3>
            {
                orderedPhones?.map((phone, index) => (
                    <PhoneForm phone={phone} key={index}/>
                ))
            }
            <h3>{t('contact_details__emails_header')}</h3>
            {
                orderedEmails?.map((email, index) => (
                    <EmailForm email={email} key={index}/>
                ))
            }
            <h3>{t('contact_details__addresses_header')}</h3>
            {
                addresses?.map((address, index) => (
                    <AddressForm address={address} key={index}/>
                ))
            }
        </SectionContainer>
}
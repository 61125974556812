import { gql, TypedDocumentNode } from '@apollo/client';

export const UPDATE_NPI_MUTATION: TypedDocumentNode<UpdateNPIMutationData, UpdateNPIMutationVars> = gql`
    mutation UPDATE_NPI_MUTATION($providerId: Long!, $nationalProviderIdentifier: String) {
        updateCredentials(providerId: $providerId, nationalProviderIdentifier: $nationalProviderIdentifier) {
            providerId
            credential {
                nationalProviderIdentifier
            }
        }
    }
`

export interface UpdateNPIMutationData {
    updateCredentials: {
        credential: {
            nationalProviderIdentifier: string
        }
    }
}

export interface UpdateNPIMutationVars {  
    providerId: number,
    nationalProviderIdentifier: string
}


export const UPDATE_ABN_MUTATION: TypedDocumentNode<UpdateABNMutationData, UpdateABNMutationVars> = gql`
    mutation UPDATE_ABN_MUTATION($providerId: Long!, $businessNumber: String) {
        updateCredentials(providerId: $providerId, businessNumber: $businessNumber) {
            providerId
            credential {
                businessNumber
            }
        }
    }
`

export interface UpdateABNMutationData {
    updateCredentials: {
        credential: {
            businessNumber: string
        }
    }
}

export interface UpdateABNMutationVars {  
    providerId: number,
    businessNumber: string
}
import React, { FC } from 'react';
import { InputAdornment, Checkbox, CheckboxProps } from "@mui/material"
import { Visibility as VisibilityIcon, VisibilityOff as VisibilityOffIcon } from "@mui/icons-material";
  
export const ShowPasswordCheckbox: FC<CheckboxProps> = (props) => {
    return (
        <InputAdornment position="end">
            <Checkbox
                color="default"
                className="show_password_checkbox"
                icon={<VisibilityIcon fontSize="small" />}
                checkedIcon={<VisibilityOffIcon fontSize="small" />}
                {...props}
            />
        </InputAdornment>
    )
}
import './InvoiceTaskTable.scss';

import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import React, { FC } from 'react';

import { InvoiceBillable } from '../../interfaces';
import { useTranslation } from 'react-i18next';
import { toCurrencyString } from 'utils';
import useTimezoneUtils from 'hooks/useTimezoneUtils';
import { useWspTaskCodes } from 'hooks/useWspTaskCodes';
import { calculateBillableRate } from 'components/Invoicing/utils/billableUtils';
import moment from 'moment';

interface InvoicePreviewHTMLProps {
    billables: InvoiceBillable[]
    header?: string;
    isTaskTable?: boolean
}

export const InvoiceTaskTable: FC<InvoicePreviewHTMLProps> = ({ billables, header, isTaskTable }) => {
    const { i18n, t } = useTranslation('invoicing', { useSuspense: false });

    const { formatDate } = useTimezoneUtils()

    const allWspTaskCodes = useWspTaskCodes()

    const formatInvoiceTime = (date: string | Date) => {
        return formatDate(date, 'LT')
    }

    
    const formatInvoiceDate = (date: string | Date) => {
        return formatDate(date, 'l')
    }

    
    return (
        <div className="invoice_preview_table">
            <div className="invoice-table">
                { header && <h1>{header}</h1>}
                <TableContainer className="invoice-table-container">
                    <Table className="table">
                        <TableHead className="invoice_preview_table_header">
                            <TableRow>
                                <TableCell>{t('date')}</TableCell>
                                <TableCell>{t('time')}</TableCell>
                                <TableCell>{t('duration')}</TableCell>
                                { isTaskTable && <TableCell>{t('wsp_task_code', 'WSP Task Code')}</TableCell> }
                                <TableCell>{t('rate')}</TableCell>
                                <TableCell>{t('amount')}</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody className="invoice_preview_table_body">
                            {
                                billables?.map((billable, index) => (
                                    <TableRow key={index}>
                                        <TableCell>{billable.dateUtc ? formatInvoiceDate(new Date(billable.dateUtc)) : '---'}</TableCell>
                                        <TableCell className="business_number">{billable.dateUtc ? formatInvoiceTime(new Date(billable.dateUtc)) : '---'}</TableCell>
                                        <TableCell>{billable.hours ?? '---'}</TableCell>
                                        {
                                            isTaskTable && 
                                            <TableCell>
                                                {allWspTaskCodes?.find(code => code.value === billable.wspTaskCode)?.title ?? '---'}
                                                {
                                                    billable.wspReportTypeString &&
                                                    <><br/><span>{billable.wspReportTypeString}</span></>
                                                }
                                                {
                                                    billable.wspMonthlyReportYear && billable.wspMonthlyReportMonth &&
                                                    <><br/><span>{moment({year: billable.wspMonthlyReportYear, month: billable.wspMonthlyReportMonth - 1}).format('MMMM YYYY')}</span></>
                                                }
                                            </TableCell> 
                                        }
                                        <TableCell className="business_number">{
                                            calculateBillableRate(billable, t, i18n)
                                        }</TableCell>
                                        <TableCell className="business_number">{
                                            toCurrencyString(billable.amount, i18n.language, billable.currencyCode) ?? '---'
                                        }</TableCell>
                                    </TableRow>
                                ))
                            }
                        </TableBody>
                    </Table>
                </TableContainer>
            </div>
        </div>
    )
}
import { gql, TypedDocumentNode } from '@apollo/client';
import { IAddress, IBookCalendarActivityData, ICalendarActivityListEntity, IProviderSearchScheduleSlot } from './interfaces';

// TODO: Add query to fetch Provider offices

export const CALENDAR_STEP1_ACTIVITIES_QUERY
    : TypedDocumentNode<CalendarStep1ActivitiesQueryData, CalendarStep1ActivitiesQueryVars> = gql`
    query ACTIVITIES_QUERY($providerId: Long!) {
        activities(providerId: $providerId) {
            client {
                firstName
                lastName
            }
            organization {
                name
            }
            activityId
            targetedSystem {
                systemCode
            }
            additionalParticipants {
                id 
                firstName,
                lastName,
                relationType,
                fullName,
                dateOfBirth
            }
            activityStatusCode
        }
    }
`
interface CalendarStep1ActivitiesQueryData {
    activities: ICalendarActivityListEntity[]
}

interface CalendarStep1ActivitiesQueryVars {
    providerId: number
}



export const PROVIDER_SEARCH_SCHEDULES_QUERY
    : TypedDocumentNode<ProviderSearchSchedulesQueryData, ProviderSearchSchedulesQueryVars> = gql`
    query PROVIDER_SEARCH_SCHEDULES(
        $providerId: Int!,
        $secondarySearchSchedules: String,
        $sessionId: Int!, $appDateRelCode: String!,
        $appTimeRelCode: String!,
        $appDate: String,
        $appTime: String,
        $officeId: Long
    ) {
        providerSearchSchedules(
            providerId: $providerId,
            secondarySearchSchedules:
            $secondarySearchSchedules,
            sessionId: $sessionId,
            appDateRelCode: $appDateRelCode,
            appTimeRelCode: $appTimeRelCode,
            appDate: $appDate,
            appTime: $appTime,
            officeId: $officeId
        ) {
            date,
            startDateUtc,
            clientTime,
            clientDateTime,
            dateFormatted,
            dateFormatted2,
            clientTimeFormatted,
            clientTimeFormatted2,
            duration,
            isBackToBack,
            isManagedCalendar,
            addressId,
            scheduleId
        }
    }
`;

interface ProviderSearchSchedulesQueryData {
    providerSearchSchedules: IProviderSearchScheduleSlot[]
}

interface ProviderSearchSchedulesQueryVars {
    providerId: number, 
    secondarySearchSchedules: string | null, 
    sessionId: number, 
    appDateRelCode: string, 
    appTimeRelCode: string, 
    appDate: string, 
    appTime: string, 
    officeId?: number | 'any' | null
}


export const CALENDAR_BOOKING_ACTIVITY_QUERY
    : TypedDocumentNode<CalendarBookingActivityQueryData, CalendarBookingActivityQueryVars> = gql`
    query ACTIVITY_SUMMARY_QUERY($activityId: Long!) {
        activity(activityId: $activityId) {
            timezoneOlsonName
            client {
                clientId
                firstName
                lastName
                email
                phones {
                    number
                    phoneTypeCode
                    messageType
                }
                gender
                languagePreference
                address {
                    city
                    state_ProvinceCode
                    countryName
                }
            }
            additionalParticipants {
                id 
                firstName,
                lastName,
                relationType,
                fullName,
                dateOfBirth
            }
            sessionInfo {
                modelId
                totalHoursProvided
                totalHoursRemaining
                maxCapHours
            }
            activityStatusCode
            service
            clientStatusCode
            eligibility
            clientMaxHours
            clientAvailableHours
            clientMaxHoursStartDate
            clientMaxHoursEndDate
            isHourlyContract
            modality
            targetedSystem {
                systemCode
            }
            organization {
                name
                organizationId
            }
            providerId
            presentingIssue
            solutionCode
            activityId
            sessions {
                duration
                sessionId
                sessionStatus
                startDateTime
                startDateTimeUTC
                sessionStatusId
                providerHubAppointmentStatusId
                clientReservationPhoneNumber
            }
            openDate
            urgencyLevel
            sessionBooking {
                allowed
                prohibitionReasonCode
            }
        }
    }
`

interface CalendarBookingActivityQueryData {
    activity: IBookCalendarActivityData
}

interface CalendarBookingActivityQueryVars {
    activityId: number
}




export const PROVIDER_INFO_QUERY: TypedDocumentNode<ProviderInfoQueryData, ProviderInfoQueryVars> = gql`
    query PROVIDER_INFO_QUERY($providerId: Long!) {
        providerDetails(id: $providerId) {
            timezoneOlsonName
        }
        provider(id: $providerId) {
            providerId
            contactInfo {
                addresses {
                    addressLine1
                    addressLine2
                    countryCode
                    officeType
                    providerAddressId
                    addressExpiryDate
                    addressEffectiveDate
                    officeType
                    officeTypeString
                    #wheelchair
                    #officehours
                    #officeconnection
                    #publictransport
                    #officeinstructions
                    state_ProvinceCode
                    zIP_Postal
                    addressPhone {
                        phoneNumber
                        phoneType
                        phoneTypeString
                        preferredLine
                        leaveMessage
                    }
                }
                phones {
                    phoneNumber
                    phoneType
                }
            }
        }
    }
`

interface ProviderInfoQueryData {
    providerDetails: {
        timezoneOlsonName: string
    },
    provider: {
        firstName: string,
        lastName: string,
        preferredLanguageCode: string,
        contactInfo: {
            addresses: IAddress[]
        },
        contractedServices: string[]
    }
}

interface ProviderInfoQueryVars {
    providerId: number
}


export const APPT_BOOKING_REF: TypedDocumentNode<ApptBookingRefData, ApptBookingRefVars> = gql`
    query APPT_BOOKING_REF_DATA {
        refValues {
            datePreferences {
                title
                value
            }
            timePreferences {
                title
                value
            }
        }
    }
`

interface ApptBookingRefData {
    refValues: {
        datePreferences: {
            title: string,
            value: string
        }[],
        timePreferences: {
            title: string,
            value: string
        }[]
    }
}

interface ApptBookingRefVars { }



// export const DATE_PREF_QUERY: TypedDocumentNode<DatePreferencesQueryData, DatePreferencesQueryVars> = gql`
//     query DATE_PREF_QUERY {
//         datePreferences {
//             aft,
//             any,
//             bef,
//             on
//         }
//     }
// `;

// interface DatePreferencesQueryData {
//     datePreferences: {
//         aft: string,
//         any: string,
//         bef: string,
//         on: string
//     }
// }

// interface DatePreferencesQueryVars { }


// export const TIME_PREF_QUERY: TypedDocumentNode<TimePreferencesQueryData, TimePreferencesQueryVars> = gql`
//     query TIME_PREF_QUERY {
//         timePreferences {
//             aft,
//             aftn,
//             any,
//             bef,
//             eve,
//             mor,
//             nea
//         }
//     }
// `;

// interface TimePreferencesQueryData {
//     timePreferences: {
//         aft: string,
//         aftn: string,
//         any: string,
//         bef: string,
//         eve: string,
//         mor: string,
//         nea: string
//     }
// }

// interface TimePreferencesQueryVars { }



export const OLSON_TIMEZON_QUERY = gql`
    query OLSON_TIMEZON_QUERY($timezoneId: Long!) {
        timezoneOlsonName(timezoneId: $timezoneId)
    }
`;
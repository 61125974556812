import React, { FC, useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { Spinner } from 'components/Shared';
import { InvoiceDetailsView } from 'components/Invoicing';
import { useAuth } from 'components/Auth';
import { useTodos } from 'hooks/useTodos'
import { StepDefinition } from 'components/ToDos';
import { useTerminateTodo } from 'hooks/useTerminateTodo';
import { NetworkStatus } from '@apollo/client';
import { ACTIVITY_QUERY_INVOICES } from 'components/Invoicing/queries';
import { useQueryWithCacheExpiry } from 'providers/Apollo/useQueryWithCacheExpiry';

interface InvoicesTabProps {}

interface InvoicesTabParams extends Record<string, string | undefined> {
    activityId: string,
    invoiceId: string | undefined,
}

export const InvoicesTab: FC<InvoicesTabProps> = () => {
    const { invoiceId: invoiceIdString, activityId: activityIdString } = useParams<InvoicesTabParams>();
    const { effectiveProviderData } = useAuth();

    const activityId = Number.parseInt(activityIdString as string);

    const invoiceId = useMemo(() =>
        invoiceIdString && invoiceIdString !== 'create'
            ? Number.parseInt(invoiceIdString)
            : undefined
    , [invoiceIdString]);

    const activityQuery = useQueryWithCacheExpiry(ACTIVITY_QUERY_INVOICES, {
        variables: { activityId: activityId },
        fetchPolicy: 'cache-first',
        errorPolicy: 'all',
        notifyOnNetworkStatusChange: true,
        expiryKey: `ACTIVITY_QUERY_INVOICES_${activityId}`
    });

    const activity = activityQuery.data?.activity;
    const providerId = activity?.providerId;

    const canGenerateInvoice = activity?.invoiceAllowed && (activity?.invoicableSessions?.length || activity?.isWSPActivity);

    const [terminateTodo] = useTerminateTodo();

    const { toDos, loading: todosLoading } = useTodos(effectiveProviderData?.providerId);

    const submitInvoiceTodoForActivity = toDos?.find(todo => todo.activityInfo?.activityId === activityId && todo.step === StepDefinition.SUBMIT_INVOICE);

    const loading = activityQuery.loading || activityQuery.networkStatus === NetworkStatus.refetch || todosLoading;

    useEffect(() => {
        if (activity && toDos && invoiceIdString === 'create' && submitInvoiceTodoForActivity && !canGenerateInvoice) {
            terminateTodo({
                variables: {
                    workflowId: submitInvoiceTodoForActivity?.id
                }
            })
        }
    }, [activity, toDos, invoiceIdString, submitInvoiceTodoForActivity, canGenerateInvoice])

    return (
        loading
            ? <div style={{ 
                    display: 'flex', 
                    justifyContent: 'center', 
                    alignItems: 'center',
                    width: '100%',
                    minHeight: '400px'
                }} className="invoicing-spinner">
                <Spinner/>
            </div>
            : <InvoiceDetailsView
                activity={activity}
                invoiceId={invoiceIdString === 'create' ? 'create' : invoiceId}
                providerId={providerId!}
                onInvoiceSubmitted={activityQuery.refetch}
            />
    )
}

import React, { FC, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Tabs, Tab } from '@mui/material';
import { useFeatureManager } from 'hooks/useFeatureManager';
import { Logo }  from 'components/Shared';
import { useNavigationUtils } from '../../hooks';
import { Button } from 'components/Shared/Inputs';

import './DesktopPrimaryNavbar.scss';

interface DesktopPrimaryNavbarProps {}

const KnownPaths = ['/', '/todos', '/clients', '/providers', '/invoicing', '/calendar', '/requests'];

export const DesktopPrimaryNavbar: FC<DesktopPrimaryNavbarProps> = (_) => {
    const { t } = useTranslation('navigation', { useSuspense: false });

    const { isListReferralFeatureEnabled, isAdditionalHoursFeatureEnabled } = useFeatureManager();

    const { authenticated, pathRoot, showCalendarTab, showInvoicingTab, showStaffTab } = useNavigationUtils();

    const path = KnownPaths.indexOf(pathRoot) !== -1 ? pathRoot : false;

    const renderNavbarTab = (id: string, path: string, props?: any) => (
        <Tab className="navbar_tab" id={id} label={t(id)} value={path} component={Link} to={{pathname: path}} {...props}/>
    );

    const AddNewClientButton = useCallback((props) => {
        const selected = props.isSelected;
        return <Button
            className="add_client_button"
            variant={selected ? "opaque" : "outline"}
            disabled={props.disabled}
            aria-selected={selected}
            id="add-clients"
            component={Link} to="/add-clients"
            role="tab" tabIndex={selected ? 0 : -1}
        >
            <span>{t('add-client', 'Add New Client')}</span>
        </Button>
    }, []);

    return (
        <div className='desktop_primary_navbar'>
            <a href="/"><Logo className="logos_primary_navbar"/></a>
            {
                authenticated &&
                <>
                    <Tabs className="navbar_tabs" value={path} component="nav" variant="scrollable" scrollButtons="auto">
                        {renderNavbarTab('dashboard', '/', { tabIndex: ((path === '/' || path === false) ? 0 : -1) })}
                        {renderNavbarTab('to-dos', '/todos')}
                        {renderNavbarTab('clients', '/clients')}
                        {isAdditionalHoursFeatureEnabled() && renderNavbarTab('requests', '/requests')}
                        {showStaffTab && renderNavbarTab('staff', '/providers')}
                        {showCalendarTab && renderNavbarTab('calendar', '/calendar')}
                        {showInvoicingTab && renderNavbarTab('invoicing', '/invoicing')}
                        {isListReferralFeatureEnabled() && <AddNewClientButton isSelected={pathRoot === '/add-clients'}/>}
                    </Tabs>
                </>
            }
        </div>
    )
}

import { useMutation } from "@apollo/client";
import React, { FC, PropsWithChildren, createContext, useContext, useEffect, useState } from "react";
import omit from "lodash.omit";
import unionBy from "lodash.unionby";
import moment from "moment";

import { InvoiceDetails } from "components/Invoicing/interfaces";
import { ACTIVITY_QUERY_INVOICES, CREATE_INVOICE_DRAFT } from "components/Invoicing/queries";
import { useFeatureManager } from "hooks/useFeatureManager";
import { useQueryWithCacheExpiry } from "providers/Apollo/useQueryWithCacheExpiry";
import { useAppConfig } from "providers";

let unmountCount = 0;
const abortController: React.MutableRefObject<AbortController|null> = React.createRef();

interface InvoiceDraftProviderProps extends PropsWithChildren {
    activityId: number;
}

interface InvoiceDetailsWithTimestamp extends InvoiceDetails {
    timestamp?: number;
}

interface InvoiceDraftReturnValue { 
    invoiceDraft: InvoiceDetails | false | undefined;
    loading: boolean
}

type InvoiceDraftContextProps = Record<number, InvoiceDetailsWithTimestamp | false | undefined>;

const emptyObj = {};

const InvoiceDraftContext = createContext<InvoiceDraftContextProps>(emptyObj);

InvoiceDraftContext.displayName = "InvoiceDraftContext";

const InvoiceDraftProvider: FC<InvoiceDraftProviderProps> = ({ children, activityId }) => {
    const [createInvoice, { loading, data }] = useMutation(CREATE_INVOICE_DRAFT, {
        onCompleted: ({invoice}) => updateInvoiceDraftInSession(activityId, invoice),
    });
    const invoiceDraftContext = useContext(InvoiceDraftContext);
    const activityQuery = useQueryWithCacheExpiry(ACTIVITY_QUERY_INVOICES, {
        variables: { activityId: activityId },
        fetchPolicy: 'cache-first',
        errorPolicy: 'all',
        notifyOnNetworkStatusChange: true,
        expiryKey: `ACTIVITY_QUERY_INVOICES_${activityId}`
    });
    const { INVOICE_DRAFT_EXPIRY_IN_HOURS } = useAppConfig();

    const [value, setValue] = useState<InvoiceDraftContextProps>({ ...invoiceDraftContext });
    
    const activity = activityQuery.data?.activity;
    const providerId = activity?.providerId as number;

    
    const { isWSPFeatureEnabled } = useFeatureManager();
    const useWspFunctionality = isWSPFeatureEnabled() && Boolean(activity?.isWSPActivity);

    const canGenerateInvoice = Boolean(
        activity?.isProviderHubActivity && activity?.invoiceAllowed
        && (Boolean(activity?.invoicableSessions?.length) || useWspFunctionality)
        && !invoiceDraftContext[activityId]
    );
    const getDraftAge = (drafts: InvoiceDraftContextProps) => 
                            moment().diff(moment((drafts[activityId] as InvoiceDetailsWithTimestamp)?.timestamp || Date.now()), "hours");

    function retrieveDraftsFromSession(): InvoiceDraftContextProps | undefined {
        try {
            console.info("💾 Retrieving invoice drafts from session storage");
            const draftsFromSession = window.sessionStorage.getItem("invoiceDrafts");
            if (draftsFromSession) {
                const invoiceDrafts = JSON.parse(draftsFromSession);
                let val: InvoiceDraftContextProps;
                if (getDraftAge(invoiceDrafts) < INVOICE_DRAFT_EXPIRY_IN_HOURS) {
                    val = invoiceDrafts;
                } else {
                    val = omit(invoiceDrafts, [`${activityId}`]);
                }
                setValue(val);
                return val;
            }
        } catch (e) {
            console.warn("❌ Unable to retrieve invoice drafts from session storage");
        }
        return undefined;
    }

    const abortLatest = () => abortController.current?.abort();

    useEffect(() => {
        retrieveDraftsFromSession();
        return () => {
            abortLatest();
            console.warn("⏏ unmounting InvoiceDraftProvider...", ++unmountCount);
        }
    }, []);

    useEffect(() => {
        const existingDrafts = retrieveDraftsFromSession();
        if (loading) {
            setValue({ ...(existingDrafts || value || invoiceDraftContext), [activityId]: false });
        } else if (data) {
            const val = { ...(existingDrafts || value || invoiceDraftContext), [activityId]: { ...data.invoice, timestamp: Date.now() } };
            setValue(val);
            window.sessionStorage.setItem("invoiceDrafts", JSON.stringify(val));
        }
    }, [loading, data]);

    useEffect(() => {
        if (!value[activityId] && providerId && canGenerateInvoice && !loading) {
            const controller = new window.AbortController();
            abortController.current = controller;
            setValue({ ...value, [activityId]: false });
            console.info("🌎 Retrieving invoice drafts from network");
            createInvoice({
                variables: { activityId, providerId },
                context: { fetchOptions: { signal: controller.signal } }
            });
        }
    }, [activityId, providerId, canGenerateInvoice]);

    if (!canGenerateInvoice) {
        return <InvoiceDraftContext.Provider value={{ ...invoiceDraftContext, [activityId]: undefined }}>{children}</InvoiceDraftContext.Provider>
    }
    
    if (getDraftAge(value) > INVOICE_DRAFT_EXPIRY_IN_HOURS) {
        setValue({ ...value, [activityId]: data?.invoice ? { ...data.invoice, timestamp: Date.now() } : loading ? false : undefined });
    }
    return <InvoiceDraftContext.Provider value={value}>{children}</InvoiceDraftContext.Provider>
}

export const useInvoiceDraft = (activityId: number): InvoiceDraftReturnValue => {
    const context = useContext(InvoiceDraftContext);
    const invoiceDraft = context[activityId];
    return {
        invoiceDraft: invoiceDraft ? omit(invoiceDraft, ["timestamp"]) : invoiceDraft,
        loading: invoiceDraft === false,
    }
}

export const deleteInvoiceDraftFromSession = (activityId: number): void => {
    try {
        const drafts = JSON.parse(window.sessionStorage.getItem("invoiceDrafts") || '{}');
        delete drafts[activityId];
        window.sessionStorage.setItem("invoiceDrafts", JSON.stringify(drafts));
    } catch (exc) {
        console.warn("❌ Failed to delete invoice draft from session storage!");
    }
}

export const updateInvoiceDraftInSession = (activityId: number, invoice: InvoiceDetails): void => {
    try {
        const drafts = JSON.parse(window.sessionStorage.getItem("invoiceDrafts") || '{}');
        drafts[activityId] = {
            ...invoice,
            taxes: unionBy(drafts[activityId]?.taxes, invoice.taxes, 'taxCode'),
        };
        window.sessionStorage.setItem("invoiceDrafts", JSON.stringify(drafts));
    } catch (exc) {
        console.warn("❌ Failed to update invoice draft in session storage!");
    }
}


export default InvoiceDraftProvider;
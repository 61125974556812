import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from 'components/Shared/Inputs';
import { Link } from 'react-router-dom';
import { CircularProgress } from '@mui/material';
import { FormRouteMap, TemplateNames } from 'components/Forms/constants';
import '../../views/UpdateApptStatus.scss';
// import { useFeatureManager } from 'hooks/useFeatureManager';
// import { Alert } from '@material-ui/lab';

interface AfterApptStatusUpdatedPromptProps {
    activityId: number,
    sessionId: number,
    hasFirstAssessment: boolean,
    hasLastAssessment: boolean,
    firstAssessmentCompleted: boolean,
    lastAssessmentCompleted: boolean,
    firstAssessmentCreationPending: boolean,
    lastAssessmentCreationPending: boolean,
    closureReportCreationPending: boolean,
    hasSessionNote: boolean,
    hasScheduleNextAppt: boolean,
    canBookSession: boolean,
    isHourlyContract: boolean,
    enableGad7: boolean,
    PHQ9FormId: string | null,
    Gad7FormId: string | null
}

export const AfterApptStatusUpdatedPrompt: FC<AfterApptStatusUpdatedPromptProps> = (props) => {
    const { activityId, sessionId, hasFirstAssessment, hasLastAssessment,
        firstAssessmentCompleted, lastAssessmentCompleted,
        firstAssessmentCreationPending, lastAssessmentCreationPending, canBookSession,
        hasScheduleNextAppt, hasSessionNote, PHQ9FormId, enableGad7 } = props;

    const { t } = useTranslation('caseManagement', { useSuspense: false });

    const canRenderCloseCaseButton =
        !hasScheduleNextAppt && (!hasFirstAssessment || (firstAssessmentCompleted && lastAssessmentCompleted));

    return (
        <div className="after_appt_status_updated_actions">
            {/* {
                isAdditionalHoursFeatureEnabled() && isHourlyContract && !canBookSession &&
                <>
                    <Alert severity="info">Went over hours</Alert>
                    <Button variant="opaque">
                        Request additional hours
                    </Button>
                </>
            } */}
            {
                hasScheduleNextAppt &&
                <Button variant="opaque" component={Link} to={`/clients/${activityId}/schedule_next_session`} disabled={!canBookSession}>
                    {t('schedule_appointment')}
                </Button>
            }
            {
                canRenderCloseCaseButton
                    ? <Button variant="opaque" component={Link} to={`/close_case/${activityId}`}>
                        {t('close_case')}
                    </Button>
                    : <>
                    {
                        (hasFirstAssessment && !firstAssessmentCompleted) &&
                        <Button variant="outline" component={Link} to={`/clients/${activityId}/forms/first_appointment_assessment`}
                            disabled={firstAssessmentCreationPending}>
                            {
                                firstAssessmentCreationPending &&
                                <CircularProgress size={16}/>
                            }
                            {t('begin_first_appointment_assessment')}
                            </Button>
                        }
                    {
                        (hasLastAssessment && !lastAssessmentCompleted) &&
                        <Button variant="outline" component={Link} to={`/clients/${activityId}/forms/last_appointment_assessment`}
                            disabled={lastAssessmentCreationPending}>
                            {
                                lastAssessmentCreationPending &&
                                <CircularProgress size={16}/>
                            }
                            {t('begin_last_appointment_assessment')}
                        </Button>
                    }
                    </>
            }
            {
                PHQ9FormId &&
                <Button variant="opaque" component={Link} to={`/clients/${activityId}/forms/${PHQ9FormId}`}>
                    {t('phq9_assessment_button_label')}
                </Button>
            }
            {/* {
                <Button variant="outline" component={Link} to={`/clients/${activityId}/sessions/${sessionId}/forms`}>
                    Enter administered assessment
                </Button>
            } */}
            {   
                (hasFirstAssessment && !firstAssessmentCompleted && enableGad7) &&
                <Button variant="outline" component={Link} to={`/clients/${activityId}/forms/${FormRouteMap[TemplateNames.Gad7]}`}
                    disabled={firstAssessmentCreationPending}>
                    {
                        firstAssessmentCreationPending &&
                        <CircularProgress size={16} />
                    }
                    {t('complete_gad7')}
                </Button>
            }                                
            {
                hasSessionNote &&
                <Button variant="outline" component={Link} to={`/clients/${activityId}/sessions/${sessionId}/forms/sessionnote`}>
                    {t('add_session_note')}
                </Button>
            }
            <Button variant="text" component={Link} to={`/clients/${activityId}`}>
                {t('go_to_client_page')}
            </Button>
            <Button variant="text" component={Link} to={'/'}>
                {t('go_to_dashboard')}
            </Button>
        </div>
    )
}
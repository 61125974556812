import React from 'react';
import { FormControl, InputLabel, InputLabelProps, FormHelperText, FormHelperTextProps, InputBase, InputBaseProps } from '@mui/material';
import clsx from 'clsx';

import './TextBox.scss';

interface TextBoxProps extends InputBaseProps {
    label?: React.ReactNode | string,
    InputLabelProps?: InputLabelProps,
    InputProps?: any,
    helperText?: string,
    helperTextId?: string,
    FormHelperTextProps?: FormHelperTextProps
}

export const TextBox = React.forwardRef<any, TextBoxProps>((props, ref) => {
    const { label, InputLabelProps, className, disabled, helperText, helperTextId,
        FormHelperTextProps, fullWidth, error, readOnly, style, InputProps, inputProps, ...rest } = props;

    const _readOnly = readOnly ?? inputProps?.readOnly;

    return (
        <FormControl ref={ref} disabled={disabled} error={error} fullWidth={fullWidth}
            className={clsx('ms_textbox', _readOnly && 'readonly', className)}
            variant="filled" style={style}
        >
            <InputLabel {...InputLabelProps}>{label}</InputLabel>
            <InputBase readOnly={readOnly} fullWidth={fullWidth} inputProps={inputProps} {...InputProps} {...rest}></InputBase>
            {
                helperText && (
                    <FormHelperText id={helperTextId} {...FormHelperTextProps}>
                        {helperText}
                    </FormHelperText>
                )
            }
        </FormControl>
    )
})

import { IconButton, Tooltip } from '@mui/material';
import React, { FC } from 'react';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import './style.scss'
import { useTranslation } from 'react-i18next';

export interface IPreviousArrowButtonProps {
    className?: string;
    disabled?: boolean;
    onClick?: () => void;
}

export const PreviousArrowButton: FC<IPreviousArrowButtonProps> = (props: IPreviousArrowButtonProps) => {
    const { t } = useTranslation('common', { useSuspense: false });

    const { disabled, className, ...rest } = props;

    const getIconButtonClassNames = () => {
        const defaultClasses = ['arrow-up-button', className];
        if (disabled) {
            defaultClasses.push('disabled')
        }
        return defaultClasses.join(' ')
    }

    return  (
        <Tooltip title={t('previous') || 'Previous'}>
            <div>
                <IconButton {...rest} disabled={disabled} className={getIconButtonClassNames()}> 
                    <ArrowUpwardIcon />
                </IconButton>
            </div>
        </Tooltip>
    )
}
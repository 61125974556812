import { Skeleton, TableBody, TableCell, TableRow } from "@mui/material";
import { FC } from "react";
import { ColumnDefinition, MaterialTableRow } from ".";

interface MaterialTableBodyProps {
    columns: ColumnDefinition[],
    data: any[] | undefined,
    dataLoading?: boolean,
    emptyStateMessage?: React.ReactNode,
    collapsible?: boolean,
    collapsibleRowTemplate?: (data: any) => React.ReactNode
}

export const MaterialTableBody: FC<MaterialTableBodyProps> = (props) => {
    const { columns, data, dataLoading, emptyStateMessage, collapsible, collapsibleRowTemplate } = props;
    
    const renderTableBodyContent = () => {
        if (dataLoading) {
            return (
                <>{
                    [...Array(10)].map((_, index) => (
                        <TableRow key={index}>
                            {
                                collapsible &&
                                <TableCell></TableCell>
                            }
                            { 
                                columns.map((_, columnIndex) => <TableCell key={columnIndex}><Skeleton /></TableCell>)
                            }
                        </TableRow>
                    ))
                }
                </>
            )
        } else if ((data === undefined || data.length === 0) && emptyStateMessage) {
            return (
                <TableRow className="table_body_row no_data_found_table_body_row">
                    <TableCell className="table_body_cell" colSpan={collapsible ? columns.length + 1 : columns.length}>
                        {emptyStateMessage}
                    </TableCell>
                </TableRow>
            )
        } else {
            return data?.map((row, index) => (
                <MaterialTableRow columns={columns} data={row} key={index} index={index}
                    collapsible={collapsible} collapsibleRowTemplate={collapsibleRowTemplate}/>
            )) 
        }
    }

    return (
        <TableBody className="table_body">
            {renderTableBodyContent()}
        </TableBody>
    )
}
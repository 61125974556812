export function getFirstSession<TSession extends { startDateTimeUTC: string}>(sessions?: TSession[]): TSession | undefined {
    if (!sessions || sessions.length === 0) return;
    
    let minDate = new Date(sessions[0].startDateTimeUTC).getTime();
    let firstSession = sessions[0];
    
    if (sessions.length > 0) {    
        sessions.forEach(session => {
            const sessionDate = new Date(session.startDateTimeUTC).getTime();
            if (sessionDate < minDate) {
                firstSession = session;
                minDate = sessionDate;
            }
        });
    }

    return firstSession;
}

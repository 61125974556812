import React, { FC } from 'react';
import { ToggleButton, ToggleButtonGroup, ToggleButtonGroupProps } from '@mui/material';
import { useTranslation } from 'react-i18next';


interface DayPickerProps extends ToggleButtonGroupProps {
    disabled: boolean
}

export const DayPicker: FC<DayPickerProps> = (props) => {
    const { t } = useTranslation('providerprofile', { useSuspense: false });

    return (
        <div className="recurring_days_picker_container">
            <h3 className="global__header4 global__header4--light recurring_days_picker_container_title">{t('repeats_on')}:</h3>
            <ToggleButtonGroup
                className='unavailability_recurring_days_picker_group'
                {...props}
            >
                <ToggleButton className="day_button" disabled={props.disabled} value={1}>
                    {t('mon')}
                </ToggleButton>
                <ToggleButton className="day_button" disabled={props.disabled} value={2}>
                    {t('tue')}
                </ToggleButton>
                <ToggleButton className="day_button" disabled={props.disabled} value={3}>
                    {t('wed')}
                </ToggleButton>
                <ToggleButton className="day_button" disabled={props.disabled} value={4}>
                    {t('thu')}
                </ToggleButton>
                <ToggleButton className="day_button" disabled={props.disabled} value={5}>
                    {t('fri')}
                </ToggleButton>
                <ToggleButton className="day_button" disabled={props.disabled} value={6}>
                    {t('sat')}
                </ToggleButton>
                <ToggleButton className="day_button" disabled={props.disabled} value={7}>
                    {t('sun')}
                </ToggleButton>
            </ToggleButtonGroup>
        </div>
    )
}
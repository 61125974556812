import { useAppConfig } from 'providers/AppConfig';
import React, { createContext, FC } from 'react';
import { RegionConfig } from './interfaces';

interface RegionConfigProviderProps {}

interface RegionConfigContextProps extends RegionConfig {}

const RegionConfigContext = createContext<RegionConfigContextProps>({
    defaultLng: '',
    languages: [],
    providerContactUrl: '',
    helpCenterNumber: '1-800-234-5154',
    features: [],
    techSupportUrls: {
        en: { url: '', vanityUrl: '' },
        fr: { url: '', vanityUrl: '' }
    },
    helpUrls: {
        en: { url: '', vanityUrl: '' },
        fr: { url: '', vanityUrl: '' }
    }
});

RegionConfigContext.displayName = 'RegionConfigContext';

const RegionConfigProvider: FC<RegionConfigProviderProps> = (props) => {
    const { RegionConfigs } = useAppConfig();
    const regionConfig = RegionConfigs[window.location.host] ?? {};

    const contextValue: RegionConfigContextProps = {
        providerContactUrl: '',
        techSupportUrls: {
            en: { url: '', vanityUrl: '' },
            fr: { url: '', vanityUrl: '' }
        },
        helpUrls: {
            en: { url: '', vanityUrl: '' },
            fr: { url: '', vanityUrl: '' }
        },
        defaultLng: 'en-US',
        languages: [{
            name: "English",
            code: "en-US",
            fallback: ["en"]
        }],
        helpCenterNumber: '1-800-234-5154',
        features: [],
        ...regionConfig
    }

    return <RegionConfigContext.Provider value={contextValue} {...props} />
}


const useRegionConfig = () => React.useContext(RegionConfigContext);

export { RegionConfigProvider, useRegionConfig, RegionConfigContext };
import { useAppConfig } from 'providers/AppConfig';
import { useRef, useState } from 'react';
import { LoginResponse, RequestHook, SwitchProfileInput } from '..';

export const useSwitchProfileRequest: RequestHook<LoginResponse, SwitchProfileInput> = (props) => {
    const { REACT_APP_GRAPHQL_API_HOST } = useAppConfig();
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState<LoginResponse | null>(null);
    const [error, setError] = useState<any | null>(null);
    let { current: promise } = useRef<Promise<LoginResponse> | null>(null);

    const switchProfile = (input: SwitchProfileInput) => {
        setLoading(true);
        promise = (props?.fetch || fetch)(new URL("/api/Auth/SwitchProfile", REACT_APP_GRAPHQL_API_HOST).toString(), {
            method: 'POST',
            mode: 'cors',
            cache: 'no-cache',
            credentials: 'include',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(input)
        }).then(response => (
            response.json()
        )).then(result => {
            setData(result);
            setError(null);
            props?.onCompleted?.(result);
            return result;
        }).catch(error => {
            setData(null);
            setError(error);
            props?.onError?.(error);
            throw error;
        }).finally(() => {
            setLoading(false);
            promise = null;
        });

        return promise;
    }
    

    return [
        switchProfile,
        {
            loading: loading,
            data: data,
            error: error,
            promise: promise
        }
    ]
}
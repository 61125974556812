import React, { useEffect, FC } from 'react';
import { useTranslation } from 'react-i18next';
import moment, { Moment } from 'moment';
import useTimezoneUtils from 'hooks/useTimezoneUtils';
import { FormikErrors, useFormik } from 'formik';
import { Button, DateTimeInput } from 'components/Shared/Inputs';
import { Spinner } from 'components/Shared/Spinner';
import { ContactAttempt } from '../../interfaces';

const parseContactAttempts = (contactClientAttempts?: ContactAttempt[]): Moment[] => (
    contactClientAttempts?.map((attempt) => moment(attempt.contactDate)) || []
)

interface ContactAttemptsFormProps {
    clientName: string,
    contactAttempts: ContactAttempt[] | undefined,
    onSaveAttempts: (contactAttempts: Date[]) => void,
    onCloseCase: (contactAttempts: Date[]) => void,
    disabled: boolean
}

interface ContactAttemptsFormValues {
    firstAttempt: Moment | null,
    secondAttempt: Moment | null,
    thirdAttempt: Moment | null
}


export const ContactAttemptsForm: FC<ContactAttemptsFormProps> = (props) => {
    const { clientName, contactAttempts, onSaveAttempts, onCloseCase, disabled } = props;

    const { t, ready: translationsReady } = useTranslation(['schedule', 'common'], { useSuspense: false });

    const { dateTimeInputFormat } = useTimezoneUtils()

    const formik = useFormik<ContactAttemptsFormValues>({
        initialValues: {
            firstAttempt: null,
            secondAttempt: null,
            thirdAttempt: null,
        },
        validate: (values) => {
            const errors: FormikErrors<ContactAttemptsFormValues> = {};

            if (values.firstAttempt && !values.firstAttempt.isValid) {
                errors.firstAttempt = "First appointment date is invalid";
            }
            if (values.firstAttempt && !values.firstAttempt.isValid) {
                errors.firstAttempt = "Second appointment date is invalid";
            }
            if (values.firstAttempt && !values.firstAttempt.isValid) {
                errors.firstAttempt = "Third appointment date is invalid";
            }

            return errors;
        },
        onSubmit: () => {}
    });

    
    useEffect(() => {
        const initialAttempts: Moment[] = parseContactAttempts(contactAttempts);

        formik.resetForm({
            values: {
                firstAttempt: initialAttempts[0] ? moment(initialAttempts[0]) : null,
                secondAttempt: initialAttempts[1] ? moment(initialAttempts[1]) : null,
                thirdAttempt: initialAttempts[2] ? moment(initialAttempts[2]) : null
            }
        })
    }, [contactAttempts]);


    const attempts = [formik.values.firstAttempt, formik.values.secondAttempt, formik.values.thirdAttempt];
    const validAttempts = attempts.filter(attempt => Boolean(attempt)).map(attempt => attempt!.toDate());
    const allFieldsAreEmpty = validAttempts.length === 0;
    
    const submitDisabled = disabled || allFieldsAreEmpty || !formik.isValid;

    const handleFirstAttemptDateChange = (date: Moment | null) => {
        formik.setFieldValue('firstAttempt', date);
    }
    const handleSecondAttemptDateChange = (date: Moment | null) => {
        formik.setFieldValue('secondAttempt', date);
    }
    const handleThirdAttemptDateChange = (date: Moment | null) => {
        formik.setFieldValue('thirdAttempt', date);
    }
    
    const handleSaveAttempts = () => {
        formik.validateForm().then(errors => {
            if (Object.keys(errors).length === 0) {
                onSaveAttempts(validAttempts);
            }
        });
    }

    const handleCloseCase = () => {
        formik.validateForm().then(errors => {
            if (Object.keys(errors).length === 0) {
                onCloseCase(validAttempts);
            }
        });
    }

    return !translationsReady
        ? <Spinner fillParent/>
        : <div className="update_appt_status_step">
            <p>
                {t('youve_tried_reaching_out_to_3_times_in_the_past_48_hours_but_werent_able_to_reach_him', { firstName: clientName })}
            </p>
            <p>
                {t('case_will_now_be_closed_before_we_can_close_the_case_please_let_us_know_when_you_attempted_to_reach', { firstName: clientName })}
            </p>
            <DateTimeInput
                value={formik.values.firstAttempt}
                className="appt_status_dropdown"
                onChange={handleFirstAttemptDateChange}
                format={dateTimeInputFormat}
                label={t('first_attempt')}
                maxDate={moment()}
            />
            <DateTimeInput
                value={formik.values.secondAttempt}
                className="appt_status_dropdown"
                onChange={handleSecondAttemptDateChange}
                format={dateTimeInputFormat}
                label={t('second_attempt')}
                maxDate={moment()}
            />
            <DateTimeInput
                value={formik.values.thirdAttempt}
                className="appt_status_dropdown"
                onChange={handleThirdAttemptDateChange}
                format={dateTimeInputFormat}
                label={t('third_attempt')}
                maxDate={moment()}
            />
            <Button type="button" onClick={handleSaveAttempts} disabled={submitDisabled} className="appt_status_dropdown">
                {t('save_attempts')}
            </Button>
            <Button type="button" onClick={handleCloseCase} disabled={submitDisabled} className="appt_status_dropdown">
                {t('close_case')}
            </Button>
        </div>
}
import React, { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { PRACTICE_INFO_QUERY } from './queries';
import { useApolloClient, useQuery } from '@apollo/client';
import { Spinner } from 'components/Shared/Spinner';
import './PracticeInfo.scss';
import { InsurancePanels } from '../../Sections/InsurancePanels';
import { LanguagesServed } from '../../Sections/LanguagesServed';
import { ContractedServices } from '../../Sections/ContractedServices';
import { OfficeLocations } from '../../Sections/OfficeLocations';
import { PracticeCommunicationPreferences } from '../../Sections/PracticeCommunicationPreferences';
import { OrganizationExceptions } from '../../Sections/OrganizationExceptions';
import { InsurancePanel, Language, OrganizationException } from '../../../interfaces';

interface PracticeInfoProps {
    providerId: number
}

export const PracticeInfo: FC<PracticeInfoProps> = ({providerId}) => {
    const { ready: translationsReady } = useTranslation('providerprofile', { useSuspense: false });
    const { cache } = useApolloClient();
    
    const practiceInfoQuery = useQuery(PRACTICE_INFO_QUERY, {
        fetchPolicy: 'network-only',
        variables: { providerId: providerId },
        errorPolicy: 'all'
    })

    const providerDetails = practiceInfoQuery.data?.provider?.details;

    const addressess = practiceInfoQuery.data?.provider?.contactInfo?.addresses;
    const insurancePanels = providerDetails?.insurancePanels;
    const contractedServices = providerDetails?.contractedServices;
    const servedLanguages = providerDetails?.servedLanguages;
    const exceptions = providerDetails?.organizationExceptions;

    const preferredCommunicationForNonConnected = providerDetails?.preferredCommunicationForNonConnected;
    const preferredCommunicationForNonConnectedString = providerDetails?.preferredCommunicationForNonConnectedString;

    const showInsurancePanels = providerDetails?.showInsurancePanels ?? false;

    const loading = !translationsReady || practiceInfoQuery.loading;
    
    const handleLanguagesUpdated = (languages?: Language[]) => {
        cache.writeQuery({
            query: PRACTICE_INFO_QUERY,
            variables: { providerId: providerId },
            data: {
                ...practiceInfoQuery.data,
                provider: {
                    id: providerId,
                    ...practiceInfoQuery.data?.provider,
                    details: {
                        ...practiceInfoQuery.data?.provider?.details,
                        servedLanguages: languages
                    }
                }
            }
        });
    }

    const handleInsurancePanelsUpdated = (insurancePanels: InsurancePanel[]) => {
        cache.writeQuery({
            query: PRACTICE_INFO_QUERY,
            variables: { providerId: providerId },
            data: {
                ...practiceInfoQuery.data,
                provider: {
                    id: providerId,
                    ...practiceInfoQuery.data?.provider,
                    details: {
                        ...practiceInfoQuery.data?.provider?.details,
                        insurancePanels: insurancePanels
                    }
                }
            }
        });
    }

    const handlePreferredCommunicationUpdated = (values) => {
        cache.writeQuery({
            query: PRACTICE_INFO_QUERY,
            variables: { providerId: providerId },
            data: {
                ...practiceInfoQuery.data,
                provider: {
                    id: providerId,
                    ...practiceInfoQuery.data?.provider,
                    details: {
                        ...practiceInfoQuery.data?.provider?.details,
                        ...values
                    }
                }
            }
        });
    }

    const handleOrgExceptionsUpdated = (orgExceptions: OrganizationException[]) => {
        cache.writeQuery({
            query: PRACTICE_INFO_QUERY,
            variables: { providerId: providerId },
            data: {
                ...practiceInfoQuery.data,
                provider: {
                    id: providerId,
                    ...practiceInfoQuery.data?.provider,
                    details: {
                        ...practiceInfoQuery.data?.provider?.details,
                        organizationExceptions: orgExceptions
                    }
                }
            }
        });
    }

    const smsPhoneNumber = useMemo(() => 
        providerDetails?.smsPhoneNumbers?.find(phone => phone.receiveSms)
    , [providerDetails?.smsPhoneNumbers]);

    return loading
        ? <Spinner fillParent/>
        : <>
            <OfficeLocations addresses={addressess}/>
            <PracticeCommunicationPreferences providerId={providerId}
                smsPhoneNumber={smsPhoneNumber} emailAddress={providerDetails?.emailAddress}
                allowEmailCommunication={providerDetails?.allowEmailCommunication}
                allowSmsCommunication={providerDetails?.allowSmsCommunication}
                preferredCommunicationForNonConnected={preferredCommunicationForNonConnected}
                preferredCommunicationForNonConnectedString={preferredCommunicationForNonConnectedString}
                onUpdated={handlePreferredCommunicationUpdated}
            />
            <LanguagesServed providerId={providerId} languages={servedLanguages} onUpdated={handleLanguagesUpdated}/>
            {
                showInsurancePanels &&
                <InsurancePanels providerId={providerId} insurancePanels={insurancePanels} onInsurancePanelsUpdated={handleInsurancePanelsUpdated}/>
            }
            <OrganizationExceptions providerId={providerId} exceptions={exceptions} onUpdated={handleOrgExceptionsUpdated}/>
            <ContractedServices contractedServices={contractedServices}/>
        </>
}

import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Education as IEducation } from '../../../interfaces';
import { SectionContainer } from '../../Other/SectionContainer';
import { useMutation } from '@apollo/client';
import { UPDATE_EDUCATIONS_MUTATION } from './queries';
import { EducationForm } from './EducationForm';
import { useFeatureManager } from 'hooks/useFeatureManager';

interface EducationProps {
    providerId: number,
    educations?: IEducation[],
    onEducationsUpdated: (educations: IEducation[]) => void
}

export const Education: FC<EducationProps> = (props) => {
    const { providerId, educations, onEducationsUpdated } = props;
    const { t, ready: translationsReady } = useTranslation('providerprofile', { useSuspense: false });
    
    const { isProviderProfileSelfServeFeatureEnabled } = useFeatureManager();

    const [cachePendingUpdate, setCachePendingUpdate] = useState(false);

    const [updateEducations, updateEducationsMutation] = useMutation(UPDATE_EDUCATIONS_MUTATION, {
        update: (_, result) => {
            if (result.data?.updateCredentials?.credential?.educations) {
                setCachePendingUpdate(true);
                onEducationsUpdated(result.data.updateCredentials.credential.educations);
            }
        }
    });

    useEffect(() => {
        setCachePendingUpdate(false);
    }, [educations]);
    
    return !translationsReady
        ? <></>
        : <SectionContainer title={t('education__section_title')}>
            {
                educations?.map((education, index) => (
                    <EducationForm key={index}
                        providerId={providerId}
                        education={education}
                        educations={educations}
                        mutation={[updateEducations, updateEducationsMutation]}
                        cachePendingUpdate={cachePendingUpdate}
                    />
                ))
            }
            {
                isProviderProfileSelfServeFeatureEnabled() &&
                <EducationForm
                    providerId={providerId}
                    education={undefined}
                    educations={educations}
                    mutation={[updateEducations, updateEducationsMutation]}
                    cachePendingUpdate={cachePendingUpdate}
                />
            }
        </SectionContainer>
}
import { gql, TypedDocumentNode } from '@apollo/client';
import { ReportType } from './constants';
import { InvoiceTableItem, InvoiceDetails, IActivityInvoiceData } from './interfaces';

export const INVOICES_LIST_QUERY
    : TypedDocumentNode<InvoicesListQueryData, InvoicesListQueryVars> = gql`
    query INVOICES_QUERY($providerId: Long!) {
        invoices(providerId: $providerId) {
            invoiceId
            activityId
            createdDateUtc
            submittedDateUtc
            caseAcceptedDateUtc
            statusId
            statusString
            currencyCode
            grandTotal
            client
            providerInvoiceNumber
            wspTaskCode
            isActivityAvailable
        }
    }
`

export interface InvoicesListQueryVars {
    providerId: number
}

export interface InvoicesListQueryData {
    invoices: InvoiceTableItem[]
}



export const INVOICE_STATUS_REF_DATA_QUERY
    : TypedDocumentNode<InvoiceStatusRefDataData, InvoiceStatusRefDataVars> = gql`
    query INVOICE_STATUS_REF_DATA_QUERY {
        invoiceStatuses {
            title
            value
        }
    }
`

interface InvoiceStatusRefDataVars {
    
}

interface InvoiceStatusRefDataData {
    invoiceStatuses: {
        title: string,
        value: string
    }[]
}



export const ACTIVITY_QUERY_INVOICES: TypedDocumentNode<InvoicesQueryData, InvoicesQueryVars> = gql`
    query ACTIVITY_QUERY_INVOICES($activityId: Long!) {
        activity(activityId: $activityId) {
            providerId
            activityId
            solutionCode
            service
            invoiceAllowed
            openDate
            isWSPActivity
            isProviderHubActivity
            invoices {
                invoiceId
                activityId
                createdDateUtc
                submittedDateUtc
                subTotal
                statusEnum
            }
            invoicableSessions {
                activityId
                duration
                sessionId
                startDateTime
                startDateTimeUTC
                sessionStatus
                sessionStatusId
            }
        }
    }
`

export interface InvoicesQueryVars {
    activityId: number
}

export interface InvoicesQueryData {
    activity: IActivityInvoiceData
}



const INVOICE_DRAFT_FRAGMENT = gql`
    fragment InvoiceDraftDetails on Invoice { 
        grandTotal
        currencyCode
        invoiceId
        providerInvoiceNumber
        addCaseManagementTime
        displayCaseManagementTime
        createdDate
        comments
        totalFees
        totalExpenses
        statusId
        statusString
        billables {
            activityInvoiceId
            activityId
            sessionId
            wspTaskCode
            wspReportType
            wspReportTypeString
            wspMonthlyReportYear
            wspMonthlyReportMonth
            amount
            currencyCode
            currencyDescription
            feeCode
            date
            dateUtc
            feeCodeDescription
            hours
            rate
            rateTypeCode
        }
        taxes {
            taxCode
            taxDescription
            amount
        }
        expenses {
            date
            expenseTypeCode
            expenseTypeDescription
            currencyCode
            currencyDescription
            amount
        }
        provider {
            providerId
            emailAddress
            firstName
            lastName
            businessNumber
            contactInfo {
                addresses {
                    addressLine1
                    addressLine2
                    city
                    zIP_Postal
                    state_ProvinceCode
                    provinceAbbreviation
                    countryCode
                    officeType
                }
                phones {
                    phoneNumber
                }
            }
            vendor {
                vendorId
                name
                email
                address {
                    addressLine1
                    addressLine2
                    city
                    zIP_Postal
                    state_ProvinceCode
                    provinceAbbreviation
                    countryCode
                }
            }
        }
    }
`


const INVOICE_DETAILS_FRAGMENT = gql`
    fragment InvoiceDetails on Invoice { 
        grandTotal
        currencyCode
        invoiceId
        providerInvoiceNumber
        createdDate
        submittedDate
        comments
        wspTaskCode
        approverComment
        statusId
        statusString
        totalFees
        addCaseManagementTime
        displayCaseManagementTime
        billables {
            activityId
            sessionId
            wspTaskCode
            wspReportType
            wspReportTypeString
            wspMonthlyReportYear
            wspMonthlyReportMonth
            amount
            currencyCode
            currencyDescription
            feeCode
            date
            dateUtc
            feeCodeDescription
            hours
            rate
            rateTypeCode
        }
        taxes {
            taxCode
            taxDescription
            amount
        }
        expenses {
            date
            expenseTypeCode
            expenseTypeDescription
            currencyCode
            currencyDescription
            amount
        }
        activity {
            activityId
            service
        }
        provider {
            providerId
            emailAddress
            firstName
            lastName
            businessNumber
            contactInfo {
                addresses {
                    addressLine1
                    addressLine2
                    city
                    zIP_Postal
                    state_ProvinceCode
                    provinceAbbreviation
                    countryCode
                    officeType
                }
                phones {
                    phoneNumber
                }
            }
            vendor {
                vendorId
                name
                email
                address {
                    addressLine1
                    addressLine2
                    city
                    zIP_Postal
                    state_ProvinceCode
                    provinceAbbreviation
                    countryCode
                }
            }
        }
    }
`



export const INVOICE_DETAILS_QUERY: TypedDocumentNode<InvoiceDetailsQueryData, InvoiceDetailsQueryVars> = gql`
    query INVOICE_DETAILS_QUERY($invoiceId: Long!, $providerId: Long!) {
        invoice(invoiceId: $invoiceId, providerId: $providerId) {
            activity {
                activityId
                isWSPActivity
            }
            ...InvoiceDetails
        }
    }
    ${INVOICE_DETAILS_FRAGMENT}
`

export interface InvoiceDetailsQueryVars {
    invoiceId: number,
    providerId: number
}

export interface InvoiceDetailsQueryData {
    invoice: InvoiceDetails & {
        activity: {
            isWSPActivity: boolean
        }
    }
}



export const GET_ACTIVITY_TAXES = gql`
    query GET_ACTIVITY_TAXES($activityId: Long!) {
        activityInvoiceRefData(activityId: $activityId) {
            availableTaxes {
                taxCode
            }
        }  
    }
`;



export const APPLY_TAXES: TypedDocumentNode<ApplyTaxesMutationData, ApplyTaxesMutationVars> = gql`
    mutation APPLY_TAXES($providerId: Long!, $invoiceId: Long!, $taxCodes: [String]) {
        invoice: applyTaxes(providerId: $providerId, invoiceId: $invoiceId, taxCodes: $taxCodes) {
            ...InvoiceDraftDetails
        }  
    }
    ${INVOICE_DRAFT_FRAGMENT}
`;

export interface ApplyTaxesMutationData {
    invoice: InvoiceDetails
}

export interface ApplyTaxesMutationVars {
    invoiceId: number,
    providerId: number
    taxCodes: string[], 
}



export const SUBMIT_INVOICE: TypedDocumentNode<SubmitInvoiceMutationData, SubmitInvoiceMutationVars> = gql`
    mutation SUBMIT_INVOICE($activityId: Long!, $providerId: Long!, $invoiceId: Long!, $providerInvoiceNumber: String, $comments: String, $addCaseManagementTime: Boolean) {
        invoice: submitInvoice(activityId: $activityId, providerId: $providerId, invoiceId: $invoiceId, providerInvoiceNumber: $providerInvoiceNumber, comments: $comments, addCaseManagementTime: $addCaseManagementTime) {
            ...InvoiceDraftDetails
        }  
    }
    ${INVOICE_DRAFT_FRAGMENT}
`;

export interface SubmitInvoiceMutationData {
    invoice: InvoiceDetails
}

export interface SubmitInvoiceMutationVars {
    activityId: number,
    invoiceId: number,
    providerId: number
    providerInvoiceNumber: string, 
    comments: string,
    addCaseManagementTime?: boolean
}



export const DELETE_BILLABLE: TypedDocumentNode<DeleteBillableMutationData, DeleteBillableMutationVars> = gql`
    mutation DELETE_BILLABLE($billableId: Long!, $providerId: Long!, $invoiceId: Long!) {
        invoice: deleteBillable(billableId: $billableId, providerId: $providerId, invoiceId: $invoiceId) {
            ...InvoiceDraftDetails
        }  
    }
    ${INVOICE_DRAFT_FRAGMENT}
`;

export interface DeleteBillableMutationData {
    invoice: InvoiceDetails
}

export interface DeleteBillableMutationVars {
    invoiceId: number,
    providerId: number
    billableId: number
}



export const ADD_BILLABLE: TypedDocumentNode<AddBillableMutationData, AddBillableMutationVars> = gql`
    mutation ADD_BILLABLE($providerId: Long!, $activityId: Long!, $invoiceId: Long!, $taskCode: String!, $date: DateTime!,
        $duration: Float!, $wspReportType: String, $wspMonthlyReportYear: Int, $wspMonthlyReportMonth: Int) {
        invoice: addBillable(providerId: $providerId, activityId: $activityId, invoiceId: $invoiceId, taskCode: $taskCode, date: $date,
            duration: $duration, wspReportType: $wspReportType, wspMonthlyReportYear: $wspMonthlyReportYear, wspMonthlyReportMonth: $wspMonthlyReportMonth) {
            ...InvoiceDraftDetails
        }  
    }
    ${INVOICE_DRAFT_FRAGMENT}
`;

export interface AddBillableMutationData {
    invoice: InvoiceDetails
}

export interface AddBillableMutationVars {
    invoiceId: number,
    providerId: number
    activityId: number,
    taskCode: string,
    date: string,
    duration: number,
    wspReportType?: string,
    wspMonthlyReportYear?: number,
    wspMonthlyReportMonth?: number
}



export const EDIT_BILLABLE: TypedDocumentNode<EditBillableMutationData, EditBillableMutationVars> = gql`
    mutation EDIT_BILLABLE($billableId: Int!, $providerId: Long!, $activityId: Long!, $invoiceId: Long!, $taskCode: String!, $date: DateTime!,
        $duration: Float!, $wspReportType: String, $wspMonthlyReportYear: Int, $wspMonthlyReportMonth: Int) {
        invoice: editBillable(billableId: $billableId, providerId: $providerId, activityId: $activityId, invoiceId: $invoiceId, taskCode: $taskCode, date: $date,
            duration: $duration, wspReportType: $wspReportType, wspMonthlyReportYear: $wspMonthlyReportYear, wspMonthlyReportMonth: $wspMonthlyReportMonth) {
            ...InvoiceDraftDetails
        }  
    }
    ${INVOICE_DRAFT_FRAGMENT}
`;

export interface EditBillableMutationData {
    invoice: InvoiceDetails
}

export interface EditBillableMutationVars {
    billableId: number,
    invoiceId: number,
    providerId: number
    activityId: number,
    taskCode: string,
    date: string,
    duration: number,
    wspReportType?: string,
    wspMonthlyReportYear?: number,
    wspMonthlyReportMonth?: number
}



export const CREATE_INVOICE_DRAFT: TypedDocumentNode<CreateInvoiceDraftMutationData, CreateInvoiceDraftMutationVars> = gql`
    mutation CREATE_INVOICE_DRAFT($providerId: Long!, $activityId: Long!) {
        invoice: createInvoiceDraft(providerId: $providerId, activityId: $activityId) {
            ...InvoiceDraftDetails
        }
    }
    ${INVOICE_DRAFT_FRAGMENT}
`;

export interface CreateInvoiceDraftMutationData {
    invoice: InvoiceDetails
}

export interface CreateInvoiceDraftMutationVars {
    providerId: number,
    activityId: number
}



export const INVOICE_PREVIEW_QUERY = gql`
    query INVOICE_DETAILS_QUERY($activityId: Long!) {
        invoice: invoiceDraft(activityId: $activityId) {
            ...InvoiceDetails
        }
    }
    ${INVOICE_DETAILS_FRAGMENT}
`;

export interface InvoicePreviewQueryData {
    invoice: InvoiceDetails
}

export interface InvoicePreviewQueryVars {
    activityId: number
}


export const REPORT_TYPE_SELECT_AVAILABLE_QUERY: TypedDocumentNode<ReportTypeSelectAvailableQueryData, ReportTypeSelectAvailableQueryVars> = gql`
    query REPORT_TYPE_SELECT_AVAILABLE_QUERY($activityId: Long!, $taskCode: String!) {
        reportTypeSelectAvailable(activityId: $activityId, taskCode: $taskCode)
    }
`

export interface ReportTypeSelectAvailableQueryData {
    reportTypeSelectAvailable: boolean
}

export interface ReportTypeSelectAvailableQueryVars {
    activityId: number,
    taskCode: string
}


export const SUBMITTED_REPORT_TYPES_QUERY: TypedDocumentNode<SubmittedReportTypesQueryData, SubmittedReportTypesQueryVars> = gql`
    query SUBMITTED_REPORT_TYPES_QUERY($activityId: Long!) {
        submittedReportTypes(activityId: $activityId) {
            value
            title
        }
    }
`

export interface SubmittedReportTypesQueryData {
    submittedReportTypes: {
        title: string,
        value: ReportType
    }[]
}

export interface SubmittedReportTypesQueryVars {
    activityId: number
}
import React, { FC, useMemo } from 'react';

import './index.scss';
import { Table, TableBody, TableCell, TableContainer, TableRow } from '@mui/material';

import { toCurrencyString } from 'utils';
import { useTranslation } from 'react-i18next';
import { Button } from 'components/Shared/Inputs';
import { IDurationConstant } from 'hooks/useDurationConstants/interfaces';
import { IWspTaskCode } from 'hooks/useWspTaskCodes/interfaces';
import { Add as AddIcon } from '@mui/icons-material';
import { InvoiceBillable } from '../../interfaces';
import { ColumnDefinition, MaterialTableHeader, MaterialTableRow } from 'components/Shared/MaterialTable';
import moment, { Moment } from 'moment';
import useTimezoneUtils from 'hooks/useTimezoneUtils';
import { calculateBillableRate } from 'components/Invoicing/utils/billableUtils';
import { Card } from 'components/Shared/Card';

interface InvoiceWspTableProps {
    wspTasks: InvoiceBillable[],
    durationConstants: IDurationConstant[] | undefined,
    allWspTaskCodes: IWspTaskCode[] | undefined,
    editDisabled: boolean,
    onDeleteTask: (task) => void,
    onEditTask: (task) => void,
    onAddTask: () => void
}

export const WspTasksTable: FC<InvoiceWspTableProps> = (props) => {
    const { wspTasks, allWspTaskCodes, durationConstants, editDisabled,
        onDeleteTask, onEditTask, onAddTask } = props;

    const { i18n, t } = useTranslation(['Invoicing', 'common'], {useSuspense: false});
    const totalAmount = useMemo(() => wspTasks.reduce((acc, task) => acc + (task.amount || 0), 0), [wspTasks]);

    const { formatDate } = useTimezoneUtils();

    const formatWspDate = (date: Moment | Date | string) => {
        return formatDate(date, 'l')
    }

    const formatWspTime = (date: Moment | Date | string) => {
        return formatDate(date, 'LT')
    }

    const displayDuration = (task: InvoiceBillable) => (
        durationConstants?.find(duration => Number(duration.value) === task.hours * 4)?.title
    )

    const columns: ColumnDefinition[] = [{
        title: t('date'),
        template: (task: InvoiceBillable) => (
            task.dateUtc ? formatWspDate(task.dateUtc) : '---'
        )
    }, {
        title: t('time'),
        template: (task: InvoiceBillable) => (
            task.dateUtc ? formatWspTime(task.dateUtc) : '---'
        )
    }, {
        title: t('wsp_task_code'),
        template: (task: InvoiceBillable) => (
            <div className="wsp-cell-item">
                {allWspTaskCodes?.find(code => code.value === task.wspTaskCode)?.title ?? '---'}
                {
                    task.wspReportTypeString &&
                    <><br/><span>{task.wspReportTypeString}</span></>
                }
                {
                    task.wspMonthlyReportYear && task.wspMonthlyReportMonth &&
                    <><br/><span>{moment({year: task.wspMonthlyReportYear, month: task.wspMonthlyReportMonth - 1}).format('MMMM YYYY')}</span></>
                }
            </div>
        )
    }, {
        title: t('duration'),
        template: (task: InvoiceBillable) => (
            displayDuration(task) ?? '---'
        )
    }, {
        title: t('rate'),
        template: (task: InvoiceBillable) => (
            calculateBillableRate(task, t, i18n)
        )
    }, {
        title: t('amount'),
        template: (task: InvoiceBillable) => (
            toCurrencyString(task.amount, i18n.language, task.currencyCode) ?? '---'
        )
    }];

    if (wspTasks.length > 0) {
        columns.push(...[{
            template: (task: InvoiceBillable) => (
                <Button variant="text" disabled={editDisabled} onClick={() => onEditTask(task)}>
                    {t('edit_task')}
                </Button>
            ),
            width: 120
        }, {
            template: (task: InvoiceBillable) => (
                <Button variant="text" disabled={editDisabled} onClick={() => onDeleteTask(task)}>
                    {t('delete_task')}
                </Button>
            ),
            width: 120
        }]);
    }

    return (
        <div className='invoice_wsp_table_wrapper'>
            <h3>{t('tasks')}</h3>
            <TableContainer component={Card} className="table_container" elevation={0}>
                <Table className="table">
                    <MaterialTableHeader columns={columns}/>
                    <TableBody className="table_body">
                        {
                            wspTasks?.map((task, index) => (
                                <MaterialTableRow columns={columns} data={task} key={index} index={index}/>
                            ))
                        }
                        <TableRow className="table_body_row">
                            <TableCell className="table_body_cell" colSpan={columns.length}>
                                <Button variant="text" onClick={onAddTask} startIcon={<AddIcon/>}>
                                    {t('add_task')}
                                </Button>
                            </TableCell>
                        </TableRow>
                        <TableRow className="table_body_row">
                            <TableCell className="table_body_cell total_label_cell" colSpan={5}>{t('total')}</TableCell>
                            <TableCell className="table_body_cell">{toCurrencyString(totalAmount, i18n.language, wspTasks.length > 0 ? wspTasks[0].currencyCode : '') ?? '---'}</TableCell>
                            {
                                columns.length > 6 &&
                                <TableCell colSpan={2} className="table_body_cell"/>
                            }
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    )
}
import { gql, TypedDocumentNode } from "@apollo/client";
import { LiabilityInsurance } from '../../../interfaces';

export const UPDATE_LIABILITY_INSURANCE_MUTATION
    : TypedDocumentNode<UpdateLiabilityInsuranceMutationData, UpdateLiabilityInsuranceMutationVars> = gql`
    mutation UPDATE_LIABILITY_INSURANCE_MUTATION($providerId: Long!, $expiryDate: String, $onFile: Boolean!) {
        updateLiabilityInsurance(providerId: $providerId, expiryDate: $expiryDate, onFile: $onFile) {
            providerId
            liabilityInsurance {
                expiryDate
                onFile
            }
        }
    }
`

export interface UpdateLiabilityInsuranceMutationData {
    updateLiabilityInsurance?: {
        liabilityInsurance?: LiabilityInsurance
    }
}

export interface UpdateLiabilityInsuranceMutationVars {  
    providerId: number,
    expiryDate: string,
    onFile: boolean
}

import { useNavigate, useLocation } from 'react-router-dom';

export function useHistoryState<TData>(initialValues?: TData): [TData | undefined, (values: Partial<TData>) => void] {
    const navigate = useNavigate();
    const { state, pathname } = useLocation();

    const updateState = (values: Partial<TData>) => {
        const newState = {
            ...(state ?? {}),
            ...values
        }
        navigate(pathname, { state: newState, replace: true });
    }
    
    if (state === undefined && initialValues !== undefined) {
        updateState(initialValues);
    }

    return [state, updateState];
}
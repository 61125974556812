export const AssessmentsPickerRoute = (activityId: number | string, sessionId?: number | string) => (
    sessionId
        ? `/clients/${activityId}/sessions/${sessionId}/forms`
        : `/clients/${activityId}/forms`
)

export const SessionNoteRoute = (activityId: number | string, sessionId: number | string) => (
    `/clients/${activityId}/sessions/${sessionId}/forms/sessionnote`
)

export const SessionStatusRoute = (activityId: number | string, sessionId: number | string) => (
    `/clients/${activityId}/sessions/${sessionId}/status`
)

export const ScheduleNextSessionRoute = (activityId: number | string) => (
    `/clients/${activityId}/schedule_next_session`
)
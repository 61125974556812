import React, { FC, useEffect, useMemo, useState } from 'react';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import { Grid, IconButton } from '@mui/material';
import { Button } from 'components/Shared/Inputs';
import { SectionContainer } from '../../Other/SectionContainer';
import { SPECIALIZATIONS_REF_DATA, UPDATE_SPECIALIZATIONS_MUTATION } from './queries';
import { useLazyQuery, useMutation } from '@apollo/client';
import RemoveIcon from '@mui/icons-material/HighlightOffRounded';
import AddIcon from '@mui/icons-material/AddRounded';
import { PickerDialog, Option } from '../../Other/PickerDialog';
import { Specialization } from '../../../interfaces';
import { confirm } from 'components/Shared/ConfirmDialog';
import { Spinner } from 'components/Shared';

interface SpecializationsProps {
    providerId: number,
    specializations?: Specialization[],
    onSpecializationsUpdated: (specializations: Specialization[]) => void
}

interface SpecializationsFormValues {
    specializationsIds: number[]
}

const SpecializationsComparator = (A: Option, B: Option) => {
    if (A.title < B.title) {
        return -1;
    } else if (A.title > B.title) {
        return 1;
    } else {
        return Number(A.value) - Number(B.value);
    }
}

export const Specializations: FC<SpecializationsProps> = (props) => {
    const { providerId, specializations, onSpecializationsUpdated } = props;
    
    const { t, ready: translationsReady } = useTranslation('providerprofile', { useSuspense: false });
    const { t: t_common, ready: commonTranslationsReady } = useTranslation('common', { useSuspense: false });

    const [cacheUpdating, setCacheUpdating] = useState(false);

    const [updateSpecializations, updateSpecializationsMutation] = useMutation(UPDATE_SPECIALIZATIONS_MUTATION, {
        update: (_, result) => {
            if(result.data?.updateCredentials.credential.specializations) {
                setCacheUpdating(true);
                onSpecializationsUpdated(result.data?.updateCredentials.credential.specializations);
            }
        }
    });

    const [getRefData, refDataQuery] = useLazyQuery(SPECIALIZATIONS_REF_DATA);

    const [inEdit, setInEdit] = useState(false);

    useEffect(() => {
        if (inEdit && !refDataQuery.called) {
            getRefData();
        }
    }, [inEdit]);

    useEffect(() => {
        setCacheUpdating(false);
        setInEdit(false);
        formik.resetForm({ values: { specializationsIds: specializations?.map(s => Number(s.value)) ?? [] }});
    }, [specializations]);

    const formik = useFormik<SpecializationsFormValues>({
        initialValues: {
            specializationsIds: specializations?.map(s => Number(s.value)) ?? []
        },
        onSubmit: async (values) => {
            if (formik.isValid && values.specializationsIds) {
                const dialogProps = {
                    message: <>
                        <p>{t('common__confirm_changes_dialog_text')}</p>
                        {
                            Boolean(addedEntries.length) &&
                            <>
                                {t('specializations__attestation_added_entries_header')}
                                <ul>
                                    {
                                        addedEntries.map(entry => (
                                            <li key={entry.value}>{entry.title}</li>
                                        ))
                                    }
                                </ul>
                            </>
                        }
                        {
                            Boolean(removedEntries.length) &&
                            <>
                                {t('specializations__attestation_removed_entries_header')}
                                <ul>
                                    {
                                        removedEntries.map(entry => (
                                            <li key={entry.value}>{entry.title}</li>
                                        ))
                                    }
                                </ul>
                            </>
                        }
                    </>
                }
                if (await confirm(dialogProps)) {
                    updateSpecializations({
                        variables: {
                            providerId: providerId,
                            specializations: values.specializationsIds
                        }
                    });
                }
            }
        }
    });

    const handleCancelChanges = async () => {
        if (addedEntries.length || removedEntries.length) {
            const dialogProps = {
                title: t('common__discard_changes_dialog_title'),
                message: t('common__discard_changes_dialog_text')
            };
            if (await confirm(dialogProps)) {
                formik.resetForm();
                setInEdit(false);
            }
        } else {
            formik.resetForm();
            setInEdit(false);
        }
    }

    const handleRemoveSpecializationClick = (specializationId: number) => {
        formik.setFieldValue('specializationsIds', formik.values.specializationsIds?.filter(id => id !== specializationId));
    } 

    const handleAddSpecializations = (specializations: Option[]) => {
        const updatedSpecializations = [...(value ?? []), ...specializations].sort(SpecializationsComparator);
        formik.setFieldValue('specializationsIds', updatedSpecializations.map(sp => sp.value));
        setShowPickerDialog(false);
    }

    const editorLoading = refDataQuery.loading || !commonTranslationsReady;

    const options = refDataQuery.data?.specializations.map(s => ({title: s.title, value: Number(s.value)}));
    const value = options
        ? formik.values.specializationsIds
            .map(id => options.find(option => option.value === id) ?? { title: id.toString(), value: id })
            .sort(SpecializationsComparator)
        : undefined;

    const addedEntries = useMemo(() =>
        value?.filter(newEntry => !specializations?.find(oldEntry => oldEntry.value === newEntry.value.toString())) ?? []
    , [value, specializations]);

    const removedEntries = useMemo(() =>
        specializations?.filter(oldEntry => !value?.find(newEntry => oldEntry.value === newEntry.value.toString())) ?? []
    , [value, specializations]);

    const [showPickerDialog, setShowPickerDialog] = useState(false);

    const submitDisabled = updateSpecializationsMutation.loading
        || (value?.length ?? 0) < 1
        || cacheUpdating
        || (!addedEntries.length && !removedEntries.length);

    const sortedSpecializations = useMemo(() => 
        specializations?.slice()?.sort(SpecializationsComparator)
    , [specializations]);

    return !translationsReady
        ? <></>
        : <SectionContainer title={t('specializations__section_title')} inEdit={inEdit} onEditClick={() => setInEdit(true)}>
            {
                !inEdit
                    ? sortedSpecializations?.length
                        ? <>
                            <p>{t('specializations__list_header')}</p>
                            <ul className="insurance_panels_list">
                                {
                                    sortedSpecializations.map(entry => (
                                        <li key={entry.value}><span>{entry.title}</span></li>
                                    ))
                                }
                            </ul>
                        </>
                        : <p>{t('specializations__no_entries')}</p>
                    : <>
                        {
                            editorLoading &&
                            <Spinner fillParent/>
                        }
                        {
                            (updateSpecializationsMutation.loading || cacheUpdating) &&
                            <Spinner fillParent backdrop/>
                        }
                        <Grid container component="form" onSubmit={formik.handleSubmit} spacing={3} style={{visibility: editorLoading ? 'hidden' : 'visible'}}>
                            <Grid item xs={12} component="p">
                                {t('specializations__list_header')}
                            </Grid>
                            <Grid item xs={12} component="ul" className="insurance_panels_edit_list">
                                {
                                    value?.map(specialization => (
                                        <li key={specialization.value}>
                                            <IconButton size="small" onClick={() => handleRemoveSpecializationClick(specialization.value)}><RemoveIcon/></IconButton>
                                            {specialization.title}
                                        </li>
                                    ))
                                }
                            </Grid>
                            <Grid item xs={12}>
                                <Button variant="text" onClick={() => setShowPickerDialog(true)}>
                                    <AddIcon/>{t('specializations__add_entry_button_label')}
                                </Button>
                            </Grid>
                            <Grid item xs={12} container spacing={2}>
                                <Grid item>
                                    <Button type="submit" variant="opaque" disabled={submitDisabled}>{t_common('save')}</Button>
                                </Grid>
                                <Grid item>
                                    <Button type="button" variant="text" onClick={handleCancelChanges}>{t_common('cancel')}</Button>
                                </Grid>
                            </Grid>
                        </Grid>
                        <PickerDialog open={showPickerDialog}
                            options={options}
                            value={value}
                            labels={{
                                dialogTitle: t('specializations__picker_dialog_title'),
                                helperText: t('specializations__picker_dialog_helper_text'),
                                inputPlaceholder: t('specializations__picker_dialog_input_placeholder')
                            }}
                            onCancel={() => setShowPickerDialog(false)}
                            onSubmit={handleAddSpecializations}
                        />
                    </>
            }
        </SectionContainer>
}
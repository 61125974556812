import { Spinner } from 'components/Shared';
import React, { createContext, FC, PropsWithChildren, useEffect, useRef, useState } from 'react';
import { IConfig } from './interfaces';

const AppConfigContext = createContext<IConfig>({
    AppVersion: '',
    IDLE_TIMEOUT_VALUE_IN_MILISECONDS: 0,
    REACT_APP_GRAPHQL_API_HOST: '',
    REACT_APP_GRAPHQL_WS_HOST: '',
    REFRESH_TIMEOUT_VALUE_IN_MILISECONDS: 0,
    FETCH_LATEST_CONFIG_INTERVAL: 0,
    CACHE_EXPIRY_IN_MILLISECONDS: 300000,
    INVOICE_DRAFT_EXPIRY_IN_HOURS: 12,
    RegionConfigs: [],
    ApplicationInsights: undefined,
});

AppConfigContext.displayName = 'AppConfigContext';

const AppConfigProvider: FC<PropsWithChildren> = ({ children }) => {
    const [config, setConfig] = useState<IConfig | null>(null);
    let { current: refreshTimeout } = useRef<number | null>(null);

    const fetchConfig = (): Promise<IConfig> => (
        fetch('config.json', { cache: 'no-store' }).then(response => response.json())
    )

    const updateConfig = async () => {
        const newConfig = await fetchConfig();
        if (config && newConfig.AppVersion !== config.AppVersion) {
            setConfig(newConfig);
        }
        refreshTimeout && clearTimeout(refreshTimeout);
        refreshTimeout = window.setTimeout(updateConfig, newConfig?.FETCH_LATEST_CONFIG_INTERVAL || 3_600_000);
    }

    useEffect(() => {
        fetchConfig()
            .then((res) => {
                setConfig(res);
                refreshTimeout && clearTimeout(refreshTimeout);
                refreshTimeout = window.setTimeout(updateConfig, res?.FETCH_LATEST_CONFIG_INTERVAL || 3_600_000);
            })
    }, []);

    return !config
        ? <Spinner fillParent/>
        : <AppConfigContext.Provider value={config} children={children}/>;
}

const useAppConfig = () => React.useContext(AppConfigContext);

export { AppConfigProvider, useAppConfig };
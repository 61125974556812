import React, { FC } from 'react';
import { UnavailabilitiesListItem } from './UnavailabilitiesListItem';
import { UnavailabilityReason } from './queries';
import { Address, Unavailability } from 'components/Calendaring/Unavailabilities';
import { ExecutionResult } from 'graphql';
import { IAutoCompleteValue } from 'components/Shared';
import { IWspTaskCode } from 'hooks/useWspTaskCodes/interfaces';

interface UnavailabilitiesListProps {
    unavailabilities?: Unavailability[],
    unavailabilityReasons: UnavailabilityReason[],
    addresses?: Address[],
    onSubmit: (unavailability: Unavailability) => Promise<ExecutionResult<any>>,
    onDelete: (unavailability: Unavailability) => Promise<ExecutionResult<any>>,
    managedCalendar: boolean
    wspTaskCodes?: Array<IWspTaskCode>
    wspClients?: IAutoCompleteValue[]
    intercessionWspTasks?: Array<IWspTaskCode>
}

export const UnavailabilitiesList: FC<UnavailabilitiesListProps> = (props) => {
    const { unavailabilities, unavailabilityReasons, managedCalendar,
        addresses, onDelete, onSubmit } = props;

    const sorted = unavailabilities?.sort((a, b) =>
        (a.startDate?.getTime()) ?? 0 - (b.startDate?.getTime() ?? 0));

    return <div className='unavailability_item_list_container'>
        {
            sorted?.map((unavailability, _) => (
                <UnavailabilitiesListItem key={unavailability.scheduleId}
                    onDelete={onDelete}
                    onSubmit={onSubmit}
                    addresses={addresses}
                    managedCalendar={managedCalendar}
                    pendingUnavailabilities={sorted}
                    wspClients={props.wspClients}
                    wspTaskCodes={props.wspTaskCodes}
                    intercessionWspTasks={props.intercessionWspTasks}
                    unavailability={unavailability}
                    unavailabilityReasons={unavailabilityReasons}
                />
            ))
        }
    </div>
}
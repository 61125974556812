import { StepDefinition, ToDo } from 'components/ToDos';
import { IActivityDetails, IFormByActivity, IReferral } from '../interfaces';
import { FormPaths, TemplateNames, AssessmentStages, FormStage } from 'components/Forms/constants';
import moment from 'moment';
import { allEmployeeTypes, EMPLOYEE_TYPES } from '../constants';
import { IContractDetails } from 'hooks/useContractDetails/interfaces';
import { ActivityStatus } from '../../../constants/activityStatus';
import { Assessment } from 'components/CaseManagement/interfaces';
import { GetFirstAssessmentFormsIncomplete } from 'components/CaseManagement/utils/formStatusUtils';
import { IBookCalendarActivityData } from 'components/Calendaring/AppointmentModals/interfaces';
import { COUNTRY_CODE, regionsToShowPreferredNumber } from 'utils/regionUtils';

const dateOfFirstAppointmentEntered = (activity: IActivityDetails): boolean => {
    return Boolean(activity.sessions?.length);
}

export const getPreferredPhoneNumber = (activity?: IActivityDetails | IBookCalendarActivityData, region?: string, isStaffProvider?: boolean): string => {
    if (!activity) return '---';
    const showSessionPreferredNumber = regionsToShowPreferredNumber.includes(region as COUNTRY_CODE)
                                      && activity.modality === 'Tele-Counselling'
                                      && activity?.service === 'Counselling'
                                      && isStaffProvider;
    // if we have a recent appointment with preferred phone number we use it
    if (showSessionPreferredNumber && activity?.sessions.length > 0) {
        const latestSessionPhoneNumber = activity.sessions
            .filter(sessions => !!sessions.clientReservationPhoneNumber)
            ?.slice(-1)[0]?.clientReservationPhoneNumber || '';
        if (latestSessionPhoneNumber) return latestSessionPhoneNumber;
    }
    // or, if we have a client phone in serviceForms we use it
    if ((activity as IActivityDetails)?.serviceForms?.clientPhoneNumber) {
        return (activity as IActivityDetails).serviceForms.clientPhoneNumber || '---';
    }
    // if previous conditions are not met return the only client phone number
    if (activity?.client?.phones.length === 1) {
        return activity.client.phones[0].number;
    }
    // if multiple numbers, choose the one with 'Detailed' message type
    return (
        (showSessionPreferredNumber 
        && activity?.client?.phones?.find(phone => phone.messageType === 'Detailed')?.number)
        || activity?.client?.phones?.[0].number
        || '---'
    );
}

const dateOfAppointmentPassed = (activity: IActivityDetails): boolean => {
    const now = new Date();
    
    for (let session of activity?.sessions || []) {
        if (now >= new Date(session.startDateTimeUTC)) {
            return true;
        }
    }

    return false;
}

const dateOfFollowUpReached = (activityFollowUpDate: string): boolean => {
    if(activityFollowUpDate){
        const followUpDate = moment(activityFollowUpDate);

        if(followUpDate.isSameOrBefore(moment())){
            return true
        }
    }

    return false;
}

const allAppointmentsCompleted = (activity: IActivityDetails): boolean => {
    for (let session of activity?.sessions || []) {
        if (!Boolean(session.sessionStatus) || session.sessionStatusId === 1) {
            return false;
        }
    }
    return true;
}


export const isFirstApptAssessmentForm = (form: IFormByActivity, firstAppointmentAssessments: Assessment[]) => {
    return firstAppointmentAssessments.some(assessment => assessment.templateName === form.templateName)
        && form.stage === AssessmentStages.FIRST_ASSESSMENT_STAGE
        && form.templateName.startsWith(FormPaths.Assessments)
}


// NOTE: The reason for this manual approach for determining Todos is because
// the Workflow service can be unreliable in its retrieval of Todos.
// So we might show invalid or stale Todos to the user, which would not be correct.
// In order to avoid this, we do the manual checks below to see which Todo makes
// sense to show in the given context. This is a terrible approach, but due
// to the configuration of the Workflow Service & Todo updates, this issue 
// cannot be solved immediately using any other approach.

//for non-active activity (i.e. activity status code != open), return null except the submit invoice
export const GetNextStep = (activity: IActivityDetails, 
    forms?: (IFormByActivity)[], 
    referral?: IReferral,
    contractDetails?: IContractDetails | undefined,
    allTodos?: ToDo[] | undefined
): StepDefinition | null => {
 
    if (activity.activityStatusCode !== ActivityStatus.Void && allTodos?.find(todo => todo.step === StepDefinition.SUBMIT_INVOICE && activity.activityId === todo.activityInfo?.activityId)) { 
        return StepDefinition.SUBMIT_INVOICE;
    }

    //if the activity already close/void, no more step should be available
    if (activity.activityStatusCode !== ActivityStatus.Active) {
        return null;
    }

    if (referral && !referral.referralAccepted) {
        return StepDefinition.CONFIRM_REFERRAL;
    }

    if (!dateOfFirstAppointmentEntered(activity)) {
        return StepDefinition.ENTER_DATE_FIRST_APPT;
    } 

    if (activity.preAssessments) {
        if (!activity.preAssessments?.find(x => x.status === FormStage.PreAssessmentReviewed)) {
            if (activity.preAssessments.find(y => y.additionalIndividualId === 0)) {
                return StepDefinition.REVIEW_PRE_ASSESSMENT
            }
        }
    }
        
    if (dateOfAppointmentPassed(activity)) {
        if (activity.sessions.find(x => x.sessionStatusId === 3) && GetFirstAssessmentFormsIncomplete(forms ?? [], activity.firstAppointmentAssessmentFormNames)) {
            return StepDefinition.FIRST_APPT_ASSESSMENT;
        }
        if (!allAppointmentsCompleted(activity)){
            return StepDefinition.UPDATE_APPT_STATUS;
        }
    }

    //only allow enter next appointment for open activity
    if (activity?.activityStatusCode === ActivityStatus.Active
        && activity?.sessionBooking.allowed
        && allAppointmentsCompleted(activity)) {
        return StepDefinition.ENTER_DATE_NEXT_APPT
    }

    if (dateOfFollowUpReached(activity.followUpDate) 
        && forms?.find(x => x.templateName === TemplateNames.FollowUp && x.stage.toUpperCase() !== FormStage.FollowUpCompleted.toUpperCase())) {
        return StepDefinition.FOLLOW_UP;
    }

    return null;
}

export const deduceClientFamilyAdjustedHours = (activity: IActivityDetails | undefined) => {

    const getFamilyEligibility = () => {
        if (activity?.familyEligibilityHours && activity?.familyEligibilityHours.length > 0) {
            return activity?.familyEligibilityHours.find(entity => entity.eligibilityCategoryId === 2) 
        }
    }

    const getEmployeeEligibility = () => {
        if (activity?.familyEligibilityHours && activity?.familyEligibilityHours.length > 0) {
            return activity?.familyEligibilityHours.find(entity => entity.eligibilityCategoryId === 1) 
        }
    }

    const isCurrentClientEmployee = () => allEmployeeTypes.includes(activity?.clientStatusCode as EMPLOYEE_TYPES)

    const isCurrentClientPartOfFamily = () => activity?.familyEligibilityHours && activity?.familyEligibilityHours.length > 0

    const deduceClientMaxHours = () => {
        if (isCurrentClientPartOfFamily()) {
            let clientEligibility = isCurrentClientEmployee() ? getEmployeeEligibility() : getFamilyEligibility()
            return clientEligibility?.allocatedHours
        }
        return activity?.clientMaxHours
    }

    const deduceClientRemainingHours = () => {
        if (isCurrentClientPartOfFamily()) {
            let clientEligibility = isCurrentClientEmployee() ? getEmployeeEligibility() : getFamilyEligibility()
            return clientEligibility?.remainingHours
        }
        return activity?.clientAvailableHours
    }


    return {
        clientMaxHours : deduceClientMaxHours(),
        clientRemainingHours : deduceClientRemainingHours()
    }
}
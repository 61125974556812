import React, { FC, useState } from 'react';
import { Alert } from '@mui/material';
import { Link } from 'react-router-dom';
import BackIcon from '@mui/icons-material/ArrowBackRounded';
import { useFormik } from 'formik';
import { FORGOT_PASSWORD_MUTATION, ForgotPasswordMutationVars } from '../queries';
import { useMutation } from '@apollo/client';
import { useAuth } from '../AuthProvider';
import { Spinner } from 'components/Shared';
import { useTranslation } from 'react-i18next';
import { Button, TextBox } from 'components/Shared/Inputs';


interface ForgotPasswordFormProps { }

export const ForgotPasswordForm: FC<ForgotPasswordFormProps> = (_) => {
    const { t } = useTranslation(['login', 'common'], { useSuspense: false }); 
    const [loading, setLoading] = useState<boolean>(false);
    const [emailSent, setEmailSent] = useState(false);
    const { resetPasswordUrl } = useAuth();

    const [forgotPasswordMutation] = useMutation<{}, ForgotPasswordMutationVars>(FORGOT_PASSWORD_MUTATION, {
        context: {
            skipGlobalErrorHandling: true
        }
    });

    const formik = useFormik({
        initialValues: {
            email: '',
            username: ''
        },
        validate: (values) => { 
            const errors: any = {};

            if (!values.username) {
                errors.username = t('common:id_is_required', 'ID is required');
            }

            if (!values.email) {
                errors.email = t('common:email_req', 'Email is required');
            } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
                errors.email = t('common:email_invalid', 'Invalid email address');
            }

            return errors;
        },
        validateOnBlur: true,
        onSubmit: (values) => {
            formik.setTouched({email: true});
            formik.validateForm();

            if (formik.isValid) {
                setLoading(true);
                forgotPasswordMutation({
                    variables: {
                        username: values.username,
                        email: values.email,
                        resetUrl: resetPasswordUrl
                    }
                }).finally(() => {
                    setLoading(false);
                    setEmailSent(true);
                })
            }
        }
    });

    const getErrorMessage = (): string | null => {
        if (formik.touched.username && formik.errors.username) {
            return formik.errors.username;
        }
        if (formik.touched.email && formik.errors.email) {
            return formik.errors.email;
        }
        return null;
    }

    return (
        <form onSubmit={formik.handleSubmit} className="login_screen_form">
        {
            !emailSent
            ? (<React.Fragment>
                <Button variant="inline" className="login_screen_form_back_button" component={Link} to="/login" startIcon={<BackIcon/>} size="small">
                    {t('common:back','Back')}
                </Button>
                <h2 className="login_screen_form_title">{t('login:forgot_your_password','Forgot your password?')}</h2>
                <p className="login_screen_form_text">{t('login:forgot_your_password_desc','Enter your ID and email to receive password reset instructions.')}</p>
                <TextBox 
                    onChange={formik.handleChange}
                    value={formik.values.username}
                    onBlur={formik.handleBlur}
                    error={formik.touched.username && !!formik.errors.username}
                    name="username"
                    className="login_screen_form_input"
                    label={t('common:provider_ID','Provider ID')}
                    id='provider_ID_input_field'
                />
                <TextBox 
                    onChange={formik.handleChange}
                    value={formik.values.email}
                    onBlur={formik.handleBlur}
                    error={formik.touched.email && !!formik.errors.email}
                    name="email"
                    className="login_screen_form_input"
                    label={t('common:email','Email')}
                    id='email_input_field'
                />
                {
                    getErrorMessage() &&
                    <Alert severity="error" className="login_screen_alert">{getErrorMessage()}</Alert>
                }
                <div className="filler" />
                {
                    loading
                        ? <Spinner />
                        : <Button variant="opaque" type="submit" className="login_screen_form_submit_button">
                            {t('common:send','Send')}
                        </Button>
                }
                <p className="login_screen_form_footer_text">{t('login:remember_password','Remember password?')} <Link to="login">{t('common:login','Login')}</Link></p>
            </React.Fragment>)
            : (<React.Fragment>
                <h2 className="login_screen_form_title">{t('login:email_has_been_sent','Email has been sent!')}</h2>
                <p className="login_screen_form_text">{t('login:email_has_been_sent_desc','If provided ID and email address were correct you should receive an email with further instructions shortly.')}</p>
                <div className="filler" />
                <div className="login_screen_form_footer_text">{t('login:didnt_receive_an_email',"Didn't receive an email?")}
                {
                    loading
                        ? <div style={{display: 'inline-block'}}><Spinner/></div>
                        : <Button variant="inline" size="small" className="login_screen_inline_button" type="submit">{t('common:resend','Resend')}</Button>
                }
                </div>
            </React.Fragment>)
        }
        </form>
    )
}
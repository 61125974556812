import { gql, TypedDocumentNode } from '@apollo/client';
import { Address } from './interfaces';

export const UNAVAILABILITY_QUERY: TypedDocumentNode<UnavailabilityQueryData, UnavailabilityQueryVars> = gql`
    query UNAVAILABILITY_QUERY($providerId: Long!) {
        schedule(providerId: $providerId) {
            unavailabilities {
                startDate
                endDate
                oneTime
                scheduleId
                unavailabilityReasonID
                shifts {
                    startDay
                    endDay
                    startTime
                    endTime
                }
                seriesId
                activityId
                wspTaskCode
            }
            unavailabilityReasons {
                description
                editable
                id
            }
            addresses {
                providerAddressId
                addressLine1
                addressLine2
                state_ProvinceCode
                officeTypeString
                officeType
            }
        }
    }
`

export interface UnavailabilityReason {
    description: string,
    editable: boolean,
    id: number
}

export interface UnavailabilityQueryVars {
    providerId: number
}

export interface UnavailabilityQueryData {
    schedule: {
        unavailabilities: UnavailabilityData[],
        unavailabilityReasons: UnavailabilityReason[],
        addresses: Address[]
        __typename?: string
    }
}

export interface UnavailabilityData {
    startDate: Date,
    endDate: Date,
    oneTime: Boolean,
    unavailabilityReasonID: number,
    shifts: {
        startDay: number,
        endDay: number
    }[],
    scheduleId: number,
    seriesId: string,
    activityId?: number | string,
    wspTaskCode?: string,
}

export const SAVE_UNAVAILABILITY_MUTATION = gql`
    mutation SAVE_UNAVAILABILITY_MUTATION($activityId: Int, $applyToSeries: Boolean, $daysOfTheWeek: [Int!], $endDate: DateTime!, $monthly: Boolean, $providerId: Int!, $reasonId: Short!, $scheduleId: Int, $seriesId: Uuid, $startDate: DateTime!, $username: String, $wspTaskCode: String, $startTime: TimeSpan, $endTime: TimeSpan) {
        saveUnavailability(activityId: $activityId, applyToSeries: $applyToSeries, daysOfTheWeek: $daysOfTheWeek, endDate: $endDate, monthly: $monthly, providerId: $providerId, reasonId: $reasonId, scheduleId: $scheduleId, seriesId: $seriesId, startDate: $startDate, username: $username, wspTaskCode: $wspTaskCode, startTime: $startTime, endTime: $endTime)
    }
`

export interface SaveUnavailabilityMutationData {
    saveUnavailability: number
}

export interface SaveUnavailabilityMutationVars {
    activityId?: number | string,
    wspTaskCode?: string,
    applyToSeries?: boolean,
    daysOfTheWeek?: number[],
    endDate: string,
    monthly?: boolean,
    providerId: number,
    reasonId: number,
    scheduleId?: number,
    seriesId?: string,
    startDate: string,
    username?: string,
    startTime?: string,
    endTime?: string,
}

export const DELETE_UNAVAILABILITY_MUTATION = gql`
    mutation DELETE_UNAVAILABILITY_MUTATION($eventDate: DateTime, $providerId: Int!, $scheduleId: Int!, $username: String, $seriesId: Uuid) {
        deleteUnavailability(eventDate: $eventDate, providerId: $providerId, scheduleId: $scheduleId, username: $username, seriesId: $seriesId)
    }
`

export interface DeleteUnavailabilityMutationData {
    deleteUnavailability: number
}

export interface DeleteUnavailabilityMutationVars {
    eventDate: Date,
    providerId: number,
    scheduleId: number,
    username: string,
    seriesId?: string
}

export const ACTIVITIES_QUERY = gql`
    query ACTIVITIES_QUERY($providerId: Long!) {
        activities(providerId: $providerId) {
            client {
                firstName
                lastName
            }
            additionalParticipants {
                id 
                firstName,
                lastName,
                relationType,
                fullName,
                dateOfBirth
            }
            activityId
            activityStatusCode
        }
    }
`


export const UNAVAILABILITY_REASONS_QUERY = gql`
    query UNAVAILABILITY_REASONS_QUERY($providerId: Long!) {
        schedule(providerId: $providerId) {
            unavailabilityReasons {
                description
                editable
                id
            }
            addresses {
                providerAddressId
                addressLine1
                addressLine2
                state_ProvinceCode
                officeTypeString
                officeType
            }
        }
    }
`
export interface UnavailabilityReasonsQueryVars {
    providerId: number
}
export interface UnavailabilityReasonsQueryData {
    schedule: {
        unavailabilityReasons: UnavailabilityReason[],
        addresses: Address[]
    }
}



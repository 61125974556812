import { useMemo } from 'react';
import { derivePaginationDetails, PaginationDetails } from 'components/Shared/MaterialTable';
import { ToDosDataRequest, ToDo } from '../interfaces';
import { getToDoTypes, sortToDos } from '../utils';
import { StepDefinition } from '../constants';

export const useProcessToDosData = (data: ToDo[] | undefined, dataRequest: ToDosDataRequest)
    : [ToDo[] | undefined, PaginationDetails, StepDefinition[] | undefined] =>
{
    const parsedData = useMemo(() =>
        data?.filter(toDo => Boolean(toDo))
    , [data]);

    const toDoFilterTypes = useMemo(() => getToDoTypes(parsedData), [parsedData]);

    const filteredData = useMemo(() => 
        dataRequest.filters.step !== 'ALL'
            ? parsedData?.filter(toDo => toDo.step === dataRequest.filters.step)
            : parsedData
    , [parsedData, dataRequest.filters]);

    const sortedData = useMemo(() =>
        sortToDos(filteredData)
    , [filteredData]);

    const pagedData = useMemo(() => 
        sortedData?.slice(dataRequest.skip, dataRequest.skip + dataRequest.take)
    , [sortedData, dataRequest.skip, dataRequest.take]);

    const paginationDetails = derivePaginationDetails(sortedData?.length, dataRequest);
    
    return [pagedData, paginationDetails, toDoFilterTypes];
}
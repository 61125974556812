import { gql, TypedDocumentNode } from "@apollo/client";
import { Language } from '../../../interfaces'; 

export const LANGUAGES_REF_DATA
    : TypedDocumentNode<LanguagesRefDataData, LanguagesRefDataVars> = gql`
query INSURANCE_PANELS_REF_DATA {
    languages {
        title
        value
    }
}
`

interface LanguagesRefDataData {
    languages: {
        title: string,
        value: string
    }[]
}

interface LanguagesRefDataVars {}

export const UPDATE_SERVED_LANGUAGES_MUTATION
    : TypedDocumentNode<UpdateServedLanugagesMutationData, UpdateServedLanugagesMutationVars> = gql`
    mutation UPDATE_SERVED_LANGUAGES_MUTATION($providerId: Long!, $languageCodes: [String]) {
        updateProviderDetails(providerId: $providerId, languageCodes: $languageCodes) {
            providerId
            details {
                servedLanguages {
                    title
                    value
                }
            }
        }
    }
`

interface UpdateServedLanugagesMutationData {
    updateProviderDetails: {
        details: {
            servedLanguages: Language[]
        }
    }
}

interface UpdateServedLanugagesMutationVars {
    providerId: number,
    languageCodes: string[]
}
import { Tab, Tabs } from '@mui/material';
import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import { useNavigationUtils } from '../../hooks';
import { Logo }  from 'components/Shared';
import { useTranslation } from 'react-i18next';

import './BasicNavbar.scss';

interface BasicNavbarProps {
    showNavigation: boolean
}

const KnownPaths = ['/login', '/faq', '/about_us'];

export const BasicNavbar: FC<BasicNavbarProps> = (props) => {
    const { t } = useTranslation('navigation', { useSuspense: false });
    const { showNavigation } = props;
    
    const { pathRoot } = useNavigationUtils();

    const path = KnownPaths.indexOf(pathRoot) !== -1 ? pathRoot : false;

    const renderNavbarTab = (id: string, path: string) => (
        <Tab className="basic_layout_navbar_tab" id={id} label={t(id)} value={path} component={Link} to={{pathname: path}}/>
    );

    return (
        <div className="basic_layout_navbar">
            <Logo className="logos"/>
            {
                showNavigation &&
                <Tabs className="basic_layout_navbar_tabs" value={path} component="nav">
                    {renderNavbarTab('login', '/login')}
                    {renderNavbarTab('faq', '/faq')}
                    {renderNavbarTab('about_us', '/about_us')}
                </Tabs>
            }
        </div>
    )
}

import { useEffect, useState } from "react";
import {
    QueryLazyOptions,
    useLazyQuery,
    WatchQueryFetchPolicy
} from "@apollo/client";
import { getOperationName } from "@apollo/client/utilities";
import { fetchTimestampRefs, cacheClearEvents } from "./ApolloProvider";
import { useLocation } from "react-router-dom";
import { useAppConfig } from "providers/AppConfig";
import { TUseLazyQueryWithCacheExpiry } from "./interfaces";


export const useLazyQueryWithCacheExpiry: TUseLazyQueryWithCacheExpiry = (query, options) => {
    const { CACHE_EXPIRY_IN_MILLISECONDS } = useAppConfig();  // 15 minutes is the default cache expiry if not specified in options
    const [loadingStart, setLoadingStart] = useState<number>(0);
    const [loadingStop, setLoadingStop] = useState<number>(0);
    const MIN_NETWORK_DELAY = 300;
    const location = useLocation();
    // if the user runs window.sessionStorage.set("apolloCacheLife", "2") in browser devtools, cache expiry becomes 2 minutes
    const testCacheExpiry = parseInt(window.sessionStorage.getItem("apolloCacheLife") || "0", 10) * 1000 * 60;
    const isQATesting = testCacheExpiry > 0;
    const { refs: fetchTimeRef } = fetchTimestampRefs;
    const [fetchPolicy, setFetchPolicy] = useState<WatchQueryFetchPolicy>("cache-first");
    const { cacheLife = isQATesting ? testCacheExpiry : CACHE_EXPIRY_IN_MILLISECONDS, ...hookOptions } = options;
    const currentTime = Date.now();
    const queryName = getOperationName(query);
    const cacheKey = options.expiryKey || `${location.pathname.replaceAll('/', '_')}__${queryName}`;

    const getLastFetchedTime = () => {
        return fetchTimeRef?.current?.[cacheKey] || 0;
    }

    const setLastFetchedTime = (newTime: number) => {
        if (fetchTimeRef?.current) {
            fetchTimeRef.current[cacheKey] = newTime;
        }
    }

    const getLoadingDuration = () => loadingStop - loadingStart;

    const [refetch, { data, loading, ...queryResult }] = useLazyQuery(query, { ...hookOptions, fetchPolicy });

    useEffect(() => {
        if(loading && !loadingStart) {
            setLoadingStart(Date.now());
        }
        if(!loading && loadingStart && !!data) {
            setLoadingStop(Date.now());
        }
        if (options.expiryEvents?.length) {
            options.expiryEvents.forEach(bucket => {
                if (!cacheClearEvents[bucket]) {
                    cacheClearEvents[bucket] = new Set();
                }
                cacheClearEvents[bucket].add(cacheKey);
            });
            isQATesting && console.info(`📁💾 Added cache key '${cacheKey}' to ${options.expiryEvents.join(" and ")} expiry events`, cacheClearEvents);
        }
    }, [loading]);

    useEffect(() => {
        if (fetchTimeRef?.current && currentTime > (getLastFetchedTime() + cacheLife)) {
            isQATesting && console.log(queryName, "🚀🚀🚀 refetching from network...", fetchTimeRef.current, currentTime);
            setFetchPolicy("network-only");
        }
        // isQATesting && console.log(queryName, "⏳⏳⏳", currentTime, fetchTimeRef?.current, ((currentTime - (getLastFetchedTime() + cacheLife))/60000).toFixed(2));
    }, [currentTime, cacheLife]);
    
    if (getLoadingDuration() > MIN_NETWORK_DELAY) {
        isQATesting && console.log(`${queryName}, 🔁🔁🔁 setting fetchPolicy to cache-first after ${getLoadingDuration()} milliseconds data fetch`, fetchPolicy);
        setFetchPolicy("cache-first");
        setLastFetchedTime(Date.now());
        setLoadingStart(0);
        setLoadingStop(0);
    }

    const reftechWithTimestamp = async (variables?: QueryLazyOptions<typeof hookOptions.variables>) => {
        setLoadingStart(Date.now());
        return refetch(variables).then((resp) => {
            setLoadingStop(Date.now());
            if (!!fetchTimeRef && !fetchTimeRef?.current?.[cacheKey]) {
                isQATesting && console.info(queryName, "💾🤞😢 setting last fetched time because is was not set...", Date.now());
                setLastFetchedTime(Date.now());
            } else if (getLoadingDuration() > MIN_NETWORK_DELAY) {
                isQATesting && console.log(`${queryName}, 🔁🔁🔁 setting fetchPolicy to cache-first after ${getLoadingDuration()} milliseconds data fetch`, fetchPolicy);
                setFetchPolicy("cache-first");
                setLastFetchedTime(Date.now());
                setLoadingStart(0);
                setLoadingStop(0);
            }
            return resp;
        });
    }
    return [reftechWithTimestamp, { ...queryResult, data, loading }];
}

import { useTranslation } from "react-i18next";


export const useSurveyJSUtils = () => {
    const { t } = useTranslation('caseManagement', { useSuspense: false });

    const updateAriaLabels = (divWithMatrix: Element | null | undefined) => {
        if (divWithMatrix) {
            const table: HTMLTableElement = divWithMatrix.querySelector("table") as HTMLTableElement;
            if (table) {
                const rows = table.tBodies[0].rows;
                const headerAnswers = table.tHead?.getElementsByTagName("th");
                Array.from(rows).forEach((row) => {
                    const cells = row.cells;
                    const questionText = cells[0].getElementsByTagName("span")[0].innerText;
                    const numberOfAnswers = cells.length - 1;
                    Array.from(cells).forEach((cell, cellIdx) => {
                        if (cellIdx === 0)
                            return;

                        const cellInputElements = cell.getElementsByTagName("input");
                        Array.from(cellInputElements).forEach((cellInputElement, _inputId) => {
                            let answer = headerAnswers?.[cellIdx].getElementsByTagName("span")[0].innerText;

                            const checkedRadio = cell.getElementsByClassName("sv-radio--checked");
                            let selectedText: string;

                            if (checkedRadio && checkedRadio.length > 0) {
                                selectedText = t("selected_answer");
                            }
                            else {
                                selectedText = t("unselected_answer");
                            }

                            cellInputElement.setAttribute("aria-label", `${questionText}, ${answer}, ${cellIdx} ${t('of')} ${numberOfAnswers}, ${selectedText}`);
                        });

                    });
                });
            }
        }
    }

    const updateAriaLabelsAnswerInCell = (divWithMatrix: Element | null | undefined) => {
        if (divWithMatrix) {
            const table: HTMLTableElement = divWithMatrix.querySelector("table") as HTMLTableElement;
            if (table) {
                const rows = table.tBodies[0].rows;
                Array.from(rows).forEach((row) => {
                    const cells = row.cells;
                    const questionText = cells[0].getElementsByTagName("span")[0].innerText;
                    const numberOfAnswers = cells.length - 1;
                    const cellToUpdate = cells[1];
                    const divs = cellToUpdate.getElementsByClassName("sv-radio");

                    Array.from(divs).forEach((div, idx) => {
                        const input = div.getElementsByTagName("input")[0];
                        const answer = div.getElementsByClassName("sv-item__control-label")[0];
                        const checked = div.classList.contains("sv-radio--checked")
                        let selectedText: string;

                        if (checked) {
                            selectedText = t("selected_answer");
                        }
                        else {
                            selectedText = t("unselected_answer");
                        }

                        input.setAttribute("aria-label", `${questionText}, ${answer.textContent}, ${idx + 1} ${t('of')} ${numberOfAnswers}, ${selectedText}`);
                    });
                });
            }
        }
    }

    const updateAriaLabelsForQuestionInRow = (divWithQuestion: Element | null | undefined) => {
        if (divWithQuestion) {
            const headerDiv = divWithQuestion.getElementsByClassName("sv-question__header");
            const question = (headerDiv[0] as HTMLDivElement).innerText;
            const answers = divWithQuestion.getElementsByTagName("label");

            Array.from(answers).forEach((answer: any, idx) => {
                const numberOfAnswers = answers.length;
                const answerText = answer.innerText;
                const input = answer.getElementsByTagName("input")[0];
                const checked = answer.classList.contains("sv-rating__item--selected");
                let selectedText: string;

                if (checked) {
                    selectedText = t("selected_answer");
                }
                else {
                    selectedText = t("unselected_answer");
                }

                input.setAttribute("aria-label", `${question}, ${answerText}, ${idx + 1} ${t('of')} ${numberOfAnswers}, ${selectedText}`);
            });
        }
    }

    return {
        updateAriaLabels,
        updateAriaLabelsAnswerInCell,
        updateAriaLabelsForQuestionInRow
    }
}
import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { OrganizationException } from '../../../interfaces';
import { SectionContainer } from '../../Other/SectionContainer';
import { useMutation } from '@apollo/client';
import { UPDATE_EXCEPTIONS_MUTATION } from './queries';
import { OrganizationExceptionsForm } from './OrganizationExceptionsForm';
import { useFeatureManager } from 'hooks/useFeatureManager';


interface OrganizationExceptionsProps {
    providerId: number,
    exceptions: OrganizationException[] | undefined,
    onUpdated: (exceptions: OrganizationException[]) => void
}

export const OrganizationExceptions: FC<OrganizationExceptionsProps> = (props) => {
    const { providerId, exceptions, onUpdated } = props;
    const { t, ready: translationsReady } = useTranslation('providerprofile', { useSuspense: false });
    
    const { isProviderProfileSelfServeFeatureEnabled } = useFeatureManager();

    const [cachePendingUpdate, setCachePendingUpdate] = useState(false);

    const [update, updateMutation] = useMutation(UPDATE_EXCEPTIONS_MUTATION, {
        update: (_, result) => {
            if (result.data?.updateOrgExceptions?.details?.organizationExceptions) {
                setCachePendingUpdate(true);
                onUpdated(result.data.updateOrgExceptions.details.organizationExceptions);
            }
        }
    });

    useEffect(() => {
        setCachePendingUpdate(false);
    }, [exceptions]);
    
    return !translationsReady
        ? <></>
        : <SectionContainer title={t('organization_exceptions__section_title')}>
            {
                exceptions?.map((exception, index) => (
                    <OrganizationExceptionsForm key={index}
                        providerId={providerId}
                        exceptions={exceptions}
                        entry={exception}
                        mutation={[update, updateMutation]}
                        cachePendingUpdate={cachePendingUpdate}
                    />
                ))
            }
            {
                isProviderProfileSelfServeFeatureEnabled() &&
                <OrganizationExceptionsForm
                    providerId={providerId}
                    exceptions={exceptions}
                    entry={undefined}
                    mutation={[update, updateMutation]}
                    cachePendingUpdate={cachePendingUpdate}
                />
            }
        </SectionContainer>
}
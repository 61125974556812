import React, { useEffect, forwardRef, RefObject, useMemo, useRef } from 'react';
import FullCalendar from '@fullcalendar/react';
import { EventSourceFunc, DatesSetArg, DidMountHandler, DayCellMountArg, ViewMountArg } from '@fullcalendar/core';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import momentTimezonePlugin from '@fullcalendar/moment-timezone';
import { useAuth } from 'components/Auth';
import { DateClickArgKeyboard, EventClickArgKeyboard, EventType } from '../../interfaces';
import { useTranslation } from 'react-i18next';
import { extendMoment } from 'moment-range';
import * as _Moment from 'moment';
import { tzSafeMinDate, tzSafeMaxDate } from 'constants/momentDates';
import { Moment } from 'moment';
const moment = extendMoment(_Moment);

interface MonthViewProps {
    selectedPeriodStart: string,
    selectedDate: string,
    events: EventSourceFunc,
    onDatesSet: (arg: DatesSetArg) => void,
    onDateClick: (arg: DateClickArgKeyboard) => void,
    onEventClick: (arg: EventClickArgKeyboard) => void
}

export const MonthView = forwardRef<FullCalendar, MonthViewProps>((props, ref) => {
    const { selectedPeriodStart, selectedDate, events, onDatesSet, onDateClick, onEventClick } = props;
    const { t, i18n } = useTranslation('calendaring', { useSuspense: false });
    const { effectiveProviderData } = useAuth();

    let calendarElementRef = useRef<HTMLDivElement>();
    let focusedElementRef = useRef<HTMLAnchorElement>();

    const eventsSrc = useRef<EventSourceFunc>(events);
    eventsSrc.current = events;
    const getEvents: EventSourceFunc = (dateRange, successCallback, failureCallback) => {
        eventsSrc.current(dateRange, successCallback, failureCallback);
    } 

    const timeZone = effectiveProviderData?.timeZone;

    useEffect(() => {
        (ref as RefObject<FullCalendar>)?.current?.getApi().gotoDate(selectedPeriodStart);
    }, [selectedPeriodStart]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        (ref as RefObject<FullCalendar>)?.current?.getApi().select(selectedDate);
    }, [selectedDate, selectedPeriodStart]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        (ref as RefObject<FullCalendar>)?.current?.getApi().refetchEvents();
    }, [moment.localeData().firstDayOfWeek()]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        const handler = () => {
            focusedElementRef.current?.blur();
            focusedElementRef.current = undefined;
        }
        window.addEventListener('click', handler);
        return () => window.removeEventListener('click', handler);
    }, []);

    const getDateElement = (date: Moment): HTMLAnchorElement | null => (
        calendarElementRef.current?.querySelector<HTMLAnchorElement>(`td[data-date="${date.format('YYYY-MM-DD')}"] a`) ?? null
    )

    const dayCellDidMount: DidMountHandler<DayCellMountArg> = (arg) => {
        const dateEl = arg.el.querySelector<HTMLAnchorElement>('a') as HTMLAnchorElement;
        dateEl.tabIndex = 0;
        dateEl.setAttribute('role', 'button');

        const range = moment.range(moment(arg.date).startOf('day'), moment(arg.date).endOf('day'));
        const events = arg.view.calendar.getEvents()
            // @ts-expect-error
            .filter(event => event._def.ui.display !== 'background')
            .filter(event => moment.range(event.start ?? tzSafeMinDate, event.end ?? tzSafeMaxDate).overlaps(range));

        const appointments = events.filter(event => event.extendedProps.type === EventType.Reservation);
        const unavailabilities = events.filter(event => event.extendedProps.type === EventType.Unavailability);

        const eventsLabel = !events.length
            ? t('no_events_label')
            : `${Boolean(appointments.length)
                ? t('appointments_count_label', { count: appointments.length })
                : t('no_appointments_label')}, ${
                Boolean(unavailabilities.length)
                ? t('unavailabilities_count_label', { count: unavailabilities.length })
                : t('no_unavailabilities_label')}`;

        dateEl.setAttribute('aria-label', moment(arg.date).format('Do MMMM') + ', ' + eventsLabel);

        dateEl.addEventListener('focus', (jsEvent) => {
            focusedElementRef.current = jsEvent.target as HTMLAnchorElement ?? undefined;
        });

        dateEl.addEventListener('keydown', (jsEvent) => {
            if (jsEvent.code === 'Tab') {
                return;
            }

            jsEvent.preventDefault();

            switch (jsEvent.code) {
                case 'Enter': {
                    onDateClick({
                        view: arg.view,
                        allDay: true,
                        date: arg.date,
                        dayEl: arg.el,
                        dateStr: moment(arg.date).format('YYYY-MM-DD'),
                        jsEvent: jsEvent
                    });
                    break;
                }
                case 'Escape': {
                    document.getElementById('calendar_previous_period_button')?.focus();
                    focusedElementRef.current = undefined;
                    break;
                }
                default: {
                    let targetDate: Moment | undefined = undefined;
                    switch (jsEvent.code) {
                        case 'ArrowUp': {
                            targetDate = moment(arg.date).subtract(1, 'week');
                            break;
                        }
                        case 'ArrowDown': {
                            targetDate = moment(arg.date).add(1, 'week');
                            break;
                        }
                        case 'ArrowLeft': {
                            targetDate = moment(arg.date).subtract(1, 'day');
                            break;
                        }
                        case 'ArrowRight': {
                            targetDate = moment(arg.date).add(1, 'day');
                            break;
                        }
                        case 'PageUp': {
                            targetDate = moment(arg.date).subtract(1, 'month');
                            break;
                        }
                        case 'PageDown': {
                            targetDate = moment(arg.date).add(1, 'month');
                            break;
                        }
                        case 'Home': {
                            if (jsEvent.ctrlKey) {
                                targetDate = moment(arg.date).startOf('month');
                            } else {
                                targetDate = moment(arg.date).startOf('week');
                            }
                            break;
                        }
                        case 'End': {
                            if (jsEvent.ctrlKey) {
                                targetDate = moment(arg.date).endOf('month');
                            } else {
                                targetDate = moment(arg.date).endOf('week');
                            }
                            break;
                        }
                        default: {
                            return;
                        }
                    }

                    let targetEl = getDateElement(targetDate);

                    if (!targetEl) {
                        targetDate.isAfter(arg.date)
                            ? arg.view.calendar.next()
                            : arg.view.calendar.prev();
                        targetEl = getDateElement(targetDate);
                    }

                    targetEl?.focus();
                }
            }
        });
    }

    const viewDidMount: DidMountHandler<ViewMountArg> = (arg) => {
        calendarElementRef.current = arg.el as HTMLDivElement;
    }

    const calendar = useMemo(() => (
        <FullCalendar
            dayMaxEventRows={true}
            initialView="dayGridMonth"
            ref={ref}
            plugins={[dayGridPlugin, interactionPlugin, momentTimezonePlugin]}
            timeZone={timeZone || ''}
            unselectAuto={false}
            firstDay={moment.localeData(i18n.language).firstDayOfWeek()}
            locale={i18n.language} 
            dateClick={onDateClick}
            datesSet={onDatesSet}
            initialDate={selectedPeriodStart}
            headerToolbar={false}
            eventClick={onEventClick}
            height="100%"
            dayHeaderClassNames="monthview_header calendar_table_header"
            expandRows={true}
            events={getEvents}
            viewDidMount={viewDidMount}
            dayCellDidMount={dayCellDidMount}
        />), [timeZone, i18n.language]); // eslint-disable-line react-hooks/exhaustive-deps

    return calendar;
})

import React, { useEffect, useState } from 'react';
import { useQuery, useMutation } from '@apollo/client';
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { GET_CASE_DATA, DECLINE_REFERRAL_MUTATION, ACCEPT_REFERRAL_MUTATION,
    SAVE_FIRST_AVAILABLE_APPOINTEMENT_DATE_MUTATION, TERMINATE_TODO_MUTATION } from '../queries';
import { HighLevelDetails } from '../components/HighLevelDetails';
import { FirstAvailableDate } from '../components/FirstAvailableDate';
import { CaseManagementActionContainer, Spinner } from 'components/Shared';
import { useAuth } from 'components/Auth';
import { useIsActivityAccepted } from 'hooks/useIsActivityAccepted'
import { Moment } from 'moment';
import { ACTIVE_STATUS_CODE } from 'constants/activityStatus';
import { DeclineCaseDialog } from '../../components/DeclineCaseDialog';
import { confirm } from 'components/Shared/ConfirmDialog';
import { AcceptanceDecision } from '../components/AcceptanceDecision';
import { PageMetadata } from 'components/Shared/PageMetadata';
import { isValueDefined } from 'utils';

import './ConfirmCaseAcceptance.scss';


export enum ConfirmAcceptanceViewStages {
    HIGH_LEVEL_DETAILS_VIEW = 'HIGH_LEVEL_DETAILS_VIEW',
    FIRST_AVAILABLE_DATE_VIEW = 'FIRST_AVAILABLE_DATE_VIEW',
    ACCEPTANCE_DECISION_VIEW = 'ACCEPTANCE_DECISION_VIEW'
}


interface ConfirmAcceptanceViewProps { }

export const ConfirmAcceptanceView: React.FC<ConfirmAcceptanceViewProps> = (_) => {
    const { t, ready: translationsReady } = useTranslation('caseManagement', { useSuspense: false });

    const { activityId: activityIdString } = useParams<{activityId: string}>();
    const location = useLocation();
    const navigate = useNavigate();
    
    const { effectiveProviderData } = useAuth();
    const providerId = effectiveProviderData?.providerId ?? -1;

    const activityId = parseInt(activityIdString || '');

    const todoWorkflowId = location?.state?.workflowId;

    const [currentViewToShow, setCurrentViewToShow] = useState<ConfirmAcceptanceViewStages>(ConfirmAcceptanceViewStages.HIGH_LEVEL_DETAILS_VIEW)

    const [declineDialogOpen, setDeclineDialogOpen] = useState(false);

    const [isConfirmed, setIsConfirmed] = useState(false);

    const { data: caseDetailsData, loading: caseDataLoading } = useQuery(GET_CASE_DATA, {
        variables: { activityId: activityId },
        notifyOnNetworkStatusChange: true,
        fetchPolicy: 'network-only'
    });


    useEffect(() => {
        if (!caseDataLoading && caseDetailsData?.activity?.providerFirstAvailableAppointmentDate) {
            setCurrentViewToShow(ConfirmAcceptanceViewStages.ACCEPTANCE_DECISION_VIEW)
        }
    }, [caseDataLoading, caseDetailsData])

    const { data: isActivityAcceptedResponse, loading: isActivityAcceptedLoading } = useIsActivityAccepted(activityId);

    const [declineCase, declineCaseMutation] = useMutation(DECLINE_REFERRAL_MUTATION, {
        onCompleted: () => navigate('/', { replace: true })
    });

    const [terminateToDo] = useMutation(TERMINATE_TODO_MUTATION, {
        context: { skipGlobalErrorHandling: true },
        onError: (err) => {
            console.error(err)
        }
    });

    const [acceptReferral, acceptReferalMutation] = useMutation(ACCEPT_REFERRAL_MUTATION, {
        variables: { activityId: activityId, providerId: providerId },
        onCompleted: () => {
            setIsConfirmed(true);
            setCurrentViewToShow(ConfirmAcceptanceViewStages.HIGH_LEVEL_DETAILS_VIEW);
        }
    });

    const [saveDate, { loading: saveDateLoading }] = useMutation(SAVE_FIRST_AVAILABLE_APPOINTEMENT_DATE_MUTATION, {});

    const saveDateMutation = (date: Moment) => {
        return saveDate({
            variables: {
                date: date.toISOString(true),
                activityId: activityId,
                providerId: providerId
            }
        })
    }

    const declineCaseAfterDateOutsideParams = () => {
        return  declineCase({
            variables: {
                activityId: activityId,
                providerId,
                reasonCode: 5
            }
        });
    }

    const showDateOutsideParamsConfirmDialog = () => {
        return confirm({
            message: t('date_outside_decline', {
                firstName: caseDetailsData?.activity?.client?.firstName,
                lastName: caseDetailsData?.activity?.client?.lastName
            }),
            title: t('decline_case'),
            cancelLabel: t('cancel'),
            confirmLabel: t('continue')
        });
    }

    const onSaveDateOutsideParams = async (date: Moment) => {
        const answer = await showDateOutsideParamsConfirmDialog()
        if (answer) {
            saveDateMutation(date).then(() => {
                return declineCaseAfterDateOutsideParams()
            });
        }
    }

    const onSaveDate = (date: Moment) => {
        saveDateMutation(date).then(() => {
            setCurrentViewToShow(ConfirmAcceptanceViewStages.ACCEPTANCE_DECISION_VIEW)
        });
    }

    const handleDecline = (reasonCode: number) => {
        return declineCase({
            variables: {
                activityId: activityId,
                providerId: providerId,
                reasonCode: reasonCode
            }
        })
    };

    const onHightLevelDetailsContinue = () => {
        if (isConfirmed) {
            console.info(`🌎🌐🌍 Navigating to /clients/${activityId}`);
            return navigate(`/clients/${activityId}`);
        }
        setCurrentViewToShow(ConfirmAcceptanceViewStages.FIRST_AVAILABLE_DATE_VIEW)
    }

    const isReferralExpired = new Date(caseDetailsData?.referral?.referralExpirationDate || '') <= new Date();


    const renderHighLevelDetails = () => (
        <HighLevelDetails 
            onContinue={onHightLevelDetailsContinue} 
            activity={caseDetailsData?.activity}
            referral={caseDetailsData?.referral}
            confirmed={isConfirmed}
        />
    )

    const renderFirstAvailableDateView = () => (
        <FirstAvailableDate 
            activity={caseDetailsData?.activity} 
            onSaveDate={onSaveDate}
            onSaveDateOutsideParams={onSaveDateOutsideParams}
            loading={saveDateLoading || caseDataLoading || declineCaseMutation.loading}
            slaDate={caseDetailsData?.referral?.sLADate} 
        />
    )

    const renderAcceptanceDecisionView = () => (
        <AcceptanceDecision 
            onAccept={acceptReferral} 
            onDecline={() => setDeclineDialogOpen(true)}
            activity={caseDetailsData?.activity} 
            isExpired={isReferralExpired}
            loading={declineDialogOpen || declineCaseMutation.loading || acceptReferalMutation.loading || acceptReferalMutation.loading}
        />
    )

    const getCurrentStep = () => {
        switch(currentViewToShow) {
            case ConfirmAcceptanceViewStages.ACCEPTANCE_DECISION_VIEW:
                return renderAcceptanceDecisionView()
            case ConfirmAcceptanceViewStages.HIGH_LEVEL_DETAILS_VIEW:
                return renderHighLevelDetails()
            case ConfirmAcceptanceViewStages.FIRST_AVAILABLE_DATE_VIEW:
                return renderFirstAvailableDateView()
            default:
                return renderHighLevelDetails()
        }
    } 


    const isEssentialDataLoading = caseDataLoading || isActivityAcceptedLoading;

    useEffect(() => {
        if (!isEssentialDataLoading) {
            // if Todo item through which the user was routed here is invalid
            if (isValueDefined(caseDetailsData?.activity?.activityStatusCode) && todoWorkflowId) {
                if (caseDetailsData?.activity?.activityStatusCode !== ACTIVE_STATUS_CODE || isActivityAcceptedResponse?.isActivityAcceptedByProvider) {
                    terminateToDo({ variables: { workflowId: todoWorkflowId }})
                }
            }
        }
    }, [todoWorkflowId, isActivityAcceptedResponse?.isActivityAcceptedByProvider, isEssentialDataLoading, caseDetailsData])

    const handleBackClick = () => {
        switch(currentViewToShow) {
            case ConfirmAcceptanceViewStages.FIRST_AVAILABLE_DATE_VIEW:
                setCurrentViewToShow(ConfirmAcceptanceViewStages.HIGH_LEVEL_DETAILS_VIEW);
                break;
            case ConfirmAcceptanceViewStages.ACCEPTANCE_DECISION_VIEW:
            case ConfirmAcceptanceViewStages.HIGH_LEVEL_DETAILS_VIEW:
            default:
                navigate(-1);
        }
    }

    return (
        <CaseManagementActionContainer onBackClick={isConfirmed ? undefined : handleBackClick} activityId={false} className="confirm_acceptance_container">
            {
                translationsReady && 
                <PageMetadata 
                    pageDescription={t('confirm_acceptance_page_description')} 
                    pageTitle={t('confirm_acceptance_page_title')}
                /> 
            }
            { 
                isEssentialDataLoading || !translationsReady
                ? <Spinner/>
                : <>
                    <h1 style={isConfirmed ? { textAlign: "center" } : {}}>
                        {isConfirmed ? t('confirmed', 'Confirmed') : t('confirm_acceptance', 'Confirm acceptance') }
                    </h1>
                    {
                        isActivityAcceptedResponse?.isActivityAcceptedByProvider
                        ? <p className="not-accepted-yet">
                            {t('case_already_accepted')}
                        </p>
                        : getCurrentStep()
                    }
                </>
            }
            <DeclineCaseDialog open={declineDialogOpen}
                onDeclineCase={handleDecline}
                selectReason
                onCancel={() => setDeclineDialogOpen(false)}
                firstName={caseDetailsData?.activity?.client?.firstName ?? ''}
                lastName={caseDetailsData?.activity?.client?.lastName ?? ''}
            />
        </CaseManagementActionContainer>
    );
}


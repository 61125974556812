import React from 'react'
import { useLocation, Outlet, Navigate } from 'react-router-dom';

interface AuthLayoutProps {
    authenticated: boolean
}

export const AuthLayout: React.FC<AuthLayoutProps> = ({ authenticated }) => {
    const location = useLocation();
    return (authenticated 
        ? <Outlet /> 
        : <Navigate to="/login" state={{ from: location }} />); 
};
import { useQuery } from '@apollo/client';
import { SCHEDULE_QUERY, ScheduleQueryData, ScheduleQueryVars } from '../queries';
import { useAuth } from 'components/Auth';

export const useScheduleQuery = () => {
    const { effectiveProviderData } = useAuth();
    
    return useQuery<ScheduleQueryData, ScheduleQueryVars>(
        SCHEDULE_QUERY,
        {
            fetchPolicy: 'network-only',
            variables: {
                providerId: effectiveProviderData?.providerId ?? 0
            }
        }
    );
}
import { useQuery } from "@apollo/client";
import { USE_TODOS_QUERY } from "./queries";

// TODO: This hook should replace the useToDos hook
// inside Todos component. Since all consumers of Todos want the 
// latest Todos, it does not make sense to rely purely on Context
// since the Todos could be outdated.  
export const useTodos = (providerId: number | undefined) => {
    const { data, loading } = useQuery(USE_TODOS_QUERY, {
        variables: {
            providerId: providerId!
        },
        fetchPolicy: 'network-only',
        errorPolicy: 'all',
        notifyOnNetworkStatusChange: true
    });

    return {
        toDos: data?.toDos,
        loading
    }
}
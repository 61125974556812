import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { SurveyModel } from 'survey-react';
import { SurveyJSContainer, SurveyJSContainerProps, useSurveyJSUtils } from '../../../components/SurveyJS';

interface RiskAssessment_Props extends SurveyJSContainerProps {}

export const RiskAssessment: FC<RiskAssessment_Props> = (props) => {
    const { onSurveyJSInitialized, ...rest } = props;
    const { t } = useTranslation('formNotes', { useSuspense: false });
    const { updateAriaLabels, updateAriaLabelsAnswerInCell } = useSurveyJSUtils();

    const handleSurveyJSInitialized = (surveyModel: SurveyModel) => {
        onSurveyJSInitialized?.(surveyModel);

        surveyModel.onVisibleChanged.add(() => {
            // const document = options.htmlElement?.ownerDocument;
            updateAriaLabels(document?.querySelector("div[name='rsk_informed_others']"));
            updateAriaLabelsAnswerInCell(document?.querySelector("div[name='rsk_assessed_risks']"));
        });

        surveyModel.onValueChanged.add(() => {
            // const document = options.htmlElement?.ownerDocument;
            updateAriaLabels(document?.querySelector("div[name='rsk_informed_others']"));
            updateAriaLabelsAnswerInCell(document?.querySelector("div[name='rsk_assessed_risks']"));
        });
    }

    return <SurveyJSContainer
        onSurveyJSInitialized={handleSurveyJSInitialized}
        {...rest}
        coachingNote={t('providerhub/assessment/risk_note')}
        coachingNoteTitle={t('providerhub/assessment/risk_note_title')}
    />
}
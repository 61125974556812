import { EventInput } from '@fullcalendar/core';
import { ScheduleItem, UnavailabilityItem, Event, AvailabilityItem, EventType, Reservation, ICalendarReservation } from '../interfaces';
import { Unavailability } from '../Unavailabilities';
import { tzSafeMinDate, tzSafeMaxDate } from 'constants/momentDates';
import { ScheduleQueryData } from '../queries';

import * as Moment from 'moment';
import { DateRange, extendMoment } from 'moment-range';
import { parseUavailabilityName, inferStartAndEndTimes } from '../Unavailabilities/utils';
import { ISchedule, ScheduleSlot } from '../AppointmentModals/interfaces';
const moment = extendMoment(Moment);


/**
 * @param {ScheduleItem[]} items events to parse
 * @param {DateRange} dateRange period for parsed events
 * @param {string} providerTimezone timezone name in which events should be parsed
 * @returns {Event[]} collection of one time and interpolated recurring events withing provided dates range
 * 
 * Merges overlapping events to create single, continous ones
 */
export const flattenScheduleItems = (items: ScheduleItem[], dateRange: DateRange, providerTimezone: string): Event[] => {
    return items.reduce<Event[]>((acc, item) => {
        const start = item.startDate ? moment.parseZone(item.startDate).tz(providerTimezone) : undefined;
        const end = item.endDate ? moment.parseZone(item.endDate).tz(providerTimezone) : undefined;

        const period = moment.range(dateRange.start, dateRange.end);

        // if recurring event has no end or start date they fallback to maximum possible date
        // later it will be truncated to selected dates range
        const itemDateRange = moment.range(start?.clone().startOf('day') ?? tzSafeMinDate, end?.clone().endOf('day') ?? tzSafeMaxDate);

        if (!itemDateRange.overlaps(period)) {
            return acc;
        }

        if (item.oneTime && start && end) {
            acc.push({
                start: start,
                end: end,
                item: item
            });
        } else {
            const interpolationRange = itemDateRange.clone().intersect(period) as DateRange;
            const weeks = Array.from(interpolationRange?.by('week') ?? []);
            
            // shift are configured per week day
            // interating by weeks and interpolating shift instances
            for (let i = 0; i < weeks.length; ++i) {
                const startDay = weeks[i].clone();
                startDay.tz(providerTimezone);
                startDay.startOf('isoWeek');

                const shifts = item.shifts?.map((shift) => {
                    const shiftLengthInDays =
                        shift.startDay <= shift.endDay
                            ? shift.endDay - shift.startDay
                            : shift.endDay - shift.startDay + 7;

                    // Purpose of those is to simply get the hour/minute/second - it's not intended to get the actual date of the shift
                    // this will be inferred by the startDay below
                    const tmpStart = moment(shift.startTime, 'HH:mm:ss');
                    const tmpEnd = moment(shift.endTime, 'HH:mm:ss');

                    const shiftStart = startDay.clone()
                        .add(shift.startDay - startDay.isoWeekday(), 'days')
                        .set({ hour: tmpStart.hour(), minute: tmpStart.minute(), second: tmpStart.second() })
                        .tz(providerTimezone);
                    const shiftEnd = startDay.clone()
                        .add(shift.startDay - startDay.isoWeekday() + shiftLengthInDays, 'days')
                        .set({ hour: tmpEnd.hour(), minute: tmpEnd.minute(), second: tmpEnd.second() })
                        .tz(providerTimezone);

                    return moment.range(shiftStart, shiftEnd);
                });

                for (const shift of shifts) {
                    if (shift.overlaps(interpolationRange)) {
                        acc.push({
                            start: shift.start,
                            end: shift.end,
                            item: item
                        });
                    }
                    
                    // in case some of the shifts start or end outside selected dates range but still partially overlap
                    if (i === 0) {
                        const clone = shift.clone();
                        clone.start.subtract(1, 'week').set({ hour: shift.start.hour(), minute: shift.start.minute() });
                        clone.end.subtract(1, 'week').set({ hour: shift.end.hour(), minute: shift.end.minute() });
                        if (clone.overlaps(interpolationRange)) {
                            acc.push({
                                start: clone.start,
                                end: clone.end,
                                item: item
                            });
                        }
                    }
                    if (i === (weeks.length - 1)) {
                        const clone = shift.clone();
                        clone.start.add(1, 'week').set({ hour: shift.start.hour(), minute: shift.start.minute() });
                        clone.end.add(1, 'week').set({ hour: shift.end.hour(), minute: shift.end.minute() });
                        if (clone.overlaps(interpolationRange)) {
                            acc.push({
                                start: clone.start,
                                end: clone.end,
                                item: item
                            });
                        }
                    }
                }
            }
        }
        return acc;
    }, []);
};

export const mapReservations = (availabilities: AvailabilityItem[], providerTimezone: string): Event[] => {
    return availabilities
        .reduce<ICalendarReservation[]>((acc, availability) => [...acc, ...(availability.reservations.map(reservation => ({...reservation, modalityCode: availability.modalityCode})))], [])
        .map<Event>((reservation: Reservation) => ({
            start: moment.tz(reservation.startTime, providerTimezone), 
            end: moment.tz(reservation.endTime, providerTimezone), 
            item: reservation
        }));
};

export const mapUnavailabilityItemToUnavailability = (unavailabilityItem: UnavailabilityItem): Unavailability => {
    const unavailabilityRecurringDays: number[] = [];

    if (unavailabilityItem.shifts?.length) {
        for (const shift of unavailabilityItem.shifts) {
            if (unavailabilityRecurringDays.indexOf(shift.startDay) === -1) {
                unavailabilityRecurringDays.push(shift.startDay);
            }
        }
    }

    const startDate = unavailabilityItem.startDate ? moment(unavailabilityItem.startDate) : undefined;
    const endDate = unavailabilityItem.endDate ? moment(unavailabilityItem.endDate) : undefined;
    
    const { startTime, endTime } = inferStartAndEndTimes(unavailabilityItem)

    return {
        startDate: startDate?.toDate(),
        endDate: endDate?.toDate(),
        address: unavailabilityItem.address,
        reason: unavailabilityItem.unavailabilityReason,
        seriesId: unavailabilityItem.seriesId,
        recurring: !unavailabilityItem.oneTime,
        scheduleId: unavailabilityItem.scheduleId,
        recurringDays: unavailabilityRecurringDays.sort(),
        wspTaskCode: unavailabilityItem.wspTaskCode,
        activityId: unavailabilityItem.activityId,
        startTime,
        endTime
    };
};

/**
 * @param {Event[]} events events which period should be merged
 * @returns {DateRange[]} merged periods of provided events
 * 
 * Merges overlapping events to create single, continous ones
 */
export const mergeOverlappingEvents = (events: Event[]): DateRange[] => {
    const ranges = events.map((x) => moment.range(x.start, x.end));
    const mergedRanges: DateRange[] = [];

    while (ranges.length) {
        const range = ranges.pop() as DateRange;

        const overlapping = ranges.find((x) => x.overlaps(range, { adjacent: true }));

        if (overlapping) {
            const merged = overlapping.add(range, { adjacent: true });
            overlapping.start = merged?.start ? merged.start : overlapping.start;
            overlapping.end = merged?.end ? merged.end : overlapping.end;
        } else {
            mergedRanges.push(range);
        }
    }

    return mergedRanges;
};


/**
 * @param {DateRange[]} events events from which opposite events will be calculated
 * @param {DateRange} period boundary for calculated opposite events
 * @returns {DateRange[]} events with opposite ranges
 * 
 * Creates events that are opposite of provided events
 * for example, 12:00-14:00 and 16:00-17:00 with range 00:00 to 00:00 next day
 * will result with 00:00-12:00, 14:00-16:00, and 17:00-00:00
 */
export const getInvertedEvents = (events: DateRange[], period: DateRange): DateRange[] => {
    const sorted = events.sort((a, b) => a.start.valueOf() - b.start.valueOf());
    const result: DateRange[] = [];

    let start = moment(period.start);

    for (const range of sorted) {
        if (!range.contains(start)) {
            result.push(moment.range(start, range.start));
        }
        start = moment(range.end);
    }

    if (period.contains(start)) {
        result.push(moment.range(start, period.end));
    }
    return result;
};

/**
 * @param {DateRange[]} events collection of events to find
 * @returns {Event[]} events collection with all day (midnight to midnight) events split
 * 
 * Finds events that at least one full day and splits into all day event
 * and two partial events before and after the whole day events,
 * for example 2020-09-09T16:00:00 to 2020-09-11T14:00:00 will result in
 * 2020-09-10T00:00:00 to 2020-09-11T00:00:00,
 * and 020-09-09T16:00:00 to 2020-09-10T00:00:00,
 * and 2020-09-11T00:00:00 to 2020-09-11T14:00:00
 */
export const splitWholeDayEvents = (events: DateRange[]): Event[] => {
    const result: Event[] = [];

    for (const range of events) {
        // truncate event period to whole days (midnight to midnight)
        const start = range.start.clone().subtract(1, 'millisecond').endOf('day').add(1, 'millisecond');
        const end = range.end.clone().add(1, 'millisecond').startOf('day');

        if (end.diff(start, 'days') >= 1) {
            result.push({
                start: start,
                end: end,
                allDay: true,
                item: {}
            });

            if (start.diff(range.start) > 0) {
                result.push({
                    start: range.start,
                    end: start,
                    allDay: false,
                    item: {}
                });
            }

            if (range.end.diff(end) > 0) {
                result.push({
                    start: end,
                    end: range.end,
                    allDay: false,
                    item: {}
                });
            }
        } else {
            result.push({
                start: range.start,
                end: range.end,
                allDay: false,
                item: {}
            });
        }
    }

    return result;
};

export const parseEvents = (data: ScheduleQueryData | undefined, dateRangeJSON: string, providerTimezone: string) => {
    if (!data) return [];

    const dateRange: {
        start: string,
        end: string,
        startStr: string,
        endStr: string,
        timeZone: string
    } = JSON.parse(dateRangeJSON);

    const calendarTimezone = dateRange.timeZone;
    const start = moment.tz(dateRange.startStr, calendarTimezone);
    const end = moment.tz(dateRange.endStr, calendarTimezone);
    const period = moment.range(start, end);

    const availabilities = flattenScheduleItems(data.schedule.availabilities, period, providerTimezone)
        .map((event) => convertTimezone(event, calendarTimezone));
    const mergedAvailabilities = mergeOverlappingEvents(availabilities);

    const notAvailabilites = splitWholeDayEvents(getInvertedEvents(mergedAvailabilities, period))
        .map(event => ({
            start: event.allDay ? event.start.format('YYYY-MM-DD') : event.start.toDate(),
            end: event.allDay ? event.end.format('YYYY-MM-DD') : event.end.toDate(),
            backgroundColor: '#B7BDC0',
            display: 'background',
            groupId: 'availabilities',
            extendedProps: {type: EventType.Availability}
        }));

    const reservations = mapReservations(data.schedule.availabilities, providerTimezone)
        .map((event) => convertTimezone(event, calendarTimezone))
        .map<EventInput>(({start, end, item}) => ({
            start: start.toISOString(true),
            end: end.toISOString(true),
            title: item?.clientName,
            backgroundColor: "#B6D2FF",
            color: "#0055DE",
            textColor: "black",
            className: 'appointment_event',
            extendedProps: {...item, type: EventType.Reservation}
        }));
    const unavailabilities = flattenScheduleItems(data.schedule.unavailabilities, period, providerTimezone)
        .map((event) => convertTimezone(event, calendarTimezone))
        .map<EventInput>(({start, end, item}) => {
            const unavailabilityReason = data?.schedule?.unavailabilityReasons?.find(reason => reason.id === (item as UnavailabilityItem).unavailabilityReasonID);
            return {
                start: start.toISOString(true),
                end: end.toISOString(true),
                title: parseUavailabilityName(unavailabilityReason?.description),
                backgroundColor: "#FFE7EA",
                color: "#D00218",
                textColor: "black",
                className: 'unavailability_event',
                extendedProps: {
                    ...item,
                    unavailabilityReason: unavailabilityReason, 
                    type: EventType.Unavailability}
            }
        });

    return [...notAvailabilites, ...reservations, ...unavailabilities];
}

const convertTimezone = (event: Event, timezone: string): Event => ({
    start: moment.tz(event.start, timezone),
    end: moment.tz(event.end, timezone),
    item: event.item
})

export const scheduleKeysCompareFn = ((a, b) => {
    const [dayA, monthA, yearA] = a.split('/').map(val => Number(val));
    const [dayB, monthB, yearB] = b.split('/').map(val => Number(val));

    return (
        yearA - yearB === 0
            ? monthA - monthB === 0
                ? dayA - dayB
                : monthA - monthB
            : yearA - yearB
    );
});

export const sortScheduleKeys = (keys: string[]) => (
    keys.sort(scheduleKeysCompareFn)
)

export const convertTimeto12Clock = (time24: string) => {
    let ts = time24;
    let H = +ts.substr(0, 2);
    if (isNaN(H)) {
        // If NaN, then most likely hours do not have leading zeros 
        H = +ts.substr(0, 1);
    }
    let h: string | number = (H % 12) || 12;
    h = (h < 10) ? ("0"+h) : h;  // leading 0 at the left for 1 digit hours
    const ampm = H < 12 ? " AM" : " PM";
    ts = h + ts.substr(2, 3) + ampm;
    return ts;
}

export const groupSlotsByDate = (slots: ScheduleSlot[] | undefined, targetTimeZone: string) => {
    if (!slots) {
        return undefined;
    }

    const objGroups: {[date: string]: ScheduleSlot[]} = {};

    for (const slot of slots) {
        const convertedStartDate = slot.startDate.clone().tz(targetTimeZone).format('YYYY-MM-DD');

        objGroups[convertedStartDate]
            ? objGroups[convertedStartDate].push(slot)
            : objGroups[convertedStartDate] = [slot];
    }

    const groups = Object.keys(objGroups)
        .map(key => ({date: Moment.tz(key, 'YYYY-MM-DD', targetTimeZone), slots: objGroups[key]}))
        .sort((a, b) => a.date.unix() - b.date.unix());

    return groups;
}

export const parseIScheduleToScheduleSlot = (schedule: ISchedule): ScheduleSlot => ({
    addressId: schedule.addressId,
    duration: schedule.duration,
    isBackToBack: schedule.isBackToBack,
    isManagedCalendar: schedule.isManagedCalendar,
    scheduleId: schedule.scheduleId,
    startDate: moment.utc(schedule.startDateUtc)
})
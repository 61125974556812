import React, { FC, useEffect, useCallback, useState } from 'react';
import { useAuth } from '../AuthProvider';
import { useFormik } from 'formik';
import { Box, IconButton, FormControl, MenuItem, Dialog, DialogContent, Alert } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useLazyQuery } from '@apollo/client';
import { ProvidersLiteQueryData, ProvidersSummaryQueryVars, PROVIDERS_LITE_QUERY } from '../queries';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Button, Select, TextBox } from 'components/Shared/Inputs';
import { Spinner } from 'components/Shared';

interface SelectEffectiveProviderDialogProps {
    onEffectiveProviderChanged: () => void,
    isOpen: boolean,
    onClose: () => void
}

export const SelectEffectiveProviderDialog: FC<SelectEffectiveProviderDialogProps> = (props) => {
    const { t } = useTranslation(['login', 'common'], { useSuspense: false });
    const { providerData, effectiveProviderData, setEffectiveProvider } = useAuth();
    const { onEffectiveProviderChanged, isOpen, onClose } = props;
    const navigate = useNavigate();
    const [submitting, setSubmitting] = useState(false);

    const [getProviders, { data: providers }] = useLazyQuery<ProvidersLiteQueryData, ProvidersSummaryQueryVars>(
        PROVIDERS_LITE_QUERY);
    const getProvidersCallback = useCallback(getProviders, []);

    useEffect(() => {
        if (isOpen && providerData?.isAdmin && providerData?.vendor?.vendorId) {
            getProvidersCallback({ variables: { vendorId: providerData.vendor.vendorId} })
        }
    }, [providerData, getProvidersCallback, isOpen]);

    const formik = useFormik({
        initialValues: {
            providerId: effectiveProviderData?.providerId && effectiveProviderData?.providerId > 0
                ? effectiveProviderData?.providerId?.toString()
                : '',
            graphQL: ''
        },
        validate: (values) => { 
            const errors: any = {};

            if (!values.providerId) {
                errors.providerId = t('common:id_is_required','ID is required');
            } else if (/[^0-9]/g.test(values.providerId)) {
                errors.providerId = t('common:enter_valid_ID','Enter valid ID');
            }

            return errors;
        },
        validateOnBlur: true,
        onSubmit: (values) => {
            formik.setTouched({providerId: true});
            formik.validateForm();

            if (formik.isValid && values.providerId) {
                setSubmitting(true);
                setEffectiveProvider(Number.parseInt(values.providerId))
                    .then(response => {
                        if (!Boolean(response.effectiveUserAuthData?.provider)) {
                            formik.setFieldError('graphQL', `Provider with ID ${values.providerId} not found.`);
                        } else {
                            onEffectiveProviderChanged();
                            navigate('/');
                        }
                    })
                    .catch((error) => {
                        if (error?.message) {
                            formik.setFieldError('graphQL', error.message);
                        }
                    })
                    .finally(() => setSubmitting(false))
            }
        }
    });


    const onInputChange = (event) => {
        event.target.value = event.target.value.replace(/[^0-9]/g, '');
        formik.handleChange(event);
    }

    const getErrorMessage = (): string | null => {
        if (formik.touched.providerId && formik.errors.providerId) {
            return formik.errors.providerId;
        }
        if (formik.errors.graphQL) {
            return formik.errors.graphQL;
        }
        return null;
    }
    const isVendorAdmin = Boolean(providerData?.vendor?.vendorId);

    return (
        <Dialog open={isOpen} onClose={onClose} className="change_provider_dialog" >
            <DialogContent className="change_provider_dialog_content_wrapper">
                <form onSubmit={formik.handleSubmit} className="change_provider_form">
                    <Box className="change_provider_content_header">
                        <h2 className="global__header3">{
                            isVendorAdmin
                                ? t('login:select_provider', 'Select provider')
                                : t('login:enter_provider_ID','Enter provider ID')
                        }</h2>
                        <IconButton className="close_button" onClick={onClose}><CloseIcon /></IconButton>
                    </Box>
                    <p className="global__paragraph global__paragraph--light description">{
                        isVendorAdmin
                        ? t('login:provider_account_select_desc', 'You can switch between provider accounts by selecting the ID of the provider whose account you want to see.')
                        : t('login:provider_account_switch_desc', 'You can switch between provider accounts by entering the ID of the provider whose account you want to see.')
                    }</p>
                    {
                        isVendorAdmin
                            ? <FormControl variant="outlined" error={formik.touched.providerId && !!formik.errors.providerId}>
                                <Select
                                    labelId="demo-simple-select-outlined-label"
                                    id="demo-simple-select-outlined"
                                    name="providerId"
                                    value={formik.values.providerId}
                                    onBlur={formik.handleBlur}
                                    onChange={onInputChange}
                                    label={t('common:provider','Provider')}
                                >
                                {
                                    providers && providers.providersLite.map((provider, index) => (
                                        <MenuItem value={provider.providerId.toString()} key={index}>
                                            {`${provider.providerId}: ${provider.firstName} ${provider.lastName}`}
                                        </MenuItem>
                                    ))
                                }
                                </Select>
                            </FormControl>
                            : <TextBox
                                onChange={onInputChange}
                                value={formik.values.providerId}
                                onBlur={formik.handleBlur}
                                error={formik.touched.providerId && !!formik.errors.providerId}
                                name="providerId"
                                className="login_screen_form_input form_input_field"
                                label={t('common:provider_ID','Provider ID')}
                                id='provider_ID_input_field'
                            />
                    }
                    {
                        getErrorMessage() &&
                        <Alert severity="error">{getErrorMessage()}</Alert>
                    }
                    <div className="filler" />
                    <div className="action_buttons_div">
                        <Button type="button" variant="text" className="form_cancel_button" onClick={onClose}>
                            {t('common:cancel','Cancel')}
                        </Button>
                        {
                            submitting
                                ? <div className="spinner_wrapper"><Spinner fillParent={true} /></div>
                                : (<Button type="submit" variant="opaque" disabled={!formik.isValid} className="login_screen_form_submit_button">
                                    {t('common:continue', 'Continue')}
                                </Button>)
                        }
                    </div>
                </form>
            </DialogContent>
        </Dialog>
    )
}
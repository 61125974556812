import React, { FC } from 'react';
import { LoginScreenLayout } from './LoginScreenLayout';
import { ResetPasswordForm } from '../Forms/ResetPasswordForm';
import { useParams } from 'react-router-dom';
import { PageMetadata } from 'components/Shared/PageMetadata';
import { useTranslation } from 'react-i18next';

interface ResetPasswordScreenProps { }

export const ResetPasswordScreen: FC<ResetPasswordScreenProps> = (_) => {
    const { token } = useParams<{token: string}>();
    const { t, ready } = useTranslation(['login'], { useSuspense: false });

    return (
        <React.Fragment>
            { ready && 
                <PageMetadata 
                    pageDescription={t('reset_password_page_description')} 
                    pageTitle={t('reset_password_page_title')}
                /> 
            }
            <LoginScreenLayout>
                <ResetPasswordForm token={token}/>
            </LoginScreenLayout>
        </React.Fragment>
    )
}
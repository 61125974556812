import { Button, Container, Grow, Modal, Paper, TableContainer } from '@mui/material';
import FiltersIcon from '@mui/icons-material/FilterList';
import React, { FC, useState } from 'react';
import { InvoicingDataRequest } from '../../interfaces';
import { InvoicesTableFiltersForm } from '../../components/InvoicesTable';
import './InvoicesTableView.scss';
import { useInvoicesColumnsDefinitions, useInvoicesListQuery, useProcessInvoicesData } from '../../hooks';
import { useTranslation } from 'react-i18next';
import { SearchInput } from 'components/Shared/Inputs';
import { ISortsDefinition, MaterialTable, Paginator } from 'components/Shared/MaterialTable';
import { Spinner } from 'components/Shared';
import { PageMetadata } from 'components/Shared/PageMetadata';
import { Card } from 'components/Shared/Card';
import { FullScreenMessage } from 'components/Shared/FullScreenMessage';

interface InvoicesTableViewProps { }

export const InvoicesTableView: FC<InvoicesTableViewProps> = (_) => {
    const { t, ready: translationsReady } = useTranslation(['invoicing', 'common'], { useSuspense: false });

    const [ showFilters, setShowFilters ] = useState<boolean>(false);

    const [ dataRequest, setDataRequest ] = useState<InvoicingDataRequest>({
        filters: {
            status: -1,
            startDate: undefined,
            endDate: undefined
        },
        sorts: {'invoiceId': 'asc'},
        take: 10,
        skip: 0,
        search: ''
    });
    
    const handleSearchValueChange = (value: string) => {
        setDataRequest({...dataRequest, search: value, skip: 0});
    }

    const handleSortsChange = (sorts: ISortsDefinition) => {
        setDataRequest({...dataRequest, sorts: sorts});
    }

    const handlePageChange = (currentPage: number) => {
        setDataRequest({...dataRequest, skip: (currentPage - 1) * dataRequest.take});
    }

    const { data, loading } = useInvoicesListQuery();

    const [pagedData, paginationDetails] = useProcessInvoicesData(data?.invoices, dataRequest);
    const columnsDefinitions = useInvoicesColumnsDefinitions();

    const emptyStateMessage = <FullScreenMessage title={t('sorry_we_couldnt_find_any_matching_invoices')} />

    return (
        <React.Fragment>
            { translationsReady && 
                <PageMetadata 
                    pageDescription={t('invoicing_page_description')} 
                    pageTitle={t('invoicing_page_title')}
                /> 
            }
            <div className="table_view_wrapper invoices_view">
            {
                !translationsReady
                ? <Spinner fillParent/>
                : <Container>
                    <div className="table_view_title_wrapper">
                        <h1>{t('invoicing')}</h1>
                        <div className="search_box_filter_wrapper">
                            <SearchInput value={dataRequest.search} onChange={handleSearchValueChange}
                                label={t('search_invoices_input_label')} placeholder={t('search_invoices_input_placeholder')}
                                helperText={t('search_by_reference_no_invoice_id_activity_id_or_client_name')} 
                                ariaLabel={t('search_by_reference_no_invoice_id_activity_id_or_client_name_label')} />
                            <Button className="global__link filter_button" startIcon={<FiltersIcon />} variant="text"
                                onClick={() => setShowFilters(!showFilters)}>{t('filters')}</Button>
                        </div>
                    </div>
                    <TableContainer component={Card} elevation={1} className="table_container">
                        <MaterialTable
                            columns={columnsDefinitions}
                            onSortsChange={handleSortsChange}
                            sorts={dataRequest.sorts}
                            data={pagedData}
                            dataLoading={loading}
                            emptyStateMessage={emptyStateMessage}
                        />
                        </TableContainer>
                    <Paginator paginationDetails={paginationDetails} onPageChange={handlePageChange}/>

                    <Modal role="dialog" aria-labelledby="filter_content_title" aria-describedby="filter_content_desc" aria-modal 
                    open={showFilters} onClose={() => setShowFilters(false)} className="filter_options_modal_wrapper" 
                    disablePortal disableScrollLock keepMounted>
                        <Grow in={showFilters} style={{ transformOrigin: '100% 0 0' }}>
                            <Paper elevation={3} className="filter_content">
                                <div id="filter_content_desc" className="visually_hidden" aria-live="polite">
                                    {t('filter_content_desc')}
                                </div>
                                <InvoicesTableFiltersForm dataRequest={dataRequest}
                                    onClose={() => setShowFilters(false)} onDataRequestChange={setDataRequest} />
                            </Paper>
                        </Grow>
                    </Modal>
                </Container>
            }
            </div>
        </React.Fragment>
    );
};

import { Button } from 'components/Shared/Inputs';
import { IContractDetails } from 'hooks/useContractDetails/interfaces';
import { useFeatureManager } from 'hooks/useFeatureManager';
import useTimezoneUtils from 'hooks/useTimezoneUtils';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { ActivityDataForAppointmentBooking } from '../../interfaces';
import { isValueDefined } from 'utils';
import {         
    MODEL_5,
    MODEL_6,
    MODEL_8,
    MODEL_10,
    MODEL_12,
    ONE_APPOINTMENTS_VALUE,
    TWO_APPOINTMENTS_VALUE,
    THREE_APPOINTMENTS_VALUE,
    FOUR_APPOINTMENTS_VALUE
} from 'utils/hourlyWorkflow';
import { ACTIVE_STATUS_CODE, NO_SHOW_STATUS_CODE, CANCELLED_LT_24H_STATUS_CODE, COMPLETE_STATUS_CODE  } from 'constants/sessionStatus';
import { Alert } from '@mui/material';

interface AfterSessionSavedPromptProps {
    activityId: number,
    activity: ActivityDataForAppointmentBooking | undefined,
    appointmentDate: Date,
    contractDetails: IContractDetails | undefined
}

export const AfterSessionSavedPrompt: FC<AfterSessionSavedPromptProps> = (props) => {
    const { activityId, activity, appointmentDate, contractDetails } = props;

    const { t } = useTranslation('caseManagement', { useSuspense: false });
    const { formatDate, EXPLICIT_MONTH_DAY_YEAR_MOMENT_FORMAT, TIME_PORTION_MOMENT_FORMAT } = useTimezoneUtils();

    const { isHourlyWorkflowFeatureEnabled } = useFeatureManager();

    const contractWithSharedFamilyHours = () => {
        const familyHours = contractDetails?.activeContractPeriod?.familyHourAllocation;

        if (familyHours)
        {
            return !familyHours.employeeHoursLocked && !familyHours.familyHoursLocked;                        
        }

        return false;        
    }

    const getBookedAppointments = () => {
        const sessions = activity?.sessions;
        const bookedSessions = sessions?.filter(
            x => x.sessionStatusId === ACTIVE_STATUS_CODE ||
                x.sessionStatusId === NO_SHOW_STATUS_CODE ||
                x.sessionStatusId === COMPLETE_STATUS_CODE ||
                x.sessionStatusId === CANCELLED_LT_24H_STATUS_CODE);

        if (bookedSessions) {
            return bookedSessions.length;
        }

        return 0;
    }

    const clientExceededNumberOfAppointments =  (numberOfAppointments: number) =>
    {        
            return getBookedAppointments() >= numberOfAppointments;                    
    }

    const numberOfAppointmentsBookedForGivenModel = (model: number) => {
        switch (model) {
            case MODEL_5:
                {
                    return clientExceededNumberOfAppointments(THREE_APPOINTMENTS_VALUE);
                }
            case MODEL_6:
                {
                    return clientExceededNumberOfAppointments(ONE_APPOINTMENTS_VALUE);
                }
            case MODEL_8:
                {
                    return clientExceededNumberOfAppointments(TWO_APPOINTMENTS_VALUE);
                }
            case MODEL_10:
                {
                    return clientExceededNumberOfAppointments(THREE_APPOINTMENTS_VALUE);
                }
            case MODEL_12:
                {
                    return clientExceededNumberOfAppointments(FOUR_APPOINTMENTS_VALUE);

                }
            default: {
                return false;
            }
        }
    }
    
    const renderCaseHours = () => (
        isHourlyWorkflowFeatureEnabled()
        && activity?.isHourlyContract
        && activity?.pcShowFamilyHours
        && contractWithSharedFamilyHours()
            ? <Alert severity="info">                                    
                <p>{t('total_hours_available')} {activity?.caseDetailHours.totalHoursAvailable ?? 'N/A'}</p>
            </Alert>
            : <Alert severity="info">   
                <p>{t('max_hours')} {activity?.caseDetailHours.numberOfMaxHours ?? 'N/A'}</p>
                <p>{t('additional_hours')} {activity?.caseDetailHours.approvedHours ?? 'N/A'}</p>
                <p>{t('remaining_hours')} {activity?.caseDetailHours.clientRemainingHours ?? 'N/A'}</p>
                <p>{t('used_hours')} {activity?.caseDetailHours.numberOfHoursUsed ?? 'N/A'}</p>
            </Alert>
    )
    
    const activityModelId = contractDetails?.modeId as number;


    const isProviderAtOrOverSessionAverageAfterBooking = () => {
        if (isValueDefined(contractDetails?.averageHours) && activity?.caseDetailHours?.numberOfHoursUsed) {
            return activity?.caseDetailHours?.numberOfHoursUsed >= (contractDetails?.averageHours as number)
        }
        return false
    }

    const isProvider2HoursOrMoreOverSessionAverage = () => {
        if (isValueDefined(contractDetails?.averageHours) && activity?.caseDetailHours?.numberOfHoursUsed) {
            return activity?.caseDetailHours?.numberOfHoursUsed >= ((contractDetails?.averageHours as number) + 2)
        }
        return false 
    }

    const renderAverageWarningWithMessage = (message: string) => (
        <Alert severity="warning">
            <p>{message}</p>
        </Alert>
    )

    const renderAverageHourWarningsIfApplicable = () => {
        if (activity?.isHourlyContract && numberOfAppointmentsBookedForGivenModel(activityModelId) )
        {            
            return null;            
        }
        else if (!activity?.isHourlyContract && !numberOfAppointmentsBookedForGivenModel(activityModelId)) 
        { 
            return null;   
        }        
        if (isProviderAtOrOverSessionAverageAfterBooking()) {
            if (isProvider2HoursOrMoreOverSessionAverage()) {
                return renderAverageWarningWithMessage(t('session_average_two_hours_exceeded'))
            }
            return renderAverageWarningWithMessage(t('at_session_average'))
        }
        return null;
    }

    return <div className="update_appt_status_step">
        <p>
            {t('congratulations_you_scheduled_an_appointment_with_your_client_on_the_following_date')}:
        </p>
        <p>
            {formatDate(appointmentDate || new Date(), EXPLICIT_MONTH_DAY_YEAR_MOMENT_FORMAT)}
            {` ${t('at')} `}
            {formatDate(appointmentDate || new Date(), TIME_PORTION_MOMENT_FORMAT)}
        </p>
        {renderCaseHours()}
        {renderAverageHourWarningsIfApplicable()}
        <Button variant="opaque" component={Link} to={{pathname: '/todos'}} className="appt_status_dropdown">
            {t('back_to_todos')}
        </Button>
        <Button variant="outline" onClick={() => window.location.pathname  = `/clients/${activityId}`} className="appt_status_dropdown">
            {t('view_clients_profile')}
        </Button>
    </div>
}
import React, { FC } from 'react';
import '../Login.scss';
import { LoginScreenLayout } from './LoginScreenLayout';
import { LoginForm } from '../Forms/LoginForm';
import { PageMetadata } from 'components/Shared/PageMetadata';
import { useTranslation } from 'react-i18next';

interface LoginScreenProps { }

export const LoginScreen: FC<LoginScreenProps> = (_) => {
    const { t, ready } = useTranslation(['login'], { useSuspense: false });
    
    return (
        <React.Fragment>
            { ready && 
                <PageMetadata 
                    pageDescription={t('login_page_description')} 
                    pageTitle={t('login_page_title')}
                /> 
            }
            <LoginScreenLayout>
                <LoginForm />
            </LoginScreenLayout>
        </React.Fragment>
    )
}
import { gql, TypedDocumentNode } from '@apollo/client';
import { IReason } from '../interfaces';

export const DECLINE_REASONS_QUERY: TypedDocumentNode<DeclineReasonsQueryData, DeclineReasonsQueryVars> = gql`
    query DECLINE_REASONS_QUERY {
        appointmentDeclineReasons {
            id
            description 
        }
    }
`
interface DeclineReasonsQueryVars { }

interface DeclineReasonsQueryData {
    appointmentDeclineReasons: IReason[]
}
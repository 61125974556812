
import { useMemo } from 'react';
import { DataRequest, sortData, derivePaginationDetails, PaginationDetails } from 'components/Shared/MaterialTable';
import { OverHoursApprovalDTO } from '../interfaces';

export const useRequestDataFiltering = (data: OverHoursApprovalDTO[] | undefined, dataRequest: DataRequest)
    : [OverHoursApprovalDTO[] | undefined, PaginationDetails] =>
{
    const searchString = useMemo(() => dataRequest.search?.toLowerCase().trim() || '', [dataRequest.search]);

    const filteredData = useMemo(() => (
        data?.filter(approval =>
            (`${approval.clientFirstName} ${approval.clientLastName}`.toLowerCase().includes(searchString) ?? false)
            || (approval.clientId?.toString().includes(searchString) ?? false)
            || (approval.status?.toLowerCase().includes(searchString) ?? false)
            || (approval.reason?.toLowerCase().includes(searchString) ?? false)
            || (approval.request?.reason?.toLowerCase().includes(searchString) ?? false)
            || (approval.request?.requestedHoursStatusString?.toLowerCase().includes(searchString) ?? false)
        )
    ), [data, searchString]);

    const sortedData = useMemo(() => {
        return sortData(filteredData, dataRequest.sorts)
    }, [filteredData, dataRequest.sorts]);

    const pagedData = useMemo(() => 
        sortedData?.slice(dataRequest.skip, dataRequest.skip + dataRequest.take)
    , [sortedData, dataRequest.skip, dataRequest.take]);

    const paginationDetails = derivePaginationDetails(sortedData?.length, dataRequest);
    
    return [pagedData, paginationDetails];
}
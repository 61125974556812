import React, { FC } from 'react';
import useTimezoneUtils from 'hooks/useTimezoneUtils';
import { useTranslation } from 'react-i18next';
import { determineRemainingBasedOnModelId } from 'utils/hourlyWorkflow';
import { IBookCalendarActivityData } from '../interfaces';
import { getPreferredPhoneNumber } from 'components/CaseManagement/utils/activityDetailsUtils';
import { getAppRegionCode } from 'utils/regionUtils';
import { useAuth } from 'components/Auth';
import { checkIfStaffProvider } from 'utils';

export interface IActivityValue {
    client: string
    activityId: number
}

export interface ActivityOverviewProps {
    activity?: IBookCalendarActivityData
    onCancel?: () => void
    onSchedule?: () => void
    startDate?: Date
    renderApptDetails?: boolean
    hideDuration?: boolean
    phone?: string
    email?: string
    providerWillContactClient?: boolean
}


export const ActivityOverview: FC<ActivityOverviewProps> = (props) => {
    const { activity, phone: preferredPhone, email: preferredEmail, providerWillContactClient } = props;
    const { t } = useTranslation('calendaring', { useSuspense: false });
    const { formatDate } = useTimezoneUtils();
    const { effectiveProviderData } = useAuth();

    const clientAvailableHours = determineRemainingBasedOnModelId(
        activity?.clientAvailableHours, 
        activity?.sessionInfo?.totalHoursRemaining,
        activity?.sessionInfo?.modelId ?? undefined
    )

    const renderAppointmentDetails = () => (
        <>
            <hr aria-hidden="true"></hr>
            <div className="appt-details">
                <h3 className="global__header3">{t('appointment_details')}</h3>
                <p className="global__paragraph">
                    {t('this_appointment_is_scheduled_on')} {' '}
                    <strong>
                        {formatDate(props.startDate || new Date(), 'LL')} 
                        { ` ${t('at')} `}
                        {formatDate(props.startDate || new Date(), 'LT')} 
                    </strong>{' '}
                    {props.hideDuration ? '' : `(${t('1_hour')})`}
                </p>
            </div>
        </>
    )
    const {
        countryName: clientCountry = '',
        state_ProvinceCode = '',
        city: clientCity = ''
    } = activity?.client?.address || {};

    return (
        <section className="step2_section">
            <h3 className="global__header3 calendar_appt_step2_booking_title">
                {t('appointment')}
            </h3>
            <div className="client_details_div">
                <h2 className="global__header1">
                    {activity?.client?.firstName} {activity?.client?.lastName}
                </h2>
                <p className="global__paragraph preenting-issue-text">
                    {activity?.service}, {activity?.presentingIssue}
                </p>
                <section className="info-block-section">
                    <div className="item phone_item">
                        <p className="value">
                            {providerWillContactClient ? t('provider') : t('client')}                         
                        </p>
                        <p className="key">{t('who_will_initiate_contact')}</p>
                    </div>
                    <div className="item location_item">
                        <p className="value">
                        {clientCity.toLocaleLowerCase()}, {state_ProvinceCode}, {clientCountry.toLocaleLowerCase()}                       
                        </p>
                        <p className="key">{t('client_location')}</p>
                    </div>
                    <div className="item lang_item">
                        <p className="value">
                            {activity?.client.languagePreference}
                        </p>
                        <p className="key">{t('language_spoken')}</p>
                    </div>
                    <div className="item phone_item">
                        <p className="value">
                            {preferredPhone ? preferredPhone : getPreferredPhoneNumber(activity, getAppRegionCode(), checkIfStaffProvider(effectiveProviderData)) }                            
                        </p>
                        <p className="key">{t('phone_(preferred)')}</p>
                    </div>
                    {
                        preferredEmail &&
                        <div className="item phone_item">
                            <p className="value">
                                {preferredEmail}
                            </p>
                            <p className="key">{t('email_(preferred)')}</p>
                        </div>
                    }
                    <div className="item client_status_item">
                        <p className="value">{activity?.eligibility ?? t('n/a')}</p>
                        <p className="key">{t('client_status')}</p>
                    </div>

                    <div className="item client_id_item">
                        <p className="value">{activity?.activityId}</p>
                        <p className="key">{t('activity_id')}</p>
                    </div>
                    <div className="item" />
                    <div className="item available_hours_item">
                        <p className="value">
                            {clientAvailableHours}
                        </p>
                        <p className="key">{t('available_hours')}</p>
                    </div>
                </section>
                <section className="addition-participants-section">
                    <p className="key">{t('additional_participants')}</p>
                    <p className="value">
                        {activity?.additionalParticipants
                            ?.map(
                                (participant) =>
                                    participant?.firstName +
                                    ' ' +
                                    participant?.lastName,
                            )
                            .join(', ')}
                    </p>
                </section>
            </div>
            {
                props.renderApptDetails &&
                renderAppointmentDetails()
            }
        </section>
    )
}

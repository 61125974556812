import React, { FC, useState } from 'react';
import { MobileToolbar } from '../components/MobileToolbar';
import { MobileDrawer } from '../components/MobileDrawer';

interface MobileNavigationProps {}

export const MobileNavigation: FC<MobileNavigationProps> = (_) => {
    const [drawerOpen, setDrawerOpen] = useState(false);

    const HandleDrawerOpen = () => {
        setDrawerOpen(true);
    }

    const HandleDrawerClose = () => { 
        setDrawerOpen(false);
    }

    return (
        <>
            <MobileToolbar onMenuClick={HandleDrawerOpen}/>
            <MobileDrawer onOpen={HandleDrawerOpen} onClose={HandleDrawerClose} isOpen={drawerOpen}/>
        </>
    )
}
import React, { FC, useState, useRef, useMemo } from 'react';
import { Collapse, TableCell, TableRow } from '@mui/material';
import { ColumnDefinition } from './interfaces';
import ChevronDown from '@mui/icons-material/KeyboardArrowDown';
import { Button } from '../Inputs';

interface MaterialTableRowProps {
    data: any,
    index: number,
    columns: ColumnDefinition[],
    collapsible?: boolean,
    collapsibleRowTemplate?: (data: any, index: number) => React.ReactNode
}

export const MaterialTableRow: FC<MaterialTableRowProps> = (props) => {
    const { columns, data, index, collapsible, collapsibleRowTemplate } = props;

    const [collapsed, setCollapsed] = useState(true);

    const rowRef = useRef<HTMLTableRowElement | null>(null);
    const collapsibleRowRef = useRef<HTMLTableRowElement | null>(null);

    const handleMouseOver = () => {
        rowRef?.current?.classList.add('hover');
        collapsibleRowRef?.current?.classList.add('hover');
    }

    const handleMouseOut = () => {
        rowRef?.current?.classList.remove('hover');
        collapsibleRowRef?.current?.classList.remove('hover');
    }

    const totalColSpan = useMemo(() => 
        columns.reduce(
            (sum, column) => sum += (column.colSpan ?? 1),
            collapsible ? 1 : 0
        )
    , [columns, collapsible]);

    const toggleCollapsed = () => {
        setCollapsed(!collapsed)
    }

    return <>
        <TableRow className={collapsible ? 'table_body_row_with_collapse' : 'table_body_row'} ref={rowRef}
            onMouseOver={handleMouseOver} onMouseOut={handleMouseOut} onClick={toggleCollapsed}>
            {
                collapsible &&
                <TableCell className="table_collapse_indicator_cell">
                    <Button aria-label="expand row" variant="text" round size="medium" onClick={toggleCollapsed}>
                        <ChevronDown className="collapse_indicator" style={{transform: collapsed ? 'rotate(360deg)' : 'rotate(180deg)'}}/>
                    </Button>
                </TableCell>
            }
            {
                columns.filter(column => column.colSpan !== 0).map((column, columnIndex) => (
                    <TableCell className="table_body_cell" key={columnIndex} colSpan={column.colSpan ?? 1}
                        style={{ paddingLeft: columnIndex === 0 && collapsible ? 0 : undefined }}
                    >
                    {
                        column.template instanceof Function
                            ? column.template(data, index)
                            : column.field ? data[column.field] : undefined
                    }
                    </TableCell>
                ))
            }
        </TableRow>
        {
            collapsible &&
            <TableRow className="table_body_collapse_row" ref={collapsibleRowRef}
                onMouseOver={handleMouseOver} onMouseOut={handleMouseOut} onClick={toggleCollapsed}>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0, paddingLeft: 60 }} colSpan={totalColSpan}>
                    <Collapse in={!collapsed} timeout="auto" unmountOnExit>
                        {collapsibleRowTemplate?.(data, index)}
                    </Collapse>
                </TableCell>
            </TableRow>
        }
    </>
}
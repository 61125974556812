import { gql, TypedDocumentNode } from '@apollo/client';
import { PreviousExperience, PreviousExperienceInput } from '../../../interfaces';

export const UPDATE_PREVIOUS_EXPERIENCE_MUTATION
    : TypedDocumentNode<UpdatePreviousExperienceMutationData, UpdatePreviousExperienceMutationVars> = gql`
    mutation UPDATE_PREVIOUS_EXPERIENCE_MUTATION($providerId: Long!, $previousExperience: [PreviousExperienceInput]) {
        updateCredentials(providerId: $providerId, previousExperience: $previousExperience) {
            providerId
            credential {
                previousExperience {
                    providerPreviousExperienceId
                    employerName
                    endDate
                    jobTitleRole
                    startDate
                }
            }
        }
    }
`

export interface UpdatePreviousExperienceMutationData {
    updateCredentials: {
        credential: {
            previousExperience: PreviousExperience[]
        }
    }
}

export interface UpdatePreviousExperienceMutationVars {  
    providerId: number,
    previousExperience: PreviousExperienceInput[]
}
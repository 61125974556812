import React, { FC } from 'react';
import { Container } from '@mui/material';
import { IActivityInvoiceData } from '../../interfaces';
import { InvoiceDetailsHeader } from '../../components/InvoiceDetailsHeader';
import { useFeatureManager } from 'hooks/useFeatureManager';
import { InvoiceEditor } from '../../components/InvoiceEditor';
import { InvoiceOverview } from '../../components/InvoiceOverview';

import './InvoiceDetails.scss';
import { InvoiceDetailsSummary } from 'components/Invoicing/components/InvoiceDetailsSummary';
import { useNavigate } from 'react-router';
import { Button } from 'components/Shared/Inputs';
import { useTranslation } from 'react-i18next';

interface InvoiceDetailsViewProps {
    activity: IActivityInvoiceData | undefined,
    providerId: number,
    invoiceId: number | 'create' | undefined,
    onInvoiceSubmitted?: () => void
}

export const InvoiceDetailsView: FC<InvoiceDetailsViewProps> = (props) => {
    const { activity, invoiceId, providerId, onInvoiceSubmitted } = props;

    const { t } = useTranslation(['invoicing','common'], { useSuspense: false });
    const navigate = useNavigate();

    const { isWSPFeatureEnabled } = useFeatureManager();
    const useWspFunctionality = isWSPFeatureEnabled() && Boolean(activity?.isWSPActivity);

    const canGenerateInvoice = Boolean(
        activity?.isProviderHubActivity && activity?.invoiceAllowed
        && (Boolean(activity?.invoicableSessions?.length) || useWspFunctionality)
    );

    if (invoiceId === 'create' && !canGenerateInvoice) {
        navigate(`/clients/${activity?.activityId}/invoices`, { replace: true })
    }

    const renderContent = (activity: IActivityInvoiceData) => {
        switch (invoiceId) {
            case undefined: {
                return <>
                    <InvoiceDetailsHeader
                        activityId={activity.activityId}
                        invoices={activity.invoices}
                        printAvailable={false}
                        generateInvoiceAvailable={canGenerateInvoice}
                    />
                    {
                        !activity?.isProviderHubActivity || !activity?.invoiceAllowed
                            ? <div className="cannot-generate-invoice">
                                <p className="global__paragraph global__paragraph--light not-accepted-yet">
                                    {t('cannot_submit_invoice')}
                                </p>
                                <Button variant="outline" onClick={() => navigate(-1)}>
                                    {t('common:back')}
                                </Button>
                            </div>
                            : <InvoiceDetailsSummary uninvoicedAppointmentsCount={activity.invoicableSessions?.length ?? 0}/>
                    }
                </>
            }
            case 'create': {
                return <InvoiceEditor
                    activity={activity}
                    providerId={providerId}
                    useWspFunctionality={useWspFunctionality}
                    onInvoiceSubmitted={onInvoiceSubmitted}
                />
            }
            default: {
                return <InvoiceOverview
                    activity={activity}
                    invoiceId={invoiceId}
                    providerId={providerId}
                    useWspFunctionality={useWspFunctionality}
                    canGenerateInvoice={canGenerateInvoice}
                />
            }
        }
    }

    return (
        <Container maxWidth="lg" className="invoice_details_content_wrapper">
            { 
                activity
                    ? renderContent(activity)
                    : <></>
            }
        </Container>
    )
}
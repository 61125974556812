import React, { FC } from 'react';
import { useQuery } from '@apollo/client';
import { useNavigate, useParams } from 'react-router-dom';
import { InvoicePreviewHTML } from '../../components/InvoicePreview';
import { INVOICE_DETAILS_QUERY } from '../../queries';
import { useAuth } from 'components/Auth';
import { useTranslation } from 'react-i18next';
import { PageMetadata } from 'components/Shared/PageMetadata';
import { CaseManagementActionContainer, Spinner } from 'components/Shared';
import { Button } from 'components/Shared/Inputs';
import PrintIcon from '@mui/icons-material/PrintOutlined';
import SaveIcon from '@mui/icons-material/SaveAltRounded';
import print from 'print-js';
import {  InvoiceStatus } from '../../interfaces';

import './InvoiceOverviewView.scss';

interface InvoiceOverviewViewProps { }

export const InvoiceOverviewView: FC<InvoiceOverviewViewProps> = (_) =>  {
    const { t, ready } = useTranslation('invoicing', { useSuspense: false });
    const navigate = useNavigate();
    
    const invoiceIdString = useParams()['invoiceId'];
    const invoiceId = Number.parseInt(invoiceIdString as string, 10);

    const { effectiveProviderData } = useAuth();
    const providerId = effectiveProviderData?.providerId ?? -1;

    const invoiceQuery = useQuery(INVOICE_DETAILS_QUERY, {
        variables: {
            invoiceId: invoiceId,
            providerId: providerId
        }
    })

    const invoice = invoiceQuery.data?.invoice;
    const activityId = invoice?.activity?.activityId;
    const activityService = invoice?.activity?.service;

    const getStatusClass = (): string => {
        switch (invoice?.statusId) {
            case InvoiceStatus.Approved: return 'approved';
            case InvoiceStatus.Draft: return 'draft';
            case InvoiceStatus.Declined: return 'declined';
            case InvoiceStatus.Submitted:
            case InvoiceStatus.PendingApproval: return 'pending_approval';
            case InvoiceStatus.Void:
            default: return '';
        }
    }

    const handlePrintClick = async () => {
        if (invoice) {
            const { generateInvoicePDF2 } = await import('../../utils/generateInvoicePDF');
            const pdf = await generateInvoicePDF2(invoice.invoiceId);
            const printable = pdf.output('bloburl');
            print({ type: 'pdf', printable });
        }
    }

    const handleSavePDFClick = async () => {
        if (invoice) {
            const { generateInvoicePDF2 } = await import('../../utils/generateInvoicePDF');
            const pdf = await generateInvoicePDF2(invoice.invoiceId);
            pdf.save('invoice.pdf');
        }
    }

    return (
        <CaseManagementActionContainer className='invoice_details_content_wrapper' activityId={false} onBackClick={() => navigate(-1)} headerMaxWidth='lg' contentMaxWidth='lg'>
            {
                ready && 
                <PageMetadata 
                    pageDescription={t('invoice_details_page_description')} 
                    pageTitle={t('invoice_details_page_title')}
                /> 
            }
            <div className="invoice_overview_header_content">
                <h2>
                    {`${t('invoice')} ${invoiceId}`}
                </h2>
                { invoice &&
                        <div> 
                            <h3 className="invoice_overview_header_section_title">{t('status')}</h3>                  
                            <p className={`invoice_overview_header_status_label ${getStatusClass()}`}>{invoice.statusString}</p>
                        </div>
                }
                <div className="invoice_header_button_wrapper">
                    <Button variant="text" startIcon={<PrintIcon/>} disabled={!invoice} onClick={handlePrintClick}>
                        {t('print_invoice')}
                    </Button>
                    <Button variant="text" startIcon={<SaveIcon/>} disabled={!invoice} onClick={handleSavePDFClick}>
                        {t('save_as_pdf')}
                    </Button>
                </div>
            </div>
            {
                invoiceQuery.loading ?? !invoice
                    ? <Spinner fillParent />
                    : <InvoicePreviewHTML invoice={invoice} activityId={activityId} activityService={activityService} />
            }
        </CaseManagementActionContainer>
    )
}


export const SPACE = ' '
export const DIGIT_MASK_CHAR = '_'

export const getMaskForDateFormat = (momentDateFormat: string) => {
    return momentDateFormat.replace(/\w/g, DIGIT_MASK_CHAR)
} 


export const getMaskForTimeFormat = (momentTimeFormat: string) => {
    const timeFormatMaskWithoutMeridian = `${DIGIT_MASK_CHAR}${DIGIT_MASK_CHAR}:${DIGIT_MASK_CHAR}${DIGIT_MASK_CHAR}`;
    const hasMeridian = momentTimeFormat.includes('A')

    return timeFormatMaskWithoutMeridian  + (hasMeridian ? (SPACE + DIGIT_MASK_CHAR + 'M') : '')
}


export const getDateTimeInputMask = (momentDateFormat: string, momentTimeFormat: string) => {
    const dateFormatMask = getMaskForDateFormat(momentDateFormat)
    const timeFormatMask = getMaskForTimeFormat(momentTimeFormat)

    return dateFormatMask + SPACE + timeFormatMask
}

export const getDateTimeInputFormat = (momentDateFormat: string, momentTimeFormat: string) => {
    return momentDateFormat + SPACE + momentTimeFormat
}


export const getDateInputMask = (momentDateFormat: string) => {
    return getMaskForDateFormat(momentDateFormat)
}

export const getTimeInputMask = (momentDateFormat: string) => {
    return getMaskForTimeFormat(momentDateFormat)
}
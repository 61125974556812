// import { ProviderDetails } from "components/ProviderProfile/components/Tabs";
export interface TokenPayload {
    role: UserRole[] | UserRole,
    provider_id: number,
    vendor_id?: number,
    email: string,
    unique_name: string,
    calendar: boolean,
    invoice: boolean,
    nbf: number,
    exp: number,
    iat: number
}

export interface LoginInput {
    username: string,
    password: string
}

export interface SwitchProfileInput {
    providerId: number
}

export interface LoginResult {
    token: string,
    providerID: number,
    firstName: string,
    lastName: string,
    provider: ProviderData
}

export enum UserRole {
    Provider = 'provider',
    GroupAdmin = 'group_admin',
    Admin = 'admin'
}

export interface AuthData {
    providerData: ProviderData | undefined,
    effectiveProviderData: ProviderData | undefined,
    token: string | undefined,
    effectiveToken: string | undefined
}

export interface ProviderLite {
    providerId: number,
    firstName: string
    lastName: string,
    vendor: {
        vendorId: number,
        name: string
    } | null
}

interface Provider extends ProviderLite {
    id: string,
    preferredLanguageCode: string,
    isProvider: boolean,
    isAdmin: boolean,
    timeZone: string | null,
    hasManagedCalendar: boolean,
    employmentTypeId?: number
    hasInvoicing: boolean,
}

interface ProviderDetails {
    associateWithVendor: boolean
}

export interface ProviderData extends Provider, ProviderDetails {
    provider: Provider | null,
    providerDetails: ProviderDetails | null
}

export interface User {
    id: string,
    providerId: string,
    profileId: number,
    email: string,
    firstName: string,
    lastName: string,
    providerConnectUser: boolean,
    token: string,
    timeZone: string | null,
    provider: ProviderData | null
    providerDetails: ProviderDetails | null
}

export interface LoginResponse {
    error?: any;
    userAuthData: User | null,
    effectiveUserAuthData: User | null
}


interface RequestHookProps<TData> {
    prepareHeaders?: () => HeadersInit | undefined,
    onCompleted?: (data: TData) => void,
    onError?: (error: any) => void,
    fetch?: (input: RequestInfo, init?: RequestInit) => Promise<Response>
}

interface RequestData<TData> {
    data: TData | null,
    loading: boolean,
    error: any | null,
    promise: Promise<TData> | null
}

type RequestFn<TData, TVariables> = (variables: TVariables) => Promise<TData>;

export type RequestHook<TData, TVars> = (props?: RequestHookProps<TData>) => [
    RequestFn<TData, TVars>,
    RequestData<TData>
];

export enum AuthAction {
    Login = 'login',
    Refresh = 'refresh',
    Logout = 'logout',
    SwitchProfile = 'switch-profile',
    SyncRequest = 'sync-request',
    SyncResponse = 'sync-response',
}
import React, { FC } from 'react';
import { DesktopSecondaryNavbar } from '../components/DesktopSecondaryNavbar';
import { DesktopPrimaryNavbar } from '../components/DesktopPrimaryNavbar';

interface DesktopNavigationProps {}

export const DesktopNavigation: FC<DesktopNavigationProps> = (_) => (
    <div className="desktop_navigation">
        <DesktopSecondaryNavbar />
        <DesktopPrimaryNavbar />
    </div>
)
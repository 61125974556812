import React, { useState, FC, useEffect, useMemo } from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom';
import { useMutation, useQuery } from '@apollo/client';
import { COMPLETE_REVIEW_MUTATION, GET_ACTIVITY_PRE_ASSESSMENTS_QUERY } from '../../queries';
import { Spinner } from 'components/Shared';
import { useTranslation } from 'react-i18next';
import { AssessmentStages } from '../../constants';
import { PageMetadata } from 'components/Shared/PageMetadata';
import { FormPresenter } from '../../components/FormPresenter';
import { Container, Grid } from '@mui/material';
import { Button } from 'components/Shared/Inputs';

import './Assessments.scss';

interface PreAssessmentsViewProps {}

export const PreAssessmentsView: FC<PreAssessmentsViewProps> = (_) => {
    const [currentStep, setCurrentStep] = useState<number>(0);
    const { activityId: activityIdString, individualId: individualIdString } = useParams<{ activityId: string; individualId: string }>();
    const { t, ready } = useTranslation('assessments', { useSuspense: false });
    const navigate = useNavigate();

    const activityId = parseInt(activityIdString || '');
    const individualId = parseInt(individualIdString || '0');

    const activityQuery = useQuery(GET_ACTIVITY_PRE_ASSESSMENTS_QUERY, {
        variables: { activityId: activityId },
        fetchPolicy: 'no-cache'
    });

    const [completeReview, completeReviewMutation] = useMutation(COMPLETE_REVIEW_MUTATION, {
        onCompleted: () => {
            navigate(`/clients/${activityId}/documents`)
        }
    });

    const activity = activityQuery.data?.activity;
    const client = activity?.client;

    const preAssessments = activity?.preAssessments;

    const preAssessment = useMemo(() =>
        preAssessments?.filter(pa => pa.additionalIndividualId === individualId)[0]
    , [preAssessments, individualId]);

    const forms = preAssessment?.forms;
    const currentForm = forms?.[currentStep];

    const canBeReviewed = preAssessment?.status === AssessmentStages.PRE_ASSESSMENT_FINAL_STAGE;

    const handleNextStepClick = () => {
        if (!isLastStep) {
            setCurrentStep(currentStep + 1);
        } else {
            const variables = { activityId: activityId, individualId: individualId };
            completeReview({ variables: variables });
        }
    }

    const handlePreviousStepClick = () => {
        if (currentStep - 1 >= 0) {
            setCurrentStep(currentStep - 1);            
        }
    }

    const handleCompleteReviewClick = () => {
        const variables = { activityId: activityId, individualId: individualId.toString() };
        completeReview({ variables: variables });
    }

    const isLastStep = (currentStep === (forms?.length ?? 0) - 1);
    const isFirstStep = (currentStep === 0);

    const clientName = `${client?.firstName} ${client?.lastName}`;
        
    useEffect(()=>{
        window.scrollTo(0, 0);
    }, [currentStep]);

    return (
        <>
            { ready && 
                <PageMetadata 
                    pageDescription={t('preassessments_page_description')} 
                    pageTitle={t('preassessments_page_title')}
                /> 
            }
            {
                completeReviewMutation.loading &&
                <Spinner fillParent backdrop/>
            }
            {
                activityQuery.loading
                    ? <Spinner fillParent/>
                    : <Container className="assessment_container">
                        <Grid item className="assessment_left_content">
                            <p className="type_of_appointment_assessment global__header3">{t('PreAssessment-title')}</p>
                            <div className="client_name_div">
                                <span>{t('client')}: </span>
                                <Link className="color--link client_name_link"
                                    to={{ pathname: `/clients/${activityId}` }}
                                >
                                    {clientName}
                                </Link>
                            </div>
                            <div className="assessment_details">
                                <div className="global__header3">{t('details')}</div>
                                <section className="assessment_details_content">
                                    <div className="assessment_details_item_div">
                                        <div className="assessment_details_item_title">{t('individual_name')}:</div>
                                        <div className="assessment_details_item_value">{preAssessment?.clientName}</div>
                                    </div>
                                    <div className="assessment_details_item_div">
                                        <div className="assessment_details_item_title">{t('status')}:</div>
                                        <div className="assessment_details_item_value">{t(`${preAssessment?.status}`)}</div>
                                    </div>
                                </section>
                            </div>
                            <div className="assessment_link">
                                <Link className="color--link client_name_link"
                                    to={{  pathname: `/clients/${activityId}/documents` }}
                                >
                                    {t('back_to_clinical_records')}
                                </Link>
                            </div>
                        </Grid>
                        <Grid className="assessment_survey_content" item xs={6}>
                        {
                            currentForm &&
                            <FormPresenter
                                activityId={activityId}
                                sessionId={null}
                                form={currentForm}
                                readOnly={true}
                                onSubmit={() => {}}
                            />
                        }
                            <div className="assessment_navigation">
                                <div>
                                    <Button variant="opaque" disabled={isFirstStep} onClick={handlePreviousStepClick}>
                                        {t('previous')}
                                    </Button>
                                </div>
                                <div>
                                    {
                                        isLastStep
                                            ? <Button variant="opaque" disabled={!canBeReviewed} onClick={handleCompleteReviewClick}>
                                                {t('done')}
                                            </Button>
                                            : <Button variant="opaque" disabled={isLastStep} onClick={handleNextStepClick}>
                                                {t('next')}
                                            </Button>
                                    }
                                </div>
                            </div>
                        </Grid>
                        <Grid item className="assessment_right_content"></Grid>
                    </Container>
                }
            </>
    );
};

import React, { FC, useState, useEffect } from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom';
import { NetworkStatus, useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';

import { GET_ACTIVITY_DATA_QUERY, TERMINATE_TODO_MUTATION, SAVE_APPT_DATE_MUTATION,
    SAVE_CONTACT_ATTEMPTS_MUTATION, GET_CONTACT_ATTEMPTS_QUERY } from '../queries';
import { useAuth } from 'components/Auth';
import { CaseManagementActionContainer, Spinner } from 'components/Shared';
import { PROVIDER_HUB } from 'constants/targetedSystem';
import { PageMetadata } from 'components/Shared/PageMetadata';
import { useHistoryState } from 'hooks/useHistoryState';
import { ConnectWithClientPrompt } from '../components/ConnectWithClientPrompt';
import { ContactAttemptsForm } from '../components/ContactAttemptsForm';
import { SessionDateForm } from '../components/SessionDateForm';
import { AfterSessionSavedPrompt } from '../components/AfterSessionSavedPrompt';
import { useContractDetails } from 'hooks/useContractDetails';
import { FullScreenMessage } from 'components/Shared/FullScreenMessage';
import { Button } from 'components/Shared/Inputs';
import { useRegionConfig } from 'providers/RegionConfig';
import { AfterContactAttemptsSavedPrompt } from '../components/AfterContactAttemptsSavedPrompt';
import { useMutationWithExpiration } from 'providers/Apollo/useMutationWithExpiration';

import './ScheduleSession.scss';

enum ShownForm {
    None,
    ContactAttempts,
    SessionDate
}

export interface ScheduleFirstSessionViewHistoryState {
    activity?: any,
    workflowId?: string
}

interface ScheduleFirstSessionViewProps { }

export const ScheduleFirstSessionView: FC<ScheduleFirstSessionViewProps> = (_) => {
    const { t, ready: translationsReady } = useTranslation('caseManagement', { useSuspense: false });
    const { effectiveProviderData } = useAuth();
    const { providerContactUrl } = useRegionConfig();

    const navigate = useNavigate();

    const { activityId: activityIdString } = useParams<{activityId: string}>();
    const activityId = parseInt(activityIdString || '');

    const [historyState] = useHistoryState<ScheduleFirstSessionViewHistoryState>();
    const [enteredDate, setEnteredDate] = useState<Date | null>(null);

    const [shownForm, setShownForm] = useState(ShownForm.None);

    const activityDataQuery = useQuery(GET_ACTIVITY_DATA_QUERY, {
        variables: { activityId: activityId },
        fetchPolicy: 'no-cache',
        notifyOnNetworkStatusChange: true
    });

    const contractDetailQuery = useContractDetails(activityId);

    const [getContactAttempts, contactAttemptsQuery] = useLazyQuery(GET_CONTACT_ATTEMPTS_QUERY, {
        variables: {
            activityId: activityId,
            attemptType: 'REACH_OUT',
            providerId: effectiveProviderData!.providerId
        },
        fetchPolicy: 'no-cache'
    });

    const [saveSessionDate, saveSessionDateMutation] = useMutationWithExpiration(SAVE_APPT_DATE_MUTATION, {
        errorPolicy: 'all',
        onError: () => {}
    });
    const [saveContactAttempts, saveContactAttemptsMutation] = useMutation(SAVE_CONTACT_ATTEMPTS_MUTATION);

    const [closeToDo, closeToDoMutation] = useMutationWithExpiration(TERMINATE_TODO_MUTATION, {
        context: { skipGlobalErrorHandling: true }
    });

    const todoWorkflowId = historyState?.workflowId;

    const activity = activityDataQuery.data?.activity;
    const contractDetails = contractDetailQuery?.data?.contractDetails;

    const isProviderHubActivity = activity?.targetedSystem?.systemCode === PROVIDER_HUB;
    const isFirstSession = activity?.sessions?.length === 0;
    const cannotBookReason = activity?.sessionBooking.prohibitionReasonCode;


    useEffect(() => {
        if (isFirstSession !== undefined) {
            // if Todo item through which the user was routed here is invalid
            if (todoWorkflowId && !isFirstSession) {
                closeToDo({ variables: { workflowId: todoWorkflowId }})
            }
        }
    }, [todoWorkflowId, isFirstSession]);

    const handleShowContactAttemptsFormClick = () => {
        getContactAttempts();
        setShownForm(ShownForm.ContactAttempts);
    }


    const handleSaveSessionDate = (date: Date, mismatchReasonCode?: number) => {
        saveSessionDate({variables: {
            activityId: activityId,
            providerId: effectiveProviderData!.providerId,
            date: date.toJSON(),
            isFirstAppointment: true,
            mismatchReasonCode: mismatchReasonCode
        }}).then((result) => {
            if (!result.errors) {
                activityDataQuery.refetch({ activityId: activityId });
                setEnteredDate(date);
            }
        })
    }

    const handleSaveContactAttempts = (contactAttempts: Date[]) => {
        saveContactAttempts({variables: {
            activityId: activityId,
            providerId: effectiveProviderData!.providerId,
            dates: contactAttempts.map(date => date.toJSON()),
            attemptType: 'reach_out',
            close: false
        }})
    }

    const handleCloseCase = (contactAttempts: Date[]) => {
        saveContactAttempts({variables: {
            activityId: activityId,
            providerId: effectiveProviderData!.providerId,
            dates: contactAttempts.map(date => date.toJSON()),
            attemptType: 'reach_out',
            close: true
        }})
    }

    const saveSessionDisabled = !(activity?.sessionBooking.allowed) || saveSessionDateMutation.loading;

    const renderContent = () => {
        if (!isProviderHubActivity ?? false) {
            return (
                 <FullScreenMessage title={t('provider_contact_case_warning_title')}>
                    <p>
                        {t('provider_contact_case_warning')}
                        <a target="_blank" rel="noopener noreferrer"
                            href={`${providerContactUrl}/Account/Login`}
                        >
                            <b>{t('provider_contact')}</b>
                        </a>
                        .
                    </p>
                </FullScreenMessage>
            )
        }
        if (cannotBookReason === 'ACTIVITY_NOT_ACCEPTED') {
            return (
                <FullScreenMessage title={t('activity_not_accepted_warning_title')}>
                    <p>{t('activity_not_accepted_warning')}</p>
                    <Button variant="outline" component={Link} to="/clients">Go to clients list</Button>
                </FullScreenMessage>
            )
        }
        if (!enteredDate) {
            if (!isFirstSession) {
                return (
                    <FullScreenMessage title={t('first_session_already_booked_warning_title')}>
                        <p>{t('first_session_already_booked_warning')}</p>
                        <Button variant="outline" component={Link} to="/clients">Go to clients list</Button>
                    </FullScreenMessage>
                )
            }
            switch (shownForm) {
                case ShownForm.None:
                    return <>
                        <h2>{t('enter_date_of_first_appointment_for')} {activity?.client?.firstName}</h2>
                        <ConnectWithClientPrompt activityId={activityId}
                            onDidContactClick={() => setShownForm(ShownForm.SessionDate)}
                            onNoContactClick={handleShowContactAttemptsFormClick}
                        />
                    </>
                case ShownForm.ContactAttempts:
                    return saveContactAttemptsMutation.data
                        ? <>
                            <h2>{t('enter_date_of_first_appointment_for')} {activity?.client?.firstName}</h2>
                            <AfterContactAttemptsSavedPrompt activityId={activityId}/>
                        </>
                        : <>
                            <h2>{t('enter_date_of_first_appointment_for')} {activity?.client?.firstName}</h2>
                            <ContactAttemptsForm
                                contactAttempts={contactAttemptsQuery.data?.contactClientAttempts} clientName={activity?.client?.firstName ?? ''}
                                onSaveAttempts={handleSaveContactAttempts} onCloseCase={handleCloseCase}
                                disabled={saveContactAttemptsMutation?.loading}
                            />
                        </>
                case ShownForm.SessionDate:
                    return <>
                        <h2>{t('enter_date_of_first_appointment_for')} {activity?.client?.firstName}</h2>
                        <SessionDateForm activityId={activityId}
                            description={t('first_session_date_form_description', { replace: { clientName: activity?.client.firstName }})}
                            firstAvailableDate={activity?.providerFirstAvailableAppointmentDate}
                            activityOpenDate={activity?.openDate}
                            onSaveSessionDate={handleSaveSessionDate} disabled={saveSessionDisabled}
                        />
                    </>
            }
        } else {
            return activity && contractDetails && <>
                <h2>{t('enter_date_of_first_appointment_for')} {activity?.client?.firstName}</h2>
                <AfterSessionSavedPrompt activityId={activityId} appointmentDate={enteredDate}
                    activity={activity} contractDetails={contractDetails}
                />
            </>
        }
    }

    const handleBackClick = () => {
        if (shownForm !== ShownForm.None) {
            setShownForm(ShownForm.None);
        } else {
            navigate(-1)
        }
    }

    const clientName = activity && `${activity.client.firstName} ${activity.client.lastName}`;

    const loading = !translationsReady || activityDataQuery.loading || contactAttemptsQuery.loading
        || activityDataQuery.networkStatus === NetworkStatus.refetch
        || closeToDoMutation.loading || contractDetailQuery.loading;
    const mutationPending = saveSessionDateMutation.loading || saveContactAttemptsMutation.loading;

    return (
        <CaseManagementActionContainer onBackClick={handleBackClick} activityId={activityId} className="update_appt_status_content"
            clientName={clientName}
        >
            {
                translationsReady && 
                <PageMetadata
                    pageDescription={t('enter_date_first_appt_page_description')} 
                    pageTitle={t('enter_date_first_appt_page_title')}
                /> 
            }
            {
                loading
                    ? <Spinner fillParent/>
                    : renderContent()
            }
            {
                mutationPending &&
                <Spinner fillParent backdrop/>
            }
        </CaseManagementActionContainer>
    )
}

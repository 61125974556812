import React, { FC, useMemo, useState, useEffect } from "react";
import { FormikErrors, useFormik } from  "formik";
import moment, { Moment } from "moment";
import { Button, DateTimeInput, Select } from "components/Shared/Inputs";
import { useTimezoneUtils } from "hooks/useTimezoneUtils";
import { Trans, useTranslation } from "react-i18next";
import { useQuery } from "@apollo/client";
import { APPOINTMENT_MISMATCH_REASONS_QUERY } from "hooks/useSessionStatuses/queries";
import { CircularProgress, MenuItem, Alert } from "@mui/material";
import { isValid15MinuteIncrement, roundTimeToNearest } from "utils/dateUtils";
import { Link } from "react-router-dom";

interface SessionDateFormProps {
    activityId: number,
    firstAvailableDate: string | undefined,
    activityOpenDate: string | undefined,
    onSaveSessionDate: (date: Date, mismatchReasonCode?: number) => void,
    disabled: boolean,
    description: string,
    outOfHours?: boolean,
    contractAllowsAdditionalHours?: boolean
}

interface SessionDateFormValues {
    sessionDate: Moment,
    mismatchReasonCode: number | null
}

export const SessionDateForm: FC<SessionDateFormProps> = (props) => {
    const { activityId, firstAvailableDate, activityOpenDate, onSaveSessionDate,
        disabled, description, outOfHours, contractAllowsAdditionalHours } = props;

    const [mismatchReasonCodeChanged, setMismatchReasonCodeChanged] = useState<boolean>(false);
    
    const { t } = useTranslation(['caseManagement', 'common'], { useSuspense: false });

    const { dateTimeInputFormat } = useTimezoneUtils();
    
    const appointmentMismatchReasonsQuery = useQuery(APPOINTMENT_MISMATCH_REASONS_QUERY);

    const formik = useFormik<SessionDateFormValues>({
        initialValues: {
            sessionDate: moment(roundTimeToNearest(new Date(), 15)),
            mismatchReasonCode: null
        },
        validate: (values) => {
            const errors: FormikErrors<{ sessionDate: string, mismatchReasonCode: string}> = {};

            if (!values.sessionDate) {
                errors.sessionDate = t('common:enter_valid_date');
            } else if (!values.sessionDate.isValid()) {
                errors.sessionDate = t('common:enter_valid_date');
            } else if (!isValid15MinuteIncrement(values.sessionDate)) {
                errors.sessionDate = t('common:enter_15_increment_date');
            } else if (activityOpenDate && values.sessionDate?.isBefore(activityOpenDate)){
                errors.sessionDate = t('session_date_input_before_case_open_date_error');
            } else if (firstAvailableDate && values.sessionDate?.isAfter(moment(firstAvailableDate), 'day')) {
                if (!values.mismatchReasonCode) {
                    errors.mismatchReasonCode = 'Select mismatch reason';
                }
            }

            return errors;
        },
        onSubmit: (values, helpers) => {
            helpers.validateForm(values).then(errors => {
                console.log(errors);
                if (Object.keys(errors).length === 0) {
                    onSaveSessionDate(values.sessionDate!.toDate(), values.mismatchReasonCode ?? undefined);
                }
            });
        }
    });

    useEffect(() => {
        if (!!formik.values.mismatchReasonCode) {
            setMismatchReasonCodeChanged(true);
        }
    }, [formik.values.mismatchReasonCode]);

    const showMismatchReasonPicker = firstAvailableDate && formik.values.sessionDate?.isAfter(moment(firstAvailableDate), 'day');

    const handleSessionDateChange = (date) => {
        formik.setFieldValue('sessionDate', date);
    }

    const submitDisabled = disabled || !formik.isValid || outOfHours;

    const mismatchReasons = useMemo(() =>
        appointmentMismatchReasonsQuery.data?.firstAppointmentMismatchReasons
            .map(r => ({title: r.title, value: Number(r.value)}))
    , [appointmentMismatchReasonsQuery.data]);

    
    const renderOutOfHoursWarning = () => (
        <Alert severity="info" className="appt_status_dropdown">
            <span>{t('out_of_hours_prompt_title')}</span>
            {
                contractAllowsAdditionalHours &&
                <Trans t={t} i18nKey="out_of_hours_prompt_content">
                    You can request more on the <Link to={`/clients/${activityId}/requests`}>Requests page</Link>
                </Trans>
            }
        </Alert>
    )

    return (
        <form onSubmit={formik.handleSubmit} className="update_appt_status_step">
            <p>
                {description}
            </p>
            {
                outOfHours &&
                renderOutOfHoursWarning()
            }
            <DateTimeInput
                className="appt_status_dropdown"
                format={dateTimeInputFormat}
                disabled={(outOfHours || disabled) && !mismatchReasonCodeChanged}
                // placeholder={dateTimeInputMask}
                value={formik.values.sessionDate}
                onChange={handleSessionDateChange}
                minutesStep={15}
                label={t('appt_date_input_label')}
                // error={Boolean(formik.errors.sessionDate)}
                // helperText={formik.errors.sessionDate}
                // cancelLabel={t('common:cancel')}
                // okLabel={t('common:ok')}
                // invalidDateMessage={t('common:invalid_date_format')}
            />
            {
                showMismatchReasonPicker &&
                <>
                    <p>
                        {t('please_tell_us_why_the_client_was_not_scheduled_for_your_first_available_appointment')}
                    </p>
                    <Select
                        value={formik.values.mismatchReasonCode ?? ''}
                        className="appt_status_dropdown"
                        name="mismatchReasonCode"
                        onChange={formik.handleChange}
                        label={t('schedule_mismatch_reason_input_label')}
                        error={Boolean(formik.errors.mismatchReasonCode)}
                        endAdornment={
                            appointmentMismatchReasonsQuery.loading
                                ? <CircularProgress color="inherit" size={20}/>
                                : undefined
                        }
                    >
                        {
                            mismatchReasons?.map((reason) => 
                                <MenuItem value={reason.value} key={reason.value}>
                                    {reason.title}
                                </MenuItem>
                            )
                        }
                    </Select>
                </>
            }
            <Button variant="opaque" type="submit" disabled={submitDisabled} className="appt_status_dropdown">
                {t('save_appointment')}
            </Button>
        </form>
    )
}
import React from 'react';
import { StaffTableItem } from '../interfaces';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { ColumnDefinition } from 'components/Shared/MaterialTable';

export const useStaffColumnsDefinitions = (): ColumnDefinition[] => {
    const { t } = useTranslation('staff', { useSuspense: false });

    const renderProviderNameCell = (provider: StaffTableItem) => (
        <Link className="color--link" to={`/providers/${provider.providerId}/profile`}>
            {provider.name ?? '---'}
        </Link>
    )

    const renderPhoneNumberCell = (provider: StaffTableItem) => (
        provider.preferredPhone?.phoneNumber ?? '---'
    )

    return [
        {
            title: t('name'),
            field: 'name',
            sortable: true,
            template: renderProviderNameCell
        }, {
            title: t('id'),
            field: 'providerId',
            sortable: true,
        }, {
            title: t('email'),
            field: 'emailAddress',
            sortable: true,
        }, {
            title: t('phone'),
            field: 'preferredPhone.phoneNumber',
            sortable: true,
            template: renderPhoneNumberCell
        }
    ];
}
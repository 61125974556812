import { gql, TypedDocumentNode } from '@apollo/client';
import { Address, Email, Phone, ProviderDetails } from '../../../interfaces';

export const PROFILE_DETAILS_QUERY: TypedDocumentNode<ProfileDetailsQueryData, ProfileDetailsQueryVars> = gql`
    query PROVIDER_DETAILS_QUERY($providerId: Long!) {
        provider(id: $providerId) {
            id
            providerId
            businessNumber
            details {
                firstName
                lastName
                salutation
                employmentTypeId
                employmentTypeString
                preferredLanguage
                preferredLanguageString
                preferredCommunication
                preferredCommunicationString
                preferredCommunicationForNonConnected
                disability
                disabilityString
                ethnicityCode
                ethnicityString
                gender
                genderString
                religionCode
                religionString
                sexualOrientation
                sexualOrientationString
                showVeteranStatus
                veteranStatus
                veteranStatusString
                allowSmsCommunication
                allowEmailCommunication
                emailAddress
                smsPhoneNumbers {
                    phoneId
                    phoneNumber
                    receiveSms
                }
            }
            contactInfo {
                mailingAddresses {
                    addressLine1
                    addressLine2
                    countryCode
                    countryName
                    zIP_Postal
                    state_ProvinceCode
                    officeType
                    officeTypeString
                    addressExpiryDate
                    addressEffectiveDate
                }
                emails {
                    confidential
                    primary
                    emailAddress
                    providerEmailId
                }
                phones {
                    phoneNumber
                    phoneType
                    phoneTypeString
                    leaveMessage
                    preferredLine
                    preferredConfidentialLine
                    providerPhoneId
                }
            }
        }
    }
`

export interface ProfileDetailsQueryData {
    provider: {
        id: number,
        details?: ProviderDetails,
        contactInfo?: {
            mailingAddresses: Address[],
            emails: Email[],
            phones: Phone[]
        }
    }
}

export interface ProfileDetailsQueryVars {
    providerId: number
}

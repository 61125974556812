import React, { FC, useEffect, useMemo } from 'react';
import { FormViewContainer } from '../../components/FormViewContainer';
import { FormPresenter } from '../../components/FormPresenter';
import { CREATE_FORM_BY_TEMPLATE, GET_FORMS_BY_ACTIVITY, UPDATE_FORM } from '../../queries';
import { useMutation, useQuery } from '@apollo/client';
import { Spinner } from 'components/Shared';
import { getFirstSession } from 'components/CaseManagement/utils/sessionUtils';
import { ISession } from 'components/CaseManagement/interfaces';
import { PROVIDER_HUB } from 'constants/targetedSystem';
import { useAuth } from 'components/Auth';
import { ActivityStatus } from 'constants/activityStatus';
import { FullScreenMessage } from 'components/Shared/FullScreenMessage';
import { useNavigate } from 'react-router-dom';
import { useHistoryState } from 'hooks/useHistoryState';
import { useTranslation } from 'react-i18next';

interface FormByTemplateViewProps {
    activityId: number,
    sessionId: number | undefined,
    templateName: string
}

export const FormByTemplateView: FC<FormByTemplateViewProps> = (props) => {
    const { activityId, sessionId, templateName } = props;
    const { providerData, effectiveProviderData } = useAuth();
    const navigate = useNavigate();
    
    const [historyState] = useHistoryState<{ entryRoute?: string }>();
    const { t } = useTranslation('assessments', { useSuspense: false });

    const [createForm, createFormMutation] = useMutation(CREATE_FORM_BY_TEMPLATE);
    const [updateForm, updateFormMutation] = useMutation(UPDATE_FORM, {
        onCompleted: () => {
            navigate(historyState?.entryRoute ? historyState?.entryRoute : `/clients/${activityId}`);
        }
    });

    const activityDataQuery = useQuery(GET_FORMS_BY_ACTIVITY, {
        variables: { 
            activityId: activityId,
            sessionId: sessionId,
            templateName: templateName
        },
        errorPolicy: 'all',
        fetchPolicy: 'no-cache'
    });

    const activity = activityDataQuery.data?.activity;

    const existingForms = activityDataQuery.data?.forms;

    const existingForm = useMemo(() => (
        existingForms?.find(form => form.templateName === templateName && (sessionId ? form.sessionId === sessionId : true))
    ), [existingForms, sessionId, templateName]);
    
    const createdForm = createFormMutation.data?.createFormByTemplate;

    const activityInactive = activity?.activityStatusCode !== ActivityStatus.Active;

    useEffect(() => {
        if (existingForms && !existingForm && !activityInactive) {
            createForm({
                variables: {
                    templateName: templateName,
                    form: {
                        activityId: activityId,
                        providerId: providerData?.providerId || effectiveProviderData?.providerId || -1,
                        createdBy: `${effectiveProviderData?.providerId}`,
                        sessionId: sessionId ?? getFirstSession(activity?.sessions as ISession[])?.sessionId
                    }
                }
            })
        }
    }, [existingForms, templateName, existingForm, activityInactive]);

    const form = existingForm || createdForm;

    const readOnly = (updateFormMutation.called && !updateFormMutation.error)
        || form?.readOnly
        || activityInactive
        || activity?.targetedSystem?.systemCode !== PROVIDER_HUB;

    const handleFormSubmit = (result) => {
        form && updateForm({
            variables: {
                data: result,
                formId: form?.id,
                isCompleted: true
            }
        })
    }

    const clientName = `${activity?.client.firstName} ${activity?.client.lastName}`;

    return (
        <FormViewContainer activityId={activityId} clientName={clientName}>
            {
                updateFormMutation.loading &&
                <Spinner fillParent backdrop/>
            }
            {
                (activityDataQuery.loading || createFormMutation.loading)
                    ? <Spinner fillParent/>
                    : form
                        ? <FormPresenter
                            activityId={activityId}
                            sessionId={sessionId || form.sessionId}
                            form={form}
                            readOnly={readOnly ?? true}
                            onSubmit={handleFormSubmit}
                        />
                        : <FullScreenMessage title={t('assessment_not_found_message')} />
            }
        </FormViewContainer>
    )
}

import { AuthData, LoginResponse, ProviderData, TokenPayload } from '../interfaces';
import { jwtDecode } from 'jwt-decode';
import moment from 'moment';


export const ADMIN_USER_ID = 0;

export const MapLoginResponseToAuthData = (loginResponse: LoginResponse): AuthData | null => {
    const userData = loginResponse.userAuthData;
    const effectiveUserData = loginResponse.effectiveUserAuthData;

    if (!userData && !effectiveUserData) {
        return null;
    }

    const newAuthData: AuthData = {
        providerData: undefined,
        effectiveProviderData: undefined,
        token: undefined,
        effectiveToken: undefined
    };

    if (userData) {
        newAuthData.token = userData.token;

        if (userData.provider) {
            const prov: ProviderData = {
                ...userData.provider,
                ...userData.providerDetails,
                id: userData.id,
                provider: userData.provider,
                providerDetails: userData.providerDetails,
                timeZone: userData.timeZone ?? null
            };
            newAuthData.providerData = prov;
        } else {
            const prov: ProviderData = {
                id: userData.id,
                firstName: userData.firstName ?? '',
                isAdmin: true,
                isProvider: true,
                hasManagedCalendar: true,
                hasInvoicing: true,
                lastName: userData.lastName ?? '',
                providerId: Number(userData.providerId) || ADMIN_USER_ID,
                associateWithVendor: false,
                vendor: null,
                preferredLanguageCode: 'en',
                timeZone: null,
                provider: null,
                providerDetails: null
            };
            newAuthData.providerData = prov;
        }
    }
    if (effectiveUserData) {
        newAuthData.effectiveToken = effectiveUserData.token;

        if (effectiveUserData.provider) {
            const prov: ProviderData = {
                ...effectiveUserData.provider,
                ...effectiveUserData.providerDetails,
                id: effectiveUserData.id,
                provider: effectiveUserData.provider,
                providerDetails: effectiveUserData.providerDetails,
                timeZone: effectiveUserData.timeZone ?? null
            };
            newAuthData.effectiveProviderData = prov;
        } else {
            const prov: ProviderData = {
                id: effectiveUserData.id,
                firstName: effectiveUserData.firstName ?? '',
                isAdmin: true,
                isProvider: true,
                hasManagedCalendar: true,
                hasInvoicing: true,
                lastName: effectiveUserData.lastName ?? '',
                providerId: Number(effectiveUserData.providerId) || ADMIN_USER_ID,
                associateWithVendor: false,
                vendor: null,
                preferredLanguageCode: 'en',
                timeZone: null,
                provider: null,
                providerDetails: null
            };
            newAuthData.effectiveProviderData = prov;
        }
    } else {
        newAuthData.effectiveProviderData = newAuthData.providerData;
    }

    return newAuthData;
}

export const IsTokenValid = (token: string | undefined) => {
    if (token) {
        const tokenData = jwtDecode<TokenPayload>(token);
        return tokenData.exp * 1000 > new Date().getTime();
    }
    return false;
}

export const IsTokenAboutToExpire = (token: string | undefined) => {
    if (token) {
        const tokenData = jwtDecode<TokenPayload>(token);
        const expireOn = tokenData.exp * 1000;
        const current = new Date().getTime();
        const diff = moment(expireOn).diff(current, 'minutes');
        return diff <= 5;
    }
    return true;
}
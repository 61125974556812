import React, { forwardRef } from 'react';
import { TimePicker, TimePickerProps } from '@mui/x-date-pickers';

type TimeInputProps = Omit<TimePickerProps<any>, "inputVariant" | "renderInput"> & {
    error?: boolean,
    helperText?: string,
    onBlur?: React.FocusEventHandler<HTMLInputElement | HTMLTextAreaElement>
};

export const TimeInput = forwardRef<HTMLDivElement, TimeInputProps>(({ error, className, helperText, onBlur, ...props}, ref) => (
    <TimePicker
        slotProps={{
            openPickerButton: { 
                // component: Button,
                // variant: 'text',
                // round: true,
                
                size: 'small',
                disableRipple: true,
                style: {
                    color: 'inherit'
                }
            },
            textField: { helperText, onBlur, error, className },
            inputAdornment: {
                style: {
                    color: 'inherit'
                }
            }
        }}
        ref={ref}
        {...props}
    />
))
import React, { FC } from 'react';
import { Tabs, Tab } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Routes, Route, Link, useLocation, Navigate } from 'react-router-dom';

import { Overview } from '../ActivityDetailsTabs/Overview/Overview';
import { ClinicalRecordsTab } from '../ActivityDetailsTabs/ClinicalRecords';
import { ClientDetails } from '../ActivityDetailsTabs/ClientDetails/ClientDetails';
import { Requests } from '../ActivityDetailsTabs/Requests/Requests';
import { InvoicesTab } from '../ActivityDetailsTabs/Invoices/InvoicesTab';
import { IActivityDetails, IFormByActivity } from '../../../interfaces';
import { useAuth } from 'components/Auth';
import { StepDefinition } from 'components/ToDos';
import { Button } from 'components/Shared/Inputs';
import { useScreenSize } from 'components/Layout';
import { IContractDetails } from 'hooks/useContractDetails/interfaces';
import { useFeatureManager } from 'hooks/useFeatureManager';
import { OverHoursApprovalDTO } from 'components/Requests/interfaces';
// import { AssessmentTools } from '../ActivityDetailsTabs/AssessmentTools';
import { PROVIDER_HUB } from 'constants/targetedSystem';
import InvoiceDraftProvider from 'components/Invoicing/components/InvoiceDraftProvider';

export interface ActivityDetailsContentProps {
    activity: IActivityDetails,
    forms?: IFormByActivity[],
    step: StepDefinition | null,
    onShowDetailsClick: () => void
    contractDetails: IContractDetails | undefined,
    onAdditionalHoursRequestCreated: () => void,
    additionalHoursApprovals: OverHoursApprovalDTO[] | undefined
}

export const ActivityDetailsContent: FC<ActivityDetailsContentProps> = (props) => {
    const { activity, onShowDetailsClick, forms, step,
        additionalHoursApprovals, onAdditionalHoursRequestCreated } = props;

    const { t } = useTranslation('activitydetails', { useSuspense: false });
    const { isAdditionalHoursFeatureEnabled } = useFeatureManager();
    const location = useLocation();
    const { effectiveProviderData } = useAuth();
    const { isMobile } = useScreenSize();
    const fromToDo = location?.state?.["fromToDo"];

    const showRequestsTab = isAdditionalHoursFeatureEnabled()
        && (activity?.contractAllowsAdditionalHours || additionalHoursApprovals?.some(approval => approval.request));

    function getCurrentTabValue() {
        const match = location.pathname.replace(/\/clients\/[0-9]+\//, "") || `/clients/${props.activity?.activityId}`;
        return match?.startsWith('invoices') ? "invoices" : match;
    }

    return (
        <div className="activity_details_content_wrapper">
            {
                isMobile &&
                <section className="client_name_and_details_button_section">
                    <h1 className="global__header1 client_name">
                        {`${activity?.client?.firstName} ${activity?.client?.lastName}`}
                    </h1>
                    <Button variant="text" onClick={onShowDetailsClick} className="client_details_button">
                        Details
                    </Button>
                </section>
            }
            <Tabs className="activity_details_tabs" indicatorColor="primary"
                variant="scrollable"
                scrollButtons={false}
                value={getCurrentTabValue()}
            >
                {
                    activity?.isAvailableToProvider &&
                    <Tab className="button_tab" component={Link} value={`/clients/${props.activity?.activityId}`} label={t('overview', 'Overview')} to={`/clients/${props.activity?.activityId}`} />
                }
                {
                    activity?.isAvailableToProvider && activity?.targetedSystem?.systemCode === PROVIDER_HUB &&
                    <Tab className="button_tab" component={Link} value="documents" label={t('clinical_records', 'Clinical Records')} to="documents" />
                }
                {/* {
                    activity?.isAvailableToProvider && activity?.targetedSystem?.systemCode === PROVIDER_HUB &&
                    <Tab className="button_tab" component={Link} value="/assessment_tools" label={t('assessment_tools')} to={`/clients/${props.activity?.activityId}/assessment_tools`} />
                }   */}
                {
                     activity?.isAvailableToProvider &&
                     <Tab className="button_tab" component={Link} value="details" label={t('client_details', 'Client Details')} to="details" />
                }               
                {
                    effectiveProviderData?.hasInvoicing &&
                    <Tab className="button_tab" component={Link} value="invoices" label={t('invoices', 'Invoices')} to="invoices" />
                }
                {
                    showRequestsTab && activity?.isAvailableToProvider &&
                    <Tab className="button_tab" component={Link} value="requests" label={t('requests', 'Requests')} to="requests" />
                }
            </Tabs>
            <Routes>
                <Route 
                    path="/"
                    element={
                        <Overview activity={activity} step={step} contractDetails={props.contractDetails} onAdditionalHoursRequestCreated={onAdditionalHoursRequestCreated} />
                    }
                />
                <Route
                    path="/documents"
                    element={
                        <ClinicalRecordsTab activityId={activity?.activityId} forms={forms} preAssessments={activity?.preAssessments} fromToDo={fromToDo}/>
                    }
                />
                {/* <Route 
                        path={`${path}/assessment_tools`}
                        element={<AssessmentTools activityId={activity?.activityId} forms={forms} sessions={activity.sessions}/>}
                /> */}
                <Route
                    path="/details"
                    element={<ClientDetails activity={activity} />}
                />
                <Route
                    path="/requests"
                    element={<Requests activity={activity} approvals={additionalHoursApprovals} onAdditionalHoursRequestCreated={onAdditionalHoursRequestCreated} />}
                />
                {
                    effectiveProviderData?.hasInvoicing
                    ? [
                        <Route
                            path="/invoices/:invoiceId"
                            key="invoices/:invoiceId"
                            element={
                                <InvoiceDraftProvider activityId={activity.activityId}>
                                    <InvoicesTab/>
                                </InvoiceDraftProvider>
                            }
                        />,
                        <Route
                            path="/invoices"
                            key="invoices"
                            element={<InvoicesTab/>}
                        />
                    ]
                    : <Route path="/invoices/*" element={<Navigate replace to="/" />} />
                }
            </Routes>
        </div>
    )
}
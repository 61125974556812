import { gql } from '@apollo/client';

export const CHANGE_PASSWORD_MUTATION = gql`
    mutation CHANGE_PASSWORD_MUTATION($currentPassword: String, $newPassword: String, $userName: String) {
        changePassword(currentPassword: $currentPassword, newPassword: $newPassword, userName: $userName)
    }
`

export interface ChangePasswordMutationVars {
    currentPassword: string,
    newPassword: string,
    userName: string
}
import { gql, TypedDocumentNode } from '@apollo/client';
import { License, LicenseInput } from '../../../interfaces';

export const LICENSE_REF_DATA: TypedDocumentNode<LicenseRefDataData, LicenseInfoRefDataVars> = gql`
    query LICENSE_REF_DATA {
        licenseRegistrationAssociations {
            title
            value
        }
        provinces {
            title
            value
        }
    }
`

interface LicenseRefDataData {
    licenseRegistrationAssociations: {
        title: string,
        value: string
    }[],
    provinces: {
        title: string,
        value: string
    }[]
}

interface LicenseInfoRefDataVars {}

export const UPDATE_LICENSES_MUTATION: TypedDocumentNode<UpdateLicensesMutationData, UpdateLicensesMutationVars> = gql`
    mutation UPDATE_LICENSES_MUTATION($providerId: Long!, $licenses: [LicenseInput]) {
        updateCredentials(providerId: $providerId, licenses: $licenses) {
            providerId
            credential {
                licenses {
                    providerLicenseId
                    registrationNo
                    licenseRegistrationAssociation
                    countryCode
                    province
                    provinceString
                    licenseType
                    licenseTypeDescr
                    expiryDate
                }
            }
        }
    }
`

export interface UpdateLicensesMutationData {
    updateCredentials: {
        credential: {
            licenses: License[]
        }
    }
}

export interface UpdateLicensesMutationVars {  
    providerId: number,
    licenses: LicenseInput[]
}

import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import { useTimezoneUtils } from 'hooks/useTimezoneUtils';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { ChecklistItem } from '../ChecklistItem';
import { FormStatus, Session, SessionStatus } from '../../interfaces';
import { Button } from 'components/Shared/Inputs';

interface SessionsStepProps {
    activityId: number,
    sessions: Session[] | undefined
}

export const SessionsStep: FC<SessionsStepProps> = (props) => {
    const { activityId, sessions } = props;
    const { t } = useTranslation('assessments', { useSuspense: false });
    const { formatDate } = useTimezoneUtils();

    const activeSessions = sessions?.filter(session => (session.sessionStatusId ?? SessionStatus.ACTIVE) === SessionStatus.ACTIVE);

    return activeSessions
        ? <>
            <p>{t('close_case_checklist_set_session_status_message', {count: activeSessions.length})}</p>
            <ul className="close_case_checklist_list">
                {
                    activeSessions?.map(session => {
                        const date = moment(session.startDateTimeUTC);
                        return (
                            <ChecklistItem key={session.sessionId.toString()} label={formatDate(date, 'MMMM Do, YYYY | LT')} status={FormStatus.Incomplete}>
                                <Button to={{ pathname: `/clients/${activityId}/sessions/${session.sessionId}/status` }}
                                    variant="outline" component={Link}>{t('close_case_checklist_set_session_status_button_label')}
                                </Button>
                            </ChecklistItem>
                        )
                    })
                }
            </ul>
        </>
        : <></>
}
import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { PreviousExperience as IPreviousExperience } from '../../../interfaces';
import { SectionContainer } from '../../Other/SectionContainer';
import { PreviousExperienceForm } from './PreviousExperienceForm';
import { UPDATE_PREVIOUS_EXPERIENCE_MUTATION } from './queries';
import { useMutation } from '@apollo/client';
import { useFeatureManager } from 'hooks/useFeatureManager';

interface PreviousExperienceProps {
    providerId: number,
    previousExperience?: IPreviousExperience[]
    onPreviousExperienceUpdated: (previousExperience: IPreviousExperience[]) => void
}

export const PreviousExperience: FC<PreviousExperienceProps> = (props) => {
    const { providerId, previousExperience, onPreviousExperienceUpdated } = props;
    const { t, ready: translationsReady } = useTranslation('providerprofile', { useSuspense: false });
    
    const { isProviderProfileSelfServeFeatureEnabled } = useFeatureManager();

    const [cachePendingUpdate, setCachePendingUpdate] = useState(false);

    const [update, updateMutation] = useMutation(UPDATE_PREVIOUS_EXPERIENCE_MUTATION, {
        update: (_, result) => {
            if (result.data?.updateCredentials?.credential?.previousExperience) {
                setCachePendingUpdate(true);
                onPreviousExperienceUpdated(result.data.updateCredentials.credential.previousExperience);
            }
        }
    });

    useEffect(() => {
        setCachePendingUpdate(false);
    }, [previousExperience]);
    
    return !translationsReady
        ? <></>
        : <SectionContainer title={t('previous_experience__section_title')}>
            {
                previousExperience?.map((experience, index) => (
                    <PreviousExperienceForm key={index}
                        providerId={providerId}
                        entry={experience}
                        previousExperience={previousExperience}
                        mutation={[update, updateMutation]}
                        cachePendingUpdate={cachePendingUpdate}
                    />
                ))
            }
            {
                isProviderProfileSelfServeFeatureEnabled() &&
                <PreviousExperienceForm
                    providerId={providerId}
                    entry={undefined}
                    previousExperience={previousExperience}
                    mutation={[update, updateMutation]}
                    cachePendingUpdate={cachePendingUpdate}
                />
            }
        </SectionContainer>
}
import { useEffect, useRef } from 'react';
import { useAuth } from 'components/Auth';
import { useAppConfig } from 'providers/AppConfig';
import { ApolloClient, HttpLink, InMemoryCache, NormalizedCacheObject, split } from '@apollo/client';
import { WebSocketLink } from '@apollo/client/link/ws';
import { getMainDefinition } from '@apollo/client/utilities';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';

export const useApolloClient = () => {
    const config = useAppConfig();
    const { authFetch } = useAuth();
    const { i18n } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();

    const buildClient = (): ApolloClient<NormalizedCacheObject> => {
        const httpLink = new HttpLink({
            uri: config.REACT_APP_GRAPHQL_API_HOST,
            credentials: 'include',
            fetch: authFetch as typeof fetch,
        });
    
        const wsLink = new WebSocketLink({
            uri: config.REACT_APP_GRAPHQL_WS_HOST,
            options: {
                reconnect: true
            }
        });

        const link = split(
            // split based on operation type
            ({ query }) => {
                const definition = getMainDefinition(query);
                return (
                    definition.kind === 'OperationDefinition' &&
                    definition.operation === 'subscription'
                );
            },
            wsLink,
            httpLink
        );

        const errorLink = onError(({ graphQLErrors, networkError, operation }) => {
            if (!Boolean(operation.getContext().skipGlobalErrorHandling)) {
                if (graphQLErrors) {
                    enqueueSnackbar?.(graphQLErrors[0].message, { variant: 'error' });
    
                    if (graphQLErrors.length > 1) {
                        for (const error of graphQLErrors.slice(1)) {
                            console.log(error.message);
                        }
                    }
                }
    
                if (networkError) {
                    enqueueSnackbar?.(networkError.message, { variant: 'error' });
                }
            }
        });
    
        const authLink = setContext((_, { headers }) => {
            // var lang = localStorage.getItem('i18nextLng');
            return {
                headers: {
                    ...headers,
                    'Accept-Language': i18n.language
                } 
            }
            // return token 
            //     ? {
            //         headers: {
            //             ...headers,
            //             Authorization: `Bearer ${token}`,
            //             'Accept-Language': i18n.language
            //         }
            //     }
            //     : { 
            //         headers: {
            //         ...headers,
            //         'Accept-Language': i18n.language
            //         } 
            //     }
        });

        return new ApolloClient({
            link: authLink.concat(errorLink.concat(link)),
            cache: new InMemoryCache({
                typePolicies: {
                    Activity: {
                        keyFields: ["activityId"]
                    },
                    Provider: {
                        keyFields: ["providerId"]
                    },
                    Invoice: {
                        keyFields: ["invoiceId"]
                    }
                }
            })
        });
    }

    const { current: client } = useRef<ApolloClient<NormalizedCacheObject>>(buildClient());

    useEffect(() => {
        client.resetStore();
    }, [i18n.language]);

    return client;
}
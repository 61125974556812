import { useAuth } from 'components/Auth';
import { useLocation } from 'react-router-dom';


export const useNavigationUtils = () => {
    const { providerData, effectiveProviderData, authenticated } = useAuth();
    const { pathname } = useLocation();

    const pathRoot: string = "/" + pathname.split("/")[1];

    const showStaffTab = Boolean((providerData?.providerId !== 0 && providerData?.isAdmin && providerData?.associateWithVendor)
        || (providerData?.providerId === 0 && effectiveProviderData?.isAdmin && effectiveProviderData?.associateWithVendor));

    const showCalendarTab = Boolean(effectiveProviderData?.hasManagedCalendar);

    const showInvoicingTab = Boolean(effectiveProviderData?.hasInvoicing);

    return {
        pathRoot,
        showStaffTab,
        showCalendarTab,
        showInvoicingTab,
        authenticated
    }
}
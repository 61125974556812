import React from 'react';
import { OverHoursApprovalDTO } from '../interfaces';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { ColumnDefinition } from 'components/Shared/MaterialTable';
import { useTimezoneUtils } from 'hooks/useTimezoneUtils';

export const useRequestsColumnsDefinitions = (showActivityInfo: boolean): ColumnDefinition[] => {
    const { t } = useTranslation('requests', { useSuspense: false });

    const { createDateFormatFunction, DATE_PORTION_MOMENT_FORMAT } = useTimezoneUtils();
    const formatDate = createDateFormatFunction(DATE_PORTION_MOMENT_FORMAT);

    const renderClientNameCell = (approval: OverHoursApprovalDTO) => (
        <Link to={{pathname: `/clients/${approval.activityId}`}}>
            {`${approval.clientFirstName} ${approval.clientLastName}`}
        </Link>
    );

    const renderRequestedHoursCell = (approval: OverHoursApprovalDTO) => (approval.request?.requestedHours ?? '---');
    const renderRequestedDateCell = (approval: OverHoursApprovalDTO) => formatDate(approval.request?.requestedDate ?? null);
    const renderApprovedDateCell = (approval: OverHoursApprovalDTO) => formatDate(approval.approvedDate);
    const renderRequestStatusCell = (approval: OverHoursApprovalDTO) => (approval.request?.requestedHoursStatusString ?? '---');

    return showActivityInfo
        ? [{
            title: t('client_name'),
            field: 'clientName',
            sortable: true,
            template: renderClientNameCell
        }, {
            title: t('activity_id'),
            sortable: true,
            field: 'activityId'
        }, {
            title: t('date_requested'),
            field: 'dateRequested',
            sortable: true,
            template: renderRequestedDateCell
        }, {
            title: t('date_approved'),
            field: 'dateApproved',
            sortable: true,
            template: renderApprovedDateCell
        }, {
            title: t('approved_status'),
            sortable: true,
            field: 'request.status',
            template: renderRequestStatusCell
        }]
        : [{
            title: t('hours_requested'),
            field: 'requestedHours',
            sortable: true,
            template: renderRequestedHoursCell
        }, {
            title: t('approved_hours'),
            sortable: true,
            field: 'approvedHours'
        }, {
            title: t('date_requested'),
            field: 'dateRequested',
            sortable: true,
            template: renderRequestedDateCell
        }, {
            title: t('date_approved'),
            field: 'dateApproved',
            sortable: true,
            template: renderApprovedDateCell
        }, {
            title: t('approved_status'),
            sortable: true,
            field: 'request.status',
            template: renderRequestStatusCell
        }];
}
import { gql, TypedDocumentNode } from '@apollo/client';
import { IDurationConstantResponse } from './interfaces';

export const DURATION_CONSTANTS_QUERY: TypedDocumentNode<IDurationConstantResponse, {  }> = gql`
    query DURATION_CONSTANTS_QUERY {
        appointmentDurations {
            title
            value
        }
    }
`
import React, { FC, useState } from "react";
import { FormikErrors, useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import { PreviousExperience, PreviousExperienceInput } from '../../../interfaces';
import { Grid } from "@mui/material";
import { Button, DateInput, TextBox } from 'components/Shared/Inputs';
import { Spinner } from "components/Shared";
import { MutationTuple } from "@apollo/client";
import { UpdatePreviousExperienceMutationData, UpdatePreviousExperienceMutationVars } from "./queries";
import moment from "moment";
import { confirm } from 'components/Shared/ConfirmDialog';
import AddIcon from '@mui/icons-material/Add';
import { SectionContainer } from '../../Other/SectionContainer';
import { useTimezoneUtils } from "hooks/useTimezoneUtils";


const MapExperienceToExperienceInput = (entry: PreviousExperience): PreviousExperienceInput => ({
    employerName: entry.employerName,
    endDate: entry.endDate,
    jobTitleRole: entry.jobTitleRole,
    providerPreviousExperienceId: entry.providerPreviousExperienceId,
    startDate: entry.startDate
})

interface PreviousExperienceFormProps {
    providerId: number,
    previousExperience?: PreviousExperience[],
    entry?: PreviousExperience,
    mutation: MutationTuple<UpdatePreviousExperienceMutationData, UpdatePreviousExperienceMutationVars>,
    cachePendingUpdate: boolean
}

interface PreviousExperienceFormValues {
    providerPreviousExperienceId: number | null,
    employerName: string,
    jobTitleRole: string,
    startDate: string,
    endDate: string
}

export const PreviousExperienceForm: FC<PreviousExperienceFormProps> = (props) => {
    const { providerId, entry, previousExperience, mutation, cachePendingUpdate } = props;

    const { t } = useTranslation('providerprofile', { useSuspense: false });
    const { t: t_common } = useTranslation('common', { useSuspense: false });

    const { createDateFormatFunction, DATE_PORTION_MOMENT_FORMAT, localeDateFormat } = useTimezoneUtils();
    const formatCredentialDate = createDateFormatFunction(DATE_PORTION_MOMENT_FORMAT);

    const [requestPending, setRequestPending] = useState(false);
    
    const [inEdit, setInEdit] = useState(false);
    
    const [update, updateMutation] = mutation;

    const formik = useFormik<PreviousExperienceFormValues>({
        initialValues: {
            providerPreviousExperienceId: entry?.providerPreviousExperienceId ?? null,
            employerName: entry?.employerName ?? '',
            jobTitleRole: entry?.jobTitleRole ?? '',
            startDate: entry?.startDate ?? moment().format('YYYY-MM-DD'),
            endDate: entry?.endDate ?? moment().format('YYYY-MM-DD')
        },
        validate: (_) => {
            const errors: FormikErrors<PreviousExperienceFormValues> = {};

            return errors;
        },
        validateOnBlur: true,
        validateOnChange: true,
        onSubmit: async (values) => {
            formik.validateForm();

            if (formik.isValid) {
                const dialogProps = {
                    message: <>
                        <p>{t('common__confirm_changes_dialog_text')}</p>
                        <ul>
                        {
                            (!entry || values.employerName !== entry.employerName) &&
                            <li>{t('previous_experience__employer_label')}: <b>{values.employerName}</b></li>
                        }
                        {
                            (!entry || values.jobTitleRole !== entry.jobTitleRole) &&
                            <li>{t('previous_experience__job_title_label')}: <b>{values.jobTitleRole}</b></li>
                        }
                        {
                            (!entry || values.startDate !== entry.startDate) &&
                            <li>{t('previous_experience__start_date_label')}: <b>{moment(values.startDate, "YYYY-MM-DD").format(localeDateFormat)}</b></li>
                        }
                        {
                            (!entry || values.endDate !== entry.endDate) &&
                            <li>{t('previous_experience__end_date_label')}: <b>{moment(values.endDate, "YYYY-MM-DD").format(localeDateFormat)}</b></li>
                        }
                        </ul>
                    </>
                }
                if (await confirm(dialogProps)) {
                    setRequestPending(true);
                    const updatedData = [
                        ...(previousExperience?.filter(e => e !== entry) || []).map(MapExperienceToExperienceInput),
                        {
                            providerPreviousExperienceId: values.providerPreviousExperienceId,
                            employerName: values.employerName,
                            jobTitleRole: values.jobTitleRole,
                            startDate: values.startDate,
                            endDate: values.endDate
                        }
                    ];
                    update({
                        variables: {
                            providerId: providerId,
                            previousExperience: updatedData
                        }
                    }).then(() => { setInEdit(false); formik.resetForm(); }).finally(() => setRequestPending(false));
                }
            }
        }
    });

    const handleDelete = async () => {
        const dialogProps = {
            title: t('common__delete_entry_dialog_title'),
            message: t('common__delete_entry_dialog_text')
        }
        if (await confirm(dialogProps)) {
            setRequestPending(true);
            update({
                variables: {
                    providerId: providerId,
                    previousExperience: [
                        ...(previousExperience?.filter(e => e.providerPreviousExperienceId !== entry?.providerPreviousExperienceId) || [])
                        .map(MapExperienceToExperienceInput)
                    ]
                }
            }).finally(() => setRequestPending(false))
        }
    }
    
    const hasChanges = formik.dirty 
        && (formik.values.employerName !== entry?.employerName
            || formik.values.jobTitleRole !== entry?.jobTitleRole
            || formik.values.startDate !== entry?.startDate
            || formik.values.endDate !== entry?.endDate);

    const deleteDisabled = updateMutation.loading || cachePendingUpdate;
    const saveDisabled = deleteDisabled || !hasChanges || !formik.isValid;

    const handleCancelChanges = async () => {
        if (hasChanges) {
            const dialogProps = {
                title: t('common__discard_changes_dialog_title'),
                message: t('common__discard_changes_dialog_text')
            }
            if (await confirm(dialogProps)) {
                formik.resetForm();
                setInEdit(false);
            }
        } else {
            formik.resetForm();
            setInEdit(false);
        }
    }

    const handleStartDateChange = (date) => {
        if (date) {
            formik.setFieldValue('startDate', date.format('YYYY-MM-DD'));
            const endDate = moment(formik.values.endDate, 'YYYY-MM-DD');
            if (date.isAfter(endDate)) {
                formik.setFieldValue('endDate', date.format('YYYY-MM-DD'));
            }
        }
    }

    const handleEndDateChange = (date) => {
        if (date) {
            formik.setFieldValue('endDate', date.format('YYYY-MM-DD'));
            const startDate = moment(formik.values.startDate, 'YYYY-MM-DD');
            if (date.isBefore(startDate)) {
                formik.setFieldValue('startDate', date.format('YYYY-MM-DD'));
            }
        }
    }

    return !entry && !inEdit
        ? <Button variant="text" onClick={() => setInEdit(true)} className="add_entry_button">
            <AddIcon/>{t('common__add_entry_button_label')}
        </Button>
        : <SectionContainer elevation={0} radius="small" inEdit={inEdit} onEditClick={() => setInEdit(true)}>
            {
                inEdit
                ? (
                    <Grid className="credentialing_sub_section_form" container component='form' onSubmit={formik.handleSubmit} spacing={3}>
                        {
                            requestPending && <Spinner fillParent backdrop />
                        }
                        <Grid item xs={12} xl={6}>
                            <TextBox 
                                value={formik.values.employerName} 
                                label={t('previous_experience__employer_label')}
                                name="employerName" 
                                onChange={formik.handleChange}
                                className="provider_details_input"
                                error={Boolean(formik.errors.employerName)}
                            />
                        </Grid>
                        <Grid item xs={12} xl={6}>
                            <TextBox 
                                value={formik.values.jobTitleRole} 
                                label={t('previous_experience__job_title_label')}
                                name="jobTitleRole" 
                                onChange={formik.handleChange}
                                className="provider_details_input"
                                error={Boolean(formik.errors.jobTitleRole)}
                            />
                        </Grid>
                        <Grid item xs={12} xl={6}>
                            <DateInput 
                                value={moment(formik.values.startDate, "YYYY-MM-DD")}
                                label={t('previous_experience__start_date_label')}
                                className="provider_details_input"
                                onChange={handleStartDateChange}
                                // error={Boolean(formik.errors.startDate)}
                            />
                        </Grid>
                        <Grid item xs={12} xl={6}>
                            <DateInput 
                                value={moment(formik.values.endDate, "YYYY-MM-DD")}
                                label={t('previous_experience__end_date_label')}
                                className="provider_details_input"
                                onChange={handleEndDateChange}
                                // error={Boolean(formik.errors.endDate)}
                            />
                        </Grid>
                        <Grid item xs={12} container spacing={2}>
                            <Grid item>
                                <Button type="submit" variant="opaque" disabled={saveDisabled}>{t_common('save')}</Button>
                            </Grid>
                            <Grid item>
                                <Button type="button" variant="outline" onClick={handleCancelChanges}>{t_common('cancel')}</Button>
                            </Grid>
                            { 
                                entry && inEdit && ((previousExperience?.length ?? 0) > 1) &&
                                <Grid item>
                                    <Button type="button" variant="text" onClick={handleDelete} disabled={deleteDisabled}>{t_common('delete')}</Button>
                                </Grid>
                            }
                        </Grid>
                    </Grid>
                )
                : (
                    entry &&
                    <Grid container>
                        <Grid item xs={12} sm={6}>
                            <p className='global__paragraph'>{entry.employerName}</p>
                            <p className='global__paragraph--small'>{t('previous_experience__employer_label')}</p>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <p className='global__paragraph'>{entry.jobTitleRole}</p>
                            <p className='global__paragraph--small'>{t('previous_experience__job_title_label')}</p>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <p className='global__paragraph'>{entry.startDate ? formatCredentialDate(entry.startDate): t('n_a','N/A')}</p>
                            <p className='global__paragraph--small'>{t('previous_experience__start_date_label')}</p>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <p className='global__paragraph'> {entry.endDate ? formatCredentialDate(entry.endDate) : t('n_a','N/A')}</p>
                            <p className='global__paragraph--small'>{t('previous_experience__end_date_label')}</p>
                        </Grid>
                    </Grid>
                )
            }
        </SectionContainer>
}
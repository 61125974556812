import { FC, PropsWithChildren } from 'react';
import { Container, Skeleton } from '@mui/material';
import { ArrowBack as BackIcon } from '@mui/icons-material';
import { Button } from '../Inputs/Buttons';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import './CaseManagementActionContainer.scss';
interface CaseManagementActionContainerProps extends PropsWithChildren {
    onBackClick?: () => void,
    clientName?: string,
    activityId: number | string | undefined | boolean,
    headerMaxWidth?: 'xs' | 'sm' | 'md' | 'lg' | 'xl',
    contentMaxWidth?: 'xs' | 'sm' | 'md' | 'lg' | 'xl',
    className?: string
}

export const CaseManagementActionContainer: FC<CaseManagementActionContainerProps> = (props) => {
    const { children, onBackClick, clientName, activityId, headerMaxWidth = 'md', contentMaxWidth = 'xs', className } = props;

    const { t } = useTranslation('common', { useSuspense: false });

    return (
        <>
            <Container maxWidth={headerMaxWidth} className="case_management_container">
                {
                    !!onBackClick && (
                        <Button variant="text" onClick={onBackClick} startIcon={<BackIcon/>}>
                            {t('common:back')}
                        </Button>
                    )
                }
                {
                    activityId !== false &&
                    <div>
                        <span>{t('client', 'Client')}: </span>
                        {
                            clientName && activityId
                                ? <Link className="client_name_link" to={{ pathname: `/clients/${activityId}` }}>
                                    {clientName}
                                </Link>
                                : <Skeleton variant="text" width={125} className="client_name_placeholder"/>
                        }
                    </div>
                }
            </Container>
            <Container maxWidth={contentMaxWidth} className={className}>
                {children}
            </Container>
        </>
    )
}
import { gql, TypedDocumentNode } from '@apollo/client';
import { IProviderSearchScheduleSlot } from './AppointmentModals/interfaces';
import { AvailabilityItem, UnavailabilityItem, UnavailabilityReason } from './interfaces';

export const SCHEDULE_QUERY: TypedDocumentNode<ScheduleQueryData, ScheduleQueryVars> = gql`
    query SCHEDULE_QUERY($providerId: Long!) {
        schedule(providerId: $providerId) {
            addresses {
                providerAddressId
                addressLine1
                addressLine2
            }
            availabilities {
                address
                oneTime
                startDate
                endDate
                modalityCode
                scheduleId
                shifts {
                    startDay
                    endDay
                    startTime
                    endTime
                }
                reservations {
                    reservationId
                    startTime
                    endTime
                    firstTime
                    clientName
                    activityId
                    sessionId
                    clientPhoneNumber
                    clientEmail
                    providerWillContactClient
                }
            }
            unavailabilities {
                address
                oneTime
                startDate
                endDate
                seriesId
                scheduleId
                wspTaskCode
                activityId
                shifts {
                    startDay
                    endDay
                    startTime
                    endTime
                }
                unavailabilityReasonID
            }
            unavailabilityReasons {
                description
                editable
                id
            }
        }
    }
`;

export interface ScheduleQueryVars {
    providerId: number
}

export interface ScheduleQueryData {
    schedule: {
        addresses: {
            providerAddressId: number,
            addressLine1: string,
            addressLine2: string
        }[],
        availabilities: AvailabilityItem[],
        unavailabilityReasons: UnavailabilityReason[],
        unavailabilities: UnavailabilityItem[]
    }
}



export const PROVIDER_SEARCH_SCHEDULES_QUERY
    : TypedDocumentNode<ProviderSearchSchedulesQueryData, ProviderSearchSchedulesQueryVars> = gql`
    query PROVIDER_SEARCH_SCHEDULES(
        $providerId: Int!,
        $secondarySearchSchedules: String,
        $sessionId: Int!,
        $appDateRelCode: String!,
        $appTimeRelCode: String!,
        $appDate: String,
        $appTime: String,
        $officeId: Long
    ) {
        providerSearchSchedules(
            providerId: $providerId,
            secondarySearchSchedules: $secondarySearchSchedules,
            sessionId: $sessionId,
            appDateRelCode: $appDateRelCode,
            appTimeRelCode: $appTimeRelCode,
            appDate: $appDate,
            appTime: $appTime,
            officeId: $officeId
        ) {
            date,
            startDateUtc,
            clientTime,
            clientDateTime,
            dateFormatted,
            dateFormatted2,
            clientTimeFormatted,
            clientTimeFormatted2,
            duration,
            isBackToBack,
            isManagedCalendar,
            addressId,
            scheduleId
        }
    }
`;

interface ProviderSearchSchedulesQueryData {
    providerSearchSchedules: IProviderSearchScheduleSlot[]
}

interface ProviderSearchSchedulesQueryVars {
    providerId: number, 
    secondarySearchSchedules: string | null, 
    sessionId: number, 
    appDateRelCode: string, 
    appTimeRelCode: string, 
    appDate: string, 
    appTime: string, 
    officeId?: number | 'any' | null
}


export const SCHEDULE_SEARCH_DATA_QUERY: TypedDocumentNode<ScheduleSearchDataQueryData, ScheduleSearchDataQueryVars> = gql`
    query SCHEDULE_SEARCH_ACTIVITY_QUERY($activityId: Long!) {
        activity(activityId: $activityId) {
            timezoneOlsonName
            provider {
                id
                providerId
                timeZone
                contactInfo {
                    addresses {
                        providerAddressId
                        addressLine1
                        addressLine2
                        officeType
                        #addressExpiryDate
                        #addressEffectiveDate
                    }
                }
            }
            sessions {
                sessionId
                sessionStatusId
            }
        }
    }
`;

export interface ScheduleSearchDataQueryVars {
    activityId: number
}

export interface ScheduleSearchDataQueryData {
    activity: {
        timezoneOlsonName: string,
        provider: {
            id: number,
            timeZone: string,
            contactInfo: {
                addresses: any[]
            }
        },
        sessions: any[]
    }
}



export const APPT_BOOKING_REF: TypedDocumentNode<ApptBookingRefData, ApptBookingRefVars> = gql`
    query APPT_BOOKING_REF_DATA {
        refValues {
            datePreferences {
                title
                value
            }
            timePreferences {
                title
                value
            }
        }
    }
`

interface ApptBookingRefData {
    refValues: {
        datePreferences: {
            title: string,
            value: string
        }[],
        timePreferences: {
            title: string,
            value: string
        }[]
    }
}

interface ApptBookingRefVars { }


import * as Moment from 'moment';
import { extendMoment } from 'moment-range';

const moment = extendMoment(Moment);


export const inferStartAndEndTimes = (unavailabilityItem: {
    startDate?: Date | string | null,
    endDate?: Date | string | null,
    oneTime?: Boolean,
    shifts?: {
        startDay?: number,
        endDay?: number
        startTime?: string,
        endTime?: string
    }[],
}): { startTime: string, endTime: string} => {
    
    const startDate = unavailabilityItem.startDate ? moment(unavailabilityItem.startDate) : undefined;
    const endDate = unavailabilityItem.endDate ? moment(unavailabilityItem.endDate) : undefined;
    let startTime = moment();
    let endTime = moment();

    if (unavailabilityItem.oneTime) {
        startDate && startTime.set({ hours: startDate.hours(), minutes: startDate.minutes(), seconds: 0 });
        endDate && endTime.set({ hours: endDate.hours(), minutes: endDate.minutes(), seconds: 0 });
    } else if (unavailabilityItem.shifts?.length) {
        const recurrenceStart =
            unavailabilityItem.startDate || unavailabilityItem.endDate
                ? moment.parseZone(unavailabilityItem.startDate || unavailabilityItem.endDate)
                : undefined;

        const offset = recurrenceStart?.utcOffset() ?? 0;

        const tmpStart = moment(unavailabilityItem.shifts[0].startTime, 'HH:mm:ss');
            tmpStart.utcOffset(offset, true);
        const tmpEnd = moment(unavailabilityItem.shifts[0].endTime, 'HH:mm:ss');
            tmpEnd.utcOffset(offset, true);

        startTime = tmpStart;
        endTime = tmpEnd;
    } else {
        startTime.set({ hours: 12, minutes: 0, seconds: 0})
        endTime.set({ hours: 12, minutes: 0, seconds: 0})
    }
    return {
        startTime: startTime?.format('HH:mm:ss'),
        endTime: endTime?.format('HH:mm:ss')
    }
}



export const parseUavailabilityName = (name?: string) => {
    return name || 'Unspecified'
}
import { ApolloProvider as _ApolloProvider } from "@apollo/client";
import { useRef, RefObject, useEffect } from "react";
import { useApolloClient } from './useApolloClient';

export const fetchTimestampRefs: Record<string, RefObject<Record<string, number>> | null> = { refs: null };
export const cacheClearEvents: Record<string, Set<string>> = {};
export const APOLLO_CACHE_CLEAR_EVENT = "apollo-cache-clear";

export const ApolloProvider = ({ children }) => {
    const apolloClient = useApolloClient();
    fetchTimestampRefs.refs = useRef({});

    useEffect(() => {
        const cacheResetter = (e: CustomEvent) => {
            const { detail: eventName } = e;
            const isQATesting = !!window.sessionStorage.getItem("apolloCacheLife");
            if (cacheClearEvents[eventName]) {
                Array.from(cacheClearEvents[eventName])?.forEach(cacheKey => {
                    if (fetchTimestampRefs.refs?.current?.[cacheKey]) {
                        isQATesting && console.info(`🧹🧼🧽 deleting cacheKey '${cacheKey}' due to ${APOLLO_CACHE_CLEAR_EVENT} event fired on '${eventName}'`);
                        delete fetchTimestampRefs.refs.current?.[cacheKey];
                    }
                });
            }
            isQATesting && console.info("🥓🥐🥨 cacheClearEvents: ", cacheClearEvents);
        }
        window.addEventListener(APOLLO_CACHE_CLEAR_EVENT, cacheResetter as EventListener);
        return () => window.removeEventListener(APOLLO_CACHE_CLEAR_EVENT, cacheResetter as EventListener);
    }, [])

    return <_ApolloProvider client={apolloClient} children={children}/>
}
import React, { FC, PropsWithChildren, useState } from 'react';
import ArrowDownIcon from '@mui/icons-material/KeyboardArrowDownRounded';
import './ExpandableNote.scss';
import SuccessIcon from '@mui/icons-material/DoneRounded';
import ErrorIcon from '@mui/icons-material/ErrorOutline';
import InfoIcon from '@mui/icons-material/InfoOutlined';
import clsx from 'clsx';
import { Collapse, ButtonBase, Alert } from '@mui/material';

interface ExpandableNoteProps extends PropsWithChildren {
    title: string,
    severity: 'success' | 'info' | 'warning' | 'error',
    id: string
}

export const ExpandableNote: FC<ExpandableNoteProps> = (props) => {
    const [expanded, setExpanded] = useState(false);
    
    const handleClick = () => setExpanded(!expanded);
    
    const iconMap = {
        success: <SuccessIcon className="expandable_note_icon"/>,
        warning: <ErrorIcon className="expandable_note_icon"/>,
        error: <ErrorIcon className="expandable_note_icon"/>,
        info: <InfoIcon className="expandable_note_icon"/>
    }

    return (
        <Alert severity={props.severity} icon={false} className={clsx("expandable_note", expanded && 'expanded')}>
            <ButtonBase className="expandable_note_title_container"
                onClick={handleClick}
                focusRipple
                id={`note_header_${props.id}`}
                aria-controls={`note_content_${props.id}`}
                aria-expanded={expanded}
            >
                {iconMap[props.severity]}
                <div className="expandable_note_title">
                    <span>{props.title}</span>
                    <ArrowDownIcon className="expandable_note_expand_icon"/>
                </div>
            </ButtonBase>
            <Collapse in={expanded} timeout="auto">
                <div className="expandable_note_message_container" role="region" id={`note_content_${props.id}`} aria-live="polite"
                    aria-labelledby={`note_header_${props.id}`}>
                    {props.children}
                </div>
            </Collapse>
        </Alert>
    )
}
import React, { FunctionComponent, useState } from 'react';
import { SessionsList } from '../../SessionsList';
import { ClientDetailsPromptCard } from '../../../../components/ClientDetailsPromptCard';
import { Container, Alert, AlertTitle } from '@mui/material';
import { IActivityDetails } from '../../../../interfaces';
import { ActivityStatus } from 'constants/activityStatus';
import { PROVIDER_HUB } from 'constants/targetedSystem'
import './Overview.scss';
import { useRegionConfig } from 'providers/RegionConfig';
import { useTranslation } from 'react-i18next';
import { StepDefinition } from 'components/ToDos';
import { useTimezoneUtils } from 'hooks/useTimezoneUtils';
import moment, { Moment } from 'moment';
import { SESSION_APPT_STATUSES } from 'hooks/useSessionStatuses/constants';
import { IContractDetails } from 'hooks/useContractDetails/interfaces';
import { RequestsDialog } from 'components/Requests';
import { Card } from 'components/Shared/Card';

interface OverviewProps {
    activity: IActivityDetails,
    step: StepDefinition | null
    contractDetails: IContractDetails | undefined,
    onAdditionalHoursRequestCreated: () => void
}

export const Overview: FunctionComponent<OverviewProps> = (props) => {
    const { activity, step, contractDetails, onAdditionalHoursRequestCreated } = props;
    const { providerContactUrl } = useRegionConfig();
    const isProviderHubActivity = activity?.targetedSystem?.systemCode === PROVIDER_HUB;
    const { t } = useTranslation('activityDetails', { useSuspense: false });
    const { formatToProviderDay } = useTimezoneUtils();

    const [additionalHoursDialogOpen, setAdditionalHoursDialogOpen] = useState(false);

    const renderPromptCard = () => {
        const session = step === StepDefinition.UPDATE_APPT_STATUS
            ? activity?.sessions?.find(s => s.sessionStatusId.toString() === SESSION_APPT_STATUSES.ACTIVE)
            : undefined;
            
        return (
            <Container maxWidth="lg">
                <Card elevation={1} className="overview_prompt_container">
                    <ClientDetailsPromptCard activity={activity} step={step} sessionId={session?.sessionId}/>
                </Card>
            </Container>
        )
    }

    const renderWarningHeader = () => (
        <Alert severity="info" className="activity_overview_warning" aria-live="polite">
            {t('warning_message')} <a target="_blank" rel="noopener noreferrer" href={`${providerContactUrl}/Account/Login`}><b>{t('provider_contact')}</b></a>
        </Alert>
    )

    const renderSessionsList = () => (
        <Container maxWidth="lg" className="sessions_table_container">
            <SessionsList activity={activity} contractDetails={contractDetails}
                onCreateAdditionalHoursRequestClick={() => setAdditionalHoursDialogOpen(true)}
            />
        </Container>
    )

    const renderCaseClosedNote = () => {
        let caseAvailableUntil: Moment | undefined = undefined;
        if (activity?.activityClosedTime) {
            caseAvailableUntil = 
                activity.timezoneOlsonName
                    ? moment.tz(activity.activityClosedTime, activity.timezoneOlsonName)
                    : moment(activity.activityClosedTime);
                caseAvailableUntil.add(30, 'days');
        }
        const message = caseAvailableUntil
            ? t('case_closed_note', { replace: { date: formatToProviderDay(caseAvailableUntil) }})
            : t('case_closed_note_30_days')
        return (
            <Alert severity="info" className="activity_overview_warning" aria-live="polite">
                <AlertTitle>{t('case_closed_note_title')}</AlertTitle>
                <span dangerouslySetInnerHTML={{__html: message}}/>
            </Alert>
        )
    }

    return <>
        <div className="activity_details_overview_container">
            {
                isProviderHubActivity
                ? <>
                    {
                        activity?.activityStatusCode === ActivityStatus.Closed && renderCaseClosedNote()
                    }
                    {
                        renderPromptCard()
                    }
                </>
                : renderWarningHeader()
            }
            {renderSessionsList()}
        </div>
    
        <RequestsDialog open={additionalHoursDialogOpen} onClose={() => setAdditionalHoursDialogOpen(false)} activityId={activity.activityId}
            providerId={activity.providerId} onRequestCreated={onAdditionalHoursRequestCreated}
        />
    </>
}
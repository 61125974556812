import React, { FC } from "react";
import { FormikErrors, useFormik } from 'formik';
import { Alert, Container, FormControl, FormControlLabel, FormLabel, Radio, RadioGroup } from "@mui/material";
import { Button } from 'components/Shared/Inputs/Buttons';
import './style.scss';
import { useTranslation } from "react-i18next";

interface SBQ_Props {
    onSubmit: (values: SBQ_Result) => void,
    result: SBQ_FormValues | null
}

interface SBQ_FormValues {
    have_you_ever_thought_about_or_attempted_killing_self: number | undefined,
    how_often_thought_about_killing_yourself: number | undefined,
    ever_told_someone_about_suicide: number | undefined,
    will_you_attempt_suicide: number | undefined    
}

interface SBQ_Result extends SBQ_FormValues {
    score: number
}

export const SBQ: FC<SBQ_Props> = (props) => {
    const { result, onSubmit } = props;
    
    const { t, ready } = useTranslation('forms', { useSuspense: false });

    const calculateScore = (values: SBQ_FormValues) => (
        Object.keys(values).reduce((score, question) => {
            var str = values[question] ?? '0';
            score += parseInt(str.toString());             
            return score;
        }, 0)
    )
    
    const formik = useFormik<SBQ_FormValues>({
        initialValues: {
            have_you_ever_thought_about_or_attempted_killing_self: result?.have_you_ever_thought_about_or_attempted_killing_self,
            how_often_thought_about_killing_yourself: result?.how_often_thought_about_killing_yourself,
            ever_told_someone_about_suicide: result?.ever_told_someone_about_suicide,
            will_you_attempt_suicide: result?.will_you_attempt_suicide,
        },
        validate: (values) => {
            const errors: FormikErrors<SBQ_FormValues> = {};

            for (const key in values) {
                if (values[key] === undefined) {
                    errors[key] = 'Select answer'
                }
            }

            return errors;
        },
        validateOnMount: true,
        validateOnChange: true,
        onSubmit: (values) => {
            onSubmit({
                ...values,
                score: calculateScore(values)
            });
        }
    });

    const handleChange = (event, value) => {
        //formik.setFieldValue(event.target.name, Number(value));
        formik.setFieldValue(event.target.name, parseInt(value));
    }

    const score = !formik.isValid
        ? undefined
        : calculateScore(formik.values);

    return !ready
        ? <></>
        : <Container maxWidth="md" component="form" onSubmit={formik.handleSubmit} className="sbq_form">
            <h1 className="assessment_name">{t('SBQ__title')}</h1>
            <div className="description_container">
            {t('SBQ__instruction')}
            </div>
            <p></p>
            <div className="questions_list_container">
                <FormControl component="fieldset" className="question_fieldset">
                    <FormLabel component="legend" className="question_text">1. {t('SBQ__question__1__question')}</FormLabel>
                    <RadioGroup name="have_you_ever_thought_about_or_attempted_killing_self" className="question_radiogroup"
                        value={formik.values.have_you_ever_thought_about_or_attempted_killing_self?.toString()} onChange={handleChange}>
                        <FormControlLabel value="1" control={<Radio color="primary"/>} label={t('SBQ__question__1__never') as string}/>
                        <FormControlLabel value="2" control={<Radio color="primary"/>} label={t('SBQ__question__1__briefly') as string}/>
                        <FormControlLabel value="3a" control={<Radio color="primary"/>} label={t('SBQ__question__1__plan_once') as string}/>
                        <FormControlLabel value="3b" control={<Radio color="primary"/>} label={t('SBQ__question__1__really_wanted_to') as string}/>
                        <FormControlLabel value="4a" control={<Radio color="primary"/>} label={t('SBQ__question__1__attempted_but_didnt_want_to_die') as string}/>
                        <FormControlLabel value="4b" control={<Radio color="primary"/>} label={t('SBQ__question__1__attempted_and_wanted_to_die') as string}/>
                    </RadioGroup>
                </FormControl>
                <FormControl component="fieldset" className="question_fieldset">
                    <FormLabel component="legend" className="question_text">2. {t('SBQ__question__2__question')}</FormLabel>
                    <RadioGroup name="how_often_thought_about_killing_yourself" className="question_radiogroup"
                        value={formik.values.how_often_thought_about_killing_yourself?.toString()} onChange={handleChange}>
                        <FormControlLabel value="1" control={<Radio color="primary"/>} label={t('SBQ__question__2__never') as string} />
                        <FormControlLabel value="2" control={<Radio color="primary"/>} label={t('SBQ__question__2__rarely') as string} />
                        <FormControlLabel value="3" control={<Radio color="primary"/>} label={t('SBQ__question__2__sometimes') as string} />
                        <FormControlLabel value="4" control={<Radio color="primary"/>} label={t('SBQ__question__2__often') as string} />
                        <FormControlLabel value="5" control={<Radio color="primary"/>} label={t('SBQ__question__2__veryoften') as string} />
                    </RadioGroup>
                </FormControl>
                <FormControl component="fieldset" className="question_fieldset">
                    <FormLabel component="legend" className="question_text">3. {t('SBQ__question__3__question')}</FormLabel>
                    <RadioGroup name="ever_told_someone_about_suicide" className="question_radiogroup"
                        value={formik.values.ever_told_someone_about_suicide?.toString()} onChange={handleChange}>
                        <FormControlLabel value="1" control={<Radio color="primary"/>} label={t('SBQ__question__3__no') as string}/>
                        <FormControlLabel value="2a" control={<Radio color="primary"/>} label={t('SBQ__question__3__yes_but_did_not_really_want_to_die') as string}/>
                        <FormControlLabel value="2b" control={<Radio color="primary"/>} label={t('SBQ__question__3__yes_and_really_wanted_to_die') as string}/>
                        <FormControlLabel value="3a" control={<Radio color="primary"/>} label={t('SBQ__question__3__yes_more_than_once_but_did_not_want_to_do_it') as string}/>
                        <FormControlLabel value="3b" control={<Radio color="primary"/>} label={t('SBQ__question__3__yes_more_than_once_and_wanted_to_do_it') as string}/>
                    </RadioGroup>
                </FormControl>
                <FormControl component="fieldset" className="question_fieldset">
                    <FormLabel component="legend" className="question_text">4. {t('SBQ__question__4__question')}</FormLabel>
                    <RadioGroup name="will_you_attempt_suicide" className="question_radiogroup"
                        value={formik.values.ever_told_someone_about_suicide?.toString()} onChange={handleChange}>
                        <FormControlLabel value="0" control={<Radio color="primary"/>} label={t('SBQ__question__4__never') as string}/>
                        <FormControlLabel value="1" control={<Radio color="primary"/>} label={t('SBQ__question__4__no_chance') as string}/>
                        <FormControlLabel value="2" control={<Radio color="primary"/>} label={t('SBQ__question__4__rather_unlikely') as string}/>
                        <FormControlLabel value="3" control={<Radio color="primary"/>} label={t('SBQ__question__4__unlikely') as string}/>
                        <FormControlLabel value="4" control={<Radio color="primary"/>} label={t('SBQ__question__4__likely') as string}/>
                        <FormControlLabel value="5" control={<Radio color="primary"/>} label={t('SBQ__question__4__rather_likely') as string}/>
                        <FormControlLabel value="6" control={<Radio color="primary"/>} label={t('SBQ__question__4__very_likely') as string}/>
                    </RadioGroup>
                </FormControl>
            </div>
            <div>
                <p>
                {t('SBQ__scoring__heading')}
                {t('SBQ__scoring__explanation')}
                </p>
                <p>
                {t('SBQ__scoring__interpretation_scores_heading')}
                {t('SBQ__scoring__interpretation_scores_explanation')}
                </p>
            </div>

            {/*{t('SBQ__scoring__score_description')} {calculateScore(formik.values)} */}            
            {
                score !== undefined && (
                    <Alert severity="info" className="summary">
                        {t('SBQ__scoring__score_description')} {calculateScore(formik.values)}
                    </Alert>
                )
            }

            <div className="buttons_container">
                <Button type="submit" disabled={!formik.isValid}>
                    {t('common:submit')}
                </Button>
            </div>
        </Container>
}
import React, { FC, PropsWithChildren, useEffect } from 'react';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { useAuth } from 'components/Auth';
import moment from 'moment-timezone';
import { useTranslation } from 'react-i18next';

import 'moment/locale/fr-ca';
import 'moment/locale/en-ca';
import 'moment/locale/en-gb';
import 'moment/locale/en-au';

export const MomentProvider: FC<PropsWithChildren> = (props) => {
    const { effectiveProviderData } = useAuth();
    const { i18n } = useTranslation();

    const providerTimezone = effectiveProviderData?.timeZone ?? '';

    useEffect(() => {
        moment.tz.setDefault(providerTimezone);
    }, [providerTimezone]);

    useEffect(() => {
        moment.locale(i18n.language);
    }, [i18n.language]);

    return (
        <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale={i18n.language}>
            {props.children}
        </LocalizationProvider>
    )
};

import { TemplateNames, WspTemplateNames } from 'components/Forms/constants';
import React, { FC } from 'react';
import { useParams } from 'react-router-dom';
import { FormRoutesReverseMap, AssessmentNames } from '../../constants';
import { FollowUpView, SessionNoteView, FirstAppointmentAssessmentView,
    LastAppointmentAssessmentView, FormByTemplateView, FormByIdView } from '../index';

interface FormsRouterProps {}

interface FormsRouterViewRouteProps extends Record<string, string | undefined>{
    activityId: string | undefined,
    sessionId: string | undefined,
    formId: string
}

export const FormsRouter: FC<FormsRouterProps> = () => {
    const { activityId: activityIdString, sessionId: sessionIdString, formId = '' } = useParams<FormsRouterViewRouteProps>();   

    const activityId = activityIdString ? parseInt(activityIdString) : undefined;
    const sessionId = sessionIdString ? parseInt(sessionIdString) : undefined;
    const templateName: TemplateNames | WspTemplateNames | AssessmentNames | undefined = FormRoutesReverseMap[formId];

    switch (templateName) {
        case undefined:
            return <FormByIdView formId={formId}/>;
        case AssessmentNames.FirstAppointmentAssessment:
            return <FirstAppointmentAssessmentView/>;
        case AssessmentNames.LastAppointmentAssessment:
            return <LastAppointmentAssessmentView/>;
        case TemplateNames.FollowUp:
            return <FollowUpView/>;
        case TemplateNames.SessionNote:
        case TemplateNames.SessionNote_WRP:
            return <SessionNoteView/>;
        default: {
            return activityId
                ? <FormByTemplateView activityId={activityId} sessionId={sessionId} templateName={templateName}/>
                : <></>;
        }
    }
};

import React, { FC, useEffect, useMemo, useState } from "react";
import { FormikErrors, useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import { ProfessionalDesignation, ProfessionalDesignationInput } from '../../../interfaces';
import { Grid, MenuItem } from "@mui/material";
import { Button, Select } from 'components/Shared/Inputs';
import { Spinner } from "components/Shared";
import { MutationTuple, useLazyQuery } from "@apollo/client";
import { UpdateProfessionalDesignationMutationData, UpdateProfessionalDesignationMutationVars, PROFESSIONAL_DESIGNATIONS_REF_DATA } from "./queries";
import { confirm } from 'components/Shared/ConfirmDialog';
import AddIcon from '@mui/icons-material/Add';
import { SectionContainer } from '../../Other/SectionContainer';

const mapDesignationToDesignationInput = (designation: ProfessionalDesignation): ProfessionalDesignationInput => ({
    professionalDesignationId: designation.professionalDesignationId,
    providerProfessionalDesignationId: designation.providerProfessionalDesignationId
})

interface ProfessionalDesignationFormProps {
    providerId: number,
    data?: ProfessionalDesignation[],
    entry?: ProfessionalDesignation,
    mutation: MutationTuple<UpdateProfessionalDesignationMutationData, UpdateProfessionalDesignationMutationVars>,
    cachePendingUpdate: boolean
}

interface FormValues {
    professionalDesignationId: number | null,
    providerProfessionalDesignationId: number | null
}

export const ProfessionalDesignationForm: FC<ProfessionalDesignationFormProps> = (props) => {
    const { providerId, entry, data, mutation, cachePendingUpdate } = props;

    const { t } = useTranslation('providerprofile', { useSuspense: false });
    const { t: t_common } = useTranslation('common', { useSuspense: false });

    const [requestPending, setRequestPending] = useState(false);
    
    const [inEdit, setInEdit] = useState(false);

    const [getRefData, refDataQuery] = useLazyQuery(PROFESSIONAL_DESIGNATIONS_REF_DATA);

    useEffect(() => {
        if (inEdit && !refDataQuery.called) {
            getRefData();
        }
    }, [inEdit]);

    const [update, updateMutation] = mutation;

    const formik = useFormik<FormValues>({
        initialValues: {
            providerProfessionalDesignationId: entry?.providerProfessionalDesignationId ?? null,
            professionalDesignationId: entry?.professionalDesignationId ?? null
        },
        validate: (values) => {
            const errors: FormikErrors<FormValues> = {};

            if (!values.professionalDesignationId) {
                errors.professionalDesignationId = "Desigantiona cannot be empty";
            }

            return errors;
        },
        validateOnBlur: true,
        validateOnChange: true,
        onSubmit: async (values) => {
            formik.validateForm();

            if (formik.isValid) {
                const dialogProps = {
                    message: <>
                        <p>{t('common__confirm_changes_dialog_text')}</p>
                        <ul>
                        {
                            (!entry || values.professionalDesignationId !== entry.professionalDesignationId) &&
                            <li>{t('professional_designation__designation_label')}: <b>{professionalDesignations?.find(e => e.value === values.professionalDesignationId)?.title}</b></li>
                        }
                        </ul>
                    </>
                }
                if (await confirm(dialogProps)) {
                    setRequestPending(true);
                    const updatedData = [
                        ...(data?.filter(e => e !== entry) || []).map(mapDesignationToDesignationInput),
                        {
                            providerProfessionalDesignationId: values.providerProfessionalDesignationId,
                            professionalDesignationId: values.professionalDesignationId!
                        }
                    ];
                    update({
                        variables: {
                            providerId: providerId,
                            professionalDesignation: updatedData
                        }
                    }).then(() => { setInEdit(false); formik.resetForm(); }).finally(() => setRequestPending(false));
                }
            }
        }
    });

    const handleDelete = async () => {
        const dialogProps = {
            title: t('common__delete_entry_dialog_title'),
            message: t('common__delete_entry_dialog_text')
        }
        if (await confirm(dialogProps)) {
            setRequestPending(true);
            update({
                variables: {
                    providerId: providerId,
                    professionalDesignation: [
                        ...(data?.filter(e => e.providerProfessionalDesignationId !== entry?.providerProfessionalDesignationId) || []).map(mapDesignationToDesignationInput)
                    ]
                }
            }).finally(() => setRequestPending(false))
        }
    }

    const hasChanges = formik.dirty 
        && (formik.values.professionalDesignationId !== entry?.professionalDesignationId);

    const deleteDisabled = updateMutation.loading || cachePendingUpdate ;
    const saveDisabled = deleteDisabled || !hasChanges || !formik.isValid;

    const handleCancelChanges = async () => {
        if (hasChanges) {
            const dialogProps = {
                title: t('common__discard_changes_dialog_title'),
                message: t('common__discard_changes_dialog_text')
            }
            if (await confirm(dialogProps)) {
                formik.resetForm();
                setInEdit(false);
            }
        } else {
            formik.resetForm();
            setInEdit(false);
        }
    }

    const professionalDesignations = useMemo(() =>
        refDataQuery.data?.professionalDesignations?.map(designation => ({title: designation.title, value: Number(designation.value)}))
    , [refDataQuery.data?.professionalDesignations]);


    return !entry && !inEdit
        ? <Button variant="text" onClick={() => setInEdit(true)} className="add_entry_button">
            <AddIcon/>{t('professional_designation__add_entry_button_label')}
        </Button>
        : <SectionContainer elevation={0} radius="small" inEdit={inEdit} onEditClick={() => setInEdit(true)}>
            {
                inEdit
                ? (
                    <Grid className="credentialing_sub_section_form" container component='form' onSubmit={formik.handleSubmit} spacing={3}>
                        {
                            (requestPending || cachePendingUpdate) &&
                            <Spinner fillParent backdrop />
                        }
                        <Grid item xs={12} xl={6}>
                            <Select
                                value={formik.values.professionalDesignationId ?? ''} 
                                label={t('professional_designation__designation_label')}
                                name="professionalDesignationId" 
                                onChange={formik.handleChange}
                                className="provider_details_input"
                                error={Boolean(formik.errors.professionalDesignationId)}
                            >
                                {
                                    professionalDesignations?.map(pd => (
                                        <MenuItem value={pd.value} key={pd.value}>
                                            {pd.title}
                                        </MenuItem>
                                    ))
                                }
                            </Select>
                        </Grid>
                        <Grid item xs={12} container spacing={2}>
                            <Grid item>
                                <Button type="submit" variant="opaque" disabled={saveDisabled}>{t_common('save')}</Button>
                            </Grid>
                            <Grid item>
                                <Button type="button" variant="outline" onClick={handleCancelChanges}>{t_common('cancel')}</Button>
                            </Grid>
                            { 
                                entry && inEdit && ((data?.length ?? 0) > 1) &&
                                <Grid item>
                                    <Button type="button" variant="text" onClick={handleDelete} disabled={deleteDisabled}>{t_common('delete')}</Button>
                                </Grid>
                            }
                        </Grid>
                    </Grid>
                )
                : (
                    entry &&
                    <Grid container>
                        <Grid item xs={12} sm={6}>
                            <p className='global__paragraph'>{entry.specialization}</p>
                            <p className='global__paragraph--small'>{t('professional_designation__designation_label')}</p>
                        </Grid>
                    </Grid>
                )
            }
        </SectionContainer>
}
export enum ActivitySolutionCodes {
    ECS = 'ECS',
    EAP = 'EAP'
}

export interface IActivityClientData {
    client: {
        firstName: string,
        lastName: string
    }
    additionalParticipants: {
        id: number | string
        firstName: string
        lastName: string
    }[]
    activityId: string | number
    activityStatusCode: number
    solutionCode?: ActivitySolutionCodes;
    targetedSystem: {
        systemCode: string;
    };
}

export interface IClientValueSummary extends IActivityClientData {
    label: string;
    id: number | string;
}

export interface IActivities {
    activities: IActivityClientData[]
}


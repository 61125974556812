import React, { FC } from "react";
import { ScheduleSlot } from "../../../interfaces";
import { useTranslation } from 'react-i18next';
import { Moment } from "moment-timezone";

interface ScheduleSlotsListProps {
    groupedSlots: { date: Moment, slots: ScheduleSlot[] }[] | undefined,
    selectedSlot: ScheduleSlot | null,
    onSlotSelected: (slot: ScheduleSlot) => void,
    alternateTimezone?: string
}

export const ScheduleSlotsList: FC<ScheduleSlotsListProps> = (props) => {
    const { groupedSlots, selectedSlot, onSlotSelected, alternateTimezone } = props;
    const { t } = useTranslation('calendaring', { useSuspense: false });


    return <>
    {
        groupedSlots?.map((date, index) => (
            <div className="day-time-block" key={index} >
                <h3 className="month">{date.date.format('dddd, MMMM Do')}</h3>
                {
                    date.slots.map((slot, index) => {
                        const clientDate = alternateTimezone ? slot.startDate.clone().tz(alternateTimezone) : undefined;
                        return (
                            <span
                                key={index}
                                className={`time-block${slot.startDate.toISOString() === selectedSlot?.startDate.toISOString() ? ' selected': ''}`}
                                onClick={() => onSlotSelected(slot)}
                                title={!!alternateTimezone ? `${clientDate?.format('LT')} (${clientDate?.format('z')})` : undefined}
                            >
                                {slot.startDate.format('LT')} {slot.isBackToBack ?  t('back_to_back') : null}
                            </span>
                        )
                    })
                }
            </div>
        ))
    }
    </>
}
import React from 'react';
import { LogoSmall } from 'components/Shared/Logo';
import { IconButton } from '@mui/material';
import { IconUser } from 'components/Shared/Icons';
import DrawerIcon from '@mui/icons-material/MenuRounded';

import './MobileToolbar.scss';
import { useState } from 'react';
import { ProfileMenu } from '../ProfileMenu';
import { useAuth } from 'components/Auth';

interface MobileToolbarProps {
    onMenuClick: () => void
}

export const MobileToolbar: React.FC<MobileToolbarProps> = (props) => {
    const [profileMenuAnchorEl, setProfileMenuAnchorEl] = useState<HTMLElement | null>(null);
    const { authenticated } = useAuth();

    const handleProfileClick = (event) => {
        setProfileMenuAnchorEl(event.currentTarget);
    }

    const handleProfileMenuClose = () => {
        setProfileMenuAnchorEl(null);
    };

    const renderDrawerButton = () => (
        <IconButton aria-label="menu" onClick={props.onMenuClick}>
            <DrawerIcon />
        </IconButton>
    );

    const renderProfileButton = () => (
        <IconButton aria-label="menu" onClick={handleProfileClick}>
            <IconUser />
        </IconButton>
    );

    return (
        <>
            <div className="mobile_toolbar">
                {renderDrawerButton()}
                <LogoSmall className="mobile_toolbar_logos"/>
                {authenticated && renderProfileButton()}
            </div>
            {
                authenticated &&
                <ProfileMenu anchorEl={profileMenuAnchorEl} onClose={handleProfileMenuClose}/>
            }
        </>
    )
}

import React, { FC } from 'react';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import { Address } from '../../../interfaces';
import { Button, TextBox } from 'components/Shared/Inputs';
import { Card } from 'components/Shared/Card';

interface AddressFormProps {
    address: Address
}

export const AddressForm: FC<AddressFormProps> = (props) => {
    const { address } = props;

    const { t, ready: translationsReady } = useTranslation(['providerprofile', 'common'], { useSuspense: false });

    const formik = useFormik({
        initialValues: {
            addressLine1: address?.addressLine1,
            addressLine2: address?.addressLine2,
            countryCode: address?.countryCode,
            countryName: address?.countryName,
            zIP_Postal: address?.zIP_Postal,
            state_ProvinceCode: address?.state_ProvinceCode,
            inEdit: false
        },
        onSubmit: (values) => {
            console.log(values);
        }
    });

    return !translationsReady
        ? <></>
        : <Card elevation={0} radius="small" className='contact_info_container'>
            {
                formik.values.inEdit
                    ? <form onSubmit={formik.handleSubmit}>
                        <TextBox label="Address line 1" value={formik.values.addressLine1} name="addressLine1" onChange={formik.handleChange}/>
                        <TextBox label="Address line 2 (optional)" value={formik.values.addressLine2} name="addressLine2" onChange={formik.handleChange}/>
                        <Button type="submit" variant="opaque">{t('common:save')}</Button>
                        <Button type="button" variant="text" onClick={() => formik.resetForm()}>{t('common:cancel')}</Button>
                    </form>
                    : <>
                        <p className='contact_info_contact'>{address.addressLine1} {address.addressLine2}</p>
                        <p className='contact_info_contact'>{address.zIP_Postal}</p>
                        <p className='contact_info_contact'>{address.state_ProvinceCode} {address.countryName}</p>
                        <p className='contact_info_contact_details'>{address.officeTypeString}</p>
                    </>
            }
        </Card>
}
import React, { FC, useEffect, useState } from 'react';
import { Chip, Dialog, DialogContent, InputAdornment, IconButton, TextField, Autocomplete, AutocompleteRenderInputParams } from '@mui/material';
import { Button } from 'components/Shared/Inputs';
import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';
import { useTranslation } from 'react-i18next';
import { Option } from './interfaces';

interface PickerDialogProps {
    options?: Option[],
    value?: Option[],
    onSubmit: (value: Option[]) => void,
    onCancel: () => void,
    open: boolean,
    labels: {
        dialogTitle: string,
        helperText: string,
        inputPlaceholder: string
    }
}

export const PickerDialog: FC<PickerDialogProps> = (props) => {
    const { options, value, onSubmit, onCancel, open, labels } = props;

    const { t } = useTranslation('common', { useSuspense: false });

    const [selected, setSelected] = useState<Option[]>([]);

    useEffect(() => {
        if (open) {
            setSelected([]);
            setSearchInput('');
        }
    }, [open]);

    const handleOptionSelected = (_, value) => {
        setSearchInput('');
        value && setSelected([...selected, value].sort((A, B) => A.value <= B.value ? -1 : 1));
    }

    const handleSelectionRemoved = (option: Option) => {
        setSelected(selected.filter(s => s !== option));
    }

    const filteredOptions = options
        ?.filter(option => !value?.some(o => o.value === option.value))
        ?.filter(option => !selected.some(o => o.value === option.value));

    const [searchInput, setSearchInput] = useState('');

    const handleClearInput = () => setSearchInput('');

    const renderInput = (params: AutocompleteRenderInputParams) => (
        <TextField
            {...params}
            variant="outlined"
            helperText={labels.helperText}
            InputProps={{
                ref: params.InputProps.ref,
                startAdornment: <InputAdornment position="start" children={<SearchIcon />}/>,
                endAdornment: searchInput
                    ? <InputAdornment position="end">
                        <IconButton aria-label={t("clear_input")} onClick={handleClearInput}><CloseIcon/></IconButton>
                    </InputAdornment>
                    : <span className="insurance_panels_combobox_fake_placeholder" aria-hidden>
                        {labels.inputPlaceholder}
                    </span>
            }}
        />
    )

    const handleSubmit = () => onSubmit?.(selected);

    return (
        <Dialog open={open} className="confirm_dialog">
            <DialogContent>
                <div className="confirm_dialog_header">
                    <h2 className="confirm_dialog_title">{labels.dialogTitle}</h2>
                    <IconButton className="confirm_dialog_close_button" onClick={() => onCancel}><CloseIcon /></IconButton>
                </div>
                <div className="confirm_dialog_content">
                    <Autocomplete
                        className="insurance_panels_combobox"
                        options={filteredOptions ?? []}
                        getOptionLabel={(option) => option.title}
                        style={{ width: 300 }}
                        inputValue={searchInput}
                        onInputChange={(_, value) => setSearchInput(value)}
                        value={null}
                        onChange={handleOptionSelected}
                        renderInput={renderInput}
                    />
                    {
                        selected.map(option => <Chip key={option.value} label={option.title} onDelete={() => handleSelectionRemoved(option)}/>)
                    }
                </div>
                <div className="confirm_dialog_actions">
                    <Button variant='text' onClick={onCancel} autoFocus>{t('cancel')}</Button>
                    <Button variant='opaque' onClick={handleSubmit}>{t('add')}</Button>
                </div>
            </DialogContent>
        </Dialog>
    )
}
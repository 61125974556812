import React, { FC, useState } from 'react';
import { Alert } from '@mui/material';
import { useFormik } from 'formik';
import { useMutation } from '@apollo/client';
import { ResetPasswordMutationVars, RESET_PASSWORD_MUTATION } from '../queries';
import { Link } from 'react-router-dom';
import { Spinner, ShowPasswordCheckbox } from 'components/Shared';
import { useTranslation } from 'react-i18next';
import { Button, TextBox } from 'components/Shared/Inputs';


interface ResetPasswordFormProps {
    token: string | undefined
}

export const ResetPasswordForm: FC<ResetPasswordFormProps> = ({token}) => {
    const { t } = useTranslation(['login', 'common'], { useSuspense: false });
    const [loading, setLoading] = useState<boolean>(false);
    const [passwordSaved, updatePasswordSaved] = useState(false);

    const [resetPassword] = useMutation<{}, ResetPasswordMutationVars>(RESET_PASSWORD_MUTATION, {
        onCompleted: () => {
            updatePasswordSaved(true);
        },
        onError: (error) => {
            if (error.graphQLErrors && error.graphQLErrors[0]) {
                formik.setFieldError('graphQL', error.graphQLErrors[0].message);
            } else {
                formik.setFieldError('graphQL', error.message);
            }
        }
    });

    const formik = useFormik({
        initialValues: {
            passwordA: '',
            passwordB: '',
            showPasswordA: false,
            showPasswordB: false,
            graphQL: ''
        },
        validate: (values) => { 
            const errors: any = {};

            if (values.passwordA !== values.passwordB) {
                errors.passwordB = t('common:passwords_not_match', 'Passwords do not match');
            }
            if (!/^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[*.!@#$%^&(){}[\]:\\;<>,.?/~_+\-=|]).{12,128}$/.test(values.passwordA)) {
                errors.passwordA = t('common:password_not_compliant', 'Password does not fit requirements');
            }

            return errors;
        },
        validateOnBlur: true,
        onSubmit: (values) => {
            formik.setTouched({passwordA: true, passwordB: true});
            formik.validateForm();

            if (formik.isValid && token) {
                setLoading(true);
                resetPassword({variables: { password: values.passwordA, token: token}})
                    .finally(() => setLoading(false));
            }
        }
    });

    const getErrorMessage = (): string | null => {
        if (formik.touched.passwordA && formik.touched.passwordB && formik.errors.passwordB) {
            return formik.errors.passwordB;
        }
        if (formik.touched.passwordA && formik.errors.passwordA) {
            return formik.errors.passwordA;
        }

        return null;
    }
    
    return (
        <form onSubmit={formik.handleSubmit} className="login_screen_form">
        {
            !passwordSaved
            ? <React.Fragment>
                <h2 className="login_screen_form_title">{t('login:change_password_form_header', 'Change your password.')}</h2>
                <p className="login_screen_form_text" style={{margin: 0}}>
                    {t('login:password_requirements',
                        'Password should be between 12 to 128 characters long, contain at least one lowercase and one uppercase letter, one digit and one symbol/special character.')}
                </p>
                <TextBox 
                    onChange={formik.handleChange}
                    value={formik.values.passwordA}
                    onBlur={formik.handleBlur}
                    error={formik.touched.passwordA && !!formik.errors.passwordA}
                    name="passwordA"
                    className="login_screen_form_input password_input"
                    label={t('login:new_password', 'New password')}
                    type={formik.values.showPasswordA ? "text" : "password"}
                    id='new_password_input_field'
                    InputProps={{
                        endAdornment: <ShowPasswordCheckbox name="showPasswordA" onChange={formik.handleChange} checked={formik.values.showPasswordA}
                            inputProps={{'aria-label': t('common:show_password'), 'aria-controls': "new_password_input_field"}}/>
                    }}
                />
                <TextBox 
                    onChange={formik.handleChange}
                    value={formik.values.passwordB}
                    onBlur={formik.handleBlur}
                    error={formik.touched.passwordB && !!formik.errors.passwordB}
                    name="passwordB"
                    className="login_screen_form_input password_input"
                    label={t('login:confirm_new_password', 'Confirm new password')}
                    type={formik.values.showPasswordB ? "text" : "password"}
                    id='confirm_new_password_input_field'
                    InputProps={{
                        endAdornment: <ShowPasswordCheckbox name="showPasswordB" onChange={formik.handleChange} checked={formik.values.showPasswordB}
                            inputProps={{'aria-label': t('common:show_password'), 'aria-controls': "confirm_new_password_input_field"}}/>
                    }}
                />
                {
                    getErrorMessage() &&
                    <Alert severity="error" className="login_screen_alert">{getErrorMessage()}</Alert>
                }
                <div className="filler" />
                {
                    loading
                        ? <Spinner />
                        : <Button variant="opaque" type="submit" className="login_screen_form_submit_button">
                            {t('login:save_password', 'Save Password')}
                        </Button>
                }
            </React.Fragment>
            : <React.Fragment>
                <h2 className="login_screen_form_title">
                    {t('login:password_changed_header', 'Password changed.')}
                </h2>
                <p className="login_screen_form_text">
                    {t('login:change_password_form_success_message',
                        'Your password has been changed. You can now login to portal using new password.')}
                </p>
                <div className="filler" />
                <Button variant="opaque" component={Link} to="/login" replace className="login_screen_form_submit_button">
                    {t('login:go_to_login', 'Go to login')}
                </Button>
            </React.Fragment>
        }
        </form>
    )
}
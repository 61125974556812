import React, { useState, FC } from 'react';
import { Popover } from '@mui/material';
import { Link } from 'react-router-dom';
import { useAuth, SelectEffectiveProviderDialog } from 'components/Auth';
import { useTranslation } from 'react-i18next';
import { Button } from 'components/Shared/Inputs';

interface ProfileMenuProps {
    anchorEl: HTMLElement | null,
    onClose: () => void
}
export const ProfileMenu: FC<ProfileMenuProps> = (props) => {
    const { anchorEl, onClose } = props;
    const { t } = useTranslation('common', { useSuspense: false });
    const { providerData, effectiveProviderData } = useAuth();

    const [selectProviderDialogOpen, setSelectProviderDialogOpen] = useState(false);

    const paperProps = {
        square: true
    }

    const onSelectAnotherProviderClick = () => {
        setSelectProviderDialogOpen(true);
    }
    const initials = (effectiveProviderData?.firstName.slice(0, 1) ?? '') + (effectiveProviderData?.lastName.slice(0, 1) ?? '');

    return <>
        <Popover
            anchorEl={anchorEl}
            onClose={onClose}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right'
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'right'
            }}
            open={Boolean(anchorEl)}
            PaperProps={paperProps}
            className="profile_popover_modal"
            elevation={0}
        >
            <div className='provider_profile_popover'>
                <div className="avatar_div">
                    {initials}
                </div>
                <div className="provider_profile_info_div">
                    {
                        effectiveProviderData?.associateWithVendor && effectiveProviderData?.vendor &&
                        <div className="provider_profile_info">
                            <h2 className='provider_profile_popover_header'>{effectiveProviderData.vendor.name}</h2>
                            <Link className='provider_profile_popover_link' onClick={onClose} to={{ pathname: '/profile/group_practice' }}>{t('view_practice_details','View practice details')}</Link>
                        </div>
                    }
                    <div className="provider_profile_info">
                        <h2 className='provider_profile_popover_header'>{`${effectiveProviderData?.firstName} ${effectiveProviderData?.lastName}`}</h2>
                        <p className='provider_profile_popover_body'>{effectiveProviderData?.providerId}</p>
                        <Button variant="outline" component={Link} onClick={onClose} to={{ pathname: `/profile` }}>
                            {t('view_details','View Details')}
                        </Button>
                    </div>
                    {
                        (providerData?.providerId !== effectiveProviderData?.providerId && providerData?.providerId !== 0) &&
                        <div className="provider_profile_info">
                            <h2 className='provider_profile_popover_header'>{`${providerData?.firstName} ${providerData?.lastName} (You)`}</h2>
                            <p className='provider_profile_popover_body'>{providerData?.providerId}</p>
                            <Button variant="outline" component={Link} onClick={onClose} to={{ pathname: `/providers/${providerData?.providerId}/profile` }}>
                                {t('view_details','View Details')}
                            </Button>
                        </div>
                    }
                    {
                        (providerData?.isAdmin || providerData?.providerId === 0) &&
                        <Button variant="outline" className="change_provider_link" onClick={onSelectAnotherProviderClick}>
                            {t('change_provider','Change provider')}
                        </Button>
                    }
                    
                </div>
            </div>
        </Popover>
        {
            providerData?.isAdmin &&
            <SelectEffectiveProviderDialog
                isOpen={selectProviderDialogOpen}
                onClose={() => setSelectProviderDialogOpen(false)}
                onEffectiveProviderChanged={() => setSelectProviderDialogOpen(false)}
            />
        }
        
    </>
}
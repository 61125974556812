import { useRegionConfig, ALL_FEATURES } from "providers/RegionConfig";

export interface IFeatureIndicators {
    isWSPFeatureEnabled: () => boolean,
    isListReferralFeatureEnabled: () => boolean
    isHourlyWorkflowFeatureEnabled: () => boolean,
    isProviderProfileSelfServeFeatureEnabled: () => boolean,
    isAdditionalHoursFeatureEnabled: () => boolean,
    isExtendedUpdateApptStatusFeatureEnabled: () => boolean
}

export const useFeatureManager = (): IFeatureIndicators => {
    const { features } = useRegionConfig()
    
    const isWSPFeatureEnabled = () => features.includes(ALL_FEATURES.WSP)
    const isListReferralFeatureEnabled = () => features.includes(ALL_FEATURES.LIST_REFERRAL)
    const isHourlyWorkflowFeatureEnabled = () => features.includes(ALL_FEATURES.HOURLY_CONTRACT)
    const isAdditionalHoursFeatureEnabled = () => features.includes(ALL_FEATURES.ADDITIONAL_HOURS)
    const isProviderProfileSelfServeFeatureEnabled = () => features.includes(ALL_FEATURES.PROVIDER_PROFILE_SELF_SERVE)
    const isExtendedUpdateApptStatusFeatureEnabled = () => features.includes(ALL_FEATURES.EXTENDED_UPDATE_APPT_STATUS)

    return {
        isWSPFeatureEnabled,
        isListReferralFeatureEnabled,
        isHourlyWorkflowFeatureEnabled,
        isProviderProfileSelfServeFeatureEnabled,
        isAdditionalHoursFeatureEnabled,
        isExtendedUpdateApptStatusFeatureEnabled
    }
} 

import React, { FC } from 'react'
import './Logo.scss';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';

interface LogoProps {
    className?: string
}

export const Logo: FC<LogoProps> = ({className}) => {
    const { i18n } = useTranslation();
    const imageLang = i18n.language.toLowerCase().startsWith('fr') ? "Sante_FR" : "Health_EN";
    const altText = i18n.language.toLowerCase().startsWith('fr') ? "Logo Telus Santé" : "Telus Health Logo";

    return (
        <div className={clsx('logos_wrapper', className)}>
            <img height={48}  src={`/images/TELUS_${imageLang}_Hor_2021_Digital_RGB.png`} alt={altText} />
        </div>
    );
}
import React, { FC, PropsWithChildren, useCallback, useEffect, useState } from 'react';
import { createTheme, Theme, ThemeProvider as MuiThemeProvider } from '@mui/material/styles';
import ErrorOutlineRoundedIcon from '@mui/icons-material/ErrorOutlineRounded';
import { CssBaseline } from '@mui/material';

const readCSSVar = (name: string) => (
    getComputedStyle(document.documentElement).getPropertyValue(name).trim()
)

export const configureTheme = (dark: boolean) => createTheme({
    palette: {
        primary: {
            main: readCSSVar('--accent_color'),
            light: readCSSVar('--primary_200_color'),
            dark: readCSSVar('--primary_800_color'),
            contrastText: readCSSVar('--text_reversed_color')
        },
        error: {
            main: readCSSVar('--color_secondary'),
            light: readCSSVar('--color_secondary_light'),
            dark: readCSSVar('--color_secondary'),
            contrastText: readCSSVar('--text_reversed_color')
        },
        success: {
            main: readCSSVar('--color_quaternary'),
            light: readCSSVar('--color_quaternary_light'),
            dark: readCSSVar('--color_quaternary'),
            contrastText: readCSSVar('--text_reversed_color')
        },
        warning: {
            main: readCSSVar('--color_quinary'),
            light: readCSSVar('--color_quinary_light'),
            dark: readCSSVar('--color_quinary'),
            contrastText: readCSSVar('--text_reversed_color')
        },
        text: {
            disabled: readCSSVar('--disabled_color'),
            primary: readCSSVar('--text_primary_color'),
            secondary: readCSSVar('--text_secondary_color')
        },
        background: {
            paper: readCSSVar('--background_2_color'),
            default: readCSSVar('--background_1_color')
        },
        mode: dark ? 'dark' : 'light'
    },
    shape: {
        borderRadius: Number.parseInt(readCSSVar('--border_radius_small'))
    },
    typography: {
        fontFamily: [
            '"Helvetica Now Text SA"',
            'sans-serif'
        ].join(','),
    },
    components: {
        MuiAlert: {
            defaultProps: {
                iconMapping: {
                    warning: <ErrorOutlineRoundedIcon/>
                }
            },
            styleOverrides: {
                root: {
                    fontSize: 16,
                    fontWeight: 500
                },
                icon: {
                    color: 'inherit !important'
                },
                standardError: {
                    color: readCSSVar('--color_secondary'),
                    backgroundColor: readCSSVar('--color_secondary_ultra_light')
                },
                standardSuccess: {
                    color: readCSSVar('--color_quaternary'),
                    backgroundColor: readCSSVar('--color_quaternary_ultra_light')
                },
                standardWarning: {
                    color: readCSSVar('--color_quinary'),
                    backgroundColor: readCSSVar('--color_quinary_ultra_light')
                },
                standardInfo: {
                    color: readCSSVar('--color_neutral_100'),
                    backgroundColor: readCSSVar('--color_neutral_15')
                }
            }
        }
    }
});

export const ThemeProvider: FC<PropsWithChildren> = (props) => {
    const [theme, setTheme] = useState<Theme | null>(null);

    const matcher = window.matchMedia("(prefers-color-scheme: dark)");
    const handler = (dark: boolean) => { setTimeout(() => setTheme(configureTheme(dark)), 0) }
    const listener = useCallback((e) => handler(e.matches), []);

    useEffect(() => { handler(matcher.matches) }, []);

    useEffect(() => {
        matcher.addEventListener("change", listener)
        return () => { matcher.removeEventListener("change", listener) }
    }, []);

    return (
        theme
            ? <MuiThemeProvider theme={theme}>
                <CssBaseline/>
                {props.children}
            </MuiThemeProvider>
            : <></>
    )
}

import { useAppConfig } from 'providers/AppConfig';
import { LoginInput, LoginResponse } from '..';
import { useFetch } from 'hooks/useFetch';

export const useLoginRequest = (props) => {
    const { REACT_APP_GRAPHQL_API_HOST } = useAppConfig();

    return useFetch<LoginResponse, LoginInput>({
        ...props,
        url: new URL("/api/Auth/Login", REACT_APP_GRAPHQL_API_HOST).toString()
    });
}
import { Container } from '@mui/material';
import React, { FC, useState } from 'react';
import { useQuery } from '@apollo/client';
import { STAFF_QUERY } from './queries';
import { useAuth } from 'components/Auth';
import { useTranslation } from 'react-i18next';
import { SearchInput } from 'components/Shared/Inputs';
import { Paginator, MaterialTable, ISortsDefinition, DataRequest } from 'components/Shared/MaterialTable';
import { Spinner } from 'components/Shared';
import { useStaffColumnsDefinitions, useProcessStaffData } from './hooks';
import { PageMetadata } from 'components/Shared/PageMetadata';
import { FullScreenMessage } from 'components/Shared/FullScreenMessage';

interface StaffTableViewProps {
}

export const StaffTableView: FC<StaffTableViewProps> = (_) => {
    const { effectiveProviderData } = useAuth();
    const { t, ready: translationsReady } = useTranslation(['staff', 'common'], { useSuspense: false });

    const [ dataRequest, setDataRequest ] = useState<DataRequest>({
        sorts: {},
        take: 10,
        skip: 0,
        search: ''
    });

    const handleSearchValueChange = (value: string) => {
        setDataRequest({...dataRequest, search: value});
    }

    const handleSortsChange = (sorts: ISortsDefinition) => {
        setDataRequest({...dataRequest, sorts: sorts});
    }

    const handlePageChange = (currentPage: number) => {
        setDataRequest({...dataRequest, skip: (currentPage - 1) * dataRequest.take});
    }
    
    const { data, loading } = useQuery(STAFF_QUERY, {
        variables: { vendorId: effectiveProviderData?.provider?.vendor?.vendorId ?? 0 },
        errorPolicy: 'all'
    });

    const [processedData, paginationDetails] = useProcessStaffData(data?.providers, dataRequest);
    const columnsDefinitions = useStaffColumnsDefinitions();

    const emptyStateMessage = <FullScreenMessage title={t('sorry_we_couldnt_find_any_matching_providers')} />

    return (
        <React.Fragment>
            { translationsReady && 
                <PageMetadata 
                    pageDescription={t('staff_page_description')} 
                    pageTitle={t('staff_page_title')}
                /> 
            }
            <div className="table_view_wrapper">
            {
                !translationsReady
                ? <Spinner fillParent/>
                : <Container>
                    <div className="table_view_title_wrapper">
                        <h1>{t('providers')}</h1>
                        <SearchInput value={dataRequest.search} onChange={handleSearchValueChange}
                            label={t('search_providers_input_label')} placeholder={t('search_providers_input_placeholder')}/>
                    </div>
                    <MaterialTable
                        columns={columnsDefinitions}
                        onSortsChange={handleSortsChange}
                        sorts={dataRequest.sorts}
                        data={processedData}
                        dataLoading={loading}
                        emptyStateMessage={emptyStateMessage}
                    />
                    <Paginator paginationDetails={paginationDetails} onPageChange={handlePageChange}/>
                </Container>
            }
            </div>
        </React.Fragment>
    );
};

import React, { FC, useEffect, useRef, useState } from 'react';
import * as SurveyJS from 'survey-react';
import { useTranslation } from 'react-i18next';
import { SurveyJSRenderer, COACHING_NOTE_ANCHOR_ID, COACHING_NOTE_ANCHOR_NAME } from './SurveyJSRenderer'
import { useSurveyJSUtils } from './useSurveyJSUtils';
import { Container } from '@mui/material';

export interface SurveyJSContainerProps {
    template?: string,
    formData?: any,
    formId?: string,
    onComplete?: (outcome?: string) => any,
    defaultValues?: any,
    showTitle?: boolean,
    readOnly?: boolean,
    completed?: boolean,
    coachingNote?: string,
    coachingNoteTitle?: string,
    onSurveyJSInitialized?: (surveyModel: SurveyJS.SurveyModel) => void,
    activityId?: number
}

export const SurveyJSContainer: FC<SurveyJSContainerProps> = (props) => {
    const { coachingNote, coachingNoteTitle, readOnly, template, onComplete, showTitle = true, onSurveyJSInitialized, formData, defaultValues } = props;

    const surveyModel = useRef<SurveyJS.SurveyModel | null>(null);

    const [initialized, setInitialized] = useState<{} | undefined>(undefined);

    const { t, i18n } = useTranslation('caseManagement', { useSuspense: false });

    const { updateAriaLabels } = useSurveyJSUtils();

    const [formDescriptionDialogOpen, setFormDescriptionDialogOpen] = useState(false);
    const [coachingNoteAnchor, setCoachingNoteAnchor] = useState<HTMLDivElement | null>(null);

    useEffect(() => {
        if (template) {
            surveyModel.current = new SurveyJS.Model(JSON.parse(template));
            onSurveyJSInitialized?.(surveyModel.current);
            setInitialized({});
        }
    }, [template]);

    const setupPageButtonsConfiguration = (options: any) => {
        const document = options.htmlElement.ownerDocument;
        const prevBtn = document?.querySelector(".sv-paneldynamic__prev-btn");
        const nextBtn = document?.querySelector(".sv-paneldynamic__next-btn");

        if (prevBtn) {
            setNavButtonKeyUp(prevBtn);
            setPanelNavigationLabel(prevBtn, t("previous_record")); 
        }

        if (nextBtn) {
            setNavButtonKeyUp(nextBtn);
            setPanelNavigationLabel(nextBtn, t("next_record"));
        }
    }

    const setupCoachingNoteAnchor = (options: any) => {
        const document = options.htmlElement.ownerDocument;

        if (document && !document.querySelector(COACHING_NOTE_ANCHOR_ID)) {
            const titleContainer = document?.querySelector('.sv-header__text h3') as HTMLHeadingElement;

            if (titleContainer) {
                const infoElement = document.createElement('div') as HTMLDivElement;
                infoElement.id = COACHING_NOTE_ANCHOR_NAME;
                infoElement.style.display = 'inline-block';
                titleContainer.appendChild(infoElement);
                setCoachingNoteAnchor(infoElement);
            }
        }
    }

    useEffect(() => {
        surveyModel.current?.onAfterRenderPage.add((_, options) => {
            setupPageButtonsConfiguration(options)
            setupCoachingNoteAnchor(options)
        });

        surveyModel.current?.onAfterRenderPanel.add((_, options) => {
            setupPageButtonsConfiguration(options)
            setupCoachingNoteAnchor(options)
        });   

        surveyModel.current?.onAfterRenderQuestion.add((_, options) => {
            const surveyQuestion: SurveyJS.Question = options.question

            if (surveyQuestion instanceof SurveyJS.QuestionMatrixDropdownModel) {
                const surveyRenderedTable: SurveyJS.QuestionMatrixDropdownRenderedTable = surveyQuestion.renderedTable;

                if (surveyRenderedTable) {
                    const questionsElement = options.htmlElement.ownerDocument.querySelector(`div[name='${surveyQuestion.name}']`);

                    updateAriaLabels(questionsElement);
                    updateTableAriaLabel(questionsElement, surveyQuestion.title);
                }
            }
        });
    }, [initialized]);

    const updateTableAriaLabel = (questionsElement: any, title: string) => {
        if (questionsElement) {
            const tableElement: HTMLTableElement = questionsElement.querySelector('table');
            tableElement?.setAttribute("aria-label", title);
        }
    }

    useEffect(() => {
        if (surveyModel.current) {
            surveyModel.current.data = JSON.parse(formData || '{}');
        }
    }, [initialized, formData]);

    useEffect(() => {
        if (surveyModel.current) {
            surveyModel.current.mode = readOnly ? 'display' : 'edit';
            surveyModel.current.showTitle = showTitle;
        }
    }, [initialized, readOnly, showTitle]);

    useEffect(() => {       
        if (surveyModel.current) {
            surveyModel.current.locale = i18n.language.substring(0, 2);
        }
    }, [initialized, i18n.language]);

    useEffect(() => {
        if (defaultValues) {
            Object.keys(defaultValues).forEach(fieldKey => {
                if (!formData?.[fieldKey]) {
                    surveyModel.current?.setValue(fieldKey, defaultValues[fieldKey]); 
                }
            });
        }
    }, [initialized, defaultValues]);

    const setNavButtonKeyUp = (btn: any) => {
        const clickEvent = new MouseEvent('click', {   
            bubbles: true
        });

        btn.tabIndex = 0;
        btn.onkeyup = (event: any) => {
            if (event.keyCode === 13) {
                btn.dispatchEvent(clickEvent);
            }
        };
    }

    const setPanelNavigationLabel = (btn: any, labelText: string) => {
        btn.setAttribute("aria-label", labelText);
    }

    const isLoading = !initialized;

    const handleFormSubmit = (result) => {
        onComplete?.(JSON.stringify(result.data));
    }

    return (
        <Container maxWidth="lg">
            <SurveyJSRenderer
                isLoading={isLoading}
                surveyModel={surveyModel.current}
                onFormSubmit={handleFormSubmit}
                formDescriptionDialogOpen={formDescriptionDialogOpen}
                setFormDescriptionDialogOpen={setFormDescriptionDialogOpen}
                coachingNoteAnchor={coachingNoteAnchor}
                coachingNote={coachingNote}
                coachingNoteTitle={coachingNoteTitle}
            />
        </Container>
    )
};

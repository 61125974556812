import React, { FC, PropsWithChildren } from 'react';
import { useScreenSize } from './components/ScreenSizeProvider';
import { BasicNavigation, DesktopNavigation, MobileNavigation } from './variants';

import './Layout.scss';
interface LayoutProps extends PropsWithChildren {
    variant: 'basic' | 'normal' | 'none',
    showNavigation?: boolean
}

export const LayoutResponsive: FC<LayoutProps> = (props) => {
    const { variant, children, showNavigation } = props;
    const { isMobile } = useScreenSize();

    const renderNavigation = () => {
        switch (variant) {
            case 'basic': {
                return <BasicNavigation showNavigation={showNavigation ?? true}/>
            }
            case 'normal': {
                return isMobile
                    ? <MobileNavigation/>
                    : <DesktopNavigation/>
            }
            case 'none':
            default: {
                return <React.Fragment/>;
            }
        }
    }

    return <>
        {renderNavigation()}
        <div className={(isMobile && variant !== 'basic') ? 'app_content_mobile' : 'app_content_desktop'}>
            <main id="main">
                {children}
            </main>
        </div>
    </>
}

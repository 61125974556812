import React from 'react';
import { useTranslation } from 'react-i18next';
import { useRegionConfig } from 'providers/RegionConfig';
import { OverridableComponent } from '@mui/material/OverridableComponent';
import { useNavigate } from 'react-router-dom';

interface LanguageSwitchTypeMap<P = {}, D extends React.ElementType = "button"> {
    props: P;
    classKey: "className";
    defaultComponent: D;
}

export const LanguageSwitcher: OverridableComponent<LanguageSwitchTypeMap> = (props) => {
    const { component: ComponentProp = "button", ...other } = props;
    const { i18n } = useTranslation(undefined, {useSuspense: false});
    const { languages } = useRegionConfig();
    const navigate = useNavigate();

    const changeLanguage = (code: string) => {
        i18n.changeLanguage(code);
        // because the save button on SessionNote form is not re-rendered on language change, we need to force a reload
        if (
            typeof window !== "undefined" 
            && (
                window.location.pathname.startsWith("/forms/")
                || window.location.pathname.endsWith("/forms/sessionnote")
            )
        ) {
            navigate(0);
        }
    }

    if (languages.length > 1) {
        const currentLanguageIndex = languages.findIndex(language => language.code === i18n.language);
        const nextLanguageIndex = (currentLanguageIndex + 1 === languages.length) ? 0 : currentLanguageIndex + 1;
        const nextLanguage = languages[nextLanguageIndex];

        return (
            <ComponentProp onClick={() => changeLanguage(nextLanguage.code)} {...other}>{nextLanguage.name}</ComponentProp>
        )
    }

    return <></>
};
import { gql, TypedDocumentNode } from '@apollo/client';
import { IFormMetaDataInput, IFormInfo, ReportFormInfo, IPreAssessment, AssessmentTool, SessionSummary, FormSummary } from './interfaces';

export const GET_FORMS_BY_ACTIVITY: TypedDocumentNode<GetFormsByActivityData, GetFormsByActivityVars> = gql`
    query GET_FORMS_BY_ACTIVITY($activityId: Long!, $sessionId: Long, $templateName: String, $externalReferenceId: String, $tags: [String]) {
        forms(activityId: $activityId, sessionId: $sessionId, templateName: $templateName, externalReferenceId: $externalReferenceId) {
            templateName
            sessionId
            result
            completed
            id
            sequence
            template(tags: $tags)
            readOnly
            externalReferenceId
            stage
            preAssessmentStageStatus
            postAssessmentStageStatus
            templateVersion
        }
        activity(activityId: $activityId) {
            activityId
            client {
                firstName
                lastName
                address {
                    addressLine1
                    addressLine2
                    city
                }
            }
            activityStatusCode
            service
            sessions {
                sessionId
                startDateTime
                startDateTimeUTC
                sessionStatusId
            }
            targetedSystem {
                systemCode
            }
            firstAppointmentAssessmentFormNames {
                templateName
                mandatory
            }
            lastAppointmentAssessmentFormNames {
                templateName
                mandatory
            }
        }
    }
`

export const GET_FORMS_WITH_PREASSESSMENTS_BY_ACTIVITY:  TypedDocumentNode<GetFormsByActivityData, GetFormsByActivityVars> = gql`
    query GET_FORMS_BY_ACTIVITY($activityId: Long!, $sessionId: Long, $templateName: String, $externalReferenceId: String, $tags: [String]) {
        forms(activityId: $activityId, sessionId: $sessionId, templateName: $templateName, externalReferenceId: $externalReferenceId) {
            templateName
            sessionId
            result
            completed
            id
            sequence
            template(tags: $tags)
            readOnly
            externalReferenceId
            stage
            preAssessmentStageStatus
            postAssessmentStageStatus
            templateVersion
        }
        activity(activityId: $activityId) {
            activityId
            client {
                firstName
                lastName
                address {
                    addressLine1
                    addressLine2
                    city
                }
            }
            activityStatusCode
            service
            sessions {
                sessionId
                startDateTime
                startDateTimeUTC
                sessionStatusId
            }
            targetedSystem {
                systemCode
            }
            firstAppointmentAssessmentFormNames {
                templateName
                mandatory
            }
            lastAppointmentAssessmentFormNames {
                templateName
                mandatory
            }
            preAssessments {
                additionalIndividualId
                activityId
                clientName
                status
                forms
                {
                    result
                    completed
                    id
                    template
                    templateName
                    templateVersion
                }
            }
        }
    }
`

export interface GetFormsByActivityData {
    forms : IFormInfo[],
    activity: {
        client: {
            firstName: string,
            lastName: string,
            address: {
                addressLine1: string,
                addressLine2: string,
                city: string
            }
        },
        activityStatusCode: number,
        service: string,
        sessions: {
            sessionId: number,
            startDateTime: string,
            startDateTimeUTC: string
        }[],
        targetedSystem: {
            systemCode: string
        },
        firstAppointmentAssessmentFormNames: {
            templateName: string,
            mandatory: boolean
        }[],
        lastAppointmentAssessmentFormNames: {
            templateName: string,
            mandatory: boolean
        }[],
        preAssessments?: {
            additionalIndividualId: number,
            activityId: number,
            clientName: string
            status: string,
            forms: {
                result: string,
                completed: boolean,
                id: string,
                template: string,
                templateName: string,
                templateVersion: string
            }[]
        }[]
    }
}

export interface GetFormsByActivityVars {
    activityId: number,
    sessionId?: number,
    templateName?: string
    externalReferenceId?: string,
    tags?: string[]
}

export const GET_FORM_BY_ID: TypedDocumentNode<GetFormByIdData, GetFormByIdVars> = gql`
    query GET_FORM_BY_ID($formId: String!) {
        form(formId: $formId) {
            activityId
            templateName
            sessionId
            result
            completed
            id
            sequence
            template
            readOnly
            externalReferenceId
            stage
            preAssessmentStageStatus
            postAssessmentStageStatus
            activity {
                activityId
                client {
                    firstName
                    lastName
                }
                activityStatusCode
                targetedSystem {
                    systemCode
                }
                service
                sessions {
                    sessionId
                    startDateTime
                    startDateTimeUTC
                    sessionStatusId
                }
                preAssessments {
                    additionalIndividualId
                    activityId
                    clientName
                    status
                    forms
                    {
                        result
                        completed
                        id
                        template
                        templateName
                        templateVersion
                    }
                }
            }
        }
    }
`

interface GetFormByIdData {
    // activity: any,
    form: IFormInfo
}

interface GetFormByIdVars {
    formId: string
}


const ASSESSMENT_SCORE_DETAILS_FRAGMENT = gql`
    fragment AssessmentScoreDetails on AssessmentScore {
        date
        formId
        sessionId
        score
        severity
    }
`

export const GET_REPORT_BY_ID: TypedDocumentNode<GetReportByIdData, GetReportByIdVars> = gql`
    query GET_REPORT_BY_ID($formId: String!) {
        form(formId: $formId) {
            templateName
            sessionId
            result
            completed
            readOnly
            id
            sequence
            template
            externalReferenceId
            createdOn
            stage
            activity {
                activityId
                providerId
                clientId
                client {
                    firstName
                    lastName
                }
                activityStatusCode
                service
                targetedSystem {
                    systemCode
                }
                sessions {
                    sessionId
                    sessionStatusId
                }
                assessmentsScores {
                    AUDIT { ...AssessmentScoreDetails }
                    AUDIT_C { ...AssessmentScoreDetails }
                    DAST { ...AssessmentScoreDetails }
                    SBQ { ...AssessmentScoreDetails }
                    PHQ9 { 
                        date
                        formId
                        sessionId
                        score
                        severity
                    }
                    DASS21 { 
                        date
                        formId
                        sessionId
                        score {
                            anxiety
                            depression
                            stress
                        }
                        severity {
                            anxiety
                            depression
                            stress
                        }
                    }
                }
                assessmentReportData {            
                    clientName
                    clientPosition
                    isSafetySensitivePosition
                    isSafetySensitivePositionString
                    companyName
                    currentWorkStatus
                    otherWorkStatusDesc
                    currentWorkStatusEffDate
                    anticipatedRTWDate
                    referralContactName
                }
            }
        }
    }
    ${ASSESSMENT_SCORE_DETAILS_FRAGMENT}
`

interface GetReportByIdData {
    form: ReportFormInfo
}

interface GetReportByIdVars {
    formId: string
}

export const CREATE_FORM_BY_FOLDER: TypedDocumentNode<CreateFormByFolderData, CreateFormByFolderVars> = gql`
    mutation CREATE_FORM_BY_FOLDER($folder: String, $form: FormMetaDataInput) {
        createFormByFolder(folder: $folder, form: $form) {
            templateName
            sessionId
            result
            id
            sequence
        }
    }
`

interface CreateFormByFolderData {
    createFormsByFolder: IFormInfo[]
}

interface CreateFormByFolderVars {
    folder: string,
    form: IFormMetaDataInput
}

export const CREATE_FORM_BY_TEMPLATE: TypedDocumentNode<CreateFormByTemplateData, CreateFormByTemplateVars> = gql`
    mutation CREATE_FORM_BY_TEMPLATE($form: FormMetaDataInput, $templateName: String, $templateVersion: String) {
        createFormByTemplate(form: $form, templateName: $templateName, templateVersion: $templateVersion) {
            templateName
            sessionId
            result
            completed
            id
            sequence
            template
            readOnly
            externalReferenceId
            stage
        }
    }
`

export interface CreateFormByTemplateData {
    createFormByTemplate: IFormInfo
}

export interface CreateFormByTemplateVars {
    form: IFormMetaDataInput,
    templateName?: String,
    templateVersion?: String
}

export const GET_TEMPLATE_BY_NAME: TypedDocumentNode<GetTemplateByNameData, GetTemplateByNameVars> = gql`
    query GET_TEMPLATE_BY_NAME($name: String, $tags: [String], $version: String) {
        formTemplate(name: $name, tags: $tags, version: $version) {
            template
            version
        }
    }
`

export interface GetTemplateByNameData {
    formTemplate: {
        template: string,
        version: string
    }
}

export interface GetTemplateByNameVars {
    name: string,
    tags? : string[],
    version?: string
}

export const UPDATE_FORM: TypedDocumentNode<UpdateFormData, UpdateFormVars> = gql`
    mutation UPDATE_FORM($data: String, $formId: String, $isCompleted: Boolean, $providerComment: String, $isSubmit: Boolean, $tags: [String]) {
        updateForm(data: $data, formId: $formId, isCompleted: $isCompleted, providerComment: $providerComment, isSubmit: $isSubmit) {
            updatedForm {
                templateName
                sessionId
                result
                completed
                id
                sequence
                template(tags: $tags)
                readOnly
                externalReferenceId
                stage
                preAssessmentStageStatus
                postAssessmentStageStatus
            }
            administeredForms {
                templateName
                sessionId
                result
                completed
                id
                sequence
                template(tags: $tags)
                readOnly
                externalReferenceId
                stage
                preAssessmentStageStatus
                postAssessmentStageStatus
            }
        }
    }
`

export interface UpdateFormData {
    updateForm: {
        updatedForm: IFormInfo,
        administeredForms: IFormInfo[]
    }
}

export interface UpdateFormVars {
    data: string,
    formId: string,
    isCompleted: boolean,
    providerComment?: string,
    isSubmit?: boolean
}

export const GET_TEMPLATE_BY_FORM: TypedDocumentNode<GetTemplateByFormData, GetTemplateByFormVars> = gql`
    query GET_FORM_TEMPLATE($formId: String!, $tags: [String]) {
        templateByForm(formId: $formId, tags: $tags){
            template
        }      
}
`

export interface GetTemplateByFormVars{
    formId : string,
    tags? : string[]
}

export interface GetTemplateByFormData {
    templateByForm: {
        template: string
    }
}

export const GET_ACTIVITY_PRE_ASSESSMENTS_QUERY
    : TypedDocumentNode<ActivityPreAssessmentsQueryData, ActivityPreAssessmentsQueryVars> = gql`
    query GET_ACTIVITY_PRE_ASSESSMENTS($activityId: Long!) {
        activity(activityId: $activityId) {
            activityId
            preAssessments {
                additionalIndividualId
                activityId
                clientName
                status
                forms
                {
                    result
                    completed
                    id
                    template
                    templateName
                }
            }
            client {
                firstName
                lastName
            }
        }
    }
`

interface ActivityPreAssessmentsQueryData {
    activity: {
        preAssessments: IPreAssessment[],
        client: {
            firstName: string,
            lastName: string
        }
    }
}

interface ActivityPreAssessmentsQueryVars {
    activityId: number
}

export const COMPLETE_REVIEW_MUTATION = gql`
    mutation COMPLETE_REVIEW_MUTATION($activityId: Long!, $individualId: String!) {
        completePreAssessmentReview(activityId: $activityId, individualId: $individualId)
    }
`;


export const COMPLETE_ASSESSMENT_MUTATION = gql`
    mutation COMPLETE_ASSESSMENT_MUTATION($activityId: Long!, $stage: String!) {
        completeAssessment(activityId: $activityId, stage: $stage)
    }
`;

export const GET_ACTIVITY_DATA_QUERY
    : TypedDocumentNode<GetActivityDataQueryData, GetActivityDataQueryVars> = gql`
    query GET_ACTIVITY_DATA($activityId: Long!) {
        activity(activityId: $activityId) {
            activityId
            providerFirstAvailableAppointmentDate
            sessions {
                duration
                sessionId
                sessionStatus
                startDateTimeUTC
                sessionStatusId
                providerHubAppointmentStatusId
            }
            sessionInfo {
                modelId
                totalHoursProvided
                totalHoursRemaining
                maxCapHours
            }
            clientMaxHours
            clientAvailableHours
            clientUsedHours
            activityStatusCode
            sessionBooking {
                allowed
                prohibitionReasonCode
            }
            targetedSystem {
                systemCode
            }
            client {
                firstName
                lastName
            }
        }
    }
`

export interface GetActivityDataQueryVars {
    activityId: number
}

export interface GetActivityDataQueryData {
    activity: {
        providerFirstAvailableAppointmentDate: Date,
        client: {
            firstName: string,
            lastName: string
        }
        clientMaxHours: number
        clientAvailableHours: number;
        clientUsedHours: number;
        sessions: {
            duration: number;
            sessionId: number;
            sessionStatus: string;
            startDateTimeUTC: string;
            sessionStatusId: number;
            providerHubAppointmentStatusId: number
        }[]
        targetedSystem: {
            systemCode: string
        }
        sessionInfo: {
            modelId: number;
            totalHoursProvided: number;
            totalHoursRemaining: number;
            maxCapHours: number;
        }
        activityStatusCode: number;
    }
}

export const CREATE_CLOSURE_REPORT: TypedDocumentNode<CreateFormByTemplateData, CreateFormByTemplateVars> = gql`
    mutation CREATE_CLOSURE_REPORT($form: FormMetaDataInput, $templateName: String, $templateVersion: String) {
        createClosureReport(form: $form, templateName: $templateName, templateVersion: $templateVersion) {
            templateName
            sessionId
            result
            id
            sequence
        }
    }
`


export const SESSION_NOTE_VIEW_QUERY: TypedDocumentNode<SessionNoteViewQueryData, SessionNoteViewQueryVars> = gql`
query SESSION_NOTE_VIEW_QUERY($activityId: Long!, $sessionId: Long!) {
    activity(activityId: $activityId) {
        activityId
        client {
            firstName
            lastName
            address {
                addressLine1
                addressLine2
                city
            }
        }
        activityStatusCode
        service
        sessions {
            sessionId
            startDateTime
            startDateTimeUTC
            sessionStatusId
        }
        targetedSystem {
            systemCode
        }
        sessionNote(sessionId: $sessionId) {
            id
            activityId
            templateName
            template
            sessionId
            result
            completed
            sequence
            template
            readOnly
            externalReferenceId
            stage
            preAssessmentStageStatus
            postAssessmentStageStatus
        }
    }
}
`

interface SessionNoteViewQueryData {
    activity: {
        client: any,
        activityStatusCode: any,
        service: any,
        sessions: any[],
        targetedSystem: any,
        mandatoryAssessments: any[],
        sessionNote: IFormInfo
    }
}

interface SessionNoteViewQueryVars {
    activityId: number,
    sessionId: number
}


export const CREATE_SESSION_NOTE_FORM_MUTATION: TypedDocumentNode<CreateSessionNoteFormMutationData, CreateSessionNoteFormMutationVars> = gql`
    mutation CREATE_SESSION_NOTE_FORM_MUTATION($activityId: Long!, $sessionId: Long!) {
        form: createSessionNoteForm(activityId: $activityId, sessionId: $sessionId) {
            templateName
            sessionId
            result
            completed
            id
            sequence
            template
            readOnly
            externalReferenceId
            stage
        }
    }
`

interface CreateSessionNoteFormMutationData {
    form: IFormInfo
}

interface CreateSessionNoteFormMutationVars {
    activityId: number,
    sessionId: number
}

export const ASSESSMENT_TOOLS_VIEW_QUERY: TypedDocumentNode<AssessmentToolsViewQueryData, AssessmentToolsViewQueryVars> = gql`
    query ASSESSMENT_TOOLS_VIEW_QUERY($activityId: Long!) {
        activity(activityId: $activityId) {
            activityId
            client {
                firstName
                lastName
            }
            sessions {
                sessionId
                startDateTimeUTC
                sessionStatusId
            }
            forms {
                id
                templateName
                sessionId
            }
            availableAssessmentTools {
                name
                perSession
            }
        }
    }
`

interface AssessmentToolsViewQueryData {
    activity: {
        client: {
            firstName: string,
            lastName: string
        },
        sessions: SessionSummary[],
        forms: FormSummary[],
        availableAssessmentTools: AssessmentTool[]
    }
}

interface AssessmentToolsViewQueryVars {
    activityId: number
}


export const ASSESSMENT_TOOLS_QUERY: TypedDocumentNode<AssessmentToolsQueryData, AssessmentToolsQueryVars> = gql`
    query ASSESSMENT_TOOLS_VIEW_QUERY($activityId: Long!) {
        availableAssessmentTools(activityId: $activityId) {
            name
            perSession
        }
    }
`

interface AssessmentToolsQueryData {
    availableAssessmentTools: AssessmentTool[]
}

interface AssessmentToolsQueryVars {
    activityId: number
}
export const toCurrencyString = (amount: number | null | undefined, locale: string, currency: string | null | undefined) => {
    if (currency) {
        return formatValueAsCurrency(amount, locale, currency)
    } else {
        return amount?.toLocaleString(locale, {style: 'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2});
    }
}


const formatValueAsCurrency = (amount: number | null | undefined, locale: string, currency: string): string | undefined => {
    try {
        const currencyFormattedValue = amount?.toLocaleString(locale, {currency: currency, style: 'currency', currencyDisplay: 'symbol' });
        return currencyFormattedValue
    } catch (e) {
        return amount ? amount.toString() : '-'
    }
}
import { gql, TypedDocumentNode } from '@apollo/client';
import { Education, EducationInput } from '../../../interfaces';

export const EDUCATION_REF_DATA: TypedDocumentNode<EducationRefDataData, EducationRefDataVars> = gql`
    query EDUCATION_REF_DATA {
        degrees {
            title
            value
        }
    }
`

interface EducationRefDataData {
    degrees: {
        title: string,
        value: string
    }[]
}

interface EducationRefDataVars {}


export const UPDATE_EDUCATIONS_MUTATION: TypedDocumentNode<UpdateEducationsMutationData, UpdateEducationsMutationVars> = gql`
    mutation UPDATE_EDUCATIONS_MUTATION($providerId: Long!, $educations: [EducationInput]) {
        updateCredentials(providerId: $providerId, educations: $educations) {
            providerId
            credential {
                educations {
                    institution
                    degreeId
                    degree
                    providerEducationId
                    isPrimary
                    yearEarned
                }
            }
        }
    }
`

export interface UpdateEducationsMutationData {
    updateCredentials: {
        credential: {
            educations: Education[]
        }
    }
}

export interface UpdateEducationsMutationVars {  
    providerId: number,
    educations: EducationInput[]
}

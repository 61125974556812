import { useQuery, useLazyQuery } from "@apollo/client";
// import { useEffect } from "react";
import { IStatusList } from "./interfaces";
import { APPOINTMENT_MISMATCH_REASONS_QUERY, APPOINTMENT_CANCELLATION_REASONS_FOR_STAFF_QUERY, APPOINTMENT_CANCELLATION_REASONS_FOR_AFFILIATE_QUERY } from "./queries";

export const useApptCancellationReasons = (isStaff: boolean): [IStatusList[] | undefined, boolean] => {
    const [doStaffApptStatusesQuery, apptStatusStaffQuery] = useApptCancellationReasonsForStaff()
    const [doAffilitateApptStatusesQuery, apptStatusAffiliateQuery] = useApptCancellationReasonsForAffiliate()

    if (isStaff && !apptStatusStaffQuery.called) {
        doStaffApptStatusesQuery();
    } else if (!apptStatusAffiliateQuery.called) {
        doAffilitateApptStatusesQuery();
    }

    // useEffect(() => {
    //     if (isStaff && !apptStatusStaffQuery.called) {
    //         doStaffApptStatusesQuery();
    //     } else if (!apptStatusAffiliateQuery.called) {
    //         doAffilitateApptStatusesQuery();
    //     }
    // }, [isStaff])

    return isStaff
        ? [apptStatusStaffQuery?.data?.staffProviderSessionCancellationReasons, apptStatusStaffQuery.loading]
        : [apptStatusAffiliateQuery?.data?.affiliateProviderSessionCancellationReasons, apptStatusAffiliateQuery.loading]
} 

export const useApptCancellationReasonsForStaff = () => {
    return useLazyQuery(
        APPOINTMENT_CANCELLATION_REASONS_FOR_STAFF_QUERY, 
        {}
    )
} 

export const useApptCancellationReasonsForAffiliate = () => {
    return useLazyQuery(
        APPOINTMENT_CANCELLATION_REASONS_FOR_AFFILIATE_QUERY,
        {}
    );
} 

export const useApptMismatchReasons = (): IStatusList[] | undefined => {
    const apptMismatchQuery = useQuery(
        APPOINTMENT_MISMATCH_REASONS_QUERY, 
        {}
    );
    return apptMismatchQuery?.data?.firstAppointmentMismatchReasons
}
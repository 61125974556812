import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { StaffTableItem } from 'components/Staff/interfaces';
import { SectionContainer } from '../../Other/SectionContainer';

interface GroupAdministratorsProps {
    administrators?: StaffTableItem[]
}

export const GroupAdministrators: FC<GroupAdministratorsProps> = (props) => {
    const { administrators } = props;
    const { t, ready: translationsReady } = useTranslation('providerprofile', { useSuspense: false });

    return !translationsReady
        ? <></>
        : <SectionContainer title={t('group_administrators__section_title')}>
            <h3>{t('group_administrators__group_administrators_header')}</h3>
            {
                administrators?.map((administrator, index) => (
                    <div className="group_practice_administrator_wrapper" key={index}>
                        <p className="group_practice_administrator_name">{`${administrator.firstName} ${administrator.lastName}`}</p>
                        <div className="group_practice_administrator_contacts">
                            <a href={`mailto:${administrator.emailAddress}`}>{administrator.emailAddress}</a>
                            <a href={`tel:${administrator.preferredPhone.phoneNumber}`}>{administrator.preferredPhone.phoneNumber}</a>
                        </div>
                    </div>
                ))
            }
        </SectionContainer>
}
import React, { FC } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { TemplateNames, FormTitleKeysMap, FormRouteMap } from 'components/Forms/constants';
import { useTranslation } from 'react-i18next';
import { ChecklistItem } from '../ChecklistItem';
import { ActivityData, FormResult, FormStatus, FormSummary, SessionStatus } from '../../interfaces';
import { GetFormStatus } from '../../../utils/formStatusUtils';
import { Button } from 'components/Shared/Inputs';

interface FormsStepProps {
    activity: ActivityData,
    activityId: number,
    forms?: (FormSummary & FormResult)[]
}

export const FormsStep: FC<FormsStepProps> = (props) => {
    const { activity, activityId, forms } = props;
    const { t } = useTranslation('assessments', { useSuspense: false });

    const { pathname } = useLocation();

    const renderChecklistItem = (templateName: TemplateNames, status: FormStatus, label?: string) => (
        <ChecklistItem key={templateName} label={(label ?? t(FormTitleKeysMap[templateName])) + (status === FormStatus.Declined ? ` - ${t('declined')}` : '')}
            status={status}>
            {
                status !== FormStatus.Completed &&
                    <Button 
                        variant="outline" 
                        component={Link}
                        to={{
                            pathname: `/clients/${activityId}/forms/${FormRouteMap[templateName]}`,
                        }}
                        state={{ entryRoute: pathname }}
                    >
                        {t('complete_form_button_label')}
                    </Button>
            }
        </ChecklistItem>
    )

    const renderFormStatusSummary = (templateName: TemplateNames, stage?: 'PRE' | 'POST') => {
        const form = forms?.find(form => form.templateName === templateName);
        const status = GetFormStatus(templateName, activity.mandatoryAssessments, form, stage);

        return renderChecklistItem(templateName, status);
    }

    const renderSessionNotesSummary = () => {
        var sessionNoteAssessment = activity.mandatoryAssessments.find(assessment => assessment.assessmentName.startsWith("SessionNotes"))
            ?? { assessmentName: "SessionNotes", templateName: TemplateNames.SessionNote, mandatory: false};
        var completedSessions = activity?.sessions.filter(session => session.sessionStatusId === SessionStatus.COMPLETE);

        return completedSessions.map(session => {
            const form = forms?.find(form => form.templateName === sessionNoteAssessment.templateName && form.sessionId === session.sessionId);
            const status = GetFormStatus(sessionNoteAssessment.templateName as TemplateNames, activity.mandatoryAssessments, form);

            return (
                <ChecklistItem key={TemplateNames.SessionNote + session.sessionId} status={status}
                    label={t(FormTitleKeysMap[TemplateNames.SessionNote], {replace: {sessionId: session.sessionId}})}>
                    {
                        status !== FormStatus.Completed &&
                            <Button variant="outline" component={Link}
                                to={`/clients/${activityId}/sessions/${session.sessionId}/forms/sessionnote`}
                            >
                                {t('complete_form_button_label')}
                            </Button>
                    }
                </ChecklistItem>
            )
        });
    }

    return <>
        <h2>{t('pre-assessments')}</h2>
        <ul className="close_case_checklist_list">
            {
                activity.firstAppointmentAssessmentFormNames.map(assessment => (
                    renderFormStatusSummary(assessment.templateName as TemplateNames, 'PRE')
                ))
            }
        </ul>
        <h2>{t('session-notes')}</h2>
        <ul className="close_case_checklist_list">
            {renderSessionNotesSummary()}
        </ul>
        <h2>{t('post-assessments')}</h2>
        <ul className="close_case_checklist_list">
            {
                activity.lastAppointmentAssessmentFormNames.map(assessment => (
                    renderFormStatusSummary(assessment.templateName as TemplateNames, 'POST')
                ))
            }
        </ul>
    </>
}
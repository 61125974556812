import React, { FC } from 'react';
import useTimezoneUtils from 'hooks/useTimezoneUtils';

interface TimeGridHeaderProps {
    selectedDate: Date
}

export const TimeGridHeader: FC<TimeGridHeaderProps> = (props) => {
    const { selectedDate } = props;
    const { formatDate } = useTimezoneUtils();

    return (
        <div className="date_stamp">
        <p>{formatDate(selectedDate, 'dddd, LL')}</p>
        </div>
    );
}
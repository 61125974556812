import React, { FC, useState, useRef, useEffect } from 'react';
import '@fullcalendar/react'; // needs to be imported before plugins
import FullCalendar from '@fullcalendar/react';
import { EventSourceFunc, DatesSetArg } from '@fullcalendar/core';
import { CalendaringToolbar } from './components/Toolbar/CalendaringToolbar';
import { parseEvents, mapUnavailabilityItemToUnavailability} from './utils/calendaring-utils';
import { CalendarType, DateClickArgKeyboard, DateSelectArgKeyboard, EventClickArgKeyboard, EventType } from './interfaces';
import './Calendaring.scss';
import { UnavailabilityDialog, DatesRange, Unavailability } from './Unavailabilities';
import { selectEventType } from './components/EventTypeMenu/EventTypeMenu';
import { DayView } from './components/DayView';
import { Spinner } from 'components/Shared';
import { WeekView } from './components/WeekView';
import { MonthView } from './components/MonthView';
import { UnavailabilityItem } from './interfaces';
import { useNavigate } from 'react-router-dom';
import { SaveUnavailabilityMutationData, DeleteUnavailabilityMutationData } from './Unavailabilities/queries';
import { ExecutionResult } from 'graphql';
import { useScheduleQuery } from './hooks'; 
import * as Moment from 'moment';
import memoize from 'memoize-one';
import { extendMoment } from 'moment-range';
import { useAuth } from 'components/Auth';
import { PageMetadata } from 'components/Shared/PageMetadata';
import { useTranslation } from 'react-i18next';

const moment = extendMoment(Moment);

interface CalendaringViewProps { }

const parseDayCalendarEvents = memoize(parseEvents);
const parseWeekCalendarEvents = memoize(parseEvents);
const parseMonthCalendarEvents = memoize(parseEvents);

export const CalendaringView: FC<CalendaringViewProps> = (_) => {
    const calendarContainerRef = useRef<HTMLDivElement>(null);
    const calendarRef = useRef<FullCalendar>(null);
    const dayViewRef = useRef<FullCalendar>(null);

    const { effectiveProviderData } = useAuth();
    const providerTimezone = effectiveProviderData?.timeZone ?? '';
    
    const isStaffProvider = effectiveProviderData?.employmentTypeId === 1 || effectiveProviderData?.employmentTypeId === 2

    const [calendarType, setCalendarType] = useState<CalendarType>(CalendarType.Week);

    const [monthCalendarPeriodStart, setMonthCalendarPeriodStart] = useState(moment.tz(providerTimezone).format('YYYY-MM-DD'));
    const [weekCalendarPeriodStart, setWeekCalendarPeriodStart] = useState(moment.tz(providerTimezone).format('YYYY-MM-DD'));
    const [selectedDay, setSelectedDay] = useState(moment.tz(providerTimezone).format('YYYY-MM-DD'));

    const [showUnavailabilityDialog, setShowUnavailabilityDialog] = useState(false);
    const [selectedDatesRange, setSelectedDatesRange] = useState<DatesRange | undefined>(undefined);
    const [selectedUnavailability, setSelectedUnavailability] = useState<Unavailability | undefined>(undefined);
    const navigate = useNavigate();

    const { t, ready } = useTranslation('calendaring', { useSuspense: false });

    const scheduleQuery = useScheduleQuery();

    const getDayCalendarEvents: EventSourceFunc = (dateRange, successCallback, _) => {
        successCallback(parseDayCalendarEvents(scheduleQuery.data, JSON.stringify(dateRange), providerTimezone));
    }
    const getWeekCalendarEvents: EventSourceFunc = (dateRange, successCallback, _) => {
        successCallback(parseWeekCalendarEvents(scheduleQuery.data, JSON.stringify(dateRange), providerTimezone));
    }
    const getMonthCalendarEvents: EventSourceFunc = (dateRange, successCallback, _) => {
        successCallback(parseMonthCalendarEvents(scheduleQuery.data, JSON.stringify(dateRange), providerTimezone));
    }

    useEffect(() => {
        calendarRef.current?.getApi().refetchEvents();
        dayViewRef.current?.getApi().refetchEvents();
    }, [scheduleQuery.data, providerTimezone]);

    const handleDateClick = (arg: DateClickArgKeyboard) => {
        const calendarTimezone = arg.view.getOption('timeZone') as string;
        const date = moment.tz(arg.dateStr, calendarTimezone);

        setSelectedDay(date.format('YYYY-MM-DD'));
        setWeekCalendarPeriodStart(date.startOf('week').format('YYYY-MM-DD'));
        
        if (arg.jsEvent instanceof KeyboardEvent) {
            setTimeout(() => {
                const calendar = document.querySelector('.day_view_calendar') as HTMLDivElement;
                calendar.querySelector('a')?.focus();
            }, 0);
        }
    }

    const handleDateSelected = (date: string) => {
        setSelectedDay(date);
        setMonthCalendarPeriodStart(moment(date).startOf('month').format('YYYY-MM-DD'));
        setWeekCalendarPeriodStart(moment(date).startOf('week').format('YYYY-MM-DD'));
    }

    const handleMonthCalendarDatesSet = (arg: DatesSetArg) => {
        const range = moment.range(moment.tz(arg.startStr, arg.timeZone), moment.tz(arg.endStr, arg.timeZone));
        setMonthCalendarPeriodStart(range.center().startOf('month').format('YYYY-MM-DD'));
    }

    const handleWeekCalendarDatesSet = (arg: DatesSetArg) => {
        const start = moment.tz(arg.startStr, arg.timeZone).weekday(1);
        setWeekCalendarPeriodStart(start.format('YYYY-MM-DD'));

        const center = moment.range(start, moment.tz(arg.endStr, arg.timeZone)).center();
        setMonthCalendarPeriodStart(center.startOf('month').format('YYYY-MM-DD'));
    }

    const handleTimeSlotSelected = (arg: DateSelectArgKeyboard) => {
        const anchor = {
            clientWidth: 0,
            clientHeight: 0,
            getBoundingClientRect: () =>
                arg.jsEvent instanceof MouseEvent
                    ? {
                        left: arg.jsEvent?.x,
                        right: arg.jsEvent?.x,
                        x: arg.jsEvent?.x,
                        top: arg.jsEvent?.y,
                        bottom: arg.jsEvent?.y,
                        y: arg.jsEvent?.y,
                        width: 0,
                        height: 0
                    }
                    : arg.jsEvent?.target?.['getBoundingClientRect']?.()
        };

        selectEventType({anchorEl: anchor, canBookAppointments: isStaffProvider})
            .then(result => {
                switch (result) {
                    case EventType.Unavailability: {
                        setSelectedUnavailability(undefined);
                        setSelectedDatesRange({start: arg.start, end: arg.end});
                        setShowUnavailabilityDialog(true);
                        break;
                    }
                    case EventType.Reservation: {
                        navigate('/sessions/book/step1', {
                            state: {
                                startDate: arg.start,
                                entryRoute: '/calendar'
                            }
                        })
                        break;
                    }
                    default: {
                        if (arg.jsEvent instanceof KeyboardEvent) {
                            arg.jsEvent.target?.['focus']?.();
                        }
                    }
                }
            });
    }

    const handleAddUnavailabilityClick = () => {
        setSelectedUnavailability(undefined);
        setSelectedDatesRange(undefined);
        setShowUnavailabilityDialog(true);
    }

    const handleAddAppointmentClick = () => {
        navigate('/sessions/book/step1', {
            state: { entryRoute: '/calendar' }
        });
    }

    const handleEventClick = (arg: EventClickArgKeyboard) => {
        switch (arg.event.extendedProps.type) {
            case EventType.Unavailability: {
                const unavailability = mapUnavailabilityItemToUnavailability(arg.event.extendedProps as UnavailabilityItem);
                setSelectedUnavailability(unavailability);
                setShowUnavailabilityDialog(true);
                break;
            }
            case EventType.Reservation: {                 
                navigate(`/clients/${arg.event.extendedProps.activityId}/sessions/${arg.event.extendedProps.sessionId}/status`);
                // navigate('/sessions/setstatus', {
                //     startDate: moment.tz(arg.event.extendedProps.startTime, providerTimezone).toDate(),
                //     sessionId: arg.event.extendedProps.sessionId,
                //     activityId: arg.event.extendedProps.activityId,
                //     entryRoute: '/calendar',
                //     clientPhoneNumber: arg.event.extendedProps.clientPhoneNumber,
                //     clientEmail: arg.event.extendedProps.clientEmail,
                //     providerWillContactClient: arg.event.extendedProps.providerWillContactClient,
                // })
                break;
            }
            default: {

            }
        }
    }

    const handleUnavailabilitySaved = (_: ExecutionResult<SaveUnavailabilityMutationData> | undefined) => {
        setShowUnavailabilityDialog(false);
        scheduleQuery.refetch();
    }

    const handleUnavailabilityDeleted = (_: ExecutionResult<DeleteUnavailabilityMutationData> | undefined) => {
        setShowUnavailabilityDialog(false);
        scheduleQuery.refetch();
    }

    const handleSkipNavigationClick = () => {
        if (calendarContainerRef.current) {
            if (calendarType === CalendarType.Month) {
                const el = calendarContainerRef.current.querySelector('div.fc-highlight')
                    ?.parentElement?.parentElement?.parentElement
                    ?.querySelector('a.fc-daygrid-day-number');
                if (el instanceof HTMLElement && !el.hasAttribute('disabled')) {
                    setTimeout(() => {
                        el.focus();

                    }, 0);
                    return;
                }
            }
            const allFocusable = Array.from(calendarContainerRef.current.querySelectorAll('a.fc-timegrid-event, a.fc-daygrid-day-number'));
            for (const el of allFocusable) {
                if (el instanceof HTMLElement && !el.hasAttribute('disabled')) {
                    
                    setTimeout(() => {
                        el.focus();

                    }, 0);
                    return;
                }
            }
        }
    }

    return (
        <React.Fragment>
            { ready && 
                <PageMetadata 
                    pageDescription={t('calendaring_page_description')} 
                    pageTitle={t('calendaring_page_title')}
                /> 
            }
            <div className='global_calendar_view_container'>
            { 
                scheduleQuery.loading
                ? <Spinner />
                : <>
                    {
                        calendarType === CalendarType.Month &&
                        <div className="day_view_calendar_container">
                            <DayView
                                selectedDate={selectedDay}
                                onDatesSet={(arg) => handleDateSelected(arg.start.toJSON())}
                                events={getDayCalendarEvents}
                                onTimeSlotSelected={handleTimeSlotSelected}
                                onEventClick={handleEventClick}
                                ref={dayViewRef}
                            />
                        </div>
                    }
                    <div className="full_calendar_container">
                        <CalendaringToolbar
                            calendarType={calendarType}
                            selectedDate={calendarType === CalendarType.Month ? monthCalendarPeriodStart : weekCalendarPeriodStart}
                            canBookAppointments={isStaffProvider}
                            onSkipNavigationClick={handleSkipNavigationClick}
                            onNextPeriodClick={() => calendarRef.current?.getApi().next()}
                            onPreviousPeriodClick={() => calendarRef.current?.getApi().prev()}
                            onCalendarTypeChange={setCalendarType}
                            onDateSelected={handleDateSelected}
                            onAddUnavailabilityClick={handleAddUnavailabilityClick}
                            onAddAppointmentClick={handleAddAppointmentClick}
                        />
                        <div className="calendar_view_wrapper" ref={calendarContainerRef}>
                            {
                                calendarType === CalendarType.Month &&
                                <MonthView
                                    events={getMonthCalendarEvents}
                                    selectedDate={selectedDay}
                                    onDatesSet={handleMonthCalendarDatesSet}
                                    selectedPeriodStart={monthCalendarPeriodStart}
                                    onDateClick={handleDateClick}
                                    onEventClick={handleEventClick}
                                    ref={calendarRef}
                                />
                            }
                            {
                                calendarType === CalendarType.Week &&
                                <WeekView
                                    events={getWeekCalendarEvents}
                                    onTimeSlotSelected={handleTimeSlotSelected}
                                    onDatesSet={handleWeekCalendarDatesSet}
                                    selectedPeriodStart={weekCalendarPeriodStart}
                                    onEventClick={handleEventClick}
                                    ref={calendarRef}
                                />
                            }
                        </div>
                    </div>
                </>
            }
            </div>
            <UnavailabilityDialog
                open={showUnavailabilityDialog}
                selectedUnavailability={selectedUnavailability}
                datesRange={selectedDatesRange}
                onClose={() => setShowUnavailabilityDialog(false)}
                onUnvailabilitySaved={handleUnavailabilitySaved}
                onUnavailabilityDeleted={handleUnavailabilityDeleted}
            />
        </React.Fragment>
    );
}

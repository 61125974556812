import React, { FC, useState } from 'react';
import { UnavailabilityReason } from './queries';
import { UnavailabilityForm, Unavailability, Address } from 'components/Calendaring/Unavailabilities';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { IconButton } from '@mui/material';
import { ExecutionResult } from 'graphql';
import { IAutoCompleteValue, Spinner } from 'components/Shared';
import { useTranslation } from 'react-i18next';
import { parseUavailabilityName } from 'components/Calendaring/Unavailabilities/utils';
import { IWspTaskCode } from 'hooks/useWspTaskCodes/interfaces';
import moment from 'moment';
import { useTimezoneUtils } from 'hooks/useTimezoneUtils';

interface UnavailabilitiesListItemProps {
    unavailability: Unavailability;
    unavailabilityReasons: UnavailabilityReason[];
    pendingUnavailabilities?: Unavailability[];
    addresses?: Address[];
    onSubmit: (unavailability: Unavailability) => Promise<ExecutionResult<any>>;
    onDelete?: (unavailability: Unavailability) => Promise<ExecutionResult<any>>;
    managedCalendar: boolean;
    wspTaskCodes?: Array<IWspTaskCode>;
    intercessionWspTasks?: Array<IWspTaskCode>;
    wspClients?: IAutoCompleteValue[];
}

export const UnavailabilitiesListItem: FC<UnavailabilitiesListItemProps> = (props) => {
    const {
        unavailability,
        unavailabilityReasons,
        pendingUnavailabilities,
        managedCalendar,
        addresses,
        onDelete,
        onSubmit
    } = props;

    const { t, i18n } = useTranslation('unavailabilities', { useSuspense: false });
    const [editing, setEditing] = useState(false);
    const [requestPending, setRequestPending] = useState(false);

    
    const { createDateFormatFunction, DATE_PORTION_MOMENT_FORMAT } = useTimezoneUtils();
    const formatDate = createDateFormatFunction(DATE_PORTION_MOMENT_FORMAT);


    const weekDays = {
        1: t('Monday'),
        2: t('Tuesday'),
        3: t('Wednesday'),
        4: t('Thursday'),
        5: t('Friday'),
        6: t('Saturday'),
        7: t('Sunday')
    };

    const handleEditClick = () => {
        setEditing(true);
    };

    const handleCancelEditingClick = () => {
        setEditing(false);
    };

    const getRecurrenceDescription = () => {
        if (!unavailability.recurring) {
            return null;
        } else {
            const recurringDays = unavailability.recurringDays;
            let description = t('recurring_every');

            for (const dayNumber of recurringDays || []) {
                description += ` ${weekDays[dayNumber]} `;
            }

            if (unavailability.endDate) {
                description += `${t('until')} ${unavailability.endDate.toLocaleDateString(i18n.language)}`;
            }

            return description;
        }
    };

    const handleDeleteUnavailability = () => {
        if (onDelete) {
            setRequestPending(true);
            onDelete(unavailability).finally(() => setRequestPending(false));
        }
    };

    const renderUnavailbilitiesForm = () => {
        return (
            <UnavailabilityForm
                unavailability={unavailability}
                unavailabilityReasons={unavailabilityReasons}
                pendingUnavailabilities={pendingUnavailabilities}
                managedCalendar={managedCalendar}
                addresses={addresses}
                onCancel={handleCancelEditingClick}
                onSubmit={onSubmit}
                onDelete={onDelete}
                wspTaskCodes={props.wspTaskCodes}
                intercessionWspTasks={props.intercessionWspTasks}
                wspClients={props.wspClients}
            />
        );
    };

    const isWspUnavailability = () => Boolean(unavailability?.wspTaskCode);

    const isWSPDeletable = () => {
        const matchingWspTaskCode = props.wspTaskCodes?.find(
            (wspTaskCode) => wspTaskCode.value === unavailability?.wspTaskCode
        );
        return matchingWspTaskCode ? matchingWspTaskCode.editable : true;
    };

    const renderPotentialWSPSubheading = () => {
        if (isWspUnavailability()) {
            const matchingWspTaskCode = props.wspTaskCodes?.find(
                (wspTaskCode) => wspTaskCode.value === unavailability?.wspTaskCode
            );
            return matchingWspTaskCode ? (
                <p className="global__paragraph global__paragraph--light wsp_subheading">{matchingWspTaskCode.title}</p>
            ) : null;
        }
    };

    const renderNormalUnavailabilityControls = () => {
        if (unavailability?.reason?.editable) {
            return (
                <div className="unavailability_item_controls">
                    <IconButton className="unavailability_item_control_icon" onClick={handleEditClick}>
                        <EditIcon />
                    </IconButton>
                    <IconButton className="unavailability_item_control_icon" onClick={handleDeleteUnavailability}>
                        <DeleteIcon />
                    </IconButton>
                </div>
            );
        }
    };

    const renderWSPControls = () => {
        return (
            <div className="unavailability_item_controls">
                <IconButton className="unavailability_item_control_icon" onClick={handleEditClick}>
                    <EditIcon />
                </IconButton>
                {isWSPDeletable() && (
                    <IconButton className="unavailability_item_control_icon" onClick={handleDeleteUnavailability}>
                        <DeleteIcon />
                    </IconButton>
                )}
            </div>
        );
    };

    const renderControlsSection = () => {
        return isWspUnavailability() ? renderWSPControls() : renderNormalUnavailabilityControls();
    };

    const startDate = unavailability.startDate ? moment(unavailability.startDate) : undefined;
    const endDate = unavailability.endDate ? moment(unavailability.endDate) : undefined;

    return !editing ? (
        <div className="unavailability_item_container not_editable">
            {
                <React.Fragment>
                    {requestPending && <Spinner fillParent backdrop />}
                    <div className="unavailability_item_details_container">
                        <h3 className="global__header3 unavailable_reason">
                            {parseUavailabilityName(unavailability?.reason?.description)}
                        </h3>
                        {renderPotentialWSPSubheading()}
                        <p className="date_range global__paragraph global__paragraph--light">
                            {startDate && formatDate(startDate)}
                            {' - '}
                            {endDate && formatDate(endDate)}
                        </p>
                        {unavailability.recurring && getRecurrenceDescription()}
                    </div>
                    <div className="unavailability_item_controls_container">{renderControlsSection()}</div>
                </React.Fragment>
            }
        </div>
    ) : (
        renderUnavailbilitiesForm()
    );
};

import React, { useState } from 'react';
import { ToDo, ToDoPrompt } from 'components/ToDos';
import { JumbotronPager } from '../JumbotronPager';
import { Container } from '@mui/material';
import './DashboardJumbotron.scss';
import { useTranslation } from 'react-i18next';
import { Spinner } from 'components/Shared';
import { LEFT_ARROW_CODE, RIGHT_ARROW_CODE } from 'constants/keyboardCodes'

interface DashboardJumbotronProps {
    toDos: ToDo[] | undefined,
    loading: boolean
}

export const DashboardJumbotron: React.FC<DashboardJumbotronProps> = ({toDos, loading}) => {
    const { t } = useTranslation('dashboard', { useSuspense: false });
    const [currentIdx, setIdx] = useState(0);

    const currentTodo = toDos?.find((_, idx) => idx === currentIdx); 

    const handleKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
        if (toDos) {
            if (currentIdx > 0 && e.code === LEFT_ARROW_CODE) {
                setIdx(currentIdx - 1)
            }
            else if (currentIdx < toDos.length - 1 && e.code === RIGHT_ARROW_CODE) {
                setIdx(currentIdx + 1)
            }
        }
    }

    return (
        <div className="jumbotron_container" onKeyDown={handleKeyDown} tabIndex={0}>
        {
            loading
                ? <Spinner/>
                : currentTodo
                    ? <React.Fragment>
                        <Container maxWidth="md" aria-live="polite" aria-labelledby="prompt_card_header" className="jumbotron_container_prompt">
                            <div aria-label={`Item ${currentIdx + 1} of ${toDos?.length}`}>
                                <ToDoPrompt toDo={currentTodo}/>
                            </div>
                        </Container>
                        <JumbotronPager total={toDos!.length} index={currentIdx} onChange={setIdx} />
                    </React.Fragment>
                    : <div>{t('dashboard_no_todos_message', 'No pending To-dos')}</div>
        }
        </div>
    )
}

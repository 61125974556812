import { gql, TypedDocumentNode } from '@apollo/client';
import { ContractedService, InsurancePanel, Language, OfficeAddress, OrganizationException, SmsPhoneNumber } from '../../../interfaces';

export const PRACTICE_INFO_QUERY: TypedDocumentNode<PracticeInfoQueryData, PracticeInfoQueryVars> = gql`
    query PRACTICE_INFO_QUERY($providerId: Long!) {
        provider(id: $providerId) {
            id
            providerId
            contactInfo {
                addresses {
                    addressLine1
                    addressLine2
                    countryCode
                    countryName
                    zIP_Postal
                    state_ProvinceCode
                    officeType
                    officeTypeString
                    addressExpiryDate
                    addressEffectiveDate
                    addressPhone {
                        phoneNumber
                        phoneType
                        phoneTypeString
                        leaveMessage
                        preferredLine
                        preferredConfidentialLine
                        providerPhoneId
                    }
                    #wheelchair
                    #officehours
                    #officeconnection
                    #publictransport
                    #officeinstructions
                }
            }
            details {
                preferredCommunicationForNonConnected
                preferredCommunicationForNonConnectedString
                showInsurancePanels
                contractedServices {
                    modality
                    service
                    endDate
                    startDate
                }
                insurancePanels {
                    insuranceId
                    insuranceCompany
                }
                servedLanguages {
                    title
                    value
                }
                organizationExceptions {
                    organizationExceptionId
                    exceptionTypeCode
                    exceptionTypeString
                    organizationID
                    organizationString
                    isPermanent
                    startDate
                    endDate
                    comments
                }
                allowSmsCommunication
                allowEmailCommunication
                emailAddress
                smsPhoneNumbers {
                    phoneId
                    phoneNumber
                    receiveSms
                }
            }
        }
    }
`

export interface PracticeInfoQueryData {
    provider: {
        id: number,
        contactInfo?: {
            addresses?: OfficeAddress[]
        },
        details?: {
            preferredCommunicationForNonConnected?: number,
            preferredCommunicationForNonConnectedString?: string,
            showInsurancePanels?: boolean,
            contractedServices?: ContractedService[],
            insurancePanels?: InsurancePanel[],
            servedLanguages?: Language[],
            organizationExceptions?: OrganizationException[],
            allowSmsCommunication?: boolean,
            allowEmailCommunication?: boolean,
            emailAddress?: string,
            smsPhoneNumbers?: SmsPhoneNumber[]
        }
    }
}

export interface PracticeInfoQueryVars {
    providerId: number
}
import { CN_PREASSESSMENT_FORM_PREFIX, TPH_ASSESSMENT_FORM_PREFIX } from "components/Forms/constants";
import { IFormInfo, IPreAssessment } from "components/Forms/interfaces";
import isObject from "lodash/isObject";
import { PHQ9Result_12, PHQ9Result_13 } from "./PHQ9/interfaces";

type FormResult = Record<string, any>;

const slowClone = (obj: FormResult) => {
    try {
        return JSON.parse(JSON.stringify(obj))
    } catch(exception) {
        return null;
    }
};

const traverse = (obj: FormResult) => {
    for (let i in obj) {
        if (!!obj[i] && typeof obj[i] === 'object' && obj[i] !== null) {
            traverse(obj[i]);
        } else {
            obj[i] = null;
        }
    }
    return obj;
}

const clearAllPrimitiveFields = obj => {
    const clone = slowClone(obj);
    const clearedObj = traverse(clone);
    return clearedObj;
}

export const mergeClientResults = (forms: IFormInfo[] = [], currentFormVersion: string, onlyClient2: boolean = false): string | null => {
    let result: string | null = null;
    if (!forms.length) return result;
    let [ clientForm1, clientForm2 ] = forms;
    clientForm1 = { ...clientForm1 }; // prevent the readonly warning on result field
    clientForm1.result = isObject(clientForm1?.result) ? clientForm1?.result : JSON.parse(clientForm1?.result || '{}') as any;
    if (clientForm2) {
        clientForm2 = { ...clientForm2 };
        clientForm2.result = isObject(clientForm2?.result) ? clientForm2?.result : JSON.parse(clientForm2?.result || '{}') as any;
    }

    if (onlyClient2) {
        clientForm1 = { ...forms[0], id: undefined as unknown as string, result: clearAllPrimitiveFields(isObject(forms[0].result) ? forms[0].result : JSON.parse(forms[0].result)) as any };
        clientForm2 = { ...forms[0], result: isObject(forms[0].result) ? forms[0].result : JSON.parse(forms[0].result) };
    }

    switch (forms[0]?.templateName?.substring(CN_PREASSESSMENT_FORM_PREFIX.length)) {
        case 'wos': {
            const client1Result = clientForm1.result as unknown as FormResult;
            const client2Result = clientForm2?.result as unknown as FormResult;
            const combinedResult = { 
                wos_client: [
                    ...(client1Result.wos_client || []) as FormResult[],
                    ...(client2Result?.wos_client || []) as FormResult[]
                ].filter(entry => !!entry)
            }
            result = JSON.stringify(combinedResult);
            break;
        }
        case 'phq9': {
            const client1Result = clientForm1.result as unknown as PHQ9Result_12 | PHQ9Result_13;
            const client2Result = clientForm2?.result as unknown as PHQ9Result_12 | PHQ9Result_13;
            let combinedResult: FormResult = {};
            let matchingVersions = clientForm1?.templateVersion === currentFormVersion;
            if (matchingVersions && currentFormVersion < "1.3.0" && !!clientForm1.result) {
                combinedResult = { 
                    phq9_result: [
                        ...((client1Result as PHQ9Result_12).phq9_result || []),
                        ...((client2Result as PHQ9Result_12)?.phq9_result || [])
                    ].filter(entry => !!entry),
                    phq9_entered: (client1Result as PHQ9Result_12).phq9_entered || (client2Result as PHQ9Result_12).phq9_entered,
                    phq9_not_entered_reason: (client1Result as PHQ9Result_12).phq9_entered === "no" ? "1" : undefined,
                }
            } else if (currentFormVersion >= "1.3.0" && !matchingVersions) {
                const result1 = client1Result as PHQ9Result_12;
                const result2 = client2Result as PHQ9Result_12;
                combinedResult = {
                    phq9_assessments: [
                        {
                            phq9_entered_pre: result1.phq9_entered,
                            phq9_not_entered_reason_pre: result1.phq9_entered === "no" ? "1" : undefined,
                            score_pre: result1.phq9_result?.[0]?.score,
                            questions_pre: result1.phq9_result?.[0]?.questions,
                            level_of_difficulty_pre: result1.phq9_result?.[0]?.level_of_difficulty
                        },
                        result2 ? {
                            phq9_entered_pre: result2.phq9_entered,
                            phq9_not_entered_reason_pre: result2.phq9_entered === "no" ? "1" : undefined,
                            score_pre: result2.phq9_result?.[0]?.score,
                            questions_pre: result2.phq9_result?.[0]?.questions,
                            level_of_difficulty_pre: result2.phq9_result?.[0]?.level_of_difficulty
                        } : undefined
                    ].filter(entry => !!entry)
                } as PHQ9Result_13
            } else {
                combinedResult = {
                    phq9_assessments: [
                        (client1Result as PHQ9Result_13).phq9_assessments[0],
                        (client2Result as PHQ9Result_13)?.phq9_assessments?.[0]
                    ].filter(entry => !!entry),
                }
            }
            result = JSON.stringify(combinedResult);
            break;
        }
        case 'questionnaire':
        case 'statementofunderstanding': {
            const client1Result = clientForm1.result as unknown as FormResult;
            const client2Result = clientForm2?.result as unknown as FormResult;
            const combinedResult = { 
                client_responses: [
                    ...((client1Result.client_responses 
                            ? client1Result.client_responses 
                            : [client1Result]) || []) as FormResult[],
                    ...((client2Result?.client_responses
                            ? client2Result.client_responses
                            : [client2Result]) || []) as FormResult[]
                ].filter(entry => !!entry)
            }
            result = JSON.stringify(combinedResult);
            break;
        }
        default:
            result = forms[0]?.result;
    }
    return result;
};

// utility method to set the preassessment results to current form. 
// NB: this function mutates the first parameter passed in
export const appendPreAssessmentResults = (form: IFormInfo, preAssessments: IPreAssessment[]): void => {
    const formShortName = form.templateName.substring(TPH_ASSESSMENT_FORM_PREFIX.length);
    const preAssessmentForms: (IFormInfo | undefined)[] = [];
    const onlyClient2 = preAssessments.length === 1 ? !!preAssessments[0].additionalIndividualId : false;
    preAssessments?.forEach(preAssmt => {
        let matchingForm = preAssmt.forms
                                .find(f => f.templateName.substring(CN_PREASSESSMENT_FORM_PREFIX.length) === formShortName);
        if (matchingForm) {
            // to get rid of the readonly limitation of the result field, 
            // we need clone it
            matchingForm = { ...matchingForm };
            preAssessmentForms.push(matchingForm);
        }
        // if sou and questionnaire results are not in the new format, update them to match new template
        if (
            matchingForm
            && ['questionnaire', 'statementofunderstanding']
                .includes(matchingForm?.templateName.substring(CN_PREASSESSMENT_FORM_PREFIX.length) as string)
        ) {
            if (!matchingForm.result.includes('client_responses')) {
                matchingForm.result = `{ "client_responses": [${matchingForm.result}] }`;
            }
        }
    });
    form.result = mergeClientResults(preAssessmentForms as IFormInfo[], form.templateVersion, onlyClient2) as string;
}

import React, { FC, useMemo } from 'react';
import { Box, MenuItem } from '@mui/material';
import { useTranslation } from 'react-i18next';
import moment from 'moment-timezone';
import { useFormik } from 'formik';
import { FilterOptions, TimePreference } from '../../interfaces';
import { Select, DateInput, Button } from 'components/Shared/Inputs';
import { useQuery } from '@apollo/client';
import { APPT_BOOKING_REF } from '../../queries';
import './ScheduleSearchFilters.scss';
import { Spinner } from 'components/Shared';

interface ScheduleSearchFiltersProps {
    filters: FilterOptions,
    offices: any[] | undefined,
    onFiltersChange: (filters: FilterOptions) => void
}

interface ScheduleSearchFiltersFormValues extends FilterOptions { }

export const ScheduleSearchFilters: FC<ScheduleSearchFiltersProps> = (props) => {
    const { filters, offices, onFiltersChange } = props;
    const { t } = useTranslation(['calendaring', 'common'], { useSuspense: false });

    const refDataQuery = useQuery(APPT_BOOKING_REF);
    const datePreferencesRef = refDataQuery.data?.refValues.datePreferences;
    const timePreferencesRef = refDataQuery.data?.refValues.timePreferences;

    const formik = useFormik<ScheduleSearchFiltersFormValues>({
        initialValues: { ...filters },
        onSubmit: (values) => {
            onFiltersChange(values);
        }
    });

    const handleStartDateChange = (date) => {
        formik.setFieldValue('startDate', date?.format('YYYYMMDD'));
    }

    const handleOfficeChange = (event) => {
        const value = event.target.value;
        formik.setFieldValue('officeId', value === 'any' ? null : value);
    }

    const renderFilterByDate = () => (
        <div className="filters-section">
            <p className="global__paragraph">{t('filter_by_date', 'Filter by Date')}</p>
            <Select
                value={formik.values.datePreference}
                name="datePreference"
                onChange={formik.handleChange}
                label={t('date_preferences')}
                className="filters-input"
            >
                {
                    datePreferencesRef?.map(ref => (
                        <MenuItem value={ref.value} key={ref.value}>{ref.title}</MenuItem>
                    ))
                }
            </Select>
            <DateInput
                className="filters-input"
                format="L"
                // margin="normal"
                // autoOk 
                disabled={formik.values.datePreference === 'any'}
                // id="date-picker-inline"
                label={t('date')}
                value={moment(formik.values.startDate, 'YYYYMMDD')}
                onChange={handleStartDateChange}
                // KeyboardButtonProps={{
                //     'aria-label': 'change date',
                // }}
                // cancelLabel={t('common:cancel')}
                // okLabel={t('common:ok')}
                // invalidDateMessage={t('common:invalid_date_format')}
            />
        </div>
    )


    const timeOptions = useMemo(() => {
        var time = moment({hours: 0, minutes: 0});
        const options: {value: string, title: string}[] = [];

        for (let i = 0; i < 24; ++i) {
            options.push({
                value: time.format('hh:mm a'),
                title: time.format('LT')
            });
            time.add(1, 'hour');
        }
        return options;
    }, [moment.locale()]);

    const startTimeDisabled = formik.values.timePreference === TimePreference.any
        || formik.values.timePreference === TimePreference.aftn
        || formik.values.timePreference === TimePreference.mor
        || formik.values.timePreference === TimePreference.eve;

    const renderFilterByTime = () => (
        <div className="filters-section">
            <p className="global__paragraph">{t('filter_by_time', 'Filter by Time')}</p>
            <Select
                value={formik.values.timePreference}
                name="timePreference"
                onChange={formik.handleChange}
                label={t('time_preferences')}
                className="filters-input"
            >
                {
                    timePreferencesRef?.map(ref => (
                        <MenuItem value={ref.value} key={ref.value}>{ref.title}</MenuItem>
                    ))
                }
            </Select>
            <Select
                value={formik.values.startTime}
                onChange={formik.handleChange}
                name="startTime"
                disabled={startTimeDisabled}
                label={t('time')}
                className="filters-input"
            >
                {
                    timeOptions?.map(ref => (
                        <MenuItem value={ref.value} key={ref.value}>{ref.title}</MenuItem>
                    ))
                }
            </Select>
        </div>
    )


    const renderOffice = () => (
        <div className="filters-section">
            <p className="global__paragraph">{t('filter_by_office', 'Filter by Office')}</p>
            <Select
                value={formik.values.officeId === null ? 'any' : formik.values.officeId}
                onChange={handleOfficeChange}
                name="officeId"
                label={t('office')}
                className="filters-input"
            >
                <MenuItem value={'any'} key={'any'}>{t('Any')}</MenuItem>
                {
                    offices
                        ?.filter(office => office.officeType !== "MAIL")
                        ?.map(office => (
                            <MenuItem value={office.providerAddressId} key={office.providerAddressId}>
                                {office.addressLine1}
                            </MenuItem>
                        ))
                }
            </Select>
        </div>
    )


    return (
        <Box className="date-time-filter-root">
            {
                refDataQuery.loading
                    ? <Spinner fillParent/>
                    : <>
                        {renderFilterByDate()}
                        {renderFilterByTime()}
                        {renderOffice()}
                        <div className="filters-actions">
                            <Button variant="opaque" onClick={() => formik.handleSubmit()}>
                                Apply
                            </Button>
                        </div>
                    </>
            }
        </Box>
    )
}

import { gql, TypedDocumentNode } from '@apollo/client';
import { IIntercessionWspTaskCodeResponse, IWspTaskCodeResponse } from './interfaces';

export const WSP_TASK_CODES_QUERY: TypedDocumentNode<IWspTaskCodeResponse, {  }> = gql`
    query WSP_TASK_CODES_QUERY {
        wspTaskCodes {
            title
            value
            editable
        }
    }
`


export const INTERCESSION_WSP_TASK_CODES_QUERY: TypedDocumentNode<IIntercessionWspTaskCodeResponse, {  }> = gql`
    query INTERCESSION_WSP_TASK_CODES_QUERY {
        intersessionWspTaskCodes {
            title
            value
            editable
        }
    }
`
import { gql, TypedDocumentNode } from "@apollo/client";
import { Specialization } from "components/ProviderProfile/interfaces";

export const SPECIALIZATIONS_REF_DATA
    : TypedDocumentNode<SpecializationsRefDataData, SpecializationsRefDataVars> = gql`
query SPECIALIZATIONS_REF_DATA {
    specializations {
        title
        value
    }
}
`

interface SpecializationsRefDataData {
    specializations: {
        title: string,
        value: string
    }[]
}

interface SpecializationsRefDataVars { }


export const UPDATE_SPECIALIZATIONS_MUTATION: TypedDocumentNode<UpdateSpecializationsMutationData, UpdateSpecializationsMutationVars> = gql`
    mutation UPDATE_SPECIALIZATIONS_MUTATION($providerId: Long!, $specializations: [Int!]) {
        updateCredentials(providerId: $providerId, specializations: $specializations) {
            providerId
            credential {
                specializations {
                    title
                    value
                }
            }
        }
    }
`

export interface UpdateSpecializationsMutationData {
    updateCredentials: {
        credential: {
            specializations: Specialization[]
        }
    }
}

export interface UpdateSpecializationsMutationVars {  
    providerId: number,
    specializations: number[]
}
import { useRef, useState } from "react";

interface UseFetchHookProps<TResult, TVariables> {
    url: string,
    fetch?: (input: RequestInfo, init?: RequestInit) => Promise<Response>,
    variables?: TVariables,
    onCompleted?: (result: TResult) => void
    onError?: (error: any) => void
}

type UseFetchHook<TResult, TVariables> = [
    (variables: TVariables) => Promise<TResult>,
    {
        data: TResult | null,
        loading: boolean,
        error: any | null,
        promise: Promise<TResult> | null
    }
]

export function useFetch<TResult, TVariables>(props: UseFetchHookProps<TResult, TVariables>): UseFetchHook<TResult, TVariables> {
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState<TResult | null>(null);
    const [error, setError] = useState<any | null>(null);
    let { current: promise } = useRef<Promise<TResult> | null>(null);

    const request = (variables: TVariables) => {
        setLoading(true);
        promise = (props?.fetch || fetch)(props.url, {
                method: 'POST',
                mode: 'cors',
                cache: 'no-cache',
                credentials: 'include',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(variables)
            }).then(response => (
                response.json()
            )).then(result => {
                setData(result);
                setError(null);
                props?.onCompleted?.(result);
                return result;
            }).catch(error => {
                setData(null);
                setError(error);
                props?.onError?.(error);
                throw error;
            }).finally(() => {
                setLoading(false);
                promise = null;
            });

        return promise;
    }
    
    return [
        request,
        {
            loading: loading,
            data: data,
            error: error,
            promise: null
        }
    ]
}

import React, { Fragment, FunctionComponent, useState, useLayoutEffect } from 'react';
import { Container, Grid, Divider } from '@mui/material';
import { useTranslation } from 'react-i18next';
import './ClientDetails.scss';
import { IActivityDetails } from '../../../../interfaces';
import { formatPhoneNumber } from 'utils/formatter';
import HelpIcon from '@mui/icons-material/HelpOutlineRounded';
import useTimezoneUtils from 'hooks/useTimezoneUtils';
import { useLocation } from 'react-router-dom';
import { CaseModelInfoDialog } from '../../../../components/CaseModelInfoDialog';
import { Spinner } from 'components/Shared';
import { Card } from 'components/Shared/Card';
import { Button } from 'components/Shared/Inputs';
import { getPreferredPhoneNumber } from 'components/CaseManagement/utils/activityDetailsUtils';
import { COUNTRY_CODE, getAppRegionCode, regionsToShowPreferredNumber } from 'utils/regionUtils';
import { useAuth } from 'components/Auth';
import { checkIfStaffProvider } from 'utils';

interface ClientDetailsProps {
    activity?: IActivityDetails
}

const TextLabel = ({ value, label }) => {
    return <div>
        <p className='client_details_field_value'>{value}</p>
        <p className='client_details_field_label'>{label}</p>
    </div>
}

export const ClientDetails: FunctionComponent<ClientDetailsProps> = ({ activity }) => {
    const { t, ready: translationsReady } = useTranslation(['clientdetails', 'calendaring'], { useSuspense: false });
    const { hash } = useLocation();

    useLayoutEffect(() => {
        if (hash && translationsReady) {
            setTimeout(() => document.getElementById(hash.slice(1))?.scrollIntoView(), 0);
        }
    }, [hash, translationsReady]);

    const { createDateFormatFunctionWithoutTimezoneConversion, DATE_PORTION_MOMENT_FORMAT } = useTimezoneUtils();

    const formatWithoutTimezone = createDateFormatFunctionWithoutTimezoneConversion(DATE_PORTION_MOMENT_FORMAT);

    const [showModelInfoDialog, setShowModelInfoDialog] = useState(false);

    const providerRegion = getAppRegionCode();
    const { effectiveProviderData } = useAuth();
    
    const checkIfDisplayPreferredPhoneNumber = (region: COUNTRY_CODE) => {
        const phones = activity?.client?.phones?.map(({ number }) => number);

        const showSessionPreferredNumber = regionsToShowPreferredNumber.includes(region)
                                           && activity?.modality === 'Tele-Counselling'
                                           && activity?.service === 'Counselling'
                                           && checkIfStaffProvider(effectiveProviderData);

        if (showSessionPreferredNumber && (activity?.sessions?.length || 0) > 0) {
            return activity?.sessions?.some(session => !!session.clientReservationPhoneNumber);
        }

        if (activity?.serviceForms?.clientPhoneNumber) {
            const preferredInDefault = phones?.includes(activity?.serviceForms?.clientPhoneNumber);
            return !preferredInDefault;
        }

        return false;
    }

    const renderAdditionalParticipants = () => {
        if (activity?.additionalParticipants && activity.additionalParticipants.length > 0) {
            return (
                <>
                    <Divider />
                    <p className="global__paragraph--light additional-participants-header">{t('calendaring:additional_participants', 'Additional Participants')}</p>
                    <Grid container>
                        {activity.additionalParticipants.map(participant => (
                            <Fragment key={participant.id}>
                                <Grid item xs={6}>
                                    <TextLabel
                                        value={participant.fullName}
                                        label={participant.relationType ?? t('n_a')}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <TextLabel
                                        value={participant.dateOfBirth ? formatWithoutTimezone(participant.dateOfBirth) : t('n_a')}
                                        label={t('client_details_date_of_birth')}
                                    />
                                </Grid>
                            </Fragment>
                        ))
                        }
                    </Grid>
                </>
            )
        }
    }   
    
    const caseModelGuideDocumentUrl = t('model_guide_url', { defaultValue: '' });

    return (
        <Container maxWidth="md">
            {
                !translationsReady
                ? <Spinner fillParent/>
                : <>
                    <Card className="client_details_paper personal-details">
                        <h3>{t('client_details_personal_details')}</h3>
                        <Grid container>
                            <Grid item xs={6}>
                                <TextLabel
                                    value={`${activity?.client?.firstName} ${activity?.client?.lastName}`}
                                    label={t('client_details_personal_full_name')}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextLabel value={activity?.client?.gender} label={t('client_details_personal_gender')} />
                            </Grid>
                            <Grid item xs={6}>
                                <TextLabel
                                    value={activity?.client.dateOfBirth ? formatWithoutTimezone(activity.client.dateOfBirth): t('n_a')}
                                    label={t('client_details_date_of_birth')}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextLabel
                                    value={activity?.client?.languagePreference}
                                    label={t('client_details_personal_language')}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextLabel
                                    value={activity?.eligibility ?? t('n_a', 'N/A')}
                                    label={t('client_details_personal_client_type')}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextLabel value={activity?.activityId} label={t('client_details_personal_activity_id')} />
                            </Grid>    
                        </Grid>
                        {renderAdditionalParticipants()}
                    </Card>
                    <Card className="client_details_paper">
                        <h3>{t('client_details_contact_info')}</h3>
                        <Grid container>
                            {activity?.client?.phones?.map((phone, index) => (
                                <Grid item xs={6} key={index}>
                                    <TextLabel
                                        value={formatPhoneNumber(phone.number)}
                                        label={t('client_details_contact_phone_type', {
                                            type: phone.phoneTypeCode?.toLowerCase(),
                                            messageType: phone.messageType
                                        })}
                                    />                                    
                                </Grid>
                            ))}
                            {checkIfDisplayPreferredPhoneNumber(providerRegion) &&
                            <TextLabel
                                        value={getPreferredPhoneNumber(activity, providerRegion, checkIfStaffProvider(effectiveProviderData))}
                                        label={t('client_details_contact_phone_preferred')}
                                    />}
                        </Grid>
                        <Grid container>
                            <Grid item xs={6}>
                                <TextLabel value={activity?.client?.email} label={t('client_details_contact_email')} />
                            </Grid>
                        </Grid>
                    </Card>
                    <Card className="client_details_paper">
                        <h3>{t('client_details_clinical_details')}</h3>
                        <p className="global__paragraph--small">
                            {t('client_details_clinical_opened_date', {
                                date: activity?.openDate ? formatWithoutTimezone(activity.openDate) : t('n_a', 'N/A')
                            })}
                        </p>
                        <Grid container>
                            <Grid item xs={6}>
                                <TextLabel value={activity?.service} label={t('client_details_clinical_service_type')} />
                            </Grid>
                            <Grid item xs={6}>
                                <TextLabel
                                    value={activity?.presentingIssue}
                                    label={t('client_details_clinical_presenting_issue')}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextLabel value={activity?.modality} label={t('client_details_clinical_modality')} />
                            </Grid>
                        </Grid>
                        <Divider />
                        <Grid container>
                            <Grid item xs={6}>
                                <TextLabel
                                    value={activity?.assessmentsScores?.PHQ9?.[0]?.score?.[0] ?? t('n_a', 'N/A')}
                                    label={t('client_details_clinical_PHQ9_first')}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextLabel
                                    value={activity?.assessmentsScores?.PHQ9?.[0]?.score?.[1] ?? t('n_a', 'N/A')}
                                    label={t('client_details_clinical_PHQ9_second')}
                                />
                            </Grid>
                        </Grid>
                        <Divider />
                        <Grid container>
                            <Grid item xs={6}>
                                <TextLabel
                                    value={activity?.urgencyLevelString ?? t('n_a', 'N/A')}
                                    label={t('client_details_clinical_risk_level')}
                                />
                            </Grid>
                            <Grid item xs={6}></Grid>
                        </Grid> 
                    </Card>                    
                    <Card className='client_details_paper' id="organization_details">
                        <h3>{t('client_details_organization_details')}</h3>
                        <Grid container>
                            <Grid item xs={6}>
                                <TextLabel value={activity?.organization?.name} label={t('client_details_organization_name')} />
                                <TextLabel
                                    value={<>
                                        {
                                            caseModelGuideDocumentUrl
                                            ? <a href={caseModelGuideDocumentUrl} target="_blank" rel="noopener noreferrer">
                                                {activity?.sessionInfo?.modelId}
                                            </a>
                                            : <span>{activity?.sessionInfo?.modelId}</span>
                                        }
                                        
                                        <Button round variant="text" onClick={() => setShowModelInfoDialog(true)} aria-label="Click for additional information regarding model"
                                            size="small"
                                        >
                                            <HelpIcon/>
                                        </Button>
                                    </>}
                                    label={t('client_details_organization_model_number')}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextLabel
                                    value={activity?.organization?.organizationId}
                                    label={t('client_details_organization_id')}
                                /> 
                            </Grid>
                        </Grid> 
                        <Card className="client_details_organization_sub_section" elevation={0} radius="small">
                            <TextLabel
                                value={t('client_details_organization_max_hours_client')}
                                label={t('client_details_organization_date_range', {
                                    startDate: activity?.clientMaxHoursStartDate
                                        ? formatWithoutTimezone(activity.clientMaxHoursStartDate)
                                        : t('n_a', 'N/A'),
                                    endDate: activity?.clientMaxHoursEndDate
                                        ? formatWithoutTimezone(activity.clientMaxHoursEndDate)
                                        : t('n_a', 'N/A')
                                })}
                            />
                        </Card>
                    </Card>
                    {
                        activity?.sessionInfo?.modelId &&
                        <CaseModelInfoDialog open={showModelInfoDialog} caseModel={activity.sessionInfo.modelId}
                            onClose={() => setShowModelInfoDialog(false)} />
                    }
                </>
            }
        </Container>
    );
}
import React, { FC } from 'react';
import { Modal, Paper } from '@mui/material';
import { confirmable, ReactConfirmProps, createConfirmation } from 'react-confirm';
import { useTranslation } from 'react-i18next';

const _ApplyToSeriesModal: FC<ReactConfirmProps> = (props) => {
    const { t } = useTranslation('providerprofile', { useSuspense: false });
    
    return (
        <Modal open={props.show} onClose={() => props.proceed('cancel')} className='series_modal_wrapper_div'>
            <Paper className='series_modal_content_div'>
                <p className='global__paragraph'>
                    {t('entry_part_of_series')}
                </p>
                <div className='series_modal_button_container'>
                    <button className='global__button' onClick={() => props.proceed('series')} >
                        {t('apply_to_series')}
                    </button>
                    <button className='global__button' onClick={() => props.proceed('one time')} >
                        {t('one_time')}
                    </button>
                </div>
            </Paper>
        </Modal>
    )
}

export const ApplyToSeriesModal = confirmable(_ApplyToSeriesModal);
export const applyToSeries = createConfirmation(ApplyToSeriesModal);
import React, { FC, useEffect } from 'react';
import { Container } from '@mui/material';
import { IFormByActivity, IPreAssessment } from '../../../../interfaces';
import { Link } from 'react-router-dom';
import { FormTitleKeysMap, FormStage, WspTemplateNames, TemplateNames } from 'components/Forms/constants';
import { useTranslation } from 'react-i18next';
import './ClinicalRecordsTab.scss';
import useTimezoneUtils from 'hooks/useTimezoneUtils';
import { TERMINATE_REVIEW } from 'components/ToDos/queries';
import { useMutation } from '@apollo/client';
import { useSnackbar } from 'notistack';
import { Card } from 'components/Shared/Card';
import { removeDuplicateSOU } from 'components/CaseManagement/utils/formStatusUtils';

interface ClinicalRecordsTabProps {
    activityId?: number;
    forms?: IFormByActivity[];
    preAssessments?: IPreAssessment[];
    fromToDo?: boolean;
}

function sortByCreationDate(forms?: IFormByActivity[]): IFormByActivity[] | undefined {
    if (forms) {
        const sortedForms = forms.slice();
        return sortedForms.sort((a, b) => new Date(a.createdOn).getTime() - new Date(b.createdOn).getTime());
    }
}

export const ClinicalRecordsTab: FC<ClinicalRecordsTabProps> = ({ activityId, forms, preAssessments, fromToDo }) => {
    const { t } = useTranslation('assessments', { useSuspense: false });

    const { formatDate, formatToProviderTime: formatTime } = useTimezoneUtils();
    const { enqueueSnackbar } = useSnackbar();

    const sortedForms = sortByCreationDate(forms?.filter(removeDuplicateSOU(forms))); 

    const [terminateToDo] = useMutation(TERMINATE_REVIEW, {
            context: {skipGlobalErrorHandling: true},
            onError: (error) => {
                console.error(error);
            },
            onCompleted: () => {
                enqueueSnackbar(t('pre_assessments_already_reviewed'), {variant: 'error'});
            }
        }
    )

    const getPreAssessmentFormUrl = (pa) => {
        return `/preassessments/${activityId}${
            pa.additionalIndividualId
                ? '/' + pa.additionalIndividualId
                : ''
        }`;
    }

    const closeAlreadyReviewedPreAssessments = () => {
        if (fromToDo)
        {
            let toClose = preAssessments?.find(pa => pa.status === FormStage.PreAssessmentReviewed && pa.additionalIndividualId === 0);
            if (toClose && activityId)
            {
                terminateToDo({ variables: { activityId: activityId } })            
            }
        }
    }

    const getFormattedTimestamp = (form) => {
        const date = new Date(form.updatedOn || form.createdOn);
        return `${formatDate(date, 'ddd ll')} | ${formatTime(date)}`;
    }    

    useEffect(() => {
        closeAlreadyReviewedPreAssessments();                
    }, [activityId, preAssessments, fromToDo]);

    const renderFormLink = (form: IFormByActivity) => (
        form.templateName === WspTemplateNames.InitialAssessmentReport ? (
            <Link to={{ pathname: `/clients/${activityId}/reports/${form.id}` }}>
                {t(`${FormTitleKeysMap[form.templateName]}`, { replace: { sessionId: form.sessionId } })}
            </Link>)
            : (<Link to={{ pathname: `/forms/${form.id}` }}>
                {t(`${FormTitleKeysMap[form.templateName]}`, { replace: { sessionId: form.sessionId } })}
            </Link>)
    )

    return (
        <Container maxWidth="md" className="activity_details_documents_container">
            <Card elevation={1} className="clinical_records_paper">
                {
                    preAssessments && preAssessments.length > 0 &&
                    <>
                        <h2>{t('pre-assessments')}</h2>
                        <div className="clinical_records_container">
                            {
                                preAssessments.map((pa) => (
                                    <div className="clinical_records_list_item" key={pa.additionalIndividualId}>
                                        <div className="clinical_records_list_item_left">
                                            {
                                                pa.url
                                                ? (
                                                    <a href={pa.url} target="_blank" rel="noopener noreferrer" className="color--link">
                                                        {pa.clientName}
                                                    </a>
                                                )
                                                : (
                                                    <Link className="color--link" to={{pathname: getPreAssessmentFormUrl(pa)}}>
                                                        {pa.clientName}
                                                    </Link>
                                                )
                                            }
                                        </div>
                                        <div className="clinical_records_list_item_right">
                                            <div>{t(pa.status)}</div>
                                        </div>
                                    </div>
                                ))
                            }
                        </div>
                    </>
                }
                <h2>{t('clinical_records')}</h2>
                <div className="clinical_records_container">
                    {
                        sortedForms?.map((form) => !(form.templateName === TemplateNames.SessionNote && form.updatedOn === null) && (
                            <div className="clinical_records_list_item" key={form.id}>
                                <div className="clinical_records_list_item_left">
                                    {renderFormLink(form)}
                                    <p className="clinical_record_timestamp">
                                        {getFormattedTimestamp(form)}
                                    </p>
                                </div>
                                <div className="clinical_records_list_item_right">
                                    <p>
                                        {t('FormUpdateBy-label', { replace: { name: form.updatedBy || form.createdBy }})}
                                    </p>
                                </div>
                            </div>
                        ))
                    }
                </div>
            </Card>
        </Container>
    );
};

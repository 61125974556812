import { useMutation } from '@apollo/client';
import { SAVE_UNAVAILABILITY_MUTATION, SaveUnavailabilityMutationVars, SaveUnavailabilityMutationData } from '../queries';
import { useSnackbar } from 'notistack';
import { useAuth } from 'components/Auth';
import { Unavailability } from '../interfaces';
import { applyToSeries } from '../components/ApplyToSeriesModal';
import { ExecutionResult } from 'graphql';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

export const useSubmitUnavailability = () => {
    const { enqueueSnackbar } = useSnackbar();
    const { effectiveProviderData } = useAuth();
    const { t } = useTranslation('unavailabilities', { useSuspense: false });

    const [addUnavailability] = useMutation<SaveUnavailabilityMutationData, SaveUnavailabilityMutationVars>(
        SAVE_UNAVAILABILITY_MUTATION,
        {
            errorPolicy: 'all',
            onCompleted: (data) => {
                if (data?.saveUnavailability === 1) {
                    enqueueSnackbar(t('unavailability_successfully_saved'), { variant: 'success' });
                }
            }
        }
    );

    const [updateUnavailability] = useMutation<SaveUnavailabilityMutationData, SaveUnavailabilityMutationVars>(
        SAVE_UNAVAILABILITY_MUTATION,
        {
            errorPolicy: 'all',
            onCompleted: (data) => {
                if (data?.saveUnavailability === 1) {
                    enqueueSnackbar(t('unavailability_successfully_updated'), { variant: 'success' });
                }
            }
        }
    );

    return (unavailability: Unavailability): Promise<ExecutionResult<SaveUnavailabilityMutationData>> => {
        const timezone = effectiveProviderData?.timeZone ?? '';
        const startTime = moment(unavailability.startTime, 'HH:mm:ss');
        const endTime = moment(unavailability.endTime, 'HH:mm:ss');

        const mutationVars: SaveUnavailabilityMutationVars = {
            startDate: moment.tz(unavailability.startDate, timezone).toISOString(true),
            endDate: moment.tz(unavailability.endDate, timezone).toISOString(true),
            providerId: effectiveProviderData?.providerId ?? 0,
            reasonId: unavailability.reason?.id ?? 0,
            username: effectiveProviderData?.providerId.toString()
        };
        mutationVars.startTime = unavailability.startTime;
        mutationVars.endTime = unavailability.endTime;

        if (unavailability.recurring) {
            mutationVars.daysOfTheWeek = unavailability.recurringDays;
        } else {
            mutationVars.startDate = moment(unavailability.startDate).set({
                hours: startTime.hours(),
                minutes: startTime.minutes(),
                seconds: startTime.seconds()
            }).toISOString(true);

            mutationVars.endDate = moment(unavailability.endDate).set({
                hours: endTime.hours(),
                minutes: endTime.minutes(),
                seconds: endTime.seconds()
            }).toISOString(true);
        }

        if (unavailability.activityId) {
            mutationVars.activityId = unavailability.activityId
        }
        
        if (unavailability.wspTaskCode) {
            mutationVars.wspTaskCode = unavailability.wspTaskCode
        }

        if (unavailability.scheduleId) {
            mutationVars.scheduleId = unavailability.scheduleId;

            if (unavailability.seriesId) {
                mutationVars.seriesId = unavailability.seriesId;
                return new Promise<ExecutionResult<any>>((resolve) => {
                    applyToSeries({}).then((response) => {
                        if (response === 'cancel') {
                            resolve({});
                        } else {
                            mutationVars.applyToSeries = response === 'series';
                            resolve(updateUnavailability({ variables: mutationVars }));
                        }
                    });
                });
            } else {
                return updateUnavailability({ variables: mutationVars });
            }
        } else {
            return addUnavailability({ variables: mutationVars });
        }
    };
};

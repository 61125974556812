import React from 'react';
import { OverridableComponent } from '@mui/material/OverridableComponent';
import clsx from 'clsx';

import './Card.scss';

interface CardProps {
    elevation?: 0 | 1 | 2,
    radius?: 'small' | 'regular'
}

interface CardTypeMap<P = {}, D extends React.ElementType = "div"> {
    props: P | CardProps;
    classKey: "className";
    defaultComponent: D;
}

export const Card: OverridableComponent<CardTypeMap> = (props) => {
    const { component: ComponentProp = "div", elevation = 1, radius = 'regular', className, ...other } = props;
    return <ComponentProp className={clsx("TPH_card", `elevation_${elevation}`, `radius_${radius}`, className)} {...other}/>
}

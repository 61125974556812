import React, { FC, useState, useRef } from 'react';
import { useAuth } from '../AuthProvider';
import { useFormik } from 'formik';
import { Alert } from '@mui/material';
import { Spinner } from 'components/Shared';
import { useTranslation } from 'react-i18next';
import { Button, TextBox } from 'components/Shared/Inputs';

interface SelectEffectiveProviderFormProps {
    onEffectiveProviderChanged: () => void
}

export const SelectEffectiveProviderForm: FC<SelectEffectiveProviderFormProps> = (props) => {
    const { t } = useTranslation(['login', 'common'], { useSuspense: false });
    const [loading, setLoading] = useState<boolean>(false);
    const auth = useAuth();
    const { onEffectiveProviderChanged } = props;
    const formRef = useRef(null);

    const formik = useFormik({
        initialValues: {
            providerId: auth.effectiveProviderData?.providerId && auth.effectiveProviderData?.providerId > 0
                ? auth.effectiveProviderData?.providerId?.toString()
                : '',
            graphQL: ''
        },
        validate: (values) => { 
            const errors: any = {};

            if (!values.providerId) {
                errors.providerId = t('common:id_is_required','ID is required');
            } else if (/[^0-9]/g.test(values.providerId)) {
                errors.providerId = t('common:enter_valid_ID','Enter valid ID');
            }

            return errors;
        },
        validateOnBlur: true,
        onSubmit: (values) => {
            formik.setTouched({providerId: true});
            formik.validateForm();

            if (formik.isValid && values.providerId) {
                setLoading(true);
                
                auth.setEffectiveProvider(Number.parseInt(values.providerId))
                    .then(response => {
                        if (!Boolean(response?.effectiveUserAuthData?.provider)) {
                            formik.setFieldError('graphQL', `${t('login:provider_with_ID','Provider with ID')} ${values.providerId} ${t('common:not_found','not found')}.`);
                        } else {
                            onEffectiveProviderChanged();
                        }
                    })
                    .catch((error) => {
                        if (error.graphQLErrors && error.graphQLErrors[0]?.message) {
                            formik.setFieldError('graphQL', error.graphQLErrors[0]?.message);
                        } else {
                            formik.setFieldError('graphQL', error.message);
                        }
                    })
                    .finally(() => {
                        if (formRef.current) { setLoading(false) }
                    });
            }
        }
    });

    const onInputChange = (event) => {
        event.target.value = event.target.value.replace(/[^0-9]/g, '');
        formik.handleChange(event);
    }

    const getErrorMessage = (): string | null => {
        if (formik.touched.providerId && formik.errors.providerId) {
            return formik.errors.providerId;
        }
        if (formik.errors.graphQL) {
            return formik.errors.graphQL;
        }
        return null;
    }

    return (
        <form onSubmit={formik.handleSubmit} className="login_screen_form" ref={formRef}>
            <h2 className="login_screen_form_title">{t('login:enter_provider_ID','Enter provider ID')}</h2>

            <p className="login_screen_form_text">{t('login:enter_ID_of_provider','Enter provider ID')}</p>

            <TextBox 
                onChange={onInputChange}
                value={formik.values.providerId}
                onBlur={formik.handleBlur}
                error={formik.touched.providerId && !!formik.errors.providerId}
                name="providerId"
                className="login_screen_form_input"
                label={t('common:provider_ID','Provider ID')}
                id='provider_ID_input_field'
            />
            {
                getErrorMessage() &&
                <Alert severity="error" className="login_screen_alert">{getErrorMessage()}</Alert>
            }
            <div className="filler" />
            {
                loading
                    ? <Spinner />
                    : <Button variant="opaque" type="submit" className="login_screen_form_submit_button">
                        {t('common:continue','Continue')}
                    </Button>
            }
        </form>
    )
}
import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ProfessionalDevelopment as IProfessionalDevelopment } from '../../../interfaces';
import { SectionContainer } from '../../Other/SectionContainer';
import { useMutation } from '@apollo/client';
import { UPDATE_PROFESSIONAL_DEVELOPMENT_MUTATION } from './queries';
import { ProfessionalDevelopmentForm } from './ProfessionalDevelopmentForm';
import { useFeatureManager } from 'hooks/useFeatureManager';

interface ProfessionalDevelopmentProps {
    providerId: number,
    professionalDevelopment?: IProfessionalDevelopment[]
    onProfessionalDevelopmentUpdated: (professionalDevelopment: IProfessionalDevelopment[]) => void
}

export const ProfessionalDevelopment: FC<ProfessionalDevelopmentProps> = (props) => {
    const { providerId, professionalDevelopment, onProfessionalDevelopmentUpdated } = props;
    const { t, ready: translationsReady } = useTranslation('providerprofile', { useSuspense: false });
    
    const { isProviderProfileSelfServeFeatureEnabled } = useFeatureManager();
    
    const [cachePendingUpdate, setCachePendingUpdate] = useState(false);

    const [update, updateMutation] = useMutation(UPDATE_PROFESSIONAL_DEVELOPMENT_MUTATION, {
        update: (_, result) => {
            if (result.data?.updateCredentials?.credential?.professionalDevelopment) {
                setCachePendingUpdate(true);
                onProfessionalDevelopmentUpdated(result.data.updateCredentials.credential.professionalDevelopment);
            }
        }
    });

    useEffect(() => {
        setCachePendingUpdate(false);
    }, [professionalDevelopment]);

    return !translationsReady
        ? <></>
        : <SectionContainer title={t('professional_development__section_title')}>
            {
                professionalDevelopment?.map((development, index) => (
                    <ProfessionalDevelopmentForm key={index}
                        providerId={providerId}
                        entry={development}
                        data={professionalDevelopment}
                        mutation={[update, updateMutation]}
                        cachePendingUpdate={cachePendingUpdate}
                    />
                ))
            }
            {
                isProviderProfileSelfServeFeatureEnabled() &&
                <ProfessionalDevelopmentForm
                    providerId={providerId}
                    entry={undefined}
                    data={professionalDevelopment}
                    mutation={[update, updateMutation]}
                    cachePendingUpdate={cachePendingUpdate}
                />
            }
        </SectionContainer>
}
import { useQuery } from "@apollo/client";
import { IS_ACTIVITY_ACCEPTED_QUERY } from "./queries";

export const useIsActivityAccepted = (activityId: number) => {
    return useQuery(IS_ACTIVITY_ACCEPTED_QUERY, {
        variables: { activityId },
        errorPolicy: 'all',
        fetchPolicy: 'no-cache'
    });
}

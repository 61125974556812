import React, { FC, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import { Spinner } from 'components/Shared';
import { COMPLETE_ASSESSMENT_MUTATION } from '../../queries';
import { Button } from 'components/Shared/Inputs';

interface AfterAssessmentCompletedPromptProps {
    isFirst: boolean,
    activityId: number,
    clientName?: string
}

export const AfterAssessmentCompletedPrompt: FC<AfterAssessmentCompletedPromptProps> = (props) => {
    const { isFirst, activityId, clientName } = props;
    const { t } = useTranslation('assessments', { useSuspense: false });
    const [isConfirmed, setConfirmed] = useState<boolean>(false);
    const navigate = useNavigate();

    const [completeAssessment, completeAssessmentMutation] = useMutation(COMPLETE_ASSESSMENT_MUTATION, {
        onCompleted() {
            setConfirmed(true);
        }
    });

    const renderSuccessView = () => {
        const body = props.isFirst
            ? t('PostFirstAppointmentAssessment-body')
            : t('PostLastAppointmentAssessment-body');

        const onClick = () => {
            navigate('/');
        };

        return (
            <div className="complete_assessment_page_wrapper">
                <section className="content_wrapper">
                    <h2 className="global__header1 color--brand1">{t('great_work')}!</h2>
                    <p className="global__paragraph">{body}</p>
                    <div className="buttons_wrapper">
                        {!isFirst && (
                            <Link
                                className="global__button"
                                to={{
                                    pathname: `/close_case/${activityId}`,
                                }}
                                state={{ client: clientName }}
                            >
                                {t('close_case_button_label')}
                            </Link>
                        )}
                        <button className="global__button" onClick={onClick}>
                            {t('back_to_dashboard')}
                        </button>
                    </div>
                </section>
            </div>
        );
    };

    const renderConfirmationView = () => {
        const confirm = () => {
            const stage = isFirst ? 'FirstAssessment' : 'LastAssessment';
            completeAssessment({ variables: { activityId: activityId, stage: stage } });
        };

        return (
            <div className="complete_assessment_page_wrapper">
                <section className="content_wrapper content_wrapper__center">
                    <h2 className="global__header2 color--brand1">{t('warning')}!</h2>
                    <p className="global__paragraph">
                        <Trans t={t} i18nKey="once_submit_not_able_to_modify" components={{ 1: <u /> }} />
                    </p>
                    <p className="global__paragraph">{t('attest_information_on_form')}</p>
                    <p className="global__paragraph">{t('submit_or_cancel')}</p>
                    <div className="buttons_wrapper_row">
                        <Button variant="opaque" onClick={confirm}>
                            {t('submit')}
                        </Button>
                        <Button variant="opaque" component={Link} to={{pathname: `/clients/${activityId}`}}>
                            {t('cancel')}
                        </Button>
                    </div>
                </section>
            </div>
        );
    };

    return completeAssessmentMutation.loading
        ? <Spinner fillParent />
        : isConfirmed
            ? renderSuccessView()
            : renderConfirmationView();
};

import { FormikErrors, useFormik } from 'formik';
import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { SectionContainer } from '../../Other/SectionContainer';
import { confirm } from 'components/Shared/ConfirmDialog';
import { Spinner } from 'components/Shared/Spinner';
import { Grid } from '@mui/material';
import { TextBox, Button } from 'components/Shared/Inputs';

interface IdentificationProps {
    identifier?: string,
    updateFn: (identifier: string) => void,
    mutation: any,
    validator: RegExp,
    cachePendingUpdate: boolean,
    labels: {
        sectionHeader: string,
        sectionDescription: string,
        inputLabel: string,
        noData: string
    }
}

interface IdentificationFormValues {
    identifier: string
}

export const Identification: FC<IdentificationProps> = (props) => {
    const { identifier, updateFn, mutation, validator, labels, cachePendingUpdate } = props;

    const { t, ready: translationsReady } = useTranslation('providerprofile', { useSuspense: false });
    const { t: t_common, ready: commonTranslationsReady } = useTranslation('common', { useSuspense: false });

    useEffect(() => {
        setInEdit(false);
        formik.resetForm({values: { identifier: identifier ? identifier.toString() : '' }})
    }, [identifier])

    const [inEdit, setInEdit] = useState(false);

    const formik = useFormik<IdentificationFormValues>({
        initialValues: {
            identifier: identifier ? identifier.toString() : ''
        },
        validate: (values) => {
            const errors: FormikErrors<IdentificationFormValues> = {};

            if (!validator.test(values.identifier)) {
                errors.identifier = 'Cannot be empty';
            }
            return errors;
        },
        validateOnBlur: true,
        onSubmit: async (values) => {
            const errors = await formik.validateForm();

            if (!errors.identifier && values.identifier) {
                const dialogProps = {
                    message: <>
                        <p>{t('common__confirm_changes_dialog_text')}</p>
                        <ul>
                            {
                                hasChanges &&
                                <li>{labels.inputLabel}: <b>{values.identifier}</b></li>
                            }
                        </ul>
                    </>
                }
                if (await confirm(dialogProps)) {
                    updateFn(values.identifier);
                }
            }
        }
    });

    const handleCancelChanges = async () => {
        if (hasChanges) {
            if (await confirm({title: t('common__discard_changes_dialog_title'), message: t('common__discard_changes_dialog_text')})) {
                formik.resetForm();
                setInEdit(false);
            }
        } else {
            formik.resetForm();
            setInEdit(false);
        }
    }

    const hasChanges = formik.values.identifier !== (identifier ? identifier.toString() : '');

    const editorLoading = !commonTranslationsReady;
    const submitDisabled = mutation.loading || !hasChanges || !formik.isValid || cachePendingUpdate;

    const handleInputChange = (event) => {
        const value = (event.target.value ?? '').replace(/\D/g,'');
        formik.setFieldValue('identifier', value);
    }

    return !translationsReady
        ? <></>
        : <SectionContainer title={labels.sectionHeader} inEdit={inEdit} onEditClick={() => setInEdit(true)}>
            {
                inEdit
                ? <>
                    {
                        editorLoading &&
                        <Spinner fillParent/>
                    }
                    {
                        (mutation.loading || cachePendingUpdate) &&
                        <Spinner fillParent backdrop/>
                    }
                    <Grid container component='form' onSubmit={formik.handleSubmit} spacing={3} style={{visibility: editorLoading ? 'hidden' : 'visible'}}>
                        <Grid item xs={12}>
                            {labels.sectionDescription}
                        </Grid>
                        <Grid item xs={12}>
                            <TextBox value={formik.values.identifier} label={labels.inputLabel}
                                name="identifier" onChange={handleInputChange} error={Boolean(formik.errors.identifier)}
                                className="provider_details_input"/>
                        </Grid>
                        <Grid item xs={12} container spacing={2}>
                            <Grid item>
                                <Button type="submit" variant="opaque" disabled={submitDisabled}>{t_common('save')}</Button>
                            </Grid>
                            <Grid item>
                                <Button type="button" variant="text" onClick={handleCancelChanges}>{t_common('cancel')}</Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </>
                : identifier
                    ? <>
                        <p>{labels.sectionDescription}</p>
                        <p className='global__paragraph'>{identifier}</p>
                        <p className='global__paragraph global__paragraph--small'>{labels.inputLabel}</p>
                    </>
                    : <p>{labels.noData}</p>
            }
        </SectionContainer>
}
import React, { FC } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { ExpandableNote } from 'components/Shared';
import { Button } from 'components/Shared/Inputs';

interface ConnectWithClientPromptProps {
    onDidContactClick: () => void,
    onNoContactClick: () => void,
    activityId: number
}

export const ConnectWithClientPrompt: FC<ConnectWithClientPromptProps> = (props) => {
    const { activityId, onNoContactClick, onDidContactClick } = props;

    const { t } = useTranslation('caseManagement', { useSuspense: false });

    return <div className="update_appt_status_step">
        <p>
            {t('connect_with_client')}
        </p>
        <p>
            <b>{t('if_not_connected_with_client')}</b>, {t('call_client_within_business_day')}
        </p>
        <div className="appt_status_dropdown">
            <ExpandableNote severity="info" title={t('enter_date_first_appt_note_title')}
                id="sessions_number_note">
                <Trans t={t} i18nKey="enter_date_first_appt_note">
                    To review session number expectations see ‘Model Number’ and average session expectation under 
                    <Link to={{pathname: `/clients/${activityId}/details`, hash: 'organization_details'}}>Client Details</Link>
                </Trans>
            </ExpandableNote>
        </div>
        <Button variant="opaque" onClick={onDidContactClick} className="appt_status_dropdown">
            {t('yes_i_have')}
        </Button>
        <Button variant="text" onClick={onNoContactClick} className="appt_status_dropdown">
            {t('not_yet_log_attempts')}
        </Button>
    </div>
}
import { gql, TypedDocumentNode } from '@apollo/client';
import { ProviderData } from 'components/Auth';

export const PROVIDER_SUMMARY_QUERY: TypedDocumentNode<ProviderSummaryQueryData, ProviderSummaryQueryVars> = gql`
    query PROVIDER_DETAILS_QUERY($providerId: Long!) {
        provider(id: $providerId) {
            firstName
            lastName
            providerId
            isManaged
            employmentTypeId
            preferredLanguageCode
            vendor {
                vendorId
                name
            }
        }
    }
`

export interface ProviderSummaryQueryData {
    provider: ProviderData
}

export interface ProviderSummaryQueryVars {
    providerId: number
}
export function formatPhoneNumber(phoneNumber: string, region: string = 'ca', includeCountryPrefix: boolean = true) {
    let formattedNumber: string = phoneNumber
                                    // remove all non-numeric characters
                                    .replace(/\D+/g, '')
                                    // get last 10 digits
                                    .slice(-10);
    let countryCode: string = '';
    switch (region) {
        case 'ca':
        case 'us': {
            countryCode = '+1-';
            formattedNumber = formattedNumber
                // change to north American phone number format;
                .replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3');
            break;
        }
        case 'uk': {
            countryCode = '+44 ';
            formattedNumber = formattedNumber
                // change to north American phone number format;
                .replace(/(\d{4})(\d{6})/, '(0)$1 $2');
            break;
        }
        case 'au': {
            countryCode = '+61 '
            formattedNumber = formattedNumber
                // change to north American phone number format;
                .replace(/(\d{2})(\d{4})(\d{4})/, '($1) $2 $3');
            break;
        }
    }
    const noop = (arg: string) => arg;
    formattedNumber = includeCountryPrefix ? `${countryCode}${formattedNumber}` : formattedNumber;
    noop(formattedNumber); // use this to suppress eslint warning
    // TODO: research formatting rules for different regions and uncomment the line below
    // return formattedNumber;
    // for now, just return original number
    return phoneNumber;
}

import React, { useState } from 'react';
import { Box, Paper, Container, Divider, IconButton } from '@mui/material';
import { IActivityDetails, IReferral } from '../../../interfaces';
import { ActivityStatus } from 'constants/activityStatus';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import { DeclineMutationVars, DECLINE_REFERRAL_MUTATION, } from '../../../ConfirmCaseAcceptance/queries';
import { ClientDetailsPromptCard } from '../../../components/ClientDetailsPromptCard';
import { formatPhoneNumber } from 'utils/formatter';
import { useAuth } from 'components/Auth';
import { PROVIDER_HUB } from 'constants/targetedSystem'
import { useTranslation } from 'react-i18next';
import { StepDefinition } from 'components/ToDos';
import { useScreenSize } from 'components/Layout';
import { Button } from 'components/Shared/Inputs';
import { ReactComponent as IconClose } from 'assets/images/navbar/IconClose.svg'; 
import InfoIcon from '@mui/icons-material/InfoOutlined';
import { DeclineCaseDialog } from '../../../components/DeclineCaseDialog';
import { checkIfStaffProvider, isValueDefined, scrollToTop } from 'utils';
import { MODEL_22, determineRemainingBasedOnModelId } from 'utils/hourlyWorkflow';
import { useFeatureManager } from 'hooks/useFeatureManager';
import { confirm } from 'components/Shared/ConfirmDialog';
import { DEFAULT_APPOINTMENT_DURATION } from 'constants/hourlyWorkflow';
import { IContractDetails } from 'hooks/useContractDetails/interfaces';
import { Card } from 'components/Shared/Card';
import { getPreferredPhoneNumber } from 'components/CaseManagement/utils/activityDetailsUtils';
import { COUNTRY_CODE, getAppRegionCode, regionsToShowPreferredNumber } from 'utils/regionUtils';

export interface ActivityDetailsHeaderProps {
    activity?: IActivityDetails,
    service?: string,
    presentingIssue?: string,
    step: StepDefinition | null,
    referral?: IReferral,
    onClose?: () => void,
    contractDetails: IContractDetails | undefined
}

export const ActivityDetailsHeader = (props: ActivityDetailsHeaderProps) => {
    const { activity, service, presentingIssue, step, referral, onClose } = props;
    const { isMobile } = useScreenSize();
    const [declineDialogOpen, setDeclineDialogOpen] = useState(false);

    const { isHourlyWorkflowFeatureEnabled } = useFeatureManager()

    const { t } = useTranslation(['activityDetails', 'caseManagement', 'common'], { useSuspense: false });

    const { effectiveProviderData } = useAuth();
    const providerId = effectiveProviderData?.providerId ?? -1;
    const providerRegion = getAppRegionCode();

    const activityId = activity?.activityId || 0;
    const navigate = useNavigate();
    const location = useLocation();

    const [declineCase] = useMutation<any, DeclineMutationVars>(
        DECLINE_REFERRAL_MUTATION,
        {
            onCompleted() {
                navigate('/', { replace: true });
            }
        }
    );

    const handleDeclineCase = (reasonCode) => {
        return declineCase({variables: {
            providerId,
            activityId,
            reasonCode
        }})
    }

    const openDeclineModal = () => {
        setDeclineDialogOpen(true);
    }

    const hideDeclineOption = (activity?.sessions && (activity?.sessions?.length > 0));

    const isActiveActivity = activity?.activityStatusCode === ActivityStatus.Active;
    const isProviderHubActivity = activity?.targetedSystem?.systemCode === PROVIDER_HUB;

    const canRenderCaseActionButtons = isActiveActivity && isProviderHubActivity;

    const handleFullDetails = () => {
        props.onClose && props.onClose();
        scrollToTop();
    }   

    const remainingHours = determineRemainingBasedOnModelId(
        activity?.clientAvailableHours, 
        activity?.sessionInfo?.totalHoursRemaining,
        activity?.sessionInfo?.modelId
    )

    const onClickCloseCase = async () => {
        if (isHourlyWorkflowFeatureEnabled() && activity?.isHourlyContract) {
            if (isValueDefined(remainingHours) && remainingHours >= DEFAULT_APPOINTMENT_DURATION) {
                const answer = await confirm({
                    message: t('outstanding_hours_close_case_body', { 
                        remainingHours,
                        hoursString: remainingHours === 1 ? t('common:hour_singular') : t('common:hour_plural')
                    }),
                    title: t('outstanding_hours_close_case_title'),
                    cancelLabel: t('caseManagement:no'),
                    confirmLabel: t('caseManagement:yes')
                });

                if (answer) {
                    return navigate(`/close_case/${activityId}`, { state: { client: activity?.client }})
                }
                return;
            }
        }
        return navigate(`/close_case/${activityId}`, { state: { client: activity?.client }})
    }


    const renderCaseActionButtons = () => (
        hideDeclineOption
            ? <Button variant="outline" onClick={onClickCloseCase}>
                {t('close_case')}
            </Button>
            : <Button variant="outline" onClick={openDeclineModal}>
                {t('decline_case')}
            </Button>
    )

    const renderActivityPrompCard = () => {
        return isProviderHubActivity && !isMobile && (
            <Card elevation={0} className='case_details_prompt_card_wrapper'>
                <ClientDetailsPromptCard activity={activity} step={step}/>
            </Card>
        )
    }

    const handleAuthorizedSessionsInfoClick = (event) => {
        if (location.pathname === `/clients/${activityId}/details`) {
            event.preventDefault();
            setTimeout(() => document.getElementById('organization_details')?.scrollIntoView(), 0);
        }
        onClose?.();
    }

    const renderAuthorizedSessionsField = () => {
        const { maxCapHours, modelId } = activity?.sessionInfo ?? {};
        return (
            <div className="case_detail_item_div" key='authorized_sessions'>
                <p className="case_detail_item_title">
                    {t('authorized_sessions')}
                </p>
                <p className="case_detail_item_value">
                    {maxCapHours || (modelId === MODEL_22 ? (maxCapHours ??  t('not_applicable')) : t('not_applicable'))}
                    <Button variant="text" component={Link} to={{ pathname: `/clients/${activityId}/details`, hash: 'organization_details' }}
                        onClick={handleAuthorizedSessionsInfoClick}
                        size="small" round
                        aria-label={t('link_to_service_model_info_label')}
                    >
                        <InfoIcon />
                    </Button>
                </p>
            </div>
        )
    }

    const renderValue = (label, value, key?) => (
        <div className="contact_info_div" key={key}>
            <p className="contact_info_value">
                {value || '---'}
            </p>
            <span className="contact_info_title">
                {label}
            </span>
        </div>
    )

    const renderInlineValue = (label, value, key?) => (
        <div className="case_detail_item_div" key={key}>
            <p className="case_detail_item_title">
                {label}
            </p>
            <p className="case_detail_item_value">
                {value}
            </p>
        </div>
    )

    const contractWithSharedFamilyHours = () => {
        const familyHours = props?.contractDetails?.activeContractPeriod?.familyHourAllocation;

        if(familyHours)
        {
            return !familyHours.employeeHoursLocked && !familyHours.familyHoursLocked;                        
        }

        return false;        
    }

    const checkIfDisplayPreferredPhoneNumber = (region?: COUNTRY_CODE) => {
        const phones = activity?.client?.phones?.map(({ number }) => number);

        const showSessionPreferredNumber = regionsToShowPreferredNumber.includes(region as COUNTRY_CODE)
                                           && activity?.modality === 'Tele-Counselling'
                                           && activity?.service === 'Counselling'
                                           && checkIfStaffProvider(effectiveProviderData);
        
        if (showSessionPreferredNumber && (activity?.sessions?.length || 0) > 0) {
            return activity?.sessions?.some(session => !!session.clientReservationPhoneNumber);
        }

        if (activity?.serviceForms?.clientPhoneNumber) {
            const preferredInDefault = phones?.includes(activity?.serviceForms?.clientPhoneNumber);
            return !preferredInDefault;
        }

        return false;
    }

    return (
        <>
        <Container className='activity_details_header_container' disableGutters={true}>
            <Paper className='activity_details_header_div' elevation={0}>
                <h1>
                    {`${activity?.client?.firstName} ${activity?.client?.lastName}`}
                </h1>
                {
                    isMobile && onClose &&
                    <IconButton arial-label="close" onClick={onClose} className="drawer_close_button">
                        <IconClose/>
                    </IconButton>
                }
                {
                    canRenderCaseActionButtons && renderCaseActionButtons()
                }
                <Card elevation={0} className="case_details_wrapper">
                    <h2>{t('case_details')}</h2>
                    <section className="case_details_content_section">
                        {renderValue(t('client_name'), `${activity?.client?.firstName} ${activity?.client?.lastName}`)}
                        {renderValue(t('client_type'), activity?.eligibility ?? t('not_applicable'))}
                        {renderValue(t('service_of_interest'), service)}
                        {renderValue(t('service_for'), activity?.serviceFor ?? t('not_applicable'))}
                        {renderValue(t('presenting_issue'), presentingIssue)}
                        {
                            activity?.indigenousLabel &&
                            renderValue(t('indigenous_label'), activity?.indigenousLabel)
                        }
                        {renderValue(t('risk_level'), activity?.urgencyLevelString ?? t('not_applicable'))}
                        {renderValue(t('organization_name'), activity?.organization?.name)}

                        <Box my={2}><Divider/></Box>

                        {renderValue(t('phones'), activity?.client?.phones?.map((phone) => formatPhoneNumber(phone.number)).join(', ') || t('no_phone_number'))}
                        {
                            checkIfDisplayPreferredPhoneNumber(providerRegion) &&
                            renderValue(t('preferred_phone_number'), getPreferredPhoneNumber(activity, providerRegion, checkIfStaffProvider(effectiveProviderData)) || t('no_phone_number'))
                        }
                        {renderValue(t('email'), activity?.client?.email || t('no_email'))}
                        {renderValue(t('caseManagement:calling_preference'), referral?.clientCallingPreference || '---')}
                        {renderValue(t('caseManagement:notes'), referral?.serviceFormNotes || '---')}

                        <Box my={2}><Divider/></Box>

                        {renderAuthorizedSessionsField()}
                        {
                            isHourlyWorkflowFeatureEnabled()
                            && activity?.isHourlyContract
                            && activity?.pcShowFamilyHours
                            && contractWithSharedFamilyHours()
                                ? <>
                                    {renderInlineValue(t('total_hours_available'), activity?.caseDetailHours.totalHoursAvailable ?? t('not_applicable'))}
                                </>
                                : <>
                                    {renderInlineValue(t('max_number_of_hours'), activity?.caseDetailHours.numberOfMaxHours ?? t('not_applicable'))}
                                    {renderInlineValue(t('approved_hours'), activity?.caseDetailHours.approvedHours ?? t('not_applicable'))}
                                    {renderInlineValue(t('number_of_hours_used'), activity?.caseDetailHours.numberOfHoursUsed ?? t('not_applicable'))}
                                    {renderInlineValue(t('client_remaining_hours'), activity?.caseDetailHours.clientRemainingHours ?? t('not_applicable'))}
                                </>
                        }
                    </section>
                    {
                        activity?.isAvailableToProvider &&
                        <Button variant="outline" onClick={handleFullDetails} fullWidth
                            component={Link} to={{ pathname: `/clients/${activityId}/details` }}
                        >
                            {t('see_full_details')}
                        </Button>
                    }
                </Card>
                {renderActivityPrompCard()}
            </Paper>
        </Container>
        {
            !hideDeclineOption &&
            <DeclineCaseDialog onCancel={() => setDeclineDialogOpen(false)}
                onDeclineCase={handleDeclineCase}
                open={declineDialogOpen} selectReason
                firstName={activity?.client.firstName ?? ''}
                lastName={activity?.client.lastName ?? ''}
            />
        }
        </>
    );
}

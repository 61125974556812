import { gql, TypedDocumentNode } from '@apollo/client';

export const COMMUNICATION_PREFERENCES_REF_DATA
    : TypedDocumentNode<CommunicationPreferencesRefDataData, CommunicationPreferencesRefDataVars> = gql`
    query PROVIDER_INFO_REF_DATA {
        refValues {
            nonConnectedCommunicationMedia {
                title
                value
            }
        }
    }
`
interface CommunicationPreferencesRefDataData {
    refValues: {
        nonConnectedCommunicationMedia: {
            title: string,
            value: string
        }[]
    }
}

interface CommunicationPreferencesRefDataVars { }

export const UPDATE_PROVIDER_COMMUNICATION_PREFERENCES
    : TypedDocumentNode<UpdateProviderCommunicationPreferencesData, UpdateProviderCommunicationPreferencesVars> = gql`
    mutation UPDATE_PROVIDER_COMMUNICATION_PREFERENCES($providerId: Long!, $preferredCommunicationForNonConnected: Int) {
        updateProviderDetails(providerId: $providerId, preferredCommunicationForNonConnected: $preferredCommunicationForNonConnected) {
            providerId
            details {
                preferredCommunicationForNonConnected
                preferredCommunicationForNonConnectedString
            }
        }
    }
    
`

interface UpdateProviderCommunicationPreferencesData {
    updateProviderDetails: {
        details: {
            preferredCommunicationForNonConnected: number,
            preferredCommunicationForNonConnectedString: string
        }
    }
}

interface UpdateProviderCommunicationPreferencesVars {
    providerId: number,
    preferredCommunicationForNonConnected: number
}
import React, { FC, useEffect, useState } from 'react';
import { Modal, Grow, Paper } from '@mui/material';
import { TechSupport } from 'components/Shared/TechSupport/TechSupport';
import './HelpScreen.scss';
import { ReactComponent as CopyClipboardImage } from 'assets/images/helpScreen/copy_clipboard.svg';
import { useTranslation } from 'react-i18next';
import { PageMetadata } from 'components/Shared/PageMetadata';
import { useRegionConfig } from 'providers/RegionConfig';
import { useNavigate } from "react-router-dom";

interface HelpScreenProps { }

export const HelpScreen: FC<HelpScreenProps> = () => {
    const { t, ready } = useTranslation('helpCenter', { useSuspense: false });
    const [ showTechSupport, setShowTechSupport ] = useState<boolean>(false);
    const { helpUrls } = useRegionConfig();
    const currentLanguage = localStorage.getItem('i18nextLng');
    const navigate = useNavigate();

    useEffect(() => {
        if(helpUrls && helpUrls )
        {
            const redirectUrl = currentLanguage === "fr-CA" ? helpUrls.fr : helpUrls.en;
            if (redirectUrl && redirectUrl.url) {
                window.open(redirectUrl.url);               
                navigate(-1);
            }
        }
    });

    return (
        <React.Fragment>
            { ready && 
                <PageMetadata 
                    pageDescription={t('help_center_page_description')} 
                    pageTitle={t('help_center_page_title')}
                /> 
            }
            <div className="global_help_screen_wrapper">
                <h1 className="global__header1 page_title">{t("help_Center_title", "Help Center")}</h1>
                <section className="help_container_wrapper_section">
                    <div className="info_container_wrapper">
                        <div className="text_content_div">
                            <h2 className="question_text global__header2">{t("how_can_we_help_you", "How can we help you?")}</h2>
                            <section className="answer_text_div">
                                <p className="global__paragraph global__paragraph--light">{t("how_can_we_help_you_answer", "Morneau Shepell staff will be able to answer your question and assist you with any issues. If you have a question, we suggest you visit our Frequently Asked Questions page first as we may already have an answer for you. Otherwise, feel free to email us your questions at the email below.")}</p>
                            </section>
                            <div className="action_div">
                                <a id="helpdesk_email_id" target="_blank" rel="noopener noreferrer" className="helpdesk_email global__link" href={`mailto: ${t('help_email','CRHelpdesk@telushealth.com')}`}>{t('help_email', 'CRHelpdesk@telushealth.com')}</a>
                                <button aria-label={t('copy_to_clipboard')} className="icon_button" 
                                    onClick={() => navigator.clipboard.writeText(t('help_email', 'CRHelpdesk@telushealth.com'))}
                                    >
                                    <CopyClipboardImage/>
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className="support_container_wrapper">
                        <div className="contact_tech_support_content_wrapper">
                            <h3 className="title">{t('tech_support', 'Tech Support')}</h3>
                            <p className="description global__paragraph global__paragraph--light">{t('contact_tech_support_description', 'Our tech support team is ready to help you tackle any issues that may come up while you’re using The Provider Hub in a timely fasion.')}</p>
                            <button className="contact_tech_support_button global__button" onClick={() => setShowTechSupport(true)}>{t('contact_tech_support_button', 'Ask Question')}</button>
                        </div>
                    </div>
                </section>
                <Modal open={showTechSupport} onClose={() => setShowTechSupport(false)} className="tech_support_modal_wrapper" disablePortal disableScrollLock keepMounted>
                    <Grow in={showTechSupport} style={{ transformOrigin: '100% 0 0' }}>
                        <Paper elevation={3} className="tech_support_modal_content">
                            <TechSupport onClose={() => setShowTechSupport(false)}/>
                        </Paper>
                    </Grow>
                </Modal>
            </div>
        </React.Fragment>
    )
}


import { gql, TypedDocumentNode } from '@apollo/client';
import { IContractDetails } from './interfaces';

export const CONTRACT_DETAILS_QUERY: TypedDocumentNode<ContractDetailsQueryData, ContractDetailsQueryVars> = gql`
    query CONTRACT_DETAILS_QUERY($activityId: Long!) {
        contractDetails(activityId: $activityId) {
            contractId,
            modeId,
            averageHours,
            maxCapHours,
            isFamilyHoursAvailable,
            additionalHoursApproval {
                goOverHoursCodeId,
                goOverHoursTypeId
            },
            activeContractPeriod {
                startDate,
                endDate,
                feeTypeCode,
                fee,
                maxHourDetails {
                    maxHours,
                    periodType,
                    startDate,
                    endDate
                },
                familyHourAllocation {
                    employeeHoursLocked,
                    familyHoursLocked,
                    familyTotalAllocatedHours,
                    familyTotalRemainingHours,
                    familyHourEligibilities {
                        eligibilityCategoryId,
                        allocatedHours,
                        consumedHours,
                        additionalFamilyHours,
                        remainingHours
                    }
                }
            }
        }
    }
`

export interface ContractDetailsQueryData {
    contractDetails: IContractDetails
}

export interface ContractDetailsQueryVars {
    activityId: number
}
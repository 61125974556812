import React from 'react';
import { IconButton } from '@mui/material';
import { Close as CloseIcon } from '@mui/icons-material';
import { SnackbarAction, SnackbarProvider as _SnackbarProvider } from 'notistack';
import { useRef } from 'react';

export const SnackbarProvider = ({ children }) => {
    const snackbarRef = useRef<_SnackbarProvider>(null);

    const snackbarAction: SnackbarAction = (key) => (
        <IconButton
            color="inherit"
            onClick={() => snackbarRef.current?.closeSnackbar(key)}
        >
            <CloseIcon fontSize="small" />
        </IconButton>
    )

    return <_SnackbarProvider
        className="snackbar_div"
        maxSnack={3}
        ref={snackbarRef}
        action={snackbarAction}
        children={children}
    />
}
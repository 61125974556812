import React, { FC } from 'react';
import { Typography } from '@mui/material';
import { InvoiceDetails, InvoiceStatus } from '../../interfaces';
import InvoiceIcon from '@mui/icons-material/AssignmentOutlined';
import ErrorIcon from '@mui/icons-material/ErrorOutlineRounded';
import ApprovedIcon from '@mui/icons-material/DoneRounded';
import { useTranslation } from 'react-i18next';
import { toCurrencyString } from 'utils';
import useTimezoneUtils from 'hooks/useTimezoneUtils';
import { Button } from 'components/Shared/Inputs';

interface InvoiceDetailsSummaryProps {
    invoice?: InvoiceDetails,
    uninvoicedAppointmentsCount: number,
    disableSubmitButton?: boolean,
    onSubmitInvoiceClick?: () => void
}

export const InvoiceDetailsSummary: FC<InvoiceDetailsSummaryProps> = (props) => {
    const { invoice, uninvoicedAppointmentsCount, disableSubmitButton, onSubmitInvoiceClick } = props;

    const { i18n, t } = useTranslation('invoicing', { useSuspense: false });

    const { formatDate } = useTimezoneUtils();

    const formatInvoiceSubmittedDate = (date: Date | string) => {
        return formatDate(date, 'LL')
    }

    const getDateTitle = () => {
        switch (invoice?.statusId) {
            default:
            case InvoiceStatus.Draft: return t('date_created');
            case InvoiceStatus.Declined:
            case InvoiceStatus.Submitted:
            case InvoiceStatus.Approved:
            case InvoiceStatus.Void:
            case InvoiceStatus.PendingApproval: return t('date_submitted');
        }
    }

    const inferAndFetchSubmittedDate = () => {
        const submittedOrCreatedDate = invoice?.submittedDate ? invoice?.submittedDate : invoice?.createdDate
        return submittedOrCreatedDate ? formatInvoiceSubmittedDate(new Date(submittedOrCreatedDate)) : '---'
    }

    const getDateBasedOnStatus = () => {        
        switch (invoice?.statusId) {
            case InvoiceStatus.Draft: 
                return invoice?.createdDate ? formatInvoiceSubmittedDate(new Date(invoice.createdDate)) : '---';
            default:
                return inferAndFetchSubmittedDate()
        }
    }

    const getStatusClass = (): string => {
        switch (invoice?.statusId) {
            case InvoiceStatus.Approved: return 'approved';
            case InvoiceStatus.Draft: return 'draft';
            case InvoiceStatus.Declined: return 'declined';
            case InvoiceStatus.Submitted:
            case InvoiceStatus.PendingApproval: return 'pending_approval';
            case InvoiceStatus.Void:
            default: return '';
        }
    }

    const getStatusDescription = (): string => {
        switch (invoice?.statusId) {
            case InvoiceStatus.Approved: return t('approved_desc');
            default:
            case InvoiceStatus.Draft: return t('draft_desc');
            case InvoiceStatus.Declined: return t('declined_desc');
            case InvoiceStatus.Submitted:
            case InvoiceStatus.PendingApproval: return t('pending_desc');
            case InvoiceStatus.Void: return t('void_desc');
        }
    }

    const getStepName = (): string => {
        switch (invoice?.statusId) {
            case InvoiceStatus.Void:
            case InvoiceStatus.Declined:
            case InvoiceStatus.Approved: return t('invoice_approval');
            default:
            case InvoiceStatus.Draft: return t('create_new_invoice');
            case InvoiceStatus.Submitted:
            case InvoiceStatus.PendingApproval: return t('invoice_pending_approval');
        }
    }

    const getStepString = (): string => {
        // const options = { year: 'numeric', month: 'long', day: 'numeric' };

        switch (invoice?.statusId) {
            case InvoiceStatus.Approved: 
                return `${t('step')} 3/3`; // • Approved on ${selectedInvoice?.submittedDate ? new Date(selectedInvoice.submittedDate).toLocaleDateString('en-US', options) : '---'}`; //
            default:
            case InvoiceStatus.Draft: 
                return `${t('step')} 1/3`; // • Created on ${selectedInvoice?.createdDate ? new Date(selectedInvoice.createdDate).toLocaleDateString('en-US', options) : '---'}`;
            case InvoiceStatus.Declined:
                return `${t('step')} 3/3`; // • Declined on ${selectedInvoice?.submittedDate ? new Date(selectedInvoice.submittedDate).toLocaleDateString('en-US', options) : '---'}`;
            case InvoiceStatus.Submitted:
            case InvoiceStatus.PendingApproval:
                return `${t('step')} 2/3`; // • Submitted on ${selectedInvoice?.submittedDate ? new Date(selectedInvoice.submittedDate).toLocaleDateString('en-US', options) : '---'}`;
            case InvoiceStatus.Void:
                return `${t('step')} 3/3`; // • Voided on ${selectedInvoice?.submittedDate ? new Date(selectedInvoice.submittedDate).toLocaleDateString('en-US', options) : '---'}`;
        }
    }

    return (
        <div className="invoice_summary_container_wrapper">
        {
        invoice
            ? (
                <React.Fragment>
                    <div className="invoice_details_summary_wrapper">
                        <div className="invoice_details_summary_sections_wrapper">
                            <div style={{marginRight: 50}}>
                                <h3 className="invoice_details_summary_section_title">{t('status')}</h3>
                                <p className={`invoice_details_status_label ${getStatusClass()}`}>{invoice.statusString}</p>
                            </div>
                            <div>
                                <h3 className="invoice_details_summary_section_title">{t('uninvoiced_appointments')}</h3>
                                <p className="invoice_details_summary_section_value" style={{textAlign: 'center'}}>
                                    {uninvoicedAppointmentsCount}
                                </p>
                            </div>
                        </div>
                        <div className="invoice_details_summary_sections_wrapper">
                            <div style={{marginRight: 50}}>
                                <h3 className="invoice_details_summary_section_title">{t('amount_due')}</h3>
                                <p className="invoice_details_summary_section_value amount_value">{
                                    toCurrencyString(invoice.grandTotal, i18n.language, invoice.currencyCode) ?? '---'
                                }</p>
                            </div>
                            <div>
                                <h3 className="invoice_details_summary_section_title" style={{textAlign: 'right'}}>{getDateTitle()}</h3>
                                <p className="invoice_details_summary_section_value date_value" style={{fontWeight: 'normal'}}>{getDateBasedOnStatus()}</p>
                            </div>
                        </div>
                    </div>
                    <div className="status_description_container_wrapper">
                        <div className="status_description_header_wrapper">
                            <InvoiceIcon className="invoice_icon"/>
                            <div className="status_description_header_text_wrapper">
                                <h4 className="invoice_details_status_description_name global__header3">{getStepName()}</h4>
                                <p className="invoice_details_status_description_step global__paragraph global__paragraph--light">{getStepString()}</p>
                                <div className=  {`invoice_details_status_description_text_container ${getStatusClass()}`}>
                                    {
                                        invoice?.statusId === InvoiceStatus.Approved
                                            ? <ApprovedIcon className="status_icon"/>
                                            : <ErrorIcon className="status_icon"/>
                                    }
                                    <p className="global__paragraph">{getStatusDescription()}</p>
                                </div>
                                {
                                    invoice?.statusId === InvoiceStatus.Draft &&
                                    <div className={`invoice_details_status_description_text_container warning`}>
                                        <ErrorIcon className="status_icon color--warning"/>
                                        <p className="global__paragraph color--warning">{t('you_must_submit_the_invoice_within_30_days_from_case_closure_to_ensure_the_case_does_not_become_inactive')}.</p>
                                    </div>
                                }
                                {
                                    invoice?.approverComment &&
                                    <div className="invoice_details_approver_comments_wrapper">
                                        <h4 className="invoice_details_approver_comments_label global__header3">{t('what_are_the_issues_with_your_invoice')}?</h4>
                                        <p className="invoice_details_approver_comments_text global__paragraph global__paragraph--light">{invoice.approverComment}</p>
                                    </div>
                                }                                   
                            </div>
                            {
                                invoice?.statusId === InvoiceStatus.Draft &&
                                <Button disabled={disableSubmitButton} onClick={onSubmitInvoiceClick}>{t('submit_invoice')}</Button>
                            }
                        </div>
                    </div>
                </React.Fragment>
            )
        : <Typography align="center">{t('you_have')} <b>{uninvoicedAppointmentsCount}</b> {t('uninvoiced_appointments_lw')}.</Typography>
        }
        </div>
    )
}
import React, { FC } from 'react';
import { LoginScreenLayout } from './LoginScreenLayout';
import { ConfirmRegisterationForm } from '../Forms/ConfirmRegisterationForm';
import { useParams } from 'react-router-dom';
import { PageMetadata } from 'components/Shared/PageMetadata';
import { useTranslation } from 'react-i18next';

interface ConfirmRegisterationScreenProps { }

export const ConfirmRegisterationScreen: FC<ConfirmRegisterationScreenProps> = (_) => {
    const { token } = useParams<{token: string}>();
    const { t, ready } = useTranslation(['login'], { useSuspense: false });

    return (
        <React.Fragment>
            { ready && 
                <PageMetadata 
                    pageDescription={t('confirm_registration_page_description')} 
                    pageTitle={t('confirm_registration_page_title')}
                /> 
            }
            <LoginScreenLayout>
                <ConfirmRegisterationForm token={token}/>
            </LoginScreenLayout>
        </React.Fragment>
    )
}
import React, { FC, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Tabs, Tab, Button } from '@mui/material';
import { useNavigationUtils } from '../../hooks';
import { LanguageSwitcher } from 'components/Shared/LanguageSwitcher';
import { useAuth } from 'components/Auth';
import { ProfileMenu } from '../ProfileMenu';

import './DesktopSecondaryNavbar.scss';

interface DesktopSecondaryNavbarProps { }

const KnownPaths = ['/profile', '/learn', '/help', '/faq'];

export const DesktopSecondaryNavbar: FC<DesktopSecondaryNavbarProps> = (_) => {
    const { t } = useTranslation('navigation', { useSuspense: false });
    const { authenticated, logout } = useAuth();
    const [profileMenuAnchorEl, setProfileMenuAnchorEl] = useState<HTMLElement | null>(null);

    const { pathRoot } = useNavigationUtils();

    const path = KnownPaths.indexOf(pathRoot) !== -1 ? pathRoot : false;

    const handleProfileClick = (event) => {
        event.preventDefault();
        setProfileMenuAnchorEl(event.currentTarget);
    }

    const handleProfileMenuClose = () => {
        setProfileMenuAnchorEl(null);
    };

    const renderNavbarTab = (id: string, path: string) => (
        <Tab className="navbar_tab" id={id} label={t(id)} value={path} component={Link} to={{pathname: path}}/>
    );

    const handleSkipNavigationClick = () => {
        const main = document.getElementById("main");
        if (main) {
            const allFocusable = Array.from(main.querySelectorAll('a, button, input, textarea, select, details, [tabindex]:not([tabindex="-1"])'));
            for (const el of allFocusable) {
                if (el instanceof HTMLElement && !el.hasAttribute('disabled')) {
                    el.focus();
                    return;
                }
            }
        }
    }

    return (
        <div className='desktop_secondary_navbar'>
            <Button className="skip_navigation_button" onClick={handleSkipNavigationClick}>{t('skip_navigation')}</Button>
            <LanguageSwitcher component={Button} className="language_toggle_button"/>
            <Tabs className="navbar_tabs" value={path} component="nav">
                {
                    authenticated &&
                    <Tab className={profileMenuAnchorEl ? "navbar_tab profile-header" : "navbar_tab "} label={t("profile", "Profile")} value="/profile" onClick={handleProfileClick}
                        tabIndex={path === '/profile' || path === false ? 0 : -1}
                    />
                }
                {renderNavbarTab('learn', '/learn')}
                {renderNavbarTab('faq', '/faq')}
                {renderNavbarTab('help', '/help')}
                {
                    authenticated &&
                    <Tab className="navbar_tab" label={t("logout", "Log Out")} value="LOGOUT"
                        onClick={logout} style={{display: authenticated ? 'flex' : 'none'}} />
                }
            </Tabs>
            {
                authenticated &&
                <ProfileMenu anchorEl={profileMenuAnchorEl} onClose={handleProfileMenuClose}/>
            }
        </div>
    )
};

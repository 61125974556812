import React, { FC } from 'react';
import { IconButton, Modal } from '@mui/material';
import { Button } from 'components/Shared/Inputs';
import CloseIcon from '@mui/icons-material/Close';
import { useTranslation } from 'react-i18next';

interface FormDescriptionDialogProps {
    open: boolean,
    title: string | JSX.Element,
    content: string | JSX.Element,
    onClose: () => void
}

export const FormDescriptionDialog: FC<FormDescriptionDialogProps> = (props) => {
    const { open, content, title, onClose } = props;
    const { t } = useTranslation('common', { useSuspense: false });

    return (
        <Modal open={open} onClose={onClose} className='dialog_wrapper' role="dialog"
            aria-modal aria-labelledby="form_description_dialog_title" aria-describedby="form_description_dialog_content">
            <div className='dialog_surface'>
                <div className="dialog_title_wrapper">
                    <h2 id="form_description_dialog_title">
                        {title}
                    </h2>
                    <IconButton aria-label={t('common:close')} onClick={onClose}>
                        <CloseIcon />
                    </IconButton>
                </div>
                <div className="dialog_content_wrapper" style={{fontSize: '16px'}} id="form_description_dialog_content" aria-live="polite">
                    {content}
                </div>
                <div className="dialog_actions_wrapper">
                    <Button variant="opaque" onClick={onClose}>
                        {t('common:close')}
                    </Button>
                </div>
            </div>
        </Modal>
    )
}
import React, { FC } from 'react';
import { LoginScreenLayout } from './LoginScreenLayout';
import { SelectEffectiveProviderForm } from '../Forms/SelectEffectiveProviderForm';
import { useNavigate } from 'react-router-dom';
import { PageMetadata } from 'components/Shared/PageMetadata';
import { useTranslation } from 'react-i18next';

interface SelectEffectiveProviderScreenProps { }

export const SelectEffectiveProviderScreen: FC<SelectEffectiveProviderScreenProps> = (_) => {
    const navigate = useNavigate();
    const { t, ready } = useTranslation(['login'], { useSuspense: false });

    return (
        <React.Fragment>
            { ready && 
                <PageMetadata 
                    pageDescription={t('select_provider_page_description')} 
                    pageTitle={t('select_provider_page_title')}
                /> 
            }
            <LoginScreenLayout>
                <SelectEffectiveProviderForm onEffectiveProviderChanged={() => navigate('/', { replace: true })} />
            </LoginScreenLayout>
        </React.Fragment>
    )
}
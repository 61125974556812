import { ISchedule, ScheduleSlot } from './interfaces';
import * as moment from 'moment-timezone';

export const parseIScheduleToScheduleSlot = (
    schedule: ISchedule,
    timeZone: string = Intl.DateTimeFormat().resolvedOptions().timeZone,
): ScheduleSlot => ({
    addressId: schedule.addressId,
    duration: schedule.duration,
    isBackToBack: schedule.isBackToBack,
    isManagedCalendar: schedule.isManagedCalendar,
    scheduleId: schedule.scheduleId,
    // // until the UTC conversion is fixed on the server, we'll use the client's
    // // computer locale to control the timezone conversion if provider timezone is not passed.
    // startDate: moment.tz(moment.tz(schedule.clientDateTime, providerTimeZone).toDate(), timeZone)
    // UPDATE: according to June and Ashok, the providers expect the time to be in their timezone
    startDate: moment.tz(schedule.clientDateTime, 'YYYY-MM-DDTHH:mm:ss', timeZone)
})

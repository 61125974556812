import React, { lazy, Suspense } from 'react';
import { Route, useNavigate } from 'react-router';
import { Routes, useLocation } from 'react-router-dom';
import { InvoicesTableView, InvoiceOverviewView } from 'components/Invoicing';
import { LayoutResponsive } from './components/Layout';
import { ProviderProfile } from 'components/ProviderProfile';
import { StaffTableView } from 'components/Staff';
import { ToDosView } from 'components/ToDos';
import { useAuth, LoginScreen, ForgotPasswordScreen, ResetPasswordScreen,
    SelectEffectiveProviderScreen, ConfirmRegisterationScreen, ADMIN_USER_ID } from 'components/Auth';
import { AboutUs, FAQ, HelpScreen, LearnScreen } from 'components/MetaPages';
import { BookApptStep1 } from 'components/Calendaring/AppointmentModals/BookAppt/BookApptStep1';
import { BookAppointmentView } from 'components/Calendaring/AppointmentModals';
import { useFeatureManager } from 'hooks/useFeatureManager';
import { ActivityDetailsView, ActivitiesTableView, ConfirmAcceptanceView, ScheduleFirstSessionView,
    ScheduleNextSessionView, CloseCaseChecklistView, UpdateApptStatusView, AddClient } from 'components/CaseManagement';
import { RequestsTableView } from 'components/Requests/RequestsTableView';
import { FormsRouter, WSPReportView, PreAssessmentsView } from 'components/Forms';
import { Spinner } from 'components/Shared';
import { Dashboard } from 'components/Dashboard';
import { AuthLayout } from 'AuthLayout';

const CalendaringView = lazy(() => import('components/Calendaring'));

export const AppRouting = () => {
    const { authenticated, effectiveProviderData, providerData } = useAuth();
    const { isListReferralFeatureEnabled, isAdditionalHoursFeatureEnabled } = useFeatureManager();

    const layoutVariant = authenticated && effectiveProviderData?.providerId !== ADMIN_USER_ID
        ? 'normal'
        : 'basic';
        
    const { pathname } = useLocation();
    const navigate = useNavigate();
    const hideNavigation = (pathname.startsWith('/select_provider') || pathname.startsWith('/reset_password'));

    const canAccessSelectProviderPage = () => {
        return authenticated && (effectiveProviderData?.providerId === ADMIN_USER_ID || effectiveProviderData?.isAdmin)
    }

    React.useEffect(() => {
        if (pathname==="/providers" && !((providerData?.providerId !== ADMIN_USER_ID && providerData?.isAdmin && providerData?.associateWithVendor)
        || (providerData?.providerId === ADMIN_USER_ID && effectiveProviderData?.isAdmin && effectiveProviderData?.associateWithVendor))) {
            navigate("/");
        }
        if (effectiveProviderData?.providerId === ADMIN_USER_ID) {
            navigate("/select_provider");
        }
    }, [effectiveProviderData, providerData, pathname]);

    return (
        <>
            <LayoutResponsive variant={layoutVariant} showNavigation={!hideNavigation}>
                <Suspense fallback={<Spinner fillParent />}>
                    <Routes>
                        {
                            !authenticated &&
                            [
                                <Route path="/login" element={<LoginScreen />} key="login"/>,
                                <Route path="/forgot_password" element={<ForgotPasswordScreen />} key="forgot_password"/>,
                                <Route path="/reset_password/:token" element={<ResetPasswordScreen />} key="reset_password"/>,
                                <Route path="/confirm_registration/:token" element={<ConfirmRegisterationScreen />} key="confirm_registration"/>
                            ]
                        }
                        <Route path="/faq" element={<FAQ />} />
                        <Route path="/about_us" element={<AboutUs />} />
                        <Route element={<AuthLayout authenticated={authenticated} />}>
                            {
                                canAccessSelectProviderPage() &&
                                <Route path="/select_provider" element={<SelectEffectiveProviderScreen />} />
                            }
                            <Route path="/" element={<Dashboard />} />
                            <Route path="/calendar" element={<CalendaringView />} />
                            <Route path="/profile/*" element={<ProviderProfile />} />
                            <Route path="/learn/*" element={<LearnScreen />} />
                            <Route path="/help" element={<HelpScreen />} />
                            <Route path="/providers" element={<StaffTableView />} />
                            <Route path="/providers/:providerId/profile" element={<ProviderProfile />} />

                            <Route path="/clients/:activityId/sessions/:sessionId/forms/:formId" element={<FormsRouter />} />
                            <Route path="/clients/:activityId/sessions/:sessionId/status" element={<UpdateApptStatusView />} />
                            <Route path="/clients/:activityId/schedule_first_session" element={<ScheduleFirstSessionView />} />
                            <Route path="/clients/:activityId/schedule_next_session" element={<ScheduleNextSessionView />} />
                            <Route path="/clients/:activityId/forms/:formId" element={<FormsRouter />} />
                            <Route path="/clients/:activityId/reports/:formId" element={<WSPReportView />} />
                            <Route path="/clients/:activityId/*" element={<ActivityDetailsView />} />
                            <Route path="/clients" element={<ActivitiesTableView />} />
                            <Route path="/forms/:formId" element={<FormsRouter />} />
                            {
                                isAdditionalHoursFeatureEnabled() &&
                                <Route path="/requests" element={<RequestsTableView />} />
                            }
                            <Route path="/close_case/:activityId" element={<CloseCaseChecklistView />} />
                            <Route path="/invoicing" element={<InvoicesTableView />} />
                            <Route path="/invoicing/:invoiceId" element={<InvoiceOverviewView />} />
                            <Route path="/todos" element={<ToDosView />} />
                            {
                                isListReferralFeatureEnabled() &&
                                <Route path="/add-clients" element={<AddClient />} />
                            }
                            <Route path="/sessions/book/step1" element={<BookApptStep1 />} />
                            <Route path="/sessions/book/step2" element={<BookAppointmentView />} />
                            <Route path="/confirmclient/:activityId" element={<ConfirmAcceptanceView />} />
                            <Route path="/preassessments/:activityId/:individualId?" element={<PreAssessmentsView />} />

                            {/* <Route path="*" element={<Navigate to="/" />} /> */}
                        </Route>
                    </Routes>
                </Suspense>
            </LayoutResponsive>
        </>
    )
}
/**
 * Util hook wrapping the 'useState' hook, allowing you to use large state objects without having to worry 
 * about dividing your local state into billions of little small useState(s)
 */

import { useState } from "react";

export function useBulkyState<T>(initialState: T): [T, (values: Partial<T>) => void] {
    
    const [state, setBulkyState] = useState(initialState);

    const setState = (values: Partial<T>): void => {
        setBulkyState((prevState: T) => ({
            ...prevState,
            ...values
        }))
    }

    return [
        state,
        setState
    ]
} 
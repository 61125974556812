import { gql, TypedDocumentNode } from '@apollo/client';
import { IActivityListEntity } from './interfaces';

export const ACTIVITIES_QUERY: TypedDocumentNode<ActivitiesQueryData, ActivitiesQueryVars> = gql`
    query ACTIVITIES_QUERY($providerId: Long!) {
        activities(providerId: $providerId) {
            client {
                firstName
                lastName
            }
            organization {
                name
            }
            presentingIssue
            activityId
            targetedSystem {
                systemCode
            }
            solutionCode
        }
    }
`

interface ActivitiesQueryData {
    activities: IActivityListEntity[]
}

interface ActivitiesQueryVars {
    providerId: number
}
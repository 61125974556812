
import React, { useState, FC, useMemo } from 'react';
import { Container } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { SearchInput } from 'components/Shared/Inputs';
import { DataRequest } from 'components/Shared/MaterialTable';
import { Spinner } from 'components/Shared';
import { PageMetadata } from 'components/Shared/PageMetadata';
import { RequestsTable } from './components/RequestsTable';
import { useAuth } from 'components/Auth';
import { useQuery } from '@apollo/client';
import { GET_REQUESTS_QUERY } from './queries';

export interface IRequestsTableViewProps {}

export const RequestsTableView: FC<IRequestsTableViewProps> = (_) => {
    const { t, ready: translationsReady } = useTranslation(['requests', 'common'], { useSuspense: false });

    const { effectiveProviderData } = useAuth();

    const [ dataRequest, setDataRequest ] = useState<DataRequest>({
        sorts: {
            "requestID": "asc"
        },
        take: 10,
        skip: 0,
        search: ''
    });

    const requestsQuery = useQuery(GET_REQUESTS_QUERY, {
        variables: { providerId: effectiveProviderData?.providerId ?? 0 },
        errorPolicy: 'all',
        fetchPolicy: 'no-cache'
    });

    const approvals = useMemo(() => 
        requestsQuery.data?.additionalHoursApprovals?.filter(approval => Boolean(approval.request))
    , [requestsQuery.data?.additionalHoursApprovals]);

    const handleSearchValueChange = (value: string) => {
        setDataRequest({...dataRequest, search: value, skip: 0});
    }

    return (
        <React.Fragment>
            { translationsReady && 
                <PageMetadata 
                    pageDescription={t('requests_page_description')} 
                    pageTitle={t('requests_page_title')}
                /> 
            }
            <div className="table_view_wrapper">
            {
                !translationsReady
                ? <Spinner fillParent/>
                : <Container>
                    <div className="table_view_title_wrapper">
                        <h1>{t('navigation:requests', 'Requests')}</h1>
                        <SearchInput value={dataRequest.search} onChange={handleSearchValueChange}
                            label={t('search_requests_input_label')} placeholder={t('search_requests_input_label')}/>
                    </div>
                    <RequestsTable
                        dataLoading={requestsQuery.loading}
                        dataRequest={dataRequest}
                        setDataRequest={setDataRequest}
                        showActivityInfo={true}
                        data={approvals}
                    />
                </Container>
            }
            </div>
        </React.Fragment>
    )
}
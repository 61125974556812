import React, { FC } from 'react';
import { Button, TextBox } from 'components/Shared/Inputs';
import { useTranslation } from "react-i18next";
import { CircularProgress } from '@mui/material';
// import { useSnackbar } from 'notistack';
import './RequestsForm.scss';
import { FormikErrors, useFormik } from "formik";
import { useMutation } from "@apollo/client";
import { CREATE_REQUEST_MUTATION } from '../../queries';
import { useAuth } from 'components/Auth';

export interface IRequestForm {
    activityId: number,
    providerId: number,
    onExit: () => void,
    onRequestCreated: () => void
}

interface RequestFormValues {
    requestedHours: number | null,
    reason: string
}

export const RequestForm: FC<IRequestForm> = (props) => {
    const { activityId, providerId, onExit, onRequestCreated } = props;

    const { t } = useTranslation('requests', { useSuspense: false });
    const { t: t_common } = useTranslation('common', { useSuspense: false });

    const { effectiveProviderData, providerData } = useAuth();

    const [createRequest, createRequestMutation] = useMutation(CREATE_REQUEST_MUTATION);

    const formik = useFormik<RequestFormValues>({
        initialValues: {
            requestedHours: null,
            reason: ''
        },
        validate: (values) => {
            const errors: FormikErrors<RequestFormValues> = {};

            if ((values.requestedHours ?? 0) < 1) {
                errors.requestedHours = t('hours_request_validation_message');
            }
            if (!values.reason) {
                errors.reason = t('hours_reason_validation_message');
            }

            return errors;
        },
        onSubmit: async (values) => {
            if (values.requestedHours) {
                createRequest({
                    variables: {
                        activityId: activityId,
                        providerId: providerId,
                        request: {
                            reason: values.reason,
                            requestedHours: values.requestedHours,
                            createdBy: `${providerData?.firstName} ${providerData?.lastName}`,
                            requestedBy: `${effectiveProviderData?.firstName} ${effectiveProviderData?.lastName}`
                        }
                    }
                }).then(() => onRequestCreated())
            }
        }
    });


    const handleHoursChange = (event) => {
        const value = event.target.value ?? '';
        if (/^[0-9]*$/.test(value)) {
            formik.setFieldValue('requestedHours', value ? Number(value) : null);
        }
    }

    const handleCancel = () => {
        formik.resetForm();
        onExit();
    }

    const submitDisabled = !formik.isValid || createRequestMutation.loading;

    return (
        <form className="requests_form_root" onSubmit={formik.handleSubmit}>
            <div className="new_request_form_wrapper">
                <TextBox
                    value={formik.values.requestedHours}
                    label={t('hours_requested')}
                    name="hoursRequest"
                    placeholder={t('enter_hours')}
                    className="request_form_hours_input"
                    disabled={createRequestMutation.loading}
                    error={Boolean(formik.errors.requestedHours)}
                    helperText={formik.errors.requestedHours}
                    onChange={handleHoursChange}
                    type="number"
                />
                <TextBox
                    value={formik.values.reason}
                    label={t('reason')}
                    name="reason"
                    placeholder={t('enter_reason')}
                    className="request_form_reason_input"
                    disabled={createRequestMutation.loading}
                    error={Boolean(formik.errors.reason)}
                    helperText={formik.errors.reason}
                    onChange={formik.handleChange}
                    multiline
                    rows={2}
                    maxRows={4}
                />
            </div>
            <div className="action-buttons">
                {
                    createRequestMutation.loading &&
                    <CircularProgress />
                }
                <Button variant="outline" onClick={handleCancel}>{t_common('cancel')}</Button>
                <Button type="submit" variant="opaque" disabled={submitDisabled}>{t_common('submit')}</Button>
            </div>
        </form>
    )

}
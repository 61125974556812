import { ApplicationInsights, ITelemetryItem } from '@microsoft/applicationinsights-web';
import { ReactPlugin, withAITracking } from '@microsoft/applicationinsights-react-js';
import { IConfig } from 'providers';

export const reactPlugin = new ReactPlugin();
export const activateAppInsights = (config: IConfig) => {
    const appInsights = new ApplicationInsights({
        config: {
            instrumentationKey: config.ApplicationInsights?.InstrumentationKey,
            extensions: [reactPlugin],
            enableAutoRouteTracking: true,
        }
    });
    appInsights.addTelemetryInitializer((envelope: ITelemetryItem) => {
        envelope.tags = envelope.tags || [];
        envelope.tags.push({ "ai.cloud.role": config.ApplicationInsights?.AzureMonitoringRoleName });
    });

    appInsights.loadAppInsights();
};


export { withAITracking };

import React, { FC } from 'react';
import { TemplateNames, WspTemplateNames } from '../../constants';
import { DAST, ETOS, PHQ9, WOS, AUDIT_C, AUDIT, RiskAssessment, Questionnaire, WSPReport, SBQ } from '../Forms';
import { SurveyJSContainer, SurveyJSContainerProps } from '../SurveyJS';
import { IFormInfo } from '../../interfaces';
import { FullScreenMessage } from 'components/Shared/FullScreenMessage';
import { Button } from 'components/Shared/Inputs';
import { useTranslation } from 'react-i18next';
import { SessionNote } from '../Forms/SessionNote';

interface FormPresenterProps {
    form: IFormInfo,
    activityId: number,
    sessionId: number | null,
    readOnly: boolean,
    tags?: string[],
    defaultValues?: any,
    onSubmit: (result: string) => void,
    onSkip?: () => void,
    onSaveDraft?: (result: string) => void,
}

export const FormPresenter: FC<FormPresenterProps> = (props) => {
    const { form, onSubmit, defaultValues, readOnly, onSkip, onSaveDraft } = props;
    const { t } = useTranslation('assessments', { useSuspense: false })

    const handleSurveySubmit = (outcome) => {
        outcome && onSubmit(outcome);
    }

    const handleSubmit = (result) => {
        onSubmit(JSON.stringify(result));
    }

    const surveyProps: SurveyJSContainerProps = {
        template: form.template,
        formData: form.result,
        readOnly: readOnly,
        formId: form.id,
        defaultValues: defaultValues,
        onComplete: handleSurveySubmit,
        activityId: props.activityId
    }

    switch (form.templateName) {
        case TemplateNames.DAST: {
            return <DAST result={JSON.parse(form.result)} onSubmit={handleSubmit}/>
        }
        case TemplateNames.AUDIT_C: {
            return <AUDIT_C result={JSON.parse(form.result)} onSubmit={handleSubmit}/>
        }
        case TemplateNames.SBQ: {
            return <SBQ result={JSON.parse(form.result)} onSubmit={handleSubmit}/>
        }
        case TemplateNames.AUDIT: {
            return <AUDIT result={JSON.parse(form.result)} onSubmit={handleSubmit}/>
        }
        default: {
            if (!form.template) {
                return <FullScreenMessage title={t('assessment_not_found_message')}>
                    {
                        onSkip &&
                        <Button onClick={onSkip}>{t('continue_to_next_assessment_button_label')}</Button>
                    }
                </FullScreenMessage>
            }
            switch (form.templateName) {
                case TemplateNames.PHQ9: {
                    return <PHQ9 {...surveyProps}/>
                }
                case TemplateNames.WOS: {
                    return <WOS {...surveyProps}/>
                }
                case TemplateNames.ETOS: {
                    return <ETOS {...surveyProps}/>
                }
                case TemplateNames.RiskAssessment: {
                    return <RiskAssessment {...surveyProps}/>
                }
                case TemplateNames.Questionnaire: {
                    return <Questionnaire {...surveyProps}/>
                }
                case WspTemplateNames.InitialAssessmentReport: {
                    return <WSPReport reportType="WSP_IA" {...surveyProps}/>
                }
                case WspTemplateNames.MonthlyAssessmentReport_WRP: {
                    return <WSPReport reportType="WSP_MO" {...surveyProps}/>
                }
                case WspTemplateNames.ClosureAssessmentReport_WRP: {
                    return <WSPReport reportType="WSP_CL" {...surveyProps}/>
                }
                case TemplateNames.SessionNote: {
                    return <SessionNote {...surveyProps} onSaveDraft={onSaveDraft} />
                }
                default: {
                    return <SurveyJSContainer {...surveyProps}/>
                }
            }
        }
    }
}
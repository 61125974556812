import { ToDo, ToDoBase, ToDosDataRequest } from '../interfaces';
import { StepDefinition, ToDosOrder } from '../constants';
import { AssessmentNames, FormRouteMap, TemplateNames } from 'components/Forms/constants';

export const getToDoTypes = (toDos: ToDo[] | undefined): StepDefinition[] | undefined => {
    const uniqToDoFilterValues = toDos
        ? Array.from<StepDefinition>(new Set(toDos.map(toDo => toDo.step)))
            .sort((a, b) => ToDosOrder[a] - ToDosOrder[b])
        : undefined;
    return uniqToDoFilterValues;
}

export const sortToDos = (todos: ToDo[] | undefined): ToDo[] | undefined => {
    return todos?.sort((a, b) => {
        if (a.step === b.step) {
            const dateA = a.createTime && new Date(a.createTime).getTime();
            const dateB = b.createTime && new Date(b.createTime).getTime();
            return (dateA || 0) - (dateB || 0);
        } else {
            return ToDosOrder[a.step] - ToDosOrder[b.step];
        }
    });
}


export const processDataRequest = (toDos: ToDo[] | undefined, dataRequest: ToDosDataRequest): [ToDo[] | undefined, number] => {
    let processedToDos = toDos?.slice();
    if (dataRequest.filters.step !== 'ALL') {
        processedToDos = processedToDos?.filter(toDo => toDo.step === dataRequest.filters.step);
    }

    processedToDos = sortToDos(processedToDos);

    const total = processedToDos ? processedToDos.length : 0;
    processedToDos = processedToDos?.slice(dataRequest.skip, dataRequest.skip + dataRequest.take);

    return [processedToDos, total];
}

export const getToDoLink = (toDo: ToDoBase): string => {
    const neverReached = (_: never) => {};
    
    switch (toDo.step) {
        case StepDefinition.CONFIRM_REFERRAL: {
            return `/confirmclient/${toDo?.activityInfo?.activityId}`;
        }
        case StepDefinition.ENTER_DATE_FIRST_APPT: {
            return `/clients/${toDo?.activityInfo?.activityId}/schedule_first_session`;
        }
        case StepDefinition.LIST_REFERRAL: {
            return `/clients/${toDo?.activityInfo?.activityId}`;
        }
        case StepDefinition.UPDATE_APPT_STATUS: {
            return `/clients/${toDo?.activityInfo?.activityId}/sessions/${toDo.sessionId}/status`;
        }
        case StepDefinition.FIRST_APPT_ASSESSMENT: {
            return `/clients/${toDo?.activityInfo?.activityId}/forms/${FormRouteMap[AssessmentNames.FirstAppointmentAssessment]}`;
        }
        case StepDefinition.ENTER_DATE_NEXT_APPT: {
            return `/clients/${toDo?.activityInfo?.activityId}/schedule_next_session`;
        }
        case StepDefinition.FOLLOW_UP: {
            return `/clients/${toDo?.activityInfo?.activityId}/forms/${FormRouteMap[TemplateNames.FollowUp]}`;
        }
        case StepDefinition.REVIEW_PRE_ASSESSMENT: {
            return `/clients/${toDo?.activityInfo?.activityId}/documents`;
        }
        case StepDefinition.SUBMIT_INVOICE: {
            return `/clients/${toDo?.activityInfo?.activityId}/invoices/create`;
        }
        case StepDefinition.COMPLETE_INITIAL_ASSESSMENT_STEP:
        case StepDefinition.SUBMIT_INITIAL_ASSESSMENT_REPORT:
        case StepDefinition.COMPLETE_MONTHLY_ASSESSMENT_STEP:
        case StepDefinition.SUBMIT_MONTHLY_ASSESSMENT_REPORT:
        case StepDefinition.COMPLETE_CLOSURE_ASSESSMENT_STEP:
        case StepDefinition.SUBMIT_CLOSURE_ASSESSMENT_REPORT: 
        case StepDefinition.RESUBMIT_INITIAL_ASSESSMENT_REPORT: 
        case StepDefinition.RESUBMIT_MONTHLY_ASSESSMENT_REPORT: 
        case StepDefinition.RESUBMIT_CLOSURE_ASSESSMENT_REPORT: {
            return `/clients/${toDo?.activityInfo?.activityId}/reports/${toDo?.formId}`;
        }        

        default: {
            neverReached(toDo?.step);
            return '';
        }
    }
}

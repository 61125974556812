import React, { FC } from 'react';
import { DashboardBody } from './components/DashboardBody';
import { DashboardJumbotron } from './components/DashboardJumbotron';
import './Dashboard.scss';
import { sortToDos } from 'components/ToDos';
import { useTranslation } from 'react-i18next';
import { PageMetadata } from 'components/Shared/PageMetadata';
import { useQuery } from '@apollo/client';
import { TODO_QUERY } from 'components/ToDos/queries';
import { ProviderData, useAuth } from 'components/Auth';

interface DashboardProps { }

export const Dashboard: FC<DashboardProps> = (_) => {
    const { t, ready } = useTranslation('common', { useSuspense: false });
    const { effectiveProviderData = {} as ProviderData } = useAuth();

    const toDosQuery = useQuery(TODO_QUERY, {
        variables: {
            providerId: effectiveProviderData!.providerId
        }
    });
    
    const toDos = toDosQuery.data?.toDos;

    const filteredToDos = toDos?.filter(todo => todo.visibility);
    const sortedToDos = sortToDos(filteredToDos);

    return (
        <React.Fragment>
            { ready && 
                <PageMetadata 
                pageDescription={t('page_description')} 
                pageTitle={t('page_title')}
                /> 
            }
            <div className="dashboard_container">
                <DashboardJumbotron toDos={sortedToDos} loading={toDosQuery.loading}/>
                <DashboardBody toDos={sortedToDos} loading={toDosQuery.loading}/>
            </div>
        </React.Fragment>
    )
}
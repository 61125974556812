import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { HighLevelActivityData, HighLevelReferralData } from '../../interfaces';
import { Button } from 'components/Shared/Inputs';

interface HighLevelDetailsProps {
    activity?: HighLevelActivityData;
    referral?: HighLevelReferralData;
    confirmed?: boolean;
    onContinue: () => void;
}

export const HighLevelDetails: FunctionComponent<HighLevelDetailsProps> = (props) => {
    const { activity, confirmed, referral, onContinue } = props;
    const { t } = useTranslation('caseManagement', { useSuspense: false });

    const renderValue = (title, value) => (
        <div className="client_detail">
            <p className='value'>{value}</p>
            <span className='label'>{title}</span>
        </div>
    )

    return (
        <div className="highLevel_details_wrapper_div">
            {
                confirmed ? (
                    <div className="highLevelDetails_description_confirmed">
                        <p className="highLevelDetails_description">{t('thank_you_for_accepting_case', 'Thank you for accepting this case.')}</p>
                        <p className="highLevelDetails_description">
                            {t('here_is_your_client_contact_info', 'Here is the contact information for your client:')}
                        </p>
                    </div>
                ) : 
                <p className="highLevelDetails_description">
                    {t('you_have_a_new_client')} - {`${activity?.client?.firstName}.`} {t('here_are_some_high_level_details')}
                </p>
            }
            
            {renderValue(t('name'), `${activity?.client?.firstName} ${activity?.client?.lastName}`)}
            {confirmed && renderValue(t('email'), referral?.clientEmail || '---')}
            {!confirmed && renderValue(t('activity_id'), activity?.activityId || '---')}
            {!confirmed && renderValue(t('organization'), activity?.organization?.name || '---')}
            {!confirmed && renderValue(t('modality'), activity?.modality || '---')}
            {!confirmed && renderValue(t('service_for'), activity?.serviceFor || '---')}
            {!confirmed && renderValue(t('client_type'), activity?.eligibility || '---')}
            {confirmed && renderValue(t('preferred_phone_number'), activity?.serviceForms.clientPhoneNumber || '---')}
            {confirmed && renderValue(t('calling_preference'), referral?.clientCallingPreference || '---')}
            {renderValue(t('time_zone'), activity?.timeZoneStandardName || '---')}
            {renderValue(t('scheduling_preference'), referral?.schedulingPreferenceString || '---')}
            {renderValue(t('notes'), referral?.serviceFormNotes || '---')}
            <Button variant="opaque" onClick={onContinue}>
                {t('continue')}
            </Button>
        </div>
    )
}
import  { FC } from 'react';
import { SurveyModel } from 'survey-react';
import { SurveyJSContainer, SurveyJSContainerProps, useSurveyJSUtils } from '../../../components/SurveyJS';
import { WOSDeclineReasonToAssessmentOutcomeMapping, WosFormAnswers, WosDeclinePrePostMapping } from './utils';
import { useTranslation } from 'react-i18next';
import { DECLINE_WOS_INFO_QUERY } from 'components/CaseManagement/ActivityDetails/queries';
import { useQuery } from '@apollo/client';

interface WOS_Props extends SurveyJSContainerProps {}

export const WOS: FC<WOS_Props> = (props) => {
    const { onSurveyJSInitialized, defaultValues, activityId, ...rest } = props;
    const { t } = useTranslation('formNotes', { useSuspense: false });
    const { updateAriaLabels } = useSurveyJSUtils();
    const _defaultValues: any = {};
    

    const declineWosInfoQuery = useQuery(DECLINE_WOS_INFO_QUERY, {
        variables: { activityId: activityId! },
        fetchPolicy: 'network-only',
        errorPolicy: 'all'
    });

    const outcome = declineWosInfoQuery?.data?.declineWosInfo?.outcome;
    const declinePre = declineWosInfoQuery?.data?.declineWosInfo?.declinePreAssessmentReason;
    declinePost(outcome, declinePre);   

    function declinePost(outcome: string | undefined, declinePre: string | undefined) {  
        
        if(declinePre)
        {
            const mapping = WosDeclinePrePostMapping[declinePre];

            if (mapping && mapping._decline) {
                _defaultValues.decline_post_assessment = WosFormAnswers.DECLINE_YES_ANSWER;
                _defaultValues.decline_post_assessment_reason = mapping._reason;
            } 
        }
        else if(outcome)
        {
            const declineReason = WOSDeclineReasonToAssessmentOutcomeMapping[outcome];

            if (declineReason && declineReason._decline) {
                _defaultValues.decline_post_assessment = WosFormAnswers.DECLINE_YES_ANSWER;
                _defaultValues.decline_post_assessment_reason = declineReason._reason;

            }  
        }
                      
    }

    const handleSurveyJSInitialized = (surveyModel: SurveyModel) => {
        onSurveyJSInitialized?.(surveyModel);

        surveyModel.current?.onAfterRenderPanel.add(() => {

            var tableDiv = document?.querySelectorAll("div[name='30_days_degree']");
            if (tableDiv && tableDiv.length > 0) {
                updateAriaLabels(tableDiv?.[0]);

                if (tableDiv.length === 2) {
                    updateAriaLabels(tableDiv?.[1]);
                }
            }  
        })
    }

    return <SurveyJSContainer
        onSurveyJSInitialized={handleSurveyJSInitialized}
        {...rest}
        coachingNote={t('providerhub/assessment/wos_note')}
        coachingNoteTitle={t('providerhub/assessment/wos_note_title')}
        defaultValues={{..._defaultValues, ...defaultValues}}
    />
}
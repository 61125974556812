import { gql, TypedDocumentNode } from "@apollo/client";
import { ActivityDataForAppointmentBooking, ContactAttempt } from './interfaces';


export const SAVE_APPT_DATE_MUTATION: TypedDocumentNode<SaveApptDateMutationData, SaveApptDateMutationVars> = gql`
    mutation SAVE_FIRST_APPT_DATE($activityId: Long!, $date: DateTime!, $providerId: Long!, $isFirstAppointment: Boolean, $duration: Decimal, $mismatchReasonCode: Int, $scheduleId: Long) {
        bookAppointment(activityId: $activityId, date: $date, providerId: $providerId, isFirstAppointment: $isFirstAppointment, duration: $duration, mismatchReasonCode: $mismatchReasonCode, scheduleId: $scheduleId)
    }
`

export interface SaveApptDateMutationData {
    bookAppointment: any
}

export interface SaveApptDateMutationVars {
    activityId: number,
    providerId: number,
    date: Date | string,
    duration?: number,
    isFirstAppointment?: boolean,
    mismatchReasonCode?: number,
    scheduleId?: number
}



export const TERMINATE_TODO_MUTATION: TypedDocumentNode<TerminateTodoMutationData, TerminateTodoMutationVars> = gql`
    mutation TERMINATE_TODO_MUTATION($workflowId: String!) {
        terminateToDo(workflowId: $workflowId)
    }
`

export interface TerminateTodoMutationData {
    terminateToDo: boolean,
}

export interface TerminateTodoMutationVars {
    workflowId: string,
}



export const GET_CONTACT_ATTEMPTS_QUERY: TypedDocumentNode<ContactAttemptsQueryData, ContactAttemptsQueryVars> = gql`
    query GET_CONTACT_ATTEMPTS_QUERY($activityId: Long, $attemptType: String, $providerId: Long!) {
        contactClientAttempts(activityId: $activityId, attemptType: $attemptType, providerId: $providerId){
            contactDate
        }
    }
`

interface ContactAttemptsQueryData {
    contactClientAttempts: ContactAttempt[],
}

interface ContactAttemptsQueryVars {
    activityId: number,
    attemptType: string,
    providerId: number
}



export const GET_ACTIVITY_DATA_QUERY: TypedDocumentNode<GetActivityDataQueryData, GetActivityDataQueryVars> = gql`
    query GET_ACTIVITY_DATA($activityId: Long!) {
        activity(activityId: $activityId) {
            activityId
            providerFirstAvailableAppointmentDate
            isHourlyContract
            openDate
            client {
                firstName
                lastName
            }
            sessions {
                duration
                sessionId
                sessionStatus
                startDateTimeUTC
                sessionStatusId
                providerHubAppointmentStatusId
            }
            targetedSystem {
                systemCode
            }
            sessionBooking {
                allowed
                prohibitionReasonCode
            }
            caseDetailHours {
                clientRemainingHours
                numberOfHoursUsed
                totalHoursAvailable
                numberOfMaxHours
                approvedHours
            }
            pcShowFamilyHours
            contractAllowsAdditionalHours
        }
    }
`

export interface GetActivityDataQueryVars {
    activityId: number
}

export interface GetActivityDataQueryData {
    activity: ActivityDataForAppointmentBooking
}



export const SAVE_CONTACT_ATTEMPTS_MUTATION
    : TypedDocumentNode<SaveContactAttempsMutationData, SaveContactAttempsMutationVars> = gql`
    mutation SAVE_CONTACT_ATTEMPTS(
        $activityId: Long!,
        $dates: [DateTime!],
        $attemptType: String,
        $providerId: Long!,
        $close: Boolean
    ) {
        saveContactClientAttempts(
            activityId: $activityId,
            dates: $dates,
            attemptType: $attemptType,
            providerId: $providerId,
            close: $close
        )
    }
`

interface SaveContactAttempsMutationData {
    saveContactClientAttempts: any
}

interface SaveContactAttempsMutationVars {
    activityId: number,
    dates: string[],
    attemptType?: string,
    providerId: number,
    close: boolean
}



export const MISMATCH_REASONS_QUERY: TypedDocumentNode<MismatchReasonsQueryData, MismatchReasonsQueryVars> = gql`
    query MISMATCH_REASONS_QUERY {
        appointmentMismatchReasons {
            id
            description 
        }
    }
`

interface MismatchReasonsQueryData {
    appointmentMismatchReasons: {
        id: string,
        description: string
    }[]
}

interface MismatchReasonsQueryVars {}
import { gql, TypedDocumentNode } from '@apollo/client';
import { CreateOverHoursRequestDTOInput, OverHoursApprovalDTO } from './interfaces';

export const GET_REQUESTS_QUERY: TypedDocumentNode<RequestsQueryData, RequestsQueryVars> = gql`
    query GET_REQUESTS_QUERY($providerId: Long!, $activityId: Long) {
        additionalHoursApprovals(providerId: $providerId, activityId: $activityId) {
            activityId
            approvalId
            approvedBy
            approvedDate
            approvedHours
            caseId
            clientFirstName
            clientId
            clientLastName
            contractPeriodId
            createdBy
            createdDate
            goOverHoursTypeId
            goOverHoursType
            modifiedBy
            modifiedDate
            reason
            status
            request {
                requestId
                contractPeriodId
                caseId
                clientId
                requestedHours
                requestedHoursStatusCode
                requestedHoursStatusString
                requestedBy
                requestedDate
                reason
                status
                createdBy
                createdDate
                modifiedDate
                modifiedBy
            }
        }
    }
`

interface RequestsQueryData {
    additionalHoursApprovals: OverHoursApprovalDTO[]
}

interface RequestsQueryVars {
    providerId: number,
    activityId?: number
}


export const CREATE_REQUEST_MUTATION: TypedDocumentNode<CreateRequestMutationData, CreateRequestMutationVars> = gql`
    mutation CREATE_REQUEST_MUTATION($activityId: Long!, $providerId: Long!, $request: CreateOverHoursRequestDTOInput) {
        createAdditionalHoursRequest(activityId: $activityId, providerId: $providerId, request: $request)
    }
`

interface CreateRequestMutationData {
    createAdditionalHoursRequest: number
}

interface CreateRequestMutationVars {
    activityId: number,
    providerId: number,
    request: CreateOverHoursRequestDTOInput
}

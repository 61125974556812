import React, { FC } from 'react';
import { Grid } from '@mui/material';
import { ToDo, ToDoCard } from 'components/ToDos';
import { Spinner } from 'components/Shared';
import { useTranslation } from 'react-i18next';


interface DashboardToDosGridProps {
    toDos: ToDo[] | undefined,
    loading: boolean
}

export const DashboardToDosGrid: FC<DashboardToDosGridProps> = ({ toDos, loading }) => {
    const { t } = useTranslation('dashboard', { useSuspense: false });
    
    return (
        loading
        ? <Spinner/>
        : toDos && toDos.length > 0
            ? <Grid className="dashboard_todos_container" container spacing={3}>
                {
                    toDos.map((toDo, index) => (
                        <Grid item key={index}>
                            <ToDoCard toDo={toDo}/>
                        </Grid>
                        )
                    )
                }
            </Grid>
            : <div>{t('dashboard_no_todos_message', 'No pending To-dos')}</div>
    )
}
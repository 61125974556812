import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import useTimezoneUtils, { DATE_FORMATS } from 'hooks/useTimezoneUtils';
import { SectionContainer } from '../../Other/SectionContainer';
import { LiabilityInsurance as ILiabilityInsurance } from '../../../interfaces';
import { Checkbox, FormControlLabel, Grid } from '@mui/material';
import { Spinner } from 'components/Shared';
import { confirm } from 'components/Shared/ConfirmDialog';
import { Button, DateInput } from 'components/Shared/Inputs';
import { UPDATE_LIABILITY_INSURANCE_MUTATION } from './queries';
import { useMutation } from '@apollo/client';
import { useFormik } from 'formik';
import moment from 'moment';

interface LiabilityInsuranceProps {
    providerId: number,
    liabilityInsurance: ILiabilityInsurance | undefined,
    onUpdated: (liabilityInsurance: ILiabilityInsurance) => void
}

interface LiabilityInsuranceFormValues {
    expiryDate: string,
    onFile: boolean
}

export const LiabilityInsurance: FC<LiabilityInsuranceProps> = (props) => {
    const { providerId, liabilityInsurance, onUpdated } = props;
    
    const { t, ready: translationsReady } = useTranslation('providerprofile', { useSuspense: false });
    const { t: t_common, ready: commonTranslationsReady } = useTranslation('common', { useSuspense: false });

    const [cachePendingUpdate, setCachePendingUpdate] = useState(false);
    const [inEdit, setInEdit] = useState(false);

    useEffect(() => {
        setCachePendingUpdate(false);
        setInEdit(false);
        formik.resetForm({
            values: {
                expiryDate: liabilityInsurance?.expiryDate ?? moment().format('YYYY-MM-DD'),
                onFile: liabilityInsurance?.onFile ?? false
            }
        });
    }, [liabilityInsurance]);

    const [updateData, updateDataMutation] = useMutation(UPDATE_LIABILITY_INSURANCE_MUTATION, {
        update: (_, result) => {
            if (result.data?.updateLiabilityInsurance?.liabilityInsurance) {
                setCachePendingUpdate(true);
                onUpdated(result.data?.updateLiabilityInsurance?.liabilityInsurance);
            }
        }
    });

    const { createDateFormatFunction, DATE_PORTION_MOMENT_FORMAT, localeDateFormat } = useTimezoneUtils();
    const formatCredentialDate = createDateFormatFunction(DATE_PORTION_MOMENT_FORMAT);

    const formik = useFormik<LiabilityInsuranceFormValues>({
        initialValues: {
            expiryDate: liabilityInsurance?.expiryDate ?? moment().format(DATE_FORMATS.ISO),
            onFile: liabilityInsurance?.onFile ?? false
        },
        onSubmit: async (values) => {
            formik.validateForm();

            if (formik.isValid) {
                const dialogProps = {
                    message: <>
                        <p>{t('common__confirm_changes_dialog_text')}</p>
                        <ul>
                        {
                            (!liabilityInsurance || values.onFile !== liabilityInsurance.onFile) &&
                            <li>{t('liability_insurance__on_file_label')}: <b>{values.onFile ? t_common('yes') : t_common('no')}</b></li>
                        }
                        {
                            (!liabilityInsurance || values.expiryDate !== liabilityInsurance.expiryDate) &&
                            <li>{t('liability_insurance__expiry_date_label')}: <b>{moment(values.expiryDate, "YYYY-MM-DD").format(localeDateFormat)}</b></li>
                        }
                        </ul>
                    </>
                }
                if (await confirm(dialogProps)) {
                    updateData({
                        variables: {
                            providerId: providerId,
                            expiryDate: values.expiryDate,
                            onFile: values.onFile
                        }
                    });
                }
            }
        }
    });

    const handleCancelChanges = async () => {
        if (hasChanges) {
            const dialogProps = {
                title: t('common__discard_changes_dialog_title'),
                message: t('common__discard_changes_dialog_text')
            }
            if (await confirm(dialogProps)) {
                formik.resetForm();
                setInEdit(false);
            }
        } else {
            formik.resetForm();
            setInEdit(false);
        }
    }

    const handleExpiryDateChange = (date) => {
        if (date) {
            formik.setFieldValue('expiryDate', date.format('YYYY-MM-DD'));
        }
    }

    const expiryDate = liabilityInsurance?.expiryDate ? moment(liabilityInsurance.expiryDate) : null;

    const hasChanges = formik.dirty 
        && (formik.values.expiryDate !== liabilityInsurance?.expiryDate
            || formik.values.onFile !== liabilityInsurance?.onFile);

    const editorLoading = !commonTranslationsReady;
    const submitDisabled = !hasChanges || updateDataMutation.loading || cachePendingUpdate;
    
    return !translationsReady
        ? <></>
        : <SectionContainer title={t('liability_insurance__section_title')} inEdit={inEdit} onEditClick={() => setInEdit(true)}>
            {
                inEdit
                ? <> 
                    {
                        editorLoading &&
                        <Spinner fillParent/>
                    }
                    {
                        (updateDataMutation.loading || cachePendingUpdate) &&
                        <Spinner fillParent backdrop/>
                    }
                    <Grid container component='form' onSubmit={formik.handleSubmit} spacing={3} style={{visibility: editorLoading ? 'hidden' : 'visible'}}>
                        <Grid item xl={12} xs={12}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={formik.values.onFile}
                                        onChange={formik.handleChange}
                                        name="onFile"
                                        color="primary"
                                    />
                                }
                                label={t('liability_insurance__on_file_label') as string}
                            />
                        </Grid>
                        {
                            formik.values.onFile &&
                            <Grid item xl={12} xs={12}>
                                <DateInput 
                                    value={moment(formik.values.expiryDate, DATE_FORMATS.ISO)}
                                    label={t('liability_insurance__expiry_date_label')}
                                    className="provider_details_input"
                                    onChange={handleExpiryDateChange}
                                />
                            </Grid>
                        }
                        <Grid item xs={12} container spacing={2}>
                            <Grid item>
                                <Button type="submit" variant="opaque" disabled={submitDisabled}>
                                    {t_common('save')}
                                </Button>
                            </Grid>
                            <Grid item>
                                <Button type="button" variant="text" onClick={handleCancelChanges}>
                                    {t_common('cancel')}
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </>
                : <p className='global__paragraph'>
                    {
                        expiryDate
                        ? expiryDate.isAfter(moment())
                            ? t('liability_insurance__not_expired_description', { replace: { date: formatCredentialDate(expiryDate) }})
                            : t('liability_insurance__expired_description', { replace: { date: formatCredentialDate(expiryDate) }})
                        : t('n_a','N/A')
                    }
                </p> 
            }
        </SectionContainer>
}
import React, { useState, FC } from 'react';
import { Container, TableContainer } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { SearchInput } from 'components/Shared/Inputs';
import { useAuth } from 'components/Auth';
import { MaterialTable, DataRequest, ISortsDefinition, Paginator } from 'components/Shared/MaterialTable';
import { ACTIVITIES_QUERY } from '../queries';
import { Spinner } from 'components/Shared';
import { useProcessActivitiesData } from '../hooks/useProcessActivitiesData';
import { useActivitiesColumnsDefinitions } from '../hooks/useActivitiesColumnsDefinitions';
import { PageMetadata } from 'components/Shared/PageMetadata';
import { Card } from 'components/Shared/Card';
import { useQueryWithCacheExpiry } from 'providers/Apollo/useQueryWithCacheExpiry';
import { FullScreenMessage } from 'components/Shared/FullScreenMessage';

interface ActivitiesTableViewProps {}

export const ActivitiesTableView: FC<ActivitiesTableViewProps> = (_) => {
    const { effectiveProviderData } = useAuth();
    const { t, ready: translationsReady } = useTranslation(['activities', 'common'], { useSuspense: false });

    const [ dataRequest, setDataRequest ] = useState<DataRequest>({
        sorts: {
            "clientName": "asc"
        },
        take: 10,
        skip: 0,
        search: ''
    });

    const handleSearchValueChange = (value: string) => {
        setDataRequest({...dataRequest, search: value, skip: 0});
    }

    const handleSortsChange = (sorts: ISortsDefinition) => {
        setDataRequest({...dataRequest, sorts: sorts});
    }

    const handlePageChange = (currentPage: number) => {
        setDataRequest({...dataRequest, skip: (currentPage - 1) * dataRequest.take});
    }

    const { data, loading } = useQueryWithCacheExpiry(ACTIVITIES_QUERY, {
        variables: {
            providerId: effectiveProviderData?.providerId ?? -1
        },
        fetchPolicy: 'cache-first',
        expiryEvents: [ 'ACCEPT_REFERRAL' ],
    });

    const [processedData, paginationDetails] = useProcessActivitiesData(data?.activities, dataRequest);
    const columnsDefinitions = useActivitiesColumnsDefinitions();

    const emptyStateMessage = <FullScreenMessage title={t('activies_table_no_rows')} />

    return (
        <React.Fragment>
            { translationsReady && 
                <PageMetadata 
                    pageDescription={t('clients_page_description')} 
                    pageTitle={t('clients_page_title')}
                /> 
            }
            <div className="table_view_wrapper">
            {
                !translationsReady
                ? <Spinner fillParent/>
                : <Container>
                    <div className="table_view_title_wrapper">
                        <h1>{t('navigation:clients', 'Clients')}</h1>
                        <SearchInput value={dataRequest.search} onChange={handleSearchValueChange}
                            label={t('search_clients_input_label')} placeholder={t('search_clients_input_placeholder')}/>
                    </div>
                    <TableContainer component={Card} elevation={1} className="table_container">
                        <MaterialTable
                            columns={columnsDefinitions}
                            onSortsChange={handleSortsChange}
                            sorts={dataRequest.sorts}
                            data={processedData}
                            dataLoading={loading}
                            emptyStateMessage={emptyStateMessage}
                        />
                    </TableContainer>
                    <Paginator paginationDetails={paginationDetails} onPageChange={handlePageChange}/>
                </Container>
            }
            </div>
        </React.Fragment>
    )
}
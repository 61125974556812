import { IFormInfo } from "components/Forms/interfaces";
import { ActivityStatus } from "constants/activityStatus";

export enum FormStatus {
    Completed = 'completed',
    Incomplete = 'incomplete',
    Warning = 'warning',
    Declined = 'declined'
}

export interface FormSummary {
    id: string,
    templateName: string,
    sessionId: number,
    preAssessmentStageStatus: FormStatus,
    postAssessmentStageStatus: FormStatus
}

export interface FormResult {
    id: string,
    result?: string
}

export enum SessionStatus {
    ACTIVE = 1,
    NO_SHOW = 2,
    COMPLETE = 3,
    VOID = 4,
    CANCELLED_LT_24H = 5
}

export enum CloseResult {
    ERROR = 'ERROR',
    CLOSED = 'CLOSED',
    VOIDED = 'VOIDED'
}

export enum CloseReason
{
    COMPLETED = 'COMPLETED',
    NO_SHOW = 'NO_SHOW',
    PROVIDED_CANCELLED = 'PROVIDED_CANCELLED',
    CANCELLED_LT_24 = 'CANCELLED_LT_24',
    CANCELLED_GT_24 = 'CANCELLED_GT_24',
    CLIENT_UNREACHABLE = 'CLIENT_UNREACHABLE',
    OTHER = 'OTHER'
}

export interface Assessment {
    templateName: string,
    mandatory: boolean
}

export interface IActivityDetails {
    client: IClient,
    providerId: number,
    service: string,
    timezoneOlsonName?: string,
    activityStatusCode: ActivityStatus,
    targetedSystem: {
        systemCode: string,
    },
    organization: {
        name: string,
        organizationId: number
    },
    sessionInfo: {
        modelId: number,
        totalHoursProvided: number,
        totalHoursRemaining: number,
        maxCapHours: number
    },
    additionalParticipants?: {
        id: string,
        firstName: string,
        lastName: string,
        fullName: string,
        dateOfBirth: string,
        createdBy: string,
        age: string,
        relationType: string
    }[],
    preAssessments?: IPreAssessment[],
    clientStatusCode: string,
    eligibility: string,
    clientMaxHours: number,
    clientAvailableHours: number,
    clientUsedHours: number,
    clientMaxHoursStartDate: string,
    clientMaxHoursEndDate: string,
    isHourlyContract: boolean,
    presentingIssue: string,
    modality: string,
    activityId: number,
    sessions: Session[],
    openDate: string,
    activityClosedTime?: string | null,
    urgencyLevel: number,
    linkedActivityId?: number,
    urgencyLevelString: string, 
    pcShowFamilyHours: boolean,
    familyTotalAllocatedHours: number,
    familyTotalRemainingHours: number,
    familyEligibilityHours?: {
        eligibilityCategoryDescription: string,
        allocatedHours: number,
        remainingHours: number,
        eligibilityCategoryId: number
    }[] | null,
    familyMembers?: {
        id: number,
        firstName: string,
        lastName: string
    }[] | null,    
    serviceFor?: string,
    indigenousLabel?: string | null,
    solutionCode: string,
    followUpDate: string,
    sessionBooking: {
        allowed: boolean,
        prohibitionReasonCode: string | null
    },
    caseDetailHours: {
        clientRemainingHours?: number,
        numberOfHoursUsed?: number,
        totalHoursAvailable?: number,
        numberOfMaxHours?: number,
        approvedHours?: number
    },
    serviceForms: {
        clientPhoneNumber: string | null,
    },
    contractAllowsAdditionalHours: boolean,
    assessmentsScores: {
        PHQ9: PHQ9_AssessmentScore[]
    },
    firstAppointmentAssessmentFormNames: Assessment[],
    isAvailableToProvider: boolean
}

export interface IFormByActivity {
    id: string,
    completed: boolean,
    templateName: string,
    stage: string,
    updatedBy: string | null,
    createdBy: string,
    sessionId: number,
    updatedOn: string | null,
    createdOn: string,
    preAssessmentStageStatus: FormStatus,
    postAssessmentStageStatus: FormStatus,
}

export interface AssessmentScore {
    date: string,
    formId: string,
    score: number,
    sessionId: number
}

export interface PHQ9_AssessmentScore extends Omit<AssessmentScore, 'score'> {
    score: number[]
}
export interface IReason {
    id: string,
    description: string,
}

export interface IDeclineAppointmentResponse {
    appointmentDeclineReasons: IReason[],
}

export interface IReferral {
    referralAccepted: boolean,
    clientCallingPreference: string,
    serviceFormNotes: string
}

export interface ISession {
    duration: number,
    sessionId: number,
    sessionStatus: string,
    startDateTime: string,
    startDateTimeUTC: string,
    sessionStatusId: number,
    providerHubAppointmentStatusId?: number,
    providerHubAppointmentVoidReasonId?: number
}

export interface Session {
    duration: number,
    sessionId: number,
    sessionStatus: string,
    startDateTimeUTC: string,
    sessionStatusId: number,
    providerHubAppointmentStatusId: number,
    clientReservationPhoneNumber?: string
}

export interface IClient {
    firstName: string,
    lastName: string,
    email: string,
    clientId: number,
    phones: {
        number: string,
        phoneTypeCode: string,
        messageType: string,
    }[],
    gender: string,
    dateOfBirth?: string,
    languagePreference: string,
    address: {
        city: string,
        state_ProvinceCode: string,
        countryName: string,
    },
}

export interface CloseActivityMutationVars {
    activityId: number,
    closureDate: Date,
    providerId: number,
}

export interface IPreAssessment {
    additionalIndividualId: number,
    activityId: number,
    clientName: string,
    status: string,
    forms: IFormInfo[],
    url: string,
}

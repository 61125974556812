import React, { FC, useState } from 'react';
import { InvoicingDataRequest, InvoiceStatus } from '../../interfaces';
import { Box, IconButton, FormControl, RadioGroup, FormControlLabel, Radio } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { Button, DateInput } from 'components/Shared/Inputs';
import { useTranslation } from 'react-i18next';
import moment, { Moment } from 'moment';
import useTimezoneUtils from 'hooks/useTimezoneUtils';
import { useQuery } from '@apollo/client';
import { INVOICE_STATUS_REF_DATA_QUERY } from '../../queries';
import { Spinner } from 'components/Shared';

interface InvoicesTableFiltersFormProps {
    dataRequest: InvoicingDataRequest,
    onClose: () => void,
    onDataRequestChange: (dataRequest: InvoicingDataRequest) => void
}

export const InvoicesTableFiltersForm: FC<InvoicesTableFiltersFormProps> = (props) => {
    const { t } = useTranslation(['invoicing', 'common'], { useSuspense: false });
    const { dataRequest, onClose, onDataRequestChange } = props;

    const [ status, setStatus ] = useState<InvoiceStatus | -1>(dataRequest.filters.status);
    
    const [ startDate, setStartDate ] = useState<Moment | undefined | null>(() => {
        if (dataRequest.filters.startDate) {
            return moment(dataRequest.filters.startDate);
        }
        const today = moment();
        today.startOf('day');
        return today;
    });
    const [ endDate, setEndDate ] = useState<Moment | undefined | null>(() => {
        if (dataRequest.filters.endDate) {
            return moment(dataRequest.filters.endDate);
        }
        const today = moment();
        today.endOf('day');
        return today;
    });

    const { localeDateFormat } = useTimezoneUtils();

    const invoiceStatusRefDataQuery = useQuery(INVOICE_STATUS_REF_DATA_QUERY);

    const handleApply = () => {
        onDataRequestChange({
            ...dataRequest,
            filters: {
                ...dataRequest.filters,
                status: status,
                startDate: startDate?.toDate() ?? undefined,
                endDate: endDate?.toDate() ?? undefined
            },
            skip: 0
        });
        onClose();
    }

    const handleStartDateChange = (date) => {
        date?.startOf('day');
        setStartDate(date);
        if (date && (!endDate || date.isAfter(endDate))) {
            setEndDate(date.clone().endOf('day'));
        }
    }

    const handleEndDateChange = (date) => {
        date?.endOf('day');
        setEndDate(date);
        if (date && (!startDate || date.isBefore(startDate))) {
            setStartDate(date.clone().startOf('day'));
        }
    }

    const statuses = invoiceStatusRefDataQuery.data?.invoiceStatuses
        ?.map(s => ({title: s.title, value: Number(s.value)}))
        ?.filter(s => s.value !== InvoiceStatus.PendingApproval && s.value !== InvoiceStatus.AutoApproval && s.value !== InvoiceStatus.Draft);

    return (
        <Box className="filter_content_form_container">
            <Box className="filter_content_header">
                <h2 id="filter_content_title">{t('filters')}</h2>
                <IconButton className="close_button" onClick={onClose}><CloseIcon /></IconButton>
            </Box>
            {
                invoiceStatusRefDataQuery.loading &&
                <Spinner fillParent/>
            }
            <div style={{visibility: invoiceStatusRefDataQuery.loading ? 'hidden' : 'visible'}}>
                <h3>{t('filter_by_invoice_status')}</h3>
                <FormControl component="fieldset">
                    <RadioGroup className="invoice_status_radio_group" name="invoice-status" value={status} onChange={(event) => setStatus(Number(event.target.value))}>
                        <FormControlLabel value={-1} className="radio_item" control={<Radio color="primary" autoFocus/>} label={t('all') as string} />
                        {
                            statuses?.map(s => (
                                <FormControlLabel value={s.value} className="radio_item" key={s.value}
                                    control={<Radio color="primary"/>} label={s.title} />
                            ))
                        }
                    </RadioGroup>
                </FormControl>
                <h3>{t('filter_by_date_submitted')}</h3>
                <DateInput
                    className="datepicker_input"
                    label={t('from')}
                    format={localeDateFormat}
                    value={startDate ?? null}
                    onChange={handleStartDateChange}
                    disableFuture
                />
                <DateInput
                    className="datepicker_input"
                    label={t('to')}
                    format={localeDateFormat}
                    value={endDate ?? null}
                    onChange={handleEndDateChange}
                    disableFuture
                />
                <Button variant="opaque" className="submit_filter_form_button" onClick={handleApply}>
                    {t('apply')}
                </Button>
            </div>
        </Box>
    )
}
import React, { FC } from 'react';
import { Pagination } from '@mui/material';
import { PaginationDetails } from './interfaces';
import { useTranslation } from 'react-i18next';

interface PaginatorProps {
    paginationDetails: PaginationDetails,
    onPageChange: (page: number) => void
}

export const Paginator: FC<PaginatorProps> = (props) => {
    const { t } = useTranslation('common');
    const { paginationDetails, onPageChange } = props;

    const handlePageChange = (_, page) => onPageChange(page);

    return (
        <div className="table_pagination_container">
            <p className="pagination_text">
                {paginationDetails.currentRangeStart} - {paginationDetails.currentRangeEnd} {t('common:of')} {paginationDetails.total ?? '??'}
            </p>
            <Pagination
                className="pagination_component"
                count={paginationDetails.pagesCount}
                page={paginationDetails.currentPage}
                onChange={handlePageChange}
            />
        </div>
    )
}
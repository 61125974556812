import React, { FC } from 'react';
import { SurveyModel } from 'survey-react';
import { SurveyJSContainer, SurveyJSContainerProps, useSurveyJSUtils } from '../../../components/SurveyJS';

interface ETOS_Props extends SurveyJSContainerProps {}

export const ETOS: FC<ETOS_Props> = (props) => {
    const { onSurveyJSInitialized, ...rest } = props;
    const { updateAriaLabels } = useSurveyJSUtils();

    const handleSurveyJSInitialized = (surveyModel: SurveyModel) => {
        onSurveyJSInitialized?.(surveyModel);

        surveyModel.current?.onAfterRenderPanel.add(() => {
            // const document = options.htmlElement?.ownerDocument;
            updateAriaLabels(document?.querySelector("div[name='pre_30_days_degree']"));
            updateAriaLabels(document?.querySelector("div[name='post_30_days_degree']")); 
        })
    }

    return <SurveyJSContainer
        onSurveyJSInitialized={handleSurveyJSInitialized}
        {...rest}
    />
}
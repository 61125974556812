
import { gql, TypedDocumentNode } from '@apollo/client';

export interface IAddClientMutationResult {
    addClient: {
        activityId: number;
        statusId: number;
    }
}

export interface IReferralData {
    referralList: {
        status: string;
        acceptedProviderId?: number;
        expiryDateUtc?: string
    }
}

export const ADD_CLIENT_MUTATION: TypedDocumentNode<IAddClientMutationResult, { clientToken: string }> = gql`
    mutation ADD_CLIENT_MUTATION($clientToken: String!) {
        addClient(clientToken: $clientToken) {
            status
            activityId
        }
    }
`

export const GET_REFERRAL_DATA: TypedDocumentNode<IReferralData, { clientToken: string }> = gql`
    query GET_REFERRAL_DATA($clientToken: String!) {
        referralList(token: $clientToken) {
            status
            acceptedProviderId
            expiryDateUtc
        }
    }
`
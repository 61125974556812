
import { gql, TypedDocumentNode } from "@apollo/client";


export interface ITerminateTodoVars {
    workflowId: string;
}

export const TERMINATE_TODO_MUTATION: TypedDocumentNode<boolean, ITerminateTodoVars> = gql`
    mutation TERMINATE_TODO_MUTATION($workflowId: String!) {
        terminateToDo(workflowId: $workflowId)
    }
`


import { CASE_RATE } from 'constants/sessionRateTypes';
import { toCurrencyString } from 'utils';


export const calculateBillableRate = (billable: {
    rate?: number
    currencyCode?: string,
    rateTypeCode?: string
}, t, i18n) => {
    const isCaseRate = billable.rateTypeCode === CASE_RATE
    
    if (billable.rateTypeCode === undefined) {
        return '---'
    }

    if (isCaseRate) {
        if (billable.rate && billable.rate >= 0) {
            return`${toCurrencyString(billable.rate, i18n.language, billable.currencyCode) ?? '---'}`+ t('per_case')
        }
        return '---'
    }
    return `${toCurrencyString(billable.rate, i18n.language, billable.currencyCode) ?? '---'}`+ t('per_hour')
}


export const SESSION_BILLABLES_WSP_TASK_CODES = ['FF', 'T', 'V', 'LC', 'NS']

export const isWspSessionBillable = (billable: { wspTaskCode?: string }) => (
    !Boolean(billable.wspTaskCode) || SESSION_BILLABLES_WSP_TASK_CODES.includes(billable.wspTaskCode as any)
)


export const isWspTaskBillable = (billable: { wspTaskCode?: string }) => (
    billable.wspTaskCode && !SESSION_BILLABLES_WSP_TASK_CODES.includes(billable.wspTaskCode)
)
import React from 'react';
import { useTranslation } from 'react-i18next';

import { Spinner } from 'components/Shared';
import { PageMetadata } from 'components/Shared/PageMetadata';

import './AboutUs.scss';

interface Props {
    
}

export const AboutUs = (_: Props) => {
    const { t, ready } = useTranslation('about_us', { useSuspense: false });

    return ( 
        <React.Fragment>
            { ready && 
                <PageMetadata 
                    pageDescription={t('about_us_page_description')} 
                    pageTitle={t('about_us_page_title')}
                /> 
            }
            <div className="global_about_us_wrapper_div global__fixed_background_image">
                {
                    !ready
                    ? <Spinner />
                    : <>
                        <div className="about_us_container transluscent_bg">
                            <h2 className="global__header2 about_us_page_title">{t('about_provider_hub', 'About The Provider Hub')}</h2>
                            <div className="description_div" dangerouslySetInnerHTML={{__html: t('about_us_content')}}>
                            </div>
                            <div className="action_link_div">
                                <a className="link global__link" href={t('lifeworks_url')} target="_blank" rel="noopener noreferrer">{t('telus_health', 'Telus Health')}</a>
                            </div>
                        </div>
                    </>
                }
            </div>
        </React.Fragment>
    )
}
import React, { FC } from 'react';
import { IconButton, Modal } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Button } from 'components/Shared/Inputs';
import CloseIcon from '@mui/icons-material/Close';
import { Spinner } from 'components/Shared';

interface NavigationInstructionsDialogProps {
    open: boolean,
    onClose: () => void
}

export const NavigationInstructionsDialog: FC<NavigationInstructionsDialogProps> = (props) => {
    const { open, onClose } = props;
    const { t, ready } = useTranslation(['calendaring', 'common'], { useSuspense: false });

    return (
        <Modal open={open} onClose={onClose} className='dialog_wrapper' role="dialog" aria-modal
            aria-labelledby="calendar_navigation_instructions_dialog_title" aria-describedby="calendar_navigation_instructions_dialog_content">
            <div className='dialog_surface'>
                <div className="dialog_title_wrapper">
                    <h2 id="calendar_navigation_instructions_dialog_title">
                        {ready && t('calendar_navigation_instructions_dialog_title')}
                    </h2>
                    <IconButton aria-label={t('common:close')} onClick={onClose}>
                        <CloseIcon />
                    </IconButton>
                </div>
                <div className="dialog_content_wrapper" id="calendar_navigation_instructions_dialog_content">
                {
                    ready
                    ? <div dangerouslySetInnerHTML={{__html: t('calendar_navigation_instructions_dialog_content')}}/>
                    : <Spinner/>
                }
                </div>
                <div className="dialog_actions_wrapper">
                    <Button variant="opaque" onClick={onClose}>
                        {t('common:close')}
                    </Button>
                </div>
            </div>
        </Modal>
    )
}
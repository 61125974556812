import React, { FC, useEffect, useMemo, useState } from 'react';
import { FilterOptions, DatePreference, TimePreference } from '../../interfaces';
import { NetworkStatus, useLazyQuery, useQuery } from '@apollo/client';
import { PROVIDER_SEARCH_SCHEDULES_QUERY, SCHEDULE_SEARCH_DATA_QUERY } from '../../queries';
import { IconButton, Modal, Tab, Tabs } from '@mui/material';
import { Button } from 'components/Shared/Inputs';
import CloseIcon from '@mui/icons-material/Close';
import { ScheduleSearchFilters } from '../ScheduleSearchFilters';
import { ScheduleSlotPicker } from '../ScheduleSlotPicker';
import { useTranslation } from 'react-i18next';
import { Spinner } from 'components/Shared';
import moment from 'moment';
import './ScheduleSearchDialog.scss';
import { ScheduleSlot } from 'components/Calendaring/AppointmentModals/interfaces';
import { parseIScheduleToScheduleSlot } from '../../utils';


interface ScheduleSearchDialogProps {
    activityId: number,
    onContinue: (scheduleSlot: ScheduleSlot | null) => void,
    onCancel: () => void,
    show: boolean
}

export const ScheduleSearchDialog: FC<ScheduleSearchDialogProps> = (props) => {
    const { activityId, onCancel, onContinue, show } = props;

    const { t } = useTranslation();

    const [searchSchedules, searchSchedulesQuery] = useLazyQuery(PROVIDER_SEARCH_SCHEDULES_QUERY, {
        errorPolicy: 'all', 
        fetchPolicy: 'no-cache'
    });

    const activityDataQuery = useQuery(SCHEDULE_SEARCH_DATA_QUERY, {
        variables: { activityId: activityId }
    });

    const activity = activityDataQuery.data?.activity;
    const provider = activity?.provider;
    const clientTimeZone = activity?.timezoneOlsonName ?? '';
    const providerTimeZone = provider?.timeZone;

    const [selectedSlot, setSelectedSlot] = useState<ScheduleSlot | null>(null);
    const schedules = useMemo(() =>
        searchSchedulesQuery.data?.providerSearchSchedules?.map(parseIScheduleToScheduleSlot)
    , [searchSchedulesQuery.data?.providerSearchSchedules]);

    const [shownPanel, setShownPanel] = useState(0);
    const [selectedTimeZone] = useState<'client' | 'provider'>('client');

    const displayedTimeZone = selectedTimeZone === 'client'
        ? clientTimeZone
        : providerTimeZone;

    const [filterOptions, setFilterOptions] = useState<FilterOptions>({
        datePreference: DatePreference.any,
        timePreference: TimePreference.any,
        officeId: null,
        startDate: moment().format('YYYYMMDD'),
        startTime: moment().startOf('hour').format('hh:mm a')
    });


    useEffect(() => {
        // if (!showAvailableHoursWarning && clientHasExistingSessions) {
        if (provider) {
            searchSchedules({
                variables: {
                    providerId: provider.id,
                    secondarySearchSchedules: '',
                    sessionId: activity?.sessions[0]?.sessionId,
                    appDateRelCode: filterOptions.datePreference,
                    appTimeRelCode: filterOptions.timePreference,
                    appDate: filterOptions.startDate,
                    appTime: filterOptions.startTime,
                    officeId: filterOptions.officeId
                }
            });
        }
    }, [filterOptions, activity, provider]);

    const handleFiltersChange = (filters: FilterOptions) => {
        setFilterOptions(filters);
        setShownPanel(0);
    }

    const handleConfirm = () => {
        onContinue(selectedSlot);
    }

    return (
        <Modal open={show} className="schedule_filters_dialog dialog_wrapper">
            <div className='dialog_surface'>
                <div className="dialog_title_wrapper">
                    <h2 className="confirm_dialog_title">Search schedules</h2>
                    <IconButton className="confirm_dialog_close_button" onClick={onCancel}><CloseIcon /></IconButton>
                </div>
                <div className="dialog_content_wrapper">
                    {
                        searchSchedulesQuery.networkStatus === NetworkStatus.refetch &&
                        <Spinner fillParent backdrop/>
                    }
                    {
                        activityDataQuery.loading || searchSchedulesQuery.loading
                            ? <Spinner fillParent/>
                            : <>
                                <div className="dialog_tabbar">
                                    <Tabs value={shownPanel} indicatorColor="primary" onChange={(_, value) => setShownPanel(value)} variant="fullWidth">
                                        <Tab label="Schedules"/>
                                        <Tab label="Filters"/>
                                    </Tabs>
                                </div>
                                {
                                    shownPanel === 0 &&
                                    <div role="tabpanel" className="tab_wrapper">
                                        <div className="tab_content">
                                            <ScheduleSlotPicker 
                                                filters={filterOptions}
                                                displayedTimeZone={displayedTimeZone ?? ''}
                                                scheduleSlots={schedules}
                                                selectedSlot={selectedSlot}
                                                onScheduleSlotSelected={setSelectedSlot}
                                            />
                                        </div>
                                        <div className="tab_actions">
                                            <Button variant='text' onClick={onCancel} autoFocus>{t('cancel')}</Button>
                                            <Button variant='opaque' onClick={handleConfirm} disabled={!selectedSlot}>{t('confirm')}</Button>
                                        </div>
                                    </div>
                                }
                                {
                                    shownPanel === 1 &&
                                    <div role="tabpanel" className="tab_wrapper">
                                        <div className="tab_content">
                                            <ScheduleSearchFilters
                                                filters={filterOptions}
                                                offices={provider?.contactInfo?.addresses}
                                                onFiltersChange={handleFiltersChange}
                                            />
                                        </div>
                                    </div>
                                }
                            </>
                    }
                </div>
            </div>
        </Modal>
    )
}

import { gql, TypedDocumentNode } from '@apollo/client';
import { HighLevelActivityData, HighLevelReferralData } from './interfaces';

export const GET_CASE_DATA: TypedDocumentNode<GetCaseDataData, GetCaseDataVars> = gql`
    query GetHighLevelActivityData($activityId: Long!) {
        activity(activityId: $activityId) {
            activityId
            client {
                firstName
                lastName
            }
            organization {
                name
            }
            openDate
            modality
            eligibility
            presentingIssue
            timeZoneStandardName
            providerFirstAvailableAppointmentDate
            activityStatusCode
            serviceForms {
                clientPhoneNumber
            }
            serviceFor
        }
        referral(activityId: $activityId) {
            sLADate
            clientEmail
            referralExpirationDate
            schedulingPreferenceString
            clientCallingPreference
            serviceFormNotes
        }        
    }

`

export interface GetCaseDataVars {
    activityId: number
}

export interface GetCaseDataData {
    activity: HighLevelActivityData,
    referral: HighLevelReferralData
}

export const DECLINE_REFERRAL_MUTATION: TypedDocumentNode<DeclineMutationData, DeclineMutationVars> = gql`
    mutation DECLINE_REFERRAL($activityId: Long!, $providerId: Long!, $reasonCode: Short) {
        declineReferral(activityId: $activityId, providerId: $providerId, reasonCode: $reasonCode)
    }
`

export interface DeclineMutationData {}
export interface DeclineMutationVars {
    activityId: number,
    providerId: number,
    reasonCode?: number
}

export const SAVE_FIRST_AVAILABLE_APPOINTEMENT_DATE_MUTATION: TypedDocumentNode<SaveFirstAvailableAppointmentMutationData, SaveFirstAvailableAppointmentMutationVars> = gql`
    mutation SAVE_FIRST_AVAILABLE_APPOINTEMENT_DATE($activityId: Long!, $providerId: Long!, $date: String!) {
        saveFirstAvailableAppointmentDate(activityId: $activityId, providerId: $providerId, date: $date)
    }
`

export interface SaveFirstAvailableAppointmentMutationData { }
export interface SaveFirstAvailableAppointmentMutationVars {
    activityId: number,
    providerId: number,
    date: String
}

export const ACCEPT_REFERRAL_MUTATION: TypedDocumentNode<AcceptReferralMutationData, AcceptReferralMutationVars> = gql`
    mutation ACCEPT_REFERRAL($activityId: Long!, $providerId: Long!) {
        acceptReferral(activityId: $activityId, providerId: $providerId)
    }
`

export interface AcceptReferralMutationData { }
export interface AcceptReferralMutationVars {
    activityId: number,
    providerId: number
}


export const TERMINATE_TODO_MUTATION: TypedDocumentNode<boolean, TerminateTodoMutationVars> = gql`
    mutation TERMINATE_TODO_MUTATION($workflowId: String!) {
        terminateToDo(workflowId: $workflowId)
    }
`
export interface TerminateTodoMutationVars {
    workflowId: string,
}


import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import './FAQ.scss';
import { Modal, Grow, Paper, Container } from '@mui/material';
import { TechSupport } from 'components/Shared/TechSupport/TechSupport';
import { useAuth } from 'components/Auth';
import { PageMetadata } from 'components/Shared/PageMetadata';
import { useRegionConfig } from 'providers/RegionConfig';

interface FAQProps {
    
}

export const FAQ = (_: FAQProps) => {
    const { t, i18n, ready } = useTranslation('faq', { useSuspense: false });
    const { techSupportUrls } = useRegionConfig();
    const currentLanguage = localStorage.getItem('i18nextLng');

    const { authenticated } = useAuth();

    const [ showTechSupport, setShowTechSupport ] = useState<boolean>(false);

    const redirectToTechSupport = () => {
        const redirectUrl = currentLanguage === "fr-CA" ? techSupportUrls.fr : techSupportUrls.en;
        if (redirectUrl && redirectUrl.url) {

            window.open(redirectUrl.url);
        }
        else {
            setShowTechSupport(true);
        }
    }

    let content:any|undefined = undefined;

    if (ready) {
        for (let i = 0; i < i18n.languages.length && !content ; i++) {
            const faq = i18n.store.data?.[i18n.languages[i]]?.faq;
            if (faq) {
                content = faq;
            }
        }
    }
    
    return (
        <React.Fragment>
            { ready && 
                <PageMetadata 
                    pageDescription={t('faq_page_description')} 
                    pageTitle={t('faq_page_title')}
                /> 
            }
            <div className="global_faq_questions_wrapper global__fixed_background_image">
                <Container className="faq_quetions_content_container transluscent_bg" maxWidth="md">
                    <h1 className="faq_view_title global__header1">{t('faq_title')}</h1>
                    {
                        content?.["faq_categories"]?.length &&
                        <div className="faq_questions_container">
                            {
                                content["faq_categories"].filter(category => category.type === (authenticated ? 'postLogin' : 'preLogin')).map((element, index) => (
                                    <div key={index}>
                                        <h2 className="category_title global__header2">{element.category_title}</h2>
                                        {
                                            element.questions.map((item, index) => (
                                            <div className="question_answer_wrapper" key={index}>
                                                <h3 className="global__header2 question">{item.question}</h3>
                                                <div className="answer" dangerouslySetInnerHTML={{__html: `${item.answer}`}}/>
                                            </div>
                                            ))
                                        }
                                    </div>
                                ))
                            }
                        </div>
                    }
                    <div className="contact_tech_support_content_wrapper">
                        <h3 className="title">{t('still_have_questions')}</h3>
                        <p className="description">{t('contact_tech_support_description')}</p>
                        <button className="contact_tech_support_button global__button" onClick={() => redirectToTechSupport()}>{t('contact_tech_support_button')}</button>
                    </div>
                </Container>
                <Modal open={showTechSupport} onClose={() => setShowTechSupport(false)} className="tech_support_modal_wrapper" disablePortal disableScrollLock keepMounted>
                    <Grow in={showTechSupport} style={{ transformOrigin: '100% 0 0' }}>
                        <Paper elevation={3} className="tech_support_modal_content">
                            <TechSupport onClose={() => setShowTechSupport(false)}/>
                        </Paper>
                    </Grow>
                </Modal>
            </div>
        </React.Fragment>
    )
}
import React, { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Grid } from '@mui/material';
import { ContractedService } from '../../../interfaces';
import { useTimezoneUtils } from 'hooks/useTimezoneUtils';
import { SectionContainer } from '../../Other/SectionContainer';

interface ContractedServicesProps {
    contractedServices?: ContractedService[]
}

export const ContractedServices: FC<ContractedServicesProps> = (props) => {
    const { contractedServices } = props;
    const { t, ready: translationsReady } = useTranslation('providerprofile', { useSuspense: false });
    
    const { createDateFormatFunction, DATE_PORTION_MOMENT_FORMAT } = useTimezoneUtils();

    const formatContractedServicesDate = createDateFormatFunction(DATE_PORTION_MOMENT_FORMAT);
    
    const contractedServicesGroups = useMemo(() =>
        contractedServices?.reduce<Record<string, ContractedService[]>>((groups, service) => ({
            ...groups,
            [service.service]: [
                ...(groups[service.service] || []),
                service
            ]
    }), {}) || {}, [contractedServices]);

    return !translationsReady
        ? <></>
        : <SectionContainer title={t('contracted_services__section_title')}>
            {
                contractedServices?.length
                    ? Object.keys(contractedServicesGroups).map((serviceType, index) => (
                        <div className='contracted_services_row' key={index}>
                            <h3>{serviceType}</h3>
                            {
                                contractedServicesGroups[serviceType].map((service, index) => (
                                    <Grid container className='contact_info_container' key={index}>
                                        <Grid item xs>
                                            <p className='global__paragraph'>{service.modality}</p>
                                            <p className='global__paragraph global__paragraph--light'>{t('contracted_services__sub_modality_label')}</p>
                                        </Grid>
                                        <Grid item xs>
                                            <p className='global__paragraph'>
                                                { service.startDate ? formatContractedServicesDate(service.startDate) : t('n_a','N/A')}
                                            </p>
                                            <p className='global__paragraph global__paragraph--light'>{t('contracted_services__contract_label')}</p>
                                        </Grid>
                                    </Grid>
                                    )
                                )
                            }
                        </div>
                    ))
                    : <p>{t('n_a','N/A')}</p>
            }
        </SectionContainer>
}
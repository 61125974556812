import { useTimezoneUtils } from 'hooks/useTimezoneUtils';
import { Moment } from 'moment';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

interface DayHeaderProps {
    date: Moment,
    reservationsCount: number
}

export const DayHeader: FC<DayHeaderProps> = (props) => {
    const { date, reservationsCount } = props;
    const { t, ready } = useTranslation('calendaring', { useSuspense: false });
    
    const { formatDate } = useTimezoneUtils();

    return (
        <>{
            ready &&
            <div className="schedule_day_header">
                <h3>{formatDate(date, 'dddd, LL')}</h3>
                <h4>{t('appointments_count', {count: reservationsCount})}</h4>
            </div>
        }</>
    )
}
import React, { FC, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { FormViewContainer } from '../../components/FormViewContainer';
import { FormPresenter } from '../../components/FormPresenter';
import { getFirstSession } from 'components/CaseManagement/utils/sessionUtils';
import useTimezoneUtils from 'hooks/useTimezoneUtils';
import { useMutation, useQuery } from '@apollo/client';
import { CREATE_SESSION_NOTE_FORM_MUTATION, SESSION_NOTE_VIEW_QUERY, UPDATE_FORM } from '../../queries';
import { PROVIDER_HUB } from 'constants/targetedSystem';
import { Spinner } from 'components/Shared';
import { FullScreenMessage } from 'components/Shared/FullScreenMessage';
import { useHistoryState } from 'hooks/useHistoryState';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';

interface SessionNoteViewProps { }

export const SessionNoteView: FC<SessionNoteViewProps> = () => {
    const { activityId: activityIdString, sessionId: sessionIdString } = useParams<{activityId: string, sessionId: string}>();
    const { formatDate } = useTimezoneUtils();
    const navigate = useNavigate();
    const { enqueueSnackbar } = useSnackbar();
    
    const [historyState] = useHistoryState<{ entryRoute?: string }>();
    const { t } = useTranslation('assessments', { useSuspense: false });

    const activityId = parseInt(activityIdString || '');
    const sessionId = parseInt(sessionIdString || '');

    const [isDraft, setIsDraft] = useState(false);

    const activityDataQuery = useQuery(SESSION_NOTE_VIEW_QUERY, {
        variables: {
            activityId: activityId,
            sessionId: sessionId
        },
        errorPolicy: 'all',
        fetchPolicy: 'no-cache'
    });

    const [createForm, createFormMutation] = useMutation(CREATE_SESSION_NOTE_FORM_MUTATION);
    const [updateForm, updateFormMutation] = useMutation(UPDATE_FORM, {
        onCompleted: () => {
            isDraft 
                ? enqueueSnackbar(t('note_saved_as_draft'), { variant: 'success' })
                : navigate(historyState?.entryRoute ? historyState?.entryRoute : `/clients/${activityId}`);
        }
    });

    const activity = activityDataQuery.data?.activity;
    const form = activity?.sessionNote || createFormMutation.data?.form;

    useEffect(() => {
        if (activity && !form) {
            createForm({
                variables: {
                    activityId: activityId,
                    sessionId: sessionId
                }
            })
        }
    }, [activity, form]);
    
    const isActivityInactive = activity?.activityStatusCode !== 1;
    const readOnly = (updateFormMutation.called && !updateFormMutation.error && !isDraft)
        || isActivityInactive
        || activity?.targetedSystem?.systemCode !== PROVIDER_HUB;
    
    const isFirstSession = getFirstSession(activity?.sessions)?.sessionId === sessionId;
    const tags = isFirstSession ? ["first_note"] : [];
    const currentSession = activity?.sessions?.find(session => session.sessionId === sessionId);

    // convert the UTC start date string to a Date object representation
    const startDateObject: Date = currentSession?.startDateTimeUTC ? new Date(currentSession?.startDateTimeUTC) : new Date()

    // Get the ISO String date in the Provider's timezone
    const localTimeISODate = formatDate(startDateObject, 'YYYY-MM-DD[T]HH:mm');

    const defaultValues = {
        'apt_datetime': localTimeISODate,
        '_session_id': sessionId,
        '_activity_id': activityId
    }

    const clientName = `${activity?.client.firstName} ${activity?.client.lastName}`;
    let timeout;
    // we want to clear timeout when component unmounts
    useEffect(() => {
        return () => {
            if (timeout) {
                clearTimeout(timeout);
                timeout = null;
            }
        }
    }, []);

    const saveDraftOrCompleteForm = (result: string, draft: boolean) => {
        // we want to execute on next tick to allow state updates to be commited
        timeout = setTimeout(() => form && updateForm({
            variables: {
                data: result,
                formId: form?.id,
                isCompleted: !draft || form.completed
            }
        }), 1);
    }

    const handleFormSubmit = (result: string) => {
        setIsDraft(false);
        saveDraftOrCompleteForm(result, false);
    };
    
    const handleSaveDraft = (result: string) => {
        setIsDraft(true);
        saveDraftOrCompleteForm(result, true);
    };

    return ( 
        <FormViewContainer activityId={activityId} clientName={clientName}>
            {
                updateFormMutation.loading &&
                <Spinner fillParent backdrop/>
            }
            {
                (activityDataQuery.loading || createFormMutation.loading)
                    ? <Spinner fillParent/>
                    : form
                        ? <FormPresenter
                            activityId={activityId}
                            sessionId={sessionId}
                            defaultValues={defaultValues}
                            form={form}
                            readOnly={readOnly}
                            tags={tags}
                            onSubmit={handleFormSubmit}
                            onSaveDraft={handleSaveDraft}
                        />
                        : <FullScreenMessage title={t('assessment_not_found_message')} />
            }
        </FormViewContainer> 
    )
}

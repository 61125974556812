import React, { FC, PropsWithChildren } from 'react';
import { Container, Skeleton } from '@mui/material';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import './FormViewContainer.scss';

interface FormViewContainerProps extends PropsWithChildren {
    activityId: number,
    clientName: string,
    title?: string
}

export const FormViewContainer: FC<FormViewContainerProps> = (props) => {
    const { activityId, clientName, title, children } = props;
    const { t } = useTranslation('caseManagement', { useSuspense: false });

    return (
        <div className="assessment_view_wrapper">
            <Container maxWidth="lg" className="assesment_view_header">
                <div>
                    <span>{t('client')}: </span>
                    {
                        clientName && activityId
                            ? <Link className="client_name_link" to={{ pathname: `/clients/${activityId}` }}>
                                {clientName}
                            </Link>
                            : <Skeleton variant="text" width={125} className="client_name_placeholder"/>
                    }
                </div>
                {
                    title &&
                    <h1 className="assessment_title">{title}</h1>
                }
            </Container>
            { children }
        </div>
    )
}
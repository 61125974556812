import { useQuery } from "@apollo/client";
import { IStatusList } from "./interfaces";
import { GT24_APPOINTMENT_STATUS_LIST_QUERY, LT24_APPOINTMENT_STATUS_LIST_QUERY, POST_APPOINTMENT_STATUS_LIST_QUERY } from "./queries";
import moment from 'moment';

export const usePostSessionStatusList = (dontSkip: boolean): [IStatusList[] | undefined, boolean] => {
    const query = useQuery(POST_APPOINTMENT_STATUS_LIST_QUERY, {
        skip: !dontSkip,
    });
    return [query?.data?.postApptSessionStatuses, query.loading];
} 

export const useLt24SessionStatusList = (dontSkip: boolean): [IStatusList[] | undefined, boolean] => {
    const query = useQuery(LT24_APPOINTMENT_STATUS_LIST_QUERY, {
        skip: !dontSkip,
    });
    return [query?.data?.lt24HoursSessionStatuses, query.loading];
} 

export const useGt24SessionStatusList = (dontSkip: boolean): [IStatusList[] | undefined, boolean] => {
    const query = useQuery(GT24_APPOINTMENT_STATUS_LIST_QUERY, {
        skip: !dontSkip,
    });
    return [query?.data?.gt24HoursSessionStatuses, query.loading];
}


export const useSessionStatusList = (appointmentDate: Date | string): [IStatusList[] | undefined, boolean] => {
    const currentMoment = moment()
    const appointmentMoment = moment(appointmentDate)
    const appointmentDateMinus24Hours = appointmentMoment.clone().subtract(24, 'hours')

    const isLessThan24Hours = currentMoment.isAfter(appointmentDateMinus24Hours) && currentMoment.isBefore(appointmentMoment)
    const isGreaterThan24Hours = currentMoment.isBefore(appointmentDateMinus24Hours)
    const isCurrentMomentAfterSessionDate = currentMoment.isAfter(appointmentMoment)

    const [postSessionData, postSessionLoading] = usePostSessionStatusList(isCurrentMomentAfterSessionDate)
    const [lt24SessionData, lt24SessionLoading] = useLt24SessionStatusList(isLessThan24Hours)
    const [gt24SessionData, gt24SessionLoading] = useGt24SessionStatusList(isGreaterThan24Hours)

    return [
        postSessionData || lt24SessionData || gt24SessionData,
        postSessionLoading || lt24SessionLoading || gt24SessionLoading
    ]
}

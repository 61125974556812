import React, { FC, useState, useEffect, useMemo } from 'react';
import { useQuery, useLazyQuery } from '@apollo/client';
import moment from 'moment';
import CloseIcon from '@mui/icons-material/Close';
import { IconButton, Tooltip } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useMutation } from '@apollo/client';
import { useSnackbar } from 'notistack';

import { useAuth } from 'components/Auth';
import { Spinner, PreviousArrowButton } from 'components/Shared';
import { ActivityOverview } from '../../ActivityOverview'
import { SAVE_APPT_DATE_MUTATION } from 'components/CaseManagement/ScheduleSession/queries';
import { Step2ApptContent } from './Step2AppContent';
import { ScheduleSlot } from 'components/Calendaring/AppointmentModals/interfaces';
import { PROVIDER_SEARCH_SCHEDULES_QUERY, CALENDAR_BOOKING_ACTIVITY_QUERY, PROVIDER_INFO_QUERY } from 'components/Calendaring/AppointmentModals/queries';
import { useFeatureManager } from 'hooks/useFeatureManager';
import { SchedulesFilterDialog } from './SchedulesFilterDialog';
import { DatePreference, FilterOptions, TimePreference } from './interfaces';
import { parseIScheduleToScheduleSlot } from '../../utils';

import './style.scss';

export interface IBookApptStep2Staff { 
    activityId: number;
    startDate?: Date;
    entryRoute?: string
} 

export const BookApptStep2Staff: FC<IBookApptStep2Staff> = (props) => {
    const { activityId, startDate } = props;
    const entryRoute = props?.entryRoute ?? '/';

    const { effectiveProviderData } = useAuth();
    const { enqueueSnackbar } = useSnackbar();
    const { t } = useTranslation('calendaring', { useSuspense: false });

    const [filtersModalAnchorEl, setFiltersModalAnchorEl] = useState<any>(null);

    const [selectedScheduleSlot, setSelectedScheduledSlot] = useState<ScheduleSlot | null>(null);

    const { data: detailsQuery } = useQuery(PROVIDER_INFO_QUERY, {
        variables: { providerId: effectiveProviderData!.providerId },
        errorPolicy: 'all'
    });

    const goBackToWhereWeCameFrom = () => {
        navigate(entryRoute)
    }

    const navigate = useNavigate()

    const { data: activityData, loading: activityLoading } = useQuery(CALENDAR_BOOKING_ACTIVITY_QUERY, {
        variables: { activityId }
    });

    const activityDetails = activityData?.activity;
    const clientTimeZone = activityDetails?.timezoneOlsonName || '';

    const createFilterOptions = (): FilterOptions => ({
        datePreference: startDate ? DatePreference.on : DatePreference.any,
        timePreference: startDate ? TimePreference.nea : TimePreference.any,
        officeId: null,
        startDate: (startDate ? moment(startDate) : moment()).format('YYYY-MM-DD'),
        startTime: (startDate ? moment(startDate).startOf('hour') : moment()).format('HH:mm')
    })

    const [filterOptions, setFilterOptions] = useState<FilterOptions>(createFilterOptions());

    useEffect(() => {
        setFilterOptions(createFilterOptions());
    }, [clientTimeZone, startDate])

    const { isHourlyWorkflowFeatureEnabled } = useFeatureManager();

    
    const [getProviderScheduleData, { loading: providerSchedulesLoading, data: providerSchedulesResponse }] = useLazyQuery(PROVIDER_SEARCH_SCHEDULES_QUERY, {
        errorPolicy: 'all', 
        fetchPolicy: 'no-cache'
    });
    
    const allSchedules = providerSchedulesResponse?.providerSearchSchedules;

    // const getProviderScheduleData = (_) => {};
    // const providerSchedulesLoading = false;
    // const allSchedules = mock.data.providerSearchSchedules;

    const sortedScheduleSlots = useMemo(() => {
        if (clientTimeZone) {
            return allSchedules
                ?.map((schedule) => parseIScheduleToScheduleSlot(schedule, effectiveProviderData?.timeZone || undefined))
                ?.sort((a, b) => a.startDate.unix() - b.startDate.unix())
        }
        return undefined;
    }, [allSchedules, clientTimeZone]);

    const showAvailableHoursWarning = isHourlyWorkflowFeatureEnabled() && !activityData?.activity?.sessionBooking.allowed;

    const clientHasExistingSessions = Boolean(activityDetails?.sessions?.length)

    useEffect(() => {
        if (activityDetails && !showAvailableHoursWarning && clientHasExistingSessions) {
            getProviderScheduleData({
                variables: {
                    providerId: effectiveProviderData?.providerId || 0,
                    secondarySearchSchedules: '',
                    sessionId: activityDetails?.sessions[0]?.sessionId,
                    appDateRelCode: filterOptions.datePreference,
                    appTimeRelCode: filterOptions.timePreference,
                    appDate: moment(filterOptions.startDate, "YYYY-MM-DD").format('YYYYMMDD'),
                    appTime: moment(filterOptions.startTime, "HH:mm").format('hh:mm a'),
                    officeId: filterOptions.officeId
                }
            })
        }
    }, [filterOptions, activityDetails, showAvailableHoursWarning])

    useEffect(() => {
        const targetSlot = sortedScheduleSlots?.find(slot => slot.startDate.toDate().toISOString() === startDate?.toISOString());
        if (targetSlot) {
            setSelectedScheduledSlot(targetSlot);
        }
    }, [startDate, sortedScheduleSlots, clientTimeZone])
    

    const activity = activityData?.activity

    const [saveFirstAppointment, saveFirstAppointmentMuation] = useMutation(SAVE_APPT_DATE_MUTATION, {
        onCompleted: () => {
            enqueueSnackbar(t('appointment_booked_successfully'), {variant: 'default'});
            navigate('/calendar')
        },
        onError: (_) => {
            navigate('/calendar');
        }
    });

    const onSchedule = () => {
        if (selectedScheduleSlot) {
            // TODO: Ideally the providerId should be validated through the JWT token by 
            // the GraphQL query. It should not be relied on simply by the UI. 
            saveFirstAppointment({variables: {
                activityId: activityId, 
                providerId: effectiveProviderData?.providerId || 0, 
                duration: selectedScheduleSlot.duration,
                scheduleId: selectedScheduleSlot.scheduleId,
                date: selectedScheduleSlot.startDate.toISOString(true),
                isFirstAppointment: false
            }})
        }
    }

    const onCancel = () => {
        navigate(entryRoute)
    }

    const onClickPreviousButton = () => {
        navigate(-1);
    }

    const isScheduleButtonDisabled = () => {
        return showAvailableHoursWarning
    }

    const renderSelectedScheduleInfo = () => {
        if (selectedScheduleSlot) {
            // NOTE: We're using the clientDateTime we got from the backend straight without any timezone or DST conversions
            // Ideally, we'd rely on the UTC date fetched from the backend, but UTC date handling is still TODO on the 
            // backend.

            
            const startDate = selectedScheduleSlot.startDate.clone().tz(effectiveProviderData?.timeZone ?? '');
            const diffTimeZone = clientTimeZone !== effectiveProviderData?.timeZone;
            const providerTZ = startDate.clone().tz(effectiveProviderData?.timeZone || '').format('z');
            const clientTZ = startDate.clone().tz(clientTimeZone || '').format('z');
            return (
                <div className="book-appt">
                    <div className="text-confirmation">
                        {t('you_are_booking_an_appointment_with')} <strong>{activityDetails?.client.firstName + ' ' + activityDetails?.client.lastName}</strong> {t('on')}
                        <strong> {startDate.format('dddd MMMM D ')}</strong><strong>{t('at')} {startDate.format('LT')} 
                        {diffTimeZone ? ` ${t('your_time')} (${providerTZ}) ${t('and')} ${startDate.clone().tz(clientTimeZone).format('LT')} ${t('clients_time')} (${clientTZ})`: ''}
                        {selectedScheduleSlot.isBackToBack ? t('back_to_back') : null}</strong>

                    </div>
                    <button
                        className="next_button global__button"
                        onClick={() => onSchedule && onSchedule()}
                        disabled={isScheduleButtonDisabled()}
                    >
                        {t('schedule')}
                    </button>
                    
                </div>
            )
        }
    }

    const exitFlowText = t('session_booking_exit_flow_text');

    const handleFiltersChange = React.useCallback((options: FilterOptions) => {
        setFilterOptions(options);
        setFiltersModalAnchorEl(null);
    }, []);

    const handleShowFiltersModalClick = (event) => {
        setFiltersModalAnchorEl(event.target);
    }

    const loading = activityLoading || saveFirstAppointmentMuation.loading;

    return loading
        ? <Spinner fillParent />
        : (
            <div className="calendar_booking_staff">
                <Tooltip title={exitFlowText}>
                    <IconButton className="close-booking-icon" onClick={goBackToWhereWeCameFrom}>
                        <CloseIcon />
                    </IconButton>
                </Tooltip>
                <div className="calendar_booking_staff_wrapper">
                    <div className="calendar_booking_staff_border_wrapped">
                        <ActivityOverview activity={activity} onSchedule={onSchedule} startDate={startDate} onCancel={onCancel} />
                        <Step2ApptContent
                            clientHasNoMinimumHours={showAvailableHoursWarning}
                            clientHasNoExistingSessions={clientHasExistingSessions === false}
                            activity={activity} 
                            scheduleSlots={sortedScheduleSlots}
                            loadingSchedules={activityLoading || providerSchedulesLoading}
                            selectedScheduleSlot={selectedScheduleSlot}
                            onSlotSelected={setSelectedScheduledSlot}
                            displayedTimeZone={effectiveProviderData?.timeZone ?? ''}
                            onShowFiltersModalClick={handleShowFiltersModalClick}
                            filterOptions={filterOptions}
                        />
                    </div>    
                    {renderSelectedScheduleInfo()}  
                    <PreviousArrowButton
                        className="book-appt-previous-button"
                        onClick={onClickPreviousButton}
                    />
                </div>
                <SchedulesFilterDialog
                    open={Boolean(filtersModalAnchorEl)}
                    offices={detailsQuery?.provider.contactInfo.addresses}
                    onClose={() => setFiltersModalAnchorEl(null)}
                    filterOptions={filterOptions}
                    anchorEl={filtersModalAnchorEl}
                    onFiltersChange={handleFiltersChange}/>
            </div>
        );
}


export default BookApptStep2Staff;
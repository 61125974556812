import React, { FC, useEffect, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { SurveyModel } from 'survey-react';
import { useIdleTimer } from 'react-idle-timer';

import { useAppConfig } from 'providers';
import { SurveyJSContainer, SurveyJSContainerProps } from '../../../components/SurveyJS';

interface SessionNote_Props extends SurveyJSContainerProps {
    onSaveDraft?: (result: string) => void
}

export const SessionNote: FC<SessionNote_Props> = (props) => {
    const { t } = useTranslation('common', { useSuspense: false });
    const { onSurveyJSInitialized, onSaveDraft, ...rest } = props;
    const [isModified, setIsModified] = useState(false);
    const saveButtonRef = useRef<HTMLButtonElement>();

    const { IDLE_TIMEOUT_VALUE_IN_MILISECONDS } = useAppConfig();
    // if the user supplies a query param ?test=true, we enter qa testing mode
    const isQATesting = (new URLSearchParams(window.location.search)).get("test") === "true";
    const IDLE_TIMEOUT_VALUE_FOR_QA = 6 * 60 * 1000; // this allows auto-save to happen every 1 minute during QA testing
    const MILLISECONDS_BEFORE_SESSION_TIMEOUT = 5 * 60 * 1000; // we want to auto-save 5 minutes before session timeout
    const autoSaveTimeout = (isQATesting ? IDLE_TIMEOUT_VALUE_FOR_QA : IDLE_TIMEOUT_VALUE_IN_MILISECONDS) - MILLISECONDS_BEFORE_SESSION_TIMEOUT;

    const detectInput = (_, options) => {
        // detect changes for any field except defaultValues
        if (!Object.keys(props.defaultValues || {}).includes(options?.name)) {
            setIsModified(true);
            if (saveButtonRef.current) {
                saveButtonRef.current.disabled = false;
            }
        }
    }

    const detectInputEvent = (e) => detectInput(e, e?.target);

    const autoSave = (button: HTMLButtonElement) => {
        if (isQATesting) console.info("💾 auto-saving note at ", (new Date()).toLocaleTimeString());
        button?.focus();
        button?.onclick?.(new MouseEvent("click"));
        setIsModified(false);
        if (button) {
            button.disabled = true;
        }
    };

	const { reset: resetIdleTimer, pause: pauseIdleTimer, getTotalIdleTime, start: startIdleTimer } = useIdleTimer({
		onIdle: () => {
			console.info(`⏳ User was idle for ${(getTotalIdleTime() / 1000 / 60).toFixed(0)} minute(s). Auto-saving changes...`);
            autoSave(saveButtonRef.current as HTMLButtonElement);
            resetIdleTimer();
            if (saveButtonRef.current?.disabled) {
                pauseIdleTimer();
            }
		},
		onAction: (e) => {
			if (isQATesting) console.info(`🔁 ${e?.type} event resetting idle timer`, getTotalIdleTime(), e);
            resetIdleTimer();
        },
		timeout: autoSaveTimeout,
        startManually: true,
        throttle: 1000,
	});


    useEffect(() => () => {
        if (isQATesting) console.info("⏫ unmounting SessionNote...");
        pauseIdleTimer();
    }, []);

    useEffect(() => {
        if (isModified) {
            if (isQATesting) console.info("⏳🚀 idle timer triggered by session note change!");
            startIdleTimer();
        }
    }, [isModified]);

    const handleSurveyJSInitialized = (surveyModel: SurveyModel) => {
        onSurveyJSInitialized?.(surveyModel);

        surveyModel.textUpdateMode = "onTyping";
        surveyModel?.onValueChanged.add(detectInput);
        surveyModel?.onAfterRenderQuestion.add((_, options) => {
            if (isQATesting) console.log("📂📅🎦", options.question.name, options.htmlElement);
            if (options.question.name === "apt_datetime") {
                const dateInput = options.htmlElement.querySelector("input[type='datetime-local']") as HTMLInputElement;
                if (dateInput) {
                    dateInput.disabled = true;
                }
            }
            const inputElement = options.htmlElement.querySelector("input");
            if (inputElement) {
                inputElement.addEventListener("beforeinput", detectInputEvent);
                inputElement.addEventListener("keydown", detectInputEvent);
                inputElement.addEventListener("input", detectInputEvent);
                inputElement.addEventListener("change", detectInputEvent);
            }
            const textAreaElement = options.htmlElement.querySelector("textarea");
            if (textAreaElement) {
                textAreaElement.addEventListener("beforeinput", detectInputEvent);
            }
        })
        surveyModel?.onAfterRenderPage.add((survey) => {
            const footer = document.querySelector(".sv-footer") as HTMLElement;
            const saveButton = document?.createElement("button");
            saveButton.type = "button";
            saveButton.className = "sv-btn sv-footer__complete-btn";
            saveButton.innerHTML = t("save", "Save");
            saveButton.disabled = true;
            saveButton.style.marginRight = "15px";
            saveButton.onclick = () => {
                onSaveDraft?.(JSON.stringify(survey.data));
                saveButton.disabled = true;
                setIsModified(false);
                pauseIdleTimer();
            }
            saveButtonRef.current = saveButton;
            footer?.prepend(saveButton);
        });
    }

    return <SurveyJSContainer
        onSurveyJSInitialized={handleSurveyJSInitialized}
        {...rest}
    />
}
import React, { useEffect, forwardRef, RefObject, useMemo, useCallback, useRef } from 'react';
import FullCalendar from '@fullcalendar/react';
import { EventSourceFunc, CustomContentGenerator,
    EventContentArg, DatesSetArg, AllowFunc, ClassNamesGenerator, DidMountHandler, ViewMountArg, EventMountArg, CalendarApi } from '@fullcalendar/core';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import { UnavailabilityItem, EventType, EventClickArgKeyboard, DateSelectArgKeyboard } from '../../interfaces';
import { useTranslation } from 'react-i18next';
import momentTimezonePlugin from '@fullcalendar/moment-timezone';
import { useAuth } from 'components/Auth';
import useTimezoneUtils from 'hooks/useTimezoneUtils';
import * as Moment from 'moment';
import { extendMoment } from 'moment-range';
import { parseUavailabilityName } from '../../Unavailabilities/utils';
import { renderEventMatchingIcon } from '../../utils'

const moment = extendMoment(Moment);

const SELECTION_EVENT_ID = 'selection_event';

interface WeekViewProps {
    selectedPeriodStart: string,
    onTimeSlotSelected: (arg: DateSelectArgKeyboard) => void,
    events: EventSourceFunc,
    onDatesSet: (arg: DatesSetArg) => void,
    onEventClick: (arg: EventClickArgKeyboard) => void
}

export const WeekView = forwardRef<FullCalendar, WeekViewProps>((props, ref) => {
    const { selectedPeriodStart, onTimeSlotSelected, events, onDatesSet, onEventClick } = props;
    const { t, i18n } = useTranslation('calendaring', { useSuspense: false });

    let calendarElementRef = useRef<HTMLDivElement>();

    const eventsSrc = useRef<EventSourceFunc>(events);
    eventsSrc.current = events;
    const getEvents: EventSourceFunc = (dateRange, successCallback, failureCallback) => {
        eventsSrc.current(dateRange, successCallback, failureCallback);
    } 

    const { effectiveProviderData } = useAuth();
    const timeZone = effectiveProviderData?.timeZone ?? '';

    const { createDateFormatFunction } = useTimezoneUtils();

    const formatTime = useCallback(createDateFormatFunction('LT'), [timeZone, i18n.language]);
    
    useEffect(() => {
        (ref as RefObject<FullCalendar>)?.current?.getApi().gotoDate(selectedPeriodStart);
    }, [selectedPeriodStart]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        (ref as RefObject<FullCalendar>)?.current?.getApi().refetchEvents();
    }, [moment.localeData().firstDayOfWeek()]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        const handler = () => {
            (ref as RefObject<FullCalendar>)?.current?.getApi().getEventById(SELECTION_EVENT_ID)?.remove();
        }
        window.addEventListener('click', handler);
        return () => window.removeEventListener('click', handler);
    }, []);

    const eventContent: CustomContentGenerator<EventContentArg> = (arg) => {
        switch (arg.event.extendedProps.type as EventType) {
            case EventType.Reservation: {
                const reservation = arg.event.extendedProps;
                return (
                    <div className="timegrid_event_content">
                        <div className="timegrid_event_title">
                            <div className="timegrid_event_modality">
                                {renderEventMatchingIcon(arg.event.extendedProps.modalityCode)}
                            </div>
                            <div className="timegrid_event_client_name">
                                {reservation.clientName}
                            </div>
                            {reservation.firstTime &&
                                <div className="timegrid_event_new_flag">{t('first_time_appointment_flag')}</div>
                            }
                        </div>
                        <div className="timegrid_event_time">
                            {formatTime(arg.event.start)}
                            {' - '}
                            {formatTime(arg.event.end)}
                        </div>
                    </div>
                )
            }
            case EventType.Unavailability: {
                const unavailability = arg.event.extendedProps as UnavailabilityItem;
                return (
                    <div className="timegrid_event_content">
                        <div className="timegrid_event_title">
                            {parseUavailabilityName(unavailability.unavailabilityReason?.description)}
                        </div>
                        <div className="timegrid_event_time">
                            {formatTime(arg.event.start)}
                            {' - '}
                            {formatTime(arg.event.end)}
                        </div>
                    </div>
                )
            }
            default: { }
        }
    }

    const getEventClassNames: ClassNamesGenerator<EventContentArg> = (arg) => {
        switch (arg.event.extendedProps.type) {
            case EventType.Reservation: 
            case EventType.Unavailability: return 'timeGrid_event_card';
            default: return '';
        }
    }

    const getScrollerElement = () => (
        calendarElementRef.current?.querySelector('.fc-scrollgrid-section-body .fc-scroller') as (Element | null)
    )

    const selectAllow: AllowFunc = (span, _) => {
        const start = moment(span.start);
        if (start.isBefore(moment())) {
            return false;
        }
        const events = (ref as RefObject<FullCalendar>)?.current?.getApi().getEvents();
        const range = moment.range(start, moment(span.end));
        const availability = events?.filter(x => x.extendedProps.type === EventType.Availability)
            .find(x => moment.range(moment(x.startStr), moment(x.endStr)).overlaps(range));
        return !Boolean(availability);
    }
    
    const setTimeSlotAriaLabel = (el, event) => {
        const label = t('time_slot_aria_label', {
            replace: {
                startTime: moment(event.start).format('LT'),
                endTime: moment(event.end).format('LT'),
                date: moment(event.start).format('dddd MMMM Do')
            }
        });
        el.setAttribute('aria-label', label);
    }
    
    const eventDidMount: DidMountHandler<EventMountArg> = (arg) => {
        if (arg.event.id === SELECTION_EVENT_ID) {
            const el = arg.el as HTMLAnchorElement;
            el.classList.add('selection_event');
            el.style.bottom = '0';
            el.querySelector('.fc-event-main-frame')!.innerHTML = '';
            el.tabIndex = 0;
            el.setAttribute('aria-live', 'assertive');

            setTimeSlotAriaLabel(arg.el, arg.event);

            setTimeout(() => el.focus(), 0);

            el.addEventListener('keydown', (event) => {
                if (event.code === 'Tab') {
                    setTimeout(() => arg.event.remove(), 0);
                    return;
                }

                event.preventDefault();

                switch (event.code) {
                    case 'Enter': {
                        const span = {
                            allDay: false,
                            end: arg.event.end as Date,
                            endStr: arg.event.endStr,
                            start: arg.event.start as Date,
                            startStr: arg.event.startStr
                        }
                        if (selectAllow(span, null)) {
                            onTimeSlotSelected({
                                ...span,
                                view: arg.view,
                                jsEvent: event
                            });
                        }
                        break;
                    }
                    case 'Escape': {
                        document.getElementById('calendar_previous_period_button')?.focus();
                        setTimeout(() => arg.event.remove(), 0);
                        break;
                    }
                    case 'ArrowUp': {
                        const start = moment.tz(arg.event.start, arg.view.getOption('timeZone') as string);
                        const isStartOfDay = start.get('hours') === 0 && start.get('minutes') === 0;

                        if (event.shiftKey) {
                            const range = moment.range(arg.event.start as Date, arg.event.end as Date);
                            if (range.duration('minutes') <= 30) {
                                arg.event.setExtendedProp('shifting', 'start');
                                !isStartOfDay && arg.event.moveStart({minutes: -30});
                            } else {
                                arg.event.extendedProps['shifting'] === 'start'
                                    ? !isStartOfDay && arg.event.moveStart({minutes: -30})
                                    : arg.event.moveEnd({minutes: -30});
                            }
                        } else {
                            !isStartOfDay && arg.event.moveDates({minutes: -30});
                        }

                        const scroller = getScrollerElement();
                        const eventHarness = el.parentElement;
                        if (scroller && eventHarness && scroller.scrollTop > eventHarness.offsetTop) {
                            scroller.scrollTop = eventHarness.offsetTop;
                        }

                        break;
                    }
                    case 'ArrowDown': {
                        const end = moment.tz(arg.event.end, arg.view.getOption('timeZone') as string);
                        const isEndOfDay = end.get('hours') === 0 && end.get('minutes') === 0;

                        if (event.shiftKey) {
                            const range = moment.range(arg.event.start as Date, arg.event.end as Date);
                            if (range.duration('minutes') <= 30) {
                                arg.event.setExtendedProp('shifting', 'end');
                                !isEndOfDay && arg.event.moveEnd({minutes: 30});
                            } else {
                                arg.event.extendedProps['shifting'] === 'end'
                                    ? !isEndOfDay && arg.event.moveEnd({minutes: 30})
                                    : arg.event.moveStart({minutes: 30});
                            }
                        } else {
                            !isEndOfDay && arg.event.moveDates({minutes: 30});
                        }

                        const scroller = getScrollerElement();
                        const eventHarness = el.parentElement;
                        if (scroller && eventHarness && scroller.scrollTop + scroller.clientHeight < eventHarness.offsetTop + eventHarness.offsetHeight) {
                            scroller.scrollTop = eventHarness.offsetTop + eventHarness.offsetHeight - scroller.clientHeight;
                        }

                        break;
                    }
                    case 'ArrowLeft': {
                        if (moment(arg.event.start).subtract(1, 'day').isBefore(arg.view.currentStart)) {
                            const scroller = getScrollerElement();
                            if (scroller) {
                                const scrollTop = scroller.scrollTop ?? 0;
                                arg.view.calendar.prev();
                                scroller.scrollTop = scrollTop;
                            }
                        }
                        arg.event.moveDates({days: -1});
                        break;
                    }
                    case 'ArrowRight': {
                        if (moment(arg.event.end).add(1, 'day').isAfter(arg.view.currentEnd)) {
                            const scroller = getScrollerElement();
                            if (scroller) {
                                const scrollTop = scroller.scrollTop ?? 0;
                                arg.view.calendar.next();
                                scroller.scrollTop = scrollTop;
                            }
                        }
                        arg.event.moveDates({days: 1});
                        break;
                    }
                }

                setTimeSlotAriaLabel(arg.el, arg.event);

                el.style.bottom = '0';
            });
        }

        else if (arg.event._def.ui.display !== 'background') {
            const el = arg.el as HTMLAnchorElement;
            el.tabIndex = 0;
            
            el.addEventListener('keydown', (event) => {
                if (event.code === 'Tab') {
                    return;
                }

                event.preventDefault();

                switch (event.code) {
                    case 'Enter': {
                        onEventClick({
                            el: el,
                            event: arg.event,
                            jsEvent: event,
                            view: arg.view
                        });
                        break;
                    }
                    case 'Escape': {
                        document.getElementById('calendar_previous_period_button')?.focus();
                        break;
                    }
                    case 'ArrowUp': {
                        const end = (arg.event.start?.getMinutes() ?? 0) >= 30
                            ? moment(arg.event.start).startOf('hour').add(30, 'minutes')
                            : moment(arg.event.start).startOf('hour');
                        const start = end.clone().subtract(30, 'minutes');
                        addSelectionPlaceholderEvent(arg.view.calendar, start.toDate(), end.toDate());
                        break;
                    }
                    case 'ArrowDown': {
                        const start = (arg.event.end?.getMinutes() ?? 0) > 0
                            ? (arg.event.end?.getMinutes() ?? 0) > 30
                                ? moment(arg.event.end).startOf('hour').add(1, 'hour')
                                : moment(arg.event.end).startOf('hour').add(30, 'minutes')
                            : moment(arg.event.end);
                        const end = start.clone().add(30, 'minutes');
                        addSelectionPlaceholderEvent(arg.view.calendar, start.toDate(), end.toDate());
                        break;
                    }
                    case 'ArrowLeft': {
                        const start = (arg.event.start?.getMinutes() ?? 0) < 30
                            ? moment(arg.event.start).startOf('hour').subtract(1, 'day')
                            : moment(arg.event.start).startOf('hour').add(30, 'minutes').subtract(1, 'day');
                        const end = (arg.event.end?.getMinutes() ?? 0) > 0
                            ? (arg.event.end?.getMinutes() ?? 0) > 30
                                ? moment(arg.event.end).startOf('hour').add(1, 'hour').subtract(1, 'day')
                                : moment(arg.event.end).startOf('hour').add(30, 'minutes').subtract(1, 'day')
                            : moment(arg.event.end).subtract(1, 'day');
                        addSelectionPlaceholderEvent(arg.view.calendar, start.toDate(), end.toDate());
                        break;
                    }
                    case 'ArrowRight': {
                        const start = (arg.event.start?.getMinutes() ?? 0) < 30
                            ? moment(arg.event.start).startOf('hour').add(1, 'day')
                            : moment(arg.event.start).startOf('hour').add(30, 'minutes').add(1, 'day');
                        const end = (arg.event.end?.getMinutes() ?? 0) > 0
                            ? (arg.event.end?.getMinutes() ?? 0) > 30
                                ? moment(arg.event.end).startOf('hour').add(1, 'hour').add(1, 'day')
                                : moment(arg.event.end).startOf('hour').add(30, 'minutes').add(1, 'day')
                            : moment(arg.event.end).add(1, 'day');
                        addSelectionPlaceholderEvent(arg.view.calendar, start.toDate(), end.toDate());
                        break;
                    }
                }
            });
        }
    }

    const addSelectionPlaceholderEvent = (calendar: CalendarApi, start: Date, end: Date) => {
        const selectionEvent = calendar.getEventById(SELECTION_EVENT_ID);
        if (!selectionEvent) {
            calendar.addEvent({
                start: start,
                end: end,
                id: SELECTION_EVENT_ID,
                title: '',
                className: 'selection_event',
                textColor: 'transparent',
                borderColor: 'black',
                backgroundColor: 'transparent',
                durationEditable: true
            })
        } else {
            selectionEvent.setDates(start, end);
        }
    }

    const viewDidMount: DidMountHandler<ViewMountArg> = (arg) => {
        calendarElementRef.current = arg.el as HTMLDivElement;

        // prevent scroll containers form getting focus which made keyboard navigation confusing
        const scroller = getScrollerElement() as HTMLDivElement;
        if (scroller) {
            scroller.tabIndex = -1;
        }

        const header = arg.el.querySelector('.fc-scrollgrid-section-header .fc-scroller') as HTMLDivElement;
        if (header) {
            header.tabIndex = -1;
        }
    }

    const calendar = useMemo(() => (
        <FullCalendar
            nowIndicator = {true}
            allDaySlot={false}
            dayHeaderClassNames="weekview_header calendar_table_header"
            initialView="timeGridWeek"
            eventContent={eventContent}
            ref={ref}
            plugins={[timeGridPlugin, interactionPlugin, momentTimezonePlugin]}
            timeZone={timeZone || ''}
            firstDay={moment.localeData(i18n.language).firstDayOfWeek()}
            locale={i18n.language}
            datesSet={onDatesSet}
            initialDate={selectedPeriodStart}
            headerToolbar={false}
            selectable
            scrollTime="08:00"
            select={onTimeSlotSelected}
            eventClick={onEventClick}
            height="100%"
            expandRows={true}
            events={getEvents}
            eventClassNames={getEventClassNames}
            selectAllow={selectAllow}
            eventDidMount={eventDidMount}
            viewDidMount={viewDidMount}
        />), [timeZone, i18n.language]); // eslint-disable-line react-hooks/exhaustive-deps

    return calendar;
})

import React, { FC, useMemo } from 'react';
import { useQuery, useApolloClient } from '@apollo/client';
import { PROFILE_DETAILS_QUERY } from './queries';
import { Spinner } from 'components/Shared';
import { useTranslation } from 'react-i18next';
import { PersonalInfo } from '../../Sections/PersonalInfo';
import { ContactDetails } from '../../Sections/ContactDetails';
import { CommunicationPreferences } from '../../Sections/CommunicationPreferences';
import { BillingAndPayment } from '../../Sections/BillingAndPayment';
import { ProviderDetails as IProviderDetails } from '../../../interfaces';
import './ProviderDetails.scss';
import { PhoneNumberForSms } from '../../Sections/PhoneNumberForSms';
import { SMS_COMMUNICATION_ID } from '../../../constants';

interface ProviderDetailsProps {
    providerId: number
}

export const ProviderDetails: FC<ProviderDetailsProps> = (props) => {
    const { providerId } = props;
    const { ready: translationsReady } = useTranslation('providerprofile', { useSuspense: false });
    const { cache } = useApolloClient();

    const profileDetailsQuery = useQuery(PROFILE_DETAILS_QUERY, {
        fetchPolicy: 'network-only',
        variables: { providerId: providerId },
        errorPolicy: 'all'
    });
    const providerDetails = profileDetailsQuery.data?.provider.details;
    const contactInfo = profileDetailsQuery.data?.provider?.contactInfo;

    const loading = !translationsReady || profileDetailsQuery.loading;

    const handlePersonalInfoUpdated = (providerDetails: IProviderDetails) => {
        cache.writeQuery({
            query: PROFILE_DETAILS_QUERY,
            variables: {providerId: providerId}, 
            data: {
                ...profileDetailsQuery.data,
                provider: {
                    id: providerId,
                    ...profileDetailsQuery.data?.provider,
                    details: providerDetails
                }
            }
        })
    }

    const handlePreferredCommunicationUpdated = (values) => {
        cache.writeQuery({
            query: PROFILE_DETAILS_QUERY,
            variables: { providerId: providerId },
            data: {
                ...profileDetailsQuery.data,
                provider: {
                    id: providerId,
                    ...profileDetailsQuery.data?.provider,
                    details: {
                        ...profileDetailsQuery.data?.provider?.details,
                        ...values
                    }
                }
            }
        });
    }

    const handlePhoneNumberUpdated = (values) => {
        cache.writeQuery({
            query: PROFILE_DETAILS_QUERY,
            variables: { providerId: providerId },
            data: {
                ...profileDetailsQuery.data,
                provider: {
                    id: providerId,
                    ...profileDetailsQuery.data?.provider,
                    details: {
                        ...profileDetailsQuery.data?.provider?.details,
                        ...values
                    }
                }
            }
        });
    }

    const smsPhoneNumber = useMemo(() => 
        providerDetails?.smsPhoneNumbers?.find(phone => phone.receiveSms)
    , [providerDetails?.smsPhoneNumbers]);

    const showPhoneNumberForSMSSection = providerDetails?.allowSmsCommunication
        || providerDetails?.preferredCommunication === SMS_COMMUNICATION_ID
        || providerDetails?.preferredCommunicationForNonConnected === SMS_COMMUNICATION_ID;

    return loading
        ? <Spinner fillParent/>
        : <>
            <PersonalInfo providerId={providerId} providerDetails={providerDetails} onUpdated={handlePersonalInfoUpdated}/>
            <ContactDetails contactInfo={contactInfo}/>
            <CommunicationPreferences providerId={providerId} onUpdated={handlePreferredCommunicationUpdated}
                smsPhoneNumber={smsPhoneNumber} emailAddress={providerDetails?.emailAddress}
                allowEmailCommunication={providerDetails?.allowEmailCommunication}
                allowSmsCommunication={providerDetails?.allowSmsCommunication}
                preferredCommunication={providerDetails?.preferredCommunication}
                preferredCommunicationString={providerDetails?.preferredCommunicationString}
            />
            {
                showPhoneNumberForSMSSection &&
                <PhoneNumberForSms providerId={providerId} smsPhoneNumbers={providerDetails?.smsPhoneNumbers}
                    onUpdated={handlePhoneNumberUpdated}/>
            }
            <BillingAndPayment/>
        </>
}
import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from 'components/Shared/Inputs';
import './LastAppointmentSelector.scss';

interface LastAppointmentSelectorProps {
    value: boolean | undefined,
    onChange: (newValue: boolean) => void,
    disabled: boolean
}

export const LastAppointmentSelector: FC<LastAppointmentSelectorProps> = (props) => {
    const { value, onChange, disabled } = props;
    const { t } = useTranslation('caseManagement');
    const [falseFocused, setFalseFocused] = useState(false);
    const [trueFocused, setTrueFocused] = useState(false);

    return (
        <>
            <h3 className="global__header3" id="last_appointment_selector_label">
                {t('was_this_your_clients_last_appointment')}?
            </h3>
            <div role="radiogroup" className="was_this_last_appointnment_buttons_container"
                aria-labelledby="last_appointment_selector_label">
                <div className="last_appointment_selector_inputs_container">
                    <input type="radio" name="last_appointment" value="false"
                        className="last_appointment_selector_radio"
                        aria-label={t('no')}
                        tabIndex={value === true ? -1 : 0}
                        aria-checked={value === false ? "true" : "false"}
                        onClick={() => onChange(false)}
                        onFocus={() => setFalseFocused(true)}
                        onBlur={() => setFalseFocused(false)}
                    />
                    <Button className={falseFocused ? 'Mui-focusVisible' : ''} variant={value === false ? 'opaque' : 'outline'}
                        disabled={disabled} onClick={() => onChange(false)} tabIndex={-1} fullWidth>
                        {t('no')}
                    </Button>
                </div>
                <div className="last_appointment_selector_inputs_spacer"/>
                <div className="last_appointment_selector_inputs_container">
                    <input type="radio" name="last_appointment" value="true"
                        className="last_appointment_selector_radio"
                        aria-label={t('yes')}
                        tabIndex={value === false ? -1 : 0}
                        aria-checked={value === true ? "true" : "false"}
                        onClick={() => onChange(true)}
                        onFocus={() => setTrueFocused(true)}
                        onBlur={() => setTrueFocused(false)}
                    />
                    <Button className={trueFocused ? 'Mui-focusVisible' : ''} variant={value === true ? 'opaque' : 'outline'}
                        disabled={disabled} onClick={() => onChange(true)} tabIndex={-1} fullWidth>
                        {t('yes')}
                    </Button>
                </div>
            </div>
        </>
    )
}
import React, { FC, useState } from 'react';
import { TableCell, TableHead, TableRow, TableSortLabel } from '@mui/material';
import { ColumnDefinition, ISortsDefinition } from './interfaces';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';

interface MaterialTableHeaderProps {
    className?: string,
    columns: ColumnDefinition[],
    sorts?: ISortsDefinition,
    onSortsChange?: (sorts: ISortsDefinition) => void,
    collapsible?: boolean
}

export const MaterialTableHeader: FC<MaterialTableHeaderProps> = (props) => {
    const { columns, sorts, onSortsChange, collapsible, className } = props;
    const { t } = useTranslation('common');

    const [announcedHeaders, setAnnouncedHeaders] = useState(
        columns
            .filter(column => column.field)
            .reduce((acc, column) => ({...acc, [column.field!]: false}), {})
    );

    const handleSortChange = (_, column: ColumnDefinition) => {
        if (!sorts || !onSortsChange) {
            return;
        }

        let newSort: 'asc' | 'desc' | undefined = undefined;

        if (column.field) {
            if (!sorts[column.field]) {
                newSort = 'asc';
            } else if (sorts[column.field] === 'asc') {
                newSort = 'desc';
            }
    
            onSortsChange({
                [column.field]: newSort
            });
    
            setAnnouncedHeaders({
                ...announcedHeaders,
                [column.field]: true
            });
        }
    }

    const getSortLabelText = (column: ColumnDefinition) => {
        if (!sorts || !column.field) {
            return;
        }
        let sortDirectionLabel = '';
        switch (sorts[column.field]) {
            case 'asc': {
                sortDirectionLabel = t('sorted_ascending');
                break;
            }
            case 'desc': {
                sortDirectionLabel = t('sorted_descending');
                break;
            }
            default: {
                sortDirectionLabel = t('not_sorted');
            }
        }

        return (
            <span>
                <span aria-hidden>{column.title}</span>
                <span className="visually_hidden" aria-live={announcedHeaders[column.field] ? "polite" : "off"} role="region">
                    {`${column.ariaLabel ?? column.title} ${sortDirectionLabel}`}
                </span>
                <span className="visually_hidden">{t('sort_button_hint')}</span>
            </span>
        )
    }

    const renderHeaderCell = (column: ColumnDefinition) => (
        sorts && column.sortable && column.field
            ? <TableSortLabel 
                aria-label={column.ariaLabel}
                active={sorts[column.field] !== undefined}
                direction={sorts[column.field]} 
                onClick={(event) => handleSortChange(event, column)}
            >
                {getSortLabelText(column)}
            </TableSortLabel>
            : <span>{column.title}</span>
    )

    return (
        <TableHead className={clsx("table_header", className)}>
            <TableRow className="table_header_row">
                {
                    collapsible &&
                    <TableCell className="table_header_collapse_indicator_cell"/>
                }
                {
                    columns.map((column, index) => (
                        <TableCell className="table_header_cell" style={{ width: column.width }} key={index}>
                            {renderHeaderCell(column)}
                        </TableCell>
                    ))
                }
            </TableRow>
        </TableHead>
    )
}
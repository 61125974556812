import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { SurveyModel } from 'survey-react';
import { SurveyJSContainer, SurveyJSContainerProps, useSurveyJSUtils } from '../../../components/SurveyJS';

interface PHQ9_Props extends SurveyJSContainerProps {}

export const PHQ9: FC<PHQ9_Props> = (props) => {
    const { onSurveyJSInitialized, ...rest } = props;
    const { t } = useTranslation('formNotes', { useSuspense: false });
    
    const { updateAriaLabelsForQuestionInRow } = useSurveyJSUtils();

    const handleSurveyJSInitialized = (surveyModel: SurveyModel) => {
        onSurveyJSInitialized?.(surveyModel);

        surveyModel.onAfterRenderPanel.add(() => {
            // const document = options.htmlElement?.ownerDocument;
            updateAriaLabelsForQuestionInRow(document?.querySelector("div[name='level_of_difficulty']"));
        })
    }

    return <SurveyJSContainer
        onSurveyJSInitialized={handleSurveyJSInitialized}
        {...rest}
        coachingNote={t('providerhub/assessment/phq9_note')}
        coachingNoteTitle={t('providerhub/assessment/phq9_note_title')}
    />
}
import React, { FC, PropsWithChildren } from 'react';
import { FormStatus } from '../../interfaces';
import IncompleteIcon from '@mui/icons-material/ErrorRounded';
import CompletedIcon from '@mui/icons-material/DoneRounded';
import ClearRoundedIcon from '@mui/icons-material/ClearRounded';
import WarningRoundedIcon from '@mui/icons-material/WarningRounded';

interface ChecklistItemProps extends PropsWithChildren {
    label: string,
    status: FormStatus
}

export const ChecklistItem: FC<ChecklistItemProps> = (props) => {
    const { label, status, children } = props;

    const getIcon = (status: FormStatus) => {
        switch (status) {
            case FormStatus.Completed: 
                return <CompletedIcon className="close_case_checklist_list_item_icon"/>
            case FormStatus.Incomplete: 
                return <IncompleteIcon className="close_case_checklist_list_item_icon"/>
            case FormStatus.Warning:
                return <WarningRoundedIcon className="close_case_checklist_list_item_icon"/>
            case FormStatus.Declined:
            default: 
                return <ClearRoundedIcon className="close_case_checklist_list_item_icon"/>
        }
    }

    return (
        <li className={`close_case_checklist_list_item ${status}`}>
            <div className="close_case_checklist_list_item_header">
                {getIcon(status)}
                <div className="close_case_checklist_list_item_header_text">
                    {label}
                </div>
            </div>
            {
                Boolean(children) && (
                    <div className="close_case_checklist_list_item_actions">
                        {children}
                    </div>
                )
            }
        </li>
    )
}


import { CONTRACT_DETAILS_QUERY } from './queries'
import { useQuery } from "@apollo/client";

export const useContractDetails = (activityId: number) => (
    useQuery(CONTRACT_DETAILS_QUERY, {
        variables: {
            activityId
        },
        fetchPolicy: "network-only"
    })
)
import React, { FC, useEffect } from "react";
import { useFormik } from 'formik';
import { ReportType, WSReportType, HMPReportType, SAPReportType, SRPPReportType } from '../../constants';
import { DateInput, Select, Button, DateTimeInput } from 'components/Shared/Inputs';
import useTimezoneUtils from "hooks/useTimezoneUtils";
import { IDurationConstant } from "hooks/useDurationConstants/interfaces";
import { IWspTaskCode } from "hooks/useWspTaskCodes/interfaces";
import { MutationTuple, useLazyQuery } from "@apollo/client";
import { useTranslation } from "react-i18next";
import { Dialog, DialogContent, IconButton, MenuItem, Alert } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import moment, { Moment } from 'moment';
import { EditBillableMutationData, EditBillableMutationVars,
    AddBillableMutationData, AddBillableMutationVars, REPORT_TYPE_SELECT_AVAILABLE_QUERY, SUBMITTED_REPORT_TYPES_QUERY} from '../../queries';
import { EditWspBillable } from "../../interfaces";
import { Spinner } from 'components/Shared';
import { isValid15MinuteIncrement } from "utils";

import './WspTaskEditDialog.scss';

interface WspBillableEditDialogProps {
    activityId: number,
    wspBillable: EditWspBillable | null,
    onCancel: () => void,
    updateMutation: MutationTuple<EditBillableMutationData, EditBillableMutationVars>,
    createMutation: MutationTuple<AddBillableMutationData, AddBillableMutationVars>,
    durationConstants: IDurationConstant[] | undefined,
    taskCodes: IWspTaskCode[] | undefined,
    activityCreatedDate: string | undefined
}

interface WspBillableEditDialogFormValues {
    billableId: number | undefined
    date: Moment,
    wspTaskCode: string | undefined,
    duration: number | undefined,
    reportType: string | undefined | null,
    reportDate: Moment | undefined
}

export const WspBillableEditDialog: FC<WspBillableEditDialogProps> = (props) => {
    const { wspBillable, durationConstants, taskCodes, activityCreatedDate,
        activityId, updateMutation, createMutation, onCancel } = props;
    
    const { t } = useTranslation(['Invoicing', 'common'], { useSuspense: false });

    const [updateTask, updateBillableMutation] = updateMutation;
    const [createTask, createBillableMutation] = createMutation;
    const [getReporTypeSelectAvailable, reportTypeSelectAvailableQuery] = useLazyQuery(REPORT_TYPE_SELECT_AVAILABLE_QUERY);
    const [getSubmittedReportTypes, submittedReportTypesQuery] = useLazyQuery(SUBMITTED_REPORT_TYPES_QUERY);
    
    const { dateTimeInputFormat } = useTimezoneUtils();

    const formik = useFormik<WspBillableEditDialogFormValues>({
        initialValues: {
            billableId: wspBillable?.billableId,
            date: wspBillable?.date ? moment(wspBillable.date) : moment().startOf('hour'),
            duration: wspBillable?.duration,
            wspTaskCode: wspBillable?.wspTaskCode,
            reportType: wspBillable?.wspReportType,
            reportDate: wspBillable?.wspMonthlyReportYear && wspBillable?.wspMonthlyReportMonth
                ? moment({ year: wspBillable.wspMonthlyReportYear, month: wspBillable.wspMonthlyReportMonth - 1 })
                : moment()
        },
        validate: (values) => {
            const errors: Partial<Record<keyof WspBillableEditDialogFormValues, string>> = {};

            if (!isValid15MinuteIncrement(values.date)) {
                errors.date = 'Enter valid 15 minute increment'
            }

            if (!values.duration) {
                errors.duration = 'Enter duration';
            }

            if (!values.wspTaskCode) {
                errors.wspTaskCode = 'Enter task code';
            } else if (reportTypeSelectAvailable) {
                if (submittedReportTypes) {
                    if (submittedReportTypes.length === 0) {
                        errors.wspTaskCode = 'NO_REPORT_SUBMITTED';
                    } else if (!values.reportType) {
                        errors.reportType = 'Enter report type';
                    } else if (values.reportDate?.isBefore(minDate)) {
                        errors.reportDate = 'Date must be after case was opened';
                    } else if (values.reportDate?.isAfter(maxDate)) {
                        errors.reportDate = 'Date must be before case opened date + 36 months';
                    }
                }
            }
            
            return errors;
        },
        onSubmit: (values) => {
            const variables: any = {
                activityId: wspBillable!.activityId,
                invoiceId: wspBillable!.invoiceId,
                providerId: wspBillable!.providerId,
                duration: values.duration! / 4,
                taskCode: values.wspTaskCode!,
                date: values.date.toISOString(),
                wspReportType: undefined,
                wspMonthlyReportYear: undefined,
                wspMonthlyReportMonth: undefined
            }

            if (reportTypeSelectAvailable) {
                variables.wspReportType = values.reportType;
                if (values.reportType === ReportType.Monthly ||
                    values.reportType === WSReportType.Monthly ||
                    values.reportType === HMPReportType.Monthly ||
                    values.reportType === SAPReportType.Monthly ||
                    values.reportType ===  SRPPReportType.Monthly) {
                    variables.wspMonthlyReportYear = values.reportDate?.year();
                    variables.wspMonthlyReportMonth = values.reportDate ? values.reportDate.month() + 1 : undefined;
                }
            }

            if (values.billableId) {
                updateTask({
                    variables: {
                        billableId: values.billableId,
                        ...variables
                    }
                }).then(onCancel);
            } else {
                createTask({
                    variables: variables
                }).then(onCancel);
            }
        }
    });

    const handleDateChange = (date: Moment | null) => {
        formik.setFieldValue('date', date);
    }

    const handleReportDateChange = (date: Moment | null) => {
        formik.setFieldValue('reportDate', date);
    }

    const handleDurationChange = (event) => {
        const value = event.target.value;
        formik.setFieldValue('duration', value ? Number.parseFloat(value) : undefined);
    }

    useEffect(() => {
        if (formik.values.wspTaskCode) {
            getReporTypeSelectAvailable({
                variables: {
                    activityId: activityId,
                    taskCode: formik.values.wspTaskCode
                }
            })
        }
    }, [formik.values.wspTaskCode]);
    
    useEffect(() => {
        if (reportTypeSelectAvailableQuery.data?.reportTypeSelectAvailable) {
            getSubmittedReportTypes({
                variables: {
                    activityId: activityId
                }
            })
        }
    }, [reportTypeSelectAvailableQuery.data?.reportTypeSelectAvailable]);

    const minDate = moment(activityCreatedDate ?? new Date());
    const maxDate = minDate.clone().add(3, 'years');

    const reportTypeSelectAvailable = reportTypeSelectAvailableQuery.data?.reportTypeSelectAvailable ?? false;
    const submittedReportTypes = submittedReportTypesQuery.data?.submittedReportTypes;

    const loading = updateBillableMutation.loading
        || createBillableMutation.loading
        || reportTypeSelectAvailableQuery.loading
        || submittedReportTypesQuery.loading;

    return (
        <Dialog open={Boolean(wspBillable)} className="edit_dialog">
            <DialogContent>
                {
                    loading &&
                    <Spinner fillParent backdrop/>
                }
                <div className="edit_dialog_header">
                    <h2 className="edit_dialog_title">
                        {
                            formik.values.billableId
                                ? t('edit_task')
                                : t('add_task')
                        
                        }
                    </h2>
                    <IconButton className="edit_dialog_close_button" onClick={onCancel}><CloseIcon /></IconButton>
                </div>
                <form className="edit_dialog_content">
                    <DateTimeInput
                        label={t('date')}
                        className="wsp_billable_input"
                        format={dateTimeInputFormat}
                        // placeholder={dateTimeInputMask}
                        value={formik.values.date}
                        onChange={handleDateChange}
                        disabled={loading || Boolean(formik.values.billableId)}
                        // error={Boolean(formik.errors.date)}
                        minutesStep={15}
                    />
                    <Select
                        label={t('wsp_task_code')}
                        value={formik.values.wspTaskCode ?? ''}
                        className="wsp_billable_input"
                        onChange={formik.handleChange}
                        name="wspTaskCode"
                        variant="outlined"
                        disabled={loading || Boolean(formik.values.billableId)}
                        error={Boolean(formik.errors.wspTaskCode)}
                    >
                        {
                            taskCodes
                                ?.map((code, index) => (
                                    <MenuItem value={code.value} key={index}>
                                        {code.title}
                                    </MenuItem>
                                ))
                        }
                    </Select>
                    <Select
                        label={t('duration')}
                        value={formik.values.duration ?? ''}
                        className="wsp_billable_input"
                        name="duration"
                        onChange={handleDurationChange}
                        disabled={loading}
                        error={Boolean(formik.errors.duration)}
                    >
                        {
                            durationConstants?.map((duration) => (
                                <MenuItem value={duration.value} key={duration.value}>
                                    {duration.title}
                                </MenuItem>
                            ))
                        }
                    </Select>
                    {
                        reportTypeSelectAvailable && submittedReportTypes && (
                            submittedReportTypes.length === 0
                                ? <Alert severity="error">
                                    No reports submitted
                                </Alert>
                                : <>
                                    <Select
                                        label={t('report_type')}
                                        value={formik.values.reportType ?? ''}
                                        className="wsp_billable_input"
                                        name="reportType"
                                        onChange={formik.handleChange}
                                        disabled={loading}
                                        error={Boolean(formik.errors.reportType)}
                                    >
                                        {
                                            submittedReportTypes?.map((reportType) => (
                                                <MenuItem value={reportType.value} key={reportType.value}>
                                                    {reportType.title}
                                                </MenuItem>
                                            ))
                                        }
                                    </Select>
                                    {
                                        (formik.values.reportType === ReportType.Monthly
                                            || formik.values.reportType === WSReportType.Monthly
                                            || formik.values.reportType === HMPReportType.Monthly
                                            || formik.values.reportType === SAPReportType.Monthly
                                            || formik.values.reportType === SRPPReportType.Monthly
                                        ) &&
                                        <DateInput
                                            label={t('report_month')}
                                            className="wsp_billable_input"
                                            views={['year', 'month']}
                                            openTo="month"
                                            format="MM/YYYY"
                                            minDate={minDate}
                                            maxDate={maxDate}
                                            value={moment(formik.values.reportDate, 'YYYY-MM-DD')}
                                            onChange={handleReportDateChange}
                                            disabled={loading || Boolean(formik.values.billableId)}
                                            // error={Boolean(formik.errors.reportDate)}
                                        />
                                    }
                                </>
                        )
                    }

                    <div className="edit_dialog_actions_wrapper">
                        <Button variant="text" type="button" onClick={onCancel}>
                            {t('cancel')}
                        </Button>
                        <Button variant="opaque" type="button" disabled={loading} onClick={formik.submitForm}>
                            {t('save_task')}
                        </Button>
                    </div>
                </form>
            </DialogContent>
        </Dialog>
    );
}
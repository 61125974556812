import { InputAdornment, TextField, Autocomplete } from '@mui/material';

import SearchIcon from '@mui/icons-material/Search';
import React, { FC } from 'react';

export interface IAutoCompleteValue {
    label: string;
    id: number | string;
}

export interface IAutoCompleteProps {
    options: IAutoCompleteValue[];
    selectedOption?: IAutoCompleteValue | null;
    autoHighlight?: boolean;
    placeholder?: string;
    onChangeOption?: (value: IAutoCompleteValue | null) => void;
}

export const AutoComplete: FC<IAutoCompleteProps> = (props) => {
    const { options, placeholder, onChangeOption, selectedOption } = props;
    return (
        <Autocomplete
            value={selectedOption}
            onChange={(_, newValue) => {
                onChangeOption && onChangeOption(newValue);
            }}
            autoComplete
            options={options}
            getOptionLabel={(option) => option.label}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            autoHighlight 
            renderInput={(params) => (
                <TextField 
                    variant="outlined" 
                    placeholder={placeholder}
                    className="autocomplete_search_input"
                    {...params}
                    InputProps={{
                        ...params.InputProps,
                        startAdornment: <InputAdornment position="start" children={<SearchIcon />} />
                    }}
                />
            )}
            renderOption={(props, option) => {
                return (
                  <li {...props} key={option.id}>
                    {option.label}
                  </li>
                );
              }}
        />
    )
}
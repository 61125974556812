import { gql, TypedDocumentNode } from '@apollo/client';
import { ActivityData, CloseReason, CloseResult, FormResult, FormSummary, Session } from './interfaces';

export const CLOSE_CASE_CHECKLIST_QUERY: TypedDocumentNode<CloseCaseCheclistQueryData, CloseCaseCheclistQueryVars> = gql`
    query CLOSE_CASE_CHECKLIST_QUERY($activityId: Long!) {
        activity(activityId: $activityId) {
            activityId
            service
            client {
                firstName
                lastName
            }
            sessions {
                duration
                sessionId
                startDateTimeUTC
                sessionStatusId
            }
            mandatoryAssessments {
                assessmentName
                templateName
                mandatory
            }
            firstAppointmentAssessmentFormNames {
                assessmentName
                templateName
                mandatory
            }
            lastAppointmentAssessmentFormNames {
                assessmentName
                templateName
                mandatory
            }
        }
        forms: formsByActivity(activityId: $activityId) {
            id
            completed
            templateName
            sessionId
            stage
            preAssessmentStageStatus
            postAssessmentStageStatus
        }
    }
`
interface CloseCaseCheclistQueryData {
    activity: ActivityData | undefined,
    forms: FormSummary[] | undefined
}
interface CloseCaseCheclistQueryVars {
    activityId: number
}


export const CLOSE_CASE_ACTIVITY_QUERY: TypedDocumentNode<CloseCaseActivityQueryData, CloseCaseActivityQueryVars> = gql`
    query CLOSE_CASE_SESSIONS_QUERY($activityId: Long!) {
        activity(activityId: $activityId) {
            activityId
            service
            sessions {
                duration
                sessionId
                startDateTimeUTC
                sessionStatusId
            }
        }
    }
`
interface CloseCaseActivityQueryVars {
    activityId: number
}
interface CloseCaseActivityQueryData {
    activity: {
        service: string,
        sessions: Session[]
    }
}


export const CLOSE_CASE_FORMS_QUERY: TypedDocumentNode<CloseCaseFormsQueryData, CloseCaseFormsQueryVars> = gql`
    query CLOSE_CASE_FORMS_QUERY($activityId: Long!) {
        forms: formsByActivity(activityId: $activityId) {
            id
            completed
            templateName
            sessionId
            stage
        }
    }
`
interface CloseCaseFormsQueryVars {
    activityId: number
}
interface CloseCaseFormsQueryData {
    forms: FormSummary[]
}


export const CLOSE_CASE_FORMS_RESULTS_QUERY: TypedDocumentNode<CloseCaseFormsResultsQueryData, CloseCaseFormsResultsQueryVars> = gql`
    query CLOSE_CASE_FORMS_RESULTS_QUERY($formIds: [String]) {
        forms(formIds: $formIds) {
            id
            result
        }
    }
`
interface CloseCaseFormsResultsQueryVars {
    formIds: string[],
}

interface CloseCaseFormsResultsQueryData {
    forms: FormResult[]
}


export const CLOSE_ACTIVITY_MUTATION: TypedDocumentNode<CloseActivityMutationData, CloseActivityMutationVars> = gql`
    mutation CLOSE_ACTIVITY_MUTATION($activityId: Long!, $closureDate: DateTime) {
        closeActivity(activityId: $activityId, closureDate: $closureDate) {
            result
            reason
        }
    }
`
interface CloseActivityMutationVars {
    activityId: number,
    closureDate?: Date
}


interface CloseActivityMutationData {
    closeActivity: {
        result: CloseResult,
        reason: CloseReason
    }
}
import React, { FC, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams, useNavigate } from 'react-router-dom';
import { useAuth } from 'components/Auth';
import { Spinner, confirm, CaseManagementActionContainer } from 'components/Shared';
import { useQuery } from '@apollo/client';
import { UPDATE_APPT_STATUS_DATA_QUERY, UPDATE_APPT_STATUS_MUTATION, TERMINATE_UPDATE_STATUS_TODO_MUTATION } from '../queries';
import { ApptStatusForm } from '../components/ApptStatusForm';
import { ApptStatusFormValues, Session } from '../interfaces';
import { AfterApptStatusUpdatedPrompt } from '../components/AfterApptStatusUpdatedPromp';
import { CREATE_CLOSURE_REPORT, CREATE_FORM_BY_TEMPLATE } from 'components/Forms/queries';
import { useHistoryState } from 'hooks/useHistoryState';
import { TemplateNames, WspTemplateNames } from 'components/Forms/constants';
import { SESSION_STATUS } from '../../constants';
import { useApptCancellationReasons, useSessionStatusList } from 'hooks/useSessionStatuses';
import { useDurationConstants, transformHoursToDurationKey } from 'hooks/useDurationConstants';
import useTimezoneUtils from 'hooks/useTimezoneUtils';
import { PageMetadata } from 'components/Shared/PageMetadata';
import { LastAppointmentSelector } from '../components/LastAppointmentSelector';
import { useFeatureManager } from 'hooks/useFeatureManager';
import { GetLastAssessmentFormsIncomplete, GetFirstAssessmentFormsIncomplete } from '../../utils/formStatusUtils';
import { useMutationWithExpiration } from 'providers/Apollo/useMutationWithExpiration';

import './UpdateApptStatus.scss';

export interface UpdateAppstStatusViewHistoryState {
    activity?: any,
    workflowId?: string
}

interface UpdateApptStatusViewProps {}

export const UpdateApptStatusView: FC<UpdateApptStatusViewProps> = (_) => {
    const { t, ready: translationsReady } = useTranslation('caseManagement', { useSuspense: false });
    const { effectiveProviderData } = useAuth();

    const [wasThisLastAppt, setWasThisLastAppt] = useState<boolean | undefined>(undefined);

    const [historyState] = useHistoryState<UpdateAppstStatusViewHistoryState>();

    const { formatToProviderDay, formatToProviderTime } = useTimezoneUtils();
    const { isHourlyWorkflowFeatureEnabled, isExtendedUpdateApptStatusFeatureEnabled } = useFeatureManager();

    const navigate = useNavigate();

    const { activityId: activityIdString, sessionId: sessionIdString } = useParams<{ activityId: string, sessionId: string }>();
    const activityId = parseInt(activityIdString || '');
    const sessionId = parseInt(sessionIdString || '');

    if (!activityId) {
        navigate('/', { replace: true });
    }

    const { data: activityQueryData, loading: activityDataQueryLoading } = useQuery(UPDATE_APPT_STATUS_DATA_QUERY, {
        variables: { activityId: activityId },
        fetchPolicy: 'no-cache'
    });

    const [updateStatus, updateStatusMutation] = useMutationWithExpiration(UPDATE_APPT_STATUS_MUTATION, {
        errorPolicy: 'all'
    });

    const [closeToDo, closeToDoMutation] = useMutationWithExpiration(TERMINATE_UPDATE_STATUS_TODO_MUTATION, {
        variables: { 
            activityId: activityId,
            sessionId: sessionId
        },
        errorPolicy: 'all'
    });

    const activity: any | undefined = activityQueryData?.activity || historyState?.activity;
    const afterUpdateActivityData = updateStatusMutation.data?.updateAppointment.activity;
    const pendingPHQ9FormId = updateStatusMutation.data?.updateAppointment.pendingPHQ9Form?.id ?? null;
    const pendingGad7FormId = updateStatusMutation.data?.updateAppointment.pendingGad7Form?.id ?? null;

    const sessions = afterUpdateActivityData
        ? afterUpdateActivityData.sessions
        : activity?.sessions;

    const session: Session | undefined = useMemo(() => 
        sessions?.find(session => session.sessionId === sessionId)
    , [sessions]);

    const isStaffProvider = effectiveProviderData?.employmentTypeId === 1 ||
        effectiveProviderData?.employmentTypeId === 2;

    const hasCompletedSession = useMemo(() => 
        Boolean(sessions?.find(session => session?.sessionStatusId === SESSION_STATUS.ATTENDED))
    , [sessions]);

    // const isLastScheduledSession = useMemo(() => !activity?.sessions
    //     ?.includes(s => s?.startDateTimeUTC && session?.startDateTimeUTC
    //         ? new Date(s?.startDateTimeUTC) > new Date(session?.startDateTimeUTC)
    //         : false)
    // , [activity?.sessions]);

    // const isOnlyAttendedSession = useMemo(() => sessions
    //     ?.filter(session => session.sessionStatusId === SESSION_STATUS.ATTENDED)?.length <= 1
    //     ?? true
    // , [sessions]);

    const clientName = activity?.client
        ? `${activity?.client.firstName} ${activity?.client.lastName}`
        : activity?.clientName;

    const formattedSessionDateTime =
        (session?.startDateTimeUTC && formatToProviderDay(session?.startDateTimeUTC)) + ' ' + (session?.startDateTimeUTC && formatToProviderTime(session?.startDateTimeUTC));
    
    const [apptStatuses, apptStatusesLoading] = useSessionStatusList(session?.startDateTimeUTC ?? '');
    const [apptVoidReasons, apptVoidReasonsLoading] = useApptCancellationReasons(isStaffProvider);
    const [durationConstants, durationConstantsLoading] = useDurationConstants();

    const apptStatusSet = session && session.sessionStatusId !== SESSION_STATUS.ACTIVE;

    useEffect(() => {
        if (historyState?.workflowId && apptStatusSet) {
            closeToDo().then(() => navigate('/', { replace: true }));
        }
    }, [historyState?.workflowId, apptStatusSet]);

    const [createFollowUpForm, createFollowUpFormMutation] = useMutationWithExpiration(CREATE_FORM_BY_TEMPLATE, {
        variables: {
            form: {
                activityId: activityId,
                providerId: effectiveProviderData?.providerId ?? -1,
                createdBy: effectiveProviderData?.providerId?.toString() ?? '',
                sessionId: sessionId
            },
            templateName: encodeURIComponent(TemplateNames.FollowUp)
        }
    });

    const [createClosureReportFormOnLastAppointment, createClosureReportFormOnLastAppointmentMutation] = useMutationWithExpiration(CREATE_CLOSURE_REPORT, {
        variables: {
            form: {
                activityId: activityId,
                providerId: effectiveProviderData?.providerId ?? -1,
                createdBy: effectiveProviderData?.providerId?.toString() ?? '',
                sessionId: sessionId
            },
            templateName: encodeURIComponent(WspTemplateNames.ClosureAssessmentReport_WRP)
        }
    });

    useEffect(() => {
        if (wasThisLastAppt) {
            createFollowUpForm();
            if(activity?.hasEligibleWSPReportService)
            {
                createClosureReportFormOnLastAppointment();
            }
        }
    }, [wasThisLastAppt]);

    const forms = afterUpdateActivityData?.forms;

    const preAssessmentsCompleted = useMemo(() => 
        !GetFirstAssessmentFormsIncomplete(forms ?? [], afterUpdateActivityData?.firstAppointmentAssessmentFormNames ?? [])
    , [forms, afterUpdateActivityData?.firstAppointmentAssessmentFormNames]);

    const postAssessmentsCompleted = useMemo(() => 
        !GetLastAssessmentFormsIncomplete(forms ?? [], afterUpdateActivityData?.lastAppointmentAssessmentFormNames ?? [])
    , [forms, afterUpdateActivityData?.lastAppointmentAssessmentFormNames]);

    const handleStatusUpdate = async (values: ApptStatusFormValues) => {
        let message = '';

        if (values.appointmentStatusId === SESSION_STATUS.ATTENDED) {
            const duration = durationConstants?.find(key => key.value === transformHoursToDurationKey(values.duration))?.title;
            message = t('confirm_appt_status_attended', { replace: { duration, date: formattedSessionDateTime, clientName }});
        } else if (values.appointmentStatusId === SESSION_STATUS.CANCELLED_PROVIDER) {
            const voidReason = apptVoidReasons?.find(reason => reason.value === values.providerVoidReasonId?.toString())?.title;
            message = t('confirm_appt_status_voided', { replace: { voidReason, date: formattedSessionDateTime, clientName }});
        } else {
            const status = apptStatuses?.find(status => status.value === values.appointmentStatusId.toString())?.title;
            message = t('confirm_appt_status_other', { replace: { status, date: formattedSessionDateTime, clientName }});
        }

        const messageElement = <span dangerouslySetInnerHTML={{__html: message}}/>;

        if (effectiveProviderData?.providerId && await confirm({message: messageElement})) {           
            return updateStatus({
                variables: {
                    activityId: activityId,
                    providerId: effectiveProviderData.providerId,
                    duration: values.duration,
                    sessionId: sessionId,
                    statusId: values.appointmentStatusId,
                    voidReasonId: values.providerVoidReasonId,
                    wspTaskCode: '',
                    hourlyWorkflowEnabled: isHourlyWorkflowFeatureEnabled(),
                    isHourlyContract: activity.isHourlyContract,
                    voidSpecifyReason: apptVoidReasons?.find(r => r.value === values.providerVoidReasonId?.toString())?.title,
                    additionalInfo: values.additionalInfo
                }
            });
        }
    }

    const renderCurrentStep = () => {
        if (afterUpdateActivityData) {
            if (wasThisLastAppt === undefined) {
                return <LastAppointmentSelector value={wasThisLastAppt} onChange={setWasThisLastAppt} disabled={false}/>
            } else {
                return <>
                    <h2 aria-live="polite">{t('next_steps_header')}</h2>
                    <p>{t('next_steps_sub_header')}</p>
                    <AfterApptStatusUpdatedPrompt activityId={activityId} sessionId={sessionId}
                        hasFirstAssessment={hasCompletedSession} hasLastAssessment={hasCompletedSession && wasThisLastAppt === true}
                        firstAssessmentCompleted={preAssessmentsCompleted} lastAssessmentCompleted={postAssessmentsCompleted}
                        // firstAssessmentCreationPending={createFirstAssessmentFormsMutation.loading}
                        firstAssessmentCreationPending={false}
                        lastAssessmentCreationPending={createFollowUpFormMutation.loading}
                        closureReportCreationPending = {createClosureReportFormOnLastAppointmentMutation.loading}
                        hasScheduleNextAppt={!(wasThisLastAppt ?? true)} canBookSession={afterUpdateActivityData.sessionBooking.allowed}
                        isHourlyContract={activity?.isHourlyContract ?? false}
                        hasSessionNote={session?.sessionStatusId === SESSION_STATUS.ATTENDED}
                        enableGad7={activity?.administerGad7 ?? false }
                        PHQ9FormId={pendingPHQ9FormId}
                        Gad7FormId = {pendingGad7FormId}
                    />
                </>
            }
        } else {
            return <>
                <h2 aria-live="polite">{t('update_status_and_duration_of_the_appointment_with',
                    {clientFullName: clientName, appointmentDateTime: formattedSessionDateTime})}</h2>
                <ApptStatusForm activityId={activityId}
                    session={session} disabled={session?.sessionStatusId !== SESSION_STATUS.ACTIVE}
                    submit={[handleStatusUpdate, updateStatusMutation.loading]}
                    mutation={[updateStatus, updateStatusMutation]}
                    apptStatuses={apptStatuses}
                    durationConstants={durationConstants}
                    apptVoidReasons={apptVoidReasons}
                    isStaffProvider={isStaffProvider}
                    enableAdditionalInputs={activity?.isWSPActivity && isExtendedUpdateApptStatusFeatureEnabled()}
                    timeZone={activity?.provider?.details?.timezoneOlsonName}
                />
            </>
        }
    }

    const dataLoading = !session || activityDataQueryLoading || !translationsReady
        || apptStatusesLoading || durationConstantsLoading || apptVoidReasonsLoading
        || closeToDoMutation.loading;

    return (
        <CaseManagementActionContainer onBackClick={() => navigate(-1)} activityId={activityId}
            className="update_appt_status_view_wrapper" clientName={clientName}
        >
            {
                translationsReady && 
                <PageMetadata 
                    pageDescription={t('appt_status_page_description')} 
                    pageTitle={t('appt_status_page_title')}
                />
            }
            {
                dataLoading
                    ? <Spinner fillParent/>
                    : renderCurrentStep()
            }
            {
                updateStatusMutation.loading &&
                <Spinner fillParent backdrop/>
            }
        </CaseManagementActionContainer>
    );
}
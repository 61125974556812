import { gql, TypedDocumentNode } from '@apollo/client';
import { OrganizationException, OrganizationExceptionInput } from '../../../interfaces';

export const EXCEPTIONS_REF: TypedDocumentNode<ExceptionsRefData, ExceptionsRefVars> = gql`
    query EXCEPTIONS_REF {
        refValues {
            exceptionTypes {
                title
                value
            }
        }
    }
`

export interface ExceptionsRefData {
    refValues: {
        exceptionTypes: {
            title: string,
            value: string
        }[]
    }
}

export interface ExceptionsRefVars {}


export const ORGANIZATIONS_REF: TypedDocumentNode<OrganizationsRefData, OrganizationsRefVars> = gql`
    query ORGANIZATIONS_REF($searchInput: String) {
        refValues {
            organizations(searchInput: $searchInput) {
                title
                value
            }
        }
    }
`

export interface OrganizationsRefData {
    refValues: {
        organizations: {
            title: string,
            value: string
        }[]
    }
}

export interface OrganizationsRefVars {  
    searchInput: string
}

export const UPDATE_EXCEPTIONS_MUTATION: TypedDocumentNode<UpdateExceptionsMutationData, UpdateExceptionsMutationVars> = gql`
    mutation UPDATE_EXCEPTIONS_MUTATION($providerId: Long!, $organizationExceptions: [OrganizationExceptionInput]) {
        updateOrgExceptions(providerId: $providerId, organizationExceptions: $organizationExceptions) {
            providerId
            details {
                organizationExceptions {
                    organizationExceptionId
                    exceptionTypeCode
                    exceptionTypeString
                    organizationID
                    organizationString
                    isPermanent
                    startDate
                    endDate
                    comments
                }
            }
        }
    }
`

export interface UpdateExceptionsMutationData {
    updateOrgExceptions: {
        details: {
            organizationExceptions: OrganizationException[]
        }
    }
}

export interface UpdateExceptionsMutationVars {  
    providerId: number,
    organizationExceptions: OrganizationExceptionInput[]
}
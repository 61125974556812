import React, { FC } from 'react';
import { Root, createRoot } from 'react-dom/client';
import { MenuItem, Popper, MenuList, Paper } from '@mui/material';
import { EventType } from '../../interfaces';
import { useTranslation } from 'react-i18next';

interface EventTypeMenuProps {
    resolve: (result?: any) => void,
    reject: () => void,
    anchorEl: any
    canBookAppointments: boolean
}

export const EventTypeMenu: FC<EventTypeMenuProps> = (props) => {
    const { t } = useTranslation('calendaring', { useSuspense: false });

    const handleAddUnavailabilityClick = () => {
        props.resolve(EventType.Unavailability);
    }

    const handleAddAppointmentClick = () => {
        props.resolve(EventType.Reservation);
    }

    const handleKeyDown = (event: React.KeyboardEvent<HTMLUListElement>) => {
        if (event.key === 'Escape' || event.key === 'Tab') {
            props.resolve();
        }
    }

    return (
        <Popper className="calendar_booking_popover" id="calendar_booking_popover" open={Boolean(props.anchorEl)} anchorEl={props.anchorEl} placement="bottom-start" style={{ zIndex: 1000 }} >
            <Paper elevation={3}>
                <MenuList onKeyDown={handleKeyDown}>
                    <MenuItem onClick={handleAddUnavailabilityClick} autoFocus>{t('unavailability')}</MenuItem>
                    {
                        props.canBookAppointments && <MenuItem onClick={handleAddAppointmentClick}>{t('appointment')}</MenuItem>
                    }
                </MenuList>
            </Paper>
        </Popper>
    )
}


interface EventTypeMenuIntance {
    container: HTMLDivElement,
    resolve: (result?: any) => void,
    reject: () => void,
    clickOutsideHandler: EventListener
}

interface ISelectedEventProps {
    anchorEl: any;
    canBookAppointments: boolean;
}

let existingInstance: EventTypeMenuIntance | null = null;
let root: Root | null = null;

export const selectEventType: (props: ISelectedEventProps) => Promise<string> = (props) => {
    const cleanUp = (instance: EventTypeMenuIntance) => {
        document.removeEventListener('touchend', instance.clickOutsideHandler);
        document.removeEventListener('click', instance.clickOutsideHandler);
        root?.unmount();
        root = null;
        if (document.body.contains(instance.container)) {
            document.body.removeChild(instance.container);
        }
    }

    if (existingInstance) {
        cleanUp(existingInstance);
        existingInstance.resolve();
    }


    const container = document.createElement('div');
    document.body.appendChild(container);

    const promise = new Promise<string>((resolve, reject) => {
        const instance: EventTypeMenuIntance = {
            container: container,
            resolve: resolve,
            reject: reject,
            clickOutsideHandler: (event) => {
                if (!instance.container.contains(event.target as Node)) {
                    cleanUp(instance);
                    instance.resolve();
                }
            }
        }

        existingInstance = instance;

        const onReject = () => {
            cleanUp(instance);
            reject();
        }

        const onResolve = (arg) => {
            cleanUp(instance);
            resolve(arg);
        }

        try {
            root = createRoot(container);
            root.render(
                <EventTypeMenu resolve={onResolve} reject={onReject} anchorEl={props.anchorEl} canBookAppointments={props.canBookAppointments} />
            );
            setTimeout(()=> {
                if ('ontouchend' in window) {
                    document.addEventListener('touchend', instance.clickOutsideHandler)
                } else {
                    document.addEventListener('click', instance.clickOutsideHandler)
                }
            }, 0);
        } catch (e) {
            console.error(e);
            throw e;
        }
    });

    return promise;
}

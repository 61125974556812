import { gql, TypedDocumentNode } from "@apollo/client";

export const PROVIDER_INFO_REF_DATA: TypedDocumentNode<ProviderInfoRefDataData, ProviderInfoRefDataVars> = gql`
query PROVIDER_INFO_REF_DATA {
    refValues {
        disabilities {
            title
            value
        }
        ethnicities {
            title
            value
        }
        genders {
            title
            value
        }
        languages {
            title
            value
        }
        religions {
            title
            value
        }
        sexualOrientations {
            title
            value
        }
        veteranStatuses {
            title
            value
        }
    }
}
`

interface ProviderInfoRefDataData {
    refValues: {
        disabilities: {
            title: string,
            value: string
        }[],
        genders: {
            title: string,
            value: string
        }[],
        ethnicities: {
            title: string,
            value: string
        }[],
        languages: {
            title: string,
            value: string
        }[],
        religions: {
            title: string,
            value: string
        }[],
        sexualOrientations: {
            title: string,
            value: string
        }[],
        veteranStatuses: {
            title: string,
            value: string
        }[]
    }
}

interface ProviderInfoRefDataVars {}

export const UPDATE_PROVIDER_INFO_MUTATION: TypedDocumentNode<UpdateProviderInfoMutationData, UpdateProviderInfoMutationVars> = gql`
    mutation UPDATE_PROVIDER_DETAILS($providerId: Long!, $gender: String, $ethnicityCode: String, $religionCode: String, $sexualOrientation: Int, $disability: Int, $veteranStatus: Int) {
        updateProviderDetails(providerId: $providerId, gender: $gender, ethnicityCode: $ethnicityCode, religionCode: $religionCode, sexualOrientation: $sexualOrientation, disability: $disability, veteranStatus: $veteranStatus) {
            providerId
            details {
                disability
                disabilityString
                ethnicityCode
                ethnicityString
                gender
                genderString
                religionCode
                religionString
                sexualOrientation
                sexualOrientationString
                showVeteranStatus
                veteranStatus
                veteranStatusString
            }
        }
    }
`

interface UpdateProviderInfoMutationData {
    updateProviderDetails: {
        details: {
            disability?: number,
            disabilityString?: string,
            ethnicityCode?: string,
            ethnicityString?: string,
            gender?: string,
            genderString?: string,
            religionCode?: string,
            religionString?: string,
            sexualOrientation?: number,
            sexualOrientationString?: string,
            showVeteranStatus: boolean,
            veteranStatus?: number,
            veteranStatusString?: string
        }
    }
}

interface UpdateProviderInfoMutationVars {
    providerId: number,
    gender?: string,
    ethnicityCode?: string,
    religionCode?: string,
    sexualOrientation?: number,
    disability?: number,
    veteranStatus?: number
}
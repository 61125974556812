import React, { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { OfficeAddress } from '../../../interfaces';
import { AddressForm } from '../../Forms/AddressForm';
import { PhoneForm } from '../../Forms/PhoneForm';
import { SectionContainer } from '../../Other/SectionContainer';
import { isFuture, isPast } from 'utils/dateUtils';
import { Alert } from '@mui/material';

interface OfficeLocationsProps {
    addresses?: OfficeAddress[]
}

export const OfficeLocations: FC<OfficeLocationsProps> = (props) => {
    const { addresses } = props;
    
    const { t, ready: translationsReady } = useTranslation('providerprofile', { useSuspense: false });

    const officeLocations = useMemo(() => addresses?.filter(address => {
        if (address.officeType === 'MAIL') return false;

        // default to show in case of missing dates
        if (!address.addressEffectiveDate && !address.addressExpiryDate) return true;

        if (address.addressEffectiveDate && isFuture(new Date(address.addressEffectiveDate))) return false;
        if (address.addressExpiryDate && isPast(new Date(address.addressExpiryDate))) return false;
        return true;
    }), [addresses]);

    const changeInformationNoteContent = t('office_locations__note');

    return !translationsReady
        ? <></>
        : <SectionContainer title={t('office_locations__section_title')}>
            {
                changeInformationNoteContent &&
                <Alert severity="info">{changeInformationNoteContent}</Alert>
            }
            {
                officeLocations?.length 
                    ? officeLocations.map((location, index) => (
                        <React.Fragment key={index}>
                            <h2>{`${t('office_locations__location_header')} ${index+1}`}</h2>
                            <h3>{t('office_locations__address_header')}</h3>
                            <AddressForm address={location}/>
                            {
                                location.addressPhone?.length
                                    ? <>
                                        <h3>{t('office_locations__phone_header')}</h3>
                                        {
                                            location.addressPhone.map((phone, index) => (
                                                <PhoneForm phone={phone} key={index}/>
                                            ))
                                        }
                                    </>
                                    : <></>
                            }
                        </React.Fragment>
                    ))
                    : <p>{t('n_a','N/A')}</p>
            }
        </SectionContainer>
}
import React, { FC, useRef } from 'react';
import { IconButton, InputAdornment, TextField } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/CloseRounded';
import { useTranslation } from 'react-i18next';

import './SearchInput.scss';

interface SearchInputProps {
    value: string,
    onChange: (value: string) => void,
    label: string,
    placeholder: string,
    helperText?: string,
    ariaLabel?: string
}

export const SearchInput: FC<SearchInputProps> = (props) => {
    const { value, onChange, label, placeholder, helperText, ariaLabel } = props;
    const inputRef = useRef<HTMLInputElement>(null);
    const { t } = useTranslation('common', { useSuspense: false });

    const handleClearInput = () => {
        onChange('');
        inputRef.current?.focus();
    }

    return (
        <div aria-label={ariaLabel} className="search_input_wrapper" role="search">
            <TextField variant="outlined"
                value={value}
                onChange={(event) => onChange(event.target.value)}
                className="search_input_textfield"
                inputProps={{"aria-label": label}}
                inputRef={inputRef}
                helperText={helperText}
                InputProps={{
                    startAdornment: <InputAdornment position="start" children={<SearchIcon />} />,
                    endAdornment: value
                        ? <InputAdornment position="end">
                            <IconButton aria-label={t("clear_input")} onClick={handleClearInput}><CloseIcon/></IconButton>
                        </InputAdornment>
                        : <span className="search_input_fake_placeholder" aria-hidden>
                            {placeholder}
                        </span>
                }}
            />
        </div>
    )
}

import React, { FC } from "react";
import { FormikErrors, useFormik } from 'formik';
import { Container, FormControl, FormControlLabel, FormLabel, Grid, Radio, RadioGroup, Alert } from "@mui/material";
import { Button } from 'components/Shared/Inputs/Buttons';
import './style.scss';
import { useTranslation } from "react-i18next";
interface AUDIT_C_Props {
    onSubmit: (values: AUDIT_C_Result) => void,
    result: AUDIT_C_FormValues | null
}

interface AUDIT_C_FormValues {
    how_often_drinks_alcohol: number | undefined,
    how_many_units_of_alcohol_per_day: number | undefined,
    how_often_6_or_more: number | undefined
}

interface AUDIT_C_Result extends AUDIT_C_FormValues {
    score: number
}

export const AUDIT_C: FC<AUDIT_C_Props> = (props) => {
    const { result, onSubmit } = props;
    
    const { t, ready } = useTranslation('forms', { useSuspense: false });

    const calculateScore = (values: AUDIT_C_FormValues) => (
        Object.keys(values).reduce((score, question) => {
            score += Number(values[question] ?? 0);
            return score;
        }, 0)
    )
    
    const formik = useFormik<AUDIT_C_FormValues>({
        initialValues: {
            how_often_drinks_alcohol: result?.how_often_drinks_alcohol,
            how_many_units_of_alcohol_per_day: result?.how_many_units_of_alcohol_per_day,
            how_often_6_or_more: result?.how_often_6_or_more
        },
        validate: (values) => {
            const errors: FormikErrors<AUDIT_C_FormValues> = {};

            for (const key in values) {
                if (values[key] === undefined) {
                    errors[key] = 'Select answer'
                }
            }

            return errors;
        },
        validateOnMount: true,
        validateOnChange: true,
        onSubmit: (values) => {
            onSubmit({
                ...values,
                score: calculateScore(values)
            });
        }
    });

    const handleChange = (event, value) => {
        formik.setFieldValue(event.target.name, Number(value));
    }

    const score = !formik.isValid
        ? undefined
        : calculateScore(formik.values);

    return !ready
        ? <></>
        : <Container maxWidth="md" component="form" onSubmit={formik.handleSubmit} className="assessment_container audit_c_assessment">
            <h1 className="assessment_name">{t('AUDIT_C__title')}</h1>
            <div className="description_container">
                <Alert severity="info" className="scoring_description">
                    <span className="drinks_description_header">{t('AUDIT__description__description_header')}</span>
                    <Grid container>
                        <Grid item md={4} sm={12} className="drinks_description_container">
                            <span>{t('AUDIT__description__spirits_label')}</span>
                            <ul dangerouslySetInnerHTML={{__html: t('AUDIT__description__spirits')}}></ul>
                        </Grid>
                        <Grid item md={4} sm={12} className="drinks_description_container">
                            <span>{t('AUDIT__description__beer_label')}</span>
                            <ul dangerouslySetInnerHTML={{__html: t('AUDIT__description__beer')}}></ul>
                        </Grid>
                        <Grid item md={4} sm={12} className="drinks_description_container">
                            <span>{t('AUDIT__description__wine_label')}</span>
                            <ul dangerouslySetInnerHTML={{__html: t('AUDIT__description__wine')}}></ul>
                        </Grid>
                    </Grid>
                </Alert>
            </div>
            <div className="questions_list_container">
                <FormControl component="fieldset" className="question_fieldset">
                    <FormLabel component="legend" className="question_text">1. {t('AUDIT__question__how_often_drinks_alcohol')}</FormLabel>
                    <RadioGroup name="how_often_drinks_alcohol" className="question_radiogroup"
                        value={formik.values.how_often_drinks_alcohol?.toString()} onChange={handleChange}>
                        <FormControlLabel value="0" control={<Radio color="primary"/>} label={t('AUDIT__answer__never') as string}/>
                        <FormControlLabel value="1" control={<Radio color="primary"/>} label={t('AUDIT__answer__monthly_or_less') as string}/>
                        <FormControlLabel value="2" control={<Radio color="primary"/>} label={`2-4 ${t('AUDIT__answer__times_per_month')}`}/>
                        <FormControlLabel value="3" control={<Radio color="primary"/>} label={`2-3 ${t('AUDIT__answer__times_per_week')}`}/>
                        <FormControlLabel value="4" control={<Radio color="primary"/>} label={`4+ ${t('AUDIT__answer__times_per_week')}`}/>
                    </RadioGroup>
                </FormControl>
                <FormControl component="fieldset" className="question_fieldset">
                    <FormLabel component="legend" className="question_text">2. {t('AUDIT__question__how_many_units_of_alcohol_per_day')}</FormLabel>
                    <RadioGroup name="how_many_units_of_alcohol_per_day" className="question_radiogroup"
                        value={formik.values.how_many_units_of_alcohol_per_day?.toString()} onChange={handleChange}>
                        <FormControlLabel value="0" control={<Radio color="primary"/>} label="1-2" />
                        <FormControlLabel value="1" control={<Radio color="primary"/>} label="3-4" />
                        <FormControlLabel value="2" control={<Radio color="primary"/>} label="5-6" />
                        <FormControlLabel value="3" control={<Radio color="primary"/>} label="7-9" />
                        <FormControlLabel value="4" control={<Radio color="primary"/>} label="10+" />
                    </RadioGroup>
                </FormControl>
                <FormControl component="fieldset" className="question_fieldset">
                    <FormLabel component="legend" className="question_text">3. {t('AUDIT_C__question__how_often_6_or_more')}</FormLabel>
                    <RadioGroup name="how_often_6_or_more" className="question_radiogroup"
                        value={formik.values.how_often_6_or_more?.toString()} onChange={handleChange}>
                        <FormControlLabel value="0" control={<Radio color="primary"/>} label={t('AUDIT__answer__never') as string}/>
                        <FormControlLabel value="1" control={<Radio color="primary"/>} label={t('AUDIT__answer__monthly_or_less') as string}/>
                        <FormControlLabel value="2" control={<Radio color="primary"/>} label={t('AUDIT__answer__monthly') as string}/>
                        <FormControlLabel value="3" control={<Radio color="primary"/>} label={t('AUDIT__answer__weekly') as string}/>
                        <FormControlLabel value="4" control={<Radio color="primary"/>} label={t('AUDIT__answer__daily_or_almost_daily') as string}/>
                    </RadioGroup>
                </FormControl>
            </div>
            {
                score !== undefined && (
                    score <= 2
                        ? <Alert severity="info" className="summary">
                            {t('AUDIT_C__score_label__severity_0', { replace: { score: score }})}
                        </Alert>
                        : score === 3
                            ? <Alert severity="warning" className="summary">
                                {t('AUDIT_C__score_label__severity_1', { replace: { score: score }})}
                            </Alert>
                            : <Alert severity="warning" className="summary">
                                {t('AUDIT_C__score_label__severity_2', { replace: { score: score }})}
                            </Alert>
                )
            }
            <div className="buttons_container">
                <Button type="submit" disabled={!formik.isValid}>
                    {t('common:submit')}
                </Button>
            </div>
        </Container>
}
import React from 'react'
import { ReactComponent as TeleCounsellingImage } from 'assets/images/calendar/modalities/ic_call_outline.svg';
import { ReactComponent as VideoCousellingImage } from 'assets/images/calendar/modalities/ic_video_outline.svg';
import { ReactComponent as InPersionCounsellingImage } from 'assets/images/calendar/modalities/ic_user_outline.svg';
import { SvgIcon } from '@mui/material';
import { IN_PERSON_COUNSELLING_MODALITY_CODE, TELE_COUNSELLING_MODALITY_CODE, VIDEO_COUNSELLING_MODALITY_CODE } from 'constants/modalities'

export const renderEventMatchingIcon = (modalityCode: string) => {
    switch(modalityCode) {
        case VIDEO_COUNSELLING_MODALITY_CODE:
            return <SvgIcon component={VideoCousellingImage}/>
        case TELE_COUNSELLING_MODALITY_CODE:
            return <SvgIcon component={TeleCounsellingImage}/>
        case IN_PERSON_COUNSELLING_MODALITY_CODE:
            return <SvgIcon component={InPersionCounsellingImage}/>
        default:
            return ''
    }
}
import { useMutation } from "@apollo/client";
import { getOperationName } from "@apollo/client/utilities";

import { APOLLO_CACHE_CLEAR_EVENT } from "./ApolloProvider";
import { TUseMutationWithExpiration } from "./interfaces";

export const useMutationWithExpiration: TUseMutationWithExpiration = (mutation, options) => {
    const { mutationKey, ...hookOptions } = options || {};
    const [mutate, mutationResult] = useMutation(mutation, hookOptions);
    const mutationEventName = mutationKey || getOperationName(mutation) || "UNKNOWN_MUTATION";

    const mutateWithCacheClearEvent = async (options) => {
        const isQATesting = !!window.sessionStorage.getItem("apolloCacheLife");
        return mutate(options).then(data => {
            const mutationEvent = new CustomEvent(APOLLO_CACHE_CLEAR_EVENT, { detail: mutationEventName });
            isQATesting && console.info("🍔🍔🍕 dispatching custom event", mutationEventName, mutationEvent);
            window.dispatchEvent(mutationEvent);
            return data;
        });
    }
    return [mutateWithCacheClearEvent, mutationResult];
};

import { useFormik } from "formik";
import React, { FC, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { DatePreference, FilterOptions, TimePreference } from "../interfaces";
import { Select, DateInput } from 'components/Shared/Inputs';
import { APPT_BOOKING_REF } from '../../../queries';
import moment from "moment";
import { useQuery } from "@apollo/client";
import { Grid, IconButton, MenuItem, Popover, SelectChangeEvent } from "@mui/material";
import { IAddress } from "../../../interfaces";
import CloseIcon from '@mui/icons-material/Close';
import { Button } from 'components/Shared/Inputs/Buttons';
import './style.scss';
import { DATE_FORMATS } from "hooks/useTimezoneUtils";

interface SchedulesFilterDialogProps {
    open: boolean,
    onClose: () => void,
    filterOptions: FilterOptions,
    onFiltersChange: (filterOptions: FilterOptions) => void,
    offices: IAddress[] | undefined,
    anchorEl: any
}

interface SchedulesFilterDialogFormValues extends FilterOptions {}

export const SchedulesFilterDialog: FC<SchedulesFilterDialogProps> = (props) => {
    const { open, onClose, filterOptions, onFiltersChange, offices, anchorEl } = props;

    const { t } = useTranslation(['calendaring', 'common'], { useSuspense: false });

    const formik = useFormik<SchedulesFilterDialogFormValues>({
        initialValues: {
            ...filterOptions
        },
        enableReinitialize: true,
        onSubmit: onFiltersChange
    });

    const refDataQuery = useQuery(APPT_BOOKING_REF);
    const datePreferencesRef = refDataQuery.data?.refValues.datePreferences;
    const timePreferencesRef = refDataQuery.data?.refValues.timePreferences;

    const handleStartDateChange = (date?: moment.Moment | null) => {
        formik.setFieldValue('startDate', date?.format('YYYY-MM-DD'));
    }

    const handleStartTimeChange = (event: SelectChangeEvent<unknown>) => {
        formik.setFieldValue('startTime', event.target.value);
    }

    const handleOfficeChange = (event: SelectChangeEvent<unknown>) => {
        formik.setFieldValue('officeId', event.target.value === 'any' ? null : event.target.value);
    }

    const datePreferenceValue = datePreferencesRef ? formik.values.datePreference : '';
    const timePreferenceValue = timePreferencesRef ? formik.values.timePreference : '';

    const renderFilterByDate = () => (
        <Grid container spacing={2} className="date_time_filters_section">
            <Grid item xs={12}>
                <h3 className="date_time_filters_section_label">{t('filter_by_date', 'Filter by Date')}</h3>
            </Grid>
            <Grid item xl={6} xs={12}>
                <Select
                    value={datePreferenceValue}
                    onChange={formik.handleChange}
                    label={t('date_preferences')}
                    name="datePreference"
                    className="date-time-filter-input"
                >
                    {
                        datePreferencesRef?.map(ref => 
                            <MenuItem value={ref.value} key={ref.value}>{ref.title}</MenuItem>
                        )
                    }
                </Select>
            </Grid>
            <Grid item xl={6} xs={12}>
                <DateInput
                    // disableToolbar
                    // variant="inline"
                    format="L"
                    // autoOk
                    disabled={formik.values.datePreference === DatePreference.any}
                    label={t('date')}
                    value={moment(formik.values.startDate, DATE_FORMATS.ISO)}
                    onChange={handleStartDateChange}
                    // KeyboardButtonProps={{
                    //     'aria-label': 'change date',
                    // }}
                    // cancelLabel={t('common:cancel')}
                    // okLabel={t('common:ok')}
                    // invalidDateMessage={t('common:invalid_date_format')}
                    className="date-time-filter-input"
                />
            </Grid>
        </Grid>
    )

    const timeInputDisabled = formik.values.timePreference === TimePreference.any
        || formik.values.timePreference === TimePreference.aftn
        || formik.values.timePreference === TimePreference.mor
        || formik.values.timePreference === TimePreference.eve;

    const locale = moment.locale();

    const timeInputOptions = useMemo(() => {
        const time = moment().startOf('day');
        const options: { title: string, value: string }[] = [];

        for (let i = 0; i < 24; ++i) {
            options.push({
                title: time.format('LT'),
                value: time.format('HH:mm')
            });
            time.add(1, 'hour');
        }
        return options;
    }, [locale])

    const renderFilterByTime = () => (
        <Grid container spacing={2} className="date_time_filters_section">
            <Grid item xs={12}>
                <h3 className="date_time_filters_section_label">{t('filter_by_time', 'Filter by Time')}</h3>
            </Grid>
            <Grid item xl={6} xs={12}>
                <Select
                    value={timePreferenceValue}
                    onChange={formik.handleChange}
                    label={t('time_preferences')}
                    name="timePreference"
                    className="date-time-filter-input"
                >
                    {
                        timePreferencesRef?.map(ref => 
                            <MenuItem value={ref.value} key={ref.value}>{ref.title}</MenuItem>
                        )
                    }
                </Select>
            </Grid>
            <Grid item xl={6} xs={12}>
                <Select
                    value={formik.values.startTime}
                    onChange={handleStartTimeChange}
                    name="startTime"
                    disabled={timeInputDisabled}
                    label={t('time')}
                    className="date-time-filter-input"
                >
                    {
                        timeInputOptions.map(time => 
                            <MenuItem key={time.value} value={time.value}>{time.title}</MenuItem>
                        )
                    }
                </Select>
            </Grid>
        </Grid>
    )

    
    const officesOptions = useMemo(() => 
        offices
            ?.filter(office => office.officeType !== "MAIL")
            ?.map(office => ({ title: office.addressLine1, value: office.providerAddressId }))
    , [offices]);

    const renderOffice = () => (
        <Grid container spacing={2} className="date_time_filters_section">
            <Grid item xs={12}>
                <h3 className="date_time_filters_section_label">{t('filter_by_office', 'Filter by Office')}</h3>
            </Grid>
            <Grid item xs={12}>
                <Select
                    value={formik.values.officeId ?? 'any'}
                    onChange={handleOfficeChange}
                    name="officeId"
                    label={t('office')}
                    className="date-time-filter-input"
                >
                    <MenuItem value="any" key="any">{t('common:any')}</MenuItem>
                    {
                        officesOptions?.map(office =>
                            <MenuItem value={office.value} key={office.value}>{office.title}</MenuItem>
                        )
                    }
                </Select>
            </Grid>
        </Grid>
    )

    return (
        <Popover hideBackdrop={false} open={open} keepMounted BackdropProps={{ invisible: false }} className="date_time_filters_popover"
            anchorEl={anchorEl} transformOrigin={{horizontal: "right", vertical: "top"}}>
            <div className="date_time_filters_header">
                <h2 className="global__header3">{t('filters','Filters')}</h2>
                <IconButton className="close_button" onClick={onClose}><CloseIcon /></IconButton>
            </div>
            <div className="date_time_filters_content">
                {renderFilterByDate()}
                {renderFilterByTime()}
                {renderOffice()}
                <Grid container spacing={2} className="date_time_filters_section">
                    <Grid item xs={6}>
                        <Button variant="text" onClick={onClose} className="date-time-filter-input">
                            {t('common:cancel', 'Cancel')}
                        </Button>
                    </Grid>
                    <Grid item xs={6}>
                        <Button variant="opaque" type="submit" disabled={false} onClick={formik.submitForm} className="date-time-filter-input">
                            {t('common:continue', 'Continue')}
                        </Button>
                    </Grid>
                </Grid>
            </div>
        </Popover>
    )
}
import { useQuery } from '@apollo/client';
import { useAuth } from 'components/Auth';
import { INVOICES_LIST_QUERY, InvoicesListQueryData, InvoicesListQueryVars } from '../queries';

export const useInvoicesListQuery = () => {
    const { effectiveProviderData } = useAuth();

    return useQuery<InvoicesListQueryData, InvoicesListQueryVars> (
        INVOICES_LIST_QUERY, {
            fetchPolicy: 'network-only',
            variables: { providerId: effectiveProviderData?.providerId ?? -1 },
            errorPolicy: 'all'
        }
    );
}
import React, { FC, useEffect, useMemo, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { TemplateNames } from '../../constants';
import { getFirstSession } from 'components/CaseManagement/utils/sessionUtils';
import { useMutation, useQuery } from '@apollo/client';
import { CREATE_FORM_BY_TEMPLATE, GET_FORMS_WITH_PREASSESSMENTS_BY_ACTIVITY, UPDATE_FORM } from '../../queries';
import { PROVIDER_HUB } from 'constants/targetedSystem';
import { FormViewContainer } from '../../components/FormViewContainer';
// import { useTranslation } from 'react-i18next';
import { TERMINATE_FIRST_APPOINTMENT_ASSESSMENT } from 'components/ToDos/queries';
import { Spinner } from 'components/Shared';
import { FormPresenter } from '../../components/FormPresenter';
import { useAuth } from 'components/Auth';
import { useTimezoneUtils } from 'hooks/useTimezoneUtils';
import { IFormInfo } from '../../interfaces';
import { AssessmentPage } from '../../interfaces';
import { AfterAssessmentCompletedPrompt } from '../../components/AfterAssessmentCompletedPrompt';
import { FullScreenMessage } from 'components/Shared/FullScreenMessage';
import { Button } from 'components/Shared/Inputs';
import { useTranslation } from 'react-i18next';
import { appendPreAssessmentResults } from 'components/Forms/components/Forms/formUtils';
import { IPreAssessment } from 'components/Forms/interfaces';
import { removeDuplicateSOU } from 'components/CaseManagement/utils/formStatusUtils';

import './Assessments.scss';

interface FirstAppointmentAssessmentViewProps { }

export const FirstAppointmentAssessmentView: FC<FirstAppointmentAssessmentViewProps> = (_) => {
    const { activityId: activityIdString } = useParams<{activityId: string}>();
    const { providerData, effectiveProviderData } = useAuth();
    const { formatDate } = useTimezoneUtils();
    const { t } = useTranslation('assessments', { useSuspense: false });

    const [forms, setForms] = useState<IFormInfo[] | undefined>(undefined);
    const [pages, setPages] = useState<AssessmentPage[]>([]);
    const [currentPageIdx, setCurrentPageIdx] = useState(0);
    
    const activityId = parseInt(activityIdString || '');
    
    // const navigate = useNavigate();
    
    const { state } = useLocation();

    const activityDataQuery = useQuery(GET_FORMS_WITH_PREASSESSMENTS_BY_ACTIVITY, {
        variables: {
            activityId: activityId,
            tags: ['first_appointment']
        },
        errorPolicy: 'all',
        fetchPolicy: 'no-cache',
        onCompleted: (data) => {
            setForms(data.forms.filter(removeDuplicateSOU(data.activity.firstAppointmentAssessmentFormNames)));
            setPages(data.activity.firstAppointmentAssessmentFormNames
                .filter(assessment => assessment.mandatory)
                .filter(removeDuplicateSOU(data.activity.firstAppointmentAssessmentFormNames))
                .map<AssessmentPage>(assessment => ({
                    templateName: assessment.templateName,
                    mandatory: assessment.mandatory,
                    completed: false
                })));
        }
    });

    const [terminateToDo] = useMutation(TERMINATE_FIRST_APPOINTMENT_ASSESSMENT, {
        context: { skipGlobalErrorHandling: true },
        onError: (error) => {
            console.error(error);
        }
    });

    const [createForm, createFormMutation] = useMutation(CREATE_FORM_BY_TEMPLATE, {
        onCompleted: (data) => {
            setForms([...(forms ?? []), data.createFormByTemplate]);
        }
    });

    const [updateForm, updateFormMutation] = useMutation(UPDATE_FORM, {
        onCompleted: (data) => {
            const newForms = data.updateForm.administeredForms;
            if (newForms?.length) {
                setForms([...(forms ?? []), ...newForms]);

                const newPages = newForms.map(form => ({
                    templateName: form.templateName,
                    mandatory: true,
                    completed: false
                }));

                const pivot = currentPageIdx + 1;
                setPages([
                    ...pages.slice(0, pivot),
                    ...newPages,
                    ...(pivot < pages.length ? pages.slice(pivot) : [])
                ])
            }
        }
    });

    const activity = activityDataQuery.data?.activity;

    const isActivityInactive = activity?.activityStatusCode !== 1;
    const readOnly = isActivityInactive || activity?.targetedSystem?.systemCode !== PROVIDER_HUB;
    const firstSession = getFirstSession(activity?.sessions);

    const currentPage = pages[currentPageIdx];

    const form = useMemo(() => 
        forms?.find(form => form.templateName === currentPage?.templateName)
    , [forms, currentPage?.templateName]);

    const { preAssessments = [] } = activity || {};

    // find the preassessment matching current form, and use the result to auto-fill current form
    if (!!form && !form.result) {
        appendPreAssessmentResults(form, preAssessments as IPreAssessment[]);
    }


    useEffect(() => {
        if (forms && !form && currentPage?.templateName) {
            createForm({
                variables: {
                    templateName: currentPage.templateName,
                    form: {
                        activityId: activityId,
                        providerId: providerData?.providerId || effectiveProviderData?.providerId || -1,
                        createdBy: effectiveProviderData?.providerId?.toString() ?? '',
                        sessionId: firstSession?.sessionId
                    }
                }
            })
        }
    }, [forms, form, currentPage?.templateName]);

    useEffect(() => {
        const assessesmentForms = activity?.firstAppointmentAssessmentFormNames
            ?.map(assessment => forms?.find(form => form.templateName === assessment.templateName));

        if (state?.fromToDo && assessesmentForms?.every(form => form?.completed)) {
            terminateToDo({ variables: { activityId: activityId }});
        }
    }, [state?.fromToDo, pages, forms, activity?.firstAppointmentAssessmentFormNames]);

    const clientName = `${activity?.client.firstName} ${activity?.client.lastName}`;

    const clientAddress = useMemo(() => {
        let address = '';
        if (activity?.client?.address?.addressLine1) {
            address += activity.client.address.addressLine1;
        }
        if (activity?.client?.address?.addressLine2) {
            if (address) {
                address += ' ';
            }
            address += activity.client.address.addressLine2;
        }
        if (activity?.client?.address?.city) {
            if (address) {
                address += ', '
            }
            address += activity?.client?.address?.city;
        }
        return address;
    }, [activity?.client?.address])

    const templateDefaultValueMap = {
        [TemplateNames.Facesheet]: {
            _stage: 'first_appointment'
        },
        [TemplateNames.WOS]: {
            _stage: 'first_appointment',
            _show_offwork_question: true
        },
        [TemplateNames.PHQ9]: {
            _stage: 'first_appointment',
        },
        [TemplateNames.Gad7]: {
            _stage: 'first_appointment',
        },
        [TemplateNames.SessionNote]: {
            apt_datetime: firstSession?.startDateTimeUTC
                ? formatDate(firstSession?.startDateTimeUTC, 'YYYY-MM-DD[T]HH:mm')
                : undefined
        },
        [TemplateNames.StatementOfUnderstanding_WRP]: {
            client_name: clientName,
            client_address: clientAddress
        }
    }

    const markPageComplete = (page: number) => {
        if (page < pages.length) {
            setPages([
                ...pages.slice(0, page),
                { ...pages[page], completed: true },
                ...(page < pages.length - 1
                    ? pages.slice(page + 1)
                    : [])
            ]);
        }
    }
    
    const handleSkipClick = () => {
        markPageComplete(currentPageIdx);
        setTimeout(moveToNextPage, 0);
    }

    const moveToNextPage = () => {
        if (currentPageIdx < pages.length - 1) {
            setCurrentPageIdx(currentPageIdx + 1);
        }
    }


    const handleFormSubmit = (result) => {
        form && updateForm({
            variables: {
                data: result,
                formId: form?.id,
                isCompleted: true
            }
        }).then(() => {
            markPageComplete(currentPageIdx);
            setTimeout(moveToNextPage, 0);
        });
    }

    const allAssessmentsCompleted = useMemo(() =>
        Boolean(pages.length) && !pages.some(assessment => !assessment.completed)
    , [pages]);

    const isLoading = activityDataQuery.loading || createFormMutation.loading;


    return (
        <FormViewContainer activityId={activityId} clientName={clientName}>
            {
                (activityDataQuery.loading || createFormMutation.loading) &&
                <Spinner fillParent/>
            }
            {
                updateFormMutation.loading &&
                <Spinner fillParent backdrop/>
            }
            {
                !allAssessmentsCompleted
                    ? (activityDataQuery.loading || createFormMutation.loading)
                        ? <Spinner fillParent/>
                        : form
                            ? <FormPresenter
                                activityId={activityId}
                                sessionId={firstSession?.sessionId ?? null}
                                form={form}
                                readOnly={readOnly}
                                onSubmit={handleFormSubmit}
                                onSkip={handleSkipClick}
                                defaultValues={templateDefaultValueMap[form?.templateName]}
                            />
                            : !isLoading && <FullScreenMessage title={t('assessment_not_found_message')}>
                                <Button onClick={handleSkipClick}>{t('continue_to_next_assessment_button_label')}</Button>
                            </FullScreenMessage>
                    : <AfterAssessmentCompletedPrompt activityId={activityId} isFirst={true} clientName={clientName}/>
            }
        </FormViewContainer>
    )
}

import React, { FC } from 'react';
import { Box, IconButton, TextField } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import './TechSupport.scss';
import { useFormik } from 'formik';
import { useMutation } from '@apollo/client';
import { ISendTechSupportEmailVars, SEND_TECH_SUPPORT_EMAIL_MUTATION } from './queries'
import { ReactComponent as WarningIcon } from 'assets/images/global/warningIcon.svg';
import { useTranslation } from 'react-i18next';

interface TechSupportForm {
    onClose: () => void
}

export const TechSupport: FC<TechSupportForm> = (props) => {
    const { t } = useTranslation('tech_support', { useSuspense: false });
    const { onClose } = props;

    const [sendTechSupportEmailMutation] = useMutation<any, ISendTechSupportEmailVars>(
        SEND_TECH_SUPPORT_EMAIL_MUTATION,
        {
            errorPolicy: 'all'
        }
    )

    const validateNameField = (name: string): string | null => { 
        if (!name) {
            return t("name_req", "Name is required");
        }
        if (name.length > 100) {
            return t("name_connot_exceed", "Name cannot exceed 100 characters");
        }
        return null
    }

    const validateEmailField = (email: string): string | null => {
        if (!email) {
            return t("email_req", "Email is required");
        }  
        if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email)) {
            return t("email_invalid", "Invalid email address");
        }
        return null;
    }

    
    const validateDescriptionField = (description: string): string | null => { 
        if (!description) {
            return t("description_req", "Description is required");
        }  
        if (description && description.length > 1000) {
            return t("description_connot_exceed", "Description cannot exceed 1000 characters");
        }
        return null
    }

    const formik = useFormik({
        initialValues: {
            email: '',
            name: '',
            description: '',
        },
        validate: (values) => { 

            const errors: any = {}
            const nameValidation = validateNameField(values.name);
            const emailValidation = validateEmailField(values.email);
            const descriptionValidation = validateDescriptionField(values.description);

            if (nameValidation) {
                errors.name = nameValidation
            }
            if (emailValidation) {
                errors.email = emailValidation
            }
            if (descriptionValidation) {
                errors.description = descriptionValidation;
            }
            
            return errors
        },
        validateOnMount: true,
        validateOnBlur: true,
        onSubmit: (values) => {
            formik.setTouched({email: true, name: true});
            formik.validateForm();

            if (formik.isValid) {
                sendTechSupportEmailMutation({
                    variables: {
                        name: values.name,
                        email: values.email,
                        description: values.description
                    }
                })
                onClose();
            }
        }
    });

    return (
        <Box className="tech_support_form_container">
            <Box className="tech_support_content_header">
                <h2 className="global__header3">{t("contact_tech_support", "Contact Tech Support")}</h2>
                <IconButton 
                    aria-label="Close contact tech support modal"
                    className="close_button" 
                    onClick={onClose}
                >
                    <CloseIcon />
                </IconButton>
            </Box>
            <p className="global__paragraph global__paragraph--light tech_support_description">{t("contact_tech_support_desc", "Please fill out the following form if you are experience technical difficulties with Provider Hub. Our staff will be in touch with you within the next 2 business days. ")}</p>
            <form onSubmit={formik.handleSubmit} className="tech_support_form" noValidate>
                <TextField 
                    id="email_input"
                    onChange={formik.handleChange}
                    value={formik.values.email}
                    onBlur={formik.handleBlur}
                    name="email"
                    className="form_input_field email_form_input_field"
                    variant="filled"
                    label={t("email", "Email")}
                    type="email"
                    helperText={(formik.touched.email && !!formik.errors.email) && formik.errors.email}
                    error={formik.touched.email && !!formik.errors.email}
                />
                <TextField 
                    id="name_input"
                    onChange={formik.handleChange}
                    value={formik.values.name}
                    onBlur={formik.handleBlur}
                    name="name"
                    className="form_input_field name_form_input_field"
                    variant="filled"
                    label={t("name", "Name")}
                    helperText={(formik.touched.name && !!formik.errors.name) && formik.errors.name}
                    error={formik.touched.name && !!formik.errors.name}
                />
                <TextField
                    id="description_input"
                    className="form_input_field description_form_input_field"
                    label={t("describe_the_issue", "Describe the issue")}
                    multiline
                    rows={4}
                    variant="filled"
                    name="description"
                    value={formik.values.description}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    helperText={(formik.touched.description && !!formik.errors.description) && formik.errors.description}
                    error={formik.touched.description && !!formik.errors.description}
                />
                <div className="color--background--warning warning_div">
                    <WarningIcon className="icon"/>
                    <p className="color--warning global__paragraph">{t("warning_message", "Please allow for up to 2 business days for us to respond to your request for support.")}
                    </p>
                </div>
                <div className="action_buttons_div">
                    <button type="button" className="cancel_button global__button outline_button" onClick={onClose}>{t("cancel", "Cancel")}</button>
                    <button type="submit" disabled={!formik.isValid} className="global__button login_screen_form_submit_button">{t("submit", "Submit")}</button>
                </div>
            </form>
        </Box>
    )
}

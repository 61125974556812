/**
 * File containing common date utils to be used across the application
 * All methods here will be concerned with handling and manipulating dates for display on the UI
 * TODO: Add tests for these methods
 */

import { Moment } from "moment";


export function formatTime(date?: Date | null) {
    if (date) {
        const originalDate = new Date(date);
        const AMPM = originalDate.getHours() >= 12 ? 'PM' : 'AM';
        const hours = originalDate.getHours() % 12 === 0 ? 12 : originalDate.getHours() % 12;
        const minutes = originalDate.getMinutes();
        const formattedTime = `${hours}:` + (minutes < 10 ? `0${minutes}` : minutes.toString()) + ` ${AMPM}`;
        return formattedTime;
    }
}

export function formatJsonTime(time) {
    const pieces = time.split(':');
    return `${pieces[0]}:${pieces[1]}`;
}

/**
 * 
 * @param date the date to be rounded
 * @param minutes the nearest higher minutes value for the date to be rounded to
 */
export const roundTimeToNearest = (date: Date | null = new Date(), minutes: number) => {
    const verifiedDate = date ? date : new Date() // check "null" value case
    const desiredMinutesInMiliSeconds = 1000 * 60 * minutes;
    const newTimeValue = (Math.floor(verifiedDate.getTime() / desiredMinutesInMiliSeconds) + 1) * desiredMinutesInMiliSeconds
    return new Date(newTimeValue);  
}

export const areDatesEqual = (date1: Date | null, date2: Date | null) => {
    if (date1 === date2) {
        return true
    }
    if (!date1 || !date2) {
        return false
    }
    return date1.getTime() === date2.getTime()
}

export const isDateAfter = (dateToBeCompared: Date | null, date2: Date | null) => {
    if (dateToBeCompared === date2) {
        return false
    }
    if (!dateToBeCompared || !date2) {
        return false
    }
    return dateToBeCompared.getTime() > date2.getTime()
}

export const isDateBefore = (dateToBeCompared: Date | null, date2: Date | null) => {
    return !isDateAfter(dateToBeCompared, date2)
}


export const toLocaleTimeString = (date: Date | undefined | null, locale: string, showSeconds?: boolean) => {
    return date?.toLocaleTimeString(locale, {
        hour: 'numeric',
        minute: 'numeric',
        second: Boolean(showSeconds) ? 'numeric' : undefined
    });
}


export const isValid15MinuteIncrement = (date: Moment) => {
    const currentDateMinutes = date?.format('mm')
    const integerMinutes = parseInt(currentDateMinutes || '')
    return isNaN(integerMinutes) ? false : (integerMinutes % 15) === 0
}

export const isPast = (date: Date) => date.getTime() < new Date().getTime();

export const isFuture = (date: Date) => date.getTime() > new Date().getTime();

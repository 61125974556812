import { Dialog, DialogContent, IconButton, TextField } from '@mui/material';
import React, { FC, useState } from 'react';
import { ReactConfirmProps, confirmable, createConfirmation } from 'react-confirm';
import { Button } from 'components/Shared/Inputs';
import CloseIcon from '@mui/icons-material/Close';
import { useTranslation } from 'react-i18next';
import './SubmitAssessmentDialog.scss';

interface SubmitAssessmentDialogProps {
    title?: string | JSX.Element,
    message: string | JSX.Element,
    confirmLabel?: string | JSX.Element,
    cancelLabel?: string | JSX.Element
}

const _SubmitAssessmentDialog: FC<SubmitAssessmentDialogProps & ReactConfirmProps> = (props) => {
    const { show, proceed, title, message, confirmLabel, cancelLabel } = props;
    const [comment, setComment] = useState<string>();
    const { t } = useTranslation('common');

    const handleChange = (event) => {        
        setComment(event.target.value);
    }

    const handleCancel = () => {
        proceed(false as any);
    }

    const handleConfirm = () => {       
        proceed({ comment, answer: true } as any);        
    }

    return (
        <Dialog open={show} className="confirm_dialog">
            <DialogContent>
                <div className="confirm_dialog_header">
                    <h2 className="confirm_dialog_title">{title ?? t('confirm')}</h2>
                    <IconButton className="confirm_dialog_close_button" onClick={handleCancel}><CloseIcon /></IconButton>
                </div>
                <div className="confirm_dialog_content">
                    {message}
                </div>
                <div className="confirm_dialog_content">
                    <TextField
                        value={comment}
                        onChange={handleChange}
                        multiline= {true}
                        variant="outlined"
                        label="comment"
                        required={false}
                    />
                </div>
                <div className="confirm_dialog_actions">
                    <Button variant='text' onClick={handleCancel} autoFocus>{cancelLabel ?? t('cancel')}</Button>
                    <Button variant='opaque' onClick={handleConfirm}>{confirmLabel ?? t('Submit')}</Button>
                </div>
            </DialogContent>
        </Dialog>
    )
}



export const SubmitDialog = confirmable(_SubmitAssessmentDialog);
export const submit: (props: SubmitAssessmentDialogProps) => Promise<any> = createConfirmation(SubmitDialog) as any;
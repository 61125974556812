import React from 'react';
import { FormControl, InputBase, InputLabel, SelectProps, Select as MuiSelect } from '@mui/material';
import clsx from 'clsx';
import KeyboardArrowDownRoundedIcon from '@mui/icons-material/KeyboardArrowDownRounded';
import './Select.scss';

export const Select = React.forwardRef<HTMLDivElement, SelectProps>((props, ref) => {
    const { label, className, disabled, error, readOnly, style, ...inputProps } = props;
    
    return (
        <FormControl ref={ref} disabled={disabled} error={error}
            className={clsx('ms_select', readOnly && 'readonly', className)}
            variant="filled" style={style}
        >
            <InputLabel>{label}</InputLabel>
            <MuiSelect
                input={<InputBase/>}
                IconComponent={KeyboardArrowDownRoundedIcon}
                role="combobox"
                MenuProps={{
                    className: 'ms_select_menu',
                    PaperProps: {
                        className: "ms_select_menu_surface",
                        elevation: 0
                    }
                }}
                readOnly={readOnly}
                {...inputProps}
            />
        </FormControl>
    )
});
import './InvoicePreview.scss';

import React, { FC } from 'react';

import { InvoiceDetails, InvoiceStatus, IActivityInvoiceData } from '../../interfaces';
import { useTranslation } from 'react-i18next';
import { toCurrencyString } from 'utils';
import useTimezoneUtils from 'hooks/useTimezoneUtils';
import { InvoiceTaskTable } from './InvoiceTaskTable'
import { useBillables } from '../../hooks';
import { Card } from 'components/Shared/Card';
import { COUNTRY_CODE, getAppRegionCode } from 'utils/regionUtils';

interface InvoicePreviewHTMLProps {
    invoice: InvoiceDetails
    activity?: IActivityInvoiceData
    showWspTable?: boolean
    activityId?: number 
    activityService?: string 
}

interface InvoiceHeaderProps {
    isDraft: boolean
    isAustralia: boolean
}

export const InvoicePreviewHTML: FC<InvoicePreviewHTMLProps> = ({ invoice, activity, showWspTable, activityId, activityService = '' }) => {
    const { i18n, t } = useTranslation('invoicing', { useSuspense: false });

    const { formatDate } = useTimezoneUtils()

    const formatInvoiceDate = (date: string | Date) => {
        return formatDate(date, 'l')
    }

    const activityIdVal = activity ? activity.activityId : activityId
    const serviceVal = activity ? activity.service : activityService

    const { wspBillables, sessionBillables } = useBillables(invoice?.billables, showWspTable)

    const getSubmittedDate = () => {
        const submittedOrCreatedDate = invoice?.submittedDate ? invoice?.submittedDate : invoice?.createdDate
        return submittedOrCreatedDate ? formatInvoiceDate(new Date(submittedOrCreatedDate)) : 'N/A'
    }

    const renderWspTaskTable = () => {
        if (showWspTable && wspBillables && wspBillables.length > 0) {
            return (
                <InvoiceTaskTable billables={wspBillables} header={t('tasks','Tasks')} isTaskTable/>
            )
        }
    }

    const isAustralia = getAppRegionCode() === COUNTRY_CODE.AUSTRALIA;
    const isDraftInvoice = invoice.statusId === InvoiceStatus.Draft;

    const renderSessionsTable = () => {
        if (invoice && sessionBillables && sessionBillables.length > 0) {
            return (
                <InvoiceTaskTable billables={sessionBillables} header={isAustralia ? 'Supplies made by Supplier to Recipient' : t('sessions','Sessions')} />
            )
        }
    }

    const InvoiceHeader = ({ isDraft, isAustralia }: InvoiceHeaderProps) => {
        const title = isDraft
            ? t('draft_invoice')
            : `${t('invoice')} ${invoice.invoiceId || ""}`;

        return (
            <div>
                {isAustralia && !isDraft && <h2 className="invoice_preview_title global__header2">Recipient Created Tax Invoice</h2>}
                <h2 className="invoice_preview_title global__header2">{title}</h2>
                {isAustralia && isDraft && <h2 className="invoice_preview_title global__header2">Recipient Created Tax Invoice</h2>}
            </div>
        );
    };

    const InvoiceFooter = ({ isAustralia }) => {
        const hasProviderInvoiceNumber = invoice.providerInvoiceNumber && invoice.providerInvoiceNumber.toUpperCase() !== 'N/A';
        const Agreement = () => (
            <div className={hasProviderInvoiceNumber ? "" : "invoice_footer"}>
                <p className="global__paragraph agreement_text">The GST shown will form part of the total GST payable by the supplier on the business activity statement (BAS) for the relevant tax period.</p>

                <h4>Written Agreement</h4>

                <p className="global__paragraph agreement_text">
                    The recipient and the supplier declare that this agreement relates to the above supplies. 
                    The recipient will issue tax invoices in respect of these supplies. 
                    The supplier will not issue tax invoices in respect of these supplies. 
                    The supplier acknowledges that it is registered for GST and that it will notify the recipient if it ceases to be registered. 
                    The recipient acknowledges that it is registered for GST and that it will notify the supplier if it ceases to be registered. 
                    Acceptance of this recipient created tax invoice (RCTI) constitutes acceptance of the terms of this written agreement. 
                    Both parties to this supply agree that they are parties to an RCTI agreement. 
                    The supplier must notify the recipient within 21 days of receiving this document if the supplier does not wish to accept the proposed agreement.
                </p>
            </div>
        )
        return (
            <div className="invoice_preview_text_container">
                {
                hasProviderInvoiceNumber &&
                <div className="draft_invoice_number_row_div provider_info_row">
                    <p className="provider_invoice_number_value">{invoice.providerInvoiceNumber}</p>
                    <p className="provider_invoice_number_label">{t('provider_invoice_number')}</p>
                </div>
                }
                {isAustralia && <Agreement />}
            </div>
        ) 
    }

    
    const contactAddress = 
        invoice.provider.vendor?.address
        || invoice.provider.contactInfo?.addresses?.find(addr => addr.officeType === "MAIL")
        || invoice.provider.contactInfo?.addresses?.[0];
    
    return (
        <Card className="invoice_preview_wrapper">
            <div className="invoice_preview_text_container">
                <InvoiceHeader isAustralia={isAustralia} isDraft={isDraftInvoice} />
                <div className="invoice_submission_date_container">
                    <div className="invoice_preview_provider_id_row provider_info_row">
                        <p className="value">
                            {getSubmittedDate()}
                        </p>
                        <p className="title global__paragraph global__paragraph--light">{t('submission_date')}</p>
                    </div>
                </div>
                <p className="invoice_preview_provider_name global__header3 well_spaced">{`${invoice.provider.firstName} ${invoice.provider.lastName}`}</p>
                <div className="provider_info_container_wrapper">
                    <div className="provider_info_container provider_info_container_left">
                        <div className="invoice_preview_activity_id_row provider_info_row">                 
                            <p className="value">{activityIdVal}</p>
                            <p className="title global__paragraph global__paragraph--light">{t('activity_id')}</p>
                        </div>
                        <div className="invoice_preview_provider_service_row provider_info_row">
                            <p className="value">{serviceVal}</p>
                            <p className="title global__paragraph global__paragraph--light">{t('service')}</p>
                        </div>
                    </div>
                    <div className="provider_info_container provider_info_container_right">
                        <div className="invoice_preview_provider_id_row provider_info_row">
                            <p className="value">{invoice.provider.providerId}</p>
                            <p className="title global__paragraph global__paragraph--light">{t('provider_id')}</p>
                        </div>
                        <div className="invoice_preview_provider_email_row provider_info_row">
                            <p className="value">{invoice.provider.emailAddress}</p>
                            <p className="title global__paragraph global__paragraph--light">{t('email')}</p>
                        </div>
                        <div className="invoice_preview_provider_phone_row provider_info_row">
                            <p className="value">{invoice.provider.contactInfo.phones[0].phoneNumber}</p>
                            <p className="title global__paragraph global__paragraph--light">{t('phone')}</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="invoice_preview_separator"/>
            <div className="invoice_preview_text_container">
                {isAustralia ? (
                    <table className='invoice_recipient_supplier_details'>
                        <thead>
                            <tr>
                                <th>From - Recipient:</th>
                                <th>To - Supplier:</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>
                                    <p>TELUS Health (Australia) Pty Ltd Group of Companies</p>
                                    <p>(TELUS Health (BG Australia) Pty Ltd,</p><p>TELUS Health (SMG Australia) Pty Ltd)</p>
                                    <p>Lv. 25 303 Collins Street</p>
                                    <p className="well_spaced">MELBOURNE VIC 3000</p>
                                    <p>Aus_Accounts_Payable@telushealth.com</p>
                                    <br/>
                                    <p className="business_number">ABN 45134449059</p>
                                    <p className="business_number">ABN 61003536472</p>
                                    <p className="business_number">ABN 62605939697</p>
                                </td>
                                <td>
                                    <p>{invoice.provider.vendor?.name || `${invoice.provider.firstName} ${invoice.provider.lastName}`}</p>
                                    <p className="well_spaced">{contactAddress.addressLine1}</p>
                                    {!!contactAddress.addressLine2 && <p>{contactAddress.addressLine2}</p>}

                                    <p className="well_spaced">{contactAddress.city || ''}&nbsp;{contactAddress.provinceAbbreviation}&nbsp;{contactAddress.zIP_Postal} </p>

                                    <p>{invoice.provider.vendor?.email || invoice.provider.emailAddress}</p>
                                    <br/>
                                    <p className="business_number">ABN {invoice.provider.businessNumber}</p>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    ) : (
                    <div className="provider_billing_vendor_container_wrapper">
                        <div className="provider_billing_vendor_container provider_billing_vendor_container_left">
                            <div className="billing_address_container">
                                <p className="global__paragraph global__paragraph--light">{t('bill_to')}:</p>   
                                <h3 className="global__header4 billing_company_name">{t('telus_health')}</h3>
                            </div>      
                        </div>
                        <div className="provider_billing_vendor_container provider_billing_vendor_container_right">
                            { 
                                invoice.provider.vendor?.name &&
                                <div className="invoice_preview_associate_vendor_row provider_billing_vendor_row">
                                    <span className="value">{invoice.provider.vendor.name}</span>
                                    <p className="title global__paragraph global__paragraph--light">{t('associated_vendor')}</p>
                                </div>
                            }
                        </div>
                    </div>
                    )
                }
            </div>
            
            {renderSessionsTable()}
            {renderWspTaskTable()}
            
            <div className="invoice_preview_text_container">
                { invoice.addCaseManagementTime && (
                        <div>
                            <p className='case_management_time_value'>Yes</p>
                            <p className='case_management_time_label'>Case Management Time?</p>
                            <br />
                        </div>
                    )
                }
                {
                    invoice.taxes?.map((tax) =>
                        <div key={tax.taxCode}>
                            <p className="invoice_preview_tax_amount">{toCurrencyString(tax.amount, i18n.language, invoice.currencyCode) ?? '---'}</p>
                            <p className="invoice_preview_tax_amount_label">{tax.taxDescription}</p>
                        </div>
                    )
                }
                <div className="totals">
                    <div>
                        <p className="total-fees-amount">{toCurrencyString(invoice?.totalFees ?? 0, i18n.language, invoice?.currencyCode) ?? '---'}</p>
                        <p className="total-fees-text">{t('total_fees', 'Total Fees')}</p>
                    </div>
                    <div>
                        <p className="total-expenses-amount">{toCurrencyString(invoice?.totalExpenses ?? 0, i18n.language, invoice?.currencyCode) ?? '---'}</p>
                        <p className="total-expenses-text">{t('total_expenses', 'Total Expenses')}</p>
                    </div>
                </div>
                <div className="invoice_preview_total_amount" aria-label="Total invoice amount">{
                    toCurrencyString(invoice.grandTotal, i18n.language, invoice.currencyCode) ?? '---'
                }</div>
                <p className="invoice_preview_total_amount_label title">{t('total_invoice_amount')}</p>
            </div>
            <InvoiceFooter isAustralia={isAustralia} />
        </Card>
    )
}
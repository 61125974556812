export const WosFormAnswers = {
    DECLINE_YES_ANSWER: ['yes'],
    DECLINE_NO_ANSWER: ['no'],
    SERVICE_FOR_MATURE_MINOR: 'service_for_mature_minor',
    CLIENT_MATURE_MINOR: 'client_mature_minor',
    CLIENT_DECLINED: 'client_declined',    
}

export const WosDeclinePrePostMapping =
{
    'client_mature_minor': {
        _reason: WosFormAnswers.SERVICE_FOR_MATURE_MINOR,
        _decline: true
    },
    'client_declined': {
        _reason: WosFormAnswers.CLIENT_DECLINED,
        _decline: false
    }
};

export const WOSDeclineReasonToAssessmentOutcomeMapping = {
    'CO': {
        _reason: 'eap_counsellor_withdrew',
        _decline: true
    },
    'CT': {
        _reason: 'client_reassigned',
        _decline: true
    },
    'CW': {
        _reason: 'client_discontinued',
        _decline: true
    },
    'GM': {
        _reason: 'eap_goals_met',
        _decline: false
    }
};
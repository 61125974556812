import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { SurveyModel } from 'survey-react';
import { SurveyJSContainer, SurveyJSContainerProps } from '../../../components/SurveyJS';
import { submit } from '../../SubmitAssessment';

interface WSPReportProps extends SurveyJSContainerProps {
    reportType: string
}

export const WSPReport: FC<WSPReportProps> = (props) => {
    const { reportType, onSurveyJSInitialized, onComplete, ...rest } = props;
    const { t } = useTranslation('caseManagement', { useSuspense: false });

    const submitDialog = () => (
        submit({
            message: t('Are you sure you want to submit this report? After submit you will not be able to modify.'),
            title: reportType === 'WRP_MO' ?  t('Submit monthly report') : t('Submit Assessment Report'),
            cancelLabel: t('caseManagement:Cancel'),
            confirmLabel: t('caseManagement:Submit')
        })
    )

    const handleSurveyJSInitialized = (surveyModel: SurveyModel) => {
        onSurveyJSInitialized?.(surveyModel);

        surveyModel.completeText = t('caseManagement:save');
        
        surveyModel.onAfterRenderPage.add((_, options) => {
            if (!rest.readOnly) {
                const document = options.htmlElement.ownerDocument;
                const footerContainer = document.querySelector('.sv-footer') as HTMLDivElement;

                const saveDraftInputElement = document.createElement('input') as HTMLInputElement;
                saveDraftInputElement.className = 'sv-btn sv-footer__complete-btn';
                saveDraftInputElement.type = 'button';
                saveDraftInputElement.value = t('caseManagement:submit');

                if ((props.defaultValues?.['numberOfAttendedSessions'] ?? 1) < 2) {
                    saveDraftInputElement.disabled = true;
                }

                saveDraftInputElement.addEventListener("click", async () => {
                    if (!surveyModel.hasErrors(true, true)) {
                        const response = await submitDialog();
                        if (response !== false) {
                            onComplete?.(JSON.stringify({
                                outcome: JSON.stringify(surveyModel.data),
                                comment: response.comment,
                                isSubmit: true
                            }))
                        }
                    }
                });

                footerContainer.appendChild(saveDraftInputElement);
            }
        });
    }

    const handleComplete = (outcome?: string) => {
        onComplete?.(JSON.stringify({
            outcome: outcome,
            comment: '',
            isSubmit: false
        }))
    }

    return <SurveyJSContainer
        onSurveyJSInitialized={handleSurveyJSInitialized}
        onComplete={handleComplete}
        {...rest}
    />
}
export enum StepDefinition {
    LIST_REFERRAL = 'ConfirmFirstAppointmentStep',
    CONFIRM_REFERRAL = 'ConfirmCaseAcceptanceStep',
    ENTER_DATE_FIRST_APPT = 'EnterDateOfFirstAppointmentStep',
    UPDATE_APPT_STATUS = 'UpdateAppointmentStatusStep',
    FIRST_APPT_ASSESSMENT = 'FirstAppointmentAssessmentStep',
    SUBMIT_INVOICE = 'SubmitInvoiceStep',
    ENTER_DATE_NEXT_APPT = 'EnterDateOfNextAppointmentStep',
    FOLLOW_UP = 'AppointmentFollowUpStep',
    REVIEW_PRE_ASSESSMENT = 'ReviewClinicalNowPreAssessmentStep',
    
    COMPLETE_INITIAL_ASSESSMENT_STEP = "CompleteInitialAssessmentStep",
    COMPLETE_MONTHLY_ASSESSMENT_STEP = "CompleteMonthlyAssessmentStep",
    COMPLETE_CLOSURE_ASSESSMENT_STEP = "CompleteClosureAssessmentStep",
    SUBMIT_INITIAL_ASSESSMENT_REPORT = "SubmitInitialAssessmentStep",
    SUBMIT_MONTHLY_ASSESSMENT_REPORT = "SubmitMonthlyFormStep",
    SUBMIT_CLOSURE_ASSESSMENT_REPORT = "SubmitClosureAssessmentStep",    
    RESUBMIT_INITIAL_ASSESSMENT_REPORT = "ResubmitInitialFormStep",
    RESUBMIT_MONTHLY_ASSESSMENT_REPORT = "ResubmitMonthlyFormStep",
    RESUBMIT_CLOSURE_ASSESSMENT_REPORT = "ResubmitClosureFormStep",

}

export const ToDosOrder = {
    [StepDefinition.CONFIRM_REFERRAL]: 1,
    [StepDefinition.LIST_REFERRAL]: 2,
    [StepDefinition.ENTER_DATE_FIRST_APPT]: 3,
    [StepDefinition.SUBMIT_INVOICE]: 4,
    [StepDefinition.REVIEW_PRE_ASSESSMENT]: 5,
    [StepDefinition.FIRST_APPT_ASSESSMENT]: 6,
    [StepDefinition.UPDATE_APPT_STATUS]: 7,
    [StepDefinition.ENTER_DATE_NEXT_APPT]: 8,
    [StepDefinition.FOLLOW_UP]: 9,
    [StepDefinition.COMPLETE_INITIAL_ASSESSMENT_STEP]: 10,  
    [StepDefinition.COMPLETE_MONTHLY_ASSESSMENT_STEP]: 11,   
    [StepDefinition.COMPLETE_CLOSURE_ASSESSMENT_STEP]: 12,   
    [StepDefinition.SUBMIT_INITIAL_ASSESSMENT_REPORT]: 13,   
    [StepDefinition.SUBMIT_MONTHLY_ASSESSMENT_REPORT]: 14,   
    [StepDefinition.SUBMIT_CLOSURE_ASSESSMENT_REPORT]: 15,   
    [StepDefinition.RESUBMIT_INITIAL_ASSESSMENT_REPORT]: 16,   
    [StepDefinition.RESUBMIT_MONTHLY_ASSESSMENT_REPORT]: 17,   
    [StepDefinition.RESUBMIT_CLOSURE_ASSESSMENT_REPORT]: 18,   
    
}
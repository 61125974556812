import React, { FC, useEffect, useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { FormViewContainer } from '../../components/FormViewContainer';
import { FormPresenter } from '../../components/FormPresenter';
import { useMutation, useQuery } from '@apollo/client';
import { GET_REPORT_BY_ID, UPDATE_FORM } from '../../queries';
import { PROVIDER_HUB } from 'constants/targetedSystem';
import { Spinner } from 'components/Shared';
import { FullScreenMessage } from 'components/Shared/FullScreenMessage';
import { SessionStatus } from 'components/CaseManagement/interfaces';
import { useTranslation } from 'react-i18next';
import { FormStage, ReportNames, TemplateNames } from '../../constants';
import moment from 'moment';
import { DASS21Score } from '../../interfaces';

interface ReportAssessmentScore {
    assessment: {
        type: string,
        score: string,
        date: string,
        interpretation_en: string,
        interpretation_fr: string
    }
}

interface WSPReportViewProps { }

interface WSPReportParams extends Record<string, string | undefined> {
    activityId: string
    formId: string
}

export const WSPReportView: FC<WSPReportViewProps> = () => {
    const { activityId: activityIdString, formId = '' } = useParams<WSPReportParams>();
    const navigate = useNavigate();

    const activityId = parseInt(activityIdString || '');

    const { t, ready: translationsReady, i18n } = useTranslation(['forms', 'assessments'], { useSuspense: false });

    useEffect(() => { i18n.reloadResources(['fr', 'en'], 'forms'); }, []);

    const reportQuery = useQuery(GET_REPORT_BY_ID, {
        variables: { formId: formId },
        errorPolicy: 'all',
        fetchPolicy: 'no-cache'
    });

    const [updateForm, updateFormMutation] = useMutation(UPDATE_FORM, {
        onCompleted: () => {
            navigate(`/clients/${activityId}`)
        }
    });

    const form = reportQuery.data?.form;
    const activity = form?.activity;

    const isActivityInactive = activity?.activityStatusCode !== 1;
    const readOnly = (updateFormMutation.called && !updateFormMutation.error) ||
        isActivityInactive ||
        activity?.targetedSystem?.systemCode !== PROVIDER_HUB ||
        form?.stage === FormStage.Submitted.toLocaleUpperCase() ||
        form?.stage === FormStage.Rejected.toLocaleUpperCase() ||
        form?.stage === FormStage.Accepted.toLocaleUpperCase();

    const assessmentsScores = useMemo<ReportAssessmentScore[]>(() => (
        Object
            .keys(activity?.assessmentsScores ?? {})
            .filter(key => key !== '__typename')
            .reduce((scores, assessmentName) => {
                for (const result of activity?.assessmentsScores?.[assessmentName] ?? []) {
                    console.log('result');
                    console.log(result);
                    const assessment = {
                        type: assessmentName,
                        score: result.score,
                        date: moment(result.date).format('YYYY-MM-DD'),
                        interpretation_en: '',
                        interpretation_fr: ''
                    }

                    if (assessmentName === ReportNames.Dass21) {
                        const score = result.score as DASS21Score;
                        const severity = result.severity as DASS21Score;                                        

                        assessment.interpretation_en = t('DASS21__interpretation', { lng: 'en',
                            replace: {
                                interpretationDepression: `${score.depression} - ${t(`DASS21__interpretation_depression__${severity.depression}`, { lng: 'en' })}`,
                                interpretationAnxiety: `${score.depression} - ${t(`DASS21__interpretation_anxiety__${severity.anxiety}`, { lng: 'en' })}`,
                                interpretationStress: `${score.depression} - ${t(`DASS21__interpretation_stress__${severity.stress}`, { lng: 'en' })}`
                            }
                        });
                        assessment.interpretation_fr = t('DASS21__interpretation', { lngs: ['fr', 'en'],
                            replace: {
                                interpretationDepression: `${score.depression} - ${t(`DASS21__interpretation_depression__${severity.depression}`, { lngs: ['fr', 'en'] })}`,
                                interpretationAnxiety: `${score.depression} - ${t(`DASS21__interpretation_anxiety__${severity.anxiety}`, { lngs: ['fr', 'en'] })}`,
                                interpretationStress: `${score.depression} - ${t(`DASS21__interpretation_stress__${severity.stress}`, { lngs: ['fr', 'en'] })}`
                            }
                        });
                    } else if (assessmentName === TemplateNames.PHQ9) {
                        assessment.interpretation_en = `${result.score?.[0]} - ${t(`${assessmentName}__interpretation__${result.severity?.[0]}`, { lng: 'en' })}`;
                        assessment.interpretation_fr = `${result.score?.[0]} - ${t(`${assessmentName}__interpretation__${result.severity?.[0]}`, { lngs: ['fr', 'en'] })}`;
                    } else {
                        assessment.interpretation_en = `${result.score} - ${t(`${assessmentName}__interpretation__${result.severity}`, { lng: 'en' })}`;
                        assessment.interpretation_fr = `${result.score} - ${t(`${assessmentName}__interpretation__${result.severity}`, { lngs: ['fr', 'en'] })}`;
                    }
                    scores.push({assessment: assessment});
                }
                return scores;
            }, [] as ReportAssessmentScore[])
    ), [activity?.assessmentsScores, translationsReady]);

    const defaultValues = useMemo(() => ({
        'client_name': activity?.assessmentReportData.clientName,
        'client_position': activity?.assessmentReportData.clientPosition,
        'safety_sensitive': activity?.assessmentReportData.isSafetySensitivePositionString,
        'org_name': activity?.assessmentReportData.companyName,
        'current_work_status': activity?.assessmentReportData.currentWorkStatus,
        'other_work_status_desc': activity?.assessmentReportData.otherWorkStatusDesc,
        'current_work_status_eff_date': activity?.assessmentReportData.currentWorkStatusEffDate,
        'anticipated_rtw_date': activity?.assessmentReportData.anticipatedRTWDate,
        'referral_contact_name': activity?.assessmentReportData.referralContactName,
        'created_date': form?.createdOn,
        'numberOfAttendedSessions': activity?.sessions?.filter(session => session.sessionStatusId === SessionStatus.COMPLETE)?.length,
        'assessment_tools': assessmentsScores
    }), [form]);

    const clientName = `${activity?.client.firstName} ${activity?.client.lastName}`;

    const handleFormSubmit = (result: string) => {
        const { outcome, comment, isSubmit } = JSON.parse(result) as { outcome: string, comment: string, isSubmit: boolean };

        form && updateForm({
            variables: {
                data: outcome,
                formId: form.id,
                isCompleted: true,
                isSubmit: isSubmit,
                providerComment: comment
            }
        });
    }

    return (
        <FormViewContainer activityId={activityId} clientName={clientName}>
            {
                updateFormMutation.loading &&
                <Spinner fillParent backdrop/>
            }
            {
                reportQuery.loading
                    ? <Spinner fillParent/>
                    : form
                        ? <FormPresenter
                            activityId={activityId}
                            sessionId={null}
                            defaultValues={defaultValues}
                            form={form}
                            readOnly={readOnly}
                            onSubmit={handleFormSubmit}
                        />
                        : <FullScreenMessage title={t('assessments:assessment_not_found_message')} />
            }
        </FormViewContainer> 
    )
}

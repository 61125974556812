import React, { useState, FC } from 'react';
import { Container } from '@mui/material';
import { ToDosDataRequest, StepFilterValue } from '../../interfaces';
import { ToDoTypePicker } from '../../components/ToDoTypePicker';
import { useTranslation } from 'react-i18next';
import { Paginator, MaterialTable } from 'components/Shared/MaterialTable';
import { useToDosColumnsDefinitions } from '../../hooks/useToDosColumnsDefinitions';
import { useProcessToDosData } from '../../hooks/useProcessToDosData';
import { PageMetadata } from 'components/Shared/PageMetadata';
import { useAuth } from 'components/Auth';
import { useQuery } from '@apollo/client';
import { TODO_QUERY } from '../../queries';
import { Card } from 'components/Shared/Card';

interface ToDosViewProps { }

export const ToDosView: FC<ToDosViewProps> = (_) => {
    const { t, ready } = useTranslation('todos', { useSuspense: false });
    const { effectiveProviderData } = useAuth();

    const toDosQuery = useQuery(TODO_QUERY, {
        variables: {
            providerId: effectiveProviderData!.providerId
        }
    });
    
    const toDos = toDosQuery.data?.toDos;

    const [dataRequest, setDataRequest] = useState<ToDosDataRequest>({
        filters: { step: 'ALL' },
        sorts: {},
        take: 10,
        skip: 0,
        search: ''
    });

    const handleTypeChange = (value: StepFilterValue) => {
        setDataRequest({
            ...dataRequest,
            filters: { step: value },
            skip: 0
        });
    }

    const handlePageChange = (currentPage: number) => {
        setDataRequest({...dataRequest, skip: (currentPage - 1) * dataRequest.take});
    }

    const [pagedData, paginationDetails, toDoFilterTypes] = useProcessToDosData(toDos, dataRequest);
    
    const columnsDefinitions = useToDosColumnsDefinitions();

    const emptyStateMessage = <>
        <p className="global__paragraph global__paragraph--light" role="alert" aria-live="polite">
            {t('completed_all_todos')}
        </p>
    </>

    return (
        <React.Fragment>
            { ready && 
                <PageMetadata 
                    pageDescription={t('todos_page_description')} 
                    pageTitle={t('todos_page_title')}
                /> 
            }
            <div className="table_view_wrapper">
                <Container>
                    <div className="table_view_title_wrapper">
                        <h1>{t('todos_view_header')}</h1>
                        <ToDoTypePicker value={dataRequest.filters.step} types={toDoFilterTypes} onChange={handleTypeChange}/>
                    </div>
                    <Card className="table_container" elevation={1}>
                        <MaterialTable
                            columns={columnsDefinitions}
                            data={pagedData}
                            emptyStateMessage={emptyStateMessage}
                        />
                    </Card>
                    <Paginator paginationDetails={paginationDetails} onPageChange={handlePageChange}/>
                </Container>
            </div>
        </React.Fragment>
    );
}

import { DateClickArg } from "@fullcalendar/interaction";
import { DateSelectArg, EventClickArg } from "@fullcalendar/core";
import { Moment } from "moment";

export enum CalendarType {
    Week = "timeGridWeek",
    Month = "dayGridMonth"
}

export enum EventType {
    Unavailability = "Unavailability",
    Availability = "Availability",
    Reservation = "Reservation",
    Other = "Other"
}

export interface ScheduleItem {
    address: number,
    oneTime: boolean,
    startDate?: string | null,
    endDate?: string | null,
    seriesId?: string | null,
    scheduleId?: number,
    wspTaskCode?: string
    activityId?: string,
    shifts: {
        startDay: number,
        endDay: number,
        startTime: string,
        endTime: string
    }[]
}

export interface AvailabilityItem extends ScheduleItem {
    reservations: Reservation[]
    modalityCode?: string
}


export interface ICalendarReservation extends Reservation {
    modalityCode?: string
}
export interface UnavailabilityItem extends ScheduleItem {
    seriesId: string | null,
    unavailabilityReasonID: number,
    unavailabilityReason?: UnavailabilityReason
}

export interface UnavailabilityReason {
    description: string,
    editable: boolean,
    id: number
}

export interface Reservation {
    reservationId: number,
    startTime: string,
    endTime: string, 
    firstTime: boolean,
    clientName: string,
    activityId: number,
    sessionId: number,
    clientPhoneNumber: string,
    clientEmail: string,
    providerWillContactClient: boolean
}

export interface Event {
    start: Moment,
    end: Moment,
    item: any,
    allDay?: boolean
}

export interface DateClickArgKeyboard extends Omit<DateClickArg, 'jsEvent'> {
    jsEvent: MouseEvent | KeyboardEvent
}
export interface EventClickArgKeyboard extends Omit<EventClickArg, 'jsEvent'> {
    jsEvent: MouseEvent | KeyboardEvent
}
export interface DateSelectArgKeyboard extends Omit<DateSelectArg, 'jsEvent'> {
    jsEvent: MouseEvent | KeyboardEvent | null
}

export enum DatePreference {
    aft = 'aft',
    any = 'any',
    bef = 'bef',
    on = 'on'
}

export enum TimePreference {
    aft = 'aft',
    aftn = 'aftn',
    any = 'any',
    bef = 'bef',
    eve = 'eve',
    mor = 'mor',
    nea = 'nea'
}

export interface FilterOptions {
    datePreference: DatePreference,
    timePreference: TimePreference,
    officeId: number | 'any' | null,
    startDate: string,
    startTime: string
}
import React, { FC, useCallback, useState } from 'react';

import { Menu, MenuItem } from '@mui/material';
import ArrowDown from '@mui/icons-material/KeyboardArrowDownRounded';
import { InvoiceSummary, InvoiceDetails, InvoiceStatus } from '../../interfaces';
import PrintIcon from '@mui/icons-material/PrintOutlined';
import SaveIcon from '@mui/icons-material/SaveAltRounded';
import { Link } from 'react-router-dom';
import { useDebouncedCallback } from 'use-debounce';
import { useTranslation } from 'react-i18next';
import useTimezoneUtils from 'hooks/useTimezoneUtils';
import { Button } from 'components/Shared/Inputs';
import { deleteInvoiceDraftFromSession } from '../InvoiceDraftProvider/InvoiceDraftProvider';

interface InvoiceDetailsHeaderProps {
    invoices: InvoiceSummary[] | undefined,
    selectedInvoice?: InvoiceDetails,
    onPrintClick?: () => void,
    onSavePDFClick?: () => void,
    generateInvoiceAvailable: boolean,
    printAvailable: boolean,
    activityId: number
}

export const InvoiceDetailsHeader: FC<InvoiceDetailsHeaderProps> = (props) => {
    const { invoices, onPrintClick, onSavePDFClick, selectedInvoice,
        generateInvoiceAvailable, printAvailable, activityId } = props;
    const { t } = useTranslation('invoicing', { useSuspense: false });
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    const { formatDate } = useTimezoneUtils()

    const formatInvoiceDate = useCallback((invoiceDate: string) => {
        return formatDate(invoiceDate, 'l')
    }, []);

    const handleClick = useCallback((event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    }, []);

    const handleClose = useCallback(() => {
        setAnchorEl(null);
    }, []);

    const getBulletColor = (status: InvoiceStatus): string => {
        switch (status) {
            case InvoiceStatus.Draft: return '#0055DE';
            case InvoiceStatus.Submitted:
            case InvoiceStatus.PendingApproval: return '#B35910';
            case InvoiceStatus.Approved: return '#268740';
            case InvoiceStatus.Declined: return '#9E0214';
            case InvoiceStatus.Void:
            default: return '#646464';
        }
    }

    const handlePrintClick = useDebouncedCallback(() => onPrintClick?.(), 2000, {leading: true, trailing: false})
    const handleSavePDFClick = useDebouncedCallback(() => onSavePDFClick?.(), 2000, {leading: true, trailing: false})

    return (
        <div className="invoice_details_header_content">
            <h1>
            {
                selectedInvoice
                    ? selectedInvoice.statusId !== InvoiceStatus.Draft
                        ? `${t('invoice')} ${(selectedInvoice.providerInvoiceNumber && selectedInvoice.providerInvoiceNumber.toUpperCase() !== 'N/A')
                            ? selectedInvoice.providerInvoiceNumber
                            : ""}`
                        : t('new_invoice')
                    : t('invoicing')
            }
            </h1>
            <div className="invoice_header_button_wrapper">
                {
                    printAvailable &&
                    <React.Fragment>
                        <Button variant="text" startIcon={<PrintIcon/>} onClick={handlePrintClick}>{t('print_invoice')}</Button>
                        <Button variant="text" startIcon={<SaveIcon/>} onClick={handleSavePDFClick}>{t('save_as_pdf')}</Button>
                    </React.Fragment>
                }
                <Button aria-controls="past-invoices" aria-haspopup="true" onClick={handleClick} disabled={!invoices?.length} 
                    variant="outline" endIcon={<ArrowDown/>}>{t('past_invoices')}</Button>
                <Button
                    variant="outline"
                    disabled={!generateInvoiceAvailable}
                    component={Link} to={`/clients/${activityId}/invoices/create`}
                    onClick={() => deleteInvoiceDraftFromSession(activityId)}
                >{t('generate_invoice')}</Button>
                <Menu className="past_invoices_menu_container"
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    id="past-invoices"
                    anchorEl={anchorEl}
                    disableAutoFocus
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                >
                    {
                        invoices?.map((invoice, index) => (
                            <MenuItem key={index} role="button" onClick={() => handleClose()} component={Link} to={`/clients/${invoice.activityId}/invoices/${invoice.invoiceId}`}
                                autoFocus={selectedInvoice?.invoiceId ? invoice.invoiceId === selectedInvoice.invoiceId : false}
                                className={`menu_item_wrapper ${(selectedInvoice?.invoiceId && invoice.invoiceId === selectedInvoice.invoiceId) ? "menu_item_active" : ''}`}>
                                <div>
                                    <p className="menu_date">
                                        {(invoice.submittedDateUtc && formatInvoiceDate(invoice.submittedDateUtc)) || "---"}
                                    </p>
                                    <p className="menu_date_label">{t('submitted_date')}</p>
                                </div>
                                <div style={{display: 'flex', alignItems: 'center'}}>
                                    <div className="menu_status_bullet" style={{backgroundColor: getBulletColor(invoice.statusId)}}></div>
                                    <p className="menu_status_text">
                                        {invoice.statusString}
                                    </p>
                                </div>
                            </MenuItem>
                        ))
                    }
                </Menu>
            </div>
        </div>
    )
}
import { gql, TypedDocumentNode } from '@apollo/client';
import { AfterSessionStatusUpdateActivityData, IApptStatusActivityData, UnattendedSessionAdditionalInfo } from './interfaces';
import { FormSummary } from '../interfaces';


export const UPDATE_APPT_STATUS_MUTATION
    : TypedDocumentNode<UpdateApptStatusMutationData, UpdateApptStatusMutationVars> = gql`
    mutation UPDATE_APPT_STATUS_MUTATION(
        $activityId: Long!,         
        $duration: Decimal!, 
        $providerId: Long!, 
        $sessionId: Long!, 
        $statusId: Short!, 
        $voidReasonId: Short, 
        $voidSpecifyReason: String, 
        $wspTaskCode: String,
        $hourlyWorkflowEnabled: Boolean!,
        $isHourlyContract: Boolean!,
        $additionalInfo: UnattendedSessionAdditionalInfoInput
    ) {
        updateAppointment(activityId: $activityId, 
            duration: $duration, 
            providerId: $providerId, 
            sessionId: $sessionId, 
            statusId: $statusId, 
            voidReasonId: $voidReasonId, 
            voidSpecifyReason: $voidSpecifyReason, 
            wspTaskCode: $wspTaskCode,
            hourlyWorkflowEnabled: $hourlyWorkflowEnabled,
            isHourlyContract: $isHourlyContract,
            additionalInfo: $additionalInfo
        ) {
            activity {
                activityId
                sessions {
                    duration
                    sessionId
                    sessionStatusId
                    startDateTimeUTC
                    providerHubAppointmentStatusId
                    providerHubAppointmentVoidReasonId
                }
                sessionBooking {
                    allowed
                    prohibitionReasonCode
                }
                forms {
                    id
                    templateName
                    sessionId
                    preAssessmentStageStatus
                    postAssessmentStageStatus
                }
                firstAppointmentAssessmentFormNames {
                    templateName
                    mandatory
                }
                lastAppointmentAssessmentFormNames {
                    templateName
                    mandatory
                }
            }
            pendingPHQ9Form {
                id
            }
            pendingGad7Form {
                id
            }
        }
    }
`;

export interface UpdateApptStatusMutationData {
    updateAppointment: {
        activity: AfterSessionStatusUpdateActivityData,
        pendingPHQ9Form: {
            id: string
        } | null,
        pendingGad7Form: {
            id: string
        } | null
    }
}

export interface UpdateApptStatusMutationVars {
    activityId: number,
    duration: number,
    providerId: number,
    sessionId: number,
    statusId: number,
    voidReasonId?: number,
    voidSpecifyReason?: string,
    wspTaskCode?: string,
    hourlyWorkflowEnabled: boolean,
    isHourlyContract: boolean,
    additionalInfo?: UnattendedSessionAdditionalInfo
}



export const START_APPT_ASSESSMENT: TypedDocumentNode<StartApptAssessmentData, StartApptAssessmentVars> = gql`
    mutation START_APPT_ASSESSMENT($activityId: Long!, $providerId: Long!) {
        startFirstAppointmentAssessment(activityId: $activityId, providerId: $providerId)
    }
`

export interface StartApptAssessmentData {
    startFirstAppointmentAssessment: any
}

export interface StartApptAssessmentVars {
    activityId: number,
    providerId: number
}




export const UPDATE_APPT_STATUS_DATA_QUERY: TypedDocumentNode<GetUpdateApptStatusQueryData, UpdateApptStatusQueryVars> = gql`
    query UPDATE_APPT_STATUS_DATA_QUERY($activityId: Long!) {
        activity(activityId: $activityId) {
            activityId
            client {
                firstName
                lastName
            }
            administerGad7
            hasEligibleWSPReportService
            clientAvailableHours
            isHourlyContract
            solutionCode
            timezoneOlsonName
            sessions {
                duration
                sessionId
                sessionStatusId
                startDateTimeUTC
                providerHubAppointmentStatusId
                providerHubAppointmentVoidReasonId
            }
            wosOffWorkQuestionIndicator
            isWSPActivity
            provider {
                providerId
                details {
                    timezoneOlsonName
                }
            }
        }
    }
`
export interface GetUpdateApptStatusQueryData {
    activity: IApptStatusActivityData
}

export interface UpdateApptStatusQueryVars {
    activityId: number
}



export const FORMS_SUMMARY_QUERY: TypedDocumentNode<FormsSummaryQueryData, FormsSummaryQueryVars> = gql`
    query FORMS_SUMMARY_QUERY($activityId: Long!) {
        forms: formsByActivity(activityId: $activityId) {
            id
            templateName
            sessionId
            preAssessmentStageStatus
            postAssessmentStageStatus
        }
    }
`
interface FormsSummaryQueryData {
    forms: FormSummary[]
}
interface FormsSummaryQueryVars {
    activityId: number
}



export const TERMINATE_UPDATE_STATUS_TODO_MUTATION: TypedDocumentNode<boolean, TerminateUpdateStatusTodoMutationVars> = gql`
    mutation TERMINATE_UPDATE_STATUS_TODO_MUTATION($activityId: Long!, $sessionId: Long!) {
        closeUpdateApptStatusToDo(activityId: $activityId, sessionId: $sessionId)
    }
`
export interface TerminateUpdateStatusTodoMutationVars {
    activityId: number,
    sessionId: number
}

import { OrganizationException, OrganizationExceptionInput } from '../../../interfaces';
import React, { FC, useEffect, useMemo, useState } from 'react';
import { MutationTuple, useLazyQuery, useQuery } from '@apollo/client';
import { UpdateExceptionsMutationData, UpdateExceptionsMutationVars, EXCEPTIONS_REF, ORGANIZATIONS_REF } from './queries';
import { useTranslation } from 'react-i18next';
import { FormikErrors, useFormik } from 'formik';
import moment, { Moment } from "moment";
import AddIcon from '@mui/icons-material/Add';
import { useTimezoneUtils } from "hooks/useTimezoneUtils";
import { confirm } from 'components/Shared/ConfirmDialog';
import { Checkbox, CircularProgress, FormControlLabel, Grid, MenuItem, Autocomplete } from '@mui/material';
import { SectionContainer } from '../../Other/SectionContainer';
import { Button, DateInput, Select, TextBox } from 'components/Shared/Inputs';
import { Spinner } from "components/Shared";
import { useDebouncedCallback } from 'use-debounce';
import KeyboardArrowDownRoundedIcon from '@mui/icons-material/KeyboardArrowDownRounded';

const EXCLUSION_TYPE_CODE = 1;

const mapExceptionToExceptionInput = (exception: OrganizationException): OrganizationExceptionInput => ({
    organizationExceptionId: exception.organizationExceptionId,
    comments: exception.comments,
    endDate: exception.endDate,
    exceptionTypeCode: exception.exceptionTypeCode,
    organizationID: exception.organizationID,
    isPermanent: exception.isPermanent,
    startDate: exception.startDate
})


interface OrganizationExceptionsFormProps {
    providerId: number,
    exceptions: OrganizationException[] | undefined,
    entry: OrganizationException | undefined,
    mutation: MutationTuple<UpdateExceptionsMutationData, UpdateExceptionsMutationVars>,
    cachePendingUpdate: boolean
}

interface OrganizationExceptionsFormValues {
    organizationExceptionId: number | null,
    exceptionTypeCode: number | null,
    organizationID: number | null,
    isTemporary: boolean,
    startDate: string,
    endDate: string,
    comments: string
}

export const OrganizationExceptionsForm: FC<OrganizationExceptionsFormProps> = (props) => {
    const { providerId, exceptions, entry, mutation, cachePendingUpdate } = props;

    const { t } = useTranslation('providerprofile', { useSuspense: false });
    const { t: t_common } = useTranslation('common', { useSuspense: false });

    const { createDateFormatFunction, DATE_PORTION_MOMENT_FORMAT, localeDateFormat } = useTimezoneUtils();
    const formatCredentialDate = createDateFormatFunction(DATE_PORTION_MOMENT_FORMAT);

    const [requestPending, setRequestPending] = useState(false);
    const [organizationsSearchInput, setOrganizationsSearchInput] = useState('');
    const [organizationsCache, setOrganizationsCache] = useState<{[searchInput: string] : {title: string, value: number}[]}>({});
    
    const [inEdit, setInEdit] = useState(false);

    const [getRefData, refDataQuery] = useLazyQuery(EXCEPTIONS_REF);

    const organizationsDataQuery = useQuery(ORGANIZATIONS_REF, { skip: true, fetchPolicy: 'no-cache' });

    const fetchOrganizations = (searchInput: string) => {
        organizationsDataQuery.refetch({ searchInput: searchInput })
            ?.then(value => {
                const cacheKeys = Object.keys(organizationsCache);
                const moreSpecificKey = cacheKeys.find(key => key.includes(searchInput));
                const updatedCache = {};
                for (const key of cacheKeys) {
                    if (key !== moreSpecificKey) {
                        updatedCache[key] = organizationsCache[key];
                    }
                }
                updatedCache[searchInput.toLowerCase()] = value.data.refValues.organizations
                    .map(org => ({title: org.title, value: Number(org.value)}));
                setOrganizationsCache(updatedCache);
            });
    }
    const debouncedFetchOrganizations = useDebouncedCallback(fetchOrganizations, 2000);
    
    useEffect(() => {
        if (inEdit && !refDataQuery.called) {
            getRefData();
        }
    }, [inEdit]);
    
    const [update, updateMutation] = mutation;

    const formik = useFormik<OrganizationExceptionsFormValues>({
        initialValues: {
            organizationExceptionId: entry?.organizationExceptionId ?? null,
            exceptionTypeCode: entry?.exceptionTypeCode ?? EXCLUSION_TYPE_CODE,
            organizationID: entry?.organizationID ?? null,
            isTemporary: !entry?.isPermanent ?? false,
            startDate: entry?.startDate ?? moment().format('YYYY-MM-DD'),
            endDate: entry?.endDate ?? moment().format('YYYY-MM-DD'),
            comments: entry?.comments ?? ''
        },
        validate: (values) => {
            const errors: FormikErrors<OrganizationExceptionsFormValues> = {};

            if (!values.exceptionTypeCode) {
                errors.exceptionTypeCode = "Type is required"
            }

            if (!values.organizationID) {
                errors.organizationID = "Organization is required"
            }

            if (values.isTemporary) {
                if (!values.startDate) {
                    errors.startDate = "Start date is required"
                }
                if (!values.endDate) {
                    errors.startDate = "End date is required"
                }
            }

            return errors;
        },
        validateOnBlur: true,
        validateOnChange: true,
        onSubmit: async (values) => {
            formik.validateForm();

            if (formik.isValid) {
                const dialogProps = {
                    message: <>
                        <p>{t('common__confirm_changes_dialog_text')}</p>
                        <ul>
                        {
                            (!entry || values.exceptionTypeCode !== entry.exceptionTypeCode) &&
                            <li>{t('organization_exceptions__type_label')}: <b>{exceptionTypes?.find(e => e.value === values.exceptionTypeCode)?.title}</b></li>
                        }
                        {
                            (!entry || values.organizationID !== entry.organizationID) &&
                            <li>{t('organization_exceptions__organization_label')}: <b>{organizationValue?.title}</b></li>
                        }
                        {
                            (!entry || !values.isTemporary !== entry.isPermanent) &&
                            <li>{t('organization_exceptions__is_temporary_label')}: <b>{values.isTemporary ? t_common('yes') : t('no')}</b></li>
                        }
                        {
                            values.isTemporary && (!entry || values.startDate !== entry.startDate) &&
                            <li>{t('organization_exceptions__start_date_label')}: <b>{moment(values.startDate, "YYYY-MM-DD").format(localeDateFormat)}</b></li>
                        }
                        {
                            values.isTemporary && (!entry || values.endDate !== entry.endDate) &&
                            <li>{t('organization_exceptions__end_date_label')}: <b>{moment(values.endDate, "YYYY-MM-DD").format(localeDateFormat)}</b></li>
                        }
                        {
                            (!entry || values.comments !== entry.comments) &&
                            <li>{t('organization_exceptions__comments_label')}: <b>{values.comments}</b></li>
                        }
                        </ul>
                    </>
                }
                if (await confirm(dialogProps)) {
                    setRequestPending(true);
                    const updatedExceptions = [
                        ...(exceptions?.filter(e => e !== entry)?.map(mapExceptionToExceptionInput) || []),
                        {
                            organizationExceptionId: values.organizationExceptionId,
                            comments: values.comments,
                            exceptionTypeCode: values.exceptionTypeCode,
                            organizationID: values.organizationID,
                            isPermanent: !values.isTemporary,
                            startDate: values.isTemporary ? values.startDate : null,
                            endDate: values.isTemporary ? values.endDate : null
                        }
                    ];
                    update({
                        variables: {
                            providerId: providerId,
                            organizationExceptions: updatedExceptions
                        }
                    }).then(() => { setInEdit(false); formik.resetForm(); }).finally(() => setRequestPending(false));
                }
            }
        }
    });

    const handleDelete = async () => {
        const dialogProps = {
            title: t('common__delete_entry_dialog_title'),
            message: t('common__delete_entry_dialog_text')
        }
        if (await confirm(dialogProps)) {
            setRequestPending(true);
            update({
                variables: {
                    providerId: providerId,
                    organizationExceptions: [
                        ...(exceptions?.filter(e => e !== entry)?.map(mapExceptionToExceptionInput) || [])
                    ]
                }
            }).then(() => setInEdit(false)).finally(() => setRequestPending(false));
        }
    }
    
    const hasChanges = formik.dirty 
        && (formik.values.exceptionTypeCode !== entry?.exceptionTypeCode
            || formik.values.comments !== entry?.comments
            || formik.values.endDate !== entry?.endDate
            || formik.values.startDate !== entry?.startDate
            || (entry ? formik.values.isTemporary !== !(entry!.isPermanent) : true)
            || formik.values.organizationID !== entry?.organizationID);

    const deleteDisabled = updateMutation.loading || cachePendingUpdate;
    const submitDisabled = deleteDisabled || !hasChanges || !formik.isValid;

    const handleCancelChanges = async () => {
        if (hasChanges) {
            const dialogProps = {
                title: t('common__discard_changes_dialog_title'),
                message: t('common__discard_changes_dialog_text')
            }
            if (await confirm(dialogProps)) {
                formik.resetForm();
                setInEdit(false);
            }
        } else {
            formik.resetForm();
            setInEdit(false);
        }
    }

    const handleStartDateChange = (date: Moment | null) => {
        if (date) {
            formik.setFieldValue('startDate', date.format('YYYY-MM-DD'));
            const endDate = moment(formik.values.endDate, 'YYYY-MM-DD');
            if (date.isAfter(endDate)) {
                formik.setFieldValue('endDate', date.format('YYYY-MM-DD'));
            }
        }
    }

    const handleEndDateChange = (date: Moment | null) => {
        if (date) {
            formik.setFieldValue('endDate', date.format('YYYY-MM-DD'));
            const startDate = moment(formik.values.startDate, 'YYYY-MM-DD');
            if (date.isBefore(startDate)) {
                formik.setFieldValue('startDate', date.format('YYYY-MM-DD'));
            }
        }
    }

    // const exceptionTypes = useMemo(() => 
    //     refDataQuery.data?.refValues?.exceptionTypes
    //         ?.map(e => ({title: e.title, value: Number(e.value)}))
    //         ?.filter(e => e.value === EXCLUSION_TYPE_CODE)
    // , [refDataQuery.data?.refValues?.exceptionTypes]);

    const exceptionTypes = [{
        title: t('organization_exceptions__exclusion_value'),
        value: EXCLUSION_TYPE_CODE
    }]

    const handleOrganizationFilterChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const searchInput = event.target.value;
        setOrganizationsSearchInput(searchInput);
    }

    useEffect(() => {
        if (organizationsSearchInput.length >= 3) {
            const searchInput = organizationsSearchInput.toLowerCase();
            const cacheKeys = Object.keys(organizationsCache);
            const keyExists = cacheKeys.some(key => searchInput.includes(key));
            if (!keyExists) {
                debouncedFetchOrganizations(searchInput);
            }
        }
    }, [organizationsSearchInput]);

    const handleOrganizationChange = (_, value) => {
        if (value) {
            formik.setFieldValue('organizationID', Number(value.value))
        }
    }

    const filteredOrganizations = useMemo(() => {
        const cacheKeys = Object.keys(organizationsCache);
        const searchInput = organizationsSearchInput.toLowerCase();
        const cacheKey = cacheKeys.find(key => searchInput.includes(key));
        return cacheKey
            ? organizationsCache[cacheKey].filter(e => e.title.toLowerCase().includes(searchInput))
            : [];
    }, [organizationsSearchInput, organizationsCache]);

    const organizationValue = useMemo(() => {
        const keys = Object.keys(organizationsCache);
        for (const key of keys) {
            const res = organizationsCache[key].find(e => e.value === formik.values.organizationID);
            if (res) {
                return res;
            }
        }
        return null;
    }, [organizationsCache, formik.values.organizationID]);
    
    return !entry && !inEdit
        ? <Button variant="text" onClick={() => setInEdit(true)} className="add_entry_button">
            <AddIcon/>{t('common__add_entry_button_label')}
        </Button>
        : <SectionContainer elevation={0} radius="small" inEdit={inEdit} onEditClick={() => setInEdit(true)}>
            {
                inEdit
                ? (
                    <Grid className="credentialing_sub_section_form" container component='form' onSubmit={formik.handleSubmit} spacing={3}>
                        {
                            (requestPending || cachePendingUpdate) &&
                            <Spinner fillParent backdrop />
                        }
                        <Grid item xs={12} xl={12}>
                            <Select
                                value={formik.values.exceptionTypeCode ?? ''}
                                label={t('organization_exceptions__type_label')}
                                name="exceptionTypeCode"
                                onChange={formik.handleChange}
                                className="provider_details_input"
                                error={Boolean(formik.errors.exceptionTypeCode)}
                            >
                                {
                                    exceptionTypes?.map(e => (
                                        <MenuItem value={e.value} key={e.value}>
                                            {e.title}
                                        </MenuItem>
                                    ))
                                }
                            </Select>
                        </Grid>
                        <Grid item xs={12} xl={12}>
                            <Autocomplete
                                value={organizationValue}
                                options={filteredOrganizations}
                                isOptionEqualToValue={(option, value) => option.value === value.value}
                                getOptionLabel={(option) => option.title}
                                className="provider_details_input"
                                loading={organizationsDataQuery.loading}
                                onChange={handleOrganizationChange}
                                popupIcon={<KeyboardArrowDownRoundedIcon/>}
                                renderInput={(params) => (
                                    <TextBox
                                        label={t('organization_exceptions__organization_label')}
                                        helperText={t('organization_exceptions__organization_helper_text')}
                                        onChange={handleOrganizationFilterChange}
                                        endAdornment={(
                                            <React.Fragment>
                                                {organizationsDataQuery.loading ? <CircularProgress color="inherit" size={20} /> : null}
                                                {params.InputProps.endAdornment}
                                            </React.Fragment>
                                        )}
                                        {...params}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12} xl={12}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={formik.values.isTemporary}
                                        onChange={formik.handleChange}
                                        name="isTemporary"
                                        color="primary"
                                    />
                                }
                                label={t('organization_exceptions__is_temporary_label') as string}
                            />
                        </Grid>
                        {
                            formik.values.isTemporary &&
                            <>
                                <Grid item xs={12} xl={6}>
                                    <DateInput
                                        value={moment(formik.values.startDate, 'YYYY-MM-DD')}
                                        label={t('organization_exceptions__start_date_label')}
                                        className="provider_details_input"
                                        // name="startDate"
                                        // format={localeDateFormat}
                                        onChange={handleStartDateChange}
                                        // error={Boolean(formik.errors.startDate)}
                                    />
                                </Grid>
                                <Grid item xs={12} xl={6}>
                                    <DateInput
                                        value={moment(formik.values.endDate, 'YYYY-MM-DD')}
                                        label={t('organization_exceptions__end_date_label')}
                                        className="provider_details_input"
                                        // name="endDate"
                                        // format={localeDateFormat}
                                        onChange={handleEndDateChange}
                                        // error={Boolean(formik.errors.endDate)}
                                    />
                                </Grid>
                            </>
                        }
                        <Grid item xs={12} xl={12}>
                            <TextBox
                                value={formik.values.comments}
                                label={t('organization_exceptions__comments_label')}
                                name="comments"
                                onChange={formik.handleChange}
                                className="provider_details_input"
                                error={Boolean(formik.errors.comments)}
                            />
                        </Grid>
                        <Grid item xs={12} container spacing={2}>
                            <Grid item>
                                <Button type="submit" variant="opaque" disabled={submitDisabled}>{t_common('save')}</Button>
                            </Grid>
                            <Grid item>
                                <Button type="button" variant="outline" onClick={handleCancelChanges}>{t_common('cancel')}</Button>
                            </Grid>
                            {
                                entry && inEdit && ((exceptions?.length ?? 0) > 1) &&
                                <Grid item>
                                    <Button type="button" variant="text" onClick={handleDelete} disabled={deleteDisabled}>{t_common('delete')}</Button>
                                </Grid>
                            }
                        </Grid>
                    </Grid>
                )
                : (
                    entry &&
                    <Grid container>
                        <Grid item xs={12} sm={6}>
                            <p className='global__paragraph'>
                                {`${entry.exceptionTypeString} ${entry.isPermanent ? t('organization_exceptions__permament_flag') : t('organization_exceptions__temporary_flag')}`}
                            </p>
                            <p className='global__paragraph--small'>{t('organization_exceptions__type_label')}</p>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <p className='global__paragraph'>{entry.organizationString}</p>
                            <p className='global__paragraph--small'>{t('organization_exceptions__organization_label')}</p>
                        </Grid>
                        {
                            !entry.isPermanent &&
                            <>
                                <Grid item xs={12} sm={6}>
                                    <p className='global__paragraph'>{entry.startDate ? formatCredentialDate(entry.startDate) : t('n_a','N/A')}</p>
                                    <p className='global__paragraph--small'>{t('organization_exceptions__start_date_label')}</p>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <p className='global__paragraph'>{entry.endDate ? formatCredentialDate(entry.endDate) : t('n_a','N/A')}</p>
                                    <p className='global__paragraph--small'>{t('organization_exceptions__end_date_label')}</p>
                                </Grid>
                            </>
                        }
                        <Grid item xs={12} sm={12}>
                            <p className='global__paragraph'>{entry.comments}</p>
                            <p className='global__paragraph--small'>{t('organization_exceptions__comments_label')}</p>
                        </Grid>
                    </Grid>
                )
            }
        </SectionContainer>
}
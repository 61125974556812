import { gql, TypedDocumentNode } from '@apollo/client';
import { IApptCancellationReasonsForAffiliate, IApptCancellationReasonsForStaff, IApptCancellationStatusesResponse, IApptMismatchReasonsResponse, IGtApptStatusResponse, ILtApptStatusResponse, IPostApptStatusResponse, ISessionStatusResponse } from './interfaces';


export const SESSION_STATUS_LIST_QUERY: TypedDocumentNode<ISessionStatusResponse, {  }> = gql`
    query SESSION_STATUS_LIST_QUERY {
        sessionStatuses {
            title
            value
        }
    }
`

export const POST_APPOINTMENT_STATUS_LIST_QUERY: TypedDocumentNode<IPostApptStatusResponse, {  }> = gql`
    query POST_APPOINTMENT_STATUS_LIST_QUERY {
        postApptSessionStatuses {
            title
            value
        }
    }
`

export const LT24_APPOINTMENT_STATUS_LIST_QUERY: TypedDocumentNode<ILtApptStatusResponse, {  }> = gql`
    query LT24_APPOINTMENT_STATUS_LIST_QUERY {
        lt24HoursSessionStatuses {
            title
            value
        }
    }
`


export const GT24_APPOINTMENT_STATUS_LIST_QUERY: TypedDocumentNode<IGtApptStatusResponse, {  }> = gql`
    query GT24_APPOINTMENT_STATUS_LIST_QUERY {
        gt24HoursSessionStatuses {
            title
            value
        }
    }
`


export const APPOINTMENT_CANCELLATION_REASONS_QUERY: TypedDocumentNode<IApptCancellationStatusesResponse, {  }> = gql`
    query APPOINTMENT_CANCELLATION_REASONS_QUERY {
        providerSessionCancellationReasons {
            title
            value
        }
    }
`

export const APPOINTMENT_CANCELLATION_REASONS_FOR_STAFF_QUERY: TypedDocumentNode<IApptCancellationReasonsForStaff, {  }> = gql`
    query APPOINTMENT_CANCELLATION_REASONS_FOR_STAFF_QUERY {
        staffProviderSessionCancellationReasons {
            title
            value
        }
    }
`

export const APPOINTMENT_CANCELLATION_REASONS_FOR_AFFILIATE_QUERY: TypedDocumentNode<IApptCancellationReasonsForAffiliate, {  }> = gql`
    query APPOINTMENT_CANCELLATION_REASONS_FOR_AFFILIATE_QUERY {
        affiliateProviderSessionCancellationReasons {
            title
            value
        }
    }
`

export const APPOINTMENT_MISMATCH_REASONS_QUERY: TypedDocumentNode<IApptMismatchReasonsResponse, {  }> = gql`
    query APPOINTMENT_MISMATCH_REASONS_QUERY {
        firstAppointmentMismatchReasons {
            title
            value
        }
    }
`
import { FC, useEffect } from 'react';
import { IPageMetadata } from './interfaces';

export const usePageMetadata = (title: string, description: string) =>{
    const defaultTitle = "The Provider Hub by Telus Health";
    const defaultDesc = "meta description";

    useEffect(() => {
        document.title = title || defaultTitle;
        document.querySelector("meta[name='description']")?.setAttribute("content", description || defaultDesc);
    }, [title, description]);
};

export const PageMetadata: FC<IPageMetadata> = ({ pageTitle, pageDescription }) => {
    usePageMetadata(pageTitle, pageDescription);
    return null;
};
import { useMutation, useQuery } from '@apollo/client';
import React, { FC, useMemo } from 'react';
import { Link, useNavigate, useParams, useLocation } from 'react-router-dom';
import { CLOSE_ACTIVITY_MUTATION, CLOSE_CASE_CHECKLIST_QUERY } from '../queries';
import { CaseManagementActionContainer, Spinner } from 'components/Shared';
import { useTranslation } from 'react-i18next';
import { SessionsStep } from '../components/SessionsStep';
import { FormsStep } from '../components/FormsStep';
import { CloseReason, SessionStatus } from '../interfaces';
import { useSnackbar } from 'notistack';
import { GetFirstAssessmentFormsIncomplete, GetLastAssessmentFormsIncomplete } from '../../utils/formStatusUtils';
import { PageMetadata } from 'components/Shared/PageMetadata';
import './CloseCaseChecklist.scss';
import { Button } from 'components/Shared/Inputs';

interface CloseCaseChecklistViewProps {}

export const CloseCaseChecklistView: FC<CloseCaseChecklistViewProps> = (_) => {
    const { activityId: activityIdString } = useParams<{activityId: string}>();
    const { enqueueSnackbar } = useSnackbar();
    const location = useLocation();
    const { t, ready: translationReady } = useTranslation(['assessments', 'common'], { useSuspense: false });
    const navigate = useNavigate();

    const activityId = Number.parseInt(activityIdString as string);
    
    const activityQuery = useQuery(CLOSE_CASE_CHECKLIST_QUERY, {
        variables: { activityId: activityId },
        fetchPolicy: 'network-only',
        errorPolicy: 'all'
    });

    const [closeActivity, closeCaseMutation] = useMutation(CLOSE_ACTIVITY_MUTATION, {
        variables: { activityId: activityId }, 
        onCompleted: (data) => {
            enqueueSnackbar(t('case_closed_success_message'), { variant: 'success' });
            const reason = data.closeActivity.reason;
            if (reason === CloseReason.COMPLETED) {
                // navigate(`/clients/${activityId}`, { replace: true });
                // HACK ALERT!!! due to a PHQ9 bug, we need to do a browser reload for now
                if (typeof window !== 'undefined') {
                    window.location.pathname = `/clients/${activityId}`;
                }
            } else {
                navigate(`/clients`, { replace: true });
            }
        }
    });

    const activity = activityQuery.data?.activity;
    const forms = activityQuery.data?.forms;

    const hasAttendedSession = useMemo(() =>
        Boolean(activity?.sessions.find(session =>
            session.sessionStatusId === SessionStatus.COMPLETE)),
        [activity?.sessions]
    );
    const hasNotCompletedSession = useMemo(() =>
        Boolean(activity?.sessions.find(session =>
            !session.sessionStatusId || session.sessionStatusId === SessionStatus.ACTIVE)),
        [activity?.sessions]
    );
    
    const client: any | undefined = location.state?.client ?? activity?.client;

    const preAssessmentsIncomplete = useMemo(() => {
        return hasAttendedSession && activity && forms && GetFirstAssessmentFormsIncomplete(forms, activity.firstAppointmentAssessmentFormNames);
    }, [hasAttendedSession, forms, activity]);

    const postAssessmentsIncomplete = useMemo(() => {
        return hasAttendedSession && activity && forms && GetLastAssessmentFormsIncomplete(forms, activity.lastAppointmentAssessmentFormNames);
    }, [hasAttendedSession, forms, activity]);

    const renderContinueButton = () => {
        if (preAssessmentsIncomplete) {
            return <Button component={Link} variant='opaque' to={{pathname:`/clients/${activityId}/forms/first_appointment_assessment`}}>
                    {t('complete_pre_assessments_button_label')}
                </Button>
        } else if (postAssessmentsIncomplete) {
            return <Button component={Link} variant='opaque' to={{pathname:`/clients/${activityId}/forms/last_appointment_assessment`}}>
                    {t('complete_post_assessments_button_label')}
                </Button>
        } else {
            return <Button variant='opaque' onClick={() => closeActivity()}>
                    {t('close_case_button_label')}
                </Button>
        }
    }

    const clientName = client ? `${client.firstName} ${client.lastName}` : undefined;

    return (
        <CaseManagementActionContainer onBackClick={() => navigate(-1)} activityId={activityId}
            clientName={clientName} contentMaxWidth='md'
            headerMaxWidth='xl'
        >
            {
                translationReady && 
                <PageMetadata 
                    pageDescription={t('close_case_page_description')} 
                    pageTitle={t('close_case_page_title')}
                /> 
            }
            <h1>{translationReady && t('close_case_checklist_view_header')}</h1>
            {
                activityQuery.loading || !translationReady
                ? <Spinner className="close_case_checklist_view_spinner"/>
                : <>
                    {
                        hasNotCompletedSession
                        ? <SessionsStep activityId={activityId} sessions={activity?.sessions}/>
                        : <>
                            {
                                closeCaseMutation.loading &&
                                <Spinner fillParent backdrop/>
                            }
                            {
                                hasAttendedSession
                                ? activity && <FormsStep activity={activity} activityId={activityId} forms={forms} />
                                : <span>{t('close_case_checklist_no_sessions_attended_message')}</span>
                            }
                            {
                                renderContinueButton()
                            }
                        </>
                    }
                </>
            }
        </CaseManagementActionContainer>
    )
}
import React, { FC, useEffect, useState } from 'react';
import { useMutation } from '@apollo/client';
import { Identification } from './Identification';
import { UPDATE_ABN_MUTATION } from './queries';
import { useTranslation } from 'react-i18next';

interface IdentificationNPIProps {
    providerId: number,
    ABN?: string,
    onABNUpdated: (npi: string) => void
}

export const IdentificationABN : FC<IdentificationNPIProps> = (props) => {
    const { providerId, ABN, onABNUpdated} = props;
    
    const { t, ready: translationsReady } = useTranslation('providerprofile', { useSuspense: false });
    
    const [cachePendingUpdate, setCachePendingUpdate] = useState(false);
    
    const [updateNPI, updateNPIMutation] = useMutation(UPDATE_ABN_MUTATION, {
        update: (_, result) => {
            const value = result.data?.updateCredentials?.credential?.businessNumber;
            if (value && value !== ABN) {
                onABNUpdated(value)
            }
        }
    });

    useEffect(() => {
        setCachePendingUpdate(false);
    }, [ABN]);
    
    const updateFn = (identifier) => updateNPI({variables: { providerId: providerId, businessNumber: identifier }});

    const validator = /^(\d *?){11}$/;

    const labels = {
        sectionHeader: t('identification_abn__section_title'),
        sectionDescription: t('identification_abn__section_description'),
        inputLabel: t('identification_abn__input_label'),
        noData: t('identification_abn__no_data')
    }

    return !translationsReady
        ? <></>
        : <Identification identifier={ABN} updateFn={updateFn} mutation={updateNPIMutation}
            validator={validator} labels={labels} cachePendingUpdate={cachePendingUpdate}/>
}

export const MODEL_18 = 18;
export const MODEL_4 = 4;
export const MODEL_17 = 17;
export const MODEL_1 = 1;
export const MODEL_5 = 5;
export const MODEL_6 = 6;
export const MODEL_8 = 8;
export const MODEL_10 = 10;
export const MODEL_12 = 12;
export const MODEL_20 = 20;
export const MODEL_19 = 19;
export const MODEL_21 = 21;
export const MODEL_22 = 22;

export const THREE_HOURS_VALUE = 3;
export const THREE_APPOINTMENTS_VALUE = 3;
export const ONE_APPOINTMENTS_VALUE = 1;
export const TWO_APPOINTMENTS_VALUE = 2;
export const FOUR_APPOINTMENTS_VALUE = 4;
export const modelShouldUseMaxSessionHours =  (modelId: number) => modelId >= MODEL_1 && modelId <= MODEL_17
export const modelShouldUseMaxClientHours = (modelId: number) => modelId === MODEL_18 || modelId === MODEL_20
export const modelShouldUseUniqueModel19Hours = (modelId: number) => modelId === MODEL_19;

export enum ActivityModelModes {
    MAX_HOURS_PER_CASE = 'MAX_HOURS_PER_CASE',
    MAX_HOURS_PER_CLIENT = 'MAX_HOURS_PER_CLIENT',
    THREE_HOURS_MODE = 'THREE_HOURS_MODE',
    NONE = 'NONE'
}

export const determineModelMode = (modelId: number | undefined): ActivityModelModes => {
    if (modelId) {
        if (modelShouldUseMaxSessionHours(modelId)) {
            return ActivityModelModes.MAX_HOURS_PER_CASE  
        }
        if (modelShouldUseUniqueModel19Hours(modelId)) {
            return ActivityModelModes.THREE_HOURS_MODE
        }
        if (modelShouldUseMaxClientHours(modelId)) {
            return ActivityModelModes.MAX_HOURS_PER_CLIENT 
        }
    }   
    return ActivityModelModes.NONE;
}


export const determineRemainingBasedOnModelId = (clientAvailableHours, sessionRemainingHours, modelId: number | undefined) => {
    const modelMode: ActivityModelModes = determineModelMode(modelId)
    switch (modelMode) {
        case ActivityModelModes.MAX_HOURS_PER_CASE:
            return sessionRemainingHours
        case ActivityModelModes.THREE_HOURS_MODE:
        case ActivityModelModes.MAX_HOURS_PER_CLIENT:
            return clientAvailableHours
        default:
            return null;
    }
}

export const determineMaxBasedOnModelId = (clientMaxHours, sessionMaxCapHours, modelId: number | undefined) => {
    const modelMode: ActivityModelModes = determineModelMode(modelId)
    switch (modelMode) {
        case ActivityModelModes.MAX_HOURS_PER_CASE:
            return sessionMaxCapHours
        case ActivityModelModes.MAX_HOURS_PER_CLIENT:
            return clientMaxHours
        case ActivityModelModes.THREE_HOURS_MODE:
            return THREE_HOURS_VALUE
        default:
            return null;
    }
}

export const determineUsedHoursBasedOnModelId = (clientUsedHours, totalSessionUsedHours, modelId: number | undefined) => {
    const modelMode: ActivityModelModes = determineModelMode(modelId)
    switch (modelMode) {
        case ActivityModelModes.MAX_HOURS_PER_CASE:
            return totalSessionUsedHours
        case ActivityModelModes.THREE_HOURS_MODE:
        case ActivityModelModes.MAX_HOURS_PER_CLIENT:
            return clientUsedHours
        default:
            return null;
    }
}
import { gql, TypedDocumentNode } from '@apollo/client';
import { ProfessionalDesignation, ProfessionalDesignationInput } from '../../../interfaces';

export const PROFESSIONAL_DESIGNATIONS_REF_DATA: TypedDocumentNode<ProfessionalDesignationsRefDataData, ProfessionalDesignationRefDataVars> = gql`
    query PROFESSIONAL_DESIGNATIONS_REF_DATA {
        professionalDesignations {
            title
            value
        }
    }
`

interface ProfessionalDesignationsRefDataData {
    professionalDesignations: {
        title: string,
        value: string
    }[]
}

interface ProfessionalDesignationRefDataVars {}


export const UPDATE_PROFESSIONAL_DESIGNATION_MUTATION
    : TypedDocumentNode<UpdateProfessionalDesignationMutationData, UpdateProfessionalDesignationMutationVars> = gql`
    mutation UPDATE_PROFESSIONAL_DESIGNATION_MUTATION($providerId: Long!, $professionalDesignation: [ProfessionalDesignationInput]) {
        updateCredentials(providerId: $providerId, professionalDesignation: $professionalDesignation) {
            providerId
            credential {
                professionalDesignation {
                    professionalDesignationId
                    providerProfessionalDesignationId
                    specialization
                }
            }
        }
    }
`

export interface UpdateProfessionalDesignationMutationData {
    updateCredentials: {
        credential: {
            professionalDesignation: ProfessionalDesignation[]
        }
    }
}

export interface UpdateProfessionalDesignationMutationVars {  
    providerId: number,
    professionalDesignation: ProfessionalDesignationInput[]
}
import React, { FC, useMemo, useState } from "react";
import { Container } from '@mui/material';
import { RequestsTable, RequestsDialog } from 'components/Requests';
import './Requests.scss'
import { useTranslation } from "react-i18next";
import { DataRequest } from "components/Shared/MaterialTable";
import { ActivityStatus } from "constants/activityStatus";
import { OverHoursApprovalDTO } from "components/Requests/interfaces";
import { Button } from "components/Shared/Inputs";

export interface IRequestProps {
    activity: {
        activityId: number,
        providerId: number,
        contractAllowsAdditionalHours: boolean,
        activityStatusCode: number
    },
    approvals: OverHoursApprovalDTO[] | undefined,
    onAdditionalHoursRequestCreated: () => void
}

export const Requests: FC<IRequestProps> = (props) => {
    const { activity, approvals, onAdditionalHoursRequestCreated } = props;
    const { t } = useTranslation('requests', { useSuspense: false });

    const [dialogOpen, setDialogOpen] = useState(false);

    const onClickRequestHours = () => setDialogOpen(true)

    const [ dataRequest, setDataRequest ] = useState<DataRequest>({
        sorts: {
            "requestID": "asc"
        },
        take: 10,
        skip: 0,
        search: ''
    });

    const approvalsWithRequest = useMemo(() =>
        approvals?.filter(approval => Boolean(approval.request))
    , [approvals]);

    const handleRequestCreated = () => {
        setDialogOpen(false);
        onAdditionalHoursRequestCreated();
    }

    return (
        <Container maxWidth="lg">
            <div className="requests_header">
                <h2>
                    {t('additional_hours_requests')}
                </h2>
                {
                    activity?.contractAllowsAdditionalHours && activity?.activityStatusCode === ActivityStatus.Active &&
                    <Button variant="outline" disabled={false} onClick={onClickRequestHours}>{t('new_request')}</Button>
                }
            </div>
                <RequestsTable showActivityInfo={false} data={approvalsWithRequest} dataLoading={false}
                    dataRequest={dataRequest} setDataRequest={setDataRequest}/>
            <RequestsDialog activityId={activity.activityId} providerId={activity.providerId} open={dialogOpen}
                onClose={() => setDialogOpen(false)} onRequestCreated={handleRequestCreated}
            />
        </Container>
    )

}
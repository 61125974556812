import React, { createContext, FC } from 'react';
import { SizeMe } from 'react-sizeme';

interface ScreenSizeProviderProps {}

interface ScreenSizeContextProps {
    width: number,
    height: number,
    isLandscape: boolean,
    isMobile: boolean
}

const ScreenSizeContext = createContext<ScreenSizeContextProps>({
    width: 0,
    height: 0,
    isLandscape: true,
    isMobile: false
});
ScreenSizeContext.displayName = "ScreenSizeContext";

const ScreenSizeProvider: FC<ScreenSizeProviderProps> = (props) => {
    const isLandscape = (width: number, height: number) => {
        return width >= height;
    }

    const isMobile = (width: number, height: number) => {
        return isLandscape(width, height)
            ? height <= 640
            : width <= 640
    }
    
    return (
        <SizeMe monitorHeight={true} noPlaceholder>{
            ({ size }) => (
                <>
                    <div style={{width: '100vw', height: '100vh', position:'fixed', left: 0, top: 0, zIndex: -1, pointerEvents: 'none'}} />
                    <ScreenSizeContext.Provider
                        value={{
                            width: size.width ?? 0,
                            height: size.height?? 0,
                            isLandscape: isLandscape(size.width ?? 0, size.height ?? 0),
                            isMobile: isMobile(size.width ?? 0, size.height ?? 0)
                        }}
                        {...props}
                    />
                </>
            )
        }
        </SizeMe>
    )
}

const useScreenSize = () => React.useContext(ScreenSizeContext);

export { ScreenSizeProvider, useScreenSize };
import { gql, TypedDocumentNode } from '@apollo/client';
import { LoginResponse, ProviderData, ProviderLite } from './interfaces';

const PROVIDER_SUMMARY_FRAGMENT = gql`
    fragment ProviderSummary on Provider {
        firstName
        lastName
        providerId
        isAdmin
        timeZone
        employmentTypeId
        isManaged
        isProvider
        preferredLanguageCode
        associateWithVendor
        vendor {
            vendorId
            name
        }
    }
`

// export const PROVIDER_SUMMARY_QUERY = gql`
//     query PROVIDER_SUMMARY_QUERY($providerId: Long!) {
//         provider(id: $providerId) {
//             ...ProviderSummary
//         }
//     },
//     ${PROVIDER_SUMMARY_FRAGMENT}
// `

// export interface ProviderSummaryQueryVars {
//     providerId: number
// }

// export interface ProviderSummaryQueryData {
//     provider: ProviderData
// }

export const PROVIDERS_SUMMARY_QUERY = gql`
    query PROVIDERS_SUMMARY_QUERY($vendorId: Long!) {
        providers(vendorId: $vendorId) {
            ...ProviderSummary
        }
    },
    ${PROVIDER_SUMMARY_FRAGMENT}
`

export const PROVIDERS_LITE_QUERY = gql`
    query PROVIDERS_LITE_QUERY($vendorId: Long!) {
        providersLite(vendorId: $vendorId) {
            firstName
            lastName
            providerId
            isManaged
            vendor {
                vendorId
                name
            }
        }
    }
`

export interface ProvidersSummaryQueryVars {
    vendorId: number
}

export interface ProvidersSummaryQueryData {
    providers: ProviderData[]
}

export interface ProvidersLiteQueryData {
    providersLite: ProviderLite[]
}


const LOGIN_RESPONSE_FRAGMENT = gql`
    fragment authData on LoginResponse {
        userAuthData {
            providerId
            profileId
            email
            firstName
            lastName
            providerConnectUser
            token
            provider {
                firstName
                lastName
                providerId
                isAdmin
                timeZone
                employmentTypeId
                isManaged
                isProvider
                preferredLanguageCode
                vendor {
                    vendorId
                    name
                }
                associateWithVendor
            }
            providerDetails {
                associateWithVendor
            }
        }
        effectiveUserAuthData {
            providerId
            profileId
            email
            firstName
            lastName
            providerConnectUser
            token
            provider {
                firstName
                lastName
                providerId
                isAdmin
                timeZone
                employmentTypeId
                isManaged
                isProvider
                preferredLanguageCode
                vendor {
                    vendorId
                    name
                }
                associateWithVendor
            }
            providerDetails {
                associateWithVendor
            }
        }
    }
`;

export const LOGIN_MUTATION: TypedDocumentNode<LoginMutationData, LoginMutationVars> = gql`
    mutation LOGIN_MUTATION($username: String, $password: String, $rememberMe: Boolean!) {
        login(login: {username: $username, password: $password, rememberMe: $rememberMe}) {
            ...authData
        }
    },
    ${LOGIN_RESPONSE_FRAGMENT}
`;

export interface LoginMutationData {
    login: LoginResponse
}

export interface LoginMutationVars {
    username: string,
    password: string,
    rememberMe: boolean
}

export const SWITCH_PROFILE_MUTATION: TypedDocumentNode<SwitchProfileMutationData, SwitchProfileMutationVars> = gql`
    mutation SWITCH_PROFILE_MUTATION($providerId: Long!) {
        switchProfile(providerId: $providerId) {
            ...authData
        }
    },
    ${LOGIN_RESPONSE_FRAGMENT}
`
export interface SwitchProfileMutationData {
    switchProfile: LoginResponse
}

export interface SwitchProfileMutationVars {
    providerId: number
}


export const LOGOUT_MUTATION = gql`
    mutation LOGOUT_MUTATION {
        logout
    }
`;

export const REFRESH_MUTATION: TypedDocumentNode<RefreshMutationData, RefreshMutationVars> = gql`
    mutation REFRESH_MUTATION {
        refresh {
            ...authData
        }
    },
    ${LOGIN_RESPONSE_FRAGMENT}
`;

export interface RefreshMutationData {
    refresh: LoginResponse
}
export interface RefreshMutationVars {
    refresh: LoginResponse
}

export const FORGOT_PASSWORD_MUTATION = gql`
    mutation FORGOT_PASSWORD_MUTATION($username: String, $email: String, $resetUrl: String) {
        forgetPassword(userName: $username, email: $email, resetUrl: $resetUrl) 
    }
`;

export interface ForgotPasswordMutationVars {
    username: string,
    email: string,
    resetUrl: string
}

export const RESET_PASSWORD_MUTATION = gql`
    mutation RESET_PASSWORD_MUTATION($password: String, $token: String) {
        resetPassword(password: $password, token: $token)
    }
`;

export interface ResetPasswordMutationVars {
    password: string,
    token: string
}

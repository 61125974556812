import React from 'react';
import { useTranslation } from 'react-i18next';

const ActivityCompleted = 'ActivityCompleted';

export const ActivityCompletedPrompt = (_) => {
    const { t, ready } = useTranslation('prompts', { useSuspense: false });

    const header = t(ActivityCompleted + '-header');
    const body = t(ActivityCompleted + '-body');
    return <>
        { 
            ready &&
            <div className="activity_completed_card_wrapper">
                <section className="content_wrapper">
                    <h2 className="global__header1 color--brand1">
                        {header}
                    </h2>
                    <p className="global__paragraph">
                        {body}
                    </p>
                </section>
            </div>
        }
        
    </>
}
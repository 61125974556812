import { DURATION_CONSTANTS_QUERY } from "./queries";
import { useQuery } from "@apollo/client";
import { IDurationConstant } from "./interfaces";

export const useDurationConstants = (): [IDurationConstant[] | undefined, boolean] => {
    const wspTaskCodesQuery = useQuery(
        DURATION_CONSTANTS_QUERY, 
        {}
    );
    return [wspTaskCodesQuery?.data?.appointmentDurations, wspTaskCodesQuery.loading]
} 

export const FIFTEEN_MINUTES_DURATION_ORDINAL = '1';

export const ONE_HOUR_DURATION_ORDINAL = '4';

export const transformDurationKeyToHours = (durationKey?: number | string) => Number(durationKey) * 0.25;
export const transformHoursToDurationKey = (hours?: number): string | undefined => hours ? (hours * 4)?.toFixed() : undefined;

import React, { FC, useEffect } from 'react';
import { Tabs, Tab, Button, Container } from '@mui/material';
import { ProviderDetails, PracticeInfo, Unavailabilities, Credentialing, Settings, GroupPractice } from './components/Tabs';
import { Link, Routes, Route, useLocation, useParams, Navigate } from 'react-router-dom';
import { useAuth } from 'components/Auth';
import { useLazyQuery } from '@apollo/client';
import { PROVIDER_SUMMARY_QUERY } from './queries';
import { Spinner } from 'components/Shared';
import BackIcon from '@mui/icons-material/ArrowBackRounded';
import { useTranslation } from 'react-i18next';
import { PageMetadata } from 'components/Shared/PageMetadata';

import './ProviderProfile.scss';

export const ProviderProfile: FC = () => {
    const { t, ready } = useTranslation('providerprofile', { useSuspense: false });
    const { providerId } = useParams<{providerId?: string}>();
    const providerIdNum = Number.parseInt(providerId || '-1');
    const { effectiveProviderData } = useAuth();
    const location = useLocation();

    const [getProviderData, providerDataQuery] = useLazyQuery(PROVIDER_SUMMARY_QUERY);
    
    useEffect(() => {
        if (providerIdNum !== -1 && providerIdNum !== effectiveProviderData?.providerId) {
            getProviderData({
                variables: {
                    providerId: providerIdNum
                }
            });
        }
    }, [effectiveProviderData, providerIdNum]);

    const provider = providerDataQuery.data?.provider || effectiveProviderData;

    return (
        <React.Fragment>
            {
                ready && 
                <PageMetadata 
                    pageDescription={t('provider_page_description')} 
                    pageTitle={t('provider_page_title')}
                /> 
            }
            <div className="provider_profile_view_wrapper">
            {
                !provider || providerDataQuery.loading
                ? <Spinner fillParent />
                : <Container className="global_provider_profile_container" maxWidth="lg">
                    {
                        location.pathname.startsWith('/providers') && 
                        <React.Fragment>
                            { ready && 
                                <PageMetadata 
                                    pageDescription={t('provider_profile_page_description')} 
                                    pageTitle={t('provider_profile_page_title')}
                                />
                            }
                            <Button variant="text" component={Link} to="/providers"
                                startIcon={<BackIcon/>} color="primary">{t('back')}</Button>
                        </React.Fragment>
                    }
                    <h1 className="provider_name">{`${provider?.firstName} ${provider?.lastName}`}</h1>
                    <Tabs
                        className="provider_profile_tabs"
                        value={location.pathname.replace(/\/profile(\/)?/, "") || "/"}
                        variant='scrollable'
                        scrollButtons='auto'
                    >
                        <Tab className="profile_tab" label={t('my_details_tab', 'My Details')} value='/' component={Link} to="/profile" />
                        <Tab className="profile_tab" label={t('practice_info_tab', 'Practice Info')} value='practiceinfo' component={Link} to="practiceinfo" />
                        {
                            effectiveProviderData?.associateWithVendor && effectiveProviderData?.vendor &&
                            <Tab className="profile_tab" label={t('group_practice_tab', 'Group practice')} value='group_practice' component={Link} to="group_practice" />
                        }
                        <Tab className="profile_tab" label={t('credentialing_tab', 'Credentialing')} value='credentialing' component={Link} to="credentialing" />
                        <Tab className="profile_tab" label={t('availability_header', 'My Availabilities')} value='availabilities' component={Link} to="availabilities" />
                        <Tab className="profile_tab" label={t('settings_tab', 'Settings')} value='settings' component={Link} to="settings" />
                    </Tabs>
                    {
                        provider &&
                        <Routes>
                            <Route
                                path="/"
                                element={<ProviderDetails providerId={provider.providerId} />}
                            />
                            <Route
                                path="/practiceinfo"
                                element={<PracticeInfo providerId={provider.providerId} />}
                            />
                            <Route
                                path="/group_practice"
                                element={
                                    (effectiveProviderData?.associateWithVendor && effectiveProviderData?.provider?.vendor)
                                        ? <GroupPractice vendorId={effectiveProviderData?.provider?.vendor?.vendorId} vendorName={effectiveProviderData?.provider?.vendor?.name} />
                                        : <Navigate to={"/"} />
                                }
                            />
                            <Route
                                path="/credentialing"
                                element={<Credentialing providerId={provider.providerId} />}
                            />
                            <Route
                                path="/availabilities"
                                element={<Unavailabilities providerId={provider.providerId} managedCalendar={provider.hasManagedCalendar} />}
                            />
                            <Route
                                path="/settings"
                                element={<Settings providerId={provider.providerId} />}
                            />
                        </Routes>
                    }
                </Container>
                }
            </div>
        </React.Fragment>
    )
}

import { gql, TypedDocumentNode } from '@apollo/client';
import { ProfessionalDevelopment, ProfessionalDevelopmentInput } from '../../../interfaces';

export const PROFESSIONAL_DEVELOPMENT_REF_DATA
    : TypedDocumentNode<ProfessionalDevelopmentRefDataData, ProfessionalDevelopmentRefDataVars> = gql`
    query PROFESSIONAL_DEVELOPMENT_REF_DATA {
        types {
            title
            value
        }
        training {
            title
            value
        }
    }
`

interface ProfessionalDevelopmentRefDataData {
    types: {
        title: string,
        value: string
    }[],
    training: {
        title: string,
        value: string
    }[]
}

interface ProfessionalDevelopmentRefDataVars {}


export const UPDATE_PROFESSIONAL_DEVELOPMENT_MUTATION
    : TypedDocumentNode<UpdateProfessionalDevelopmentMutationData, UpdateProfessionalDevelopmentMutationVars> = gql`
    mutation UPDATE_PROFESSIONAL_DEVELOPMENT_MUTATION($providerId: Long!, $professionalDevelopment: [ProfessionalDevelopmentInput]) {
        updateCredentials(providerId: $providerId, professionalDevelopment: $professionalDevelopment) {
            providerId
            credential {
                professionalDevelopment {
                    dateCompleted
                    trainingCode
                    training
                    type
                    typeString
                    providerProfessionalDevelopmentId
                }
            }
        }
    }
`

export interface UpdateProfessionalDevelopmentMutationData {
    updateCredentials: {
        credential: {
            professionalDevelopment: ProfessionalDevelopment[]
        }
    }
}

export interface UpdateProfessionalDevelopmentMutationVars {  
    providerId: number,
    professionalDevelopment: ProfessionalDevelopmentInput[]
}
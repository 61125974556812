import { Assessment } from "components/Forms/interfaces";

export enum FormStatus {
    Completed = 'completed',
    Incomplete = 'incomplete',
    Warning = 'warning',
    Declined = 'declined'
}

export interface ActivityData {
    service: string,
    client: {
        firstName: string,
        lastName: string
    }
    sessions: Session[],
    firstAppointmentAssessmentFormNames: {
        templateName: string,
        mandatory: boolean
    }[],
    lastAppointmentAssessmentFormNames: {
        templateName: string,
        mandatory: boolean
    }[],
    mandatoryAssessments: Assessment[]
}

export interface FormSummary {
    id: string,
    completed?: boolean | null,
    templateName: string,
    sessionId: number,
    stage: string,
    preAssessmentStageStatus: FormStatus,
    postAssessmentStageStatus: FormStatus
}

export interface FormResult {
    id: string,
    result?: string
}

export interface Session {
    duration: number,
    sessionId: number,
    startDateTimeUTC: string,
    sessionStatusId?: SessionStatus
}

export enum SessionStatus {
    ACTIVE = 1,
    NO_SHOW = 2,
    COMPLETE = 3,
    VOID = 4,
    CANCELLED_LT_24H = 5   //this should map to Symphoni Session status 5
}

export enum CloseResult {
    ERROR = 'ERROR',
    CLOSED = 'CLOSED',
    VOIDED = 'VOIDED'
}

export enum CloseReason
{
    COMPLETED = 'COMPLETED',
    NO_SHOW = 'NO_SHOW',
    PROVIDED_CANCELLED = 'PROVIDED_CANCELLED',
    CANCELLED_LT_24 = 'CANCELLED_LT_24',  //this should map to Symphoni Session status 5
    CANCELLED_GT_24 = 'CANCELLED_GT_24', //this should map to Symphoni Session status 4 (void)
    CLIENT_UNREACHABLE = 'CLIENT_UNREACHABLE',
    OTHER = 'OTHER'
}

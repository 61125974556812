import { Moment } from 'moment-timezone';
import { useAuth } from 'components/Auth';
import { useTranslation } from 'react-i18next';
import * as momentDateFormats from './momentDateFormats'
import {  } from '@mui/lab';
import { getDateInputMask, getDateTimeInputFormat, getDateTimeInputMask, getTimeInputMask } from './utils'
import moment from 'moment';

export interface ITimezoneUtils {
    formatToProviderDay: (date: Date | Moment | string | null) => string,
    formatToProviderTime: (date: Date | Moment | string | null) => string
    formatDate: (date: Date | Moment | string | null, momentFormatString: string) => string
}

export const DATE_FORMATS = Object.freeze({
    ISO: "YYYY-MM-DD",
    DMY: "DD/MM/YY",
    DMYY: "DD/MM/YYYY",
    MDY: "MM/DD/YY",
    MDYY: "MM/DD/YYYY",
})

export const useTimezoneUtils = () => {
    const { effectiveProviderData } = useAuth();
    const { i18n } = useTranslation();
    // const { moment } = useUtils();

    const hasMeridianFormat = moment.localeData(i18n.language).longDateFormat('LT').includes('A')
    const localeDateFormat = moment.localeData(i18n.language).longDateFormat('L')
    const localeTimeFormat = hasMeridianFormat ?
    'hh:mm A'
    : 'HH:mm'

    const dateTimeInputFormat = getDateTimeInputFormat(localeDateFormat, localeTimeFormat)

    const dateOnlyInputMask = getDateInputMask(localeDateFormat)
    const timeOnlyInputMask = getTimeInputMask(localeTimeFormat);
    const dateTimeInputMask = getDateTimeInputMask(localeDateFormat, localeTimeFormat)

    const timeZone = effectiveProviderData?.timeZone || moment().tz() || 'Etc/UTC';

    const getTimzoneAdjustedMomentDate = (date: Date | Moment | string | null): Moment => {
        return moment(date).tz(timeZone).locale(i18n.language);
    };

    const getProviderTimezoneOffset = () => {
        return moment.tz(moment.utc(), timeZone).utcOffset()
    }

    /**
     * @param date Date or ISO string
     * @param momentFormatString Moment format string to use for formatting the date - for example, HH:mm
     * Generic utility to format a date using any kind of provided format string
     */
    const formatDate = (date: Date | Moment | string | null, momentFormatString?: string): string => {
        return getTimzoneAdjustedMomentDate(date).format(momentFormatString);
    };

    /**
     * @param date the date to be formatted
     * Produces output in the format: 3:32 PM Or 13:10 depending on i18n locale
     */
    const formatToProviderTime = (date: Date | Moment | string | null): string => {
        return formatDate(date, momentDateFormats.TIME_PORTION_MOMENT_FORMAT);
    };

    /**
     * @param date the date to be formatted
     * Produces output in the format: 09/08/2020. Useful for iniatializing Date objects with that value for inputs or methods that require JavaScript Date objects 
     * to be used
     */
    const formatToProviderDay = (date: Date | Moment | string | null): string => {
        return formatDate(date, momentDateFormats.DATE_PORTION_MOMENT_FORMAT);
    };

    const formatDayWithoutTime = (date: Date | Moment | string | null, momentFormatString?: string): string => {
        return moment(date, 'YYYY-MM-DD').locale(i18n.language).format(momentFormatString);
    };

    /**
     *
     * @param {string} format template for formatting of date using momentjs syntax
     * @param {string} [targetTimezone] target timezone for formatted date
     * Produces a function which formats date to specified format using i18n locale
     */
    const createDateFormatFunction = (format: string, targetTimezone?: string) => {
        return (date: Date | Moment | string | null) => {
            return moment.tz(date, targetTimezone ?? timeZone)
                .locale(i18n.language)
                .format(format);
        }
    }

    
    /**
     * @param date the date to be be transformed
     * 
     * Transforms a passed date object to another Date object, keeping the same day/time but adjusting the UTC offset to match the Provider's timezone.
     * Useful in situations where components or elements expect to work using Date objects, but we need to re-interpret those dates in the Provider's timezone.
     */
    const transformDateToProviderTimezoneDate = (date: Date | Moment | string | null): Date => {
        const providerTzOffset = getProviderTimezoneOffset()
        return moment(date).utcOffset(providerTzOffset, true).toDate()
    }

    const formatDateWithoutTimezoneConversion = (date: Date | Moment | string | null, momentFormatString?: string): string => {
        return moment(date).locale(i18n.language).format(momentFormatString);
    }

    const createDateFormatFunctionWithoutTimezoneConversion = (format: string) => {
        return (date: Date | Moment | string | null) => {
            return formatDateWithoutTimezoneConversion(date, format)
        }
    }

    const getTimezoneDiff = (timezoneOlsonName = timeZone) => {
        const now = moment.tz(timeZone);
        const providerOffset = now.utcOffset();
        now.tz(timezoneOlsonName);
        const clientOffset = now.utcOffset();
        const diffInMinutes = clientOffset - providerOffset;
        return diffInMinutes / 60;
    }

    return {
        formatToProviderDay,
        formatToProviderTime,
        formatDate,
        transformDateToProviderTimezoneDate,
        createDateFormatFunction,
        formatDateWithoutTimezoneConversion,
        createDateFormatFunctionWithoutTimezoneConversion,
        formatDayWithoutTime,
        getTimezoneDiff,
        dateTimeInputMask,
        dateTimeInputFormat,
        dateOnlyInputMask,
        timeOnlyInputMask,
        localeDateFormat,
        localeTimeFormat,
        hasMeridianFormat,
        ...momentDateFormats
    };
};

export default useTimezoneUtils;

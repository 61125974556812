import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import './NewVersionMessage.scss';

interface NewVersionMessageProps {}

export const NewVersionMessage: FC<NewVersionMessageProps> = (_) => {
    const { t } = useTranslation('navigation', { useSuspense: false });

    const handleReloadClick = () => {
        if ('serviceWorker' in navigator) {
            navigator.serviceWorker.getRegistration()
                .then((registration) => {
                    if (registration?.waiting) {
                        registration.waiting.postMessage('SKIP_WAITING');
                    }
                })
                .finally(() => {
                    window.location.replace(window.location.href);
                });
        } else {
            window.location.replace(window.location.href);
        }
    }

    return (
        <div className="new_version_available_message_container" role="alert" aria-live="polite">
            {t('new_version_available_message', 'New version of Provider Hub is available!')}
            <button className="inline_button reload_page_button" onClick={handleReloadClick}>
                {t('reload_button_label', 'Click here to reload!')}
            </button>
        </div>
    )
}
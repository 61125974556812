import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { ColumnDefinition } from 'components/Shared/MaterialTable';
import { ToDo } from '../interfaces';
import { StepDefinition } from '../constants';
import { getToDoLink } from '../utils';

export const useToDosColumnsDefinitions = (): ColumnDefinition[] => {
    const { t } = useTranslation('todos', { useSuspense: false });

    const getLinkToActivityPage = (toDo: ToDo) => (
        toDo.activityInfo?.activityId
            ? `/clients/${toDo.activityInfo.activityId}`
            : '/clients'
    )

    const renderActionCell = (toDo: ToDo) => (
        // toDo.step === StepDefinition.RESUBMIT_FORM_STEP
        //     ? <Link to={{ pathname: `${getToDoLink(toDo)}`, state: { workflowId: toDo.id, activity: toDo.activityInfo, fromToDo: true }}}>
        //         {t(`${toDo.formCategoryName}-name`)}
        //     </Link>
        //     :
            <Link to={{ pathname: `${getToDoLink(toDo)}`}} state={{ workflowId: toDo.id, activity: toDo.activityInfo, fromToDo: true }}>
                {t(`${toDo.step}-name`)}
            </Link>
    )

    // Client Name can be undefined
    const renderClientNameCell = (toDo: ToDo) => (
        toDo.activityInfo?.clientName ?? ''
    )

    const renderGoToClientLink = (toDo: ToDo) => (
        toDo.step !== StepDefinition.CONFIRM_REFERRAL
            ? <Link to={{ pathname: getLinkToActivityPage(toDo) }}>
                {toDo.activityInfo?.activityId}
            </Link>
            : <span className="global__paragraph global__paragraph--light">
                {toDo.activityInfo?.activityId}
            </span>
    )

    return [
        {
            title: t('action_column_title'),
            field: 'action',
            template: renderActionCell
        }, {
            title: t('client_column_title'),
            field: 'client',
            template: renderClientNameCell
        }, {
            title: t('activity_id_column_title'),
            field: 'activity_id',
            template: renderGoToClientLink
        }
    ];
}
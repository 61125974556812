import { useMutation } from '@apollo/client';
import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ProfessionalDesignation as IProfessionalDesignation } from '../../../interfaces';
import { SectionContainer } from '../../Other/SectionContainer';
import { UPDATE_PROFESSIONAL_DESIGNATION_MUTATION } from './queries';
import { ProfessionalDesignationForm } from './ProfessionalDesignationForm';
import { useFeatureManager } from 'hooks/useFeatureManager';

interface ProfessionalDesignationProps {
    providerId: number,
    professionalDesignations?: IProfessionalDesignation[],
    onProfessionalDesignationsUpdated: (professionalDesignations: IProfessionalDesignation[]) => void
}

export const ProfessionalDesignation: FC<ProfessionalDesignationProps> = (props) => {
    const { providerId, professionalDesignations, onProfessionalDesignationsUpdated } = props;
    const { t, ready: translationsReady } = useTranslation('providerprofile', { useSuspense: false });

    const { isProviderProfileSelfServeFeatureEnabled } = useFeatureManager();

    const [cachePendingUpdate, setCachePendingUpdate] = useState(false);

    const [update, updateMutation] = useMutation(UPDATE_PROFESSIONAL_DESIGNATION_MUTATION, {
        update: (_, result) => {
            if (result.data?.updateCredentials?.credential?.professionalDesignation) {
                setCachePendingUpdate(true);
                onProfessionalDesignationsUpdated(result.data.updateCredentials.credential.professionalDesignation);
            }
        }
    });

    useEffect(() => {
        setCachePendingUpdate(false);
    }, [professionalDesignations]);
    
    return !translationsReady
        ? <></>
        : <SectionContainer title={t('professional_designation__section_title')}>
            {
                professionalDesignations?.map((designation, index) => (
                    <ProfessionalDesignationForm key={index}
                        providerId={providerId}
                        entry={designation}
                        data={professionalDesignations}
                        mutation={[update, updateMutation]}
                        cachePendingUpdate={cachePendingUpdate}
                    />
                ))
            }
            {
                isProviderProfileSelfServeFeatureEnabled() &&
                <ProfessionalDesignationForm
                    providerId={providerId}
                    entry={undefined}
                    data={professionalDesignations}
                    mutation={[update, updateMutation]}
                    cachePendingUpdate={cachePendingUpdate}
                />
            }
        </SectionContainer>
}
import React, { FC, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { TemplateNames } from '../../constants';
import { getFirstSession } from 'components/CaseManagement/utils/sessionUtils';
import { useMutation, useQuery } from '@apollo/client';
import { CREATE_FORM_BY_TEMPLATE, GET_FORMS_BY_ACTIVITY, UPDATE_FORM } from '../../queries';
import { PROVIDER_HUB } from 'constants/targetedSystem';
import { FormViewContainer } from '../../components/FormViewContainer';
import { Spinner } from 'components/Shared';
import { FormPresenter } from '../../components/FormPresenter';
import { useAuth } from 'components/Auth';
import { AssessmentPage, IFormInfo } from '../../interfaces';
import { AfterAssessmentCompletedPrompt } from '../../components/AfterAssessmentCompletedPrompt';
import { FullScreenMessage } from 'components/Shared/FullScreenMessage';
import { Button } from 'components/Shared/Inputs';
import { useTranslation } from 'react-i18next';

interface LastAppointmentAssessmentViewProps { }

export const LastAppointmentAssessmentView: FC<LastAppointmentAssessmentViewProps> = (_) => {
    const { activityId: activityIdString } = useParams<{activityId: string}>();
    const { providerData, effectiveProviderData } = useAuth();
    const { t } = useTranslation('assessments', { useSuspense: false });
    
    const activityId = parseInt(activityIdString || '');
    
    const [forms, setForms] = useState<IFormInfo[] | undefined>(undefined);
    const [pages, setPages] = useState<AssessmentPage[]>([]);
    const [currentPageIdx, setCurrentPageIdx] = useState(0);

    const activityDataQuery = useQuery(GET_FORMS_BY_ACTIVITY, {
        variables: {
            activityId: activityId,
            tags: ['last_appointment']
        },
        errorPolicy: 'all',
        fetchPolicy: 'no-cache',
        onCompleted: (data) => {
            setForms(data.forms);
            setPages(data.activity.lastAppointmentAssessmentFormNames
                .filter(assessment => assessment.mandatory)
                .map<AssessmentPage>(assessment => ({
                    templateName: assessment.templateName,
                    mandatory: assessment.mandatory,
                    completed: false
                })));
        }
    });

    const [createForm, createFormMutation] = useMutation(CREATE_FORM_BY_TEMPLATE, {
        onCompleted: (data) => {
            setForms([...(forms ?? []), data.createFormByTemplate]);
        }
    });

    const [updateForm, updateFormMutation] = useMutation(UPDATE_FORM);

    const activity = activityDataQuery.data?.activity;

    const isActivityInactive = activity?.activityStatusCode !== 1;
    const readOnly = isActivityInactive || activity?.targetedSystem?.systemCode !== PROVIDER_HUB;
    const firstSession = getFirstSession(activity?.sessions);

    const currentPage = pages[currentPageIdx];
    
    const form = useMemo(() => 
        forms?.find(form => form.templateName === currentPage?.templateName)
    , [forms, currentPage?.templateName]);

    useEffect(() => {
        if (forms && !form && currentPage?.templateName) {
            createForm({
                variables: {
                    templateName: currentPage.templateName,
                    form: {
                        activityId: activityId,
                        providerId: providerData?.providerId || effectiveProviderData?.providerId || -1,
                        createdBy: `${effectiveProviderData?.providerId}`,
                        sessionId: firstSession?.sessionId
                    }
                }
            })
        }
    }, [forms, form, currentPage?.templateName]);

    const templateDefaultValuesMap = {
        [TemplateNames.Facesheet]: {
            _stage: 'last_appointment'
        },
        [TemplateNames.WOS]: {
            _stage: 'last_appointment',
            _show_offwork_question: false
        },
        [TemplateNames.PHQ9]: {
            _stage: 'last_appointment'
        },
        [TemplateNames.Gad7]: {
            _stage: 'last_appointment'
        },
        [TemplateNames.FollowUp]: {
            _stage: 'last_appointment'
        }
    }

    const markPageComplete = (page: number) => {
        if (page < pages.length) {
            setPages([
                ...pages.slice(0, page),
                { ...pages[page], completed: true },
                ...(page < pages.length - 1
                    ? pages.slice(page + 1)
                    : [])
            ]);
        }
    }
    
    const handleSkipClick = () => {
        markPageComplete(currentPageIdx);
        setTimeout(moveToNextPage, 0);
    }

    const moveToNextPage = () => {
        if (currentPageIdx < pages.length - 1) {
            setCurrentPageIdx(currentPageIdx + 1);
        }
    }

    const clientName = `${activity?.client.firstName} ${activity?.client.lastName}`;

    const handleFormSubmit = (result) => {
        form && updateForm({
            variables: {
                data: result,
                formId: form?.id,
                isCompleted: true
            }
        }).then(() => {
            markPageComplete(currentPageIdx);
            setTimeout(moveToNextPage, 0);
        });
    }

    const allAssessmentsCompleted = useMemo(() =>
        Boolean(pages.length) && !pages.some(assessment => !assessment.completed)
    , [pages]);

    const isLoading = activityDataQuery.loading || createFormMutation.loading;

    return (
        <FormViewContainer activityId={activityId} clientName={clientName}>
            {
                (activityDataQuery.loading || createFormMutation.loading) &&
                <Spinner fillParent/>
            }
            {
                updateFormMutation.loading &&
                <Spinner fillParent backdrop/>
            }
            {
                !allAssessmentsCompleted && !activityDataQuery.loading 
                    ? form
                        ? <FormPresenter
                            activityId={activityId}
                            sessionId={firstSession?.sessionId ?? null}
                            form={form}
                            readOnly={readOnly}
                            onSubmit={handleFormSubmit}
                            defaultValues={templateDefaultValuesMap[form?.templateName]}
                            onSkip={handleSkipClick}
                        />
                        : !isLoading && <FullScreenMessage title={t('assessment_not_found_message')}>
                            <Button onClick={handleSkipClick}>{t('continue_to_next_assessment_button_label')}</Button>
                        </FullScreenMessage>
                    : !isLoading && <AfterAssessmentCompletedPrompt activityId={activityId} isFirst={false} clientName={clientName}/>
            }
        </FormViewContainer>
    )
}

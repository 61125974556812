import { InvoiceBillable } from "../interfaces";
import { isWspSessionBillable, isWspTaskBillable } from '../utils/billableUtils'

export const useBillables = (billables?: InvoiceBillable[], isWspActivity?: boolean) => {
    let wspBillables: InvoiceBillable[] = []
    let sessionBillables: InvoiceBillable[] = []

    if (!isWspActivity) {
        wspBillables = []
        sessionBillables = billables ?? []
    } else {
        billables?.forEach((billable) => {     
            if (isWspSessionBillable(billable)) {
                return sessionBillables.push(billable)
            }
            if (isWspTaskBillable(billable)) {
                return wspBillables.push(billable)
            }
        })
    }

    return { wspBillables, sessionBillables }
}
import React from 'react';
import { InvoiceTableItem } from '../interfaces';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { ColumnDefinition } from 'components/Shared/MaterialTable';
import useTimezoneUtils from 'hooks/useTimezoneUtils';
import { toCurrencyString } from 'utils';
import { Tooltip } from '@mui/material';
import ErrorIcon from '@mui/icons-material/ErrorOutline';

export const useInvoicesColumnsDefinitions = (): ColumnDefinition[] => {
    const { t, i18n } = useTranslation('invoicing', { useSuspense: false });

    const { createDateFormatFunction, DATE_PORTION_MOMENT_FORMAT } = useTimezoneUtils();
    const formatInvoiceDate = createDateFormatFunction(DATE_PORTION_MOMENT_FORMAT);

    const renderInvoiceNumberCell = (invoice: InvoiceTableItem) => (
        invoice?.providerInvoiceNumber ?? '---'
    );

    const renderSubmittedDateCell = (invoice: InvoiceTableItem) => (
        invoice.submittedDateUtc
            ? formatInvoiceDate(invoice.submittedDateUtc)
            : '---'
    );

    const renderCaseAcceptedDateCell = (invoice: InvoiceTableItem) => (
        invoice.caseAcceptedDateUtc
            ? formatInvoiceDate(invoice.caseAcceptedDateUtc)
            : '---'
    );

    const renderInvoiceIdCell = (invoice: InvoiceTableItem) => (
        <div style={{ display: 'flex' }}>
            <Link to={{ pathname: invoice?.isActivityAvailable
                ? `/clients/${invoice.activityId}/invoices/${invoice.invoiceId}`
                : `/invoicing/${invoice.invoiceId}`}}
                style={{ margin: 0 }}
            >
                {invoice.invoiceId}
                {
                    invoice?.isActivityAvailable !== true && <span className="visually_hidden">{t('invoice_id_cell_tooltip')}</span>
                }
            </Link>
            {
                invoice?.isActivityAvailable !== true &&
                <div aria-hidden>
                    <Tooltip title={t('invoice_id_cell_tooltip')?.toString() ?? ''}>
                        <ErrorIcon style={{ marginLeft: '5px', width: '20px', height: '20px' }} />
                    </Tooltip>
                </div>
            }
        </div> 
    );

    const renderTotalAmountCell = (invoice: InvoiceTableItem) => (
        toCurrencyString(invoice.grandTotal, i18n.language, invoice.currencyCode) ?? '---'
    );
    
    return [
        {
            title: t('invoice_id'),
            field: 'invoiceId',
            sortable: true,
            template: renderInvoiceIdCell
        },
        {
            title: t('number'),
            field: 'providerInvoiceNumber',
            sortable: true,
            template: renderInvoiceNumberCell,
            ariaLabel: t('number_column_label')
        },
        {
            title: t('invoice_submitted'),
            field: 'submittedDateUtc',
            sortable: true,
            template: renderSubmittedDateCell
        },
        {
            title: t('case_accepted'),
            field: 'caseAcceptedDateUtc',
            sortable: true,
            template: renderCaseAcceptedDateCell
        },
        {
            title: t('activity_id'),
            field: 'activityId',
            sortable: true,
        },
        {
            title: t('client'),
            field: 'client',
            sortable: true,
        },
        {
            title: t('status'),
            field: 'statusString',
            sortable: true,
        },
        {
            title: t('amount'),
            field: 'grandTotal',
            sortable: true,
            template: renderTotalAmountCell
        }
    ];
}
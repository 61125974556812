import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { SectionContainer } from '../../Other/SectionContainer';

interface BillingAndPaymentProps {}

export const BillingAndPayment: FC<BillingAndPaymentProps> = (_) => {
    const { t, ready: translationsReady } = useTranslation('providerprofile', { useSuspense: false });
    
    return !translationsReady
        ? <></>
        : <SectionContainer title={t('billing_and_payment__section_title')}>
            <div dangerouslySetInnerHTML={{__html: t('billing_and_payment__section_content')}}/>
        </SectionContainer>
}
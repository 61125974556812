export enum DatePreference {
    aft = 'aft',
    any = 'any',
    bef = 'bef',
    on = 'on'
}

export enum TimePreference {
    aft = 'aft',
    aftn = 'aftn',
    any = 'any',
    bef = 'bef',
    eve = 'eve',
    mor = 'mor',
    nea = 'nea'
}

export interface FilterOptions {
    datePreference: DatePreference,
    timePreference: TimePreference,
    officeId: number | null,
    startDate: string,
    startTime: string
}
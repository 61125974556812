import React, { FC, useState } from 'react';
import { FormControl, FormLabel, MenuItem, RadioGroup } from '@mui/material';
import { Spinner } from 'components/Shared';
import { useTranslation } from 'react-i18next';
import { transformDurationKeyToHours, ONE_HOUR_DURATION_ORDINAL, transformHoursToDurationKey } from 'hooks/useDurationConstants';
import { useFormik } from 'formik';
import { GetSuggestedApptStatus } from '../../utils/updateApptStatusUtils';
import { Button, ButtonRadio, DateTimeInput, Select, TextBox } from 'components/Shared/Inputs';
import { ApptStatusFormValues, Session, UnattendedSessionAdditionalInfo } from '../../interfaces';
import { SESSION_STATUS } from '../../../constants';
import { IStatusList } from 'hooks/useSessionStatuses';
import { IDurationConstant } from 'hooks/useDurationConstants/interfaces';
import moment, { Moment } from 'moment';
import { MutationTuple } from '@apollo/client';
import { UpdateApptStatusMutationData, UpdateApptStatusMutationVars } from '../../queries';
import { ScheduleSlot } from 'components/Calendaring/AppointmentModals/interfaces';
import { ScheduleSearchDialog } from 'components/Calendaring';
import { isValid15MinuteIncrement } from 'utils/dateUtils';
import { useTimezoneUtils } from 'hooks/useTimezoneUtils';

import '../../views/UpdateApptStatus.scss';

const now = moment().startOf('hour');

interface ApptStatusFormProps {
    activityId: number,
    session: Session | undefined,
    submit: [(values: ApptStatusFormValues) => Promise<any>, boolean],
    mutation: MutationTuple<UpdateApptStatusMutationData, UpdateApptStatusMutationVars>,
    disabled: boolean,
    apptStatuses: IStatusList[] | undefined,
    durationConstants: IDurationConstant[] | undefined,
    apptVoidReasons: IStatusList[] | undefined,
    enableAdditionalInputs: boolean,
    isStaffProvider: boolean,
    timeZone: string
}

interface AppointmentStatusFormValues {
    appointmentStatusId: SESSION_STATUS,
    durationOrdinal: string,
    providerVoidReason: number | undefined,
    isLastAppointment: boolean | undefined,
    additionalInformation: string,
    attemptedToReschedule: boolean | undefined,
    rescheduleAttemptDate: Moment | undefined,
    succefullyRescheduled: boolean | undefined,
    rescheduledDate: Moment | undefined,
    reasonForNoReschedule: string,
    rescheduledSlot: ScheduleSlot | undefined
}

export const ApptStatusForm: FC<ApptStatusFormProps> = (props) => {
    const { activityId, session, submit, disabled, apptStatuses, durationConstants,
        isStaffProvider, apptVoidReasons, enableAdditionalInputs, timeZone } = props;
    const [handleSubmit, submitPending] = submit;
    const { t, ready: translationsReady } = useTranslation(['updateSessionStatus', 'caseManagement', 'common'], { useSuspense: false });

    const [showScheduleSearchDialog, setShowScheduleSearchDialog] = useState(false);
    const { formatDate } = useTimezoneUtils()

    const formik = useFormik<AppointmentStatusFormValues>({
        initialValues: {
            appointmentStatusId: (session?.providerHubAppointmentStatusId && session.providerHubAppointmentStatusId !== SESSION_STATUS.ACTIVE)
                ? session.providerHubAppointmentStatusId
                : GetSuggestedApptStatus(session?.startDateTimeUTC || now.toDate()),
            durationOrdinal: transformHoursToDurationKey(session?.duration) || ONE_HOUR_DURATION_ORDINAL,
            providerVoidReason: session?.providerHubAppointmentVoidReasonId,
            isLastAppointment: undefined,
            additionalInformation: '',
            attemptedToReschedule: undefined,
            rescheduleAttemptDate: now,
            succefullyRescheduled: undefined,
            rescheduledDate: now,
            reasonForNoReschedule: '',
            rescheduledSlot: undefined
        },
        onSubmit: (values) => {
            let additionalInfo: UnattendedSessionAdditionalInfo | undefined = undefined;

            if (showAdditionalInputs) {
                additionalInfo = {
                    unattendedReason: values.additionalInformation,
                    attemptToReach: values.attemptedToReschedule
                }
                if (additionalInfo.attemptToReach) {
                    additionalInfo.attemptDateOffset = values.rescheduleAttemptDate?.tz(timeZone, true)?.toISOString(true);
                    additionalInfo.ableToReschedule = values.succefullyRescheduled;
                    if (additionalInfo.ableToReschedule) {
                        if (isStaffProvider) {
                            additionalInfo.rescheduledAppointmentDateOffset = values.rescheduledSlot?.startDate?.toISOString(true);
                            additionalInfo.scheduleId = values.rescheduledSlot?.scheduleId;
                        } else {
                            additionalInfo.rescheduledAppointmentDateOffset = values.rescheduledDate?.tz(timeZone, true)?.toISOString(true);
                        }
                    } else {
                        additionalInfo.unableToRescheduleReason = values.reasonForNoReschedule
                    }
                }

            }
            
            handleSubmit({
                duration: values.appointmentStatusId === SESSION_STATUS.ATTENDED
                    ? transformDurationKeyToHours(values.durationOrdinal)
                    : 1,
                appointmentStatusId: values.appointmentStatusId,
                providerVoidReasonId: values.appointmentStatusId === SESSION_STATUS.CANCELLED_PROVIDER && values.providerVoidReason
                    ? values.providerVoidReason
                    : undefined,
                additionalInfo: additionalInfo
            });
        },
        validateOnMount: true,
        validateOnChange: true,
        validate: (values) => {
            const errors: Partial<Record<keyof AppointmentStatusFormValues, string>> = {};

            if (!Boolean(values.appointmentStatusId)) {
                errors.appointmentStatusId = 'Select appointment status';
            }
            if (values.appointmentStatusId === SESSION_STATUS.CANCELLED_PROVIDER && !Boolean(values.providerVoidReason)) {
                errors.providerVoidReason = 'Select void reason';
            }
            if (showAdditionalInputs) {
                if (!values.additionalInformation) {
                    errors.additionalInformation = 'Enter additional informations';
                }
                if ((values.attemptedToReschedule ?? null) === null) {
                    errors.attemptedToReschedule = 'Select if attempted to reschedule';
                } else if (values.attemptedToReschedule) {
                    if (!values.rescheduleAttemptDate) {
                        errors.rescheduleAttemptDate = 'Enter reschedule attempt date';
                    }
                    if ((values.succefullyRescheduled ?? null) === null) {
                        errors.succefullyRescheduled = 'Enter whether succesfully rescheduled';
                    } else if (values.succefullyRescheduled) {
                        if (isStaffProvider) {
                            if (!values.rescheduledSlot) {
                                errors.rescheduledSlot = 'Select schedule slot';
                            }
                        } else {
                            if (!values.rescheduledDate) {
                                errors.rescheduledDate = 'Enter rescheduled date';
                            } else if (values.rescheduledDate && !isValid15MinuteIncrement(values.rescheduledDate)) {
                                errors.rescheduledDate = 'Enter valid 15 minute increment';
                            }
                        }
                    }
                    if (!values.succefullyRescheduled && !values.reasonForNoReschedule) {
                        errors.reasonForNoReschedule = 'Enter reason why no reschedule';
                    }
                }
            }

            return errors;
        }
    });

    const handleApptStatusChange = (value: string) => {
        const intValue = Number.parseInt(value);
        formik.setFieldValue('appointmentStatusId', Number.isNaN(intValue) ? undefined : intValue)
            .then(() => formik.validateForm());
        formik.setFieldValue('additionalInformation', '');
        formik.setFieldValue('attemptedToReschedule', undefined);
        formik.setFieldValue('succefullyRescheduled', undefined);
        formik.setFieldValue('reasonForNoReschedule', '');
    }

    const handleVoidReasonChange = (value: string) => {
        const intValue = Number.parseInt(value);
        formik.setFieldValue('providerVoidReason', Number.isNaN(intValue) ? undefined : intValue);
    }

    const submitDisabled = disabled || !formik.isValid || submitPending;

    const handleAttemptedToRescheduleChange = (_, value) => {
        formik.setFieldValue('attemptedToReschedule', value === 'true');
    }

    const handleSuccessfullyRescheduledChange = (_, value) => {
        formik.setFieldValue('succefullyRescheduled', value === 'true');
    }

    const handleRescheduleAttemptDateChange = (date) => {
        formik.setFieldValue('rescheduleAttemptDate', date);
    }

    const handleRescheduledDateChange = (date) => {
        formik.setFieldValue('rescheduledDate', date);
    }
    
    const handleScheduleSlotChange = (slot) => {
        formik.setFieldValue("rescheduledSlot", slot);
        setShowScheduleSearchDialog(false);
    }

    const showAdditionalInputs = enableAdditionalInputs
        && formik.values.appointmentStatusId !== SESSION_STATUS.ATTENDED
        && formik.values.appointmentStatusId !== SESSION_STATUS.ACTIVE
        && formik.values.appointmentStatusId !== SESSION_STATUS.BOOKED_IN_ERROR;

    return <>
    {
        !translationsReady
        ? <Spinner/>
        : <form onSubmit={formik.handleSubmit} className="update_appt_status_step">
            <Select
                id="appt_status_dropdown"
                className="appt_status_dropdown"
                name="appointmentStatusId"
                value={formik.values.appointmentStatusId.toString() ?? ''}
                onChange={(event) => handleApptStatusChange(event.target.value as string)}
                label={t('appointment_status_input__label')}
                disabled={disabled}
                error={Boolean(formik.errors.appointmentStatusId)}
            >
            {
                apptStatuses?.map((status) => 
                    <MenuItem value={status.value} key={status.value}>
                        {status.title}
                    </MenuItem>    
                )
            }
            </Select>
            {
                formik.values.appointmentStatusId === SESSION_STATUS.ATTENDED &&
                <Select
                    id="appt_duration_dropdown"
                    className="appt_status_dropdown"
                    name="durationOrdinal"
                    value={formik.values.durationOrdinal}
                    onChange={formik.handleChange}
                    label={t('duration_input__label')}
                    disabled={disabled}
                    error={Boolean(formik.errors.durationOrdinal)}
                >
                {
                    durationConstants?.map((duration) => 
                        <MenuItem value={Number(duration.value)} key={duration.value}>
                            {duration.title}
                        </MenuItem>
                    )
                }
                </Select>
            }
            {
                formik.values.appointmentStatusId === SESSION_STATUS.CANCELLED_PROVIDER &&
                <Select
                    id="appt_void_reason_dropdown"
                    className="appt_status_dropdown"
                    name="providerVoidReason"
                    value={formik.values.providerVoidReason?.toString() ?? ''}
                    onChange={(event) => handleVoidReasonChange(event.target.value as string)}
                    label={t('status_void_reason_input__label')}
                    disabled={disabled}
                    error={Boolean(formik.errors.providerVoidReason)}
                >
                {
                    apptVoidReasons?.map((reason) => 
                        <MenuItem value={reason.value} key={reason.value}>
                            {reason.title}
                        </MenuItem>
                    )
                }
                </Select>
            }
            {
                showAdditionalInputs &&
                <>
                    <p>{t('not_attended_reason_input__description')}</p>
                    <TextBox
                        label={t('not_attended_reason_input__label')}
                        className="appt_status_dropdown"
                        name="additionalInformation"
                        value={formik.values.additionalInformation}
                        onChange={formik.handleChange}
                        disabled={disabled}
                        error={Boolean(formik.errors.additionalInformation)}
                        multiline
                        minRows={3}
                    />
                    <FormControl component="fieldset" className="appointment_status_radio_fieldset" error={Boolean(formik.errors.attemptedToReschedule)}>
                        <FormLabel component="legend">{t('attempted_to_reschedule_input__description')}</FormLabel>
                        <RadioGroup name="attemptedToReschedule" value={formik.values.attemptedToReschedule ?? ''} onChange={handleAttemptedToRescheduleChange}>
                            <ButtonRadio value={true} label={t('common:yes')} aria-label={t('common:yes')}/>
                            <ButtonRadio value={false} label={t('common:no')} aria-label={t('common:no')}/>
                        </RadioGroup>
                    </FormControl>
                    {
                        formik.values.attemptedToReschedule &&
                        <>
                            <p>{t('reschedule_attempt_date_input_description')}</p>
                            <DateTimeInput
                                label={t('reschedule_attempt_date_input_label')}
                                className="appt_status_dropdown"
                                value={formik.values.rescheduleAttemptDate}
                                // name="rescheduleAttemptDate"
                                onChange={handleRescheduleAttemptDateChange}
                            />
                            <FormControl component="fieldset" className="appointment_status_radio_fieldset" error={Boolean(formik.errors.succefullyRescheduled)}>
                                <FormLabel component="legend">{t('able_to_reschedule_input__description')}</FormLabel>
                                <RadioGroup name="succefullyRescheduled" value={formik.values.succefullyRescheduled ?? ''} onChange={handleSuccessfullyRescheduledChange}>
                                    <ButtonRadio value={true} label={t('common:yes')} aria-label={t('common:yes')}/>
                                    <ButtonRadio value={false} label={t('common:no')} aria-label={t('common:no')}/>
                                </RadioGroup>
                            </FormControl>
                            {
                                formik.values.succefullyRescheduled &&
                                <>
                                    <p>{t('rescheduled_date_input__description')}</p>
                                    {
                                        isStaffProvider
                                        ? <div className="schedule_slot_section">
                                            <p>{formik.values.rescheduledSlot?.startDate ? formatDate(formik.values.rescheduledSlot?.startDate, "L, LT") : '--'}</p>
                                            <Button variant="opaque" onClick={() => setShowScheduleSearchDialog(true)}>
                                                Search
                                            </Button>
                                            <ScheduleSearchDialog show={showScheduleSearchDialog} activityId={activityId}
                                                onCancel={() => setShowScheduleSearchDialog(false)} onContinue={handleScheduleSlotChange}/>
                                        </div>
                                        : <DateTimeInput
                                            label={t('rescheduled_date_input__label')}
                                            className="appt_status_dropdown"
                                            value={formik.values.rescheduledDate}
                                            minutesStep={15}
                                            // name="rescheduledDate"
                                            onChange={handleRescheduledDateChange}
                                        />
                                    }
                                </>
                            }
                            {
                                formik.values.succefullyRescheduled === false &&
                                <>
                                    <p>{t('no_reschedule_reson_input__description')}</p>
                                    <TextBox
                                        label={t('no_reschedule_reson_input__label')}
                                        className="appt_status_dropdown"
                                        name="reasonForNoReschedule"
                                        value={formik.values.reasonForNoReschedule}
                                        onChange={formik.handleChange}
                                        disabled={disabled}
                                        error={Boolean(formik.errors.reasonForNoReschedule)}
                                        multiline
                                        minRows={3}
                                    />
                                </>
                            }
                        </>
                    }
                </>
            }
            <Button type="submit" disabled={submitDisabled} className="submit_button">
                {t('update_status_button__label')}
            </Button>
        </form>
    }
    </>
}

import React, { FC, useEffect, useState } from 'react';
import { Link, Route, Routes, useLocation } from 'react-router-dom';
import { Tabs, Tab } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { PageMetadata } from 'components/Shared/PageMetadata';
import { TextBox } from 'components/Shared/Inputs';

import './LearnScreen.scss';

interface LearnScreenProps {
}

interface VideosArray {
    content: string;
    title: string;
}
interface DocumentsArray {
    link: string;
    title: string;
    sub_title?: string;
    type: string;
}

interface LearnContent {
    videos?: VideosArray[],
    documents?: DocumentsArray[],
    bell_documents?: DocumentsArray[]
}

interface TabContentProps {
    content: LearnContent;
    description?: string;
    title: string;
    section: "training-videos" | "bell-documents" | "documents-and-forms";
    searchLabel?: string;
}

const addTimestampQueryParamToUrl = (contentUrl: string) => {
    return `${contentUrl}&time=${Date.now()}`
}

const TabContent: FC<TabContentProps> = ({ content, description = "", title, section, searchLabel }) => {
    const [filterText, setFilterText] = useState<string>("");
    if (section === "training-videos" && content?.videos?.length) {
        return (
            <div className="videos_container_wrapper">
                <h2 className="videos_title global__header2">{title}</h2>
                <p className="videos_description global__paragraph global__paragraph--light">{description}</p>
                <div className="videos_wrapper">
                    {
                        content?.videos?.map((video, index) => (
                            <div className="video_div" key={index}>
                                <iframe className="iframe" src={addTimestampQueryParamToUrl(video.content)} title={video.title} allowFullScreen></iframe>
                                <h3 className="video_title global__header3 global__header3--light">{video.title}</h3>
                            </div>
                        ))
                    }
                </div>
            </div>
        )
    } else if (content?.bell_documents?.length || content?.documents?.length) {
        const docsKey = section === "bell-documents" ? "bell_documents" : "documents";
        return (
            <div className="resources_container_wrapper">
                <h2 className="resources_title global__header2">{title}</h2>
                {description && <p className="videos_description global__paragraph global__paragraph--light">{description}</p>}
                {searchLabel && <TextBox
                    label={searchLabel}
                    fullWidth
                    className="filter-textbox"
                    inputComponent="input"
                    value={filterText}
                    onChange={(e) => setFilterText(e.target.value)}
                />
                }
                <div className="resources_wrapper">
                    {
                        content?.[docsKey]
                            ?.filter(doc => doc.title.toLocaleLowerCase().includes(filterText?.toLocaleLowerCase()))
                            ?.map((document, index) =>
                                document?.link?.length > 0 ?
                                    (
                                        <div className="resource_div" key={index}>
                                            <a className="pdf_doc global__link" target="_blank" href={document.link} rel="noopener noreferrer">{document.title?.replaceAll("&nbsp;", " ")}</a>
                                        </div>
                                    )
                                    :
                                    (
                                        <div className="resource_div" key={index}>
                                            <span className="pdf_doc global__link">{document.title}</span>
                                        </div>
                                    ))
                    }
                </div>
            </div>
        )
    } else return null;
}

export const LearnScreen: FC<LearnScreenProps> = () => {
    const { t, i18n, ready } = useTranslation('learnScreen', { useSuspense: false });

    let { pathname } = useLocation();

    let content: LearnContent | undefined = undefined;

    if (ready) {
        for (let i = 0; i < i18n.languages.length && !content; i++) {
            const learnScreen = i18n.store.data?.[i18n.languages[i]]?.learnScreen;
            if (learnScreen) {
                content = learnScreen as LearnContent;
            }
        }
    }

    useEffect(() => {
        const linkHandler: EventListener = (e: Event) => {
            if ((e.target as HTMLDivElement).className === 'resource_div') {
                e.stopPropagation();
                (e.target as HTMLDivElement).querySelector('a')?.click();
            }
        };
        document.addEventListener('click', linkHandler, true);
        return () => document.removeEventListener('click', linkHandler);
    }, []);

    return (
        <React.Fragment>
            {ready &&
                <PageMetadata
                    pageDescription={t('learn_screen_page_description')}
                    pageTitle={t('learn_screen_page_title')}
                />
            }
            <div className="global_learn_screen_wrapper">
                <h1 className="global__header1 page_title">{t('learn_center', 'Learn Center')}</h1>
                <Tabs
                    indicatorColor="primary"
                    variant="scrollable"
                    scrollButtons={false}
                    value={pathname.replace(/\/learn(\/)?/, "") || "training-videos"}
                >
                    <Tab className="button_tab" component={Link} value="training-videos" to="training-videos" label={t('training_videos', 'Training videos')}></Tab>
                    {!!content?.bell_documents?.length && <Tab className="button_tab" component={Link} value="bell-documents" to="bell-documents" label={t('bell_specific_documents', 'Bell specific documents')}></Tab> }
                    <Tab className="button_tab" component={Link} value="documents-and-forms" to="documents-and-forms" label={t('documents_and_forms', 'Documents and forms')}></Tab>
                </Tabs>
                <section className="learn_container_wrapper_section">
                    <Routes>
                        <Route 
                            path="/"
                            element={
                                <TabContent
                                    content={content as LearnContent}
                                    section="training-videos"
                                    title={t('training_videos', 'Training Videos')}
                                    description={t('training_videos_desc', 'This is series of videos is about how to get started with Provider Hub.')}
                                />
                            }
                        />
                        <Route
                            path="/training-videos"
                            element={
                                <TabContent
                                    content={content as LearnContent}
                                    section="training-videos"
                                    title={t('training_videos', 'Training Videos')}
                                    description={t('training_videos_desc', 'This is series of videos is about how to get started with Provider Hub.')}
                                />
                            }
                        />
                        <Route
                            path="/bell-documents"
                            element={
                                <TabContent
                                    section="bell-documents"
                                    content={content as LearnContent}
                                    title={t('bell_specific_documents', 'Bell specific documents')}
                                    description={t('bell_specific_documents_desc')}
                                    searchLabel={t('filter_label_hint')}
                                />
                            }
                        />
                        <Route
                            path="/documents-and-forms"
                            element={
                                <TabContent
                                    section="documents-and-forms"
                                    content={content as LearnContent}
                                    title={t('documents_and_forms', 'Documents and forms')}
                                    description={t('documents_and_forms_desc')}
                                    searchLabel={t('filter_label_hint')}
                                />
                            }
                        />
                    </Routes>
                </section>
            </div>
        </React.Fragment>
    )
}

import React, { useState, FunctionComponent, } from 'react';
import { Spinner } from 'components/Shared';
import { useTranslation } from 'react-i18next';
import moment, { Moment } from 'moment';
import useTimezoneUtils from 'hooks/useTimezoneUtils';
import { Button } from 'components/Shared/Inputs';
import { Alert } from '@mui/material';
import { DateInput } from 'components/Shared/Inputs';

function getAllowableServiceDate(serviceParamDays: number) {
    const date = moment();
    date.add(serviceParamDays, 'days');
    return date;
}

interface FirstAvailableDateProps {
    onSaveDate: (date: Moment) => void,
    onSaveDateOutsideParams: (date: Moment) => void,
    activity: any,
    loading: boolean,
    slaDate?: string
}

export const FirstAvailableDate: FunctionComponent<FirstAvailableDateProps> = (props) => {
    const { t } = useTranslation(['caseManagement', 'common'], { useSuspense: false });

    const { onSaveDate, onSaveDateOutsideParams, activity, loading, slaDate } = props;
    const [ date, setDate ] = useState(moment());
    const { formatDate, localeDateFormat } = useTimezoneUtils();

    const allowableDate = (slaDate ? moment(slaDate) : getAllowableServiceDate(5)).startOf('day');

    const handleDateChange = (date: Moment | null) => {
        date && setDate(date);
    }

    const handleSaveDate = () => {
        const dateNoTime = date.clone().startOf('day');

        if (dateNoTime.isAfter(allowableDate)) {
            onSaveDateOutsideParams(dateNoTime);
        } else {
            onSaveDate(dateNoTime);
        }
    }

    const dateLimit = formatDate(slaDate ? moment(slaDate) : allowableDate, 'dddd, ll');

    return (
        <div className="first_available_date_wrapper_div">
            <p>
                {t('when_are_you_first_available_to_book_an_appointment_with')} {activity?.client?.firstName} {activity?.client?.lastName}?
            </p>
            <DateInput
                className="datePicker"
                label="Date"
                format={localeDateFormat}
                value={date}
                onChange={handleDateChange}
            />
            <Alert severity="warning" className="sla_warning">
                {t('first_offered_appt_note')}
            </Alert>
            <Alert severity="warning" className="sla_warning">
                {t('if_you_choose_a_date_after_that_of_you_will_not_be_able_to_accept_this_case', {dateLimit})}
            </Alert>
            {
                loading
                    ? <Spinner />
                    : <Button variant="opaque" onClick={handleSaveDate} className="sla_warning">
                        {t('save_date')}
                    </Button>
            }              
        </div>
    )
}
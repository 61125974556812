import React, { FC, useEffect, useMemo, useState } from 'react';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import { Grid, IconButton } from '@mui/material';
import { Button } from 'components/Shared/Inputs';
import { InsurancePanel } from '../../../interfaces';
import { SectionContainer } from '../../Other/SectionContainer';
import { INSURANCE_PANELS_REF_DATA, UPDATE_INSURANCE_PANELS_MUTATION } from './queries';
import { useLazyQuery, useMutation } from '@apollo/client';
import RemoveIcon from '@mui/icons-material/HighlightOffRounded';
import AddIcon from '@mui/icons-material/AddRounded';
import { PickerDialog, Option } from '../../Other/PickerDialog';
import { confirm } from 'components/Shared/ConfirmDialog';
import { Spinner } from 'components/Shared';

import './InsurancePanels.scss';

interface InsurancePanelsProps {
    providerId: number,
    insurancePanels?: InsurancePanel[],
    onInsurancePanelsUpdated: (insurancePanels: InsurancePanel[]) => void
}

interface InsurancePanelsFormValues {
    insuranceIds: number[]
}

const PanelsComparator = (A: Option, B: Option) => {
    if (A.title < B.title) {
        return -1;
    } else if (A.title > B.title) {
        return 1;
    } else {
        return (A.value as number) - (B.value as number);
    }
}

export const InsurancePanels: FC<InsurancePanelsProps> = (props) => {
    const { providerId, insurancePanels, onInsurancePanelsUpdated } = props;

    const { t, ready: translationsReady } = useTranslation('providerprofile', { useSuspense: false });
    const { t: t_common, ready: commonTranslationsReady } = useTranslation('common', { useSuspense: false });

    const [cachePendingUpdate, setCachePendingUpdate] = useState(false);

    const [updateInsurancePanels, updateInsurancePanelsMutation] = useMutation(UPDATE_INSURANCE_PANELS_MUTATION, {
        update: (_, result) => {
            if (result.data?.updateInsurancePanels?.details?.insurancePanels) {
                setCachePendingUpdate(true);
                onInsurancePanelsUpdated(result.data.updateInsurancePanels.details.insurancePanels);
            }
        }
    });

    const [getRefData, refDataQuery] = useLazyQuery(INSURANCE_PANELS_REF_DATA);

    const [inEdit, setInEdit] = useState(false);

    useEffect(() => {
        setCachePendingUpdate(false);
        setInEdit(false);
        formik.resetForm({ values: { insuranceIds: insurancePanels?.map(panel => panel.insuranceId) ?? [] } });
    }, [insurancePanels]);

    useEffect(() => {
        if (inEdit && !refDataQuery.called) {
            getRefData();
        }
    }, [inEdit]);

    const currentPanels = useMemo(() =>
        insurancePanels?.map<Option>(panel => ({ title: panel.insuranceCompany, value: panel.insuranceId }))
        , [insurancePanels]);

    const options = useMemo(() =>
        refDataQuery.data?.insurancePanels?.map<Option>(option => ({ title: option.title, value: Number(option.value) }))
        , [refDataQuery.data?.insurancePanels]);

    const formik = useFormik<InsurancePanelsFormValues>({
        initialValues: {
            insuranceIds: currentPanels?.map(panel => panel.value as number) ?? []
        },
        onSubmit: async (values) => {
            formik.validateForm();

            if (formik.isValid && values.insuranceIds) {
                const dialogProps = {
                    message: <>
                        <p>{t('common__confirm_changes_dialog_text')}</p>
                        {
                            Boolean(addedEntries.length) &&
                            <>
                                {t('insurance_panels__attestation_added_entries_header')}
                                <ul>
                                    {
                                        addedEntries.map(entry => (
                                            <li key={entry.value}>{entry.title}</li>
                                        ))
                                    }
                                </ul>
                            </>
                        }
                        {
                            Boolean(removedEntries.length) &&
                            <>
                                {t('insurance_panels__attestation_removed_entries_header')}
                                <ul>
                                    {
                                        removedEntries.map(entry => (
                                            <li key={entry.value}>{entry.title}</li>
                                        ))
                                    }
                                </ul>
                            </>
                        }
                    </>
                }
                if (await confirm(dialogProps)) {
                    updateInsurancePanels({
                        variables: {
                            providerId: providerId,
                            insuranceIds: values.insuranceIds
                        }
                    });
                }
            }
        }
    });

    const value = useMemo(() =>
        formik.values.insuranceIds
            .map(id => options?.find(panel => panel.value === id))
            .filter(panel => panel !== undefined) as Option[]
        , [formik.values.insuranceIds, options]);

    const addedEntries = useMemo(() =>
        value?.filter(newPanel => !currentPanels?.find(oldPanel => oldPanel.value === newPanel.value)) ?? []
        , [value, currentPanels]);

    const removedEntries = useMemo(() =>
        currentPanels?.filter(oldPanel => !value?.find(newPanel => oldPanel.value === newPanel.value)) ?? []
        , [value, currentPanels]);

    const handleCancelChanges = async () => {
        if (addedEntries.length || removedEntries.length) {
            const dialogProps = {
                title: t('common__discard_changes_dialog_title'),
                message: t('common__discard_changes_dialog_text')
            };
            if (await confirm(dialogProps)) {
                formik.resetForm();
                setInEdit(false);
            }
        } else {
            formik.resetForm();
            setInEdit(false);
        }
    }

    const handleRemovePanelClick = (removedId: number) => {
        formik.setFieldValue('insuranceIds', formik.values.insuranceIds?.filter(id => id !== removedId));
    }

    const handleAddInsurancePanels = (panels: Option[]) => {
        const updatedPanels = [...(value ?? []), ...panels].sort(PanelsComparator);
        formik.setFieldValue('insuranceIds', updatedPanels.map(panel => panel.value));
        setShowPanelPickerDialog(false);
    }

    const editorLoading = refDataQuery.loading || !commonTranslationsReady;

    const [showPanelPickerDialog, setShowPanelPickerDialog] = useState(false);

    const submitDisabled = updateInsurancePanelsMutation.loading
        || (!addedEntries.length && !removedEntries.length)
        || cachePendingUpdate;

    return !translationsReady
        ? <></>
        : <SectionContainer title={t('insurance_panels__section_title')} inEdit={inEdit} onEditClick={() => setInEdit(true)}>
            {
                !inEdit
                    ? insurancePanels?.length
                        ? <>
                            <p>{t('insurance_panels__list_header')}</p>
                            <ul className="insurance_panels_list">
                                {
                                    insurancePanels.map(panel => (
                                        <li key={panel.insuranceId}><span>{panel.insuranceCompany}</span></li>
                                    ))
                                }
                            </ul>
                        </>
                        : <p>{t('insurance_panels__no_entries')}</p>
                    : <>
                        {
                            editorLoading &&
                            <Spinner fillParent />
                        }
                        {
                            (updateInsurancePanelsMutation.loading || cachePendingUpdate) &&
                            <Spinner fillParent backdrop />
                        }
                        <Grid container component="form" onSubmit={formik.handleSubmit} spacing={3} style={{ visibility: editorLoading ? 'hidden' : 'visible' }}>
                            <Grid item xs={12} component="p">
                                {t('insurance_panels__list_header')}
                            </Grid>
                            <Grid item xs={12} component="ul" className="insurance_panels_edit_list">
                                {
                                    value?.map(panel => (
                                        <li key={panel.value}>
                                            <IconButton size="small" onClick={() => handleRemovePanelClick(Number(panel.value))}><RemoveIcon /></IconButton>
                                            {panel.title}
                                        </li>
                                    ))
                                }
                            </Grid>
                            <Grid item xs={12}>
                                <Button variant="text" onClick={() => setShowPanelPickerDialog(true)}><AddIcon />{t('insurance_panels__add_entry_button_label')}</Button>
                            </Grid>
                            <Grid item xs={12} container spacing={2}>
                                <Grid item>
                                    <Button type="submit" variant="opaque" disabled={submitDisabled}>{t_common('save')}</Button>
                                </Grid>
                                <Grid item>
                                    <Button type="button" variant="text" onClick={handleCancelChanges}>{t_common('cancel')}</Button>
                                </Grid>
                            </Grid>
                        </Grid>
                        <PickerDialog open={showPanelPickerDialog}
                            options={options}
                            value={value}
                            onCancel={() => setShowPanelPickerDialog(false)}
                            labels={{
                                dialogTitle: t('insurance_panels__picker_dialog_title'),
                                helperText: t('insurance_panels__picker_dialog_helper_text'),
                                inputPlaceholder: t('insurance_panels__picker_dialog_input_placeholder')
                            }}
                            onSubmit={handleAddInsurancePanels}
                        />
                    </>
            }
        </SectionContainer>
}
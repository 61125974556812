import React, { FC, PropsWithChildren } from 'react';
import { IconButton } from '@mui/material';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { useFeatureManager } from 'hooks/useFeatureManager';
import { Card } from 'components/Shared/Card';

interface SectionContainerProps extends PropsWithChildren {
    title?: string,
    inEdit?: boolean,
    onEditClick?: () => void,
    elevation?: 0 | 1 | 2,
    radius?: 'small' | 'regular'
}

export const SectionContainer: FC<SectionContainerProps> = (props) => {
    const { title, inEdit, elevation = 1, radius = 'regular', onEditClick } = props;

    const { isProviderProfileSelfServeFeatureEnabled } = useFeatureManager();
    
    return (
        <Card elevation={elevation} radius={radius} className={title === undefined ? 'provider_profile_section_container_padding_top' : 'provider_profile_section_container'}>
            {
                (title ?? false)
                    ? <div className="provider_profile_section_header">
                        <h2>{title}</h2>
                        {
                            !(inEdit ?? true) && isProviderProfileSelfServeFeatureEnabled() &&
                            <IconButton onClick={onEditClick}>
                                <EditOutlinedIcon/>
                            </IconButton>
                        }
                    </div>
                    : !(inEdit ?? true) && isProviderProfileSelfServeFeatureEnabled() &&
                        <IconButton onClick={onEditClick} className="provider_profile_section_edit_button">
                            <EditOutlinedIcon/>
                        </IconButton>
            }
            {
                props.children
            }
        </Card>
    )
}
import React, { FC, useEffect, useMemo, useState } from "react";
import { FormikErrors, useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import { ProfessionalDevelopment, ProfessionalDevelopmentInput } from '../../../interfaces';
import { Grid, MenuItem } from "@mui/material";
import { Button, DateInput, Select } from 'components/Shared/Inputs';
import { Spinner } from "components/Shared";
import { MutationTuple, useLazyQuery } from "@apollo/client";
import { UpdateProfessionalDevelopmentMutationData, UpdateProfessionalDevelopmentMutationVars, PROFESSIONAL_DEVELOPMENT_REF_DATA } from "./queries";
import { confirm } from 'components/Shared/ConfirmDialog';
import AddIcon from '@mui/icons-material/Add';
import { SectionContainer } from '../../Other/SectionContainer';
import moment from "moment";
import { useTimezoneUtils } from "hooks/useTimezoneUtils";

const MapDevelopmentToDevelopmentInput = (entry: ProfessionalDevelopment): ProfessionalDevelopmentInput => ({
    dateCompleted: entry.dateCompleted,
    providerProfessionalDevelopmentId: entry.providerProfessionalDevelopmentId,
    trainingCode: entry.trainingCode,
    type: entry.type
})

interface ProfessionalDevelopmentFormProps {
    providerId: number,
    data?: ProfessionalDevelopment[],
    entry?: ProfessionalDevelopment,
    mutation: MutationTuple<UpdateProfessionalDevelopmentMutationData, UpdateProfessionalDevelopmentMutationVars>,
    cachePendingUpdate: boolean
}

interface FormValues {
    dateCompleted: string,
    trainingCode: string,
    type: number | null,
    providerProfessionalDevelopmentId: number | null
}

export const ProfessionalDevelopmentForm: FC<ProfessionalDevelopmentFormProps> = (props) => {
    const { providerId, entry, data, mutation, cachePendingUpdate } = props;

    const { t } = useTranslation('providerprofile', { useSuspense: false });
    const { t: t_common } = useTranslation('common', { useSuspense: false });

    const { createDateFormatFunction, DATE_PORTION_MOMENT_FORMAT, localeDateFormat } = useTimezoneUtils();
    const formatCredentialDate = createDateFormatFunction(DATE_PORTION_MOMENT_FORMAT);

    const [requestPending, setRequestPending] = useState(false);
    
    const [inEdit, setInEdit] = useState(false);

    const [getRefData, refDataQuery] = useLazyQuery(PROFESSIONAL_DEVELOPMENT_REF_DATA);
    
    useEffect(() => {
        if (inEdit && !refDataQuery.called) {
            getRefData();
        }
    }, [inEdit]);
    
    const [update, updateMutation] = mutation;

    const formik = useFormik<FormValues>({
        initialValues: {
            providerProfessionalDevelopmentId: entry?.providerProfessionalDevelopmentId ?? null,
            dateCompleted: entry?.dateCompleted ?? moment().format('YYYY-MM-DD'),
            trainingCode: entry?.trainingCode ?? '',
            type: entry?.type ?? null
        },
        validate: (values) => {
            const errors: FormikErrors<FormValues> = {};

            if (!values.trainingCode) {
                errors.trainingCode = 'Training cannot be empty';
            }

            if (!values.type) {
                errors.trainingCode = 'Type cannot be empty';
            }

            if (!values.dateCompleted) {
                errors.dateCompleted = 'Date completed cannot be empty';
            }

            return errors;
        },
        validateOnBlur: true,
        validateOnChange: true,
        onSubmit: async (values) => {
            formik.validateForm();

            if (formik.isValid) {
                const dialogProps = {
                    message: <>
                        <p>{t('common__confirm_changes_dialog_text')}</p>
                        <ul>
                        {
                            (!entry || values.type !== entry.type) &&
                            <li>{t('professional_development__type_label')}: <b>{types?.find(e => e.value === values.type)?.title}</b></li>
                        }
                        {
                            (!entry || values.trainingCode !== entry.trainingCode) &&
                            <li>{t('professional_development__training_label')}: <b>{trainings?.find(e => e.value === values.trainingCode)?.title}</b></li>
                        }
                        {
                            (!entry || values.dateCompleted !== entry.dateCompleted) &&
                            <li>{t('professional_development__date_completed_label')}: <b>{moment(values.dateCompleted, "YYYY-MM-DD").format(localeDateFormat)}</b></li>
                        }
                        </ul>
                    </>
                }
                if (await confirm(dialogProps)) {
                    setRequestPending(true);
                    const updatedData = [
                        ...(data?.filter(e => e !== entry) || []).map(MapDevelopmentToDevelopmentInput),
                        {
                            dateCompleted: values.dateCompleted,
                            providerProfessionalDevelopmentId: values.providerProfessionalDevelopmentId,
                            trainingCode: values.trainingCode,
                            type: Number(values.type)
                        }
                    ];
                    update({
                        variables: {
                            providerId: providerId,
                            professionalDevelopment: updatedData
                        }
                    }).then(() => { setInEdit(false); formik.resetForm(); }).finally(() => setRequestPending(false));
                }
            }
        }
    });

    const handleDelete = async () => {
        const dialogProps = {
            title: t('common__delete_entry_dialog_title'),
            message: t('common__delete_entry_dialog_text')
        }
        if (await confirm(dialogProps)) {
            setRequestPending(true);
            update({
                variables: {
                    providerId: providerId,
                    professionalDevelopment: [
                        ...(data?.filter(e => e.providerProfessionalDevelopmentId !== entry?.providerProfessionalDevelopmentId) || [])
                            .map(MapDevelopmentToDevelopmentInput)
                    ]
                }
            }).then(() => setInEdit(false)).finally(() => setRequestPending(false));
        }
    }

    const hasChanges = formik.dirty 
        && (formik.values.dateCompleted !== entry?.dateCompleted
            || formik.values.type !== entry?.type
            || formik.values.trainingCode !== entry?.trainingCode);

    const deleteDisabled = updateMutation.loading || cachePendingUpdate;
    const saveDisabled = updateMutation.loading || !hasChanges || !formik.isValid;

    const handleCancelChanges = async () => {
        if (hasChanges) {
            const dialogProps = {
                title: t('common__discard_changes_dialog_title'),
                message: t('common__discard_changes_dialog_text')
            }
            if (await confirm(dialogProps)) {
                formik.resetForm();
                setInEdit(false);
            }
        } else {
            formik.resetForm();
            setInEdit(false);
        }
    }

    const types = useMemo(() =>
        refDataQuery.data?.types?.map(type => ({title: type.title, value: Number(type.value)}))
    , [refDataQuery.data?.types]);

    const trainings = refDataQuery.data?.training;

    
    const handleDateCompletedChange = (date) => {
        date &&
        formik.setFieldValue('dateCompleted', date.format('YYYY-MM-DD'));
    }

    return !entry && !inEdit
        ? <Button variant="text" onClick={() => setInEdit(true)} className="add_entry_button">
            <AddIcon/>{t('professional_development__add_entry_button_label')}
        </Button>
        : <SectionContainer elevation={0} radius="small" inEdit={inEdit} onEditClick={() => setInEdit(true)}>
            {
                inEdit
                ? (
                    <Grid className="credentialing_sub_section_form" container component='form' onSubmit={formik.handleSubmit} spacing={3}>
                        {
                            (requestPending || cachePendingUpdate) &&
                            <Spinner fillParent backdrop />
                        }
                        <Grid item xs={12} xl={6}>
                            <Select
                                value={formik.values.type ?? ''} 
                                label={t('professional_development__type_label')}
                                name="type"
                                onChange={formik.handleChange}
                                className="provider_details_input"
                                error={Boolean(formik.errors.type)}
                            >
                                {
                                    types?.map(type => (
                                        <MenuItem value={type.value} key={type.value}>
                                            {type.title}
                                        </MenuItem>
                                    ))
                                }
                            </Select>
                        </Grid>
                        <Grid item xs={12} xl={6}>
                            <Select
                                value={formik.values.trainingCode ?? ''} 
                                label={t('professional_development__training_label')}
                                name="trainingCode" 
                                onChange={formik.handleChange}
                                className="provider_details_input"
                                error={Boolean(formik.errors.type)}
                            >
                                {
                                    trainings?.map(trainings => (
                                        <MenuItem value={trainings.value} key={trainings.value}>
                                            {trainings.title}
                                        </MenuItem>
                                    ))
                                }
                            </Select>
                        </Grid>
                        <Grid item xs={12} xl={6}>
                            <DateInput
                                label={t('professional_development__date_completed_label')}
                                className="provider_details_input"
                                value={moment(formik.values.dateCompleted, 'YYYY-MM-DD')}
                                onChange={handleDateCompletedChange}
                            />
                        </Grid>
                        <Grid item xs={12} container spacing={2}>
                            <Grid item>
                                <Button type="submit" variant="opaque" disabled={saveDisabled}>{t_common('save')}</Button>
                            </Grid>
                            <Grid item>
                                <Button type="button" variant="outline" onClick={handleCancelChanges}>{t_common('cancel')}</Button>
                            </Grid>
                            { 
                                entry && inEdit && ((data?.length ?? 0) > 1) &&
                                <Grid item>
                                    <Button type="button" variant="text" onClick={handleDelete} disabled={deleteDisabled}>{t_common('delete')}</Button>
                                </Grid>
                            }
                        </Grid>
                    </Grid>
                )
                : (
                    entry &&
                    <Grid container>
                        <Grid item xs={12} sm={6}>
                            <p className='global__paragraph'>{entry.typeString}</p>
                            <p className='global__paragraph--small'>{t('professional_development__type_label')}</p>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <p className='global__paragraph'>{entry.training}</p>
                            <p className='global__paragraph--small'>{t('professional_development__training_label')}</p>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <p className='global__paragraph'>{entry.dateCompleted ? formatCredentialDate(entry.dateCompleted) : t('n_a','N/A')}</p>
                            <p className='global__paragraph--small'>{t('professional_development__date_completed_label')}</p>
                        </Grid>
                    </Grid>
                )
            }
        </SectionContainer>
}
import React, { FC, useEffect, useMemo, useState } from 'react';
import { FormikErrors, useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import { Grid, MenuItem, Alert } from '@mui/material';
import { TextBox, Select, Button } from 'components/Shared/Inputs';
import { useLazyQuery, useMutation } from '@apollo/client';
import { PROVIDER_INFO_REF_DATA, UPDATE_PROVIDER_INFO_MUTATION } from './queries';
import { Spinner } from 'components/Shared';
import { ProviderDetails } from 'components/ProviderProfile/interfaces';
import { confirm } from 'components/Shared/ConfirmDialog';
import { GetChangedFields } from '../../../utils/EditorUtils';
import { SectionContainer } from '../../Other/SectionContainer';

interface PersonalInfoProps {
    providerId: number,
    providerDetails?: ProviderDetails,
    onUpdated: (providerDetails: ProviderDetails) => void
}

interface PersonalInfoFormValues {
    disability: number | null,
    ethnicityCode: string | null,
    gender: string | null,
    preferredLanguage: number | null
    religionCode: string | null,
    sexualOrientation: number | null,
    veteranStatus: number | null,
}

export const PersonalInfo: FC<PersonalInfoProps> = (props) => {
    const { providerId, providerDetails, onUpdated } = props;

    const { t, ready: translationsReady } = useTranslation('providerprofile', { useSuspense: false });
    const { t: t_common, ready: commonTranslationsReady } = useTranslation('common', { useSuspense: false });

    const [cachePendingUpdate, setCachePendingUpdate] = useState(false);

    const [getRefData, refDataQuery] = useLazyQuery(PROVIDER_INFO_REF_DATA);

    const [inEdit, setInEdit] = useState(false);

    useEffect(() => {
        setCachePendingUpdate(false);
        setInEdit(false);
        formik.resetForm({
            values: {
                disability: providerDetails?.disability ?? null,
                gender: providerDetails?.gender ?? null,
                ethnicityCode: providerDetails?.ethnicityCode ?? null,
                religionCode: providerDetails?.religionCode ?? null,
                preferredLanguage: providerDetails?.preferredLanguage ?? null,
                sexualOrientation: providerDetails?.sexualOrientation ?? null,
                veteranStatus: providerDetails?.veteranStatus ?? null
            }
        });
    }, [providerDetails]);

    useEffect(() => {
        if (inEdit && !refDataQuery.called) {
            getRefData();
        }
    }, [inEdit]);

    const [updatePersonalInfo, updatePersonalInfoMutation] = useMutation(UPDATE_PROVIDER_INFO_MUTATION, {
        update: (_, result) => {
            if (providerDetails && result.data?.updateProviderDetails?.details) {
                setCachePendingUpdate(true);
                onUpdated({ ...providerDetails, ...result.data.updateProviderDetails.details });
            }
        }
    });

    const formik = useFormik<PersonalInfoFormValues>({
        initialValues: {
            disability: providerDetails?.disability ?? null,
            gender: providerDetails?.gender || null,
            ethnicityCode: providerDetails?.ethnicityCode || null,
            religionCode: providerDetails?.religionCode || null,
            preferredLanguage: providerDetails?.preferredLanguage ?? null,
            sexualOrientation: providerDetails?.sexualOrientation ?? null,
            veteranStatus: providerDetails?.veteranStatus ?? null
        },
        validate: (values) => {
            const errors: FormikErrors<PersonalInfoFormValues> = {};
            if (!values.gender) {
                errors.gender = 'Gender cannot be empty';
            }
            if (formik.initialValues.ethnicityCode && !values.ethnicityCode) {
                errors.ethnicityCode = 'Ethnicity cannot be empty';
            }
            if (formik.initialValues.religionCode && !values.religionCode) {
                errors.religionCode = 'Religion cannot be empty';
            }
            return errors;
        },
        onSubmit: async (values) => {
            const dialogProps = {
                message: <>
                    <p>{t('common__confirm_changes_dialog_text')}</p>
                    <ul>
                        {
                            changedFields.includes('disability') &&
                            <li>{t('personal_info__disability_input_label')}: <b>{disabilities?.find(ref => ref.value === values.disability)?.title}</b></li>
                        }
                        {
                            changedFields.includes('ethnicityCode') &&
                            <li>{t('personal_info__ethnicity_input_label')}: <b>{ethnicities?.find(ref => ref.value === values.ethnicityCode)?.title}</b></li>
                        }
                        {
                            changedFields.includes('gender') &&
                            <li>{t('personal_info__gender_input_label')}: <b>{genders?.find(ref => ref.value === values.gender)?.title}</b></li>
                        }
                        {
                            changedFields.includes('religionCode') &&
                            <li>{t('personal_info__religion_input_label')}: <b>{religions?.find(ref => ref.value === values.religionCode)?.title}</b></li>
                        }
                        {
                            changedFields.includes('sexualOrientation') &&
                            <li>{t('personal_info__sexual_orientation_input_label')}: <b>{sexualOrientations?.find(ref => ref.value === values.sexualOrientation)?.title}</b></li>
                        }
                        {
                            showVeteranStatus && changedFields.includes('veteranStatus') &&
                            <li>{t('personal_info__veteran_status_label')}: <b>{veteranStatuses?.find(ref => ref.value === values.veteranStatus)?.title}</b></li>
                        }
                    </ul>
                </>
            }
            if (await confirm(dialogProps)) {
                updatePersonalInfo({
                    variables: {
                        providerId: providerId,
                        ...changedFields.reduce((val, field) =>({...val, [field]: values[field]}), {})
                    }
                });
            }
        }
    });

    const handleCancelChanges = async () => {
        if (changedFields.length !== 0) {
            const dialogProps = {
                title: t('common__discard_changes_dialog_title'),
                message: t('common__discard_changes_dialog_text')
            }
            if (await confirm(dialogProps)) {
                formik.resetForm();
                setInEdit(false);
            }
        } else {
            formik.resetForm();
            setInEdit(false);
        }
    }

    const changedFields = GetChangedFields(formik.values, providerDetails);

    const disabilities = useMemo(() =>
        refDataQuery.data?.refValues.disabilities?.map(e => ({ title: e.title, value: Number(e.value) }))
    , [refDataQuery.data?.refValues.disabilities]);
    const genders = refDataQuery.data?.refValues.genders;
    const ethnicities = refDataQuery.data?.refValues.ethnicities;
    const religions = useMemo(() => (refDataQuery.data?.refValues.religions?.slice()?.sort((A, B) => A.value === 'RSPD' ? -1 : (B.value === 'RSPD') ? 1 : 0)) 
    , [refDataQuery.data?.refValues.religions]);
    const sexualOrientations = useMemo(() =>
        refDataQuery.data?.refValues.sexualOrientations?.map(e => ({ title: e.title, value: Number(e.value) }))
    , [refDataQuery.data?.refValues.sexualOrientations]);
    const veteranStatuses = useMemo(() =>
        refDataQuery.data?.refValues.veteranStatuses?.map(e => ({ title: e.title, value: Number(e.value) }))
    , [refDataQuery.data?.refValues.veteranStatuses]);

    const disabilityValue = !disabilities ? '' : formik.values.disability ?? '';
    const ethnicityValue = !ethnicities ? '' : formik.values.ethnicityCode ?? '';
    const genderValue = !genders ? '' : formik.values.gender ?? '';
    const religionValue = !religions ? '' : formik.values.religionCode ?? '';
    const sexualOrientationValue = !sexualOrientations ? '' : formik.values.sexualOrientation ?? '';

    const showVeteranStatus = providerDetails?.showVeteranStatus ?? false;
    const veteranStatusValue = !veteranStatuses ? '' : formik.values.veteranStatus ?? '';

    const editorLoading = refDataQuery.loading || !commonTranslationsReady;
    const submitDisabled = changedFields.length === 0 || updatePersonalInfoMutation.loading || cachePendingUpdate;

    const renderValue = (label, value) => (
        <Grid item xs={6} className="provider_details_value">
            <p className='value'>{value}</p>
            <span className='label'>{label}</span>
        </Grid>
    )

    return !translationsReady
        ? <></>
        : <SectionContainer title={t('personal_info__section_title')} inEdit={inEdit} onEditClick={() => setInEdit(true)}>
            {
                inEdit
                ? <> 
                    {
                        editorLoading &&
                        <Spinner fillParent/>
                    }
                    {
                        (updatePersonalInfoMutation.loading || cachePendingUpdate) &&
                        <Spinner fillParent backdrop/>
                    }
                    <Grid container component='form' onSubmit={formik.handleSubmit} spacing={3} style={{visibility: editorLoading ? 'hidden' : 'visible'}}>
                        <Grid item xs={12} container spacing={2}>
                            <Grid item>
                                <TextBox value={providerDetails?.firstName} label={t('personal_info__first_name_input_label')} readOnly/>
                            </Grid>
                            <Grid item>
                                <TextBox value={providerDetails?.lastName} label={t('personal_info__last_name_input_label')} readOnly/>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <Select label={t('personal_info__gender_input_label')} name="gender"
                                value={genderValue} onChange={formik.handleChange}
                                error={Boolean(formik.errors.gender)}
                                className="provider_details_input">
                                {
                                    genders?.map(gender => (
                                        <MenuItem value={gender.value} key={gender.value}>
                                            {gender.title}
                                        </MenuItem>
                                    ))
                                }
                            </Select>
                        </Grid>
                        <Grid item xs={12}>
                            <Select label={t('personal_info__ethnicity_input_label')} name="ethnicityCode"
                                value={ethnicityValue} onChange={formik.handleChange}
                                error={Boolean(formik.errors.ethnicityCode)}
                                className="provider_details_input">
                                {
                                    ethnicities?.map(ethnicity => (
                                        <MenuItem value={ethnicity.value} key={ethnicity.value}>
                                            {ethnicity.title}
                                        </MenuItem>
                                    ))
                                }
                            </Select>
                        </Grid>
                        <Grid item xs={12}>
                            <Select label={t('personal_info__religion_input_label')} name="religionCode"
                                value={religionValue} onChange={formik.handleChange}
                                error={Boolean(formik.errors.religionCode)}
                                className="provider_details_input">
                                {
                                    religions?.map(religion => (
                                        <MenuItem value={religion.value} key={religion.value}>
                                            {religion.title}
                                        </MenuItem>
                                    ))
                                }
                            </Select>
                        </Grid>
                        <Grid item xs={12}>
                            <Select label={t('personal_info__sexual_orientation_input_label')} name="sexualOrientation"
                                value={sexualOrientationValue} onChange={formik.handleChange}
                                error={Boolean(formik.errors.sexualOrientation)}
                                className="provider_details_input">
                                {
                                    sexualOrientations?.map(entry => (
                                        <MenuItem value={entry.value} key={entry.value}>
                                            {entry.title}
                                        </MenuItem>
                                    ))
                                }
                            </Select>
                        </Grid>
                        <Grid item xs={12}>
                            <Select label={t('personal_info__disability_input_label')} name="disability"
                                value={disabilityValue} onChange={formik.handleChange}
                                error={Boolean(formik.errors.disability)}
                                className="provider_details_input">
                                {
                                    disabilities?.map(entry => (
                                        <MenuItem value={entry.value} key={entry.value}>
                                            {entry.title}
                                        </MenuItem>
                                    ))
                                }
                            </Select>
                        </Grid>
                        <Grid item xs={12}>
                            {
                                showVeteranStatus &&
                                <Select label={t('personal_info__veteran_status_label')} name="veteranStatus"
                                    value={veteranStatusValue} onChange={formik.handleChange}
                                    error={Boolean(formik.errors.veteranStatus)}
                                    className="provider_details_input">
                                    {
                                        veteranStatuses?.map(entry => (
                                            <MenuItem value={entry.value} key={entry.value}>
                                                {entry.title}
                                            </MenuItem>
                                        ))
                                    }
                                </Select>
                            }
                        </Grid>
                        <Grid item xs={12}>
                            <Alert severity="info">{t('personal_info__note')}</Alert>
                        </Grid>
                        <Grid item xs={12} container spacing={2}>
                            <Grid item>
                                <Button type="submit" variant="opaque" disabled={submitDisabled}>
                                    {t_common('save')}
                                </Button>
                            </Grid>
                            <Grid item>
                                <Button type="button" variant="text" onClick={handleCancelChanges}>{t_common('cancel')}</Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </>
                : <Grid container>
                    {renderValue(t('personal_info__name_label'), `${providerDetails?.salutation} ${providerDetails?.firstName} ${providerDetails?.lastName}`)}
                    {renderValue(t('personal_info__gender_input_label'), providerDetails?.genderString ?? t('n_a'))}
                    {renderValue(t('personal_info__ethnicity_input_label'), providerDetails?.ethnicityString ?? t('n_a'))}
                    {renderValue(t('personal_info__religion_input_label'), providerDetails?.religionString ?? t('n_a'))}
                    {renderValue(t('personal_info__disability_input_label'), providerDetails?.disabilityString ?? t('n_a'))}
                    {renderValue(t('personal_info__sexual_orientation_input_label'), providerDetails?.sexualOrientationString ?? t('n_a'))}
                    {renderValue(t('personal_info__language_label'), providerDetails?.preferredLanguageString ?? t('n_a'))}
                    {
                        showVeteranStatus &&
                        renderValue(t('personal_info__veteran_status_label'), providerDetails?.veteranStatusString ?? t('n_a'))
                    }
                    {renderValue(t('personal_info__employment_type_label'), providerDetails?.employmentTypeString ?? t('n_a'))}
                </Grid>
            }
        </SectionContainer>
}
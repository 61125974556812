import React, { FC } from 'react';
import SyncLoader from 'react-spinners/SyncLoader';
import clsx from 'clsx';

import './Spinner.scss';

interface SpinnerProps {
    backdrop?: boolean,
    fillParent?: boolean,
    className?: string
}

export const Spinner: FC<SpinnerProps> = (props) => {
    const { backdrop, className, fillParent } = props;

    return (
        <div className={clsx('spinner', className, fillParent && 'spinner_fillParent', backdrop && 'spinner_backdrop')}>
            <SyncLoader color='var(--accent_color)'/>
        </div>
    )
}
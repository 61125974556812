import React, { FC, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { ToDo } from 'components/ToDos';
import { DashboardToDosGrid } from '../DashboardToDosGrid';
import { useTranslation } from 'react-i18next';
import { Schedule, useScheduleQuery } from 'components/Calendaring';
import { useAuth } from 'components/Auth';

interface DashboardBodyProps {
    toDos: ToDo[] | undefined,
    loading: boolean
}

export const DashboardBody: FC<DashboardBodyProps> = ({toDos, loading}) => {
    const { t } = useTranslation('dashboard', { useSuspense: false });
    const { effectiveProviderData } = useAuth();

    const scheduleQuery = useScheduleQuery();
    const unavailabilities = useMemo(() =>
        scheduleQuery.data?.schedule?.unavailabilities?.map(unavailability => ({
            ...unavailability,
            unavailabilityReason: scheduleQuery?.data?.schedule?.unavailabilityReasons?.find(reason => reason.id === unavailability.unavailabilityReasonID)
        }))
    , [scheduleQuery.data]);

    return (
        <div className="dashboard_body_element">
            {
                effectiveProviderData?.hasManagedCalendar &&
                <div className="dashboard_schedule_section">
                    <h2>{t('upcoming_appointments')}</h2>
                    <Schedule loading={scheduleQuery.loading}
                        unavailabilities={unavailabilities}
                        availabilities={scheduleQuery.data?.schedule.availabilities}
                    />
                </div>
            }
            <div className="dashboard_todos_section outstanding_to_do_element">
                <div className="title_link_wrapper">
                    <h2>{t('dashboard_todos_section_header', 'To-dos')}</h2>
                    <Link className="viewAll__link" to={{ pathname: '/todos' }}>
                        {t('view_all', 'View all')}
                    </Link>
                </div>
                <DashboardToDosGrid toDos={toDos} loading={loading} />
            </div>
        </div>  
    );
}
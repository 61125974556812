import React, { FC } from 'react';
import { BookApptStep2Staff } from './BookApptStep2';
import { ScheduleNextOfflineSession } from 'components/CaseManagement/ScheduleSession';
import { useAuth } from 'components/Auth';
import { useTranslation } from 'react-i18next'
import { PageMetadata } from 'components/Shared/PageMetadata';
import { useLocation } from 'react-router-dom';
import './style.scss'

export const BookAppointmentView: FC = () => {
    const { t, ready } = useTranslation('calendaring', { useSuspense: false });

    const location = useLocation();

    const activityId = location?.state?.activityId;
    const startDate = location?.state?.startDate;
    const entryRoute = location?.state?.entryRoute

    const { effectiveProviderData } = useAuth();

    const setPageMetadata = () => (
        <React.Fragment>
            { ready && 
                <PageMetadata 
                    pageDescription={t('step2_page_description')} 
                    pageTitle={t('step2_page_title')}
                /> 
            } 
        </React.Fragment>
    )

    const employmentTypeId = effectiveProviderData?.employmentTypeId;
    const isStaff = employmentTypeId === 1 || employmentTypeId === 2;
    const isContractor = employmentTypeId === 3;

    if (isStaff) {
        return (
            <React.Fragment>
                {setPageMetadata()}
                <BookApptStep2Staff entryRoute={entryRoute} activityId={activityId} startDate={startDate}/>
            </React.Fragment>
        )
    }

    if (isContractor) {
        return (
            <React.Fragment>
                {setPageMetadata()}
                <ScheduleNextOfflineSession activityId={activityId} providerId={effectiveProviderData!.providerId}/>
            </React.Fragment>
        )
    }

    return (
        <React.Fragment>
            {setPageMetadata()}
            <ScheduleNextOfflineSession activityId={activityId} providerId={effectiveProviderData!.providerId} />
        </React.Fragment>
    )
}

export default BookAppointmentView;
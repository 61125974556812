export interface RegionConfig {
    defaultLng: string,
    languages: Language[],
    providerContactUrl: string,
    techSupportUrls: RedirectUrl,
    helpUrls: RedirectUrl,
    helpCenterNumber: string,
    features: ALL_FEATURES[]
}

export interface Language {
    name: string,
    code: string,
    fallback: string[]
}

export interface RedirectUrl {
    en: {
        url: string,
        vanityUrl: string
    },
    fr: {
        url: string,
        vanityUrl: string
    }
}


export enum ALL_FEATURES {
    WSP = 'WSP',
    LIST_REFERRAL = 'LIST_REFERRAL',
    HOURLY_CONTRACT = 'HOURLY_CONTRACT',
    PROVIDER_PROFILE_SELF_SERVE = 'PROVIDER_PROFILE_SELF_SERVE',
    ADDITIONAL_HOURS = 'ADDITIONAL_HOURS',
    EXTENDED_UPDATE_APPT_STATUS = 'EXTENDED_UPDATE_APPT_STATUS'
}
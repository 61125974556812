import React, { FC } from 'react';
import { IActivityDetails } from '../interfaces';
import { NoToDosCard } from 'components/Shared/PromptCard/NoToDosCard';
import { ActivityCompletedPrompt } from 'components/Shared/PromptCard/ActivityCompletedPrompt';
import { StepDefinition, ToDoBase, ToDoPrompt } from 'components/ToDos';
import { IActivityInfo } from 'components/ToDos';

interface ClientDetailsPromptCardProps {
    step: StepDefinition | null,
    activity?: IActivityDetails,
    sessionId?: number
}

export const ClientDetailsPromptCard: FC<ClientDetailsPromptCardProps> = (props) => {
    const { step, activity, sessionId } = props;
    const isActivityActive = activity?.activityStatusCode === 1;

    if (!isActivityActive && step === null) return <ActivityCompletedPrompt/>
    
    let activityInfo: IActivityInfo = {
        activityId: activity?.activityId as number,
        clientName: `${activity?.client?.firstName} ${activity?.client?.lastName}`
    }

    if (step && activity?.isAvailableToProvider) {
        const toDo: ToDoBase = {
            sessionId: sessionId ?? null,
            step: step,
            activityInfo: activityInfo
        }
        return <ToDoPrompt toDo={toDo}/>;
    }

    return <NoToDosCard clientName={activityInfo.clientName}/>;
}
import { gql, TypedDocumentNode } from '@apollo/client';
import { IActivityDetails, IFormByActivity, IReferral, IReason } from '../interfaces';

export const ACTIVITY_QUERY: TypedDocumentNode<ActivityQueryData, ActivityQueryVars> = gql`
    query ACTIVITY_QUERY($activityId: Long!) {
        activity(activityId: $activityId) {
            client {
                clientId
                firstName
                lastName
                email
                phones {
                    number
                    phoneTypeCode
                    messageType
                }
                gender
                dateOfBirth
                languagePreference
                address {
                    city
                    state_ProvinceCode
                    countryName
                }
            }
            preAssessments {
                additionalIndividualId
                activityId
                clientName
                status
                url
            }
            additionalParticipants {
                id 
                firstName,
                lastName,
                relationType,
                fullName,
                dateOfBirth
            }
            sessionInfo {
                modelId
                totalHoursProvided
                totalHoursRemaining
                maxCapHours
            }
            activityStatusCode
            service
            isAvailableToProvider
            clientStatusCode
            eligibility
            clientMaxHours
            clientUsedHours
            clientAvailableHours
            clientMaxHoursStartDate
            clientMaxHoursEndDate
            isHourlyContract
            modality
            targetedSystem {
                systemCode
            }
            organization {
                name
                organizationId
            }
            providerId
            presentingIssue
            activityId
            sessions {
                duration
                sessionId
                sessionStatus
                startDateTimeUTC
                sessionStatusId
                providerHubAppointmentStatusId
                clientReservationPhoneNumber
            }
            openDate
            activityClosedTime
            urgencyLevel
            urgencyLevelString
            pcShowFamilyHours
            familyTotalAllocatedHours
            familyTotalRemainingHours
            familyEligibilityHours {
                eligibilityCategoryDescription
                allocatedHours
                remainingHours
                eligibilityCategoryId
            }
            familyMembers {
                id
                firstName
                lastName
            }
            serviceFor
            indigenousLabel
            solutionCode
            followUpDate
            sessionBooking {
                allowed
                prohibitionReasonCode
            }
            caseDetailHours {
                clientRemainingHours
                numberOfHoursUsed
                totalHoursAvailable
                numberOfMaxHours
                approvedHours
            }
            serviceForms {
                clientPhoneNumber
            }
            contractAllowsAdditionalHours
            assessmentsScores {
                PHQ9 {
                    date
                    score
                    formId
                    sessionId
                }
            }
            firstAppointmentAssessmentFormNames {
                templateName
                mandatory
            }
        }
        formsByActivity(activityId: $activityId) {
            id
            completed
            templateName
            updatedBy
            createdBy
            sessionId
            updatedOn
            createdOn
            stage
            preAssessmentStageStatus
            postAssessmentStageStatus
        }
        referral(activityId: $activityId) {
            referralAccepted
            clientCallingPreference
            serviceFormNotes
        }
    }
`

export interface ActivityQueryData {
    activity: IActivityDetails,
    formsByActivity: IFormByActivity[],
    referral: IReferral
}

export interface ActivityQueryVars {
    activityId: number
}


export const CLOSE_ACTIVITY_MUTATION = gql`
    mutation CLOSE_ACTIVITY_MUTATION($activityId: Long!, $closureDate: DateTime, $providerId: Long!) {
        closeActivity(activityId: $activityId, closureDate: $closureDate, providerId: $providerId)
    }
`

export const DECLINE_REASONS_QUERY: TypedDocumentNode<DeclineReasonsQueryData, DeclineReasonsQueryVars> = gql`
    query DECLINE_REASONS_QUERY {
        appointmentDeclineReasons {
            id
            description 
        }
    }
`

export const DECLINE_WOS_INFO_QUERY: TypedDocumentNode<DeclineWosInfoQueryData, DeclineWosInfoQueryVars> = gql`
    query DECLINE_WOS_INFO_QUERY($activityId: Long!)  {
        declineWosInfo(activityId: $activityId) {
            outcome
            declinePreAssessmentReason             
        }
    }
`
interface DeclineReasonsQueryVars { }

interface DeclineReasonsQueryData {
    appointmentDeclineReasons: IReason[]
}

interface DeclineWosInfoQueryVars {
    activityId: number
}

interface DeclineWosInfoQueryData {
    declineWosInfo: DeclineWosInfo
}

export interface DeclineWosInfo {
    outcome: string,
    declinePreAssessmentReason: string
}


export const IS_ACTIVITY_ACCEPTED_QUERY: TypedDocumentNode<IIsActivityAcceptedQueryData, IIsActivityAcceptedQueryVars>  = gql`
    query IS_ACTIVITY_ACCEPTED_QUERY($activityId: Long!) {
        isActivityAcceptedByProvider(activityId: $activityId)
    }
`
export interface IIsActivityAcceptedQueryVars {
    activityId: number
}

export interface IIsActivityAcceptedQueryData {
    isActivityAcceptedByProvider: boolean
};


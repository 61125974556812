import { IconButton, Tooltip } from '@mui/material';
import React, { FC } from 'react';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import './style.scss'
import { useTranslation } from 'react-i18next';

export interface INextArrowButtonProps {
    className?: string;
    disabled?: boolean;
    onClick?: () => void;
}

export const NextArrowButton: FC<INextArrowButtonProps> = (props: INextArrowButtonProps) => {
    const { t } = useTranslation('common', { useSuspense: false });

    const { disabled, className, ...rest } = props;

    const getIconButtonClassNames = () => {
        const defaultClasses = ['arrow-down-button', className];
        if (disabled) {
            defaultClasses.push('disabled')
        }
        return defaultClasses.join(' ')
    }

    return  (
        <Tooltip title={t('next') || 'Next'}>
            <div>
                <IconButton {...rest} disabled={disabled} className={getIconButtonClassNames()}> 
                    <ArrowDownwardIcon />
                </IconButton>
            </div>
        </Tooltip>
    )
}
import React, { useState, FC, useCallback } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { ActivityDetailsContent } from '../components/ActivityDetailsContent';
import { ActivityDetailsHeader, ActivityDetailsHeaderProps } from '../components/ActivityDetailsHeader';
import { NetworkStatus } from '@apollo/client';
import { ACTIVITY_QUERY, IS_ACTIVITY_ACCEPTED_QUERY } from '../queries';
import { Spinner } from 'components/Shared';
import './ActivityDetails.scss';
import { GetNextStep } from '../../utils/activityDetailsUtils';
import { useScreenSize } from 'components/Layout';
import { SwipeableDrawer } from "@mui/material";
import { useTranslation } from 'react-i18next';
import { PageMetadata } from 'components/Shared/PageMetadata';
import { useTodos } from 'hooks/useTodos';
import { useAuth } from 'components/Auth';
import { Button } from 'components/Shared/Inputs';
import { CONTRACT_DETAILS_QUERY } from 'hooks/useContractDetails/queries';
import { GET_REQUESTS_QUERY } from 'components/Requests/queries';
import { FullScreenMessage } from 'components/Shared/FullScreenMessage';
import { useLazyQueryWithCacheExpiry } from 'providers/Apollo/useLazyQueryWithCacheExpiry';
import { useQueryWithCacheExpiry } from 'providers/Apollo/useQueryWithCacheExpiry';

interface ActivityDetailsViewProps { }

export const ActivityDetailsView: FC<ActivityDetailsViewProps> = (_) => {  
    const { t, ready: translationsReady } = useTranslation('clients', { useSuspense: false });

    const { activityId: activityIdString } = useParams<{activityId: string}>();
    const activityId = parseInt(activityIdString || '');

    const { isMobile } = useScreenSize();
    const { effectiveProviderData } = useAuth();

    const { toDos, loading: toDosLoading } = useTodos(effectiveProviderData?.providerId);

    const navigate = useNavigate();

    const [drawerOpen, setDrawerOpen] = useState(false);

    const HandleDrawerOpen = useCallback(() => {
        setDrawerOpen(true);
    }, []);

    const HandleDrawerClose = useCallback(() => {
        setDrawerOpen(false);
    }, []);
    
    const [getActivityData, activityQuery] = useLazyQueryWithCacheExpiry(ACTIVITY_QUERY, {
        variables: { activityId: activityId },
        errorPolicy: 'all',
        fetchPolicy: 'cache-first',
        expiryEvents: [
            'UPDATE_APPT_STATUS_MUTATION',
            'TERMINATE_UPDATE_STATUS_TODO_MUTATION',
            'SAVE_FIRST_APPT_DATE',
            'ACCEPT_REFERRAL',
        ],
    });
    
    const [getContractDetails, contractDatailsQuery] = useLazyQueryWithCacheExpiry(CONTRACT_DETAILS_QUERY, {
        variables: { activityId: activityId },
        fetchPolicy: 'cache-first'
    });

    const [doRequestsQuery, requestsQuery] = useLazyQueryWithCacheExpiry(GET_REQUESTS_QUERY, {
        variables: {
            activityId: activityId,
            providerId: effectiveProviderData!.providerId
        },
        errorPolicy: 'all',
        fetchPolicy: 'cache-first',
        notifyOnNetworkStatusChange: true
    });

    const activityAcceptedQuery = useQueryWithCacheExpiry(IS_ACTIVITY_ACCEPTED_QUERY, {
        variables: { activityId: activityId },
        errorPolicy: 'all',
        fetchPolicy: 'cache-first',
        onCompleted: (data) => {
            if (data?.isActivityAcceptedByProvider) {
                getActivityData();
                getContractDetails();
                doRequestsQuery();
            } else {
                navigate(-1);
            }
        }
    });

    const activity = activityQuery.data?.activity;
    const forms = activityQuery.data?.formsByActivity;
    const referral = activityQuery.data?.referral;
    const contractDetails = contractDatailsQuery.data?.contractDetails;
    const additionalHoursApprovals = requestsQuery.data?.additionalHoursApprovals;

    const step = activity && contractDetails && toDos
        ? GetNextStep(activity, forms, referral, contractDetails, toDos)
        : null;

    const activityDetailsHeaderProps: ActivityDetailsHeaderProps = {
        activity: activity,
        referral: referral,
        service: activity?.service,
        presentingIssue: activity?.presentingIssue,
        step: step,
        onClose: HandleDrawerClose,
        contractDetails: contractDetails
    }

    const handleAdditionalHoursRequestCreated = useCallback(() => {
        requestsQuery?.refetch?.({
            activityId: activityId,
            providerId: effectiveProviderData!.providerId
        });
    }, [activityId, effectiveProviderData?.providerId]);

    const loading = !translationsReady || activityAcceptedQuery.loading || activityQuery.loading
        || toDosLoading || requestsQuery.networkStatus === NetworkStatus.loading
        || requestsQuery.networkStatus === NetworkStatus.refetch;

    return (
        <>
            {
                translationsReady && 
                <PageMetadata 
                    pageDescription={t('client_page_description')} 
                    pageTitle={t('client_page_title')}
                /> 
            }
            <div className="activity_details_view_container">
                {
                    loading
                        ? <Spinner />
                        : activity
                            ? <>
                                <SwipeableDrawer
                                    anchor={isMobile ? "bottom" : "left"}
                                    variant={isMobile ? "temporary" : "permanent"}
                                    open={drawerOpen}
                                    onClose={HandleDrawerClose}
                                    onOpen={HandleDrawerOpen}
                                    className="activity_details_header"
                                >
                                    <ActivityDetailsHeader {...activityDetailsHeaderProps}/>
                                </SwipeableDrawer>
                                <ActivityDetailsContent activity={activity} contractDetails={contractDetails} forms={forms}
                                    step={step} onShowDetailsClick={HandleDrawerOpen} additionalHoursApprovals={additionalHoursApprovals}
                                    onAdditionalHoursRequestCreated={handleAdditionalHoursRequestCreated}
                                />
                            </>
                            : <FullScreenMessage title={t('activity_not_available_message')}>
                                <Button variant="outline" component={Link} to={{pathname: '/clients'}}>
                                    {t('go_to_activities_list_button_label')}
                                </Button>
                            </FullScreenMessage>
                }
            </div>
        </>
    );
}

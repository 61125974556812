import React from 'react';
import { Spinner } from 'components/Shared';
import { Alert } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Button } from 'components/Shared/Inputs';

interface AcceptanceDecisionProps {
    activity: any,
    onAccept: () => void,
    onDecline: () => void,
    isExpired: boolean,
    loading: boolean
}

export const AcceptanceDecision: React.FC<AcceptanceDecisionProps> = (props) => {
    const { t } = useTranslation('caseManagement', { useSuspense: false });

    const { onAccept, onDecline, activity, isExpired, loading } = props;

    return (
        <div className="confirm_accept_wrapper_div">
            <p>
                {t('please_confirm_that_you_would_like_to_work_with')} {activity.client.firstName} {activity.client.lastName}.
            </p>
            {
                loading
                    ? <Spinner/>
                    : <>
                        <Button variant="opaque" onClick={onAccept} disabled={isExpired}>
                            {t('work_with')} {activity.client.firstName}
                        </Button>
                        <Button variant="text" onClick={onDecline} disabled={isExpired} className="decline_case_button">
                            {t('decline_case')}
                        </Button>
                    </>
            }
            {
                isExpired &&
                <Alert severity='error'>
                    {t('the_referral_has_expired_and_cannot_be_accepted')}
                </Alert>
            }
        </div>
    );
}

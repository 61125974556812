// import './InvoiceDetails.scss';
import { Container } from '@mui/material';
import { useQuery } from '@apollo/client';
import print from 'print-js';
import React, { FC } from 'react';

import { Spinner } from 'components/Shared';
import { IActivityInvoiceData } from '../../interfaces';
import { InvoicePreviewHTML } from '../InvoicePreview';
import { InvoiceDetailsHeader } from '../InvoiceDetailsHeader';
import { InvoiceDetailsSummary } from '../InvoiceDetailsSummary';

import { INVOICE_DETAILS_QUERY } from '../../queries';

interface InvoiceOverviewProps {
    activity: IActivityInvoiceData,
    providerId: number,
    invoiceId: number,
    canGenerateInvoice: boolean,
    useWspFunctionality: boolean
}

export const InvoiceOverview: FC<InvoiceOverviewProps> = (props) => {
    const { activity, invoiceId, providerId, canGenerateInvoice, useWspFunctionality } = props;

    const invoiceDetailsQuery = useQuery(INVOICE_DETAILS_QUERY, {
        variables: {
            invoiceId: invoiceId,
            providerId: providerId
        },
        errorPolicy: 'all',
        fetchPolicy: 'network-only'
    });

    const activityId = activity.activityId;

    const invoice = invoiceDetailsQuery.data?.invoice;
    const invoices = activity?.invoices;

    const handlePrint = async () => {
        if (invoice) {
            const { generateInvoicePDF2 } = await import('../../utils/generateInvoicePDF');
            const pdf = await generateInvoicePDF2(invoice.invoiceId);
            const printable = pdf.output('bloburl');
            print({ type: 'pdf', printable });
        }
    };

    const handleSavePDF = async () => {
        if (invoice) {
            const { generateInvoicePDF2 } = await import('../../utils/generateInvoicePDF');
            const pdf = await generateInvoicePDF2(invoice.invoiceId);
            pdf.save('invoice.pdf');
        }
    };

    const loading = invoiceDetailsQuery.loading;

    return (
        <Container maxWidth="lg" className="invoice_details_content_wrapper">
            {
                loading
                    ? <div className="invoice_details_spinner_wrapper"><Spinner/></div>
                    : <>
                        <InvoiceDetailsHeader
                            invoices={invoices ?? []}
                            printAvailable={true}
                            selectedInvoice={invoice}
                            generateInvoiceAvailable={canGenerateInvoice}
                            activityId={activityId!}
                            onPrintClick={handlePrint}
                            onSavePDFClick={handleSavePDF}
                        />
                        <InvoiceDetailsSummary
                            invoice={invoice}
                            uninvoicedAppointmentsCount={activity.invoicableSessions?.length ?? 0}
                        />
                        {
                            invoice && <InvoicePreviewHTML showWspTable={useWspFunctionality} invoice={invoice} activity={activity}/>
                        }
                    </>
            }
        </Container>
    )
}
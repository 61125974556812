import React, { FC, useState, useEffect } from 'react';
import { Dialog, DialogTitle, IconButton } from '@mui/material';
import { UnavailabilityForm } from '../UnavailabilityForm';
import { UnavailabilityReasonsQueryData, UNAVAILABILITY_REASONS_QUERY, UnavailabilityReasonsQueryVars,
    SaveUnavailabilityMutationData, DeleteUnavailabilityMutationData } from "../../queries";
import { useQuery } from "@apollo/client";
import { useAuth } from 'components/Auth';
import { Unavailability, DatesRange } from '../../interfaces';
import CloseIcon from '@mui/icons-material/Close';

import './UnavailabilityDialog.scss';
import { useTranslation } from 'react-i18next';
import { Spinner } from 'components/Shared';
import { useDeleteUnavailability, useSubmitUnavailability } from '../../hooks';
import { useIntercessionWspTaskCodes, useWspTaskCodes } from 'hooks/useWspTaskCodes'
import { ActivitySolutionCodes, useProviderActivities } from 'hooks/useProviderActivities'
import { ExecutionResult } from 'graphql';
import moment from 'moment';
import { ACTIVE_STATUS_CODE as ACTIVITY_ACTIVE_STATUS_CODE } from 'constants/activityStatus'


interface UnavailabilityDialogProps {
    open: boolean,
    datesRange?: DatesRange,
    selectedUnavailability?: Unavailability,
    onClose: () => void,
    onUnvailabilitySaved?: (result: ExecutionResult<SaveUnavailabilityMutationData>) => void,
    onUnavailabilityDeleted?: (result: ExecutionResult<DeleteUnavailabilityMutationData>) => void
}

export const UnavailabilityDialog: FC<UnavailabilityDialogProps> = (props) => {
    const { t, ready } = useTranslation('unavailabilities', { useSuspense: false });
    const { open, datesRange, selectedUnavailability, onClose,
        onUnvailabilitySaved, onUnavailabilityDeleted } = props;
    const { effectiveProviderData } = useAuth();
    const [ unavailability, setUnavailability ] = useState<Unavailability | undefined>(undefined);

    const unavailabilityReasonsQuery = useQuery<UnavailabilityReasonsQueryData, UnavailabilityReasonsQueryVars>(
        UNAVAILABILITY_REASONS_QUERY, 
        {
            variables: {
                providerId: effectiveProviderData?.providerId || 0,
            }
        }
    );


   
    const wspTaskCodes = useWspTaskCodes()
    const intercessionWspTaskCodes = useIntercessionWspTaskCodes()
    const { allClients } = useProviderActivities(effectiveProviderData?.providerId || 0)

    
    const activeWspClients = allClients
        ?.filter((activity) => activity.activityStatusCode === ACTIVITY_ACTIVE_STATUS_CODE)
        .filter((activity) => activity.solutionCode === ActivitySolutionCodes.ECS)


    useEffect(() => {
        if (selectedUnavailability) {
            setUnavailability(selectedUnavailability);
        } else if (datesRange) {
            setUnavailability({
                recurring: false,
                startDate: new Date(datesRange.start),
                endDate: new Date(datesRange.end),
                startTime: moment(datesRange.start).format('HH:mm:ss'),
                endTime: moment(datesRange.end).format('HH:mm:ss'),
            })
        } else {
            setUnavailability(undefined);
        }
    }, [datesRange, selectedUnavailability]);

    const deleteUnavailability = useDeleteUnavailability();
    const handleDeleteUnavailability = (unavailability: Unavailability) => {
        return deleteUnavailability(unavailability)
            .then((result) => {
                onUnavailabilityDeleted?.(result);
                return result;
            })
    }

    const submitUnavailability = useSubmitUnavailability();
    const handleUnavailabilitySubmit = (unavailability: Unavailability) => {
        return submitUnavailability(unavailability)
            .then((result) => {
                onUnvailabilitySaved?.(result);
                return result;
            })
    }

    return (
        <Dialog open={open} className="add_unavailability_modal">
            <DialogTitle>
                <h3 className="add_unavailability_modal_title global__header3">
                    {
                        selectedUnavailability?.scheduleId
                            ? t('edit_unavailability_modal_header', 'Edit unavailability')
                            : t('add_unavailability_modal_header', 'Add unavailability')
                    }
                </h3>
                <IconButton aria-label="close" className="add_unavailability_modal_close" onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            {
                !ready && unavailabilityReasonsQuery.loading
                ? <Spinner/>
                : <div className="add_unavailability_modal_content">
                        <UnavailabilityForm 
                            onSubmit={handleUnavailabilitySubmit} 
                            onCancel={onClose} 
                            unavailability={unavailability}
                            managedCalendar={true}
                            wspClients={activeWspClients}
                            onDelete={handleDeleteUnavailability}
                            wspTaskCodes={wspTaskCodes}
                            intercessionWspTasks={intercessionWspTaskCodes}
                            unavailabilityReasons={unavailabilityReasonsQuery?.data?.schedule?.unavailabilityReasons}
                            addresses={unavailabilityReasonsQuery?.data?.schedule?.addresses}
                        />
                    </div>
            }
            
        </Dialog>
    )
}

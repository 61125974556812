export enum TEMPLATE_FILTERS {
    EAP = "eap",
    SSP = "ssp",
}

export enum SESSION_STATUS {
    ACTIVE = 1,
    MISSED = 2,
    ATTENDED = 3,
    CANCELLED_PROVIDER = 4,
    CANCELLED_GT = 5,
    CANCELLED_LT = 6,
    BOOKED_IN_ERROR = 7
}

export enum SolutionCode {
    EAP = "EAP",
    ECS = 'ECS',
    SSP = "SSP"
}

export enum EMPLOYEE_TYPES {
    FULLTIME = "FULLTIME",
    PARTTIME = "PARTTIME",
    CONTRACT = "CONTRACT"
}

export const allEmployeeTypes = [
    EMPLOYEE_TYPES.FULLTIME,
    EMPLOYEE_TYPES.PARTTIME,
    EMPLOYEE_TYPES.CONTRACT
]

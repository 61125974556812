import React, { useRef, forwardRef, ChangeEvent, useEffect } from 'react';
import { Button } from '../Buttons';
import { useRadioGroup, RadioProps, useFormControl } from '@mui/material';
import clsx from 'clsx';

import './ButtonRadio.scss';
interface ButtonRadioProps extends Omit<RadioProps, "checkedIcon" | "color" | "icon" | "size" | "ref"> {
    label: React.ReactElement | string
}

export const ButtonRadio = forwardRef<HTMLInputElement, ButtonRadioProps>((props, forwardedRef) => {
    const { disabled, value, onChange, onFocus, onBlur, label, className, inputRef: forwardedInputRef, ...other } = props;
    const radioGroup = useRadioGroup();
    const formControl = useFormControl();

    const isSelected = (value === radioGroup?.value);

    const buttonRef = useRef<HTMLButtonElement>(null);
    const inputRef = useRef<HTMLInputElement>(null);

    useEffect(() => {
        if (forwardedInputRef) {
            forwardedInputRef['current'] = inputRef.current;
        }
    }, []);

    const handleButtonClick = () => {
        inputRef.current?.click();
    }

    const handleInputFocus = (event) => {
        onFocus?.(event);
        setTimeout(() => buttonRef?.current?.classList.add('Mui-focusVisible'), 0);
    }

    const handleInputBlur = (event) => {
        onBlur?.(event);
        setTimeout(() => buttonRef?.current?.classList.remove('Mui-focusVisible'), 0);
    }

    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
        onChange?.(event, event.target.checked);
        radioGroup?.onChange?.(event, value as any);
    }

    const ariaProps = Object.keys(other)
        .filter(key => key.startsWith('aria'))
        .reduce((acc, key) => {
            acc[key] = other[key];
            return acc;
        }, {});

    return (
        <div className={clsx('ms-button-radio-container', className)} ref={forwardedRef}>
            <input
                {...ariaProps}
                type="radio"
                value={value as any}
                name={radioGroup?.name}
                className="ms-button-radio-input"
                aria-checked={isSelected ? 'true' : 'false'}
                checked={isSelected}
                onChange={handleChange}
                onFocus={handleInputFocus}
                onBlur={handleInputBlur}
                ref={inputRef}
                disabled={disabled}
            />
            <Button
                className={clsx(formControl?.error && 'Mui-error')}
                variant={isSelected ? 'opaque' : 'outline'}
                disabled={disabled}
                onClick={handleButtonClick}
                tabIndex={-1}
                fullWidth
                ref={buttonRef}
                // itemRef={buttonRef}
                // innerRef={buttonRef}
            >
                {label}
            </Button>
        </div>
    )
})
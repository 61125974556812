import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

interface NoToDosCardProps {
    clientName: string
}

const NoToDosStep = 'NoToDosStep';

export const NoToDosCard: FC<NoToDosCardProps> = ({clientName}) => {
    const { t, ready } = useTranslation('prompts', { useSuspense: false });

    const header = t(NoToDosStep + '-header');
    const body = t(NoToDosStep + '-body', { replace: { clientName: clientName }});

    return <>
        { 
            ready &&
            <div className="no_todos_card_wrapper">
                <section className="content_wrapper">
                    <h2>{ header }</h2>
                    <p>{ body }</p>
                </section>
            </div>
        }  
    </>
}
import React, { FC, useState } from 'react';
import { Button } from 'components/Shared/Inputs';
import { IToggleButtonProps } from './interfaces';
import clsx from 'clsx';
import './ToggleButton.scss';

export const ToggleButton: FC<IToggleButtonProps> = (props) => {
    const { value, onChange, disabled, leftButtonLabel, rightButtonLabel, leftClassName, rightClassName, className } = props; 

    const [falseFocused, setFalseFocused] = useState(false);
    const [trueFocused, setTrueFocused] = useState(false);

    return (
        <div role="radiogroup" className={clsx("radio-group", className)}>
            <div className={`left-side-container ${leftClassName}`}>
                <input type="radio" name="radio-input" value="true"
                    className="radio_selector_radio"
                    aria-checked={value === true ? "true" : "false"}
                    aria-label={leftButtonLabel} 
                    onClick={() => onChange(true)}
                    onFocus={() => setTrueFocused(true)}
                    onBlur={() => setTrueFocused(false)}
                    tabIndex={value === false ? -1 : 0}
                />
                <Button className={`${trueFocused ? 'Mui-focusVisible' : ''} left-button`} variant="opaque"
                    disabled={disabled} onClick={() => onChange(true)} tabIndex={-1}>
                    {leftButtonLabel} 
                </Button>
            </div>
            <div className={`right-side-container ${rightClassName}`}>
                <input type="radio" name="radio-input" value="false"
                    className="radio_selector_radio"
                    aria-checked={value === false ? "true" : "false"}
                    aria-label={rightButtonLabel} 
                    onClick={() => onChange(false)}
                    onFocus={() => setFalseFocused(true)}
                    onBlur={() => setFalseFocused(false)}
                    tabIndex={value === true ? -1 : 0}
                />
                <Button className={`${falseFocused ? 'Mui-focusVisible' : ''} right-button`} variant="opaque"
                    disabled={disabled} onClick={() => onChange(false)} tabIndex={-1}>
                    {rightButtonLabel} 
                </Button>
            </div>
        </div>
    )
}

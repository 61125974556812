import { IActivityClientData, IClientValueSummary, IActivities } from "./interfaces"
import { ACTIVITIES_QUERY } from './queries';
import { useQuery } from "@apollo/client";

export interface IUseProvider {
    allClients: IClientValueSummary[] | undefined,
    loading: boolean
}

export const mapActivityToListItem = (
    activity: IActivityClientData,
): IClientValueSummary => {
    const additionalParticipantsString = activity.additionalParticipants
            ?.map(participant => `${participant.firstName} ${participant.lastName}`)
            ?.join(', ');
        
    const additionalParticipantsLabel = additionalParticipantsString
            ? '(' + additionalParticipantsString + ')'
            : '';
    return {
        label: `${activity.client?.firstName} ${activity.client?.lastName} ${additionalParticipantsLabel}`,
        id: activity.activityId,
        ...activity,
    }
}

export const useProviderActivities = (providerId?: number): IUseProvider => {
    const { data: allClientsResponse, loading } = useQuery<IActivities, { providerId: number }>(ACTIVITIES_QUERY, {
        variables: { providerId: providerId ?? -1 },
        fetchPolicy: 'network-only',
    })

    const allClients: IClientValueSummary[] | undefined = 
        allClientsResponse?.activities?.map(activity => mapActivityToListItem(activity))
        .sort(function (client1, client2) {
            return client1.label.localeCompare(client2.label)
        }) || []

    return { allClients, loading }
}
import React, { FC, useEffect, useMemo, useState } from "react";
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import { EducationInput, Education } from '../../../interfaces';
import { Checkbox, FormControlLabel, Grid, MenuItem } from "@mui/material";
import { Button, DateInput, Select, TextBox } from 'components/Shared/Inputs';
import { Spinner } from "components/Shared";
import { MutationTuple, useLazyQuery } from "@apollo/client";
import { UpdateEducationsMutationData, UpdateEducationsMutationVars } from "./queries";
import moment from "moment";
import AddIcon from '@mui/icons-material/Add';
import { SectionContainer } from '../../Other/SectionContainer';
import { useTimezoneUtils } from "hooks/useTimezoneUtils";
import { EDUCATION_REF_DATA } from './queries';
import { confirm } from 'components/Shared/ConfirmDialog';

const mapEducationToEducationInput = (education: Education): EducationInput => ({
    degreeId: education.degreeId,
    institution: education.institution,
    isPrimary: education.isPrimary,
    providerEducationId: education.providerEducationId,
    yearEarned: education.yearEarned
})


interface EducationFormProps {
    providerId: number,
    educations?: Education[],
    education?: Education,
    mutation: MutationTuple<UpdateEducationsMutationData, UpdateEducationsMutationVars>,
    cachePendingUpdate: boolean
}

interface EducationFormValues {
    providerEducationId: number | null,
    degreeId: number | null,
    institution: string,
    yearEarned: string,
    isPrimary: boolean
}

export const EducationForm: FC<EducationFormProps> = (props) => {
    const { providerId, educations, education, mutation, cachePendingUpdate } = props;

    const { t } = useTranslation('providerprofile', { useSuspense: false });
    const { t: t_common } = useTranslation('common', { useSuspense: false });
    
    const { formatDateWithoutTimezoneConversion, localeDateFormat } = useTimezoneUtils();

    const [requestPending, setRequestPending] = useState(false);
    
    const [inEdit, setInEdit] = useState(false);

    const [getRefData, refDataQuery] = useLazyQuery(EDUCATION_REF_DATA);
    
    useEffect(() => {
        if (inEdit && !refDataQuery.called) {
            getRefData();
        }
    }, [inEdit]);
    
    const [updateEducations, updateEducationsMutation] = mutation;

    const formik = useFormik<EducationFormValues>({
        initialValues: {
            providerEducationId: education?.providerEducationId ?? null,
            degreeId: education?.degreeId ?? null,
            institution: education?.institution ?? '',
            yearEarned: education?.yearEarned ?? moment().format('YYYY-MM-DD'),
            isPrimary: education?.isPrimary ?? false
        },
        validate: (values) => {
            const errors: any = {};

            if (!values.degreeId) {
                errors.degreeId = "Degree is required"
            }

            if (!values.institution) {
                errors.institution = "Institution is required"
            }

            if (!values.yearEarned) {
                errors.yearEarned = "Year is required"
            }

            return errors;
        },
        validateOnBlur: true,
        validateOnChange: true,
        onSubmit: async (values) => {
            formik.validateForm();

            if (formik.isValid) {
                const dialogProps = {
                    message: <>
                        <p>{t('common__confirm_changes_dialog_text')}</p>
                        <ul>
                        {
                            (!education || values.degreeId !== education.degreeId) &&
                            <li>{t('education__degree_input_label')}: <b>{options?.find(degree => degree.value === values.degreeId)?.title}</b></li>
                        }
                        {
                            (!education || values.institution !== education.institution) &&
                            <li>{t('education__institution_input_label')}: <b>{values.institution}</b></li>
                        }
                        {
                            (!education || values.yearEarned !== education.yearEarned) &&
                            <li>{t('education__year_earned_input_label')}: <b>{moment(values.yearEarned, "YYYY-MM-DD").format(localeDateFormat)}</b></li>
                        }
                        {
                            (!education || values.isPrimary !== education.isPrimary) &&
                            <li>{t('education__is_primary_checkbox_label')}: <b>{values.isPrimary ? t_common('yes') : t_common('no')}</b></li>
                        }
                        </ul>
                    </>
                }
                if (await confirm(dialogProps)) {
                    setRequestPending(true);
                    const updatedEducations = [
                        ...(educations?.filter(e => e !== education)?.map(mapEducationToEducationInput) || []),
                        {
                            degreeId: values.degreeId!,
                            institution: values.institution,
                            isPrimary: values.isPrimary,
                            providerEducationId: values.providerEducationId,
                            yearEarned: values.yearEarned
                        }
                    ];
                    updateEducations({
                        variables: {
                            providerId: providerId,
                            educations: updatedEducations
                        }
                    }).then(() => { setInEdit(false); formik.resetForm(); }).finally(() => setRequestPending(false));
                }
            }
        }
    });

    const handleDelete = async () => {
        const dialogProps = {
            title: t('common__delete_entry_dialog_title'),
            message: t('common__delete_entry_dialog_text')
        }
        if (await confirm(dialogProps)) {
            setRequestPending(true);
            updateEducations({
                variables: {
                    providerId: providerId,
                    educations: [
                        ...(educations?.filter(e => e !== education)?.map(mapEducationToEducationInput) || [])
                    ]
                }
            }).then(() => setInEdit(false)).finally(() => setRequestPending(false));
        }
    }

    const hasChanges = formik.dirty 
        && (formik.values.degreeId !== education?.degreeId
            || formik.values.institution !== education?.institution
            || formik.values.isPrimary !== education?.isPrimary
            || formik.values.yearEarned !== education?.yearEarned);

    const deleteDisabled = updateEducationsMutation.loading || cachePendingUpdate;
    const submitDisabled = deleteDisabled || !hasChanges || !formik.isValid;

    const handleCancelChanges = async () => {
        if (hasChanges) {
            const dialogProps = {
                title: t('common__discard_changes_dialog_title'),
                message: t('common__discard_changes_dialog_text')
            }
            if (await confirm(dialogProps)) {
                formik.resetForm();
                setInEdit(false);
            }
        } else {
            formik.resetForm();
            setInEdit(false);
        }
    }

    const options = useMemo(() =>
        refDataQuery.data?.degrees?.map(degree => ({title: degree.title, value: Number(degree.value)}))
    , [refDataQuery.data?.degrees]);

    const handleYearEarnedChange = (date) => {
        date &&
        formik.setFieldValue('yearEarned', date.format('YYYY-MM-DD'));
    }

    return !education && !inEdit
        ? <Button variant="text" onClick={() => setInEdit(true)} className="add_entry_button">
            <AddIcon/>{t('common__add_entry_button_label')}
        </Button>
        : <SectionContainer elevation={0} radius="small" inEdit={inEdit} onEditClick={() => setInEdit(true)}>
            {
                inEdit
                ? (
                    <Grid className="credentialing_sub_section_form" container component='form' onSubmit={formik.handleSubmit} spacing={3}>
                        {
                            (requestPending || cachePendingUpdate) &&
                            <Spinner fillParent backdrop />
                        }
                        <Grid item xs={12} xl={6}>
                            <Select
                                value={formik.values.degreeId ?? ''}
                                label={t('education__degree_input_label')}
                                name="degreeId"
                                onChange={formik.handleChange}
                                className="provider_details_input"
                                error={Boolean(formik.errors.degreeId)}
                            >
                                {
                                    options?.map(degree => (
                                        <MenuItem value={degree.value} key={degree.value}>
                                            {degree.title}
                                        </MenuItem>
                                    ))
                                }
                            </Select>
                        </Grid>
                        <Grid item xs={12} xl={6}>
                            <TextBox
                                value={formik.values.institution}
                                label={t('education__institution_input_label')}
                                name="institution"
                                onChange={formik.handleChange}
                                className="provider_details_input"
                                error={Boolean(formik.errors.institution)}
                            />
                        </Grid>
                        <Grid item xs={12} xl={6}>
                            <DateInput
                                value={moment(formik.values.yearEarned, 'YYYY-MM-DD')}
                                label={t('education__year_earned_input_label')}
                                className="provider_details_input"
                                // name="yearEarned"
                                // format={localeDateFormat}
                                onChange={handleYearEarnedChange}
                                // error={Boolean(formik.errors.yearEarned)}
                            />
                        </Grid>
                        <Grid item xs={12} xl={6}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={formik.values.isPrimary}
                                        onChange={formik.handleChange}
                                        name="isPrimary"
                                        color="primary"
                                    />
                                }
                                label={t('education__is_primary_checkbox_label') as string}
                            />
                        </Grid>
                        <Grid item xs={12} container spacing={2}>
                            <Grid item>
                                <Button type="submit" variant="opaque" disabled={submitDisabled}>{t_common('save')}</Button>
                            </Grid>
                            <Grid item>
                                <Button type="button" variant="outline" onClick={handleCancelChanges}>{t_common('cancel')}</Button>
                            </Grid>
                            { 
                                education && inEdit && ((educations?.length ?? 0) > 1) &&
                                <Grid item>
                                    <Button type="button" variant="text" onClick={handleDelete} disabled={deleteDisabled}>{t_common('delete')}</Button>
                                </Grid>
                            }
                        </Grid>
                    </Grid>
                )
                : (
                    education &&
                    <>
                        <p className='global__paragraph'>{education.degree} {education.isPrimary && t('education__is_primary_flag')}</p>
                        <p>{education.institution}</p>
                        {
                            education.yearEarned &&
                            <p>{t('education__completed_date_label')} {formatDateWithoutTimezoneConversion(education.yearEarned, 'YYYY')}</p>
                        }
                    </>
                )
            }
        </SectionContainer>
}
import React, { FC } from 'react';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import { Email } from '../../../interfaces';
import { Card } from 'components/Shared/Card';

interface EmailFormProps {
    email: Email
}

export const EmailForm: FC<EmailFormProps> = (props) => {
    const { email } = props;

    const { t, ready: translationsReady } = useTranslation('providerprofile', { useSuspense: false });

    const formik = useFormik({
        initialValues: {
            confidential: email.confidential,
            emailAddress: email.emailAddress,
            primary: email.primary,
            providerEmailId: email.providerEmailId,
            inEdit: false
        },
        onSubmit: (values) => {
            console.log(values);
        }
    });

    return !translationsReady
        ? <></>
        : <Card elevation={0} radius="small" className='contact_info_container'>
            {
                formik.values.inEdit
                    ? <form onSubmit={formik.handleSubmit}>
                    </form>
                    : <>
                        <p className='contact_info_contact'>{email.emailAddress}</p>
                        {
                            email.primary && email.confidential
                                ? <p className='contact_info_contact_details'>
                                    {t('common__preferred_flag')} <span className="bullet"/> {t('common__confidential_flag')}
                                </p>
                                : <React.Fragment>
                                    {
                                        email.primary &&
                                            <p className='contact_info_contact_details'>{t('common__preferred_flag')}</p>
                                    }
                                    {
                                        email.confidential &&
                                            <p className='contact_info_contact_details'>{t('common__confidential_flag')}</p>
                                    }
                                </React.Fragment>
                        }
                    </>
            }
        </Card>
}
export enum SESSION_APPT_STATUSES {
    ACTIVE = '1',
    MISSED = '2',
    ATTENDED = '3',
    CANCELLED_PROVIDER = '4',
    CANCELLED_GT = '5',
    CANCELLED_LT = '6',
    BOOKED_IN_ERROR = '7'
}


export const WSP_COMPATIBLE_APPT_STATUSES = [
    SESSION_APPT_STATUSES.ATTENDED,
    SESSION_APPT_STATUSES.MISSED,
    SESSION_APPT_STATUSES.CANCELLED_PROVIDER,
    SESSION_APPT_STATUSES.CANCELLED_LT 
]



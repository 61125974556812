import React, { createContext, FC, useState, useEffect, useRef, PropsWithChildren } from 'react';
import { AuthAction, AuthData, ProviderData } from './interfaces';
import createPersistedState from 'use-persisted-state';
import { useIdleTimer } from 'react-idle-timer';
import { MapLoginResponseToAuthData, IsTokenValid, IsTokenAboutToExpire } from './Utils/AuthUtils';
import { useLoginRequest, useRefreshRequest, useLogoutRequest, useSwitchProfileRequest } from './hooks';
import { LoginResponse } from '.';
import { Spinner } from 'components/Shared';
import { BroadcastChannel } from 'broadcast-channel';
import { useAppConfig } from 'providers/AppConfig';

const usePersistedAuthData = createPersistedState<AuthData | null>('auth-data', sessionStorage);

export const ADMIN_USER_ID = 0;

interface AuthContextProps {
	authenticated: boolean;
	providerData: ProviderData | undefined;
	effectiveProviderData: ProviderData | undefined;
	resetPasswordUrl: string;
	login: (username: string, password: string) => Promise<LoginResponse>;
	logout: () => Promise<void>;
	setEffectiveProvider: (providerId: number) => Promise<LoginResponse>,
	authFetch: (input: RequestInfo, init?: RequestInit) => Promise<Response>
}

const AuthContext = createContext<AuthContextProps>({
	authenticated: false,
	providerData: undefined,
	effectiveProviderData: undefined,
	resetPasswordUrl: '',
	login: () => new Promise(() => { }),
	logout: () => new Promise(() => { }),
	setEffectiveProvider: (_) => new Promise(() => { }),
	authFetch: () => new Promise(() => { })
});

AuthContext.displayName = "AuthContext";

const AuthProvider: FC<PropsWithChildren> = ({ children }) => {
	const [persistedAuthData, setPersistedAuthData] = usePersistedAuthData(null);
	const [session, setSessionData] = useState<string | null>(localStorage.getItem('X-Session-ID'));
	const [adminSession, setAdminSessionData] = useState<string | null>(localStorage.getItem('X-Admin-Session-ID'));
	const [initPending, setInitPending] = useState(true);
	const config = useAppConfig();

	let { current: authenticationData } = useRef<AuthData | null>(null);
	let { current: authChannel } = useRef<BroadcastChannel | null>(null);
	let { current: refreshTimeout } = useRef<number | null>(null);
	let { current: sessionData } = useRef<string | null>(session);
	let { current: adminSessionData } = useRef<string | null>(adminSession);

	useEffect(() => {
		return () => {
			const close = async () => {
				authChannel = new BroadcastChannel('auth');
				authChannel.onmessage = handleMessage;
				await authChannel.close();
			}
			close();
		}
	}, []);

	useEffect(() => {
		authChannel?.postMessage({ cmd: AuthAction.SyncRequest, data: null });
		setTimeout(() => {
			if (session || adminSession) {
				if (!authenticationData) {
					let isValid = persistedAuthData && IsTokenValid(persistedAuthData?.effectiveToken) && IsTokenAboutToExpire(persistedAuthData?.effectiveToken) === false;
					if (isValid) {
						setAuthData(persistedAuthData, true)
					} else {
						refresh()
							.catch(() => { })
							.finally(() => setInitPending(false))
					}
				}
			} else {
				setInitPending(false);
			}
		}, 10);
	}, []);

	useEffect(() => {
		if (session) {
			localStorage.setItem('X-Session-ID', session);
			return;
		}
		localStorage.removeItem('X-Session-ID');
	}, [session])

	useEffect(() => {
		if (adminSession) {
			localStorage.setItem('X-Admin-Session-ID', adminSession);
			return;
		}
		localStorage.removeItem('X-Admin-Session-ID');
	}, [adminSession])

	const handleMessage = (ev: MessageEvent<{ cmd: AuthAction, data: AuthData | null }>) => {
		switch (ev.data.cmd) {
			case AuthAction.SyncRequest: {
				authenticationData && authChannel?.postMessage({ cmd: AuthAction.SyncResponse, data: authenticationData });
				break;
			}
			case AuthAction.SyncResponse: {
				!authenticationData && setAuthData(ev.data.data, true);
				break
			}
			default: {
				setAuthData(ev.data.data, true);
			}
		}
	}

	const setAuthData = (authData: AuthData | null, override?: boolean) => {
		authenticationData = authData;
		const authenticated = Boolean(authData?.providerData)

		// only set the state when needed - typically it will be done once.
		if (override) {
			setContextValue({
				...contextValue,
				providerData: authData?.providerData ?? undefined,
				effectiveProviderData: authData?.effectiveProviderData ?? undefined,
				authenticated: authenticated
			});
		}

		setPersistedAuthData(authData);

		// set the session id
		sessionData = authData?.providerData?.id || null
		setSessionData(sessionData);

		// set admin session id
		adminSessionData = null;
		if (authData?.effectiveProviderData?.id && authData?.effectiveProviderData?.id !== sessionData) {
			adminSessionData = authData?.effectiveProviderData?.id || null;
		}
		setAdminSessionData(adminSessionData);

		initPending && setInitPending(false);

		refreshTimeout && clearTimeout(refreshTimeout);
		if (authenticated && typeof window !== 'undefined') {
			refreshTimeout = window.setTimeout(refresh, config?.REFRESH_TIMEOUT_VALUE_IN_MILISECONDS || 3_600_000);
		}
	}

	const authFetch = async (input: RequestInfo, init?: RequestInit) => {

		let headers = {
			...init?.headers
		}

		// set the session id
		if (sessionData) {
			headers['X-Session-ID'] = `${sessionData}`;
		}

		// set the admin id
		if (adminSessionData && adminSessionData !== sessionData) {
			headers['X-Admin-Session-ID'] = `${adminSessionData}`;
		}

		let status: boolean = authenticationData?.effectiveToken === undefined && !refreshRequest.promise && !loginRequest.promise && !switchProfileRequest.promise;
		if (status) {
			let requestInit = {
				...init,
				headers: headers
			}

			return fetch(input, requestInit);
		}

		let isTokenValid = IsTokenValid(authenticationData?.effectiveToken);
		if (isTokenValid) {

			headers['Authorization'] = `Bearer ${authenticationData?.effectiveToken}`

			let requestInit = {
				...init,
				headers: headers
			}

			return fetch(input, requestInit)
		}

		try {
		const data = await (switchProfileRequest.promise || loginRequest.promise || refreshRequest.promise || refresh());
			const auth = MapLoginResponseToAuthData(data);

			if (auth?.effectiveToken) {
				headers['Authorization'] = `Bearer ${auth?.effectiveToken}`
			}
			// set the session id
			if (auth?.providerData?.id) {
				headers['X-Session-ID'] = `${auth?.providerData?.id}`;
			}

			// set admin session id
			if (auth?.effectiveProviderData?.id && auth?.effectiveProviderData?.id !== auth?.providerData?.id) {
				headers['X-Admin-Session-ID'] = `${auth?.effectiveProviderData?.id}`;
			}

			let requestInit = {
				...init,
				headers: headers
			}

			return fetch(input, requestInit);
		} catch {
			return fetch(input, init)
		}
	}

	const [login, loginRequest] = useLoginRequest({
		onCompleted: (data) => {
			if (data.error){
                return;
            }
			const newAuthData = MapLoginResponseToAuthData(data);
			setAuthData(newAuthData, true);
			authChannel?.postMessage({ cmd: AuthAction.Login, data: newAuthData });
		}
	});
	const [refresh, refreshRequest] = useRefreshRequest({
		prepareHeaders: () => {
			let headers = { 'Content-Type': 'application/json' };

			// set the session id
			if (sessionData) {
				headers['X-Session-ID'] = `${sessionData}`;
			}

			// set the admin id
			if (adminSessionData && adminSessionData !== sessionData) {
				headers['X-Admin-Session-ID'] = `${adminSessionData}`;
			}

			return headers;
		},
		onCompleted: (data) => {
			const newAuthData = MapLoginResponseToAuthData(data);
			setAuthData(newAuthData);
			authChannel?.postMessage({ cmd: AuthAction.Refresh, data: newAuthData });
		}
	});
	const [logout] = useLogoutRequest({
		prepareHeaders: () => {
			let headers = { 'Content-Type': 'application/json' };

			// set the session id
			if (sessionData) {
				headers['X-Session-ID'] = `${sessionData}`;
			}

			// set the admin id
			if (adminSessionData && adminSessionData !== sessionData) {
				headers['X-Admin-Session-ID'] = `${adminSessionData}`;
			}

			return headers;
		},
		onCompleted: () => {
			setAuthData(null, true);
			pauseIdleTimer();
			authChannel?.postMessage({ cmd: AuthAction.Logout, data: null });
		}
	});
	const [switchProfile, switchProfileRequest] = useSwitchProfileRequest({
		fetch: authFetch,
		onCompleted: (data) => {
			const newAuthData = MapLoginResponseToAuthData(data);
			setAuthData(newAuthData, true);
			authChannel?.postMessage({ cmd: AuthAction.SwitchProfile, data: newAuthData });
		}
	});

	const [contextValue, setContextValue] = useState({
		authenticated: Boolean(authenticationData?.providerData),
		providerData: authenticationData?.providerData,
		effectiveProviderData: authenticationData?.effectiveProviderData,
		resetPasswordUrl: (window.location.origin + '/reset_password/{token}'),
		login: (username, password) => login({ username: username, password: password }),
		logout: logout,
		setEffectiveProvider: (providerId: number) => switchProfile({ providerId }),
		authFetch: authFetch
	});

	const { start: startIdleTimer, reset: resetIdleTimer, pause: pauseIdleTimer, getTotalIdleTime } = useIdleTimer({
		onIdle: () => {
			console.log(`User was idle for ${(getTotalIdleTime() / 1000 / 60).toFixed(0)} minutes`);
			logout();
		},
		onAction: () => resetIdleTimer(),
		startManually: true,
		timeout: config?.IDLE_TIMEOUT_VALUE_IN_MILISECONDS || 3_600_000,
		crossTab: true
	});

	useEffect(() => {
		if (contextValue.authenticated) {
			startIdleTimer();
		}
	}, [contextValue.authenticated])

	return initPending
		? <Spinner fillParent />
		: <AuthContext.Provider value={contextValue} children={children} />;
};

const useAuth = () => React.useContext(AuthContext);

export { AuthContext, AuthProvider, useAuth };

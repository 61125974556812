import { ScheduleSlot } from "../../interfaces";
import * as Moment from 'moment-timezone';

export const scheduleKeysCompareFn = ((a: string, b: string) => {
    const [dayA, monthA, yearA] = a.split('/').map(val => Number(val));
    const [dayB, monthB, yearB] = b.split('/').map(val => Number(val));

    return (
        yearA - yearB === 0
            ? monthA - monthB === 0
                ? dayA - dayB
                : monthA - monthB
            : yearA - yearB
    );
});

export const sortScheduleKeys = (keys: string[]) => (
    keys.sort(scheduleKeysCompareFn)
)

export const convertTimeto12Clock = (time24: string) => {
    let ts = time24;
    let H = +ts.substr(0, 2);
    if (isNaN(H)) {
        // If NaN, then most likely hours do not have leading zeros 
        H = +ts.substr(0, 1);
    }
    let h: string | number = (H % 12) || 12;
    h = (h < 10) ? ("0"+h) : h;  // leading 0 at the left for 1 digit hours
    const ampm = H < 12 ? " AM" : " PM";
    ts = h + ts.substr(2, 3) + ampm;
    return ts;
}

export const groupSlotsByDate = (slots: ScheduleSlot[] | undefined, targetTimeZone: string) => {
    if (!slots) {
        return undefined;
    }

    const objGroups: {[date: string]: ScheduleSlot[]} = {};

    for (const slot of slots) {
        const convertedSlot = {
            ...slot,
            startDate: slot.startDate.clone().tz(targetTimeZone)
        };

        const date = convertedSlot.startDate.format('YYYY-MM-DD');

        objGroups[date]
            ? objGroups[date].push(convertedSlot)
            : objGroups[date] = [convertedSlot];
    }

    const groups = Object.keys(objGroups)
        .map(key => ({date: Moment.tz(key, 'YYYY-MM-DD', targetTimeZone), slots: objGroups[key]}))
        .sort((a, b) => a.date.unix() - b.date.unix());

    return groups;
}

import { gql, TypedDocumentNode } from '@apollo/client';
import { ToDo } from './interfaces';

export const TODO_QUERY: TypedDocumentNode<ToDoQueryData, ToDoQueryVars> = gql`
    query TODO_QUERY($providerId: Long!) {
        toDos(providerId: $providerId) {
            id
            providerId
            sessionId
            step
            visibility
            createTime
            formCategoryName
            formId
            activityInfo {
                clientName
                activityId
            }
        }
    }
`
export interface ToDoQueryData {
    toDos: ToDo[]
}

export interface ToDoQueryVars {
    providerId: number
}

export const TODO_SUBSCRIPTION: TypedDocumentNode<ToDoSubscriptionData, ToDoSubscriptionVars> = gql`
    subscription TODO_SUBSCRIPTION($providerId: Long!) {
        onToDoChange(providerId: $providerId) {
            id
            providerId
            sessionId
            step
            visibility
            createTime
            formCategoryName
            formId
            activityInfo {
                clientName
                activityId
            }
        }
    }
`
export interface ToDoSubscriptionData {
    onToDoChange: ToDo
}

export interface ToDoSubscriptionVars extends ToDoQueryVars {}



export const TERMINATE_TODO_MUTATION:  TypedDocumentNode<boolean, TerminateTodoMutationVars> = gql`
    mutation TERMINATE_TODO_MUTATION($workflowId: String!) {
        terminateToDo(workflowId: $workflowId)
    }
`

export interface TerminateTodoMutationVars {
    workflowId: string
}



export const TERMINATE_FIRST_APPOINTMENT_ASSESSMENT: TypedDocumentNode<boolean, TerminateFirstAssessmentMutationVars> = gql`
    mutation TERMINATE_FIRST_APPOINTMENT_ASSESSMENT($activityId: Long!) {
        closeFirstAppointmentAssessmentToDo(activityId: $activityId)
    }
`
export interface TerminateFirstAssessmentMutationVars {
    activityId: number
}



export const TERMINATE_FOLLOW_UP: TypedDocumentNode<boolean, TerminateFollowUpMutationVars> = gql`
    mutation TERMINATE_FOLLOW_UP($activityId: Long!) {
        closeFollowUpToDo(activityId: $activityId)
    }
`
export interface TerminateFollowUpMutationVars {
    activityId: number
}



export const TERMINATE_REVIEW: TypedDocumentNode<boolean, TerminateReviewMutationVars> = gql`
    mutation TERMINATE_REVIEW($activityId: Long!) {
        closeReviewClinicalNowPreAssessmentToDo(activityId: $activityId)
    }
`
export interface TerminateReviewMutationVars {
    activityId: number
}

export interface TerminateFormsTodoMutationVars extends TerminateFirstAssessmentMutationVars {}
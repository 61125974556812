import React, { FC } from 'react';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import { Phone } from '../../../interfaces';
import { Card } from 'components/Shared/Card';

interface PhoneFormProps {
    phone: Phone
}

export const PhoneForm: FC<PhoneFormProps> = (props) => {
    const { phone } = props;

    const { t, ready: translationsReady } = useTranslation('providerprofile', { useSuspense: false });

    const formik = useFormik({
        initialValues: {
            leaveMessage: phone.leaveMessage,
            phoneNumber: phone.phoneNumber,
            phoneType: phone.phoneType,
            preferredConfidentialLine: phone.preferredConfidentialLine,
            preferredLine: phone.preferredLine,
            providerPhoneId: phone.providerPhoneId,
            inEdit: false
        },
        onSubmit: (values) => {
            console.log(values);
        }
    });

    return !translationsReady
        ? <></>
        : <Card elevation={0} radius="small" className='contact_info_container'>
            {
                formik.values.inEdit
                ? <form onSubmit={formik.handleSubmit}></form>
                : <>
                    <p className='contact_info_contact'>{phone.phoneNumber}</p>
                    <p className='contact_info_contact_details'>
                        {
                            phone.phoneTypeString
                        }
                        {
                            phone.preferredLine &&
                                <React.Fragment>
                                    <span className="bullet"/>{t('common__preferred_flag')}
                                </React.Fragment>
                        }
                        {
                            phone.preferredConfidentialLine &&
                                <React.Fragment>
                                    <span className="bullet"/>{t('common__confidential_flag')}
                                </React.Fragment>
                        }
                        {
                            phone.leaveMessage &&
                                <React.Fragment>
                                    <span className="bullet"/>{t('common__leave_message_flag')}
                                </React.Fragment>
                        }
                    </p>
                </>
            }
        </Card>
}
import React, { FC } from 'react';
import { List, ListSubheader, ListItem } from '@mui/material';
import { DayHeader } from './DayHeader';
import './Schedule.scss';
import { AvailabilityItem, UnavailabilityItem, flattenScheduleItems,
    mapReservations, EventType } from 'components/Calendaring';
import { ScheduleEvent } from './interfaces';
import { EventCard } from './EventCard';
import * as _moment from 'moment';
import { Moment } from 'moment';
import { extendMoment } from 'moment-range';
import { useAuth } from 'components/Auth';
import { Spinner } from 'components/Shared';
import useTimezoneUtils from 'hooks/useTimezoneUtils';
import { useTranslation } from 'react-i18next';
import { Card } from 'components/Shared/Card';

const moment = extendMoment(_moment);

interface ScheduleProps {
    availabilities?: AvailabilityItem[],
    unavailabilities?: UnavailabilityItem[],
    loading: boolean
}

export const Schedule: FC<ScheduleProps> = (props) => {
    const { availabilities, unavailabilities, loading } = props;
    const { effectiveProviderData } = useAuth();
    const providerTimezone = effectiveProviderData?.timeZone ?? '';
    const { formatDate } = useTimezoneUtils();
    const { t } = useTranslation('activityDetails', { useSuspense: false });

    const datesRange = moment.range(moment().startOf('day'), moment().endOf('day').add(5, 'days'))

    const unavailabilityEvents = flattenScheduleItems(unavailabilities ?? [], datesRange, providerTimezone)
        .map<ScheduleEvent>((event) => ({
            type: EventType.Unavailability,
            ...event
        }));

    const reservations = mapReservations(availabilities ?? [], providerTimezone)
        .filter(x => x.start.isSameOrAfter(datesRange.start))
        .map((event) => ({
            type: EventType.Reservation,
            ...event
        }));

    const groupedEvents = [...unavailabilityEvents, ...reservations]
        .reduce<{[id: string]: {date: Moment, events: ScheduleEvent[]}}>((groups, event) => {
            const start = event.start.clone();
            const dateString = start.format('YYYY-MM-DD');

            if (groups[dateString]) {
                groups[dateString].events.push(event);
            } else {
                groups[dateString] = {
                    date: moment(dateString, 'YYYY-MM-DD'),
                    events: [event]
                }
            }

            return groups;
        }, {});

    const days = Object.keys(groupedEvents).map(date => ({
        key: date,
        date: groupedEvents[date].date,
        events: groupedEvents[date].events.sort((a, b) => a.start.valueOf() - b.start.valueOf())
    })).sort((a, b) => a.date.valueOf() - b.date.valueOf());

    return (
        <Card className="schedule_container" elevation={1}>
            {
                loading
                ? <Spinner />
                : days.length > 0 
                    ? <List className="schedule" disablePadding>
                        {
                            days.map((day) => (
                                <ListItem key={day.key} disableGutters style={{padding: 0}}>
                                    <List aria-label={formatDate(day.date, 'dddd, LL')} disablePadding style={{width: '100%'}}>
                                        <ListSubheader aria-hidden="true" disableGutters disableSticky>
                                            <DayHeader date={day.date}
                                                reservationsCount={day.events.filter(x => x.type === EventType.Reservation).length}
                                            />
                                        </ListSubheader>
                                        {
                                            day.events.map((event, index) => (
                                                <ListItem key={`${day.key}-${index}`}>
                                                    <EventCard event={event}/>
                                                </ListItem>
                                            ))
                                        }
                                    </List>
                                </ListItem>
                            ))
                        }
                    </List>
                    : <p className="no_appointments_text">{t('you_have_no_upcoming_appointments')}</p> 
            }
        </Card>
    )
}
import { useMutation } from '@apollo/client';
import { Grid, MenuItem } from '@mui/material';
import { SmsPhoneNumber } from 'components/ProviderProfile/interfaces';
import { Spinner } from 'components/Shared';
import { Select, Button } from 'components/Shared/Inputs';
import { useFormik } from 'formik';
import React, { FC, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { SectionContainer } from '../../Other/SectionContainer';
import { UPDATE_PHONE_NUMBER_FOR_SMS } from './queries';
import { confirm } from 'components/Shared/ConfirmDialog';

interface PhoneNumberForSmsProps {
    providerId: number,
    smsPhoneNumbers: SmsPhoneNumber[] | undefined,
    onUpdated: (values) => void
}

interface PhoneNumberForSmsFormValues {
    smsPhoneId: number | null
}

export const PhoneNumberForSms: FC<PhoneNumberForSmsProps> = (props) => {
    const { providerId, smsPhoneNumbers, onUpdated } = props;
    const { t, ready: translationsReady } = useTranslation('providerprofile', { useSuspense: false });
    const { t: t_common, ready: commonTranslationsReady } = useTranslation('common', { useSuspense: false });
    
    const [cachePendingUpdate, setCachePendingUpdate] = useState(false);

    const [inEdit, setInEdit] = useState(false);


    const phoneNumbers = useMemo(() =>
        smsPhoneNumbers?.map(phone => ({title: phone.phoneNumber, value: phone.phoneId, receiveSms: phone.receiveSms}))
    , [smsPhoneNumbers]);

    const smsPhoneNumber = useMemo(() =>
        phoneNumbers?.find(phone => phone.receiveSms)
    , [phoneNumbers]);

    useEffect(() => {
        setCachePendingUpdate(false);
        setInEdit(false);
        formik.resetForm({
            values: {
                smsPhoneId: smsPhoneNumber?.value ?? null
            }
        });
    }, [smsPhoneNumbers]);

    const [update, updateMutation] = useMutation(UPDATE_PHONE_NUMBER_FOR_SMS, {
        update: (_, result) => {
            const newSmsPhoneNumbers = result.data?.updateProviderDetails?.details?.smsPhoneNumbers;

            if (newSmsPhoneNumbers) {
                setCachePendingUpdate(true);
                onUpdated({ 
                    smsPhoneNumbers: newSmsPhoneNumbers
                });
            }
        }
    });

    const formik = useFormik<PhoneNumberForSmsFormValues>({
        initialValues: {
            smsPhoneId: smsPhoneNumber?.value ?? null,
        },
        onSubmit: async (values) => {
            const dialogProps = {
                message: <>
                    <p>{t('common__confirm_changes_dialog_text')}</p>
                    <ul>
                        {
                            hasChanges &&
                            <li>{t('phone_number_for_sms__phone_input')}: <b>{value?.title}</b></li>
                        }
                    </ul>
                </>
            }
            if (values.smsPhoneId && await confirm(dialogProps)) {
                update({
                    variables: {
                        providerId: providerId,
                        smsPhoneId: values.smsPhoneId
                    }
                });
            }
        }
    });

    const handleCancelChanges = async () => {
        if (hasChanges) {
            const dialogProps = {
                title: t('common__discard_changes_dialog_title'),
                message: t('common__discard_changes_dialog_text')
            }
            if (await confirm(dialogProps)) {
                formik.resetForm();
                setInEdit(false);
            }
        } else {
            formik.resetForm();
            setInEdit(false);
        }
    }

    const hasChanges = formik.values.smsPhoneId !== smsPhoneNumber?.value;

    const smsPhoneIdValue = formik.values.smsPhoneId ?? '';
    const value = useMemo(() =>
        phoneNumbers?.find(phone => phone.value === smsPhoneIdValue)
    , [phoneNumbers, smsPhoneIdValue]);

    
    const editorLoading = !commonTranslationsReady;
    const submitDisabled = !hasChanges || updateMutation.loading || cachePendingUpdate;

    return !translationsReady
        ? <></>
        : <SectionContainer title={t('phone_number_for_sms__section_title')} inEdit={inEdit} onEditClick={() => setInEdit(true)}>
            {
                !inEdit
                    ? <p>
                        {
                            smsPhoneNumber
                                ? t('phone_number_for_sms__section_description', { replace: { number: smsPhoneNumber.title}})
                                : t('phone_number_for_sms__editor_prompt')
                        }
                    </p>
                    : <>
                        {
                            editorLoading &&
                            <Spinner fillParent/>
                        }
                        {
                            (updateMutation.loading || cachePendingUpdate) &&
                            <Spinner fillParent backdrop/>
                        }
                        <Grid container component='form' onSubmit={formik.handleSubmit} spacing={3} style={{visibility: editorLoading ? 'hidden' : 'visible'}}>
                            <Grid item xs={12} component='p'>
                                {t('phone_number_for_sms__editor_prompt')}
                            </Grid>
                            <Grid item xs={12}>
                                <Select value={smsPhoneIdValue} label={t('phone_number_for_sms__phone_input')}
                                    name="smsPhoneId" onChange={formik.handleChange} error={Boolean(formik.errors.smsPhoneId)}
                                    className="provider_details_input">
                                    {
                                        phoneNumbers?.map(phone => (
                                            <MenuItem value={phone.value} key={phone.value}>{phone.title}</MenuItem>
                                        ))
                                    }
                                </Select>
                            </Grid>
                            <Grid item xs={12} container spacing={2}>
                                <Grid item>
                                    <Button type="submit" variant="opaque" disabled={submitDisabled}>{t_common('save')}</Button>
                                </Grid>
                                <Grid item>
                                    <Button type="button" variant="text" onClick={handleCancelChanges}>{t_common('cancel')}</Button>
                                </Grid>
                            </Grid>
                        </Grid>
                    </>
            }
    </SectionContainer>
}
import { ErrorOutline } from '@mui/icons-material'
import React from 'react';
import './Warning.scss'

export interface IWarningComponentInterface {
    className?: string
    children?: any;
}

export const WarningComponent = (props: IWarningComponentInterface) => (
    <div className={`warning-root${props.className ? (' ' + props.className) : ''}`}>
        <ErrorOutline />
        <div className="warning-message">
            {props.children}
        </div>
    </div>
)

export default WarningComponent


import React, { FC } from 'react';
import { LoginScreenLayout } from './LoginScreenLayout';
import { ForgotPasswordForm } from '../Forms/ForgotPasswordForm';
import { PageMetadata } from 'components/Shared/PageMetadata';
import { useTranslation } from 'react-i18next';

interface ForgotPasswordScreenProps { }

export const ForgotPasswordScreen: FC<ForgotPasswordScreenProps> = (_) => {
    const { t, ready } = useTranslation(['login'], { useSuspense: false });

    return (
        <React.Fragment>
            { ready && 
                <PageMetadata 
                    pageDescription={t('forgot_password_page_description')} 
                    pageTitle={t('forgot_password_page_title')}
                /> 
            }
            <LoginScreenLayout>
                <ForgotPasswordForm />
            </LoginScreenLayout>
        </React.Fragment>
    )
}
import React, { FC } from 'react';
import { Modal, Paper } from '@mui/material';
import { confirmable, ReactConfirmProps, createConfirmation } from 'react-confirm';
import { Unavailability } from '../../interfaces';
import { useTranslation } from 'react-i18next';
import useTimezoneUtils from 'hooks/useTimezoneUtils';

interface ConfirmDeleteProps {
    unavailability: Unavailability;
    series?: boolean;
}

const _ConfirmDeleteModal: FC<ConfirmDeleteProps & ReactConfirmProps> = (props) => {
    const { unavailability, series } = props;
    const { t } = useTranslation(['providerprofile', 'common'], { useSuspense: false });

    const { createDateFormatFunction, DATE_PORTION_MOMENT_FORMAT } = useTimezoneUtils();
    const formatDate = createDateFormatFunction(DATE_PORTION_MOMENT_FORMAT);

    const getDates = () => {
        if (props.unavailability.startDate?.getDate() === props.unavailability.endDate?.getDate()) {
            return (
                <span>
                    {' '}
                    on <b>{formatDate(props.unavailability.startDate as Date)}</b>
                </span>
            );
        } else {
            return (
                <span>
                    {' '}
                    {t('providerprofile:from')} <b>{formatDate(props.unavailability.startDate as Date)}</b>
                    {props.unavailability.endDate && (
                        <span>
                            {' '}
                            {t('providerprofile:to')} <b>{formatDate(props.unavailability.endDate as Date)}</b>
                        </span>
                    )}
                </span>
            );
        }
    };

    return (
        <Modal open={props.show} onClose={() => props.proceed('cancel')} className="series_modal_wrapper_div">
            <Paper className="series_modal_content_div">
                <p className="global__paragraph">
                    {unavailability.recurring ? (
                        series ? (
                            <span>
                                {t('providerprofile:are_you_sure_you_want_to_delete_unavailability_series')}{' '}
                                <b>{props.unavailability.reason?.description}</b>
                                {getDates()}?
                            </span>
                        ) : (
                            <span>
                                {t('providerprofile:are_you_sure_you_want_to_delete_instance_of_series_of_unavailability')}{' '}
                                <b>{props.unavailability.reason?.description}</b>
                                {getDates()}?
                            </span>
                        )
                    ) : (
                        <span>
                            {t('providerprofile:are_you_sure_you_want_do_delete_unavailability')}{' '}
                            <b>{props.unavailability.reason?.description}</b>
                            {getDates()}?
                        </span>
                    )}
                </p>
                <div className="series_modal_button_container">
                    <button className="global__button" onClick={() => props.proceed('delete')}>
                        {t('common:delete')}
                    </button>
                    <button className="global__button" onClick={() => props.proceed('cancel')}>
                        {t('common:cancel')}
                    </button>
                </div>
            </Paper>
        </Modal>
    );
};

export const ConfirmDeleteModal = confirmable(_ConfirmDeleteModal);
export const confirmDelete: (props: ConfirmDeleteProps) => Promise<string> = createConfirmation(ConfirmDeleteModal);

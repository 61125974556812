import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { ArrowBackIos, FilterList, ArrowForwardIos, ErrorOutline } from '@mui/icons-material';
import './style.scss'
import { IBookCalendarActivityData, ScheduleSlot } from 'components/Calendaring/AppointmentModals/interfaces';
import { Spinner } from 'components/Shared';
import { useTranslation } from 'react-i18next';
import useTimezoneUtils from 'hooks/useTimezoneUtils';
import * as Moment from 'moment-timezone';
import { extendMoment } from 'moment-range';
import { Button, IconButton } from '@mui/material';
import { WarningComponent } from 'components/Shared/Warning';
import { MODEL_18 } from 'utils/hourlyWorkflow';
import { ScheduleSlotsList } from './ScheduleSlotsList';
import { DatePreference, FilterOptions } from '../interfaces';
import { groupSlotsByDate } from '../utils';

const moment = extendMoment(Moment);

export interface IStep2ApptContentProps {
    clientHasNoMinimumHours: boolean | undefined,
    activity?: IBookCalendarActivityData,
    clientHasNoExistingSessions: boolean,
    scheduleSlots: ScheduleSlot[] | undefined,
    selectedScheduleSlot: ScheduleSlot | null,
    onSlotSelected: (scheduleSlot: ScheduleSlot | null) => void,
    onShowFiltersModalClick: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void,
    loadingSchedules?: boolean,
    displayedTimeZone: string,
    filterOptions: FilterOptions
}

export const Step2ApptContent: FC<IStep2ApptContentProps> = (props) => {
    const { onShowFiltersModalClick, clientHasNoExistingSessions, filterOptions,
        scheduleSlots, selectedScheduleSlot, onSlotSelected, displayedTimeZone } = props;
    const { t } = useTranslation('calendaring', { useSuspense: false });

    const { createDateFormatFunction, getTimezoneDiff } = useTimezoneUtils();

    const formatMonthHeader = useCallback(
        createDateFormatFunction('MMM YYYY')
    , [props.activity?.timezoneOlsonName]);

    const showTimezoneDiff = useCallback(
        () => {
            const timezoneDiff = getTimezoneDiff(props.activity?.timezoneOlsonName);
            return timezoneDiff ? (
                    timezoneDiff < 0 
                        ? t('you_are_x_hours_ahead', { hours: Math.abs(timezoneDiff) })
                        : t('you_are_x_hours_behind', { hours: Math.abs(timezoneDiff)})
                    ) : null;
        }
    , [props.activity?.timezoneOlsonName]);

    const [shownRange, setShownRange] = useState(moment.range(moment(), moment()));

    useEffect(() => {
        const start = moment.tz(filterOptions.startDate, 'YYYY-MM-DD', displayedTimeZone);
        switch (filterOptions.datePreference) {
            default:
            case DatePreference.on:
                const range = moment.range(
                    start.clone().startOf('day'),
                    start.clone().endOf('day')
                );
                setShownRange(range);
                break;
            case DatePreference.any:
            case DatePreference.bef:
            case DatePreference.aft: {
                const range = moment.range(
                    start.clone().startOf('month'),
                    start.clone().endOf('month')
                );
                setShownRange(range);
                break;
            }
        }
    }, [filterOptions.startDate, filterOptions.datePreference]);

    const filteredSlots = useMemo(() => (
        scheduleSlots?.filter(slot => shownRange.contains(slot.startDate))
    ), [scheduleSlots, shownRange]);

    const groupedSlots = useMemo(() => (
        groupSlotsByDate(filteredSlots, displayedTimeZone)
    ), [filteredSlots, displayedTimeZone]);


    const onClickNextArrow = () => {
        const newStart = shownRange.start.clone().add(1, 'month');
        setShownRange(moment.range(newStart, newStart.clone().endOf('month')));
    }
    
    const onClickPreviousArrow = () => {
        const newStart = shownRange.start.clone().subtract(1, 'month');
        setShownRange(moment.range(newStart, newStart.clone().endOf('month')));
    }

    const renderScheduleBodyContent = () => {
        if (props.clientHasNoExistingSessions) {
            return (
                <div className="no-sessions-warning">
                    <ErrorOutline/>
                    <div className="no-sessions-warning-message">
                        {t('no_session_warning')}
                    </div>
                </div>
            )
        }
        if (props.clientHasNoMinimumHours) {
            return (
                <WarningComponent className="step2-booking-hourly-warning">
                    <p>{t("book_appt_no_available_hours")} {props?.activity?.sessionInfo?.modelId === MODEL_18 && 
                        t("request_additional_hours")}</p>
                </WarningComponent>
            )
        }
        return <ScheduleSlotsList
                    groupedSlots={groupedSlots}
                    onSlotSelected={onSlotSelected}
                    selectedSlot={selectedScheduleSlot}
                    alternateTimezone={!!getTimezoneDiff(props.activity?.timezoneOlsonName) ? props.activity?.timezoneOlsonName : undefined}
                />
    }

    return !props.loadingSchedules
        ? (
            <div className="step2-appt-date">
                <div className="date-filter-row">
                    <div className="date">
                        <h2>
                            {formatMonthHeader(shownRange.start)}
                        </h2>
                        <IconButton size="small" disabled={shownRange.contains(moment())} onClick={onClickPreviousArrow}>
                            <ArrowBackIos className="arrow-back-icon"/>
                        </IconButton>
                        <IconButton size="small" onClick={onClickNextArrow}>
                            <ArrowForwardIos className="arrow-forward-icon"/>
                        </IconButton>
                    </div>
                    {
                        !clientHasNoExistingSessions &&
                        <Button onClick={onShowFiltersModalClick}>
                            <FilterList className="filter-icon"/>
                            {t('filter')}
                        </Button>
                    }
                </div>
                <p>{t('client_timezone')}: {props.activity?.timezoneOlsonName} <span style={{ float: 'right' }}>{showTimezoneDiff()}</span></p>
                {renderScheduleBodyContent()}
            </div>
        )
        : <Spinner/>
}
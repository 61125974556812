import moment from "moment";

/*The actual range of times supported by ECMAScript Date objects is 
exactly –100,000,000 days to 100,000,000 days measured relative to midnight at the beginning of 01 January, 1970 UTC. 
This gives a range of 8,640,000,000,000,000 milliseconds to either side of 01 January, 1970 UTC.
The code below adds the min/max date ranges for use, with single day subtracted to accomodate possible the time zone offset.
*/

export const tzSafeMaxTime = (Math.pow(10, 8) - 1) * 24 * 60 * 60 * 1000;
export const tzSafeMinTime = -tzSafeMaxTime;

export const tzSafeMaxDate = moment(tzSafeMaxTime);
export const tzSafeMinDate = moment(tzSafeMinTime);
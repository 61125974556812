import { useMemo } from 'react';
import { sortData, derivePaginationDetails, PaginationDetails } from 'components/Shared/MaterialTable';
import { InvoiceTableItem, InvoicingDataRequest } from '../interfaces';

export const useProcessInvoicesData = (data: InvoiceTableItem[] | undefined, dataRequest: InvoicingDataRequest)
    : [InvoiceTableItem[] | undefined, PaginationDetails] =>
{
    const parsedData = useMemo(() =>
        data?.filter(invoice => Boolean(invoice))?.map<InvoiceTableItem>(invoice => ({
            ...invoice,
            submittedDateUtc: invoice.submittedDateUtc ? new Date(invoice.submittedDateUtc) : null,
            caseAcceptedDateUtc: invoice.caseAcceptedDateUtc ? new Date(invoice.caseAcceptedDateUtc) : null,
        }))
    , [data]);

    const filteredData = useMemo(() => {
        let result = parsedData;
        if (dataRequest.filters.status !== -1) {
            result = result?.filter(invoice => invoice.statusId === dataRequest.filters.status);
        }
        if (dataRequest.filters.startDate) {
            result = result?.filter(invoice => invoice.submittedDateUtc
                && (new Date(invoice.submittedDateUtc) >= (dataRequest.filters.startDate as Date)))
        }
        if (dataRequest.filters.endDate) {
            result = result?.filter(invoice => invoice.submittedDateUtc
                && (new Date(invoice.submittedDateUtc) <= (dataRequest.filters.endDate as Date)))
        }
        if (dataRequest.search) {
            const searchString = dataRequest.search.toLowerCase().trim();
            result = result?.filter(invoice =>
                (invoice.providerInvoiceNumber?.toLowerCase().includes(searchString) ?? false)
                || (invoice.invoiceId?.toString().includes(searchString) ?? false)
                || (invoice.activityId?.toString().includes(searchString) ?? false)
                || (invoice.client?.toLowerCase().includes(searchString) ?? false)
                || (invoice.grandTotal?.toString().includes(searchString) ?? false)
            );
        }
        return result;
    }, [parsedData, dataRequest.search, dataRequest.filters]);

    
    const sortedData = useMemo(() =>
        sortData(filteredData, dataRequest.sorts)
    , [filteredData, dataRequest.sorts]);

    const pagedData = useMemo(() => 
        sortedData?.slice(dataRequest.skip, dataRequest.skip + dataRequest.take)
    , [sortedData, dataRequest.skip, dataRequest.take]);
    
    const paginationDetails = derivePaginationDetails(sortedData?.length, dataRequest);
    
    return [pagedData, paginationDetails];
}
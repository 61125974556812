import React, { FC } from 'react';

import './SessionsTable.scss';

import { Table, TableBody, TableCell, TableContainer, TableRow } from '@mui/material';
import useTimezoneUtils from 'hooks/useTimezoneUtils';

import { toCurrencyString } from 'utils';
import { useTranslation } from 'react-i18next';
import { InvoiceBillable } from '../../interfaces';
import { calculateBillableRate } from 'components/Invoicing/utils/billableUtils';
import { Card } from 'components/Shared/Card';
import { ColumnDefinition, MaterialTableHeader, MaterialTableRow } from 'components/Shared/MaterialTable';


interface SessionsTableProps {
    sessions?: Array<InvoiceBillable>;
}

export const SessionsTable: FC<SessionsTableProps> = (props) => {
    const { sessions } = props

    const { i18n, t } = useTranslation('Invoicing', { useSuspense: false })

    const { formatDate } = useTimezoneUtils()

    const formatInvoiceTime = (date: string | Date) => {
        return formatDate(date, 'LT')
    }

    const formatInvoiceDate = (date: string | Date) => {
        return formatDate(date, 'l')
    }

    const billablesSum = () => {
        const total = sessions?.reduce((acc, session) => acc + session.amount, 0)
        return toCurrencyString(total, i18n.language, sessions ? sessions[0] && sessions[0].currencyCode : null)
    }

    const columns: ColumnDefinition[] = [{
        title: t('date'),
        template: (session: InvoiceBillable) => (
            session.dateUtc ? formatInvoiceDate(new Date(session.dateUtc)) : '---'
        )
    }, {
        title: t('time'),
        template: (session: InvoiceBillable) => (
            session.dateUtc ? formatInvoiceTime(new Date(session.dateUtc)) : '---'
        )
    }, {
        title: t('duration'),
        template: (session: InvoiceBillable) => (
            session.hours ?? '---'
        )
    }, {
        title: t('rate'),
        template: (session: InvoiceBillable) => (
            calculateBillableRate(session, t, i18n)
        )
    }, {
        title: t('amount'),
        template: (session: InvoiceBillable) => (
            toCurrencyString(session.amount, i18n.language, session.currencyCode) ?? '---'
        )
    }];

    return (
        <div className="invoice_table_wrapper">
            <h3>{t('sessions')}</h3>
            <TableContainer className="table_container" component={Card} elevation={0}>
                <Table className="table">
                    <MaterialTableHeader columns={columns}/>
                    <TableBody className="table_body">
                        {
                            sessions?.map((session, index) => (
                                <MaterialTableRow columns={columns} data={session} key={index} index={index}/>
                            ))
                        }
                        <TableRow className="table_body_row">
                            <TableCell colSpan={columns.length - 1} className="table_body_cell total_label_cell">
                                {t('total')}
                            </TableCell>
                            <TableCell className="table_body_cell">
                                {billablesSum()}
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    )
}
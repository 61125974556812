import React, { FC } from 'react';
import { Table } from '@mui/material';
import { ISortsDefinition, ColumnDefinition } from './interfaces';
import clsx from 'clsx';
import { MaterialTableBody } from './MaterialTableBody';
import { MaterialTableHeader } from './MaterialTableHeader';

interface IMaterialTableProps {
    className?: string,
    columns: ColumnDefinition[],
    data: any[] | undefined,
    dataLoading?: boolean,
    sorts?: ISortsDefinition,
    emptyStateMessage?: string | JSX.Element,
    onSortsChange?: (sorts: ISortsDefinition) => void,
    collapsible?: boolean,
    collapsibleRowTemplate?: (data: any) => JSX.Element
}

export const MaterialTable: FC<IMaterialTableProps> = (props) => {
    const { className, ...other } = props;

    return (
        <Table className={clsx("table", className)}>
            <MaterialTableHeader {...other}/>
            <MaterialTableBody {...other}/>
        </Table>
    )
}

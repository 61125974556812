export const REPORT_WRITING_TASK_CODE = "RW";

export enum ReportType {
    InitialAssessment = "WRP_IA",
    Monthly = "WRP_MO",
    Closure = "WRP_CL"
}

export enum WSReportType {
    InitialAssessment = "WS_IA",
    Monthly = "WS_MO",
    Closure = "WS_CL"
}

export enum HMPReportType {
    InitialAssessment = "HMP_IA",
    Monthly = "HMP_MO",
    Closure = "HMP_CL"
}

export enum SAPReportType {
    InitialAssessment = "SAP_IA",
    Monthly = "SAP_MO",
    Closure = "SAP_CL"
}

export enum SRPPReportType {
    InitialAssessment = "SRPP_IA",
    Monthly = "SRPP_MO",
    Closure = "SRPP_CL"
}
import React from 'react';
import { ActivityTableItem } from '../interfaces';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { PROVIDER_CONNECT } from 'constants/targetedSystem';
import { ColumnDefinition } from 'components/Shared/MaterialTable';
import { Tooltip } from '@mui/material';
import ErrorIcon from '@mui/icons-material/ErrorOutline';

export const useActivitiesColumnsDefinitions = (): ColumnDefinition[] => {
    const { t } = useTranslation(['activities', 'casemanagement', 'activitydetails'], { useSuspense: false });

    const renderClientNameCell = (activity: ActivityTableItem) => (
        <div style={{display: 'flex'}}>
            <Link to={{pathname: `/clients/${activity.activityId}`}} style={{margin: 0}}>
                {activity.clientName}
                {
                    activity.targetedSystemCode === PROVIDER_CONNECT &&
                    <span className="visually_hidden">{t('client_name_cell_tooltip')}</span>
                }
            </Link>
            {
                activity.targetedSystemCode === PROVIDER_CONNECT &&
                <div aria-hidden>
                    <Tooltip title={t('client_name_cell_tooltip')?.toString() ?? ''}>
                        <ErrorIcon style={{marginLeft: '5px', width: '20px', height: '20px' }}/>
                    </Tooltip>
                </div>
            }
        </div>
    );

    return [
        {
            title: t('client_name'),
            field: 'clientName',
            sortable: true,
            template: renderClientNameCell
        }, {
            title: t('casemanagement:activity_id'),
            sortable: true,
            field: 'activityId'
        }, {
            title: t('company', 'Company'),
            sortable: true,
            field: 'organizationName'
        }, {
            title: t('activitydetails:presenting_issue'),
            sortable: true,
            field: 'presentingIssue'
        }
    ];
}
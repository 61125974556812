import { useAppConfig } from 'providers/AppConfig';
import { useRef, useState } from 'react';
import { RequestHook } from '..';

export const useLogoutRequest: RequestHook<void, void> = (props) => {
    const { REACT_APP_GRAPHQL_API_HOST } = useAppConfig();
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState<void | null>(null);
    const [error, setError] = useState<any | null>(null);
    let { current: promise } = useRef<Promise<void> | null>(null);

    const logout = () => {
        setLoading(true);      
        promise = (props?.fetch || fetch)(new URL("/api/Auth/Logout", REACT_APP_GRAPHQL_API_HOST).toString(), {
            method: 'POST',
            mode: 'cors',
            cache: 'no-cache',
            credentials: 'include',
            headers: props?.prepareHeaders?.()
        }).then(response => (
            response.json()
        )).then(result => {
            setData(result);
            setError(null);
            props?.onCompleted?.(result);
            return result;
        }).catch(error => {
            setData(null);
            setError(error);
            props?.onError?.(error);
            throw error;
        }).finally(() => {
            setLoading(false);
        });

        return promise;
    }


    return [
        logout,
        {
            loading: loading,
            data: data,
            error: error,
            promise: promise
        }
    ]
}
import { Dialog, DialogContent } from '@mui/material';
import React, { FC } from 'react';
import { ReactConfirmProps, confirmable, createConfirmation } from 'react-confirm';
import { Button } from '../Inputs/Buttons';
import CloseIcon from '@mui/icons-material/Close';
import { useTranslation } from 'react-i18next';
import './ConfirmDialog.scss';

interface ConfirmDialogProps {
    title?: string | JSX.Element,
    message: string | JSX.Element,
    confirmLabel?: string | JSX.Element,
    cancelLabel?: string | JSX.Element
}

const _ConfirmDialog: FC<ConfirmDialogProps & ReactConfirmProps> = (props) => {
    const { show, proceed, title, message, confirmLabel, cancelLabel } = props;

    const { t } = useTranslation('common');

    const handleCancel = () => {
        proceed(false as any);
    }

    const handleConfirm = () => {
        proceed(true as any);
    }

    return (
        <Dialog open={show} className="confirm_dialog">
            <DialogContent>
                <div className="confirm_dialog_header">
                    <h2 className="confirm_dialog_title">{title ?? t('confirm')}</h2>
                    <Button variant="text" round className="confirm_dialog_close_button" onClick={handleCancel}>
                        <CloseIcon/>
                    </Button>
                </div>
                <div className="confirm_dialog_content">
                    {message}
                </div>
                <div className="confirm_dialog_actions">
                    <Button variant='text' onClick={handleCancel} autoFocus>{cancelLabel ?? t('cancel')}</Button>
                    <Button variant='opaque' onClick={handleConfirm}>{confirmLabel ?? t('confirm')}</Button>
                </div>
            </DialogContent>
        </Dialog>
    )
}

export const ConfirmDialog = confirmable(_ConfirmDialog);
export const confirm: (props: ConfirmDialogProps) => Promise<boolean> = createConfirmation(ConfirmDialog) as any;
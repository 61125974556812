import React, { FC } from 'react';
import { Card } from 'components/Shared/Card';
import { Button } from 'components/Shared/Inputs';
import { Link } from 'react-router-dom';
import { ToDo } from '../../interfaces';
import { useTranslation } from 'react-i18next';
import { getToDoLink } from '../../utils';

import './ToDoCard.scss';

interface ToDoCardProps {
    toDo: ToDo
}

export const ToDoCard: FC<ToDoCardProps> = (props) => {
    const { toDo } = props;
    const { t, ready: translationsReady } = useTranslation('todos', { useSuspense: false });
    
    return (
        translationsReady
            ? <Card elevation={1} className="todo_card">
                <div className="todo_card_content">
                    <h3 id={`title_field_${toDo.id}`} className="card_title">
                        {t(`${toDo.step.toString()}-title`, { replace: { clientName: toDo?.activityInfo?.clientName }})}
                    </h3>
                    <div className="todo_card_actions_container">
                        <Button variant="opaque" component={Link} to={{ pathname: getToDoLink(toDo)}} state={{ workflowId: toDo.id, activity: toDo.activityInfo, fromToDo: true }}>
                            {t(`${toDo?.step?.toString()}-button`)}
                        </Button>
                    </div>
                </div>
            </Card>
            : <></>
    )
};
import { Button } from 'components/Shared/Inputs';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

interface AfterContactAttemptsSavedPromptProps {
    activityId: number
}

export const AfterContactAttemptsSavedPrompt: FC<AfterContactAttemptsSavedPromptProps> = (props) => {
    const { activityId } = props;
    const { t } = useTranslation('caseManagement', { useSuspense: false });

    return <>
        <p>
            {t('youve_logged_and_saved_one_or_more_attempts_to_reach_your_client')}
        </p>
        <Button variant="outline" component={Link} to={{pathname: '/todos'}} className="appt_status_dropdown">
            {t('back_to_todos')}
        </Button>
        <Button variant="outline" component={Link} to={{pathname: `/clients/${activityId}`}} className="appt_status_dropdown">
            {t('view_clients_profile')}
        </Button>
    </>
}
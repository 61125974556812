import { gql } from '@apollo/client';

export const SEND_TECH_SUPPORT_EMAIL_MUTATION = gql`
    mutation SEND_TECH_SUPPORT_EMAIL_MUTATION($name: String!, $email: String!, $description: String) {
        sendTechSupportEmail(name: $name, email: $email, description: $description)
    }
`

export interface ISendTechSupportEmailVars {
    email: string;
    name: string;
    description: string;
}
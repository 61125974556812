


import { useMutation } from "@apollo/client";
import { TERMINATE_TODO_MUTATION } from "./queries";

export const useTerminateTodo = () => {
    return useMutation(
        TERMINATE_TODO_MUTATION,
        {
            context: { skipGlobalErrorHandling: true },
            onError: (err) => {
                console.error(err)
            }
        }
    )
}
import { gql, TypedDocumentNode } from '@apollo/client';

export const COMMUNICATION_PREFERENCES_REF_DATA
    : TypedDocumentNode<CommunicationPreferencesRefDataData, CommunicationPreferencesRefDataVars> = gql`
    query PROVIDER_INFO_REF_DATA {
        refValues {
            preferredCommunicationMedia {
                title
                value
            }
        }
    }
`
interface CommunicationPreferencesRefDataData {
    refValues: {
        preferredCommunicationMedia: {
            title: string,
            value: string
        }[]
    }
}

interface CommunicationPreferencesRefDataVars { }

export const UPDATE_PROVIDER_COMMUNICATION_PREFERENCES
    : TypedDocumentNode<UpdateProviderCommunicationPreferencesData, UpdateProviderCommunicationPreferencesVars> = gql`
    mutation UPDATE_PROVIDER_COMMUNICATION_PREFERENCES($providerId: Long!, $preferredCommunication: Int) {
        updateProviderDetails(providerId: $providerId, preferredCommunication: $preferredCommunication) {
            providerId
            details {
                preferredCommunication
                preferredCommunicationString
            }
        }
    }
    
`

interface UpdateProviderCommunicationPreferencesData {
    updateProviderDetails: {
        details: {
            preferredCommunication: number,
            preferredCommunicationString: string,
        }
    }
}

interface UpdateProviderCommunicationPreferencesVars {
    providerId: number,
    preferredCommunication: number
}
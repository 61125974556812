import { gql } from '@apollo/client';


export const ACTIVITIES_QUERY = gql`
query ACTIVITIES_QUERY($providerId: Long!) {
    activities(providerId: $providerId) {
        client {
            firstName
            lastName
        }
        additionalParticipants {
            id 
            firstName,
            lastName,
            relationType,
            fullName,
            dateOfBirth
        }
        solutionCode
        activityId
        targetedSystem {
            systemCode
        }
        activityStatusCode
    }
}
`


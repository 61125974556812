import { SESSION_STATUS } from '../../constants';
import moment from 'moment';
import { IApptStatusActivityData } from '../interfaces';

export const GetSuggestedApptStatus = (appointmentDate: Date | string) => {
    const currentMoment = moment();
    const appointmentMoment = moment(appointmentDate);
    const appointmentDateMinus24Hours = appointmentMoment.clone().subtract(24, 'hours');
    
    const isGreaterThan24Hours = currentMoment.isBefore(appointmentDateMinus24Hours);
    if (isGreaterThan24Hours) {
        return SESSION_STATUS.CANCELLED_GT;
    }

    const isLessThan24Hours = currentMoment.isAfter(appointmentDateMinus24Hours) && currentMoment.isBefore(appointmentMoment);
    if (isLessThan24Hours) {
        return SESSION_STATUS.CANCELLED_LT;
    }

    return SESSION_STATUS.ATTENDED
}

export const GetTags = (isFirst : boolean, activity?: IApptStatusActivityData) => {
    const tags: string[] = [];
    
    if (isFirst) {
        tags.push("first_note");
    }

    if (activity?.wosOffWorkQuestionIndicator === false) {
        tags.push("wosOffWork_hidden");
    }

    return tags;
}
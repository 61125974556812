import React from 'react';

import { OpaqueButton } from './Opaque/OpaqueButton';
import { OutlineButton } from './Outline/OutlineButton';
import { TextButton } from './Text/TextButton';
import { InlineButton } from './Inline/InlineButton';
import { ButtonType } from './interfaces';
import { ButtonBase } from '@mui/material';
import clsx from 'clsx';

const Button: ButtonType = (props) => {
    const { variant, className, fullWidth, round, size = 'medium', children, startIcon, endIcon, ...other } = props;

    let buttonClass = 'opaque_button';
    
    switch (variant) {
        case 'outline': {
            buttonClass = 'outline_button';
            break;
        }
        case 'text': {
            buttonClass = 'text_button';
            break;
        }
        case 'inline': {
            buttonClass = 'inline_button';
            break;
        }
        case 'opaque':
        default: {
            buttonClass = 'opaque_button';
        }
    }

    const classes = [
        'ms-button',
        buttonClass,
        className,
        variant !== 'inline' && size,
        fullWidth && 'ms-button_fullwidth',
        round && 'ms-button_round',
        endIcon && 'has_end_icon',
        startIcon && 'has_start_icon'
    ]

    return (
        <ButtonBase className={clsx(...classes)} disableRipple focusRipple={false} {...other}>
            {
                startIcon &&
                <div className='start_icon_wrapper'>{startIcon}</div>
            }
            { children }
            {
                endIcon &&
                <div className='end_icon_wrapper'>{endIcon}</div>
            }
        </ButtonBase>
    )
}


export { Button, OpaqueButton, OutlineButton, TextButton, InlineButton };
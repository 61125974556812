import { SwipeableDrawer, List, ListItem, Divider, IconButton } from '@mui/material';
import { Link } from 'react-router-dom';
import React from 'react';
import './MobileDrawer.scss';
import { useTranslation } from 'react-i18next';
import { LanguageSwitcher } from 'components/Shared/LanguageSwitcher';
import { Button } from 'components/Shared/Inputs';
import AddIcon from '@mui/icons-material/AddRounded';

import clsx from 'clsx';
import CloseIcon from '@mui/icons-material/CloseRounded';
import { useNavigationUtils } from '../../hooks';
import { useAuth } from 'components/Auth';
import { useFeatureManager } from 'hooks/useFeatureManager';

interface MobileDrawerProps {
    isOpen: boolean,
    onClose: () => void,
    onOpen: () => void
}

export const MobileDrawer: React.FC<MobileDrawerProps> = (props) => {
    const { isOpen, onClose, onOpen } = props;
    const { authenticated, logout } = useAuth();
    const { t } = useTranslation('navigation', { useSuspense: false });
    
    const { pathRoot, showCalendarTab, showInvoicingTab, showStaffTab } = useNavigationUtils();
    
    const { isAdditionalHoursFeatureEnabled, isListReferralFeatureEnabled } = useFeatureManager();

    const renderCloseButton = () => (
        <IconButton aria-label="close_button" onClick={onClose}>
            <CloseIcon />
        </IconButton>
    )

    const renderLink = (id: string, path: string) => (
        <ListItem button className={clsx('navbar_item', path === pathRoot && 'active')} id={id}
            component={Link} to={path} onClick={onClose}
        >
            {t(id)}
        </ListItem>
    )

    const renderAddClientButton = () => (
        <ListItem button className={clsx('navbar_item', pathRoot === '/add-clients' && 'active')} id={'add-client'}
            component={Link} to={'/add-clients'} onClick={onClose}
        >
            <AddIcon/>
            {t('add-client')}
        </ListItem>
    )

    const handleLogout = () => {
        onClose();
        logout();
    }
    
    return (
        <SwipeableDrawer
            anchor='left'
            open={isOpen}
            onClose={onClose}
            onOpen={onOpen}
            className="mobile_drawer"
        >
            <div className="mobile_drawer_toolbar">
                {renderCloseButton()}
                <LanguageSwitcher component={Button} variant="text" />
            </div>

            <List component="nav" className="mobile_drawer_nav_list" disablePadding>
                {
                    authenticated &&
                    <>
                        {renderLink('dashboard', '/')}
                        {renderLink('to-dos', '/todos')}
                        {renderLink('clients', '/clients')}
                        {showStaffTab && renderLink('staff', '/providers')}
                        {showCalendarTab && renderLink('calendar', '/calendar')}
                        {showInvoicingTab && renderLink('invoicing', '/invoicing')}
                        {isAdditionalHoursFeatureEnabled() && renderLink('requests', '/requests')}
                        <Divider />
                        {
                            isListReferralFeatureEnabled() &&
                            <>
                                {renderAddClientButton()}
                            </>
                        }
                        <Divider />
                    </>
                }
                {renderLink('learn', '/learn')}
                {renderLink('faq', '/faq')}
                {renderLink('help', '/help')}
                {
                    authenticated &&
                    <ListItem className="navbar_item" button onClick={handleLogout}>
                        {t("logout")}
                    </ListItem>
                }
            </List>
        </SwipeableDrawer>
    )
}


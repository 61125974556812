import { DataRequest, PaginationDetails, ISortsDefinition } from '../interfaces';
import get from 'lodash.get'

export const sortData: <T>(data: T[] | undefined, sorts: ISortsDefinition) => T[] | undefined = (data, sorts) => {
    if (!data) {
        return undefined;
    }

    let sortedData = data.slice();
    if (sortedData.length > 0) {
        for (const field in sorts) {
            if (sorts[field]) {
                const dir = sorts[field];
                sortedData = (dir === 'asc')
                    ? sortedData.sort((itemA, itemB) => {
                        let A = get(itemA, field);
                        let B = get(itemB, field);
                        if (A instanceof Date) { A = new Date(A.getUTCFullYear(), A.getUTCMonth(), A.getUTCDate()) }
                        if (B instanceof Date) { B = new Date(B.getUTCFullYear(), B.getUTCMonth(), B.getUTCDate()) }
                        if (typeof A === 'string') { A = A.toLocaleLowerCase() }
                        if (typeof B === 'string') { B = B.toLocaleLowerCase() }
                        if (A === B) return 0;
                        return A >= B ? 1 : -1;
                    })
                    : sortedData.sort((itemA, itemB) => {
                        let A = get(itemA, field);
                        let B = get(itemB, field);
                        if (A instanceof Date) { A = new Date(A.getUTCFullYear(), A.getUTCMonth(), A.getUTCDate()) }
                        if (B instanceof Date) { B = new Date(B.getUTCFullYear(), B.getUTCMonth(), B.getUTCDate()) }
                        if (typeof A === 'string') { A = A.toLocaleLowerCase() }
                        if (typeof B === 'string') { B = B.toLocaleLowerCase() }
                        if (A === B) return 0;
                        return A < B ? 1 : -1;
                    })
            }
        }
    }
    
    return sortedData;
}

export const derivePaginationDetails = (total: number | undefined, dataRequest: DataRequest): PaginationDetails => {
    const pagesCount = Math.ceil((total || 1) / dataRequest.take);
    const currentPage = (dataRequest.skip / dataRequest.take) + 1;
    const currentRangeStart = dataRequest.skip + (total ? 1 : 0);
    const currentRangeEnd = dataRequest.skip + ((total || 0) < dataRequest.take ? (total || 0) : dataRequest.take);

    return {
        pagesCount,
        currentPage,
        currentRangeStart,
        currentRangeEnd,
        total
    }
}
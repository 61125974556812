
import React, { FC, SetStateAction, Dispatch } from 'react';
import { useTranslation } from 'react-i18next';
import { MaterialTable, DataRequest, Paginator, ISortsDefinition } from 'components/Shared/MaterialTable';
import { OverHoursApprovalDTO } from '../../interfaces';
import { useRequestDataFiltering } from '../../hooks/useRequestDataFiltering';
import { useRequestsColumnsDefinitions } from '../../hooks/useRequestsColumnsDefinitions';
import useTimezoneUtils from 'hooks/useTimezoneUtils';
import { TableContainer } from '@mui/material';
import { Card } from 'components/Shared/Card';
import { FullScreenMessage } from 'components/Shared/FullScreenMessage';

export interface RequestsTableProps {
    showActivityInfo: boolean,
    dataLoading: boolean,
    dataRequest: DataRequest,
    setDataRequest: Dispatch<SetStateAction<DataRequest>>,
    data: OverHoursApprovalDTO[] | undefined
}

export const RequestsTable: FC<RequestsTableProps> = (props) => {
    const { dataLoading, dataRequest, setDataRequest, data, showActivityInfo } = props;

    const { t } = useTranslation(['requests', 'common'], { useSuspense: false });
    const { createDateFormatFunction, DATE_PORTION_MOMENT_FORMAT } = useTimezoneUtils();
    const formatDate = createDateFormatFunction(DATE_PORTION_MOMENT_FORMAT);

    const columns = useRequestsColumnsDefinitions(showActivityInfo);

    const handlePageChange = (currentPage: number) => {
        setDataRequest({...dataRequest, skip: (currentPage - 1) * dataRequest.take});
    }

    const [filteredData, paginationDetails] = useRequestDataFiltering(data, dataRequest)

    const handleSortsChange = (sorts: ISortsDefinition) => {
        setDataRequest({...dataRequest, sorts: sorts});
    }

    const emptyStateMessage = <FullScreenMessage title={t('requests_table_no_rows')} />

    const collapsibleRowTemplate = (row: OverHoursApprovalDTO) => (
        <div>
            {
                row.request?.requestedDate &&
                <p><b>{t('date_requested')}: </b>{formatDate(row.request.requestedDate)}</p>
            }
            {
                row.request?.requestedHours &&
                <p><b>{t('hours_requested')}: </b>{row.request.requestedHours}</p>
            }
            <p><b>{t('date_approved')}: </b>{formatDate(row.approvedDate)}</p>
            <p><b>{t('approved_hours')}: </b>{row.approvedHours}</p>
            <p><b>{t('reason')}: </b>{row.request?.reason}</p>
        </div>
    )

    return (
        <>
            <TableContainer component={Card} elevation={1}>
                <MaterialTable
                    columns={columns}
                    sorts={dataRequest.sorts}
                    onSortsChange={handleSortsChange}
                    data={filteredData}
                    dataLoading={dataLoading}
                    emptyStateMessage={emptyStateMessage}
                    collapsible={true}
                    collapsibleRowTemplate={collapsibleRowTemplate}
                />
            </TableContainer>
            <Paginator paginationDetails={paginationDetails} onPageChange={handlePageChange}/>
        </>
    )
}
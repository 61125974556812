import { gql, TypedDocumentNode } from '@apollo/client';
import { SmsPhoneNumber } from '../../../interfaces';

export const UPDATE_PHONE_NUMBER_FOR_SMS
    : TypedDocumentNode<UpdatePhoneNumberForSmsData, UpdatePhoneNumberForSmsVars> = gql`
    mutation UPDATE_PROVIDER_COMMUNICATION_PREFERENCES($providerId: Long!, $smsPhoneId: Long) {
        updateProviderDetails(providerId: $providerId, smsPhoneId: $smsPhoneId) {
            providerId
            details {
                smsPhoneNumbers {
                    phoneId
                    phoneNumber
                    receiveSms
                }
            }
        }
    }
`

interface UpdatePhoneNumberForSmsData {
    updateProviderDetails: {
        details: {
            smsPhoneNumbers: SmsPhoneNumber[]
        }
    }
}

interface UpdatePhoneNumberForSmsVars {
    providerId: number,
    smsPhoneId: number
}
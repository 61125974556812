import React, { FC, useEffect, useMemo, useState } from 'react';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import { Grid, MenuItem, Alert } from '@mui/material';
import { Select, Button } from 'components/Shared/Inputs';
import { useLazyQuery, useMutation } from '@apollo/client';
import { SectionContainer } from '../../Other/SectionContainer'
import { COMMUNICATION_PREFERENCES_REF_DATA, UPDATE_PROVIDER_COMMUNICATION_PREFERENCES } from './queries';
import { Spinner } from 'components/Shared';
import { confirm } from 'components/Shared/ConfirmDialog';
import { SmsPhoneNumber } from 'components/ProviderProfile/interfaces';
import { EMAIL_COMMUNICATION_ID, SMS_COMMUNICATION_ID } from '../../../constants';

interface PracticeCommunicationPreferencesProps {
    providerId: number,
    allowSmsCommunication: boolean | undefined,
    allowEmailCommunication: boolean | undefined,
    emailAddress: string | undefined,
    smsPhoneNumber: SmsPhoneNumber | undefined,
    preferredCommunicationForNonConnected?: number,
    preferredCommunicationForNonConnectedString?: string,
    onUpdated: (values) => void
}

interface PracticeCommunicationPreferencesFormValues {
    preferredCommunication: number | null
}

export const PracticeCommunicationPreferences: FC<PracticeCommunicationPreferencesProps> = (props) => {
    const { providerId, preferredCommunicationForNonConnected, preferredCommunicationForNonConnectedString, onUpdated,
        allowSmsCommunication, allowEmailCommunication, smsPhoneNumber, emailAddress } = props;
    
    const { t, ready: translationsReady } = useTranslation('providerprofile', { useSuspense: false });
    const { t: t_common, ready: commonTranslationsReady } = useTranslation('common', { useSuspense: false });

    const [cachePendingUpdate, setCachePendingUpdate] = useState(false);

    const [getRefData, refDataQuery] = useLazyQuery(COMMUNICATION_PREFERENCES_REF_DATA);

    const [inEdit, setInEdit] = useState(false);

    useEffect(() => {
        setCachePendingUpdate(false);
        setInEdit(false);
        formik.resetForm({
            values: {
                preferredCommunication: preferredCommunicationForNonConnected ?? null
            }
        });
    }, [preferredCommunicationForNonConnected]);

    useEffect(() => {
        if (inEdit && !refDataQuery.called) {
            getRefData();
        }
    }, [inEdit]);

    const [update, updateMutation] = useMutation(UPDATE_PROVIDER_COMMUNICATION_PREFERENCES, {
        update: (_, result) => {
            const newPreferredCommunicationForNonConnected = result.data?.updateProviderDetails?.details?.preferredCommunicationForNonConnected;
            const newPreferredCommunicationForNonConnectedString = result.data?.updateProviderDetails?.details?.preferredCommunicationForNonConnectedString;

            if (newPreferredCommunicationForNonConnected && newPreferredCommunicationForNonConnectedString) {
                setCachePendingUpdate(true);
                onUpdated({ 
                    preferredCommunicationForNonConnected: newPreferredCommunicationForNonConnected,
                    preferredCommunicationForNonConnectedString: newPreferredCommunicationForNonConnectedString
                });
            }
        }
    });

    const formik = useFormik<PracticeCommunicationPreferencesFormValues>({
        initialValues: {
            preferredCommunication: preferredCommunicationForNonConnected ?? null
        },
        onSubmit: async (values) => {
            const dialogProps = {
                message: <>
                    <p>{t('common__confirm_changes_dialog_text')}</p>
                    <ul>
                        {
                            hasChanges &&
                            <li>{t('practice_communication_preferences__input_label')}: <b>{value?.title}</b></li>
                        }
                    </ul>
                </>
            }
            if (values.preferredCommunication && await confirm(dialogProps)) {
                update({
                    variables: {
                        providerId: providerId,
                        preferredCommunicationForNonConnected: values.preferredCommunication
                    }
                });
            }
        }
    });

    const handleCancelChanges = async () => {
        if (hasChanges) {
            const dialogProps = {
                title: t('common__discard_changes_dialog_title'),
                message: t('common__discard_changes_dialog_text')
            }
            if (await confirm(dialogProps)) {
                formik.resetForm();
                setInEdit(false);
            }
        } else {
            formik.resetForm();
            setInEdit(false);
        }
    }

    const hasChanges = formik.values.preferredCommunication !== preferredCommunicationForNonConnected;

    const options = useMemo(() => 
        refDataQuery.data?.refValues?.nonConnectedCommunicationMedia
            ?.map(media => ({title: media.title, value: Number(media.value)}))
            ?.filter(media => {
                if (media.value === SMS_COMMUNICATION_ID && !allowSmsCommunication) {
                    return false
                }
                if (media.value === EMAIL_COMMUNICATION_ID && !allowEmailCommunication) {
                    return false;
                }
                return true;
            })
    , [refDataQuery.data?.refValues?.nonConnectedCommunicationMedia]);
        
    const value = options?.find(media => media.value === formik.values.preferredCommunication);
    
    const editorLoading = refDataQuery.loading || !commonTranslationsReady;

    const submitDisabled = !hasChanges || updateMutation.loading || cachePendingUpdate;

    return !translationsReady
        ? <></>
        : <SectionContainer title={t('practice_communication_preferences__section_title')} inEdit={inEdit} onEditClick={() => setInEdit(true)}>
            {
                !inEdit
                    ? <>
                        <p>{t('practice_communication_preferences__section_description')}<b>{preferredCommunicationForNonConnectedString}</b>.</p>
                        {
                            preferredCommunicationForNonConnected === SMS_COMMUNICATION_ID && (
                                smsPhoneNumber
                                    ? <p>{t('practice_communication_preferences__number_for_sms_communication_message', { replace: { number: smsPhoneNumber.phoneNumber }})}</p>
                                    : <Alert severity="warning">
                                        {t('practice_communication_preferences__select_number_for_sms_communication_prompt')}
                                    </Alert>
                            )
                        }
                        {
                            preferredCommunicationForNonConnected === EMAIL_COMMUNICATION_ID && emailAddress &&
                            <p>{t('practice_communication_preferences__email_communication_message', { replace: { email: emailAddress }})}</p>
                        }
                    </>
                    : <>
                        {
                            editorLoading &&
                            <Spinner fillParent/>
                        }
                        {
                            (updateMutation.loading || cachePendingUpdate) &&
                            <Spinner fillParent backdrop/>
                        }
                        <Grid container component='form' onSubmit={formik.handleSubmit} spacing={3} style={{visibility: editorLoading ? 'hidden' : 'visible'}}>
                            <Grid item xs={12} component='p'>
                                {t('practice_communication_preferences__section_description')}
                            </Grid>
                            <Grid item xs={12}>
                                <Select value={formik.values.preferredCommunication ?? ''} label={t('practice_communication_preferences__input_label')}
                                    name="preferredCommunication" onChange={formik.handleChange}
                                    className="provider_details_input">
                                    {
                                        options?.map(media => (
                                            <MenuItem value={media.value} key={media.value}>{media.title}</MenuItem>
                                        ))
                                    }
                                </Select>
                            </Grid>
                            {
                                formik.values.preferredCommunication === SMS_COMMUNICATION_ID &&
                                <Grid item xs={12}>
                                    {
                                        smsPhoneNumber
                                            ? <Alert severity="info">
                                                {t('practice_communication_preferences__number_for_sms_communication_message', { replace: { number: smsPhoneNumber.phoneNumber }})}
                                            </Alert>
                                            : <Alert severity="warning">
                                                {t('practice_communication_preferences__select_number_for_sms_communication_prompt')}
                                            </Alert>
                                    }
                                </Grid>
                            }
                            {
                                formik.values.preferredCommunication === EMAIL_COMMUNICATION_ID && emailAddress &&
                                <Grid item xs={12}>
                                    <Alert severity="info">
                                        {t('practice_communication_preferences__email_communication_message', { replace: { email: emailAddress }})}
                                    </Alert>
                                </Grid>
                            }
                            <Grid item xs={12} container spacing={2}>
                                <Grid item>
                                    <Button type="submit" variant="opaque" disabled={submitDisabled}>{t_common('save')}</Button>
                                </Grid>
                                <Grid item>
                                    <Button type="button" variant="text" onClick={handleCancelChanges}>{t_common('cancel')}</Button>
                                </Grid>
                            </Grid>
                        </Grid>
                    </>
            }
    </SectionContainer>
}
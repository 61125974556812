import React, { FC, PropsWithChildren, useState } from 'react';
import { TermsOfUse } from 'components/Shared';
import { useTranslation } from 'react-i18next';
import { useRegionConfig } from 'providers/RegionConfig';
import { LanguageSwitcher } from 'components/Shared/LanguageSwitcher';
import { Container } from '@mui/material';
import { Card } from 'components/Shared/Card';
import { Button } from 'components/Shared/Inputs';

export const LoginScreenLayout: FC<PropsWithChildren> = ({children}) => {
    const { t } = useTranslation(['login', 'common', 'helpCenter'], { useSuspense: false });
    const { languages, helpUrls } = useRegionConfig();
    const currentLanguage = localStorage.getItem('i18nextLng');
    
    const [openTermsOfUse, setOpenTermsOfUse] = useState(false);

    const handleClickOpen = () => {
        setOpenTermsOfUse(true);
    };

    const handleClose = () => {
        setOpenTermsOfUse(false);
    };

    const getHelpSupportSection = () => {
        const redirectUrl = currentLanguage === "fr-CA" ? helpUrls.fr : helpUrls.en;
        if (redirectUrl && redirectUrl.url) {
            return (
                <p className="login_screen_help_message">
                    <a href={redirectUrl.url} target="_blank" rel="noreferrer">{t('login:contact_us', 'Contact us')}</a><br />
                </p>
            )
        }
        else {
            return (
                <p className="login_screen_help_message">{t('login:for_help_please_email', 'For help, please email')}<br />
                    <a href={`mailto: ${t('helpCenter:help_email', 'CRHelpdesk@telushealth.com')}`}>{t('helpCenter:help_email', 'CRHelpdesk@telushealth.com')}</a>.
                </p>)
        }
    }

    const renderLanguageButton = () => (
        languages.length > 1 &&
        <>
            <LanguageSwitcher component={Button} variant="inline" className="link" />
            <div className="bullet"/>
        </>
    )

    return (
        <div className="login_screen_wrapper global__fixed_background_image">
            <Container maxWidth="sm" className="login_screen_container transluscent_bg">
                <div className="global__flex_filler" />
                <div className="login_screen_content">
                    <h1>{t('login:the_provider_hub','The Provider Hub')}</h1>
                    <Card elevation={1} className="login_screen_form_container">
                        {children}
                    </Card>
                    {getHelpSupportSection()}
                    <div className="login_screen_help_separator"/>
                    <div className="login_screen_links_wrapper">
                        {renderLanguageButton()}
                        <Button variant="inline" className="link" component="a" href={t('login:privacy_policy_link', 'https://www.morneaushepell.com/ca-en/privacy-policy')} target="_blank" rel="noopener noreferrer">
                            {t('login:privacy_policy', 'Privacy policy')}
                        </Button>
                        <div className="bullet"/>
                        <Button variant="inline" className="link" onClick={handleClickOpen}>
                            {t('login:terms_of_use', 'Terms of Use')}
                        </Button>
                        {
                            openTermsOfUse &&
                            <TermsOfUse open={openTermsOfUse} handleClose={handleClose} handleClickOpen={handleClickOpen}/>
                        }
                    </div>
                    <div className="filler" />
                </div>
                <div className="global__flex_filler" />
                <p className="login_screen_help_message" dangerouslySetInnerHTML={ { __html: t('login:all_rights_reserved', '© 2020. Morneau Shepell & Lifeworks, All Rights Reserved. ')}} />
            </Container>
        </div>
    )
}
import React, { FC, FormEvent } from 'react';
import { Box, Divider } from '@mui/material';
import { useSnackbar } from 'notistack';
import './AddClient.scss';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useMutation, useLazyQuery, LazyQueryResult } from '@apollo/client';
import { ADD_CLIENT_MUTATION, GET_REFERRAL_DATA, IAddClientMutationResult, IReferralData } from './queries';
import { Spinner, CaseManagementActionContainer } from 'components/Shared';
import { useBulkyState } from 'hooks/useBulkyState';
import { PageMetadata } from 'components/Shared/PageMetadata';
import { TEXT_FIELD_ID, AddClientErrorTypes } from './constants';
import { ListReferralStatus } from 'constants/listReferralStatus';
import moment from 'moment';
import { Button, TextBox } from 'components/Shared/Inputs';

interface AddClientProps { }

export interface IAddClientState {
    token: string,
    showLoadingSpinner: boolean,
    hasValidationError: boolean
    tokenHelperText: string
}

export const AddClient: FC<AddClientProps> = () => {
    const { t, ready } = useTranslation('clients', { useSuspense: false });
    const { enqueueSnackbar } = useSnackbar();

    const [state,  setState] = useBulkyState<IAddClientState>({
        token: '',
        showLoadingSpinner: false,
        hasValidationError: false,
        tokenHelperText: '',
    })
    
    const navigate = useNavigate();

    const handleTokenChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setState({
            token: event.target.value.replace(/[^a-zA-Z0-9]/g,'')
        });
    };

    const [addClient, addClientMutation] = useMutation(ADD_CLIENT_MUTATION, {
        context: { skipGlobalErrorHandling: true },
    });

    const [queryListReferralData, listReferralDataQuery] = useLazyQuery(GET_REFERRAL_DATA, {
        fetchPolicy: 'network-only',
        errorPolicy: 'all',
        context: { skipGlobalErrorHandling: true }
    })

    const isValidToken = () => state.token.length > 0

    const validateToken = () => {
        return isValidToken() ? Promise.resolve(true) : Promise.reject(new Error(AddClientErrorTypes.VALIDATION_ERROR))
    }

    const checkReferralStatus = (referralData: LazyQueryResult<IReferralData, any>) => {

        if (referralData?.error || !referralData.data?.referralList) {           
            return Promise.reject(new Error(AddClientErrorTypes.TOKEN_NOT_VALID))
        }

        const { acceptedProviderId, status, expiryDateUtc } = referralData.data.referralList;

        if (Boolean(acceptedProviderId) || status === ListReferralStatus.Accepted) {
            return Promise.reject(new Error(AddClientErrorTypes.REFERRAL_ALREADY_ACCEPTED))
        }

        if (status !== ListReferralStatus.Active) return Promise.reject(new Error(AddClientErrorTypes.REFERRAL_VOID))

        if (moment(expiryDateUtc).isBefore(moment())) return Promise.reject(new Error(AddClientErrorTypes.REFERRAL_EXPIRED))

        return Promise.resolve()
    }

    const handleSubmitError = (err: Error): any => {
        if (err) {
            switch(err.message) {
                case AddClientErrorTypes.VALIDATION_ERROR:
                    return setStateWithPromise({ hasValidationError: true, tokenHelperText: t('enter_non_empty_value') })
                case AddClientErrorTypes.TOKEN_NOT_VALID:
                    enqueueSnackbar(t('token_not_found'), {variant: 'error'});
                    return setStateWithPromise({ hasValidationError: true, tokenHelperText: '' })
                case AddClientErrorTypes.REFERRAL_ALREADY_ACCEPTED: 
                    return enqueueSnackbar(t('referral_already_accepted'), {variant: 'error'});
                case AddClientErrorTypes.REFERRAL_VOID: 
                    return enqueueSnackbar(t('referral_void'), {variant: 'error'});
                case AddClientErrorTypes.REFERRAL_EXPIRED:
                    return enqueueSnackbar(t('referral_expired'), {variant: 'error'});
                default:
                    return enqueueSnackbar(t('failed_adding_client'), {variant: 'error'});
            }
        }
    }

    const setStateWithPromise = (values: Partial<IAddClientState>): Promise<boolean> => {
        return new Promise((resolve) => {
            setState(values)
            resolve(true)
        })
    }

    const routeToClientPage = (data?: IAddClientMutationResult | null) => {
        const activityId = data?.addClient?.activityId;
        enqueueSnackbar(t('successfully_added_client'), {variant: 'success'});
        navigate('/clients/' + activityId)
    }

    const formSubmit = (event: FormEvent) => {
        event.preventDefault();

        return validateToken()
            .then(() => setStateWithPromise({ hasValidationError: false, showLoadingSpinner: true, tokenHelperText: '' }))
            .then(() => queryListReferralData({variables: { clientToken: state.token}}))
            .then((result) => checkReferralStatus(result))
            .then(() => addClient({ variables: { clientToken: state.token }}))
            .then((mutationResult) => routeToClientPage(mutationResult?.data))
            .catch((err) => handleSubmitError(err))
            .finally(() => setStateWithPromise({ showLoadingSpinner: false }))
    }

    const renderBottomSection = () => (
        <div className="invalid-token-info">
            <h3>{t('invalid_or_expired_token')}?</h3>
            <p className="invalid_expired_token_description">
                {t('if_you_need_more_help_please_email_us_at') + ' '} 
                <a href={`mailto:${t('morneau_help_email')}`}>{t('morneau_help_email')}</a>, {t('using_token_reactivation')} 
            </p>
            <p>
                {t('provide_referral_data')}
            </p>
        </div>
    )

    const renderDivider = () => (
        <Box py={2}>
            <Divider/>
        </Box>
    )


    const renderTextFieldSection = () => (
        <>
            <label htmlFor={TEXT_FIELD_ID} className="token_input_box_label">
                {t('your_client_gives_you_the_token_id_when_you_accept_and_schedule_an_appointment_with_them')}.
            </label>
            <TextBox 
                id={TEXT_FIELD_ID} 
                label={t('token_id')}
                value={state.token} 
                onChange={handleTokenChange}
                className="token_input_box"
                error={state.hasValidationError}
                helperText={state.tokenHelperText}
            />
            <p className="token_input_box_note">{t('the_token_id_must_contain_8_characters')}</p>
            <Button variant="opaque" type="submit" disabled={state.showLoadingSpinner}>{t('submit')}</Button>
        </>
    )

    const loading = listReferralDataQuery.loading || addClientMutation.loading;

    return (
        <CaseManagementActionContainer onBackClick={() => navigate('/')}
            activityId={false} className='add_client_container'
        >
            {
                ready && 
                <PageMetadata
                    pageDescription={t('add_client_page_description')}
                    pageTitle={t('add_client_page_title')}
                />
            }
            {
                loading
                    ? <Spinner fillParent />
                    : <form noValidate autoComplete="off" onSubmit={formSubmit} className="add_client_form">
                        <h2>{t('adding_a_new_client')}</h2>
                        <div className="add_client_form_content_div">
                            {renderTextFieldSection()}
                            {renderDivider()}
                            {renderBottomSection()}
                        </div>
                    </form>
            }
        </CaseManagementActionContainer>
    )
}
import React, { FC, useEffect, useState } from 'react';
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import { useRegionConfig } from 'providers/RegionConfig';

export const TranslationsProvider: FC<any> = (props) => {
    const { defaultLng, languages } = useRegionConfig();
    const [initPending, setInitPending] = useState(true);

    const setLangAttr = (lang: string) => {
        document.documentElement.setAttribute('lang', lang);
    }

    useEffect(() => {
        setInitPending(true);
        const currentLanguage = localStorage.getItem('i18nextLng');

        if (!currentLanguage || !languages.find(language => language.code === currentLanguage)) {
            localStorage.setItem('i18nextLng', defaultLng);
        }
    
        const fallbackLng = {};
        const supportedLngs: string[] = [];
    
        for (const language of languages) {
            if (!supportedLngs.includes(language.code)) {
                supportedLngs.push(language.code);
            }
            for (const lng of language.fallback) {
                if (!supportedLngs.includes(lng)) {
                    supportedLngs.push(lng);
                }
            }

            fallbackLng[language.code] = language.fallback;
        }
    
        i18n
            .use(Backend)
            .use(LanguageDetector)
            .use(initReactI18next)
            .init({
                debug: false,
                supportedLngs: supportedLngs,
                fallbackLng: fallbackLng,
                ns: ['navigation', 'common'],
                interpolation: { escapeValue: false },
            }).then((_) => {
                setLangAttr(i18n.language);
                setInitPending(false);
            });

    }, [defaultLng, languages]);

    useEffect(() => {
        i18n.on('languageChanged', setLangAttr);
    }, [i18n]);

    return <>{!initPending && props.children}</>
}

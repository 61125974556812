import React from 'react'
import { Dialog, AppBar, Toolbar, IconButton, Slide } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { Logo }  from '../../Shared';
import { TransitionProps } from '@mui/material/transitions';
import { Trans, useTranslation } from 'react-i18next';
import './TermsOfUse.scss';

interface TermsOfUseProps {
    open: boolean,
    handleClose: () => void,
    handleClickOpen: () => void,
}

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & { children: React.ReactElement<any, any> },
    ref: React.Ref<unknown>,
  ) {
    return <Slide direction="up" ref={ref} {...props}/>
  });

export const TermsOfUse: React.FC<TermsOfUseProps> = (props) => {
    const { t } = useTranslation('termsofuse', { useSuspense: false });
    
    
    return (
        <Dialog fullScreen className="global_terms_of_use_wrapper" open={props.open} onClose={props.handleClose} TransitionComponent={Transition}>
            <AppBar className="terms_of_use_header_container">
                <Toolbar className="terms_of_use_toolbar">
                    <Logo className="logo_component"/>
                    <IconButton className="close_button" edge="end" onClick={props.handleClose} aria-label="close">
                        <CloseIcon />
                    </IconButton>
                </Toolbar>
            </AppBar>
            <section className="terms_of_use_body_container">
                <h2 className="terms_of_use_title global__header1" id="terms_of_use_title_id">{t('TermsOfUse_title')}</h2>
                <div className="terms_of_use_content_wrapper">
                    <Trans i18nKey="TermsOfUse_content">
                        {t('TermsOfUse_content')}
                    </Trans>
                </div>
            </section>
        </Dialog>
    )
}

import React, { FC, useState, useEffect, useMemo } from "react";
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import { License, LicenseInput } from '../../../interfaces';
import { Grid, MenuItem } from "@mui/material";
import { Button, DateInput, Select, TextBox } from 'components/Shared/Inputs';
import { Spinner } from "components/Shared";
import { MutationTuple, useLazyQuery } from "@apollo/client";
import { LICENSE_REF_DATA, UpdateLicensesMutationData, UpdateLicensesMutationVars } from "./queries";
import moment from "moment";
import { confirm } from 'components/Shared/ConfirmDialog';
import AddIcon from '@mui/icons-material/Add';
import { SectionContainer } from '../../Other/SectionContainer';
import { DATE_FORMATS, useTimezoneUtils } from "hooks/useTimezoneUtils";

const mapLicenseToLicenseInput = (license: License): LicenseInput => ({
    countryCode: license.countryCode,
    expiryDate: license.expiryDate,
    licenseType: license.licenseType,
    providerLicenseId: license.providerLicenseId,
    province: license.province,
    registrationNo: license.registrationNo,
    licenseTypeDescr: license.licenseTypeDescr
})


interface LicenseAndRegistrationFormProps {
    providerId: number,
    licenses?: License[],
    license?: License,
    cachePendingUpdate: boolean,
    mutation: MutationTuple<UpdateLicensesMutationData, UpdateLicensesMutationVars>
}

interface LicenseAndRegistrationFormValues {
    providerLicenseId: number | null,
    registrationNo: string,
    countryCode: string | null,
    province: number | null,
    licenseType: number | null
    expiryDate: string
}

export const LicenseAndRegistrationsForm: FC<LicenseAndRegistrationFormProps> = (props) => {
    const { providerId, licenses, license, mutation, cachePendingUpdate } = props;

    const { t } = useTranslation('providerprofile', { useSuspense: false });
    const { t: t_common } = useTranslation('common', { useSuspense: false });

    const { localeDateFormat } = useTimezoneUtils();

    const [requestPending, setRequestPending] = useState(false);
    
    const [inEdit, setInEdit] = useState(false);

    const [getRefData, refDataQuery] = useLazyQuery(LICENSE_REF_DATA);
    
    useEffect(() => {
        if (inEdit && !refDataQuery.called) {
            getRefData();
        }
    }, [inEdit]);

    const [updateLicenses, updateLicensesMutation] = mutation;

    const formik = useFormik<LicenseAndRegistrationFormValues>({
        initialValues: {
            providerLicenseId: license?.providerLicenseId ?? null,
            registrationNo: license?.registrationNo ?? "",
            countryCode: license?.countryCode ?? null,
            province: license?.province ?? null,
            licenseType: license?.licenseType ?? null,
            expiryDate: license?.expiryDate ?? moment().format('YYYY-MM-DD')
        },
        validate: (values) => {
            const errors: any = {};
            
            if (!values.registrationNo) {
                errors.registrationNo = "Registration number is required"
            }

            if (!values.expiryDate) {
                errors.expiryDate = "Expiry date is required"
            }

            if (!values.licenseType) {
                errors.licenseType = "License type is required"
            }

            if (!values.province) {
                errors.province = "Province is required"
            }

            return errors;
        },
        validateOnBlur: true,
        validateOnChange: true,
        onSubmit: async (values) => {
            formik.validateForm();

            if (formik.isValid) {
                const dialogProps = {
                    message: <>
                        <p>{t('common__confirm_changes_dialog_text')}</p>
                        <ul>
                        {
                            (!license || values.licenseType !== license.licenseType) &&
                            <li>{t('licenses_registrations__association_label')}: <b>{associations?.find(e => e.value === values.licenseType)?.title}</b></li>
                        }
                        {
                            (!license || values.registrationNo !== license.registrationNo) &&
                            <li>{t('licenses_registrations__registration_number_label')}: <b>{values.registrationNo}</b></li>
                        }
                        {
                            (!license || values.province !== license.province) &&
                            <li>{t('licenses_registrations__province_label')}: <b>{provinces?.find(e => e.value === values.province)?.title}</b></li>
                        }
                        {
                            (!license || values.expiryDate !== license.expiryDate) &&
                            <li>{t('licenses_registrations__expiry_date_label')}: <b>{moment(values.expiryDate, "YYYY-MM-DD").format(localeDateFormat)}</b></li>
                        }
                        </ul>
                    </>
                }
                if (await confirm(dialogProps)) {
                    setRequestPending(true);
                    const updatedLicenses = [
                        ...(licenses?.filter(l => l !== license)?.map(mapLicenseToLicenseInput) || []),
                        {
                            providerLicenseId: values.providerLicenseId,
                            registrationNo: values.registrationNo,
                            countryCode: values.countryCode,
                            province: values.province!,
                            licenseType: values.licenseType!,
                            expiryDate: values.expiryDate,
                            licenseTypeDescr: null
                        }
                    ];
                    updateLicenses({
                        variables: {
                            providerId: providerId,
                            licenses: updatedLicenses
                        }
                    }).then(() => { setInEdit(false); formik.resetForm(); }).finally(() => setRequestPending(false));
                }
            }
        }
    });

    const handleDelete = async () => {
        const dialogProps = {
            title: t('common__delete_entry_dialog_title'),
            message: t('common__delete_entry_dialog_text')
        }
        if (await confirm(dialogProps)) {
            setRequestPending(true);
            updateLicenses({
                variables: {
                    providerId: providerId,
                    licenses: [
                        ...(licenses?.filter(l => l !== license)?.map(mapLicenseToLicenseInput) || [])
                    ]
                }
            }).then(() => setInEdit(false)).finally(() => setRequestPending(false));
        }
    }
    
    const hasChanges = formik.dirty 
        && (formik.values.countryCode !== license?.countryCode
            || formik.values.licenseType !== license?.licenseType
            || formik.values.province !== license?.province
            || formik.values.registrationNo !== license?.registrationNo
            || formik.values.expiryDate !== license?.expiryDate);

    const deleteDisabled = updateLicensesMutation.loading || cachePendingUpdate;
    const submitDisabled = deleteDisabled || !hasChanges || !formik.isValid;

    const handleCancelChanges = async () => {
        if (hasChanges) {
            const dialogProps = {
                title: t('common__discard_changes_dialog_title'),
                message: t('common__discard_changes_dialog_text')
            }
            if (await confirm(dialogProps)) {
                formik.resetForm();
                setInEdit(false);
            }
        } else {
            formik.resetForm();
            setInEdit(false);
        }
    }

    const associations = useMemo(() =>
        refDataQuery.data?.licenseRegistrationAssociations?.map(e => ({title: e.title, value: Number(e.value)}))
    , [refDataQuery.data?.licenseRegistrationAssociations]);
    const provinces = useMemo(() =>
        refDataQuery.data?.provinces?.map(e => ({title: e.title, value: Number(e.value)}))
    , [refDataQuery.data?.provinces]);

    
    const handleExpiryDateChange = (date) => {
        date &&
        formik.setFieldValue('expiryDate', date.format('YYYY-MM-DD'));
    }

    return !license && !inEdit
        ? <Button variant="text" onClick={() => setInEdit(true)} className="add_entry_button">
            <AddIcon/>{t('common__add_entry_button_label')}
        </Button>
        : <SectionContainer elevation={0} radius="small" inEdit={inEdit} onEditClick={() => setInEdit(true)}>
            {
                inEdit
                ? (
                    <Grid className="credentialing_sub_section_form" container component='form' onSubmit={formik.handleSubmit} spacing={3}>
                        {
                            requestPending && <Spinner fillParent backdrop />
                        }
                        <Grid item xs={12} xl={6}>
                            <Select
                                label={t('licenses_registrations__association_label')}
                                name="licenseType"
                                value={formik.values.licenseType ?? ''} 
                                onChange={formik.handleChange}
                                className="provider_details_input"
                            >
                                {
                                    associations?.map(licenseRegistrationAssociation => (
                                        <MenuItem value={licenseRegistrationAssociation.value} key={licenseRegistrationAssociation.value}>
                                            {licenseRegistrationAssociation.title}
                                        </MenuItem>
                                    ))
                                }
                            </Select>
                        </Grid>
                        <Grid item xs={12} xl={6}>
                            <TextBox 
                                value={formik.values.registrationNo} 
                                label={t('licenses_registrations__registration_number_label')}
                                name="registrationNo" 
                                onChange={formik.handleChange}
                                className="provider_details_input"
                                error={Boolean(formik.errors.registrationNo)}
                            />
                        </Grid>
                        <Grid item xs={12} xl={6}>
                            <DateInput
                                className="provider_details_input"
                                label={t('licenses_registrations__expiry_date_label')}
                                value={moment(formik.values.expiryDate, DATE_FORMATS.ISO)}
                                onChange={handleExpiryDateChange}
                            />
                        </Grid>
                        <Grid item xs={12} xl={6}>
                            <Select
                                value={formik.values.province ?? ''} 
                                label={t('licenses_registrations__province_label')}
                                name="province" 
                                onChange={formik.handleChange}
                                className="provider_details_input"
                                error={Boolean(formik.errors.province)}
                                type="number"
                            >
                                {
                                    provinces?.map(province => (
                                        <MenuItem value={province.value} key={province.value}>
                                            {province.title}
                                        </MenuItem>
                                    ))
                                }
                            </Select>
                        </Grid>
                        <Grid item xs={12} container spacing={2}>
                            <Grid item>
                                <Button type="submit" variant="opaque" disabled={submitDisabled}>{t_common('save')}</Button>
                            </Grid>
                            <Grid item>
                                <Button type="button" variant="outline" onClick={handleCancelChanges}>{t_common('cancel')}</Button>
                            </Grid>
                            { 
                                license && inEdit && ((licenses?.length ?? 0) > 1) &&
                                <Grid item>
                                    <Button type="button" variant="text" onClick={handleDelete} disabled={deleteDisabled}>{t_common('delete')}</Button>
                                </Grid>
                            }
                        </Grid>
                    </Grid>
                )
                : (
                    license &&
                    <Grid container>
                        <Grid item xs={12} sm={6}>
                            <p className='global__paragraph'>{license.licenseRegistrationAssociation}</p>
                            <p className='global__paragraph--small'>{t('licenses_registrations__association_label')}</p>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <p className='global__paragraph'>{moment(license.expiryDate).format(localeDateFormat)}</p>
                            <p className='global__paragraph--small'>{t('licenses_registrations__expiry_date_label')}</p>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <p className='global__paragraph'>{license.registrationNo}</p>
                            <p className='global__paragraph--small'>{t('licenses_registrations__registration_number_label')}</p>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <p className='global__paragraph'>{license.provinceString} {license.countryCode}</p>
                            <p className='global__paragraph--small'>{t('licenses_registrations__province_label')}</p>
                        </Grid>
                    </Grid>
                )
            }
        </SectionContainer>
}
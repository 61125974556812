import { NetworkStatus, useQuery } from "@apollo/client";
import React, { FC, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { StepDefinition } from "components/ToDos";
import { useTodos } from "hooks/useTodos";
import { GET_ACTIVITY_DATA_QUERY, SAVE_APPT_DATE_MUTATION, TERMINATE_TODO_MUTATION } from "../../queries";
import { AfterSessionSavedPrompt } from "../AfterSessionSavedPrompt";
import { SessionDateForm } from "../SessionDateForm";
import { CaseManagementActionContainer, Spinner } from 'components/Shared';
import { useContractDetails } from "hooks/useContractDetails";
import { FullScreenMessage } from 'components/Shared/FullScreenMessage';
import { PROVIDER_HUB } from "constants/targetedSystem";
import { useRegionConfig } from 'providers/RegionConfig';
import { useMutationWithExpiration } from "providers/Apollo/useMutationWithExpiration";

import '../../views/ScheduleSession.scss';

interface ScheduleNextOfflineSessionProps {
    activityId: number,
    providerId: number
}

export const ScheduleNextOfflineSession: FC<ScheduleNextOfflineSessionProps> = (props) => {
    const { activityId, providerId } = props;
    const { t, ready: translationsReady } = useTranslation('caseManagement', { useSuspense: false });
    
    const { providerContactUrl } = useRegionConfig();

    const { toDos, loading: todosLoading } = useTodos(providerId);

    const [enteredDate, setEnteredDate] = useState<Date | null>(null);

    const activityDataQuery = useQuery(GET_ACTIVITY_DATA_QUERY, {
        variables: { activityId: activityId },
        errorPolicy: 'all',
        fetchPolicy: 'no-cache',
        notifyOnNetworkStatusChange: true
    });

    const contractDetailQuery = useContractDetails(activityId);

    const [closeToDo, closeToDoMutation] = useMutationWithExpiration(TERMINATE_TODO_MUTATION, {
        context: { skipGlobalErrorHandling: true }
    });


    const [saveSessionDate, saveSessionDateMutation] = useMutationWithExpiration(SAVE_APPT_DATE_MUTATION, {
        errorPolicy: 'all',
        onError: () => {}
    });
    
    const navigate = useNavigate();

    const handleSaveSessionDate = (date: Date) => {
        saveSessionDate({variables: {
            activityId: activityId,
            providerId: providerId,
            date: date.toJSON(),
            isFirstAppointment: false
        }}).then((result) => {
            if (!result.errors) {
                activityDataQuery.refetch({ activityId: activityId });
                setEnteredDate(date);
            }
        })
    }

    const activity = activityDataQuery.data?.activity;
    const contractDetails = contractDetailQuery?.data?.contractDetails;
    
    const isProviderHubActivity = activity?.targetedSystem?.systemCode === PROVIDER_HUB;

    const enterNextSessionDateToDo = useMemo(() =>
        toDos?.find(todo => todo.activityInfo?.activityId === activityId && todo.step === StepDefinition.ENTER_DATE_NEXT_APPT)
    , [activityId, toDos]);

    useEffect(() => {
        if (activity && enterNextSessionDateToDo
            && !activity.sessionBooking.allowed
            && activity.sessionBooking.prohibitionReasonCode === 'HOURS_EXCEEDED'
        ) {
            closeToDo({ variables: { workflowId: enterNextSessionDateToDo.id }});
        }
    }, [activity, enterNextSessionDateToDo])

    const saveSessionDisabled = !(activity?.sessionBooking.allowed) || saveSessionDateMutation.loading;

    const renderContent = () => {
        if (!isProviderHubActivity ?? false) {
            return (
                 <FullScreenMessage title={t('provider_contact_case_warning_title')}>
                    <p>
                        {t('provider_contact_case_warning')}
                        <a target="_blank" rel="noopener noreferrer"
                            href={`${providerContactUrl}/Account/Login`}
                        >
                            <b>{t('provider_contact')}</b>
                        </a>
                        .
                    </p>
                </FullScreenMessage>
            )
        }
        if (!enteredDate) {
            return <>
                <h2>{t('enter_date_of_next_appointment')}</h2>
                <SessionDateForm activityId={activityId} outOfHours={activity?.sessionBooking.prohibitionReasonCode === "HOURS_EXCEEDED"}
                    contractAllowsAdditionalHours={activity?.contractAllowsAdditionalHours}
                    description={t('next_session_date_form_description', { replace: { clientName: activity?.client.firstName }})}    
                    firstAvailableDate={undefined} activityOpenDate={activity?.openDate}
                    onSaveSessionDate={handleSaveSessionDate} disabled={saveSessionDisabled}
                />
            </>
        } else {
            return <>
                <h2>{t('enter_date_of_next_appointment')}</h2>
                <AfterSessionSavedPrompt activityId={activityId} appointmentDate={enteredDate}
                    activity={activity} contractDetails={contractDetails}
                />
            </>
        }
    }

    const handleBackClick = () => {
        navigate(-1);
    }
    
    const clientName = activity && `${activity.client.firstName} ${activity.client.lastName}`;

    const loading = !translationsReady || todosLoading || activityDataQuery.loading
        || closeToDoMutation.loading || activityDataQuery.networkStatus === NetworkStatus.refetch;
    const mutationPending = saveSessionDateMutation.loading;

    return (
        <CaseManagementActionContainer onBackClick={handleBackClick} activityId={activityId} className="update_appt_status_content"
            clientName={clientName}
        >
            {
                loading
                    ? <Spinner fillParent/>
                    : renderContent()
            }
            {
                mutationPending &&
                <Spinner fillParent backdrop/>
            }
        </CaseManagementActionContainer>
    )
}
import { INTERCESSION_WSP_TASK_CODES_QUERY, WSP_TASK_CODES_QUERY } from "./queries";
import { IWspTaskCode } from './interfaces'
import { useQuery } from "@apollo/client";

export const WSP_INTERCESSION_TASK_CODE_ID = 18;

export const useWspTaskCodes = (): IWspTaskCode[] | undefined => {
    const wspTaskCodesQuery = useQuery(
        WSP_TASK_CODES_QUERY, 
        {}
    );
    return wspTaskCodesQuery?.data?.wspTaskCodes
} 

export const useIntercessionWspTaskCodes = (): IWspTaskCode[] | undefined => {
    const intercessionWspTaskQuery = useQuery(
        INTERCESSION_WSP_TASK_CODES_QUERY, 
        {}
    );
    return intercessionWspTaskQuery?.data?.intersessionWspTaskCodes
} 
import React, { FC, useEffect } from 'react';
import { FormViewContainer } from '../../components/FormViewContainer';
import { FormPresenter } from '../../components/FormPresenter';
import { GET_FORM_BY_ID, UPDATE_FORM } from '../../queries';
import { useMutation, useQuery } from '@apollo/client';
import { Spinner } from 'components/Shared';
import { PROVIDER_HUB } from 'constants/targetedSystem';
import { ActivityStatus } from 'constants/activityStatus';
import { FullScreenMessage } from 'components/Shared/FullScreenMessage';
import { useNavigate } from 'react-router-dom';
import { useHistoryState } from 'hooks/useHistoryState';
import { useTranslation } from 'react-i18next';
import { IPreAssessment } from 'components/Forms/interfaces';
import { appendPreAssessmentResults } from 'components/Forms/components/Forms/formUtils';
import { useSnackbar } from 'notistack';

interface FormByIdViewProps {
    formId: string
}

export const FormByIdView: FC<FormByIdViewProps> = (props) => {
    const { formId } = props;
    const navigate = useNavigate();
    const { enqueueSnackbar } = useSnackbar();
    const [historyState] = useHistoryState<{ entryRoute?: string }>();
    const { t } = useTranslation('assessments', { useSuspense: false });
    const [isDraft, setIsDraft] = React.useState(false);

    const [updateForm, updateFormMutation] = useMutation(UPDATE_FORM, {
        onCompleted: () => {
            isDraft 
                ? enqueueSnackbar(t('note_saved_as_draft'), { variant: 'success' })
                : navigate(historyState?.entryRoute ? historyState?.entryRoute : `/clients/${activityId}`);
        }
    });

    const formQuery = useQuery(GET_FORM_BY_ID, {
        variables: { formId: formId }
    });

    // cloning object because 'result' field is readonly in original object
    const form = formQuery.data?.form ? { ...formQuery.data.form } : undefined;
    const activity = formQuery.data?.form?.activity;
    const activityId = activity?.activityId;

    const { preAssessments = [] } = activity || {};

    // find the preassessment matching current form, and use the result to auto-fill current form
    if (!!form && !form.result) {
        appendPreAssessmentResults(form, preAssessments as IPreAssessment[]);
    }

    const readOnly = (updateFormMutation.called && !updateFormMutation.error && !isDraft)
        || form?.readOnly
        || activity?.activityStatusCode !== ActivityStatus.Active
        || activity?.targetedSystem?.systemCode !== PROVIDER_HUB;

    let timeout;
    // we want to clear timeout when component unmounts
    useEffect(() => {
        return () => {
            if (timeout) {
                clearTimeout(timeout);
                timeout = null;
            }
        }
    }, []);

    const saveDraftOrCompleteForm = (result: string, draft: boolean) => {
        // we want to execute on next tick to allow state updates to be commited
        timeout = setTimeout(() => form && updateForm({
            variables: {
                data: result,
                formId: form?.id,
                isCompleted: !draft || form.completed
            }
        }), 1);
    }

    const handleFormSubmit = (result: string) => {
        setIsDraft(false);
        saveDraftOrCompleteForm(result, false);
    };
    
    const handleSaveDraft = (result: string) => {
        setIsDraft(true);
        saveDraftOrCompleteForm(result, true);
    };

    const clientName = `${activity?.client.firstName} ${activity?.client.lastName}`;

    return (
        <FormViewContainer activityId={activityId} clientName={clientName}>
            {
                updateFormMutation.loading &&
                <Spinner fillParent backdrop/>
            }
            {
                formQuery.loading
                    ? <Spinner fillParent/>
                    : form
                        ? <FormPresenter
                            activityId={activityId}
                            sessionId={form.sessionId ?? null}
                            form={form}
                            readOnly={readOnly ?? true}
                            onSubmit={handleFormSubmit}
                            onSaveDraft={handleSaveDraft}
                        />
                        : <FullScreenMessage title={t('assessment_not_found_message')} />
            }
        </FormViewContainer>
    )
}


import { TemplateNames } from 'components/Forms/constants';
import { Assessment, FormStatus, FormSummary, IFormByActivity } from '../interfaces';
import { IFormInfo } from 'components/Forms/interfaces';

export const GetFormStatus = (templateName: string,
    mandatoryAssessments: Assessment[],
    form: FormSummary | undefined,
    stage: 'PRE' | 'POST' = 'PRE'): FormStatus => {
    
        if (!form) {
            return mandatoryAssessments.find(assessment => assessment.templateName === templateName)?.mandatory
                ? FormStatus.Incomplete
                : FormStatus.Warning;
        } else {
            return stage === 'PRE'
                ? form.preAssessmentStageStatus === FormStatus.Completed || form.preAssessmentStageStatus === FormStatus.Declined
                    ? form.preAssessmentStageStatus
                    : mandatoryAssessments.find(assessment => assessment.templateName === form.templateName)?.mandatory
                        ? FormStatus.Incomplete : FormStatus.Warning
                : form.postAssessmentStageStatus === FormStatus.Completed || form.postAssessmentStageStatus === FormStatus.Declined
                    ? form.postAssessmentStageStatus
                    : mandatoryAssessments.find(assessment => assessment.templateName === form.templateName)?.mandatory
                        ? FormStatus.Incomplete : FormStatus.Warning;
        }
    
}

export const GetFirstAssessmentFormsIncomplete = (forms: FormSummary[], firstAppointmentAssessments: Assessment[]) => (
    firstAppointmentAssessments
        .map(assessment => {
            var form = forms.find(form => form.templateName === assessment.templateName);
            return form?.preAssessmentStageStatus === FormStatus.Completed || form?.preAssessmentStageStatus === FormStatus.Declined
                ? form?.preAssessmentStageStatus
                : assessment.mandatory ? FormStatus.Incomplete : FormStatus.Warning
        }) 
        .includes(FormStatus.Incomplete)
)

export const GetLastAssessmentFormsIncomplete = (forms: FormSummary[], lastAppointmentAssessments: Assessment[]) => (
    lastAppointmentAssessments
        .map(assessment => {
            var form = forms.find(form => form.templateName === assessment.templateName);
            return form?.postAssessmentStageStatus === FormStatus.Completed || form?.postAssessmentStageStatus === FormStatus.Declined
                ? form?.postAssessmentStageStatus
                : assessment.mandatory ? FormStatus.Incomplete : FormStatus.Warning
        }) 
        .includes(FormStatus.Incomplete)
)

type TemplateNameTuple = {
    templateName: string,
    mandatory: boolean
};

export const removeDuplicateSOU = (formList: (IFormInfo | TemplateNameTuple | IFormByActivity)[]) => (assessment: IFormInfo | TemplateNameTuple | IFormByActivity) => !(
    formList.find(form => form.templateName === TemplateNames.StatementOfUnderstanding_BELL) 
    && assessment.templateName === TemplateNames.StatementOfUnderstanding
);


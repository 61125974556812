import { Box } from '@mui/material'
import React, { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ErrorOutline } from '@mui/icons-material'
import { useQuery } from '@apollo/client'
import {
    Spinner,
    AutoComplete,
} from 'components/Shared'
import { useNavigate, useLocation } from 'react-router-dom'
import { BookApptFrame } from '../BookApptFrame'
import { ICalendarActivityListEntity } from '../../interfaces'
import { ACTIVE_STATUS_CODE as ACTIVITY_ACTIVE_STATUS_CODE } from 'constants/activityStatus'
import { PROVIDER_CONNECT } from 'constants/targetedSystem'
import { useRegionConfig } from 'providers/RegionConfig'
import { CALENDAR_STEP1_ACTIVITIES_QUERY } from '../../queries'
import { PageMetadata } from 'components/Shared/PageMetadata';
import { useAuth } from 'components/Auth'
import './style.scss'

export interface IActivityValue {
    client: string
    activityId: number
}

export interface IClientCalendarStep1ActivityListData extends ICalendarActivityListEntity {
    label: string;
    id: number | string;
}

export const mapActivityToListItem = (
    activity: ICalendarActivityListEntity,
): IClientCalendarStep1ActivityListData => {
    const additionalParticipantsString = activity.additionalParticipants?.map(participant => `${participant.firstName} ${participant.lastName}`)?.join(', ');
    const additionalParticipantsLabel = additionalParticipantsString
        ? '(' + additionalParticipantsString + ')'
        : '';

    return {
        label: `${activity.client?.firstName} ${activity.client?.lastName} ${additionalParticipantsLabel}`,
        id: activity.activityId,
        ...activity,
    }
}

export const BookApptStep1: FC = () => {
    const location = useLocation();
    const startDate = location?.state?.startDate;
    const entryRoute = location?.state?.entryRoute;

    const { t, ready } = useTranslation('calendaring', { useSuspense: false });
    const navigate = useNavigate();
    const { effectiveProviderData } = useAuth();
    const { providerContactUrl } = useRegionConfig();
    const [ selectedClientActivity, setSelectedClientActivity ] = useState<IClientCalendarStep1ActivityListData | null>(null)

    const { data: response, loading } = useQuery(CALENDAR_STEP1_ACTIVITIES_QUERY, {
        variables: { providerId: effectiveProviderData?.providerId ?? -1 },
        fetchPolicy: 'no-cache',
    });

    const allClientActivities = response?.activities
        ?.filter((activity) => activity.activityStatusCode === ACTIVITY_ACTIVE_STATUS_CODE)
        ?.map(activity => mapActivityToListItem(activity))
        ?.sort((client1, client2) => client1.label.localeCompare(client2.label));

    const routeToStep2 = () => {
        navigate('/sessions/book/step2', {
            state: {
                activityId: selectedClientActivity?.id,
                startDate,
                entryRoute
            }
        })
    }

    const retrieveSelectedActivity = () => {
        if (selectedClientActivity) {
            return allClientActivities?.find(
                (activity) =>
                    activity.activityId === selectedClientActivity?.id,
            )
        }
    }

    const isProviderContactActivity = () => {
        const selectedActivity = retrieveSelectedActivity()
        return selectedActivity
            ? selectedActivity.targetedSystem.systemCode === PROVIDER_CONNECT
            : false
    }

    const WarningComponent = (props) => (
        <div className="provider-contact-warning">
            <ErrorOutline />
            <div className="provider-contact-warning-message">
                {props.children}
            </div>
        </div>
    )

    const renderProviderContactWarning = () => {
        if (isProviderContactActivity()) {
            return (
                <WarningComponent>
                    {t('provider_warning')} {' '}
                    <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href={`${providerContactUrl}/Account/Login`}
                    >
                        <b>{t('provider_contact')}</b>
                    </a>
                    .
                </WarningComponent>
            )
        }
    }

    const isNextButtonDisabled = () =>
        selectedClientActivity === null || isProviderContactActivity()

    return (
        <React.Fragment>
            { ready && 
                <PageMetadata 
                    pageDescription={t('step1_page_description')} 
                    pageTitle={t('step1_page_title')}
                /> 
            }
            { !loading ? (
                <BookApptFrame
                    className="calendar_booking_step1"
                    onClickNextButton={routeToStep2}
                    showNextButton
                    nextButtonDisabled={isNextButtonDisabled()}
                    backRoute={entryRoute}
                >
                    <h1 className="global__header2 calendar_appt_booking_title">
                        {t('choose_client')}
                    </h1>
                    <Box className="calendar_appt_booking_container_box">
                        <div className="search_box_wrapper">
                            <AutoComplete
                                options={allClientActivities ?? []}
                                selectedOption={selectedClientActivity}
                                autoHighlight
                                placeholder={t('search_clients')}
                                onChangeOption={(clientActivity) =>
                                    setSelectedClientActivity(clientActivity as IClientCalendarStep1ActivityListData)
                                }
                            />
                        </div>
                        {
                            isProviderContactActivity() &&
                            renderProviderContactWarning()
                        }
                    </Box>
                </BookApptFrame>
                ) : (
                    <Spinner fillParent />
                )
            }
        </React.Fragment>
    )
}

import React, { FC, useState, useEffect, useCallback, useMemo } from "react";
import { FormikErrors, useFormik } from 'formik';
import { MenuItem,  RadioGroup, FormControlLabel, Radio, Checkbox, Alert } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { DayPicker } from './DayPicker';
import { ExecutionResult } from 'graphql';
import { IAutoCompleteValue, Spinner } from "components/Shared";
import { UnavailabilityReason } from "../../queries";
import { Unavailability, Address } from "../../interfaces";
import '../../Unavailabilities.scss';
import * as Moment from "moment";
import { extendMoment } from 'moment-range';
import useTimezoneUtils, { DATE_FORMATS } from "hooks/useTimezoneUtils";
import { WSP_INTERCESSION_TASK_CODE_ID } from "hooks/useWspTaskCodes";

import { parseUavailabilityName } from "../../utils";
import { IWspTaskCode } from "hooks/useWspTaskCodes/interfaces";
import { useFeatureManager } from "hooks/useFeatureManager";
import { Button, DateInput, TimeInput, Select } from "components/Shared/Inputs";

const moment = extendMoment(Moment)

interface UnavailabilityFormProps {
    unavailability?: Unavailability,
    unavailabilityReasons?: UnavailabilityReason[],
    wspTaskCodes?: Array<IWspTaskCode>
    intercessionWspTasks?: Array<IWspTaskCode>
    wspClients?: IAutoCompleteValue[]
    addresses?: Address[],
    pendingUnavailabilities?: Unavailability[],
    managedCalendar: boolean,
    onSubmit: (unavailability: Unavailability) => Promise<ExecutionResult<any>>,
    onCancel: () => void,
    onDelete?: (unavailability: Unavailability) => Promise<ExecutionResult<any>>
}

interface UnavailabilityFormValues {
    scheduleId?: number,
    seriesId?: string,
    startDate: string,
    endDate: string,
    startTime: string,
    endTime: string,
    wholeDay: boolean,
    addressId?: number,
    recurring: boolean,
    recurringDays: number[],
    reason: UnavailabilityReason | null,
    graphQL: string | null,
    isReadOnly: boolean,
    selectedClientId?: string,
    selectedWspCode?: string,
    disabled: {
        startDate: boolean,
        endDate: boolean,
        recurring: boolean,
        recurringDays: boolean
    }
}

export interface IUnavailabilityDate {
    startDate?: Date | string, 
    startTime: string,
    endDate?: Date | string, 
    endTime: string
}


export const UnavailabilityForm: FC<UnavailabilityFormProps> = (props) => {
    const { unavailability, unavailabilityReasons, pendingUnavailabilities, addresses, managedCalendar,
        onSubmit, onCancel, onDelete } = props;

    const { t, i18n } = useTranslation(['providerprofile', 'common'], { useSuspense: false });

    const { isWSPFeatureEnabled } = useFeatureManager()

    const { createDateFormatFunction } = useTimezoneUtils();

    const [requestPending, setRequestPending] = useState(false);
    const [submitSuccessfull, setSubmitSuccessfull] = useState(false);
    const [disabled, setDisabled] = useState(false);
    const [warning, setWarning] = useState('');

    const today = moment().startOf('day');
    const tomorrow = today.clone().add(1, 'day');

    const formatDate = useCallback(createDateFormatFunction('YYYY-MM-DD'), []);
    const formatTime = useCallback(createDateFormatFunction('HH:mm:ss'), []);
    const reasonError = t('unavailability_reason_required','Unavailability reason is required');

    const areDatesOverlapping = (dateA: IUnavailabilityDate, dateB: IUnavailabilityDate ) => {
        const formattedStartDateA = formatDate(dateA.startDate || '')
        const formattedEndDateA = formatDate(dateA.endDate || '')

        const formattedStartDateB = formatDate(dateB.startDate || '')
        const formattedEndDateB = formatDate(dateB.endDate || '')

        const rangeA = moment().range(moment(`${formattedStartDateA} ${dateA.startTime}`), moment(`${formattedEndDateA} ${dateA.endTime}`));
        const rangeB = moment().range(moment(`${formattedStartDateB} ${dateB.startTime}`), moment(`${formattedEndDateB} ${dateB.endTime}`));

        return rangeA.overlaps(rangeB)
    }

    const isWspUnavailability = () => Boolean(unavailability?.wspTaskCode)

    const isUnavailabilityReadOnly = (unavailability?: Unavailability) => {
        if (isWspUnavailability()) {
            const matchingWspTaskCode = props.wspTaskCodes?.find(wspTaskCode => wspTaskCode.value === unavailability?.wspTaskCode)
            return matchingWspTaskCode ? !matchingWspTaskCode.editable : false
        }
        return unavailability?.reason?.editable === false
    }

    useEffect(() => {
        // formik.validateForm();
        return () => {
            formik.validateForm();
        }
    }, [t, i18n.language])

    const formik = useFormik<UnavailabilityFormValues>({
        initialValues: {
            startDate: formatDate(unavailability?.startDate ?? today),
            endDate: formatDate(unavailability?.endDate ?? tomorrow),
            startTime: unavailability?.startTime ?? '12:00:00',
            endTime: unavailability?.endTime ?? '12:00:00',
            wholeDay: managedCalendar ? false : true,
            reason: unavailability?.reason ?? null,
            addressId: unavailability?.address,
            recurring: unavailability?.recurring ?? false,
            recurringDays: unavailability?.recurringDays ?? [],
            scheduleId: unavailability?.scheduleId ?? undefined,
            selectedClientId: unavailability?.activityId ? String(unavailability?.activityId) : '',
            selectedWspCode: unavailability?.wspTaskCode || '',
            seriesId: unavailability?.seriesId ?? undefined,
            isReadOnly: isUnavailabilityReadOnly(unavailability),
            graphQL: '',
            disabled: {
                startDate: (Boolean(unavailability?.scheduleId)
                    && unavailability?.startDate
                    && moment(unavailability.startDate).isSameOrBefore(tomorrow)) ?? false,
                endDate: false,
                recurring: Boolean(unavailability?.scheduleId),
                recurringDays: false
            }
        },
        validate: (values) => {
            const errors: FormikErrors<UnavailabilityFormValues> = {};

            const invalidDateText = "Invalid date";            

            if (pendingUnavailabilities) {
                const overlapping = pendingUnavailabilities
                    .find(x => {
                        return x.scheduleId !== values.scheduleId && 
                        areDatesOverlapping({
                            startDate: values.startDate,
                            endDate: values.endDate,
                            startTime: values.startTime,
                            endTime: values.endTime
                        }, {
                            startDate: x.startDate,
                            endDate: x.endDate,
                            startTime: x.startTime || '00:00',
                            endTime: x.endTime || '00:00'
                        })
                    });

                if (overlapping) {
                    setWarning(t('overlap_with_unavailability_warning', {
                        unavailabilityDescription: parseUavailabilityName(overlapping.reason?.description),
                        start: overlapping.startDate?.toLocaleDateString(i18n.language),
                        end: overlapping.endDate?.toLocaleDateString(i18n.language)
                    }))
                } else {
                    setWarning('');
                }
            } else {
                setWarning('');
            }

            if (values.reason?.id === WSP_INTERCESSION_TASK_CODE_ID) {
                if (!values.selectedClientId) {
                    if (!hasWspClients()) {
                        errors.selectedClientId = t('dont_have_wsp_case');
                    } else {
                        errors.selectedClientId = t('select_a_client');
                    }
                }
                if (!values.selectedWspCode) {
                    errors.selectedWspCode = t('select_a_wsp_code');
                }
            }

            if (values.startDate === invalidDateText) {
                errors.startDate = t('invalid_start_date')
            } else if (moment(values.startDate).isBefore(moment(), 'day')) {
                errors.startDate = t('date_should_not_be_before_minimal_date')
            }

            if (values.endDate === invalidDateText) {
                errors.endDate = t('invalid_end_date')
            } else if (moment(values.endDate).isBefore(moment(), 'day')) {
                errors.endDate = t('date_should_not_be_before_minimal_date')
            } else if (moment(values.endDate).isSame(values.startDate, 'day')) {
                if (!values.wholeDay) { 
                    if (moment(values.endTime, 'HH:mm:ss').isBefore(moment(values.startTime, 'HH:mm:ss')) || 
                        moment(values.endTime, 'HH:mm:ss').isSame(moment(values.startTime, 'HH:mm:ss'))
                    )   {
                        errors.endTime = t('end_time_should_after_start_time');
                    }
                }
            } else if (moment(values.endDate).isBefore(values.startDate)) {
                errors.endDate = t('end_before_start');
            }

            if (values.startTime === invalidDateText) {
                errors.startTime = t('invalid_start_time')
            } 

            if (values.endTime === invalidDateText) {
                errors.endTime = t('invalid_end_time')
            }

            if (values.recurring && values.startDate === values.endDate) {
                errors.endDate = t('end_equal_start')
            }

            if (values.recurring && !values.recurringDays.length) {
                errors.recurringDays = t('select_at_least_one_day');
            }

            if (!values.reason) {
                errors.reason = reasonError;
            }

            return errors;
        },
        validateOnBlur: true,
        validateOnChange: true,
        onSubmit: (values) => {
            formik.setTouched({
                startDate: true,
                endDate: true,
                startTime: true,
                endTime: true,
                reason: true
            });
            formik.validateForm();

            if (formik.isValid) {
                setRequestPending(true);
                setDisabled(true);
                onSubmit(getUnavailability(values))
                    .then(result => {
                        if (result?.data?.saveUnavailability === 1) {
                            setSubmitSuccessfull(true);
                            setDisabled(true);
                        } else {
                            setDisabled(false);
                        }
                    })
                    .catch(error => {
                        setDisabled(false);
                        if (error?.graphQLErrors && error?.graphQLErrors[0]?.message) {
                            formik.setFieldError('graphQL', error.graphQLErrors[0].message);
                        } else {
                            formik.setFieldError('graphQL', error.message);
                        }
                    })
                    .finally(() => setRequestPending(false));
            }
        }
    });

    const officeAddresses = addresses?.filter(address => address.officeType !== 'MAIL');

    const hasWspClients = () => props.wspClients && props.wspClients.length > 0

    useEffect(() => {
        if (officeAddresses?.length && formik.values.addressId === undefined) {
            formik.setFieldValue('addressId', officeAddresses[0].providerAddressId);
        }
    }, [officeAddresses, formik]);

    const getUnavailability = (values: UnavailabilityFormValues): Unavailability => {
        return {
            startDate: moment(values.startDate).toDate(),
            endDate: moment(values.endDate).toDate(),
            startTime: values.wholeDay ? '00:00:00' : values.startTime,
            endTime: values.wholeDay ? '23:59:59' : values.endTime,
            address: values.addressId,
            reason: values.reason as UnavailabilityReason,
            recurring: values.recurring,
            recurringDays: values.recurringDays,
            scheduleId: values.scheduleId,
            seriesId: values.seriesId,
            activityId: values.selectedClientId ? Number(values.selectedClientId) : undefined,
            wspTaskCode: values.selectedWspCode,
        }
    } 

    const getErrorMessage = () => {
        if (formik.errors.graphQL) {
            return formik.errors.graphQL;
        }
        if (formik.errors.reason) {
            return formik.errors.reason;
        }
        if (formik.errors.startDate) {
            return formik.errors.startDate;
        }
        if (formik.errors.endDate) {
            return formik.errors.endDate;
        }
        if (formik.errors.startTime) {
            return formik.errors.startTime;
        }
        if (formik.errors.endTime) {
            return formik.errors.endTime;
        }
        if (formik.errors.recurringDays) {
            return formik.errors.recurringDays;
        }
        if (formik.errors.selectedWspCode) {
            return formik.errors.selectedWspCode;
        }
        if (formik.errors.selectedClientId) {
            return formik.errors.selectedClientId;
        }
        if(formik.errors.reason) {
           return formik.errors.reason;
        }
        return null;
    }

    const handleRecurringDayChange = (event: React.MouseEvent<HTMLElement>, newDays: number[]) => {
        formik.setFieldValue('recurringDays', newDays);
    }

    const handleStartDateChange = (date) => {
        if (date) {
            formik.setFieldValue('startDate', formatDate(date));
            if (formik.values.wholeDay) {
                const endOfDay = date.clone().endOf('day');
                if (endOfDay.isAfter(formik.values.endDate)) {
                    formik.setValues({
                        ...formik.values,
                        startDate: formatDate(date),
                        endDate: formatDate(endOfDay)
                    });
                }
            } else if (date.isAfter(formik.values.endDate)) {
                formik.setValues({
                    ...formik.values,
                    startDate: formatDate(date),
                    endDate: formatDate(date)
                });
            }
        }
    }

    const handleEndDateChange = (date) => {
        if (date) {
            formik.setFieldValue('endDate', formatDate(date));
            if (formik.values.wholeDay) {
                const startOfDay = date.clone().startOf('day')
                if (startOfDay.isBefore(formik.values.startDate)) {
                    formik.setValues({
                        ...formik.values,
                        startDate: formatDate(startOfDay),
                        endDate: formatDate(date)
                    });
                }
            } else if (date.isBefore(formik.values.startDate)) {
                formik.setValues({
                    ...formik.values,
                    startDate: formatDate(date),
                    endDate: formatDate(date)
                });
            }
        }
    }

    const handleStartTimeChange = (date) => {
        formik.setFieldValue('startTime', formatTime(date));
    }

    const handleEndTimeChange = (date) => {
        formik.setFieldValue('endTime', formatTime(date));
    }

    const handleRecurringChange = (event, value: string) => {
        formik.setFieldValue('recurring', value === 'true');
    }

    const handleReasonChange = (event => {
        const reason = unavailabilityReasons?.find(x => x.id === event.target.value);
        formik.setFieldValue('reason', reason);
    })

    const handleWspChange = (event => {
        const wspCode = props.wspTaskCodes?.find(x => x.value === event.target.value);
        formik.setFieldValue('selectedWspCode', wspCode?.value);
    })

    const handleClientChange = (event => {
        const clientId = props.wspClients?.find(x => x.id === event.target.value);
        formik.setFieldValue('selectedClientId', clientId?.id);
    })

    const handleDeleteUnavailability = () => {
        if (onDelete && unavailability) {
            setRequestPending(true);
            onDelete(unavailability)
                .finally(() => setRequestPending(false));
        }
    }

    const datePickerFormat = useMemo(() => 
        moment.localeData(i18n.language).longDateFormat('L')
    , [i18n.language]);

    const renderValues = (...values) => values.filter(value => value).join(', ');

    const renderErrorText = (errorValue: string | undefined) => Boolean(errorValue) ? errorValue : undefined;

    const renderDatePickers = () => {
        return (
            <div className="unavailability_pickers_wrapper">
                <DateInput
                    className='date_time_picker'
                    format={datePickerFormat}
                    value={moment(formik.values.startDate, DATE_FORMATS.ISO)}
                    onChange={handleStartDateChange}
                    onBlur={formik.handleBlur}
                    label={t('unavailability_start_date')}
                    error={Boolean(formik.errors.startDate)}
                    disabled={formik.values.isReadOnly || disabled || formik.values.disabled.startDate}
                    minDate={formik.values.disabled.startDate ? undefined : today}
                    maxDate={today.clone().add(12, 'months')}
                    helperText={renderErrorText(formik.errors.startDate)}
                />
                <DateInput
                    className='date_time_picker'
                    format={datePickerFormat}
                    value={moment(formik.values.endDate, DATE_FORMATS.ISO)}
                    onChange={handleEndDateChange}
                    onBlur={formik.handleBlur}
                    label={t('unavailability_end_date')}
                    error={Boolean(formik.errors.endDate)}
                    disabled={formik.values.isReadOnly || disabled || formik.values.disabled.endDate}
                    minDate={today}
                    maxDate={today.clone().add(18, 'months')}
                    helperText={renderErrorText(formik.errors.endDate)}
                />
            </div>
        )
    }

    const timePickerFormat = useMemo(() =>
        moment.localeData(i18n.language).longDateFormat('LT').includes('A')
            ? 'hh:mm A'
            : 'HH:mm'
    , [i18n.language]);

    const renderWspSection = () => {
        if (formik.values.reason?.id === WSP_INTERCESSION_TASK_CODE_ID && isWSPFeatureEnabled()){
            return (
                <>
                <Select label={t('common:wsp_task_code')}
                    className='unavailability_reason_dropdown'
                    value={formik.values.selectedWspCode ?? ''}
                    onChange={handleWspChange}
                    onBlur={formik.handleBlur}
                    name="addressId"
                    disabled={formik.values.isReadOnly || disabled}
                >
                    {
                        props.intercessionWspTasks?.map((code, index) => (
                            <MenuItem value={code.value} key={index}>
                                {code.title}
                            </MenuItem>
                        ))
                }
                </Select>
                <Select
                    className='unavailability_reason_dropdown'
                    value={formik.values.selectedClientId ?? ''}
                    onChange={handleClientChange}
                    onBlur={formik.handleBlur}
                    name="activityId"
                    disabled={formik.values.isReadOnly || disabled || !hasWspClients()}
                    label={t('associated_activity')}
                >
                    {
                        props.wspClients?.map((client, index) => (
                            <MenuItem value={client.id} key={index}>
                                {client.label}
                            </MenuItem>
                        ))
                    }
                </Select>
                </>
            )
        }
    }

    const renderTimePickers = () => {
        return (
            <div className="unavailability_pickers_wrapper">
                <TimeInput
                    className="date_time_picker"
                    label={t('start_time')}
                    format={timePickerFormat}
                    error={Boolean(formik.errors.startTime)}
                    value={moment(formik.values.startTime, 'HH:mm:ss')}
                    minutesStep={15}
                    disabled={formik.values.isReadOnly || disabled}
                    onChange={handleStartTimeChange}
                    onBlur={formik.handleBlur}
                    helperText={renderErrorText(formik.errors.startTime)}
                />
                <TimeInput
                    className="date_time_picker"
                    label={t('end_time')}
                    format={timePickerFormat}
                    error={Boolean(formik.errors.endTime)}
                    value={moment(formik.values.endTime, 'HH:mm:ss')}
                    minutesStep={15}
                    disabled={formik.values.isReadOnly || disabled}
                    onChange={handleEndTimeChange}
                    onBlur={formik.handleBlur}
                    helperText={renderErrorText(formik.errors.endTime)}
                />
            </div>
        )
    }

    return (
        <form onSubmit={formik.handleSubmit} className="unavailablility_form_wrapper">
            <Select
                className='unavailability_reason_dropdown'
                value={formik.values.reason?.id ?? ''}
                onChange={handleReasonChange}
                onBlur={formik.handleBlur}
                label={t('unavailability_reason_label')}
                error={Boolean(formik.errors.reason)}
                disabled={formik.values.isReadOnly || disabled}
            >
                {
                    unavailabilityReasons?.filter(reason => reason.editable)?.map((reason, index) => (
                        <MenuItem value={reason.id} key={index}>
                            {reason.description}
                        </MenuItem>
                    ))
                }
            </Select>
            {renderWspSection()}
            {
                managedCalendar &&
                <Select
                    className='unavailability_reason_dropdown'
                    value={formik.values.addressId ?? ''}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    disabled={formik.values.isReadOnly || disabled}
                    label={t('unavailability_address_label')}
                >
                    {
                        officeAddresses?.map((address, index) => (
                            <MenuItem value={address.providerAddressId} key={index}>
                                {`${address.officeTypeString} ${address.addressLine1}. ${renderValues(address.addressLine2, address.state_ProvinceCode)}`}
                            </MenuItem>
                        ))
                    }
                </Select>
            }
            <div className='unavailability_item_date_entry_container'>
                { renderDatePickers() }
                {
                    managedCalendar &&
                    <>
                        <FormControlLabel label={t('whole_day') as string} control={
                            <Checkbox
                                checked={formik.values.wholeDay}
                                onChange={formik.handleChange}
                                name="wholeDay"
                                disabled={formik.values.isReadOnly || disabled}
                                color="primary"
                            />
                            }
                        />
                        { !formik.values.wholeDay && renderTimePickers() }
                    </>
                    }
            </div>
            <div className='unavailability_recurrence_container'>
                <RadioGroup className="radio_group_container" row
                    value={formik.values.recurring ? 'true' : 'false'} onChange={handleRecurringChange}>
                    <FormControlLabel value='false' control={<Radio disabled={formik.values.isReadOnly || disabled || formik.values.disabled.recurring} color="primary"/>} label={t('unavailability_one_time') as string} />
                    <FormControlLabel value='true' control={<Radio disabled={formik.values.isReadOnly || disabled || formik.values.disabled.recurring} color="primary"/>} label={t('unavailability_recurring') as string} />
                </RadioGroup>
            </div>
            {
                formik.values.recurring &&
                <DayPicker
                    value={formik.values.recurringDays}
                    onChange={handleRecurringDayChange}
                    disabled={formik.values.isReadOnly || disabled || formik.values.disabled.recurringDays}
                />
            }
            {
                warning &&
                <Alert severity="warning" className='unavailability_message_container'>{warning}</Alert>
            }
            {
                submitSuccessfull &&
                <Alert severity="success" className='unavailability_message_container'>{t('unavailability_successfully_saved')}</Alert>
            }
            {
                getErrorMessage() &&
                <Alert severity="error" className='unavailability_message_container'>{getErrorMessage()}</Alert>
            }
            {
                requestPending && <Spinner fillParent backdrop />
            }
            <div className="unavailability_and_cancel_button_div">
                {
                    !formik.values.isReadOnly &&
                    <Button variant="opaque" className='save_unavailability_entry_button' type="submit" disabled={formik.values.isReadOnly || disabled}>
                        {t('save_changes')}
                    </Button>
                }
                <Button variant="text" className='cancel_button' type="button" onClick={onCancel}>
                    {t('cancel_button')}
                </Button>
            </div>
            {
                unavailability && Boolean(unavailability.scheduleId) && !formik.values.isReadOnly &&
                <Button variant="text" className='delete_button' type="button"
                    onClick={handleDeleteUnavailability}>
                    {t('delete_this_unavailability')}
                </Button>
            }
        </form>
    )
}
import { gql, TypedDocumentNode } from '@apollo/client';


export const IS_ACTIVITY_ACCEPTED_QUERY: TypedDocumentNode<IIsActivityAcceptedQueryData, IIsActivityAcceptedQueryVars>  = gql`
    query IS_ACTIVITY_ACCEPTED_QUERY($activityId: Long!) {
        isActivityAcceptedByProvider(activityId: $activityId)
    }
`
export interface IIsActivityAcceptedQueryVars {
    activityId: number
}

export interface IIsActivityAcceptedQueryData {
    isActivityAcceptedByProvider: boolean
};
import { useMutation } from "@apollo/client";
import { DELETE_UNAVAILABILITY_MUTATION, DeleteUnavailabilityMutationVars, DeleteUnavailabilityMutationData } from "../queries";
import { useSnackbar } from 'notistack';
import { useAuth } from 'components/Auth';
import { Unavailability } from '../interfaces';
import { applyToSeries } from '../components/ApplyToSeriesModal';
import { confirmDelete } from '../components/ConfirmDeleteModal';
import { ExecutionResult } from "graphql";
import { useTranslation } from "react-i18next";


export const useDeleteUnavailability = (): (unavailability: Unavailability) => Promise<ExecutionResult<DeleteUnavailabilityMutationData>> => {
    const { enqueueSnackbar } = useSnackbar();
    const { effectiveProviderData } = useAuth();
    const { t } = useTranslation('unavailabilities', { useSuspense: false });

    const [deleteUnavailability] =  useMutation<DeleteUnavailabilityMutationData, DeleteUnavailabilityMutationVars>(
        DELETE_UNAVAILABILITY_MUTATION,
        {
            errorPolicy: 'all',
            onCompleted: (data) => {
                if (data.deleteUnavailability === 1) {
                    enqueueSnackbar(t('unavailability_successfully_deleted'), { variant: 'success' })
                }
            }
        }
    )

    return (unavailability: Unavailability): Promise<ExecutionResult<DeleteUnavailabilityMutationData>> => {
        const deleteMutationVars: any = {
            eventDate: unavailability.startDate,
            providerId: effectiveProviderData?.providerId ?? 0,
            scheduleId: unavailability.scheduleId || -1,
            username: effectiveProviderData?.providerId.toString()
        }
        if (unavailability.seriesId) {
            return new Promise<ExecutionResult<any>>((resolve) => {
                applyToSeries({}).then((response) => {
                    if (response === 'cancel') {
                        resolve({});
                    } else {
                        const series = response === 'series';
                        if (series) {
                            deleteMutationVars.seriesId = unavailability.seriesId;
                        }
    
                        confirmDelete({unavailability: unavailability as any, series: series})
                            .then(response => {
                                if (response === 'delete') {
                                    resolve(deleteUnavailability({ variables: deleteMutationVars }));
                                } else {
                                    resolve({});
                                }
                            });
                    }
                })
            });
        } else {
            return new Promise<ExecutionResult<any>>((resolve) => {
                confirmDelete({unavailability: unavailability as any})
                    .then(response => {
                        if (response === 'delete') {
                            resolve(deleteUnavailability({ variables: deleteMutationVars }));
                        } else {
                            resolve({});
                        }
                    })
            })
        }
    }
}
import React, { FC } from 'react';
import { EventType, renderEventMatchingIcon } from 'components/Calendaring';
import { ScheduleEvent } from './interfaces';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { IconClock } from 'components/Shared/Icons';
import { useTimezoneUtils } from 'hooks/useTimezoneUtils';
import { parseUavailabilityName } from '../../Unavailabilities/utils'


interface EventCardProps {
    event: ScheduleEvent
}

export const EventCard: FC<EventCardProps> = (props) => {
    const { event } = props;
    const { t, ready } = useTranslation('calendaring', { useSuspense: false });

    const { formatToProviderTime } = useTimezoneUtils();

    const prepareTimeAriaLabel = () => {
        return `${formatToProviderTime(event.start)} - ${formatToProviderTime(event.end)}`;
    }

    const renderIcon = () => {
        if (event.type === EventType.Unavailability) {
            return <IconClock />
        } else {
            return renderEventMatchingIcon(event.item.modalityCode)
        }
    }

    const renderTime = () => {
        return (
            <span>
                {prepareTimeAriaLabel()}
            </span>
        )
    }

    const renderTitle = () => {
        if (event.type === EventType.Unavailability) {
            return <span>{parseUavailabilityName(event.item.unavailabilityReason?.description)}</span>
        } else {
            return (
                <>
                    <div className="eventcard_title_wrapper" title={`${event.item.clientName} (${event.item.activityId})`}>
                        <Link aria-label={`${event.item.clientName} ${prepareTimeAriaLabel()}`} to={`/clients/${event.item.activityId}`}>{event.item.clientName}</Link>
                        {renderNewFlag()}
                    </div>
                    <div>{event.item.activityId}</div>
                </>
            )
        }
    }

    const renderNewFlag = () => (
        event.item.firstTime &&
        <div className="eventcard_new_flag">{t('first_time_appointment_flag')}</div>
    )

    const getStatusClass = () => {
        const now = moment();
        if (event.start.isSameOrBefore(now)) {
            if (event.end.isSameOrBefore(now)) {
                return 'past';
            } else {
                return 'pending';
            }
        } else {
            return '';
        }
    }

    const getTypeClass = () => {
        switch (event.type) {
            case EventType.Reservation: return 'reservation';
            case EventType.Unavailability: return 'unavailability';
            default: return '';
        }
    }

    return (
        <>{
            ready &&
            <div className={`eventcard_wrapper ${getStatusClass()} ${getTypeClass()}`}>
                <div className="eventcard_bar"/>
                <div className="eventcard_content">
                    <div className="eventcard_header_wrapper">
                        {renderIcon()}
                        <div className="eventcard_infos_wrapper">
                            {renderTitle()}
                            {renderTime()}
                        </div>
                    </div>
                </div>
            </div>
        }</>
    )
}
import { gql, TypedDocumentNode } from '@apollo/client';
import { Education, LiabilityInsurance, License, PreviousExperience, ProfessionalDesignation, ProfessionalDevelopment, Specialization } from '../../../interfaces';

export const CREDENTIALING_QUERY: TypedDocumentNode<CredentialingQueryData, CredentialingQueryVars> = gql`
    query CREDENTIALING_QUERY($providerId: Long!) {
        provider(id: $providerId) {
            id
            providerId
            liabilityInsurance {
                onFile
                expiryDate
            }
            credential {
                nationalProviderIdentifier
                businessNumber
                showNationalProviderIdentifier
                showBusinessNumber
                educations {
                    institution
                    degreeId
                    degree
                    providerEducationId
                    isPrimary
                    yearEarned
                }
                licenses {
                    providerLicenseId
                    registrationNo
                    licenseRegistrationAssociation
                    countryCode
                    province
                    provinceString
                    licenseType
                    licenseTypeDescr
                    expiryDate
                }
                previousExperience {
                    providerPreviousExperienceId
                    employerName
                    endDate
                    jobTitleRole
                    startDate
                }
                professionalDesignation {
                    professionalDesignationId
                    providerProfessionalDesignationId
                    specialization
                }
                professionalDevelopment {
                    dateCompleted
                    trainingCode
                    training
                    type
                    typeString
                    providerProfessionalDevelopmentId
                }
                specializations {
                    title
                    value
                }
            }
        }
    }
`

export interface CredentialingQueryData {
    provider: {
        id: number,
        liabilityInsurance?: LiabilityInsurance,
        credential: {
            nationalProviderIdentifier: string | null,
            businessNumber: string | null,
            showNationalProviderIdentifier: boolean,
            showBusinessNumber: boolean,
            educations: Education[],
            licenses: License[],
            previousExperience: PreviousExperience[],
            professionalDesignation: ProfessionalDesignation[],
            professionalDevelopment: ProfessionalDevelopment[],
            specializations: Specialization[]
        }
    }
}

export interface CredentialingQueryVars {
    providerId: number
}
export const CN_PREASSESSMENT_FORM_PREFIX = 'clinicalnow/preassessment/';
export const TPH_ASSESSMENT_FORM_PREFIX = 'providerhub/assessment/';

export enum TemplateNames {
    StatementOfUnderstanding = 'providerhub/assessment/statementofunderstanding',
    StatementOfUnderstanding_BELL = 'providerhub/assessment/statementofunderstanding_bell',
    StatementOfUnderstanding_SSP = 'providerhub/assessment/statementofunderstanding_ssp',
    StatementOfUnderstanding_DC = 'providerhub/assessment/statementofunderstanding_dc',
    StatementOfUnderstanding_SAP = 'providerhub/assessment/statementofunderstanding_sap',
    StatementOfUnderstanding_SRPP = 'providerhub/assessment/statementofunderstanding_srpp',
    StatementOfUnderstanding_TA = 'providerhub/assessment/statementofunderstanding_ta',
    StatementOfUnderstanding_WA = 'providerhub/assessment/statementofunderstanding_wa',
    StatementOfUnderstanding_WRP = 'providerhub/wsp/wrp_sou',
    Facesheet = 'providerhub/assessment/facesheet',
    Questionnaire = 'providerhub/assessment/questionnaire',
    PHQ9 = 'providerhub/assessment/phq9',
    WOS = 'providerhub/assessment/wos',
    ETOS = 'providerhub/assessment/etos',
    RiskAssessment = 'providerhub/assessment/risk',
    SessionNote = 'providerhub/note',
    SessionNote_WRP = 'providerhub/wsp/wrp_session_note',
    FollowUp = 'providerhub/followup',
    DAST = 'providerhub/assessment/dast',
    AUDIT_C = 'providerhub/assessment/audit_c',
    AUDIT = 'providerhub/assessment/audit',
    DASS21 = 'providerhub/assessment/dass21',
    SBQ = 'providerhub/assessment/sbq',
    Gad7 = 'providerhub/assessment/gad7'
}

export enum WspTemplateNames {    
    InitialAssessmentReport = 'providerhub/wsp/wrp_initial_report',
    MonthlyAssessmentReport_WRP = 'providerhub/wsp/wrp_monthly_report',
    ClosureAssessmentReport_WRP = 'providerhub/wsp/wrp_closure_report'
}

export enum AssessmentNames {
    FirstAppointmentAssessment = 'first_appointment_assessment',
    LastAppointmentAssessment = 'last_appointment_assessment'
}

export const AssessmentStages = {
    FIRST_ASSESSMENT_STAGE: "FIRSTASSESSMENT",
    LAST_ASSESSMENT_STAGE: "LASTASSESSMENT",
    PRE_ASSESSMENT_FINAL_STAGE: "PreAssessmentFinal"
}

export const FormTitleKeysMap: Record<string, string> = {
    [TemplateNames.StatementOfUnderstanding]: 'StatementOfUnderstanding-title',
    [TemplateNames.StatementOfUnderstanding_BELL]: 'StatementOfUnderstanding-title',
    [TemplateNames.StatementOfUnderstanding_SSP]: 'StatementOfUnderstanding-title',
    [TemplateNames.StatementOfUnderstanding_DC]: 'StatementOfUnderstanding-title',
    [TemplateNames.StatementOfUnderstanding_SAP]: 'StatementOfUnderstanding-title',
    [TemplateNames.StatementOfUnderstanding_SRPP]: 'StatementOfUnderstanding-title',
    [TemplateNames.StatementOfUnderstanding_TA]: 'StatementOfUnderstanding-title',
    [TemplateNames.StatementOfUnderstanding_WA]: 'StatementOfUnderstanding-title',
    [TemplateNames.StatementOfUnderstanding_WRP]: 'StatementOfUnderstanding-title',
    [TemplateNames.Facesheet]: 'Facesheet-title',
    [TemplateNames.Questionnaire]: 'Questionnaire-title',
    [TemplateNames.PHQ9]: 'PHQ9-title',
    [TemplateNames.WOS]: 'WOS-title',
    [TemplateNames.ETOS]: 'ETOS-title',
    [TemplateNames.RiskAssessment]: 'RiskAssessment-title',
    [TemplateNames.SessionNote]: 'SessionNote-title',
    [TemplateNames.SessionNote_WRP]: 'SessionNote-title',
    [TemplateNames.FollowUp]: 'FollowUp-title',
    [TemplateNames.DAST]: 'DAST-title',
    [TemplateNames.AUDIT]: 'AUDIT-title',
    [TemplateNames.AUDIT_C]: 'AUDIT_C-title',
    [TemplateNames.DASS21]: 'DASS21-title',
    [TemplateNames.SBQ]: 'SBQ-title',
    [TemplateNames.Gad7]: 'Gad7-title',
    [WspTemplateNames.InitialAssessmentReport]: 'InitialAssessmentReport-title',
    [WspTemplateNames.MonthlyAssessmentReport_WRP]: 'MonthlyAssessmentReportWrp-title',
    [WspTemplateNames.ClosureAssessmentReport_WRP]: 'ClosureAssessmentReportWrp-title',
}


export const FormRouteMap: Record<string, string> = {
    [TemplateNames.StatementOfUnderstanding]: 'stmtofunderstanding',
    [TemplateNames.StatementOfUnderstanding_BELL]: 'stmtofunderstanding-bell',
    [TemplateNames.StatementOfUnderstanding_SSP]: 'stmtofunderstanding-ssp',
    [TemplateNames.StatementOfUnderstanding_DC]: 'stmtofunderstanding-dc',
    [TemplateNames.StatementOfUnderstanding_SAP]: 'stmtofunderstanding-sap',
    [TemplateNames.StatementOfUnderstanding_SRPP]: 'stmtofunderstanding-srpp',
    [TemplateNames.StatementOfUnderstanding_TA]: 'stmtofunderstanding-ta',
    [TemplateNames.StatementOfUnderstanding_WA]: 'stmtofunderstanding-wa',
    [TemplateNames.StatementOfUnderstanding_WRP]: 'stmtofunderstanding-wrp',
    [TemplateNames.Facesheet]: 'facesheet',
    [TemplateNames.Questionnaire]: 'questionnaire',
    [TemplateNames.PHQ9]: 'phq9',
    [TemplateNames.WOS]: 'wos',
    [TemplateNames.ETOS]: 'etos',
    [TemplateNames.RiskAssessment]: 'riskassessment',
    [TemplateNames.SessionNote]: 'sessionnote',
    [TemplateNames.SessionNote_WRP]: 'sessionnote_WRP',
    [TemplateNames.FollowUp]: 'followup',
    [TemplateNames.DAST]: 'dast',
    [TemplateNames.AUDIT]: 'audit',
    [TemplateNames.AUDIT_C]: 'audit-c',
    [TemplateNames.DASS21]: 'dass21',
    [TemplateNames.SBQ]: 'sbq',
    [TemplateNames.Gad7]: 'gad7',
    [AssessmentNames.FirstAppointmentAssessment]: 'first_appointment_assessment',
    [AssessmentNames.LastAppointmentAssessment]: 'last_appointment_assessment',
    [WspTemplateNames.InitialAssessmentReport]: 'assessmentreport',
    [WspTemplateNames.MonthlyAssessmentReport_WRP]: 'monthlyassessmentreport-wrp',
    [WspTemplateNames.ClosureAssessmentReport_WRP]: 'closureassessmentreport-wrp',
}

export const FormRoutesReverseMap = {
    'stmtofunderstanding': TemplateNames.StatementOfUnderstanding,
    'stmtofunderstanding-bell': TemplateNames.StatementOfUnderstanding_BELL,
    'stmtofunderstanding-ssp': TemplateNames.StatementOfUnderstanding_SSP,
    'stmtofunderstanding-dc': TemplateNames.StatementOfUnderstanding_DC,
    'stmtofunderstanding-sap': TemplateNames.StatementOfUnderstanding_SAP,
    'stmtofunderstanding-srpp': TemplateNames.StatementOfUnderstanding_SRPP,
    'stmtofunderstanding-ta': TemplateNames.StatementOfUnderstanding_TA,
    'stmtofunderstanding-wa': TemplateNames.StatementOfUnderstanding_WA,
    'stmtofunderstanding-wrp': TemplateNames.StatementOfUnderstanding_WRP,
    'facesheet': TemplateNames.Facesheet,
    'questionnaire': TemplateNames.Questionnaire,
    'phq9': TemplateNames.PHQ9,
    'wos': TemplateNames.WOS,
    'etos': TemplateNames.ETOS,
    'riskassessment': TemplateNames.RiskAssessment,
    'sessionnote': TemplateNames.SessionNote,
    'sessionnote_WRP': TemplateNames.SessionNote_WRP,
    'followup': TemplateNames.FollowUp,
    'dast': TemplateNames.DAST,
    'audit': TemplateNames.AUDIT,
    'audit-c': TemplateNames.AUDIT_C,
    'dass21': TemplateNames.DASS21,
    'sbq': TemplateNames.SBQ,
    'gad7': TemplateNames.Gad7,
    'first_appointment_assessment': AssessmentNames.FirstAppointmentAssessment,
    'last_appointment_assessment': AssessmentNames.LastAppointmentAssessment,
    'assessmentreport': WspTemplateNames.InitialAssessmentReport,
    'monthlyassessmentreport-wrp': WspTemplateNames.MonthlyAssessmentReport_WRP,
    'closureassessmentreport-wrp': WspTemplateNames.ClosureAssessmentReport_WRP
}

export const FormPaths = { 
    Assessments: 'providerhub/assessment',
    PreAssessments: 'clinicalnow/preassessment',
    Appointment: 'providerhub/note',
    FollowUp: 'providerhub/followup'
}

export const FormStage = {
    Created: "Created",
    FirstAssessment: "FirstAssessment",
    LastAssessment: "LastAssessment",
    FollowUpCompleted: "FollowUpCompleted",
    PreAssessmentCreated: "PreAssessmentCreated",
    PreAssessmentPartial: "PreAssessmentPartial",
    PreAssessmentFinal: "PreAssessmentFinal",
    PreAssessmentReviewed: "PreAssessmentReviewed",
    AssessmentCompleted: "AssessmentCompleted",
    Submitted: "Submitted",
    Accepted: "Accepted",
    Rejected: "Rejected"    
}

export const ReportNames = 
{
    Dass21: "DASS21"
}
import React, { FC } from 'react';
import { StepFilterValue } from '../../interfaces';
import { StepDefinition } from '../../constants';
import { MenuItem, TextField, InputAdornment } from '@mui/material';
import { useTranslation } from 'react-i18next';
import FiltersIcon from '@mui/icons-material/FilterList';

interface ToDoTypePickerProps {
    value: StepFilterValue,
    types: StepDefinition[] | undefined,
    onChange: (value: StepFilterValue) => void
}

export const ToDoTypePicker: FC<ToDoTypePickerProps> = (props) => {
    const { value, types, onChange } = props;
    const { t } = useTranslation(['todos', 'common'], { useSuspense: false });

    const handleChange = (event: React.ChangeEvent<{ name?: string; value: unknown }>) => {
        onChange(event.target.value as StepFilterValue);
    };
    
    return (
        <TextField
            select
            value={value}
            variant="outlined"
            onChange={handleChange}
            inputProps={{'aria-label': t('filter_todos_type_picker_label')}}
            InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                        <FiltersIcon/>
                  </InputAdornment>
                )
            }}
        >
            <MenuItem value="ALL">{t('common:all', 'All')}</MenuItem>
            {
                types?.map((toDoType, index) => (
                    <MenuItem value={toDoType} key={index}>
                        {t(`${toDoType}-name` )}
                    </MenuItem>
                ))
            }
        </TextField>
    );
};

import { DataRequest, PaginationDetails, derivePaginationDetails, sortData } from "components/Shared/MaterialTable";
import { StaffTableItem } from "../interfaces";
import { useMemo } from "react";

export const useProcessStaffData = (data: StaffTableItem[] | undefined, dataRequest: DataRequest)
    : [StaffTableItem[] | undefined, PaginationDetails] =>
{
    const parsedData = useMemo(() =>
        data?.filter(provider => Boolean(provider))?.map(provider => ({
            ...provider,
            name: `${provider.firstName} ${provider.lastName}`,
            // status: t('available')
        }))
    , [data]);

    const filteredData = useMemo(() => {
        const searchString = dataRequest.search.toLowerCase().trim();
        return parsedData?.filter(provider =>
            (provider.name?.toLowerCase().includes(searchString) ?? false)
            || (provider.emailAddress?.toLowerCase().includes(searchString) ?? false)
            || (provider.providerId?.toString().includes(searchString) ?? false)
        );
    }, [parsedData, dataRequest.search]);

    const sortedData = useMemo(() =>
        sortData(filteredData, dataRequest.sorts)
    , [filteredData, dataRequest.sorts]);

    const pagedData = useMemo(() => 
        sortedData?.slice(dataRequest.skip, dataRequest.skip + dataRequest.take)
    , [sortedData, dataRequest.skip, dataRequest.take]);

    const paginationDetails = derivePaginationDetails(sortedData?.length, dataRequest);

    return [pagedData, paginationDetails];
}
import React, { FC } from 'react';
import { IconButton, Modal } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Button } from 'components/Shared/Inputs';
import CloseIcon from '@mui/icons-material/Close';
import { Spinner } from 'components/Shared';
import './CaseModelInfoDialog.scss';

interface CaseModelInfoDialogProps {
    open: boolean,
    caseModel: number,
    onClose: () => void
}

export const CaseModelInfoDialog: FC<CaseModelInfoDialogProps> = (props) => {
    const { open, caseModel, onClose } = props;
    const { t, ready, i18n } = useTranslation(['caseModelInfos', 'common'], { useSuspense: false });

    return (
        <Modal open={open} onClose={onClose} className='dialog_wrapper' role="dialog" aria-modal
            aria-labelledby="model_info_dialog_title" aria-describedby="model_info_dialog_content">
            <div className='dialog_surface'>
                <div className="dialog_title_wrapper">
                    <h2 id="model_info_dialog_title">
                        {ready && t('case_model_dialog_title', { replace: { modelNumber: caseModel.toString() }})}
                    </h2>
                    <IconButton aria-label={t('common:close')} onClick={onClose}>
                        <CloseIcon />
                    </IconButton>
                </div>
                <div className="dialog_content_wrapper" id="model_info_dialog_content">
                {
                    ready
                    ? <div dangerouslySetInnerHTML={{__html: t(caseModel.toString())}}/>
                    : <Spinner/>
                }
                {
                    (caseModel <= 17 || caseModel === 21) &&
                    <div className="case_model_chart_wrapper">
                        <img src={`images/CaseModelCharts${i18n.language.slice(0, 2).toUpperCase()}/${caseModel}.png`} alt=""/>
                    </div>
                }
                </div>
                <div className="dialog_actions_wrapper">
                    <Button variant="opaque" onClick={onClose}>
                        {t('common:close')}
                    </Button>
                </div>
            </div>
        </Modal>
    )
}
import { gql, TypedDocumentNode } from "@apollo/client";
import { InsurancePanel } from '../../../interfaces';

export const INSURANCE_PANELS_REF_DATA
    : TypedDocumentNode<InsurancePanelsRefDataData, InsurancePanelsRefDataVars> = gql`
query INSURANCE_PANELS_REF_DATA {
    insurancePanels {
        title
        value
    }
}
`

interface InsurancePanelsRefDataData {
    insurancePanels: {
        title: string,
        value: string
    }[]
}

interface InsurancePanelsRefDataVars {}

export const UPDATE_INSURANCE_PANELS_MUTATION: TypedDocumentNode<UpdateInsurancePanelsMutationData, UpdateInsurancePanelsMutationVars> = gql`
    mutation UPDATE_INSURANCE_PANELS_MUTATION($providerId: Long!, $insuranceIds: [Int!]) {
        updateInsurancePanels(providerId: $providerId, insuranceIds: $insuranceIds) {
            providerId
            details {
                insurancePanels {
                    insuranceId
                    insuranceCompany
                }
            }
        }
    }
`

export interface UpdateInsurancePanelsMutationData {
    updateInsurancePanels: {
        details: {
            insurancePanels: InsurancePanel[]
        }
    }
}

export interface UpdateInsurancePanelsMutationVars {  
    providerId: number,
    insuranceIds: number[]
}
import React, { FC, useEffect, useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { FormPresenter } from '../../components/FormPresenter';
import { FormStage, TemplateNames } from '../../constants';
import { getFirstSession } from 'components/CaseManagement/utils/sessionUtils';
import { useMutation, useQuery } from '@apollo/client';
import { CREATE_FORM_BY_TEMPLATE, GET_FORMS_BY_ACTIVITY, UPDATE_FORM } from '../../queries';
import { PROVIDER_HUB } from 'constants/targetedSystem';
import { TERMINATE_FOLLOW_UP } from 'components/ToDos/queries';
import { Box, Button, Container, Paper } from '@mui/material';
import { ArrowBack as BackIcon } from '@mui/icons-material';
import { FormViewContainer } from '../../components/FormViewContainer';
import { useTranslation } from 'react-i18next';
import { Spinner } from 'components/Shared';
import { useAuth } from 'components/Auth';
import { FullScreenMessage } from 'components/Shared/FullScreenMessage';
import { useHistoryState } from 'hooks/useHistoryState';

interface FollowUpViewProps { }

export const FollowUpView: FC<FollowUpViewProps> = (_) => {
    const { activityId: activityIdString } = useParams<{activityId: string}>();
    const { providerData, effectiveProviderData } = useAuth();

    const { t } = useTranslation(['caseManagement', 'assessment'], { useSuspense: false });
    
    const activityId = parseInt(activityIdString || '');
    
    const navigate = useNavigate();
    
    const [historyState] = useHistoryState<{ fromToDo?: boolean, entryRoute?: string }>();

    const activityDataQuery = useQuery(GET_FORMS_BY_ACTIVITY, {
        variables: { activityId: activityId },
        errorPolicy: 'all',
        fetchPolicy: 'no-cache'
    });

    const [terminateToDo] = useMutation(TERMINATE_FOLLOW_UP, {
        context: {skipGlobalErrorHandling: true},
        onError: (error) => {
            console.error(error);
        }
    });

    const [createForm, createFormMutation] = useMutation(CREATE_FORM_BY_TEMPLATE);

    const [updateForm, updateFormMutation] = useMutation(UPDATE_FORM, {
        onCompleted: () => {
            navigate(historyState?.entryRoute ? historyState.entryRoute : `/clients/${activityId}`);
        }
    });

    const activity = activityDataQuery.data?.activity;
    const forms = activityDataQuery.data?.forms;

    useEffect(() => {
        if (forms) {
            const followUpForm = forms?.find(form => form.templateName === TemplateNames.FollowUp);

            !followUpForm && createForm({
                variables: {
                    templateName: TemplateNames.FollowUp,
                    form: {
                        activityId: activityId,
                        providerId: providerData?.providerId || effectiveProviderData?.providerId || -1,
                        createdBy: `${effectiveProviderData?.providerId}`,
                        sessionId: firstSession?.sessionId
                    }
                }
            })
        }
    }, [forms]);
    
    const isActivityInactive = activity?.activityStatusCode !== 1;
    const readOnly = isActivityInactive || activity?.targetedSystem?.systemCode !== PROVIDER_HUB;
    
    const firstSession = getFirstSession(activity?.sessions);
    const form = forms?.find(form => form.templateName === TemplateNames.FollowUp)
        || createFormMutation.data?.createFormByTemplate;

    const followupPending = useMemo(() => {
        if (form) {
            const formResult = JSON.parse(form?.result);
            if (formResult?.flp_agreeed === "yes" && form.stage?.localeCompare(FormStage.Created.toUpperCase()) === 0) {
                return true;
            }
        }
        return false;
    }, [form]);

    const shouldTerminateToDo = historyState?.fromToDo
        && form
        && form?.completed
        && form?.stage.toUpperCase() === FormStage.FollowUpCompleted.toUpperCase();

    useEffect(() => {
        if (shouldTerminateToDo) {
            terminateToDo({ variables: { activityId: activityId } })
        }
    }, [form, shouldTerminateToDo]);

    const clientName = `${activity?.client.firstName} ${activity?.client.lastName}`;

    const handleFormSubmit = (result) => {
        form && updateForm({
            variables: {
                data: result,
                formId: form.id,
                isCompleted: true
            }
        });
    }

    return form?.completed && form?.stage.toUpperCase() === FormStage.FollowUpCompleted.toUpperCase()
        ? (
            <Paper className="confirm_acceptance_card" elevation={0}>
                <Container className="confirm_acceptance_container">
                    <Box>
                        <Button
                            className="back_button"
                            onClick={() => historyState?.entryRoute ? navigate(historyState.entryRoute) : navigate(-1)}
                            startIcon={<BackIcon />}
                            color="primary"
                        >
                            {t('back')}
                        </Button>
                    </Box>
                    <h2 className="global__header3">
                        {t('FollowUp-title', 'Follow Up')}
                    </h2>
                    <p className="global__paragraph global__paragraph--light not-accepted-yet">
                        {t('followup_already_completed')}
                    </p>
                </Container>
            </Paper>
        )
        : (
            <FormViewContainer activityId={activityId} clientName={clientName}>
                {
                    updateFormMutation.loading &&
                    <Spinner fillParent backdrop/>
                }
                {
                    (activityDataQuery.loading || createFormMutation.loading)
                        ? <Spinner fillParent/>
                        : form 
                            ? <FormPresenter
                                activityId={activityId}
                                sessionId={firstSession?.sessionId ?? null}
                                form={form}
                                readOnly={readOnly && !followupPending}
                                onSubmit={handleFormSubmit}
                            />
                            : <FullScreenMessage title={t('assessment:assessment_not_found_message')} />
                }
            </FormViewContainer> 
        )
}

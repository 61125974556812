import React, { FC, useEffect } from 'react';
import ReactDOM from 'react-dom';
import './surveys.scss';
import 'survey-react/modern.min.css';
import * as SurveyJS from 'survey-react';
import { useTranslation } from 'react-i18next';
import { Spinner } from 'components/Shared';
import { IconButton } from '@mui/material';
import InfoIcon from '@mui/icons-material/InfoOutlined';
import { FormDescriptionDialog } from '../FormDescriptionDialog';

SurveyJS.StylesManager.applyTheme('modern');

export const COACHING_NOTE_ANCHOR_NAME = 'form_coaching_note_anchor'
export const COACHING_NOTE_ANCHOR_ID = `#${COACHING_NOTE_ANCHOR_NAME}`

interface ISurveyJSRendererProps {
    isLoading: boolean,
    surveyModel: SurveyJS.SurveyModel | null,
    onFormSubmit: (result) => void,
    formDescriptionDialogOpen: boolean,
    coachingNoteAnchor: Element | null,
    coachingNote?: string,
    coachingNoteTitle?: string,
    setFormDescriptionDialogOpen: (value: boolean) => void
}

export const SurveyJSRenderer: FC<ISurveyJSRendererProps> = React.memo((props) => {
    const { t } = useTranslation('caseManagement', { useSuspense: false });

    const {
        isLoading,
        surveyModel,
        onFormSubmit,
        formDescriptionDialogOpen,
        setFormDescriptionDialogOpen,
        coachingNoteAnchor,
        coachingNote,
        coachingNoteTitle
    } = props

    const renderCoachingNoteInfoButton = () => {
        return coachingNoteAnchor && (
            ReactDOM.createPortal(
                <IconButton onClick={() => setFormDescriptionDialogOpen(true)} aria-label={t('form_note_button_label')}>
                    <InfoIcon/>
                </IconButton>
            , coachingNoteAnchor)
        )
    }

    useEffect(() => {
        surveyModel?.onAfterRenderQuestion.add((_, options) => console.log(options))
        surveyModel?.onAfterRenderQuestionInput.add((_, options) => console.log(options))
        surveyModel?.onAfterRenderPage.add((_, options) => console.log(options))
    }, [surveyModel]);

    return (
        isLoading 
        ? <Spinner fillParent />
        : <>
            <SurveyJS.Survey model={surveyModel} onComplete={onFormSubmit}/>
            {
                coachingNoteAnchor && coachingNote &&
                renderCoachingNoteInfoButton()
            }
            {
                coachingNote &&
                <FormDescriptionDialog open={formDescriptionDialogOpen} title={coachingNoteTitle ?? ''}
                    content={<p dangerouslySetInnerHTML={{ __html: coachingNote }}/>}
                    onClose={() => setFormDescriptionDialogOpen(false)}/>
            }
        </>
    )
})

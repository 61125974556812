import { Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, Divider, FormControlLabel, IconButton, TextField } from '@mui/material';
import React, { FC, useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { useTranslation } from 'react-i18next';
import { Button } from 'components/Shared/Inputs';

interface SubmitInvoiceConfirmationDialogProps {
    show: boolean,
    onCancel: () => void,
    onContinue: (invoiceNumber: string) => void
}

export const SubmitInvoiceConfirmationDialog: FC<SubmitInvoiceConfirmationDialogProps> = (props) => {
    const { show, onCancel, onContinue } = props;

    const { t } = useTranslation('invoicing', { useSuspense: false });
    
    const [invoiceNumber, setInvoiceNumber ] = useState<string>('');
    const [confirmChecked, setConfirmChecked ] = useState<boolean>(false);

    const handleSubmitInvoiceClick = () => {
        onContinue(invoiceNumber);
    }

    return (
        <Dialog open={show} className="invoice_summary_submit_dialog" onClose={onCancel}>
            <DialogTitle className="invoice_summary_header">
                <h3 className="invoice_summary_submit_dialog_title global__header3">
                    {t('submit_invoice')}
                </h3>
                <IconButton aria-label="close" className="invoice_summary_submit_dialog_close" onClick={onCancel}>
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent className="invoice_summary_body_content">
                <div className="invoice_summary_submit_dialog_confirmation_wrapper">
                    <FormControlLabel 
                        className="invoice_summary_submit_dialog_confirmation_text global__paragraph" 
                        label={t('invoicing_submit_checkbox_label') as string} 
                        control={
                            <Checkbox
                                className="invoice_summary_submit_checkbox"
                                checked={confirmChecked}
                                color="primary"
                                onChange={(event, value) => setConfirmChecked(value)}
                            />
                        }
                        />
                </div>
                <Divider />
                <div className="invoice_dialog_text_wrapper">
                    <h3 className="global__header4 global__header4--light invoice_tracking_title">{t('invoice_tracking')}</h3>
                    <p className="global__paragraph global__paragraph--light">
                        {t('invoice_tracking_msg')}
                    </p>
                    <TextField
                        id="invoice_summary_submit_dialog_input_field"
                        name="invoice_summary_submit_dialog"
                        className="invoice_summary_submit_dialog_input"
                        autoFocus
                        label={t('provider_invoice_number_optional')}
                        value={invoiceNumber}
                        variant="outlined"
                        onChange={(event) => setInvoiceNumber(event.target.value)}
                    />
                </div>
            </DialogContent>
            <DialogActions className="invoice_summary_action_buttons">
                <Button variant="text" onClick={onCancel}>
                    {t('cancel')}
                </Button>
                <Button variant="opaque" onClick={handleSubmitInvoiceClick} disabled={!confirmChecked}>
                    {t('submit_invoice')}
                </Button>
            </DialogActions>
        </Dialog>
    )
}
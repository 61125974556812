// import './InvoiceDetails.scss';
import { Checkbox, CircularProgress, Container, FormControlLabel } from '@mui/material';
import { Spinner } from 'components/Shared';
import React, { FC, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { EditWspBillable, IActivityInvoiceData, InvoiceBillable, InvoiceDetails as IInvoiceDetails } from '../../interfaces';
import { InvoicePreviewHTML } from '../InvoicePreview';
import { useBillables } from '../../hooks'

import { SessionsTable } from '../SessionsTable'
import { ADD_BILLABLE, APPLY_TAXES, DELETE_BILLABLE, EDIT_BILLABLE, SUBMIT_INVOICE } from '../../queries';
import { scrollToTop, toCurrencyString } from 'utils';
import { ToggleButton } from 'components/Shared/Inputs/ToggleButton';
import { useLazyQuery, useMutation } from '@apollo/client';
import { INTERCESSION_WSP_TASK_CODES_QUERY, WSP_TASK_CODES_QUERY } from 'hooks/useWspTaskCodes/queries';
import { useAuth } from 'components/Auth';
import { WspTasksTable } from '../WspTasksTable';
import { DURATION_CONSTANTS_QUERY } from 'hooks/useDurationConstants/queries';
import { useNavigate } from 'react-router-dom';
import { WspBillableEditDialog } from '../WspTaskEditDialog';
import { Button } from 'components/Shared/Inputs';
import { SubmitInvoiceConfirmationDialog } from '../SubmitInvoiceConfirmationDialog';
import { InvoiceDetailsHeader } from '../InvoiceDetailsHeader';
import { InvoiceDetailsSummary } from '../InvoiceDetailsSummary';
import { deleteInvoiceDraftFromSession, updateInvoiceDraftInSession, useInvoiceDraft } from '../InvoiceDraftProvider/InvoiceDraftProvider';

import "./InvoiceEditor.scss";

interface InvoiceEditorProps {
    activity: IActivityInvoiceData,
    providerId: number,
    useWspFunctionality: boolean,
    onInvoiceSubmitted?: () => void
}

interface ITax {
    amount: number;
    taxCode: string; 
    taxDescription: string;
}

export const InvoiceEditor: FC<InvoiceEditorProps> = (props) => {
    const { activity, providerId, useWspFunctionality, onInvoiceSubmitted } = props;
    const { i18n, t } = useTranslation('invoicing', { useSuspense: false });
    const { effectiveProviderData } = useAuth();
    const navigate = useNavigate();
    const [allTaxes, setAllTaxes] = useState<ITax[]>([]);

    const activityId = activity.activityId;

    const updateInvoiceUnderEdit = (invoice: IInvoiceDetails) => {
        if (invoice.taxes.length > allTaxes.length) {
            setAllTaxes(invoice.taxes);
        }
        updateInvoiceDraftInSession(activityId, invoice);
        setInvoiceUnderEdit(invoice);
    }

    const [applyTaxes, applyTaxesMutation] = useMutation(APPLY_TAXES, {
        onCompleted: ({invoice}) => updateInvoiceUnderEdit(invoice)
    });
    const [addBillable, addBillableMutation] = useMutation(ADD_BILLABLE, {
        onCompleted: ({invoice}) => updateInvoiceUnderEdit(invoice)
    });
    const [deleteBillable, deleteBillableMutation] = useMutation(DELETE_BILLABLE, {
        onCompleted: ({invoice}) => updateInvoiceUnderEdit(invoice)
    });
    const [editBillable, editBillableMutation] = useMutation(EDIT_BILLABLE, {
        onCompleted: ({invoice}) => updateInvoiceUnderEdit(invoice)
    });
    const [submitInvoice, submitInvoiceMutation] = useMutation(SUBMIT_INVOICE, {
        onCompleted: ({invoice}) => {
            deleteInvoiceDraftFromSession(activityId);
            navigate(`/clients/${activityId}/invoices/${invoice.invoiceId}`, { replace: true });
            scrollToTop();
            onInvoiceSubmitted?.();
        }
    });
    const { invoiceDraft, loading: invoiceDraftLoading } = useInvoiceDraft(activityId);

    const [taskUnderEdit, setTaskUnderEdit] = useState<EditWspBillable | null>(null);
    const [invoiceUnderEdit, setInvoiceUnderEdit] = useState<IInvoiceDetails | null>(null);
    const [showSubmitDialog, setShowSubmitDialog] = useState(false);

    const [selectedTaxCodes, setSelectedTaxCodes] = useState<{[id: string]: boolean}>({});

    useEffect(() => {
        if (invoiceDraft && !invoiceUnderEdit && !invoiceDraftLoading) {
            if (invoiceDraft.taxes.length > allTaxes.length) {
                setAllTaxes(invoiceDraft.taxes);
            }
            setInvoiceUnderEdit(invoiceDraft as IInvoiceDetails);
        }
    }, [invoiceDraft, invoiceUnderEdit, invoiceDraftLoading]);

    useEffect(() => {
        allTaxes?.length && setSelectedTaxCodes(allTaxes.reduce((acc, tax) => {
            acc[tax.taxCode] = true;
            return acc;
        }, {}));
    }, [allTaxes]);

    const [fetchWspTaskCodes, wspTaskCodesQuery] = useLazyQuery(WSP_TASK_CODES_QUERY);
    const [fetchIntercessionWspTaskCodes, wspIntercessionTaskCodesQuery] = useLazyQuery(INTERCESSION_WSP_TASK_CODES_QUERY);
    const [fetchDurationConstants, durationConstantsQuery] = useLazyQuery(DURATION_CONSTANTS_QUERY);

    useEffect(() => {
        fetchWspTaskCodes();
        fetchIntercessionWspTaskCodes();
        fetchDurationConstants();
    }, [useWspFunctionality]);

    // const allWspTaskCodes = wspTaskCodesQuery.data?.wspTaskCodes;
    const intercessionWspTaskCodes = wspIntercessionTaskCodesQuery.data?.intersessionWspTaskCodes;
    const durationConstants = durationConstantsQuery.data?.appointmentDurations;

    const [ showBuilder, setShowBuilder ] = useState(true);

    const { wspBillables, sessionBillables } = useBillables(invoiceUnderEdit?.billables, useWspFunctionality);

    const handleAddWspBillable = () => {
        setTaskUnderEdit({
            activityId: activityId!,
            invoiceId: invoiceUnderEdit!.invoiceId,
            providerId: providerId,
            billableId: undefined,
            date: undefined,
            duration: 4,
            wspTaskCode: undefined,
            wspReportType: undefined,
            wspMonthlyReportMonth: undefined,
            wspMonthlyReportYear: undefined
        });
    }
    
    const handleEditWspBillable = (billable: InvoiceBillable) => {
        setTaskUnderEdit({
            activityId: activityId!,
            invoiceId: invoiceUnderEdit!.invoiceId,
            providerId: providerId,
            billableId: billable.activityInvoiceId,
            date: billable.dateUtc,
            duration: billable.hours * 4,
            wspTaskCode: billable.wspTaskCode,
            wspReportType: billable.wspReportType,
            wspMonthlyReportMonth: billable.wspMonthlyReportMonth,
            wspMonthlyReportYear: billable.wspMonthlyReportYear
        });
    }
    
    const handleCancelWspBillableEdit = () => {
        setTaskUnderEdit(null);
    }

    const handleDeleteWspBillable = (task) => (
        deleteBillable({
            variables: {
                providerId: effectiveProviderData?.providerId ?? 0,
                invoiceId: invoiceUnderEdit?.invoiceId ?? 0,
                billableId: task.activityInvoiceId
            }
        })
    )

    const handleInvoiceSubmit = (invoiceNumber: string) => {
        setShowSubmitDialog(false);
        invoiceUnderEdit && submitInvoice({
            variables: {
                providerId: effectiveProviderData?.providerId ?? 0,
                invoiceId: invoiceUnderEdit.invoiceId ?? 0,
                comments: invoiceUnderEdit.approverComment ?? '',
                providerInvoiceNumber: invoiceNumber,
                activityId: activityId!,
                addCaseManagementTime: false,
            }
        });
    }

    const createTaxSelectionHandler = (taxCode: string) => (event) => {
        const newSelectedTaxCodes = {
            ...selectedTaxCodes,
            [taxCode]: event.target.checked
        };

        setSelectedTaxCodes(newSelectedTaxCodes);

        applyTaxes({
            variables: {
                providerId: effectiveProviderData?.providerId ?? 0,
                invoiceId: invoiceUnderEdit?.invoiceId ?? 0,
                taxCodes: allTaxes?.filter(tax => newSelectedTaxCodes[tax.taxCode]).map(tax => tax.taxCode) ?? []
            }
        });
    }

    const renderTaxSelection = useCallback(() => (
        !!allTaxes?.length ?
        <div className="tax-selection">
            <h4>{t('taxes')}</h4>
            <p>{t('select_applicable_taxes')}</p>
            {
                allTaxes.map(tax => (
                    <div className="applicable-tax" key={tax.taxCode}>
                        <FormControlLabel 
                            className="applicable-tax-text global__paragraph"
                            label={tax.taxDescription}
                            control={
                                <Checkbox
                                    className="applicable-tax-checkbox"
                                    checked={selectedTaxCodes[tax.taxCode] ?? false}
                                    color="primary"
                                    disabled={applyTaxesMutation.loading}
                                    onChange={createTaxSelectionHandler(tax.taxCode)}
                                />
                            }
                        />
                    </div>
                ))
            }
            {
                applyTaxesMutation.loading &&
                <CircularProgress/>
            }
        </div>
        :
        /* we want to render an empty div so case management checkbox stays on the right if no taxes available  */
        (<div className='tax-selection'></div>)
    ), [selectedTaxCodes, applyTaxesMutation]);

    const renderTaxes = () => (
        !!allTaxes?.length &&
        <div className="taxes">
            {
                allTaxes.map(tax => (
                    <div className="tax cost-row" key={tax.taxCode}>
                        <p className="title">{tax.taxDescription}</p>
                        <p className="value">{toCurrencyString(tax.amount, i18n.language, invoiceUnderEdit?.currencyCode)}</p>
                    </div>
                ))
            }
        </div>
    )

    const renderTotals = () => (
        <div className="total-invoice">
            <div className="total-fees total cost-row">
                <p className="title">{t('total_fees', 'Total Fees')}</p>
                <p className="value">{toCurrencyString(invoiceUnderEdit?.totalFees, i18n.language, invoiceUnderEdit?.currencyCode) ?? '---'}</p>
            </div>
            <div className="total-expenses total cost-row">
                <p className="title">{t('total_expenses', 'Total Expenses')}</p>
                <p className="value">{toCurrencyString(invoiceUnderEdit?.totalExpenses, i18n.language, invoiceUnderEdit?.currencyCode) ?? '---'}</p>
            </div>
            <div className="fees-total-after-taxes">
                <div className="invoice_builder_total_amount" aria-label={t('total_invoice_amount')}>
                    {toCurrencyString(invoiceUnderEdit?.grandTotal, i18n.language, invoiceUnderEdit?.currencyCode) ?? '---'}</div>
                <p className="invoice_builder_total_amount_label title">{t('total_invoice_amount')}</p>
            </div>
        </div>
    )

    const renderWspTasksTable = () => (
        <WspTasksTable
            wspTasks={wspBillables}
            editDisabled={false}
            allWspTaskCodes={wspTaskCodesQuery.data?.wspTaskCodes}
            durationConstants={durationConstantsQuery.data?.appointmentDurations}
            onAddTask={handleAddWspBillable}
            onDeleteTask={handleDeleteWspBillable}
            onEditTask={handleEditWspBillable}
        />
    )

    const renderSessionsTable = () => (
        <SessionsTable sessions={sessionBillables}/>
    )

    const mutationLoading = applyTaxesMutation.loading
        || addBillableMutation.loading
        || deleteBillableMutation.loading
        || editBillableMutation.loading
        || submitInvoiceMutation.loading;

    return (
        <Container maxWidth="lg" className="invoice_details_content_wrapper">
            {
                mutationLoading &&
                <Spinner fillParent backdrop/>
            }
            {
                invoiceDraftLoading
                    ? <Spinner fillParent/>
                    : <>
                        <InvoiceDetailsHeader
                            invoices={activity.invoices}
                            printAvailable={false}
                            selectedInvoice={invoiceUnderEdit ?? (invoiceDraft as IInvoiceDetails)}
                            generateInvoiceAvailable={false}
                            activityId={activityId!}
                        />
                        <InvoiceDetailsSummary
                            invoice={invoiceUnderEdit ?? (invoiceDraft as IInvoiceDetails)}
                            uninvoicedAppointmentsCount={activity.invoicableSessions?.length ?? 0}
                            onSubmitInvoiceClick={() => setShowSubmitDialog(true)}
                            disableSubmitButton={mutationLoading}
                        />
                        <ToggleButton
                            value={showBuilder}
                            onChange={setShowBuilder}
                            disabled={false}
                            leftButtonLabel={t('edit')}
                            rightButtonLabel={t('preview')}
                            className="editor_mode_toggle"
                            leftClassName={showBuilder ? "" : "left-opaque-mirror"}
                            rightClassName={showBuilder ? "right-opaque-mirror" : ""}
                        />
                        {
                            invoiceUnderEdit && (!showBuilder
                                ? <InvoicePreviewHTML
                                    showWspTable={useWspFunctionality}
                                    invoice={invoiceUnderEdit}
                                    activity={activity}
                                    />
                                : <>
                                    <h2>{t('fees')}</h2>
                                    { Boolean(sessionBillables?.length) && renderSessionsTable() }
                                    { useWspFunctionality && renderWspTasksTable() }
                                    <div className="additional-charges">
                                        { renderTaxSelection() }
                                    </div>
                                    { renderTaxes() }
                                    { renderTotals() }
                                </>
                            )
                        }
                        <div className="invoice_action_buttons" style={{ display: 'flex'}}>
                            <Button variant="outline" onClick={() => setShowBuilder(!showBuilder)} style={{ marginRight: '15px' }}>
                                {showBuilder ? t('preview_invoice') : t('edit_invoice')}
                            </Button>
                            <Button variant="opaque" disabled={mutationLoading} onClick={() => setShowSubmitDialog(true)}>
                                {t('submit_invoice')}
                            </Button>
                        </div>
                    </>
            }
            <SubmitInvoiceConfirmationDialog
                show={showSubmitDialog}
                onCancel={() => setShowSubmitDialog(false)}
                onContinue={handleInvoiceSubmit}
            />
            {
                taskUnderEdit &&
                <WspBillableEditDialog
                    activityId={activityId}
                    durationConstants={durationConstants}
                    taskCodes={intercessionWspTaskCodes}
                    wspBillable={taskUnderEdit}
                    activityCreatedDate={activity.openDate}
                    createMutation={[addBillable, addBillableMutation]}
                    updateMutation={[editBillable, editBillableMutation]}
                    onCancel={handleCancelWspBillableEdit}
                />
            }
        </Container>
    )
}